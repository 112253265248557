/**
 *   @description - converts a column index to an excel column letter - ex. 0 = A, 1 = B,26 = AA, 52 = BA, etc.
 *  @param columnNumber - column index (starting from 0)
 *   @returns - Letters like excel column headers
 */

const numberToCellLetter = (columnNumber: number) => {
  // convert numbers 0-25 to letters A-Z
  const generateLetter = (num: number) => String.fromCharCode(num + "A".charCodeAt(0));

  if (columnNumber > 25) {
    // if more than 25 - return multiple letters
    const firstLetterNumber = Math.floor(columnNumber / 26) - 1;
    const firstLetter = generateLetter(firstLetterNumber);

    const secondLetterNumber = columnNumber % 26;
    const secondLetter = generateLetter(secondLetterNumber);

    return `${firstLetter}${secondLetter}`;
  }

  // if less than 26 - just return single letter
  const letter = generateLetter(columnNumber);
  return letter;
};

export default numberToCellLetter;
