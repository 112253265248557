import React, { FC, useEffect, useState } from 'react';
import movaraLogoWhite from 'assets/images/movara-home-logo-white.png';
import {
    Avatar,
    Box,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Weekly from 'components/_modules/WeeklyAssessment/Weekly';
import Post from './Post';
import Profile from './Profile';
import { CircleCheckIcon } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { http } from 'services/data';
import Auth from 'services/domain/auth';
import Button from 'components/_newDesign/button/Button';
import ModalWaringSubmit from 'components/_newDesign/alert/ModalWaring';
interface Step {
    step1: boolean;
    step2: boolean;
    step3: boolean;
}

const logout = () => {
    Auth.logout();
    // history.push('signin');
};

const BoxSuccess: FC<any> = (props) => {
    const classes = useStyles();
    return (
        <Box my={1}>
            <Paper style={{ borderRadius: '20px' }}>
                <Box
                    pb={3}
                    display="flex"
                    alignContent="center"
                    flexDirection={'column'}
                >
                    <Box
                        px={6}
                        py={3}
                        display="flex"
                        alignItems="center"
                        flexGrow={1}
                    >
                        <Typography align="center" className={classes.subtitle}>
                            {props.title}
                        </Typography>
                        <hr />
                    </Box>
                    <Box
                        style={{
                            width: '100%',
                            borderTop: '1px solid #E6E6E6',
                        }}
                    ></Box>
                    <Box textAlign="center" flexGrow={1} mt={3}>
                        <CircleCheckIcon
                            isActive
                            viewBox="0 0 32 32"
                            style={{ height: '62px', width: '62px' }}
                        />
                    </Box>
                    {props.subtitle && (
                        <Box textAlign="justify" flexGrow={1} mt={2} px={6}>
                            <Typography className={classes.content}>
                                {props.subtitle}
                            </Typography>
                        </Box>
                    )}
                    <Box textAlign="center" flexGrow={1} mt={3}>
                        <Button color="primary" disabled variant="contained">
                            Submitted
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};
const StepOnBoarding: React.FC<any> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const classes = useStyles();
    const history = useHistory();
    const [step, setStep] = useState<Step>({
        step1: false,
        step2: false,
        step3: false,
    });
    const user = Auth.userProperties;

    const checkOnboarding = () => {
        const values = { ...step };
        if (
            props.data.step1.bio !== undefined &&
            props.data.step1.displayName !== undefined
        )
            values.step1 = true;
        if (props.data.step2 !== null) values.step2 = true;
        if (props.data.step3 !== null) values.step3 = true;
        setStep(values);
    };
    const changeStatusStep = (key: string) => {
        if (key === 'step1' || key === 'step2') {
            window.scrollTo({
                top: key === 'step1' ? 250 : 550,
                left: 0,
                behavior: 'smooth',
            });
        }
        setStep({ ...step, [key]: true });
    };

    const onBoardingCompleted = () => {
        setLoading(true);
        http.post('onboarding')
            .then(() => {
                Auth.updateUserProperties('onboarding', true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const modalOnclose = () => {
        setOpen(false);
        window.scrollTo({
            top: step.step1 ? 350 : 550,
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        checkOnboarding();
        // eslint-disable-next-line
    }, [props.data]);

    useEffect(() => {
        if (step.step1 && step.step2 && step.step3) {
            onBoardingCompleted();
            props.onCompleted();
            setComplete(true);
        }
        // eslint-disable-next-line
    }, [step]);

    return (
        <React.Fragment>
            <ModalWaringSubmit
                show={open}
                onClose={() => modalOnclose()}
                title={
                    step.step1
                        ? 'Submit Set Up Weekly Submission'
                        : 'Submit Set Up Account'
                }
                content={
                    step.step1
                        ? 'You have to submit Set Up Weekly Submission first!'
                        : 'You have to submit Set Up Account form first!'
                }
            />
            <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                mt={4}
                mr={4}
            >
                <Box pt={1} width="100%" textAlign="right">
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Box>
            </Box>
            <Box
                style={{ backgroundColor: 'white' }}
                width="100%"
                px={1}
                py={2}
                display={user.userData.statusFlow !== '0' ? 'flex' : 'none'}
                justifyContent="center"
                flexDirection="column"
                position="fixed"
                bottom={0}
                zIndex={1000}
            >
                {user.userData.statusFlow !== '0' && (
                    <>
                        <Box
                            display="inline-block"
                            width="100%"
                            textAlign="center"
                        >
                            <Box
                                mt={5}
                                width={'100%'}
                                px={16}
                                className={classes.onBoardDesc}
                            >
                                <Grid
                                    container
                                    style={{ justifyContent: 'center' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={complete ? 3 : 4}
                                        xl={complete ? 3 : 4}
                                        sm={complete ? 3 : 4}
                                        xs={4}
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <Box>
                                            <Avatar
                                                className={` ${
                                                    step.step1
                                                        ? classes.newStepCircleDone
                                                        : classes.newStepCircleMain
                                                }`}
                                            >
                                                1
                                            </Avatar>
                                        </Box>
                                        <Box mt={2}>
                                            <LinearProgress
                                                style={{
                                                    height: '12px',
                                                    borderRadius: '6px',
                                                }}
                                                variant="determinate"
                                                color={
                                                    step.step1
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                value={step.step1 ? 100 : 0}
                                            />
                                        </Box>
                                        <Box textAlign={'center'} mt={2}>
                                            <div
                                                className={classes.newStepDesc}
                                            >
                                                Account Set Up
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        md={complete ? 3 : 4}
                                        xl={complete ? 3 : 4}
                                        sm={complete ? 3 : 4}
                                        xs={4}
                                    >
                                        <Box>
                                            <Avatar
                                                className={` ${
                                                    step.step2
                                                        ? classes.newStepCircleDone
                                                        : classes.newStepCircleMain
                                                }`}
                                            >
                                                2
                                            </Avatar>
                                        </Box>
                                        <Box mt={2}>
                                            <LinearProgress
                                                style={{
                                                    height: '12px',
                                                    borderRadius: '6px',
                                                }}
                                                variant="determinate"
                                                color={
                                                    step.step2
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                value={step.step2 ? 100 : 0}
                                            />
                                        </Box>
                                        <Box textAlign={'center'} mt={2}>
                                            <div
                                                className={classes.newStepDesc}
                                            >
                                                Submit Weekly Assessment
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        md={complete ? 3 : 4}
                                        xl={complete ? 3 : 4}
                                        sm={complete ? 3 : 4}
                                        xs={4}
                                    >
                                        <Box>
                                            <Avatar
                                                className={` ${
                                                    step.step3
                                                        ? classes.newStepCircleDone
                                                        : classes.newStepCircleMain
                                                }`}
                                            >
                                                3
                                            </Avatar>
                                        </Box>
                                        <Box mt={2}>
                                            <LinearProgress
                                                style={{
                                                    height: '12px',
                                                    borderRadius: '6px',
                                                }}
                                                variant="determinate"
                                                color={
                                                    step.step3
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                                value={step.step3 ? 100 : 0}
                                            />
                                        </Box>
                                        <Box textAlign={'center'} mt={2}>
                                            <div
                                                className={classes.newStepDesc}
                                            >
                                                Post to Community Page
                                            </div>
                                        </Box>
                                    </Grid>
                                    {complete && (
                                        <Grid
                                            md={complete ? 3 : 4}
                                            xl={complete ? 3 : 4}
                                            sm={complete ? 3 : 4}
                                            item
                                            xs={4}
                                        >
                                            <Box>
                                                <CircleCheckIcon
                                                    isActive
                                                    viewBox="0 0 32 32"
                                                    style={{
                                                        height: '40px',
                                                        width: '40px',
                                                    }}
                                                />
                                            </Box>
                                            <Box textAlign={'center'} mt={2}>
                                                <div
                                                    className={
                                                        classes.newStepDesc
                                                    }
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '24px',
                                                    }}
                                                >
                                                    Onboarding <br />
                                                    Completed!
                                                </div>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </>
                )}

                {!step.step1 && !step.step2 && !step.step3 && (
                    <React.Fragment>
                        <Box
                            mt={6}
                            width="100%"
                            textAlign="center"
                            className={classes.successDesc}
                        >
                            <Typography className={classes.subtitle}>
                                Setting up your profile
                            </Typography>
                        </Box>
                    </React.Fragment>
                )}

                {step.step1 && step.step2 && step.step3 && (
                    <React.Fragment>
                        <Box
                            mt={6}
                            mb={1}
                            width="100%"
                            textAlign="center"
                            className={classes.successDescButton}
                        >
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                className={classes.button}
                                onClick={() =>
                                    user.userData.statusBooking === 'Active'
                                        ? history.push('/dashboard-user')
                                        : history.push('/waiting')
                                }
                                disabled={loading}
                            >
                                Continue to Movara Home Dashboard
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
            <Container maxWidth="md">
                <Box
                    margin="auto"
                    mb={10}
                    mt={10}
                    textAlign="center"
                    maxWidth="800px"
                >
                    <img
                        src={movaraLogoWhite}
                        alt="movara's logo"
                        width="50%"
                    />
                </Box>
                <Box my={10}>
                    {step.step1 ? (
                        <BoxSuccess title="Setting up profile" />
                    ) : (
                        <Profile
                            profile={props.data.step1}
                            changeStatusStep={(key: string) =>
                                changeStatusStep(key)
                            }
                        />
                    )}
                </Box>
                {user.userData.statusFlow !== '0' && (
                    <>
                        <Box my={10}>
                            {step.step2 ? (
                                <BoxSuccess
                                    title="Weekly Weight Submission"
                                    subtitle={
                                        'Success! You’ve just completed your first Weekly Assessment, we do these every Sunday to track our progress.'
                                    }
                                />
                            ) : (
                                <Paper style={{ borderRadius: '20px' }}>
                                    <Box
                                        px={6}
                                        py={3}
                                        display="flex"
                                        alignItems="center"
                                        flexGrow={1}
                                    >
                                        <Typography
                                            className={classes.subtitle}
                                        >
                                            Weekly Weight Submission
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '100%',
                                            borderTop: '1px solid #E6E6E6',
                                        }}
                                    ></Box>
                                    <Box className={classes.weeklyPadding}>
                                        <Typography
                                            align="left"
                                            className={classes.content}
                                        >
                                            You will fill in this form every
                                            Sunday while you’re active on the
                                            program. It’s a part the structure
                                            and accountability what will help
                                            you keep track of your progress
                                            during you Movara Home experience.
                                        </Typography>
                                    </Box>
                                    <Box className={classes.weeklyPadding}>
                                        <Weekly
                                            openModalWarning={() =>
                                                setOpen(true)
                                            }
                                            step={step}
                                            changeStatusStep={(key: string) =>
                                                changeStatusStep(key)
                                            }
                                        />
                                    </Box>
                                </Paper>
                            )}
                        </Box>
                        <Box my={10}>
                            {step.step3 ? (
                                <BoxSuccess
                                    title="Post to Community Page"
                                    subtitle={
                                        'Your post is submitted! From here you can check out the Community Page and see what the group is up to! Sunday is when you’ll have full access to your account.'
                                    }
                                />
                            ) : (
                                <Paper style={{ borderRadius: '20px' }}>
                                    <Box
                                        px={6}
                                        py={3}
                                        display="flex"
                                        alignItems="center"
                                        flexGrow={1}
                                    >
                                        <Typography
                                            align="center"
                                            className={classes.subtitle}
                                        >
                                            Post to Community Page
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '100%',
                                            borderTop: '1px solid #E6E6E6',
                                        }}
                                    ></Box>
                                    <Box className={classes.weeklyPadding}>
                                        <Post
                                            openModalWarning={() =>
                                                setOpen(true)
                                            }
                                            step={step}
                                            changeStatusStep={(key: string) =>
                                                changeStatusStep(key)
                                            }
                                        />
                                    </Box>
                                </Paper>
                            )}
                        </Box>
                    </>
                )}
            </Container>
        </React.Fragment>
    );
};

export default StepOnBoarding;
