import { getBookingByEmail } from "services/domain/admin/bookings";
import moment from "moment";

const getRange = (startDate: string, endDate: string) => {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, "days");
  let range: Date[] = [];
  for (let i = 0; i < diff; i++) {
    const date = moment(startDate).add(i, "days").format("YYYY-MM-DD");
    range.push(new Date(date));
  }
  return range;
};

const getWeeks = (prevBookedDates) => {
  const noWeeks = 4;

  const weeks: any = [];
  let lastWeekBooked = prevBookedDates[prevBookedDates.length - 1];

  //add the 4 weeks to week array
  for (let i = 1; i < noWeeks + 1; i++) {
    const dateFormatted: any = moment(lastWeekBooked).add(i, "weeks").startOf("isoWeek").subtract(1, "days").format("MMMM Do YYYY");
    const dateNum = moment(lastWeekBooked).add(i, "weeks").startOf("isoWeek").subtract(1, "days").format();
    weeks.push({ dateFormatted, dateNum, selected: false });
  }

  return weeks;
};

export default async (email) => {
  try {
    const result = await getBookingByEmail({ email: email });
    result.data.map((elm) => {
      if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
      if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
      return elm;
    });
    const filteredData = result.data.filter((item) => item.statusBooking === "Waiting" || item.statusBooking === "Active");
    let dates: Date[] = [];
    if (filteredData.length !== 0) {
      for (let index = 0; index < filteredData.length; index++) {
        const days = await getRange(filteredData[index].startDate, filteredData[index].endDate);
        dates = dates.concat(days);
        days.splice(0, 1);
        filteredData[index].days = days;
      }
    }
    // dates may be out of order - we want them in order so we know that the end of the array is the last active dates they have booked
    const sortedDates = dates.sort((a: Date, b: Date) => {
      return a.getTime() > b.getTime() ? 1 : -1;
    });

    const calculatedWeeks = getWeeks(sortedDates);
    const nextDateValue = new Date(calculatedWeeks[0]?.dateNum);

    const currentBooking = filteredData.find((booking: any) => booking.statusBooking === "Active");
    return {
      nextStartDate: nextDateValue,
      currentBooking,
    };
  } catch (e) {
    console.error(e);
  }
};
