import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function GearIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 20" className={classes.root}>
            <g clipPath="url(#clip0)">
            <path d="M19.5897 11.0992V8.86136L16.5483 8.73479C16.3757 7.82659 16.0171 6.98323 15.5147 6.24651L17.5776 4.00432L15.9952 2.42151L13.7526 4.48479C13.0159 3.98245 12.1726 3.62385 11.2647 3.4512L11.1382 0.40979H8.90029L8.77373 3.4512C7.86553 3.62385 7.02295 3.98245 6.28545 4.48479L4.04326 2.42151L2.46084 4.00432L4.52373 6.24651C4.02139 6.98323 3.66279 7.82659 3.49014 8.73479L0.44873 8.86136V11.0992L3.49014 11.2258C3.66279 12.134 4.02139 12.9766 4.52373 13.7141L2.46084 15.9563L4.04365 17.5391L6.28584 15.4762C7.02334 15.9785 7.86592 16.3371 8.77412 16.5098L8.90068 19.5512H11.1386L11.2651 16.5098C12.1733 16.3371 13.0167 15.9785 13.7534 15.4758L15.9956 17.5391L17.578 15.9563L15.5151 13.7141C16.0175 12.9766 16.3761 12.134 16.5487 11.2258L19.5897 11.0992ZM13.6315 11.6676C13.2358 12.5125 12.5522 13.1969 11.7073 13.593C11.6011 13.643 11.4921 13.6887 11.3804 13.7289C10.9554 13.8836 10.4972 13.9684 10.0194 13.9684C9.54209 13.9684 9.08311 13.884 8.6585 13.7289C8.54756 13.6887 8.43818 13.6434 8.33193 13.5934C7.48701 13.1969 6.80342 12.5129 6.40732 11.668C6.35693 11.5617 6.31162 11.4528 6.27139 11.3414C6.1167 10.9168 6.03193 10.4582 6.03193 9.9805C6.03193 9.50237 6.11631 9.04417 6.27139 8.61956C6.31162 8.50784 6.35771 8.39886 6.40732 8.29261C6.80342 7.44768 7.48779 6.76409 8.33271 6.368C8.43896 6.318 8.54756 6.27268 8.6585 6.23245C9.0835 6.07776 9.54209 5.993 10.0194 5.993C10.4976 5.993 10.9558 6.07737 11.3804 6.23245C11.4913 6.27268 11.6003 6.318 11.7069 6.368C12.5519 6.76409 13.2362 7.44846 13.6315 8.29339C13.6819 8.39964 13.7272 8.50862 13.7675 8.61995C13.9222 9.04495 14.0069 9.50315 14.0069 9.98089C14.0069 10.459 13.9226 10.9172 13.7675 11.3418C13.7272 11.4528 13.6823 11.5614 13.6315 11.6676Z" stroke="#414141" strokeMiterlimit="10"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </SvgIcon>
    );;
}

