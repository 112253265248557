import React, { useState, useContext, useEffect } from "react";
import { Box, Container, LinearProgress, withStyles, Theme, createStyles, CircularProgress } from "@material-ui/core";
import { useStyles } from "../styles";
import Profile from "./Profile";
import Checkout from "./Checkout";
import Finished from "./Finished";
import moment from "moment";
import auth from "services/domain/auth";
import Button from "components/_newDesign/button/Button";
import { useHistory, useLocation } from "react-router-dom";
import { HomeIcon } from "components/_newIcon";
import { ProfileContext, ProfileContextType, ProfileInterface } from "./PaymentStore";
import getCurrentBookingAndNextStartDate from "./Form/getCurrentBookingAndNextStartDate";
import { getUser } from "services/domain/users";
import checkPrice from "./Form/checkPrice";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 17,
      borderRadius: 10,
      boxShadow: "inset 1px 1px 8px rgba(0, 0, 0, 0.1)",
    },
    colorPrimary: {
      backgroundColor: "#F8F8F8",
    },
    bar: {
      borderRadius: 5,
    },
  }),
)(LinearProgress);

const StepPayment: React.FC<any> = (props) => {
  const { search } = useLocation();

  const urlQueries = new URLSearchParams(search);
  const hasAddWeeksQuery = urlQueries.has("add-weeks");

  const classes = useStyles();
  const [step, setStep] = useState<number>(0);
  const { profile, setProfile } = useContext(ProfileContext) as ProfileContextType;
  const history = useHistory();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  const [showSpinner, setShowSpinner] = useState(hasAddWeeksQuery ? true : false);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const changeStatusStep = (key: number) => {
    setStep(key);
  };

  const onProfileSubmit = (profile: ProfileInterface) => {
    setProfile(profile);
  };

  const getProfile = async () => {
    try {
      const { data } = await getUser({
        user_id: auth.userProperties?.userData?._id,
      });

      return data;
    } catch (error) {}
  };

  useEffect(() => {
    hasAddWeeksQuery && setStep(1);
  }, []);

  useEffect(() => {
    // when adding weeks, we automatically go to step 1 - when that happens we need to get the default start date and booking id
    if (!hasAddWeeksQuery || step !== 1 || !auth.userProperties?.userData?.email) return;

    (async () => {
      await handleSkipStepOne();
    })();
  }, [step]);

  // When a user clicks the Add More Weeks from My Account - we are going to skip directly to Step 2: Checkout
  // we need to auto select the next possible day for the start date as we are just extending the existing booking and update profile as needed
  const handleSkipStepOne = async () => {
    const profileUserDt = await getProfile();

    const userEmail = auth.userProperties.userData.email;

    const nextStartAndActiveBookingId = await getCurrentBookingAndNextStartDate(userEmail);

    if (!nextStartAndActiveBookingId) return;

    const { nextStartDate, currentBooking } = nextStartAndActiveBookingId;

    const endDate = moment(nextStartDate).add(4, "weeks").toISOString();
    const formattedStartDate = moment(nextStartDate).toISOString();

    const price = checkPrice(4);

    setProfile({
      ...profile,
      firstName: profileUserDt.name.firstName,
      lastName: profileUserDt.name.lastName,
      phoneNumber: profileUserDt.phoneNumber,
      email: profileUserDt.email,
      gender: profileUserDt.gender || "male",
      dateOfBirth: profileUserDt.dob || "2021-07-25",
      address: profileUserDt.address,
      city: profileUserDt.city || "",
      state: profileUserDt.state || "",
      zipCode: profileUserDt.zipCode || "",
      startDate: formattedStartDate,
      endDate: endDate,
      nameOnCard: `${profileUserDt.name.firstName} ${profileUserDt.name.lastName}`,
      index: price.index,
      amount: price.amount,
      perweek: price.perweek,
      password: "",
      coach_id: currentBooking.coach_id,
      coachName: currentBooking.coachName,
    });

    setActiveBookingId(currentBooking._id);
    setShowSpinner(false);
  };

  const BackButton = () => {
    const isUserAuthenticated = auth.userProperties;

    const backDashboard = () => {
      history.push("/");
    };
    const backlogin = () => {
      history.push("/signin");
    };

    return (
      <Box pt={1} width="100%" textAlign="right">
        <Button color="primary" type="submit" variant="contained" onClick={isUserAuthenticated ? backDashboard : backlogin}>
          {isUserAuthenticated ? "Back to Booking" : "Back to Login"}
        </Button>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" mt={4} className={classes.logoutButton}>
          <BackButton />
        </Box>
        <Box margin="auto" mb={5} mt={5} textAlign="center" maxWidth="1200px">
          <h1 className={classes.titleHeader}>Experience Movara at home!</h1>
        </Box>
        <Box display={"flex"} flexDirection="column" margin="auto" className={scrollPosition > 186 ? classes.stepFixStyle : classes.stepStyle}>
          <Box mb={2} display="flex" justifyContent="center" gridGap={"50px"}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.subStepStyle}
              onClick={() => step === 1 && setStep(0)}
              style={{
                cursor: step === 1 ? "pointer" : "auto",
              }}
            >
              <div className={`${classes.circleStep} ${step === 0 ? classes.circleStepActive : classes.circleStepDone}`}>1</div>
              <div className={`${classes.stepTitle} ${step === 0 ? classes.stepTitleActive : classes.stepTitleDone}`}>Step 1: Enter Information</div>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.subStepStyle}>
              <div className={`${classes.circleStep} ${step === 1 ? classes.circleStepActive : step === 2 ? classes.circleStepDone : ""}`}>2</div>
              <div className={`${classes.stepTitle} ${step === 1 ? classes.stepTitleActive : step === 2 ? classes.stepTitleDone : ""}`}>Step 2: Checkout</div>
            </Box>
            {step === 2 && (
              <Box display="flex" justifyContent="center" alignItems="center" className={classes.subStepStyle}>
                <div className={`${classes.circleStep} ${classes.circleStepActive}`}>
                  <HomeIcon />
                </div>
                <div className={`${classes.stepTitle} ${classes.stepTitleActive}`}>You’re all set!</div>
              </Box>
            )}
          </Box>
          <Box display={"flex"} width="100%" gridGap={"30px"} className={classes.stepContainer}>
            <Box mb={2} width="100%" flexGrow={1}>
              <BorderLinearProgress variant="determinate" value={step === 0 ? 50 : 100} />
            </Box>
            <div className={classes.stepTitle}>{step === 0 ? "1/2" : "2/2"}</div>
          </Box>
        </Box>
        {step === 0 ? (
          <Profile
            changeStatusStep={(key: number) => changeStatusStep(key)}
            onProfileSubmit={(profile: ProfileInterface) => onProfileSubmit(profile)}
            hasAddWeeksQuery={hasAddWeeksQuery}
          />
        ) : step === 1 ? (
          <>
            {showSpinner ? (
              <Box
                display="flex"
                pt="10"
                // className={classes.loadingPost}
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Checkout changeStatusStep={(key: number) => changeStatusStep(key)} profile={profile} hasAddWeeksQuery={hasAddWeeksQuery} activeBookingId={activeBookingId} />
            )}
          </>
        ) : step === 2 ? (
          <Finished profile={profile} />
        ) : (
          <div></div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default StepPayment;
