import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            paddingBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            boxShadow: '1px 1px 8px rgba(65, 65, 65, 0.1)',
            borderRadius: '10px'
        },
        headerClass: {
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '120%',
            letterSpacing: '0.4px',
            color: '#000000',
            textAlign: 'center'
        }
    },
));
