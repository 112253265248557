import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 900,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        rootCheckout: {
            width: 1200,
        },
        cardContentWrapper: {
            backgroundColor: '#fff',
            borderRadius: '20px',
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            paddingTop: '50px',
            paddingBottom: '50px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
        },
        avatar: {
            // transform: 'translateY(-80px)',
        },
        profileInfo: {
            flex: 1,
            '&.is-edit': {
                '& .icon-setting, & .profile__items': {
                    display: 'none',
                },
            },
            '& .profile__guest-desc span': {
                color: '#C4C4C4',
            },
        },
        container: {
            position: 'relative',
            '&:hover': {
                '& $image': {
                    opacity: 0.6,
                },
                '& $middle': {
                    opacity: 1,
                },
            },
        },
        image: {
            opacity: 1,
            display: 'block',
            transition: '.5s ease',
            backfaceVisibility: 'hidden',
        },
        middle: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        middleLoading: {
            transition: '.5s ease',
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        text: {
            backgroundColor: '#C4C4C4',
            color: 'white',
            fontSize: '16px',
            padding: '16px 32px',
        },
        containerAvatar: {
            backgroundColor: '#E5E5E5',
            transform: 'translateY(-80px)',
            position: 'relative',
            borderRadius: '50%',
            '&:hover': {
                '& $middleAvatar': {
                    opacity: 1,
                },
                '& $avatar': {
                    opacity: 0.6,
                },
            },
        },
        middleAvatar: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: 200,
        },
        middleLoadingAvatar: {
            transition: '.5s ease',
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        coverInfo: {
            background: 'white',
            padding: '5px 10px',
            borderRadius: '10px',
            display: 'flex',
            boxShadow: '0px 1.25px 4px rgba(0, 0, 0, 0.25)',
        },
        inputCustomWidth: {
            height: '45px',
            padding: '8px 16px !important',
        },
        buttonCancel: {
            marginLeft: '20px',
        },
        formSwitchContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            flexWrap: 'nowrap',
        },
        switchTitleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        label: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontSize: 10,
            fontWeight: 400,
            lineHeight: '120%',
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            marginBottom: '14px',
        },
        optional: {
            color: '#9A9A9A',
            fontSize: '12px',
            fontWeight: 'normal',
        },
        formContainer: {
            marginTop: '20px',
        },
        circleStep: {
            border: '1px solid #B3B3B3',
            width: 34,
            height: 34,
            borderRadius: 34,
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '280%',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: ' 0.05em',
            color: '#B3B3B3',
        },
        circleStepActive: {
            border: '1px solid #FFA21E',
            color: '#000000',
        },
        circleStepDone: {
            backgroundColor: '#FFA21E',
            color: '#FFFFFF',
            border: 'none',
        },
        stepTitle: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.05em',
            color: '#B3B3B3',
            marginLeft: 15,
        },
        stepTitleActive: {
            color: '#000000',
        },
        dateRangePicker: {
            width: 'fit-content',
        },
        inputMov: {
            padding: '3px 16px',
            minHeight: 37,
            background: '#FAFAFA',
            boxShadow:
                'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
            borderRadius: '7px',
            minWidth: '100%',
            border: 'none',
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.05em',
        },
        inputMovStripe: {
            padding: '10px 14px',
            minHeight: 37,
            background: '#FAFAFA',
            boxShadow:
                'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
            borderRadius: '7px',
            minWidth: '100%',
            border: 'none',
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.05em',
        },
        inputMovFocused: {
            borderColor: theme.palette.primary.main,
            border: '2px solid red',
        },
        orderDetail: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '22px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#000000',
            marginBottom: '10px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '11px',
            },
        },
        orderDesc: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.05em',
            color: '#000000',
            [theme.breakpoints.down('xs')]: {
                fontSize: '10px',
            },
        },
        orderTotal: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '36px',
            lineHeight: '120%',
            textTransform: 'uppercase',
            color: '#000000',
            marginBottom: '10px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px',
                fontWeight: 500,
            },
        },
        orderNotes: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '9px',
            lineHeight: '100%',
            letterSpacing: '0.05em',
            color: '#808080',
        },
        stepStyle: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'start',
            },
        },
        subStepStyle: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px',
            },
        },
        inputDateCustom: {
            padding: '3px 16px',
            minHeight: 37,
            background: '#FAFAFA',
            boxShadow:
                'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
            borderRadius: '7px',
            minWidth: '100%',
            border: 'none',
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.05em',
        },
        datepickercss: {
            paddingRight: '20px',
            marginTop: '0px',
        },
        mobileDisplay: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        desktopDisplay: {
            display: 'flex',
            // [theme.breakpoints.up('md')]: {
            //     display: 'flex',
            // },
        },
        calendarDisplay: {
            display: 'flex',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
        footerStyle: {
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
            },
        },
        checkoutContainer: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                padding: '0px',
            },
        },
        checkoutSubOne: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto',
                display: 'flex',
                boxShadow: 'none',
                borderBottom: '1px solid rgb(0 0 0 / 5%)',
                paddingBottom: '10px',
            },
        },
        checkoutSubOneDesc: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        checkoutSubForm: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: '0px',
            },
        },
        coachAvatar: {
            width: '96px',
            height: '96px',
        },
        coachBox: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '12px',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                flexDirection: 'column',
            },
        },
        coachCard: {
            display: 'flex',
            flexDirection: 'column',
            width: '192px',
            padding: '12px',
            borderRadius: '10px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                flexDirection: 'row',
            },
        },
        coachSchedule: {
            padding: '4px 8px',
            borderRadius: '20px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '150%',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.25px',
            color: '#000000',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        coachAvatarBox: {
            textAlign: 'center',
            paddingBottom: '8px',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 0,
                paddingRight: '8px',
            },
        },
        cardRoot: {
            borderRadius: '20px',
        },
        DayCoachLeft: {
            fontFamily: 'Montserrat',
            display: 'table-cell',
            padding: '0.5em',
            borderRadius: '50%',
            verticalAlign: 'middle',
            textAlign: 'center',
            border: '1.5px solid #F1F1F1',
            width: '38px !important',
            marginBottom: '7px',
            fontSize: '14px',
            fontWeight: 400,
            color: '#CDCDCD',
            '&::after': {
                top: 'unset',
                width: '14px',
                content: '""',
                display: 'block',
                zIndex: 1,
                position: 'absolute',
                height: '1px',
                background: '#bbbbbb',
                marginTop: '-11px',
                marginLeft: '29px',
            },
        },
        DayCoachRight: {
            fontFamily: 'Montserrat',
            display: 'table-cell',
            padding: '0.5em',
            borderRadius: '50%',
            verticalAlign: 'middle',
            textAlign: 'center',
            border: '1.5px solid #F1F1F1',
            width: '38px !important',
            marginBottom: '7px',
            fontSize: '14px',
            fontWeight: 400,
            color: '#CDCDCD',
            '&::after': {
                top: 'unset',
                width: '14px',
                content: '""',
                display: 'block',
                zIndex: 1,
                position: 'absolute',
                height: '1px',
                background: '#bbbbbb',
                marginTop: '-11px',
                marginLeft: '-22px',
            },
        },
        dayCoachAvailable: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '8px',
            lineHeight: '120%',
            textAlign: 'center',
            marginBottom: '19px',
        },
        DayCoachActive: {
            background: '#41C6D5',
            fontWeight: 600,
            color: '#000000',
        },
        zipClass: {
            paddingLeft: '20px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0px',
            },
        },
        legendBooked: {
            fontFamily: 'Montserrat',
            display: 'table-cell',
            padding: '0.5em',
            borderRadius: '50%',
            verticalAlign: 'middle',
            textAlign: 'center',
            width: '38px !important',
            height: '38px !important',
            marginBottom: '7px',
            fontSize: '14px',
            fontWeight: 400,
            color: '#CDCDCD',
            background: 'rgba(0, 0, 0, 0.05)',
            '&::after': {
                top: 'unset',
                width: '14px',
                content: '""',
                display: 'block',
                zIndex: 1,
                position: 'absolute',
                height: '1px',
                background: '#fafafa',
                marginTop: '-11px',
                marginLeft: '29px',
            },
        },
        legendSelected: {
            fontFamily: 'Montserrat',
            display: 'table-cell',
            padding: '0.5em',
            borderRadius: '50%',
            verticalAlign: 'middle',
            textAlign: 'center',
            width: '38px !important',
            height: '38px !important',
            marginBottom: '7px',
            fontSize: '14px',
            fontWeight: 400,
            color: '#CDCDCD',
            background: '#FFA11E',
            '&::after': {
                top: 'unset',
                width: '14px',
                content: '""',
                display: 'block',
                zIndex: 1,
                position: 'absolute',
                height: '1px',
                background: '#fafafa',
                marginTop: '-11px',
                marginLeft: '29px',
            },
        },
    })
);
