import React, { createContext, useState } from 'react'

interface TabMenuProviderProps {
    value: string
}

export interface TabMenuContextInterface {
    currentTab: string
    setCurrentTab(newData: string): any
}

const defaultValue: TabMenuContextInterface = {
    currentTab: 'daily',
    setCurrentTab: (newData: string) => null,
}


const TabMenuContext = createContext<TabMenuContextInterface>(defaultValue)

export const TabMenuProvider: React.FC<TabMenuProviderProps> = props => {
    const [tab, setTab] = useState<string>(props.value)

    const value: TabMenuContextInterface = {
        currentTab: tab,
        setCurrentTab: (newData: string) => setTab(newData),
    }
    return <TabMenuContext.Provider value={value}>{props.children}</TabMenuContext.Provider>
}
export const TabMenuConsumer = TabMenuContext.Consumer

export default TabMenuContext
