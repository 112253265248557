import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function NotesIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const globalClasses = globalStyles();
    
    return (
        <SvgIcon {...props} viewBox="0 0 26 26" className={`${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}>
            <path d="M9.10677 1.48623C6.69777 1.60267 4.29369 1.82824 1.9019 2.16148C0.699367 9.70368 0.699367 16.8639 1.9019 24.4047C9.26114 25.4317 16.7389 25.4317 24.0981 24.4047C25.3006 16.8634 25.3006 9.7032 24.0981 2.16148C21.7063 1.82824 19.3022 1.60267 16.8932 1.48623M9.10677 1.48623V5.03006M9.10677 1.48623C11.7006 1.36005 14.2994 1.36005 16.8932 1.48623M9.10677 5.03006C9.10677 5.94013 9.85159 6.67823 10.77 6.67823H15.23C16.1484 6.67823 16.8932 5.94013 16.8932 5.03006M9.10677 5.03006L9.10683 4.36458H4.00024V22.2021H21.9999V4.36458H16.8933L16.8932 5.03006M9.10677 5.03006L9.10673 1.17493H16.8932L16.8932 5.03006M16.8932 5.03006V1.48623M7.00763 10.0316H18.8692M7.00763 14.0119H18.8692M7.00763 17.9923H18.8692" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
