import React, { FC, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    Paper,
    Box,
    Typography,
    Button,
    TextField,
    withStyles,
    Popper,
    Tabs,
    Tab,
    Menu,
    MenuItem,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Chip,
} from '@material-ui/core';
import { Column } from 'react-table';

import MTable from 'components/MTable';
import DateCell from 'components/MTable/Cells/DateCell';
import EllipsisCell from 'components/MTable/Cells/EllipsisCell';
import BookingAddNew from './BookingAddNew';
import BookingEdit from './BookingEdit';
import { ActionCell, DurationCell, GuestProfile } from './components/cells';
import { useStyles } from './styles';
import {
    getBookingPauseByName,
    getBookingsPause,
} from 'services/domain/admin/bookings';
import { startCaseToUpper } from 'services/util/common';
import { Autocomplete } from '@material-ui/lab';
import { SearchIcon } from 'components/icons';
import { getUserByFirstNameBooking } from 'services/domain/users/users';
import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BookingPauseData } from 'interfaces/booking';
import Calendar from 'pages/BookingsCoach/components/calendar/calendar';

type Booking = {
    _id: string;
    email: string;
    createdAt: string;
    startDate: string;
    paymentStatus: string;
};

const PopperMy = (props: any) => {
    const classes = useStyles();
    return (
        <Popper
            {...props}
            className={classes.popperStyle}
            placement="bottom-start"
        />
    );
};

const CssTextField = withStyles({
    root: {
        background: '#F8F8F8',
        boxShadow:
            'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
        borderRadius: '7px',
        paddingRight: '20px',
        '&:focus': {
            backgroundColor: 'white',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
        },
        '& label.Mui-focused': {
            color: 'transparent',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

const FocusStyle = {
    backgroundColor: '#ffffff',
    boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
};

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
interface ChipData {
    key: string;
    label: string;
    value?: string;
    date?: DateRange;
}

const Bookings: FC<{}> = () => {
    const [bookings, setBookings] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const classes = useStyles();
    const { action } = useParams<{ action: string }>();
    const [searchValue, setSearchValue] = useState<any>();
    const [open, setOpen] = React.useState(false);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [options, setOptions] = React.useState<any[]>([]);
    const [pageIndexMem, setPageIndexMem] = useState<any>(0);
    const [pageSizeMem, setPageSizeMem] = useState<any>(0);
    const [sortByMem, setSortByMem] = useState<any>('asc');
    const [refresh, setRefresh] = useState<boolean>(false);
    const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>();
    const [loading, setLoading] = useState<boolean>(false);
    const [requestDateOpen, setRequestDateOpen] = useState<boolean>(false);
    const [programStatusOpen, setProgramStatusOpen] = useState<boolean>(false);
    const [programStatus, setProgramStatus] = useState<string>();
    const [approvalStatus, setApprovalStatus] = useState<string>();
    const [approvalOpen, setApprovalOpen] = useState<boolean>(false);
    const [sortName, setSortName] = useState<number>(0);
    const [sortDate, setSortDate] = useState<number>(0);
    const tableComp = React.useRef<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [chipData, setChipData] = React.useState<ChipData[]>([]);

    const fetchBookings = useCallback(
        async ({
            pageIndex,
            pageSize,
            sortBy,
            programStatus,
            approvalStatus,
            name,
            startDate,
            endDate,
            requestDate,
        }: {
            pageIndex: any;
            pageSize: any;
            sortBy: any;
            programStatus?: string;
            approvalStatus?: string;
            name?: number;
            startDate?: string;
            endDate?: string;
            requestDate?: number;
        }) => {
            try {
                setLoading(true);
                let result;
                result = await getBookingsPause({
                    existData: pageIndex * pageSize,
                    limitData: pageSize,
                    requestStart: startDate,
                    requestEnd: endDate,
                    programStatus: programStatus,
                    approvalStatus: approvalStatus,
                    name: sortName === 1 ? sortName : undefined,
                    requestDate: requestDate || 0,
                });
                result.data.map((elm) => {
                    if (elm.startDate)
                        elm.startDate = elm.startDate.slice(0, 10);
                    if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
                    return elm;
                });
                setBookings(result.data);
                setPageCount(result.meta.pagination.totalPage);
                setPageIndexMem(pageIndex);
                setPageSizeMem(pageSize);
                setSortByMem(sortBy);
            } catch (e) {
                /**
                 * TODO: error handle here
                 */
            } finally {
                setLoading(false);
            }
        },
        // eslint-disable-next-line
        []
    );

    const fetchBookingsEmail = async ({
        pageIndex,
        pageSize,
        sortBy,
        firstName,
    }: {
        pageIndex: any;
        pageSize: any;
        sortBy: any;
        firstName: string;
    }) => {
        try {
            const result = await getBookingPauseByName(searchValue?.firstName);
            result.data.map((elm) => {
                if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
                if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
                return elm;
            });
            setBookings(result.data);
            setPageCount(result.data.length);
            setRefresh(false);
        } catch (e) {
            /**
             * TODO: error handle here
             */
        } finally {
            /**
             * TODO: final action here
             */
        }
    };

    const columns: Column<BookingPauseData>[] = useMemo(
        () => [
            {
                Header: 'Booking Id',
                accessor: '_id',
                Cell: EllipsisCell,
            },
            {
                Header: 'Guest Name',
                accessor: 'email',
                Cell: GuestProfile,
            },
            {
                Header: 'Request Date',
                accessor: 'createdAt',
                Cell: DateCell,
            },
            {
                Header: 'Program Status',
                accessor: 'statusBooking',
                Cell: (props) => startCaseToUpper(props.value),
            },
            {
                Header: 'Duration',
                accessor: 'startDate',
                Cell: DurationCell,
            },
            {
                Header: 'ACTION',
                Cell: (props: any) => (
                    <ActionCell actionEdit={handleEditAction} {...props} />
                ),
            },
        ],
        []
    );

    const handleEditAction = () => {
        if (tableComp && tableComp.current) tableComp.current.forceFetch();
    };

    if (action === 'add') {
        return <BookingAddNew />;
    }

    if (action === 'edit') {
        return <BookingEdit />;
    }

    const inputChange = async (event: any, value: any) => {
        try {
            setLoadingSearch(true);
            const payload = {
                firstName: value,
            };
            const rspn = await getUserByFirstNameBooking(payload);
            setOptions(rspn.data);
        } catch (error) {
        } finally {
            setLoadingSearch(false);
        }
    };

    const onChangeValue = (event: any, value: any) => {
        setSearchValue(value);
        setChipData([]);
        if (value !== null) {
            setRefresh(true);
            setTimeout(() => {
                fetchBookingsEmail({
                    pageIndex: 0,
                    pageSize: 10,
                    sortBy: sortByMem,
                    firstName: value?.firstName,
                });
            }, 100);
        } else {
            if (tableComp && tableComp.current) tableComp.current.forceFetch();
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseName = () => {
        setSortDate(0);
        setSortName(1);
        setAnchorEl(null);
    };
    const handleCloseDate = () => {
        setSortDate(1);
        setSortName(0);
        setAnchorEl(null);
    };

    const onDateFilterChange = (data: DateRange) => {
        const origin = chipData;
        const index = origin.findIndex((item) => item.key === 'requestDate');
        if (index === -1) {
            origin.push({
                key: 'requestDate',
                date: data,
                label: `Request Date: ${moment(data.startDate).format(
                    'MMM-DD-YYYY'
                )}-${moment(data.endDate).format('MMM-DD-YYYY')}`,
            });
        } else {
            origin[index].label = `Request Date: ${moment(
                data.startDate
            ).format('MMM-DD-YYYY')}-${moment(data.endDate).format(
                'MMM-DD-YYYY'
            )}`;
            origin[index].date = data;
        }
        setChipData(origin);
        setDateRangeFilter(data);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const origin = chipData;
        const index = origin.findIndex((item) => item.key === 'programStatus');
        if (index === -1) {
            origin.push({
                key: 'programStatus',
                value: event.target.value,
                label: `Program Status: ${event.target.value}`,
            });
        } else {
            origin[index].label = `Program Status: ${event.target.value}`;
            origin[index].value = event.target.value;
        }
        setChipData(origin);
        setProgramStatus(event.target.value);
    };

    const handleChangeApproval = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const origin = chipData;
        const index = origin.findIndex((item) => item.key === 'approval');
        if (index === -1) {
            origin.push({
                key: 'approval',
                value: event.target.value,
                label: `Approval: ${event.target.value}`,
            });
        } else {
            origin[index].label = `Approval: ${event.target.value}`;
            origin[index].value = event.target.value;
        }
        setChipData(origin);
        setApprovalStatus(event.target.value);
    };

    const requestDateOnClick = () => {
        setRequestDateOpen(!requestDateOpen);
        setProgramStatusOpen(false);
        setApprovalOpen(false);
    };

    const programOnClick = () => {
        setProgramStatusOpen(!programStatusOpen);
        setRequestDateOpen(false);
        setApprovalOpen(false);
    };

    const approvalOnClick = () => {
        setApprovalOpen(!approvalOpen);
        setProgramStatusOpen(false);
        setRequestDateOpen(false);
    };

    const handleDelete = (chipToDelete: ChipData) => () => {
        const origin = chipData;
        const newData = origin.filter((chip) => chip.key !== chipToDelete.key);
        setChipData(newData);
        setChipData((chips) =>
            chips.filter((chip) => chip.key !== chipToDelete.key)
        );
        if (chipToDelete.key === 'requestDate') {

            setDateRangeFilter(undefined);
            setRequestDateOpen(false);
        }
        if (chipToDelete.key === 'programStatus') {

            setProgramStatus('');
            setProgramStatusOpen(false);
        }
        if (chipToDelete.key === 'approval') {

            setApprovalStatus('');
            setApprovalOpen(false);
        }
        setTimeout(() => {
            const date = newData.find((item) => item.key === 'requestDate');
            const program = newData.find(
                (item) => item.key === 'programStatus'
            );
            const approval = newData.find((item) => item.key === 'approval');
            fetchBookings({
                pageIndex: pageIndexMem,
                pageSize: pageSizeMem,
                sortBy: sortByMem,
                startDate:
                    (date &&
                        moment(date.date?.startDate).format('YYYY-MM-DD')) ||
                    undefined,
                endDate:
                    (date && moment(date.date?.endDate).format('YYYY-MM-DD')) ||
                    undefined,
                programStatus: (program && program.value) || undefined,
                approvalStatus: (approval && approval.value) || undefined,
                name: sortName === 1 ? sortName : undefined,
                requestDate: sortDate === 1 ? sortDate : undefined,
            });
        }, 100);
    };

    const onFilter = () => {
        const date = chipData.find((item) => item.key === 'requestDate');
        const program = chipData.find((item) => item.key === 'programStatus');
        const approval = chipData.find((item) => item.key === 'approval');
        fetchBookings({
            pageIndex: pageIndexMem,
            pageSize: pageSizeMem,
            sortBy: sortByMem,
            startDate:
                (date && moment(date.date?.startDate).format('YYYY-MM-DD')) ||
                undefined,
            endDate:
                (date && moment(date.date?.endDate).format('YYYY-MM-DD')) ||
                undefined,
            programStatus: (program && program.value) || undefined,
            approvalStatus: (approval && approval.value) || undefined,
            name: sortName === 1 ? sortName : undefined,
            requestDate: sortDate === 1 ? sortDate : undefined,
        });
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4">Manage Bookings</Typography>
            </Box>

            <Box mt={3}>
                <Paper>
                    <Tabs
                        value={0}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        aria-label="manage bookings coach"
                        style={{ borderBottom: 'solid 1px #dddddd61' }}
                    >
                        <Tab label="Coach" />
                    </Tabs>
                    <TabPanel value={0} index={0}>
                        <Box display="flex">
                            <Box>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className={classes.buttonSort}
                                >
                                    <ArrowDownwardIcon color="primary" />
                                    <ArrowUpwardIcon color="primary" />
                                    &nbsp; &nbsp;
                                    <ExpandMoreIcon color="primary" />
                                </Button>
                            </Box>
                            <Box
                                flexGrow={1}
                                display="flex"
                                flexDirection="row-reverse"
                                paddingLeft={1}
                            >

                                <Autocomplete
                                    value={searchValue}
                                    size="small"
                                    freeSolo
                                    id="autocomplete-search"
                                    style={{ width: '100%' }}
                                    // open={true}
                                    onOpen={() => {
                                        inputChange(null, null);
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    onChange={onChangeValue}
                                    onInputChange={inputChange}
                                    // getOptionSelected={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) =>
                                        option.firstName + ' ' + option.lastName
                                    }
                                    options={options}
                                    loading={loadingSearch}
                                    PopperComponent={PopperMy}
                                    renderInput={(params) => (
                                        <CssTextField
                                            placeholder="Search for ‘something’"
                                            {...params}
                                            variant="outlined"
                                            style={open ? FocusStyle : {}}
                                        />
                                    )}
                                />
                                <SearchIcon
                                    style={{
                                        position: 'absolute',
                                        marginTop: '9px',
                                        right: '75px',
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" mt={2}>
                            <Box>
                                <Button
                                    className={classes.buttonSort}
                                    onClick={() => requestDateOnClick()}
                                >
                                    <div className={classes.buttonFont}>
                                        Request Date
                                    </div>
                                </Button>
                            </Box>
                            <Box ml={1}>
                                <Button
                                    className={classes.buttonSort}
                                    onClick={() => programOnClick()}
                                >
                                    <div className={classes.buttonFont}>
                                        Program Status
                                    </div>
                                    &nbsp; &nbsp;
                                    <ExpandMoreIcon color="primary" />
                                </Button>
                            </Box>
                            <Box ml={1}>
                                <Button
                                    className={classes.buttonSort}
                                    onClick={() => approvalOnClick()}
                                >
                                    <div className={classes.buttonFont}>
                                        Approval
                                    </div>
                                    &nbsp; &nbsp;
                                    <ExpandMoreIcon color="primary" />
                                </Button>
                            </Box>

                        </Box>
                        <Box display="flex" mt={2}>
                            {requestDateOpen && (
                                <Calendar
                                    dateRangeValues={dateRangeFilter}
                                    onCalendarChange={(data) =>
                                        onDateFilterChange(data)
                                    }
                                />
                            )}
                            {programStatusOpen && (
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        onChange={handleChange}
                                        row
                                        aria-label="gender"
                                        name="row-radio-buttons-group"
                                        value={programStatus}
                                    >
                                        <FormControlLabel
                                            value="Active"
                                            control={<Radio color="primary" />}
                                            label="Active"
                                        />
                                        <FormControlLabel
                                            value="Waiting"
                                            control={<Radio color="primary" />}
                                            label="Waiting"
                                        />
                                        <FormControlLabel
                                            value="Inactive"
                                            control={<Radio color="primary" />}
                                            label="Inactive"
                                        />
                                        <FormControlLabel
                                            value="Pause"
                                            control={<Radio color="primary" />}
                                            label="Pause"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                            {approvalOpen && (
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        onChange={handleChangeApproval}
                                        row
                                        aria-label="gender"
                                        name="row-radio-buttons-group"
                                        value={approvalStatus}
                                    >
                                        <FormControlLabel
                                            value="Need Approval"
                                            control={<Radio color="primary" />}
                                            label="Need Approval"
                                        />
                                        <FormControlLabel
                                            value="Approved"
                                            control={<Radio color="primary" />}
                                            label="Approved"
                                        />
                                        <FormControlLabel
                                            value="Decline"
                                            control={<Radio color="primary" />}
                                            label="Decline"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Box>
                        <Box
                            component="ul"
                            display="flex"
                            justifyContent="start"
                            flexWrap="wrap"
                            pl={0}
                            mt={2}
                            style={{ listStyle: 'none' }}
                        >
                            {chipData.map((data) => (
                                <li key={data.key}>
                                    <Chip
                                        style={{ marginLeft: '10px' }}
                                        label={data.label}
                                        onDelete={handleDelete(data)}
                                    />
                                </li>
                            ))}
                        </Box>
                    </TabPanel>
                </Paper>
            </Box>
            <Box mt={3} textAlign="right" width="100%">
                <Button
                    disabled={chipData.length === 0}
                    color="primary"
                    variant="contained"
                    onClick={onFilter}
                >
                    Save
                </Button>
            </Box>

            <Box mt={3}>
                <Paper>
                    <MTable
                        columns={columns}
                        data={bookings}
                        onFetchData={
                            searchValue ? fetchBookingsEmail : fetchBookings
                        }
                        pageCount={pageCount}
                        refreshPage={refresh}
                        loading={loading}
                        ref={tableComp}
                    />
                </Paper>
            </Box>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleCloseName} selected={sortName === 1}>
                    Sort by Name
                </MenuItem>
                <MenuItem onClick={handleCloseDate} selected={sortDate === 1}>
                    Sort by Recent Request Date
                </MenuItem>
            </Menu>
        </>
    );
};

export default Bookings;
