import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function WeeklyAssessmentIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99967 6.66667C5.38481 6.66667 4.83934 6.86453 4.47558 7.15554C4.11954 7.44037 3.99967 7.75462 3.99967 8V26.6667C3.99967 26.912 4.11954 27.2263 4.47558 27.5111C4.83934 27.8021 5.38481 28 5.99967 28H25.9997C26.6145 28 27.16 27.8021 27.5238 27.5111C27.8798 27.2263 27.9997 26.912 27.9997 26.6667V8C27.9997 7.75462 27.8798 7.44037 27.5238 7.15554C27.16 6.86453 26.6145 6.66667 25.9997 6.66667H22.6663C21.93 6.66667 21.333 6.06971 21.333 5.33333C21.333 4.59695 21.93 4 22.6663 4H25.9997C27.1529 4 28.3031 4.36404 29.1896 5.07322C30.0838 5.78859 30.6663 6.83089 30.6663 8V26.6667C30.6663 27.8358 30.0838 28.8781 29.1896 29.5934C28.3031 30.3026 27.1529 30.6667 25.9997 30.6667H5.99967C4.84642 30.6667 3.69621 30.3026 2.80972 29.5934C1.91552 28.8781 1.33301 27.8358 1.33301 26.6667V8C1.33301 6.83089 1.91552 5.78859 2.80972 5.07322C3.69621 4.36404 4.84642 4 5.99967 4H9.33301C10.0694 4 10.6663 4.59695 10.6663 5.33333C10.6663 6.06971 10.0694 6.66667 9.33301 6.66667H5.99967Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6667 4.08916V6.57747C10.7216 6.61615 10.8347 6.66665 11 6.66665H21C21.1653 6.66665 21.2784 6.61615 21.3333 6.57747V4.08916C21.2784 4.05047 21.1653 3.99998 21 3.99998H11C10.8347 3.99998 10.7216 4.05047 10.6667 4.08916ZM8 3.99998C8 2.26496 9.63616 1.33331 11 1.33331H21C22.3638 1.33331 24 2.26496 24 3.99998V6.66665C24 8.40167 22.3638 9.33331 21 9.33331H11C9.63616 9.33331 8 8.40167 8 6.66665V3.99998Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 13.3333C6.66602 12.597 7.26297 12 7.99935 12H9.33268C10.0691 12 10.666 12.597 10.666 13.3333C10.666 14.0697 10.0691 14.6667 9.33268 14.6667H7.99935C7.26297 14.6667 6.66602 14.0697 6.66602 13.3333Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 18.6666C6.66602 17.9303 7.26297 17.3333 7.99935 17.3333H9.33268C10.0691 17.3333 10.666 17.9303 10.666 18.6666C10.666 19.403 10.0691 20 9.33268 20H7.99935C7.26297 20 6.66602 19.403 6.66602 18.6666Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 24C6.66602 23.2636 7.26297 22.6667 7.99935 22.6667H9.33268C10.0691 22.6667 10.666 23.2636 10.666 24C10.666 24.7364 10.0691 25.3334 9.33268 25.3334H7.99935C7.26297 25.3334 6.66602 24.7364 6.66602 24Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 14.6667H13.333V12H23.9997V14.6667Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 20H13.333V17.3333H23.9997V20Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 25.3334H13.333V22.6667H23.9997V25.3334Z"
                fill="#6D6D6D"
            />
        </SvgIcon>
    );
}
