import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CardIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            fill="none"
            viewBox="0 0 16 16"
            {...props}
            style={{
                color: 'white',
                width: props.width || '16px',
                height: props.height || '16px',
            }}
        >
            <path
                d="M13.3333 2.6665H2.66666C1.92666 2.6665 1.33999 3.25984 1.33999 3.99984L1.33333 11.9998C1.33333 12.7398 1.92666 13.3332 2.66666 13.3332H13.3333C14.0733 13.3332 14.6667 12.7398 14.6667 11.9998V3.99984C14.6667 3.25984 14.0733 2.6665 13.3333 2.6665ZM13.3333 11.9998H2.66666V7.99984H13.3333V11.9998ZM13.3333 5.33317H2.66666V3.99984H13.3333V5.33317Z"
                fill="#515151"
            />
        </SvgIcon>
    );
}
