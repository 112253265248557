import React, { FC } from 'react';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
        >
            {
                value === index
                && (
                    <Box>
                        {children}
                    </Box>
                )
            }
        </div>
    );
};

export default TabPanel;
