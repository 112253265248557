import React, { FC } from 'react';
import { createStyles, makeStyles, Modal, Theme } from '@material-ui/core';
interface Props {
    show: boolean;
    image: string;
    onClose: () => void;
}
  
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
  
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
        position: 'absolute',
        width: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        },
    }),
);


const ImageModal: FC<Props> = ({ show, image, onClose }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const handleClose = () => {
        onClose()
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <img src={image} alt=''/>
            </div>
        </Modal>
    );
};
export default ImageModal;