import React from 'react';
// import { SignUpLayout } from 'components/layout/containers';

import { useStyles } from './styles';
// import { useHistory } from 'react-router-dom';
import Feedback from 'components/layout/Feedback';
import { Avatar, Box, CircularProgress, Container, Grid, Paper, Typography, LinearProgress } from '@material-ui/core';
import movaraLogoWhite from 'assets/images/movara-home-logo-white.png';
import illustration from 'assets/images/continueonboard.png';
import auth from 'services/domain/auth';
import Button from 'components/_newDesign/button/Button'
import Timeline from './component/Timeline';

interface Step {
    step1: boolean
    step2: boolean
    step3: boolean
}

const ContinueOnboarding = (props) => {
    const classes = useStyles();
    const user = auth.userProperties.userData;
    const [step, setStep] = React.useState<Step>({
        step1: false,
        step2: false,
        step3: false,
    });

    const changeStarted = () => {
        props.changeStarted()
    }

    const checkOnboarding = () => {
        const values = {...step}
        if (props.data.step1.bio !== undefined && props.data.step1.displayName !== undefined) values.step1 = true
        if (props.data.step2 !== null ) values.step2 = true
        if (props.data.step3 !== null) values.step3 = true
        setStep(values)

    }

    React.useEffect(() => {
        if (props.data) checkOnboarding()
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <>
            <Container maxWidth="md">
                <Box margin="auto" mb={10} mt={10} textAlign="center" maxWidth="800px">
                    <img src={movaraLogoWhite} alt="movara's logo" width="50%"/>
                </Box>
                <Box my={10}>
                    <Paper style={{backgroundColor: '#FAFAFA'}}>
                        <Box
                            py={7}
                        >
                            {props.loading ? (
                                <Box display="flex"
                                    pt="10"
                                    className={classes.loadingPost}
                                    alignItems="center"
                                    justifyContent="center">
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Box mt={2} className={classes.onBoardTitle} textAlign={'center'}>
                                        <Typography className={classes.profileTitle}>
                                            Check in with the numbers, then say hello to your new favorite community!
                                        </Typography>
                                    </Box>
                                    <Box mt={3}>
                                        <img src={illustration} alt="movara's logo" width="100%" style={{maxWidth: '300px'}}/>
                                    </Box>
                                    <Box mt={3}>
                                        <Typography className={classes.profileTitle}>
                                            {user.displayName ? 'Hey ' + user.displayName : ''}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Timeline type="second"/>
                                    </Box>
                                    <Box mt={3} px={22} className={classes.onBoardDesc}>
                                        <Typography className={classes.profileDesc}>
                                            Let's continue where you left off with the onboarding process to have an early access to the platform!
                                        </Typography>
                                    </Box>
                                    <Box mt={5} width={'100%'} px={16} className={classes.onBoardDesc}>
                                        <Grid container style={{justifyContent: "center"}} spacing={2}>
                                            <Grid item xs={4} style={{justifyContent: "center"}}>
                                                <Box>
                                                    <Avatar className={` ${ step.step1 ? classes.newStepCircleDone : classes.newStepCircle}`}>1</Avatar>
                                                </Box>
                                                <Box mt={2}>
                                                    <LinearProgress style={{height: '12px', borderRadius: '6px'}} variant="determinate" color={step.step1 ? 'primary': 'secondary'} value={step.step1 ? 100 : 0}/>
                                                </Box>
                                                <Box textAlign={'center'} mt={2}>
                                                    <div className={classes.newStepDesc}>Account Set Up</div>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Avatar className={` ${ step.step2 ? classes.newStepCircleDone : classes.newStepCircle}`}>2</Avatar>
                                                </Box>
                                                <Box mt={2}>
                                                    <LinearProgress style={{height: '12px', borderRadius: '6px'}} variant="determinate" color={step.step2 ? 'primary': 'secondary'} value={step.step2 ? 100 : 0}/>
                                                </Box>
                                                <Box textAlign={'center'} mt={2}>
                                                    <div className={classes.newStepDesc}>Submit Weekly Assessment</div>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box>
                                                    <Avatar className={` ${ step.step3 ? classes.newStepCircleDone : classes.newStepCircle}`}>3</Avatar>
                                                </Box>
                                                <Box mt={2}>
                                                    <LinearProgress style={{height: '12px', borderRadius: '6px'}} variant="determinate" color={step.step3 ? 'primary': 'secondary'} value={step.step3 ? 100 : 0}/>
                                                </Box>
                                                <Box textAlign={'center'} mt={2}>
                                                    <div className={classes.newStepDesc} style={{ fontWeight: 600}}>Post to Community Page</div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mt={3}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            onClick={changeStarted}
                                        >
                                            Continue Onboarding
                                        </Button>
                                    </Box>

                                </Grid>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Container>
        <Feedback />
        </>
    );
};

export default ContinueOnboarding;
