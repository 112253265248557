import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function LocationIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path
                d="M3.5 5.18263C3.5 9.67503 8.18263 15.5 8.18263 15.5C8.18263 15.5 12.8653 9.75137 12.8653 5.18263C12.8653 2.59627 10.7687 0.5 8.18263 0.5C5.59657 0.5 3.5 2.59627 3.5 5.18263Z"
                stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M8.18244 7.83534C9.61591 7.83534 10.778 6.67328 10.778 5.23981C10.778 3.80634 9.61591 2.64429 8.18244 2.64429C6.74897 2.64429 5.58691 3.80634 5.58691 5.23981C5.58691 6.67328 6.74897 7.83534 8.18244 7.83534Z"
                stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
