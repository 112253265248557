import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface Props {
    value: any;
    handleChange: (event: React.ChangeEvent<{}>, val: any) => void;
}

function a11yProps(index: any) {
    return {
        id: `videos-tab-${index}`,
        'aria-controls': `videos-tabpanel-${index}`,
    };
}

const VideoTab: React.FC<Props> = ({ value, handleChange }) => {
    return (
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
        >
            <Tab value="course" label="Course Videos" {...a11yProps('coures')} />
            <Tab value="cooking" label="Cooking Videos" {...a11yProps('cooking')} />
        </Tabs>
    );
}

VideoTab.defaultProps = {
    value: 'all',
};

export default VideoTab;
