import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowRightIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 29 29"
            style={{
                color: props.color || 'black',
                width: props.width || '29px',
                height: props.height || '29px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7929 9.29289C12.1834 8.90237 12.8166 8.90237 13.2071 9.29289L17.2071 13.2929C17.5976 13.6834 17.5976 14.3166 17.2071 14.7071L13.2071 18.7071C12.8166 19.0976 12.1834 19.0976 11.7929 18.7071C11.4024 18.3166 11.4024 17.6834 11.7929 17.2929L15.0858 14L11.7929 10.7071C11.4024 10.3166 11.4024 9.68342 11.7929 9.29289Z"
                fill="#515151"
            />
        </SvgIcon>
    );
}
