import React, { FC } from 'react';
import {
    Box,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Modal,
    Theme,
} from '@material-ui/core';
import { CloseIcon } from 'components/icons';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AmountCalories from 'components/_modules/NutritionGoal/AmountCalories';
import TrackMacros from 'components/_modules/NutritionGoal/TrackMarcos';
import Intermittan from 'components/_modules/NutritionGoal/Intermittan';
import BalancedMeals from 'components/_modules/NutritionGoal/BalancedMeals';
import OzWater from 'components/_modules/NutritionGoal/OzWater';
import NoSnacking from 'components/_modules/NutritionGoal/NoSnacking';
import Eliminate from 'components/_modules/NutritionGoal/Eliminate';
import Other from 'components/_modules/NutritionGoal/Other';
import Typography from 'components/_newDesign/typography/Typography';
interface Props {
    show: boolean;
    value?: string;
    onClose: () => void;
    title: string;
    type:
        | 'amount_of_calories'
        | 'balanced_meals'
        | 'eliminate'
        | 'intermittent_fasting'
        | 'no_snacking'
        | 'other'
        | 'oz_of_water'
        | 'track_macros';
    amountCalories?: number;
    ozOfWater?: number;
    otherText?: string;
    eliminateText?: string;
    preview: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 0, 2),
            width: '623px',
            maxWidth: '623px',
            maxHeight: '95%',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
            },
            borderRadius: '20px',
        },
        header: {
            // width: '805px',
            // maxWidth: '805px',
        },
        'header-item': {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '36px',
            lineHeight: '100%',
            color: '#000000',
        },
        content: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#000000',
        },
        'upload-text': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '150%',
            /* identical to box height, or 36px */

            letterSpacing: ' 0.15px',

            color: '#000000',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
        },
        modalContent: {
            padding: '0px 24px 16px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 16px 0px',
            },
        }
    })
);

const WaitingModal: FC<Props> = ({ show, preview, onClose, title, type }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        onClose();
    };
    const renderContent = (value) => {
        switch (value) {
            case 'amount_of_calories':
                return <AmountCalories active={false} preview={preview}/>;
            case 'balanced_meals':
                return <BalancedMeals active={false} preview={preview} />;
            case 'eliminate':
                return <Eliminate active={false} preview={preview} />;
            case 'intermittent_fasting':
                return <Intermittan active={false} preview={preview} />;
            case 'no_snacking':
                return <NoSnacking active={false} preview={preview} />;
            case 'other':
                return <Other active={false} preview={preview} />;
            case 'oz_of_water':
                return <OzWater active={false} preview={preview} />;
            case 'track_macros':
                return <TrackMacros active={false} preview={preview} />;
            default:
                return null;
        }
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            style={{ maxHeight: '100vh' }}
            className={classes.modal}
            BackdropProps={{ style: {backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(10px)'} }}
        >
            <div className={classes.paper}>
                <Grid
                    container
                    className={classes.header}
                    style={{ padding: '0px 20px' }}
                >
                    <Grid item xs={9} className={classes['header-item']}>
                        <Typography className={classes['upload-text']}>
                            {title} Preview
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        className={classes['header-item']}
                        style={{ justifyContent: 'flex-end' }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <hr
                    style={{
                        backgroundColor: '#E6E6E6',
                        height: '1px',
                        border: 'none',
                    }}
                />
                <Box padding={"24px 30px 0px 30px"}>
                    <Typography variant="mov_body1">
                        The screen is only preview.
                    </Typography>
                    <Typography variant="mov_body1">
                        {`${matches ? 'Tap': 'Click'}`} anywhere outside this box to close this view.
                    </Typography>
                </Box>
                <Box width={'100%'} className={classes.modalContent} mt="32px">
                    {renderContent(type)}
                </Box>

            </div>
        </Modal>
    );
};
export default WaitingModal;
