import React from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { InfoIcon, CloseIcon } from 'components/icons';

const useStyles = makeStyles((theme: Theme) => ({
    coverInfo: {
        background: 'white',
        borderRadius: '10px',
        display: 'flex',
        boxShadow: '0px 1.25px 4px rgba(0, 0, 0, 0.25)',
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s, opacity 0.5s linear',
        height: 0,
    },
    displayBlock: {
        visibility: 'visible',
        opacity: 1,
        height: 'auto',
        padding: '5px 10px',
    }
}));
interface InfoAlertProps {
    text: string
    isOpen?: boolean
    closeable?: boolean
    onClose?: () => void
}
const InfoAlert: React.FC<InfoAlertProps> = ({text, closeable, isOpen, onClose}) => {
    const classes = useStyles()
    const [close, setClose] = React.useState<boolean>(false)

    let buttonClose = false
    if (closeable) buttonClose = true

    const onCloseButton = () =>{
        setClose(true)
        if (onClose !== undefined) onClose()
    } 
    React.useEffect(() => {
        if (isOpen !== undefined) setClose(!isOpen)
    },[isOpen])
    return ( 
        <div  className={`${ classes.coverInfo} ${!close && classes.displayBlock}`}> 
            <InfoIcon style={{marginRight: 5}}/> 
            <Typography>{text}</Typography> 
            {buttonClose && (
                <IconButton style={{marginLeft: 'auto'}} onClick={()=> onCloseButton()}>
                    <CloseIcon size={14} />
                </IconButton>
            )}
        </div>
    );
};

export default InfoAlert