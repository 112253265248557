import React from 'react';
import { Avatar, Card, CardHeader } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles'
import dummyAvatar from 'assets/images/dummy-avatar-256.png';

const useStyles = makeStyles(( theme: Theme ) => ({
    root: {
        backgroundColor: 'transparent',
        '& .MuiAvatar-root': {
            backgroundColor: theme.palette.primary.main,
        }
    },
}));

const GuestProfile = ({ value, row }: { value: any, row: any }) => {
    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.root}>
            <CardHeader
                avatar={<Avatar src={dummyAvatar} />}
                title={`${row.original.firstName ?? ''} ${row.original.lastName ?? ''}`}
                subheader={value ?? ''}
            />
        </Card>
    );
};

export default GuestProfile;
