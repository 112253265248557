import { http } from 'services/data';
import { PaginationInterface } from 'interfaces/common';

const endPoints = {
    dailyTotalsAll: 'admin/accounting/daily-totals',
    coachReports: 'admin/accounting/coach-reports',
};

export const getDailyTotals = (payload: PaginationInterface) =>
    http.get(endPoints.dailyTotalsAll, { params: payload });
export const getCoachReports = (payload: PaginationInterface) =>
    http.get(endPoints.coachReports, { params: payload });
