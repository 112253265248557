import React, { ReactNode } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import TopBar from '../TopBar/TopBar';
import Footer from 'components/layout/Footer';
import Feedback from 'components/layout/Feedback';

interface Props {
    children: ReactNode;
}

const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(180deg, rgba(255, 162, 30, 0) 0%, rgba(255, 162, 30, 0.5) 61.11%, rgba(65, 65, 65, 0.8) 100%)',
    },
});

const SingleLayout = ({ children }: Props) => {
    const classes = useStyles();

    return (
        <Container
            className={classes.root}
            maxWidth={false}
            disableGutters
        >
            <TopBar disableBackButton={false}/>
            {children}
            <Feedback />
            <Footer />
        </Container>
    );
};

export default SingleLayout;