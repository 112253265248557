import React, { FC } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FormikHelpers } from "formik";

import movaraLogo from "assets/images/movara-home-logo.png";

import { IRegisterForm } from "services/domain/users";

interface Props {
  onSubmit: (value: IRegisterForm, { setSubmitting }: FormikHelpers<IRegisterForm>) => void;
}

const SignUpForm: FC<Props> = (props) => {
  return (
    <Container maxWidth="lg">
      <Paper>
        <Box px={42.5} pt={10} pb={22.5} my={10}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={5} display="flex" justifyContent="center">
                <img src={movaraLogo} alt="movara's logo" />
              </Box>
            </Grid>
            {props.children}
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUpForm;
