import React, { useEffect, useState } from "react";
import { getPointLogs, PointsDetail, redeemPoints } from "services/domain/users";
import { Waypoint } from "react-waypoint";
import { Box, Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Divider, Grid, GridList, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import moment from "moment";
import { InfoOutlined } from "@material-ui/icons";
import { ExpandMoreIcon } from "components/icons";
import PointsRedeemModal from "components/_modules/PointsRedeem/PointsRedeemModal";
import PointsWebBackground from "assets/images/points-background-web.png";
import PointsMbBackground from "assets/images/points-background-mobile.png";
import EarnPointsInfoModal from "components/_modules/EarnPointsInfo";
import ButtonMui from "components/_newDesign/button/Button";
import { Toast } from "services/util/toast";

const PointsLogCard: React.FC<any> = (props) => {
  const [activity, setActivity] = React.useState<PointsDetail[]>([]);
  const [offSet, setOffset] = React.useState<number>(0);
  const [limitData] = React.useState<number>(15);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
  const [scrollLoading, setScrollLoading] = React.useState<boolean>(false);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const [showRedeemInfo, setShowRedeemInfo] = useState<boolean>(false);
  const [showEarnPointsInfo, setEarnPointsInfo] = useState<boolean>(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [notEnoughPoints, setNotEnoughPoints] = React.useState<boolean>(false);
  const [redeemingItem, setRedeemingItem] = React.useState<boolean>(false);
  const [checkedItem, setCheckedItem] = React.useState<string>("");
  const [checkedItemPoints, setCheckedItemPoints] = React.useState<number>(0);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleSubmitItem = async () => {
    const userCurrentPoints = props.profile.totalPoints;

    if (checkedItemPoints > userCurrentPoints) {
      setNotEnoughPoints(true);
      return;
    }

    setRedeemingItem(true);
    const toast = Toast({});

    try {
      const payload = {
        userId: props.profile._id,
        newPoints: userCurrentPoints - checkedItemPoints,
        displayName: props?.profile?.displayName || "",
        name: `${props?.profile?.name.firstName} ${props?.profile?.name.lastName}`,
        email: props?.profile?.email || "",
        address: props?.profile?.address || "",
        selectedItem: checkedItem,
        points: checkedItemPoints,
      };

      const redeemPointsRepso = await redeemPoints(payload);
      await props.getProfile();

      setActivity([redeemPointsRepso.data.pointLog, ...activity]);

      setShowRedeemInfo(false);
      setCheckedItem("");
      setCheckedItemPoints(0);
      setRedeemingItem(false);

      toast.fire({
        icon: "success",
        title: `You have successfully claimed your item (${checkedItem}) for ${checkedItemPoints} points!
                You have ${userCurrentPoints - checkedItemPoints} points left.`,
        timer: 7000,
      });
    } catch (error) {
      console.error(error);
      toast.fire({
        icon: "error",
        title: `Failed to claim your item! Please try again. 
                If continues please contact movara support.`,
        timer: 7000,
      });
    }
  };

  const init = async () => {
    try {
      setScrollLoading(true);
      const payload = {
        userId: props.profile._id,
        existData: offSet,
        limitData: limitData,
        year: moment().year(),
      };

      const rspn = await getPointLogs(payload);
      setOffset(offSet + limitData);
      setActivity(rspn.data.logs);
    } catch (error) {
    } finally {
      setScrollLoading(false);
    }
  };

  const loadMore = async () => {
    try {
      if (!hasNextPage) {
        return;
      }
      setScrollLoading(true);
      const payload = {
        userId: props.profile._id,
        existData: offSet,
        limitData: limitData,
        year: moment().year(),
      };
      const rspn = await getPointLogs(payload);
      if (rspn.data.logs.length === 0) {
        setHasNextPage(false);
        return;
      }
      const values = [...activity, ...rspn.data.logs];
      setOffset(offSet + limitData);
      setActivity(values);
    } catch (error) {
    } finally {
      setScrollLoading(false);
    }
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (offSet === 0 && hasNextPage) {
      init();
    }
    // eslint-disable-next-line
  }, [offSet, hasNextPage]);
  return (
    <Box mt={3}>
      <>
        {" "}
        {/* Total points earned */}
        <Grid container direction="column">
          <Box mt={3} minHeight="120px" alignItems={"middle"}>
            <Box
              mx={3}
              style={{
                backgroundImage: matches ? `url(${PointsMbBackground})` : `url(${PointsWebBackground})`,
                height: "116px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "auto",
              }}
            >
              <Grid item>
                <Typography align="center" className={classes.pointsValue}>
                  {props.profile.totalPoints}
                </Typography>
              </Grid>
              <Box height="8px"></Box>
              <Grid item>
                <Typography align="center" className={classes.pointsLabel}>
                  Points earned
                </Typography>
              </Grid>
            </Box>

            <Grid item>
              <Box mt={1}>
                <Divider></Divider>
              </Box>
            </Grid>
            <Grid item>
              <Box my={2} justifyContent="center" alignItems="center" display={"flex"}>
                <ButtonMui variant="outlined" style={{ textTransform: "none" }} onClick={() => setEarnPointsInfo(true)}>
                  <InfoOutlined />
                  <Typography className={classes.earnPointsHeader}> How to earn points? </Typography>
                </ButtonMui>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </>
      <>
        {" "}
        {/* Points log */}
        <Box>
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            Points Log
          </Typography>
          <Accordion expanded={expanded} onChange={() => handleChange()}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`content-${moment().year()}`} id="content-header" className={classes.cardHeaderBox}>
              <Typography className={classes.cardHeader}>{moment().year()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={"100%"}>
                <GridList cellHeight={480}>
                  <ul className={classes.timeline}>
                    {activity.map((item, index) => {
                      return (
                        <li key={index}>
                          <Waypoint
                            key={index}
                            onEnter={() => {
                              if (index + 1 === activity.length) loadMore();
                            }}
                          >
                            <Box display={"flex"} flexDirection={"row"} justifyContent="space-between">
                              <Box minWidth={"80%"}>
                                <Typography style={{ color: "#282828", fontWeight: 600 }}>{`${item?.activity}`}</Typography>
                                <Typography className={classes.timelineTimeLabel} style={{ color: "#282828", fontWeight: 400 }}>{`${moment(item?.createdAt).format(
                                  "MMM/DD/YYYY",
                                )}`}</Typography>
                              </Box>
                              <Box
                                minWidth={"20%"}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  align="right"
                                  style={{ fontSize: "16px", paddingRight: "5px", color: item.points.toString().includes("-") ? "#EF5136" : "#849733", fontWeight: 600 }}
                                >
                                  {item?.points}
                                </Typography>
                              </Box>
                            </Box>
                          </Waypoint>
                        </li>
                      );
                    })}
                    {scrollLoading && (
                      <Box display="flex" pb={1} width="100%" alignItems="center" justifyContent="center">
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                  </ul>
                </GridList>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </>
      <>
        {" "}
        {/* Redeem Points */}
        <Box my={3} alignContent="center">
          <Button
            onClick={() => setShowRedeemInfo(true)}
            style={{
              width: "100%",
              backgroundColor: "#FFA11E",
              textTransform: "none",
              borderRadius: "12px",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              Redeem Rewards
            </Typography>
          </Button>
        </Box>
      </>
      {/* Redeem Points Info Modal */}
      <PointsRedeemModal
        show={showRedeemInfo}
        handleSubmitItem={handleSubmitItem}
        checkedItem={checkedItem}
        setCheckedItem={setCheckedItem}
        handleClose={() => setShowRedeemInfo(false)}
        title={`Redeem Reward | POINTS: ${props.profile.totalPoints}`}
        notEnoughPoints={notEnoughPoints}
        setNotEnoughPoints={setNotEnoughPoints}
        redeemingItem={redeemingItem}
        checkedItemPoints={checkedItemPoints}
        setCheckedItemPoints={setCheckedItemPoints}
      />
      <EarnPointsInfoModal show={showEarnPointsInfo} onClose={() => setEarnPointsInfo(false)} />
    </Box>
  );
};

export default PointsLogCard;
