import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { FormikHelpers } from 'formik';
import { SignUpLayout } from 'components/layout/containers';
import SignUpForm from './SignUpForm';

import { postRegister } from 'services/domain/users';

import { IRegisterForm } from 'services/domain/users';

const SignUp = () => {
    const [errors, setError] = useState<string[]>([]);
    // eslint-disable-next-line
    const [messages, setMesssages] = useState<string[]>([]);

    const onSubmit = async (values: IRegisterForm, { setSubmitting, resetForm }: FormikHelpers<IRegisterForm>) => {
        setSubmitting(true);
        try {
            const { passwordConfirmation, ...payload } = values;
            await postRegister(payload);
            // setMesssages([...Object.values(result)]);
            resetForm();
        } catch (e) {
            if ('messages' in e) {
                if (e.messages instanceof Array) {
                    setError(e.messages);
                };

                if (typeof e.messages === 'string') {
                    setError([e.messages]);
                }
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <SignUpLayout>
            <SignUpForm onSubmit={onSubmit}>
                {errors.length > 0 && errors.map(error =>
                    <Alert key={error} severity="error">{error}</Alert>
                )}
                {messages.length > 0 && messages.map(message =>
                    <Alert key={message} severity="success">{message}</Alert>
                )}
            </SignUpForm>
        </SignUpLayout>
    );
};

export default SignUp;
