import { http } from "../../data";

export interface ILogPoints {
  userId: string;
  points: any;
  activity: string;
  userTimezone: string;
}

export interface PointsDetail {
  activity: String;
  createdAt: Date;
  points: number;
}

const endPoints = {
  logPoints: "logPoints",
  pointLogs: "pointsLogs",
  redeemPoints: "redeemPoints",
};

export const logPoint = async (payload: ILogPoints): Promise<any> => await http.post(endPoints.logPoints, payload);
export const getPointLogs = async (payload: any): Promise<any> => await http.get(endPoints.pointLogs, { params: payload });
export const redeemPoints = async (payload: any): Promise<any> => await http.put(endPoints.redeemPoints, payload);
