import React, { FC } from 'react';
import { makeStyles, InputBase, Theme, Typography } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    inputMov: {
        padding: '12px 16px',
        minHeight: 45,
        background: '#FAFAFA',
        boxShadow: 'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
        borderRadius: '7px',
        minWidth:'100%',
        border: 'none',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
    },
    inputMovFocused: {
        borderColor: theme.palette.primary.main,
        border: '2px solid red',
    },
    inputClass: {
        '&::placeholder': {
            color: '#282828',
            opacity: 1
        }
    },
}));

const CustomInput: FC<Props & InputBaseProps> = ({ meta, isSubmitted, placeholder, ...props }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <InputBase
                classes={{focused: classes.inputMovFocused, input: classes.inputClass}}
                {...props}
                placeholder={placeholder}
                className={classes.inputMov}/>
            {meta?.touched && meta?.error && (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            )}
        </React.Fragment>
    );
};

export default CustomInput;
