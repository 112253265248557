import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

interface CommentIconProps extends SvgIconProps {
    withDot?: boolean;
}

export default function CommentIcon({ withDot, ...props }: CommentIconProps) {
    const classes = useStyles();
    let pathString = "M12 1.59078H17.7422C19.328 1.59078 20.7633 2.29203 21.8025 3.42547C22.8417 4.55891 23.4844 6.125 23.4844 7.85516V9.94344V12.0317C23.4844 14.9422 18.4383 21.0167 12 22.4089C12 19.4984 12 18.2961 12 18.2961H6.25781C4.67203 18.2961 3.23672 17.5948 2.1975 16.4614C1.15828 15.327 0.515625 13.7609 0.515625 12.0308V9.9425V7.85422C0.515625 6.12453 1.15828 4.55844 2.1975 3.42453C3.23672 2.29063 4.67203 1.58984 6.25781 1.58984H12";

    if (withDot) {
        pathString = "M12 1.76638H17.7422C19.328 1.76638 20.7633 2.46763 21.8025 3.60107C22.8417 4.7345 23.4844 6.3006 23.4844 8.03075V12.2073C23.4844 15.1178 18.4383 21.1923 12 22.5845C12 19.674 12 18.4717 12 18.4717H6.25781C4.67203 18.4717 3.23672 17.7704 2.1975 16.637C1.15828 15.5026 0.515625 13.9365 0.515625 12.2064V8.02982C0.515625 6.30013 1.15828 4.73404 2.1975 3.60013C3.23672 2.46622 4.67203 1.76544 6.25781 1.76544H12M7.3125 9.99649C7.3125 10.1 7.22855 10.184 7.125 10.184C7.02145 10.184 6.9375 10.1 6.9375 9.99649C6.9375 9.89294 7.02145 9.80899 7.125 9.80899C7.22855 9.80899 7.3125 9.89294 7.3125 9.99649ZM12.1875 9.99649C12.1875 10.1 12.1036 10.184 12 10.184C11.8964 10.184 11.8125 10.1 11.8125 9.99649C11.8125 9.89294 11.8964 9.80899 12 9.80899C12.1036 9.80899 12.1875 9.89294 12.1875 9.99649ZM17.0625 9.99649C17.0625 10.1 16.9786 10.184 16.875 10.184C16.7714 10.184 16.6875 10.1 16.6875 9.99649C16.6875 9.89294 16.7714 9.80899 16.875 9.80899C16.9786 9.80899 17.0625 9.89294 17.0625 9.99649Z";
    }

    return (
        <SvgIcon {...props} viewBox="0 0 24 24" className={classes.root}>
            <path
                d={pathString}
                stroke={props.stroke || '#414141'} strokeWidth="0.5" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
