import { useState, useCallback, RefObject, useEffect } from 'react';

interface UseInfiniteScrollProps {
    ref: RefObject<HTMLElement>;
    hasMore: boolean;
    onLoadMore: () => void;
}

export const useInfiniteScroll = ({ ref, hasMore, onLoadMore }: UseInfiniteScrollProps): [boolean, () => void] => {
    const [isFetching, setIsFetching] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const handleScroll = useCallback(() => {
        if (ref.current!.scrollTop === 0 && isFetching === false && hasMore) {
            setIsFetching(true);
            setIsWaiting(true);
        }
    }, [ref, isFetching, hasMore])

    useEffect(() => {
        const elem = ref.current;

        if (!elem) return;

        elem.addEventListener('scroll', handleScroll);

        return () => {
            elem.removeEventListener('scroll', handleScroll);
        }
    }, [ref, handleScroll])

    useEffect(() => {
        if (isFetching && !isWaiting) {
            onLoadMore();
        }
    }, [isFetching, onLoadMore, isWaiting]);

    const stopFetching = useCallback(() => {
        setIsFetching(false);
        setIsWaiting(false);
    }, []);

    return [isFetching, stopFetching]
};
