import React, { FC, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Paper, AppBar, Toolbar, Card, CardHeader, Avatar, Box, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from 'styled-components';

import MessageList from 'components/Chats/components/ChatWindow/MessageList';
import MessageInput from 'components/Chats/components/ChatWindow/MessageInput';
import { useStyles } from 'components/Chats/components/ChatWindow/styles';
import { getMessages, createMessage, chatObservable } from 'services/domain/chats';
import { ChatStore, Message } from 'interfaces/chat';
import { ChatPaginationProvider } from 'components/Chats/context';
import { usePagination } from 'components/Chats/hooks';
import { ChatContext } from '../hooks/ChatContext';

const NameInnerWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NameHolder = styled.span`
    margin-right: 8px;
`;

const NameWrapper: FC<{ name: string; }> = ({ name }) => {
    return (
        <NameInnerWrapper>
            <NameHolder>{name}</NameHolder>
        </NameInnerWrapper>
    )
};

const useCustomStyles = makeStyles((theme: Theme) => ({
    'chat-toolbar': {
        justifyContent: 'space-between',
    },
    'button-close': {
        color: theme.palette.common.white,
    },
}));

const ChatWindow: FC<{}> = () => {
    const classes = useStyles();
    const customClasses = useCustomStyles();
    const [isInit, setIsInit] = useState(true);
    const [messages, setMessages] = useState([]);
    const [dateInfo, setDateInfo] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const { after, limit, setAfter } = usePagination();
    const { chatActiveUser } = useSelector((state: { chats: ChatStore }) => ({
        chatActiveUser: state.chats.chatAssessmentUser,
    }));

    const handlePrependMessage = (rawMessages: any[], prevMessages: any[], initDateInfo: string) => {
        let latestDateInfo = initDateInfo;
        const updatedMessages = rawMessages.reduce((prev: Message[], message: Message, index: number) => {
            if (latestDateInfo === '') {
                latestDateInfo = moment(message.timeStamp).format('YYYYMMDD');
                if (rawMessages.length === index + 1) setDateInfo(latestDateInfo);
            }
            
            if (latestDateInfo !== moment(message.timeStamp).format('YYYYMMDD')) {
                latestDateInfo = moment(message.timeStamp).format('YYYYMMDD');
                if (rawMessages.length === index + 1) setDateInfo(latestDateInfo);
                return [message, { dateSeparator: moment(message.timeStamp).format('ddd, DD MMM') }, ...prev];
            }
    
            return [message, ...prev];
        }, [...prevMessages]);

        return updatedMessages;
    }

    const fetchMessages = async (existData: number, limitData: number = limit) => {
        try {
            const result = await getMessages({ existData, limitData, from: chatActiveUser!.displayName });
            return result;
        } catch (e) {
            return null;
        }
    }

    const initMessages = async () => {
        const result = await fetchMessages(0);
        const pageCount = result?.meta?.pagination?.totalPage ?? 0;
        const rawData = result?.data?.length > 0 ? result.data : [];
        const updatedMessages = handlePrependMessage(rawData, [], '');
        setTotalPage(pageCount);
        setIsInit(true);
        setMessages(updatedMessages);
    }

    const fetchMoreMessages = async (updatedAfter: number) => {
        if (isInit) setIsInit(false);
        const result = await fetchMessages(after * limit);
        const updatedMessages = handlePrependMessage(result.data, messages, dateInfo);
        setMessages(updatedMessages);
    }

    const onSendMessage = useCallback(async (message) => {
        try {
            const result = await createMessage({ message, to: chatActiveUser!.displayName }); // TODO:
            // @ts-ignore
            setMessages(prevMessages => [...prevMessages, result.chats]);
        } catch(e) {

        } finally {}
    }, [chatActiveUser]);

    useEffect(() => {
        const subscribtion = chatObservable.subscribe((message) => {
            if (message) {
                // @ts-ignore
                if ('isChatListActive' in message! && message!.isChatListActive) {
                    /**
                     * this just to trigger read message signal to server, to update read status
                     */
                    fetchMessages(0, 1);
                    const newMessage = message!;
                    // @ts-ignore
                    delete newMessage.isChatListActive;
                    // @ts-ignore
                    setMessages(prevMessages => [...prevMessages, newMessage]);
                }
            }
        });

        return () => {
            subscribtion.unsubscribe();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!chatActiveUser) return;
        initMessages();
        // eslint-disable-next-line
    }, [chatActiveUser]);

    useEffect(() => {
        if (chatActiveUser) {
            fetchMoreMessages(after);
        }
        // eslint-disable-next-line
    }, [after]);

    const onLoadMore = useCallback(() => {
        let afterValue = after + 1;

        if (isInit) {
            afterValue = 1;
        }

        setAfter(afterValue);
        // eslint-disable-next-line
    }, [after, isInit, totalPage]);

    let newAfter = after + 1;
    if (isInit) newAfter = 1;

    return (
        <Paper className={classes.chatWindowInnerWrapper}>
            <AppBar position="static" color="primary">
                <Toolbar className={customClasses["chat-toolbar"]}>
                    <Card elevation={0} className={classes.chatCard}>
                        <CardHeader
                            avatar={<Avatar src={chatActiveUser!?.avatar} />}
                            title={<NameWrapper name={chatActiveUser!?.name} />}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'inherit',
                            }}
                            subheader={chatActiveUser!?.displayName}
                            subheaderTypographyProps={{
                                color: 'inherit',
                            }}
                        />
                    </Card>

                    <ChatContext.Consumer>
                        {value => (
                            <Box>
                                <IconButton onClick={value.handleCloseChat} className={customClasses["button-close"]}>
                                    <ChevronRightIcon color="inherit"/>
                                </IconButton>
                            </Box>

                        )}
                    </ChatContext.Consumer>
                </Toolbar>
            </AppBar>
            <div className={classes.chatWindowBody}>
                <MessageList
                    messages={messages}
                    loadMore={onLoadMore}
                    hasMore={totalPage > newAfter}
                />
                <MessageInput onSendMessage={onSendMessage} />
            </div>
        </Paper>
    );
}

const ChatWindowScreen: FC<{}> = () => (
    <ChatPaginationProvider>
        <ChatWindow />
    </ChatPaginationProvider>
);

export default React.memo(ChatWindowScreen);
