import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        minWidth: 78,
        width: 78,
    },
    taskList: {
        marginBottom: theme.spacing(2),
    },
    listBox: {
        overflow: 'auto',
    },
    listItemBox: {
        background: '#FFFFFF',
        boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.5), 1px 1px 8px rgba(0, 0, 0, 0.1), inset 1px 1px 3px #F8F8F8',
        borderRadius: '7px',
        marginBottom: '10px',
    },
    videoOption: {
        marginBottom: 20,
    },
    iconListDelete: {
        marginLeft: 'auto',
    }
}));
export default useStyles;
