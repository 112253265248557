import React from "react";
import moment from "moment";
import { Paper, Box, InputLabel, Button, Grid, Typography, Card, CardHeader, Avatar, CircularProgress, TextField as TextFieldMui } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import BookingInfo from "components/_modules/booking/BookingInfo";
import { Autocomplete as AutocompleteMui } from "@material-ui/lab";
import BagIcon from "components/icons/BagIcon";
import { useStyles } from "./styles";
import { phoneRegExp } from "services/util/regex";
import history from "services/data/history";
import { getUserByFirstName } from "services/domain/users/users";
import { saveBooking } from "services/domain/admin/bookings";
import { getTimeDiff } from "services/util/common";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { Toast } from "services/util/toast";

type DateRange = {
  startDate: Date;
  endDate: Date;
};

type UserBooking = {
  dateRange: DateRange;
  paymentStatus: string;
};

interface FormikState {
  email: string;
  phoneNumber: string;
  address: string;
  firstName: string;
  lastName: string;
  bookingData: UserBooking[];
}

const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  address: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  bookingData: Yup.array().of(
    Yup.object().shape({
      dateRange: Yup.object({
        startDate: Yup.date(),
        endDate: Yup.date(),
      }),
      paymentStatus: Yup.string().when("dateRange", {
        is: (dateRange) => !!dateRange,
        then: Yup.string().required("Payment status is required"),
      }),
    }),
  ),
});

const initialValues = {
  email: "",
  phoneNumber: "",
  address: "",
  firstName: "",
  lastName: "",
  bookingData: [
    {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      paymentStatus: "",
    },
  ],
};

const BookingAddNew = () => {
  const classes = useStyles();
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = React.useState<any>();
  // eslint-disable-next-line
  const [_open, setOpen] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    setSubmitting(true);
    try {
      const { firstName, lastName, bookingData, ...payload } = values;
      const toast = Toast({ onClose: goBack });
      const transformBookingData = bookingData.map(({ dateRange, ...booking }) => ({
        ...booking,
        startDate: moment(dateRange.startDate).utc(true),
        endDate: moment(dateRange.endDate).utc(true),
        totalWeeks: getTimeDiff({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }),
        email: payload.email,
        firstName,
        lastName,
        phoneNumber: payload.phoneNumber,
        address: payload.address,
      }));

      await saveBooking({
        bookId: "",
        // ...payload,
        bookingData: transformBookingData,
      });

      toast.fire({
        icon: "success",
        title: "New booking has been added",
      });
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Create new booking failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleEmailChange = (user: any, setFieldValue: any) => {
    setFieldValue("email", user?.email ?? "");
    setFieldValue("phoneNumber", user?.phoneNumber ?? "");
    setFieldValue("address", user?.address ?? "");
    setFieldValue("firstName", user?.name?.firstName ?? "");
    setFieldValue("lastName", user?.name?.lastName ?? "");
  };

  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const payload = {
        firstName: value,
        role: "guest",
      };
      const rspn = await getUserByFirstName(payload);
      setOptions(rspn.data);
    } catch (error) {
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <Paper className={classes.formContainer}>
      <Box maxWidth="430px" marginLeft="auto" marginRight="auto">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
              <Form>
                <Grid container alignItems="center" justify="center" className={classes.titleContainer}>
                  <BagIcon />
                  <Typography component="span">&nbsp;&nbsp;&nbsp;Add New Booking</Typography>
                </Grid>

                <AutocompleteMui
                  value={searchValue}
                  id="autocomplete-search-booking"
                  // open={true}
                  onOpen={() => {
                    inputChange(null, null);
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onChange={(e, obj) => {
                    handleEmailChange(obj, setFieldValue);
                  }}
                  onInputChange={inputChange}
                  // getOptionSelected={(option, value) => option._id === value._id}
                  getOptionLabel={(option) => option.name.firstName + " " + option.name.lastName}
                  options={options}
                  loading={loadingSearch}
                  // PopperComponent={PopperMy}
                  renderInput={(params) => (
                    <TextFieldMui
                      {...params}
                      label={"Search by first name"}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />

                <Box className={classes.userId}>
                  <InputLabel>ID</InputLabel>
                  <Typography>{}</Typography>
                </Box>
                <Card elevation={0}>
                  <CardHeader avatar={<Avatar src={dummyAvatar} />} title={`${values.firstName} ${values.lastName}`} />
                </Card>

                <Field component={TextField} type="text" label="Email Address (*)" name="email" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Phone Number (*)" name="phoneNumber" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Address (*)" name="address" margin="normal" fullWidth />

                <BookingInfo bookingData={values.bookingData} setFieldValue={setFieldValue} minItemCount={1} />

                <Box mt={10}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                    Save Booking
                  </Button>
                  <Button onClick={goBack} className={classes.buttonCancel} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default BookingAddNew;
