import React, { lazy, useState, Suspense, useCallback } from "react";
import { useParams, useLocation, Link as RouterLink } from "react-router-dom";
import { Paper, Box, Typography, Button, TextField, withStyles, Popper } from "@material-ui/core";

import AddIcon from "components/icons/AddIcon";
import UserTab from "./components/UserTab";
import UserTabPanel from "./components/UserTabPanel";
import UserAddNew from "./UserAddNew";
import UserEdit from "./UserEdit";

import { useStyles } from "./styles";
import { Autocomplete } from "@material-ui/lab";
import { SearchIcon } from "components/icons";
import { getUserByFirstName } from "services/domain/users/users";
import Employee from "./Employee/Employee";

const UserAll = lazy(() => import("./UserAll"));
const Guest = lazy(() => import("./Guest"));
const Admin = lazy(() => import("./Admin"));
const Coach = lazy(() => import("./Coach"));
const Presenter = lazy(() => import("./Presenter"));

const PopperMy = (props: any) => {
  const classes = useStyles();
  return <Popper {...props} className={classes.popperStyle} placement="bottom-start" />;
};

const CssTextField = withStyles({
  root: {
    background: "#F8F8F8",
    boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)",
    borderRadius: "7px",
    paddingRight: "20px",
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
})(TextField);

const FocusStyle = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
};

const Users = () => {
  const [tab, setTab] = useState("all");
  const [searchValue, setSearchValue] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const { action } = useParams<{ action: string }>();
  const location = useLocation();
  const classes = useStyles();

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, val: string) => {
    setTab(val);
    setSearchValue(null);
  }, []);

  if (action === "add") {
    return <UserAddNew />;
  }

  if (action === "edit") {
    return <UserEdit />;
  }
  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const payload = {
        role: tab === "all" ? undefined : tab,
        firstName: value,
      };
      const rspn = await getUserByFirstName(payload);
      setOptions(rspn.data);
    } catch (error) {
    } finally {
      setLoadingSearch(false);
    }
  };

  const onChangeValue = (event: any, value: any) => {
    setSearchValue(value);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Users</Typography>

        <Button color="primary" component={RouterLink} to={`${location.pathname}/add`}>
          ADD NEW USER
          <AddIcon className={classes.addIcon} />
        </Button>
      </Box>
      <Box mt={3}>
        <Paper elevation={3}>
          <Box display="flex" justifyContent="center">
            <div style={{ flexGrow: 1 }} />
            <UserTab value={tab} handleChange={handleTabChange} />
            <div style={{ flexGrow: 1 }} />
          </Box>
        </Paper>
      </Box>
      <Box mt={3} display="flex" flexDirection="row-reverse">
        <Autocomplete
          value={searchValue}
          size="small"
          freeSolo
          id="autocomplete-search"
          style={{ width: 300 }}
          onOpen={() => {
            inputChange(null, null);
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={onChangeValue}
          onInputChange={inputChange}
          getOptionLabel={(option) => option.name.firstName + " " + option.name.lastName}
          options={options}
          loading={loadingSearch}
          PopperComponent={PopperMy}
          renderInput={(params) => <CssTextField placeholder="Search for ‘something’" {...params} variant="outlined" style={open ? FocusStyle : {}} />}
        />
        <SearchIcon style={{ position: "absolute", marginTop: "9px", right: "48px" }} />
      </Box>

      <Suspense fallback={<h1>Loading ...</h1>}>
        <UserTabPanel value={tab} index="all">
          <UserAll searchValue={searchValue} />
        </UserTabPanel>
        <UserTabPanel value={tab} index="guest">
          <Guest searchValue={searchValue} />
        </UserTabPanel>
        <UserTabPanel value={tab} index="admin">
          <Admin searchValue={searchValue} />
        </UserTabPanel>
        <UserTabPanel value={tab} index="coach">
          <Coach searchValue={searchValue} />
        </UserTabPanel>
        <UserTabPanel value={tab} index="employee">
          <Employee searchValue={searchValue} />
        </UserTabPanel>
        <UserTabPanel value={tab} index="presenter">
          <Presenter searchValue={searchValue} />
        </UserTabPanel>
      </Suspense>
    </>
  );
};

export default Users;
