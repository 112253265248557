import React, { useState, useRef } from 'react';
import {
    useHistory,
    Link as RouterLink,
    useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import { IconButton, Avatar, Menu, MenuItem, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import dummyAvatar from 'assets/images/dummy-avatar-256.png';
import MessageAlert from './components/MessageAlert';
import Notification from './components/Notification';

import auth from 'services/domain/auth';
import { getUser } from 'services/domain/users';
import { Creators } from 'services/domain/users/reducer';
import { chatSubject } from 'services/domain/chats';
import { useProfile } from 'services/util';
import { ChatStore } from 'interfaces/chat';
import MenuIcon from '@material-ui/icons/Menu';
import useTabMenu from '../../../../../hooks/useTabMenu';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        marginTop: '-10px',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            width: 22,
            height: 22,
            marginTop: '0px',
        },
    },
    hideMenu: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuListStyle: {
        top: '46px !important',
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const NavBar = () => {
    const loggedUserId = auth.userProperties?.userData?._id;
    const [isMenuOpen, setMenuState] = useState(false);
    const [isMenuListOpen, setMenuListState] = useState(false);
    const profile = useProfile();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { path } = useRouteMatch();
    const messageAlertRef = useRef<HTMLButtonElement>(null);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const anchorRefMenu = useRef<HTMLButtonElement>(null);
    const notificationRef = useRef(null);
    const menuId = 'primary-search-account-menu';
    const { chatActiveUser, chatAssessmentUser } = useSelector(
        (state: { chats: ChatStore }) => ({
            chatActiveUser: state.chats.chatActiveUser,
            chatAssessmentUser: state.chats.chatAssessmentUser,
        })
    );
    const { currentTab, setCurrentTab } = useTabMenu();

    const dispatchProfileUpdate = async () => {
        try {
            const userId = auth.userProperties?.userData?._id;
            if (userId) {
                const result = await getUser({ user_id: userId });
                dispatch(Creators.updateProfile(result.data));
            }
        } catch (e) {
            // set error handler here
        }
    };

    React.useEffect(() => {
        dispatchProfileUpdate();
        const subscription = auth.user.subscribe();

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line
    }, []);

    const checkAuth = async () => {
        try {
            const hoursLocal = localStorage.getItem('hours');
            const userId = auth.userProperties?.userData?._id;
            const today = new Date();
            const hours = today.getHours();
            if (hoursLocal === hours.toString()) {
                localStorage.setItem('hours', hours.toString());
                return;
            }
            if (hoursLocal === null)
                localStorage.setItem('hours', hours.toString());
            if (userId) {
                const result = await getUser({ user_id: userId });
                if (
                    auth.userProperties?.userData?.statusBooking !==
                    result.data.statusBooking
                ) {
                    if (result.data.statusBooking === 'Waiting')
                        history.push('/waiting');
                    if (result.data.statusBooking === 'Pause')
                        history.push('/waiting');
                    if (result.data.statusBooking === 'Inactive')
                        history.push('/inactive');
                }
                if (
                    auth.userProperties?.userData?.lastWeek !==
                    result.data.lastWeek
                ) {
                    if (result.data.lastWeek) history.push('/inactive');
                }
                auth.updateUserProperties(
                    'statusBooking',
                    result.data.statusBooking
                );
                auth.updateUserProperties('nextStart', result.data.nextStart);
                auth.updateUserProperties('lastWeek', result.data.lastWeek);
                auth.updateUserProperties('statusFlow', result.data.statusFlow);
                auth.updateUserProperties('dayLeft', result.data.dayLeft);
            }
        } catch (e) {
            // set error handler here
        }
    };

    React.useEffect(() => {
        const pusher = new Pusher('c3ff317270d095e35b8a', {
            cluster: 'us3',
            authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/pusher/auth`,
        });

        const channel = pusher.subscribe(`private-${loggedUserId}`);
        channel.bind('notification-comfeed-comment', function (data: any) {
            // @ts-ignore
            notificationRef.current.updateNotification(data);
        });
        channel.bind('notification-comfeed-like', function (data: any) {
            // @ts-ignore
            notificationRef.current.updateNotification(data);
        });
        channel.bind('notification-chats', function (data: any) {
            /**
             * subscribers: chat window
             */
            chatSubject.next({
                ...data,
                isChatListActive:
                    data.from === chatActiveUser?.id ||
                    data.from === chatAssessmentUser?.userId,
            });
            // @ts-ignore
            messageAlertRef.current.handleChatListUpdate({
                ...data,
                isChatListActive:
                    data.from === chatActiveUser?.id ||
                    data.from === chatAssessmentUser?.userId,
            });
        });

        checkAuth();

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    });

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuState(true);
    };
    const handleMenuListOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuListState(true);
    };

    const handleMenuClose = () => {
        setMenuState(false);
        // handleMobileMenuClose();
    };

    const handleMenuClick = (value) => {
        if (value) setCurrentTab(value);
        setMenuListState(false);
    };

    const logout = () => {
        handleMenuClose();
        auth.logout();
    };

    const renderMenu = (
        <Menu
            classes={{
                paper: classes.menuListStyle,
            }}
            anchorEl={anchorRef.current}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >

            <MenuItem
                onClick={handleMenuClose}
                component={RouterLink}
                to="/account"
                selected={path === '/account'}
            >
                MY ACCOUNT
            </MenuItem>
            <MenuItem
                onClick={handleMenuClose}
                component={RouterLink}
                to="/profile"
                selected={path === '/profile'}
            >
                VIEW PROFILE
            </MenuItem>
            <MenuItem
                onClick={handleMenuClose}
                component={RouterLink}
                to="/bookings-guest"
                selected={path === '/bookings-guest'}
            >
                BOOKINGS
            </MenuItem>
            {profile &&
                (profile.role === 'admin' || profile.role === 'coach') && (
                    <MenuItem
                        onClick={handleMenuClose}
                        component={RouterLink}
                        to="/dashboard"
                        selected={path === '/dashboard'}
                    >
                        GO TO ADMIN DASHBOARD
                    </MenuItem>
                )}
            <Divider />
            <MenuItem onClick={logout}>LOG OUT</MenuItem>
        </Menu>
    );

    const renderMenuList = (
        <Menu
            classes={{
                paper: classes.menuListStyle,
            }}
            anchorEl={anchorRefMenu.current}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={isMenuListOpen}
            onClose={() => handleMenuClick('')}
            TransitionComponent={Transition}
            transitionDuration={1000}
        >
            <MenuItem
                onClick={() => handleMenuClick('daily')}
                selected={currentTab === 'daily'}
            >
                DAILY
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuClick('comunity')}
                selected={currentTab === 'comunity'}
            >
                COMMUNITY
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuClick('timeline')}
                selected={currentTab === 'timeline'}
            >
                TIMELINE
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuClick('recipes')}
                selected={currentTab === 'recipes'}
            >
                RECIPES
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuClick('simplemath')}
                selected={currentTab === 'simplemath'}
            >
                SIMPLE MATH
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuClick('coaching')}
                selected={currentTab === 'coaching'}
            >
                COACHING
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <MessageAlert ref={messageAlertRef} />

            <Notification ref={notificationRef} />

            <IconButton
                ref={anchorRef}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
            >
                <Avatar
                    src={profile?.avatar?.path || dummyAvatar}
                    className={classes.avatar}
                />
            </IconButton>

            <IconButton
                className={classes.hideMenu}
                ref={anchorRefMenu}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuListOpen}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            {renderMenu}
            {renderMenuList}
        </>
    );
};

export default React.memo(NavBar);
