/**
 * @description Given a weekly report record and a coach, generate a PDF report
 *
 * @param weeklyReport a weekly report record (CoachReport model)
 * @param coach a coach record (User model)
 *
 *
 */

import jsPDF from 'jspdf';
import moment from 'moment';
import { formatCentsToDollars } from '../common';

const coachReport = (weeklyReport: any, coach: any) => {
    // data for the PDF report
    const startDate = moment(weeklyReport.startDate).format('MM/DD/YYYY');
    const endDate = moment(weeklyReport.endDate).format('MM/DD/YYYY');
    const coachName = `${coach?.name?.firstName} ${coach?.name?.lastName}`;
    const paymentRate = formatCentsToDollars(weeklyReport.paymentRate);
    const totalPayment = formatCentsToDollars(weeklyReport.totalPayment);

    // data formatted for the filename
    const startDateForFilename = moment(weeklyReport.startDate).format(
        'MMDDYYYY'
    );
    const endDateForFilename = moment(weeklyReport.endDate).format('MMDDYYYY');
    const coachNameForFilename = coachName.replace(' ', '_');

    const filename = `WeeklyReport_${coachNameForFilename}_${startDateForFilename}-${endDateForFilename}.pdf`;

    const doc = new jsPDF('portrait', 'px', 'letter');

    const marginX = 32;
    const marginY = 32;

    let currentY = marginY; // keep track of the current Y (vertical) position

    const pageHeight = doc.internal.pageSize.height;

    doc.text(`Coach Name: ${coachName}`, marginX, currentY);

    currentY += 32;

    doc.text(`Payment Rate: ${paymentRate}`, marginX, currentY);

    currentY += 32;

    doc.text(`Pay Period: ${startDate} - ${endDate}`, marginX, currentY);

    currentY += 32;

    doc.text(`Bookings for this week:`, marginX, currentY);

    currentY += 16;

    weeklyReport.bookings.forEach((booking: any, index: number) => {
        // if we've reached the end of the page, add a new page and reset the currentY
        if (currentY + 32 > pageHeight) {
            doc.addPage();
            currentY = marginY;
        }

        doc.text(`${index + 1}. ${booking.email}`, marginX, currentY);

        currentY += 16;
    });

    currentY += 16;

    doc.text(`Total Weekly Payment: ${totalPayment}`, marginX, currentY);

    doc.save(filename);
};

export default coachReport;
