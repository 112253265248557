import React, { FC, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "pages/DashboardUser/components/main/daily/styles";
import InputText from "components/_newDesign/input/InputText";
import ButtonPopUp from "./ButtonPopUp";
import ErrorMessage from "components/_newDesign/alert/ErrorMessage";
import InfoAlert from "components/alert/InfoAlert";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview: boolean;
  isDone?: boolean;
  defaultValue: string;
}
const OtherLabel: FC<Props> = (props) => {
  const isDone = props.isDone || false;
  const classes = useStyles();
  const [other, setOther] = React.useState<string>(props.defaultValue);
  const [otherInfo, setOtherInfo] = React.useState<boolean>(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const onChange = (e: any) => {
    if (e.target.value.length > 160) {
      setOther(other);
    } else {
      setOther(e.target.value);
      props.onChange && props.onChange(e.target.value);
    }
  };

  React.useEffect(() => {
    if (props.active) setOtherInfo(true);
  }, [props.active]);

  useEffect(() => {
    setOther(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box display={"flex"} flexDirection="column">
      <Box display={"flex"} flexDirection="column" marginTop={"11px"} width={matches ? "100%" : "360px"}>
        <Box display={"flex"}>
          <Box flexGrow={1} display="flex" alignItems={"center"} marginLeft={1}>
            <Typography gutterBottom className={classes.nutritionGoalTypo}>
              <span style={{ color: "black" }}>Other</span>
            </Typography>
          </Box>
        </Box>
        <Box>
          <InputText
            disabled={isDone}
            onChange={onChange}
            multiline
            placeholder="ex. I took a photo of everything I ate today."
            value={other}
            endAdornment={<Typography variant="caption">{`${other.length}/160`}</Typography>}
          />
        </Box>
        {props.active && other === "" && <ErrorMessage message={"Field is Required"} />}
        {!isDone && (
          <Box marginTop={"10px"}>
            <InfoAlert
              onClose={() => setOtherInfo(false)}
              isOpen={otherInfo}
              text="Please state in first person past tense. The exact phrasing you enter will show on your daily check box. "
              closeable={true}
            />
          </Box>
        )}
        {props.active && (
          <Box marginTop={"10px"}>
            <ButtonPopUp title="Other" type="other" preview={props.preview} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OtherLabel;
