import React, { FC } from 'react';
import { Box } from '@material-ui/core';
// import useStyles from './styles';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'components/_newIcon'
import Typography from 'components/_newDesign/typography/Typography';
import { getNutritionGoal } from '../../../helper/question';
import moment from 'moment';
import NoDataComponent from './WeeklyNoData'
import AmountCalories from '../NutritionGoal/NutritionGoalAdmin/AmountCalories';
import BalancedMeals from '../NutritionGoal/NutritionGoalAdmin/BalancedMeals';
import Eliminate from '../NutritionGoal/NutritionGoalAdmin/Eliminate';
import Intermittan from '../NutritionGoal/NutritionGoalAdmin/Intermittan';
import NoSnacking from '../NutritionGoal/NutritionGoalAdmin/NoSnacking';
import Other from '../NutritionGoal/NutritionGoalAdmin/Other';
import OzWater from '../NutritionGoal/NutritionGoalAdmin/OzWater';
import TrackMarcos from '../NutritionGoal/NutritionGoalAdmin/TrackMarcos';

interface WeeklyInterface {
    data: any
    startDate?: any
}

const DailyNutritionDoalAdmin: FC<WeeklyInterface> = props => {
    // const classes = useStyles();
    const [index, setIndex] = React.useState(props.data.index)
    const [nutritionGoal, setNutrionGoal] = React.useState<any>()

    const renderContent = (daily, weekly) => {
        const data = getNutritionGoal(weekly)
        switch (data) {
            case 'caloriesIsCheck':
                if (daily?.calories.achieved) return <AmountCalories data={daily?.calories} amountCalories={weekly.calories.amount} />;
                break;
            case 'plateMethod':
                if (daily?.balanceMeals.achieved) return <BalancedMeals data={daily?.balanceMeals} />;
                break;
            case 'eliminateIsCheck':
                if (daily?.eliminate.achieved) return <Eliminate data={daily?.eliminate} eliminateText={weekly.eliminate.text}/>;
                break;
            case 'intermittentFasting':
                if (daily?.intermittentFasting.achieved) return <Intermittan data={daily?.intermittentFasting} />;
                break;
            case 'snacking':
                if (daily?.snacking.achieved) return <NoSnacking data={daily?.snacking} />;
                break;
            case 'othersIsCheck':
                if (daily?.others.achieved) return <Other data={daily?.others} otherText={weekly.others.text} />;
                break;
            case 'waterIsCheck':
                if (daily?.water.achieved) return <OzWater data={daily?.water} amount={weekly.water.amount} />;
                break;
            case 'trackMacros':
                if (daily?.trackMacros.achieved) return <TrackMarcos data={daily?.trackMacros}/>;
                break;
            default:
                return <NoDataComponent />;
        }
        return <NoDataComponent />;
    };

    React.useEffect(() => {
        const findDayTaskByIndex = props.data.data.allTask.find((task: any) => task.typesDaily === `day ${index}`);
        if (findDayTaskByIndex && findDayTaskByIndex.dailyNutritionGoal) {
            setNutrionGoal(findDayTaskByIndex.dailyNutritionGoal);
        } else {
            setNutrionGoal(undefined);
        }
    }, [props.data, index])
    return (
        <>  
            <Box 
                width={'100%'} 
                height={'auto'}
                boxShadow="1px 1px 8px rgba(65, 65, 65, 0.1)"
                display={'flex'}
                flexDirection="column"
                justifyContent={'center'}
                alignItems="center"
            >
                <Box display={'flex'} justifyContent='center' alignItems={'center'} height={'auto'} width="100%" padding={'20px 45px 10px 45px'} textAlign="center">
                    <Typography variant="mov_subtitle2_regular">Nutrition Goal Submission</Typography>
                </Box>
                <Box display={'flex'} justifyContent='space-between' alignItems={'center'} height={'auto'} width="400px" borderBottom={'2px solid #FFA21E'} textAlign="center">
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" paddingX={'20px'} width="100%">
                        <Box mr={1} flexGrow={1} textAlign="left">
                            <Typography variant="mov_caption1">{moment(props.startDate).add(index, 'days').format('dddd, MMMM DD YYYY')}</Typography>
                        </Box>
                        <Box style={{paddingTop: "10px"}}>
                            <ArrowLeftCircleIcon fill={index === 0 ? '#B3B3B3' : '#000000'} width={'24px'} height={'24px'}
                                onClick={() => {if (index > 0) setIndex(index - 1)}}
                                style={{cursor: index === 0 ? 'not-allowed' : 'pointer'}}
                                /> 
                            <ArrowRightCircleIcon fill={index === 6 ? '#B3B3B3' : '#000000'} width={'24px'} height={'24px'}
                                onClick={() => {if (index < 6) setIndex(index + 1)}}
                                style={{cursor: index === 6 ? 'not-allowed' : 'pointer'}}
                                />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box margin={'42px 50px 42px 50px'}>
                {!nutritionGoal ? (
                    <NoDataComponent />
                ) : (
                    renderContent(nutritionGoal, props.data.data.originalData.nutritionGoalWeekly)
                )}
            </Box>
        </>
    );
};
export default DailyNutritionDoalAdmin;