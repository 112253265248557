import React from 'react';
import { Avatar, Box } from '@material-ui/core';
// import { makeStyles, Theme } from '@material-ui/core/styles'
import dummyAvatar from 'assets/images/dummy-avatar-256.png';
import Typography from 'components/_newDesign/typography/Typography';

// const useStyles = makeStyles(( theme: Theme ) => ({
//     root: {
//         backgroundColor: 'transparent',
//         '& .MuiAvatar-root': {
//             backgroundColor: theme.palette.primary.main,
//         }
//     },
// }));

const GuestProfile = ({ value, row }: { value: any, row: any }) => {
    // const classes = useStyles();

    return (
        // <Card elevation={0} className={classes.root}>
        //     <CardHeader
        //         avatar={<Avatar src={dummyAvatar} />}
        //         title={`${row.original.firstName ?? ''} ${row.original.lastName ?? ''}`}
        //         subheader={value ?? ''}
        //     />
        // </Card>
        <Box display={'flex'} alignItems="center" width={'fix-content'} margin="auto">
            <Avatar style={{ width: '24px', height: '24px'}} src={dummyAvatar} />
            <Box ml="8px">
                <Typography variant="mov_body1">{`${row.original.firstName ?? ''} ${row.original.lastName ?? ''}`}</Typography>
                <Typography variant="mov_caption1" style={{color: '#9A9A9A', marginTop: '4px'}}>{row.original.email}</Typography>
            </Box>
        </Box>
    );
};

export default GuestProfile;
