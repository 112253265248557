import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputStyle: {
            background: '#F8F8F8',
            boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
            borderRadius: '7px',
            height: '32px',
            width: '75px',
        },
        input: {
            padding: '5px 5px 5px 10px;',
        },
        inputTextStyle: {
            background: '#F8F8F8',
            boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
            borderRadius: '7px',
            height: '60px',
        },
        inputFile: {
            maxWidth: '170px',
        },
        uploadButton: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        taskButton: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0.05em',
            height: '35px',
            width: '92px',
            borderRadius: '7px',
            textTransform: 'capitalize',
        },
        taskCheckbox: {
            background: '#E5E5E5',
        },
        taskInputText: {
            background: '#F8F8F8',
            boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
            borderRadius: '7px',
            padding: '4px 10px',
        },
        taskTitle:{
            fontWeight: 500,
        },
        submitDaily: {
            [theme.breakpoints.down('sm')]: {
                flex: '1 0 100%',
                justifyContent: 'center'
            },
        },
        weeklyContainer: {
            backgroundColor: '#FAFAFA', 
            borderRadius: '20px', 
            padding: '24px', 
            paddingBottom: '32px',
            [theme.breakpoints.down('md')]: {
                padding: '0px', 
                paddingBottom: '20px',
            },

        },
        weeklyContentRes: {
            flexDirection: 'row',
            padding: '32px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                padding: '10px',
                paddingBottom: '32px',
            },
        },
        weeklyContentRes2: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                width: '100%',
            },
        },
        weeklyContentOpt: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
        },
        weeklyInputRes: {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'flex-end',
            },
        },
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                paddingTop: '10px',
            },
        },
        calendarMob: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        calendarNormal: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        optionalTitle: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '150%',
            letterSpacing: '0.15px',
            color: '#000000',
        },
        optionalDesc: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '0.25px',
            color: '#000000',
        },
        uploadDesc: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.25px',
            color: '#545454',
        },
        nutritionGoalTypo: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '150%',
            letterSpacing: '0.25px',
            color: '#282828',

        },
        radioGroup: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '-33px',
            },
        },
        summaryContent:{
            display: 'block',
            marginBottom: '0px',
        },
        avatarSmall: {
            width: '24px',
            height: '24px',
            fontSize: '12px'
        },
        avatarGroup: {
            width: '24px',
            height: '24px',
            fontSize: '11px',
            marginLeft: '-6px'
        },
        accordionRoot: {
            backgroundColor: '#FAFAFA',
            boxShadow: '1px 1px 8px rgba(65, 65, 65, 0.1)',
            borderRadius: '8px !important',
        },
        accordionDetailsRoot: {
            flexDirection: 'column',
        }
    }),
);
export default useStyles;
