import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function WeightInIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 34"
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path
                d="M15.6252 14.7141V28.7198M28.317 4.20954H25.601V3.86931C25.601 2.28461 24.221 1 22.5191 1H9.04327C7.34143 1 5.9614 2.28461 5.9614 3.86931V4.20954H3.24609C2.00557 4.20954 1 5.14602 1 6.30098V30.9086C1 32.0635 2.00557 33 3.24609 33H28.3176C29.5581 33 30.5637 32.0635 30.5637 30.9086V6.30098C30.5631 5.14602 29.5575 4.20954 28.317 4.20954ZM22.0583 9.85075H9.60907C9.46956 9.85075 9.35702 9.73821 9.35702 9.59869V5.33685C9.35702 5.19734 9.46956 5.0848 9.60907 5.0848H22.0583C22.1978 5.0848 22.3104 5.19734 22.3104 5.33685V9.59869C22.3104 9.73821 22.1978 9.85075 22.0583 9.85075Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M9.46387 16.999L9.46387 25.4227" strokeWidth="0.5" strokeMiterlimit="10"
                  strokeLinejoin="round"/>
        </SvgIcon>
    );
};