/**
 * TODO: optimize rendering
 */

import React, {
    forwardRef,
    PropsWithChildren,
    Ref,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
// import _ from 'lodash';
import {
    HeaderGroup,
    useTable,
    TableOptions,
    useFilters,
    usePagination,
    useSortBy,
    useAsyncDebounce,
} from 'react-table';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Checkbox,
} from '@material-ui/core';

import { useStyles } from './styles';
import TablePagination from './components/TablePagination';
import Loading from '../../progress/Loading';

interface TableInterface<T extends object> extends TableOptions<T> {
    // @ts-ignore TODO:
    onFetchData?: ({ pageIndex, pageSize, sortBy }) => any;
    onClickRow?: (item: any) => void;
    withCheckbox?: boolean;
    onChangeCheckbox?: (item: any) => void;
    loading?: boolean;
    pageSize?: number;
    hidePagination?: boolean;
    handleMessageEmpty?: string;
}

const hooks = [useFilters, useSortBy, usePagination];

interface RefObject {
    forceFetch: () => void;
}

const MTable = forwardRef(
    <T extends object>(
        props: PropsWithChildren<TableInterface<T>>,
        ref: Ref<RefObject>
    ) => {
        const classes = useStyles();
        const {
            onFetchData,
            onClickRow,
            withCheckbox,
            onChangeCheckbox,
            refreshPage,
            ...rest
        } = props;
        const [loading, setLoading] = useState<boolean>(false);
        const instance = useTable<T>(
            {
                ...rest,
                initialState: {
                    pageIndex: 0,
                    pageSize: props.pageSize ? props.pageSize : 50,
                }, // FIXME: trial pagination
                manualPagination: true,
                autoResetPage: false,
            },
            ...hooks
        );
        const {
            gotoPage,
            getTableProps,
            headerGroups,
            getTableBodyProps,
            page,
            prepareRow,
            state: { pageIndex, pageSize, sortBy },
        } = instance;

        let fetcher: any;
        if (onFetchData) fetcher = onFetchData;
        const onFetchDataDebounced = useAsyncDebounce(fetcher, 500);
        const [selected, setSelected] = useState<any[]>([]);

        useImperativeHandle(ref, () => ({
            forceFetch: () => {
                setLoading(true);
                onFetchDataDebounced({ pageIndex, pageSize, sortBy }).finally(
                    () => {
                        if (onChangeCheckbox) {
                            setSelected([]);
                            onChangeCheckbox([]);
                        }
                        setLoading(false);
                    }
                );
            },
        }));

        useEffect(() => {
            if (onFetchData) {
                setLoading(true);
                onFetchDataDebounced({ pageIndex, pageSize, sortBy }).finally(
                    () => {
                        setLoading(false);
                    }
                );
            }
            if (refreshPage) {
                gotoPage(0);
            }
            // eslint-disable-next-line
        }, [onFetchDataDebounced, pageIndex, pageSize, refreshPage]);

        const handleCheckbox = (
            event: React.ChangeEvent<HTMLInputElement>,
            index: number,
            row: any,
            pageIndex: number
        ) => {
            // eslint-disable-next-line
            let newSelected = Array();
            if (event.target.checked) {
                newSelected = [
                    ...selected,
                    { id: index + pageIndex * pageSize, row },
                ];
            } else {
                newSelected = selected.filter(
                    (value: any) => value.id !== index + pageIndex * pageSize
                );
            }
            setSelected(newSelected);
            if (onChangeCheckbox) {
                onChangeCheckbox(newSelected);
            }
        };

        const checkSelectedAll = (pageIndexConst: number) => {
            let amount = 0;

            for (
                let i = pageIndex * pageSize;
                i < pageIndex * pageSize + pageSize;
                i++
            ) {
                for (let j = 0; j < selected.length; j++) {
                    if (selected[j].id === i) amount = amount + 1;
                }
            }
            if (amount === pageSize) {
                return true;
            } else {
                return false;
            }
        };

        const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
            // eslint-disable-next-line
            let newSelected = Array();
            let oldSelected = selected;
            if (event.target.checked) {
                for (
                    let i = pageIndex * pageSize;
                    i < pageIndex * pageSize + pageSize;
                    i++
                ) {
                    const find = selected.find((item) => item.id === i);
                    if (!find)
                        newSelected.push({
                            id: i,
                            row: page[i - pageIndex * pageSize]?.original,
                        });
                }
                setSelected([...selected, ...newSelected]);
                if (onChangeCheckbox) {
                    onChangeCheckbox([...selected, ...newSelected]);
                }
            } else {
                for (
                    let i = pageIndex * pageSize;
                    i < pageIndex * pageSize + pageSize;
                    i++
                ) {
                    oldSelected = oldSelected.filter((item) => item.id !== i);
                }
                setSelected(oldSelected);
                if (onChangeCheckbox) {
                    onChangeCheckbox(oldSelected);
                }
            }
        };

        return (
            <TableContainer className={classes.tableContainer}>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(
                            <T extends object>(headerGroup: HeaderGroup<T>) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {withCheckbox && page.length !== 0 && (
                                        <TableCell>
                                            <Checkbox
                                                checked={checkSelectedAll(
                                                    pageIndex
                                                )}
                                                color={'primary'}
                                                onChange={(e) =>
                                                    handleCheckAll(e)
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    {headerGroup.headers.map((column) => (
                                        <TableCell
                                            {...column.getHeaderProps()}
                                            className={classes.headerClass}
                                        >
                                            {column.canSort ? (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    direction={
                                                        column.isSortedDesc
                                                            ? 'desc'
                                                            : 'asc'
                                                    }
                                                    {...column.getSortByToggleProps()}
                                                >
                                                    {column.render('Header')}
                                                </TableSortLabel>
                                            ) : (
                                                column.render('Header')
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        )}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {loading || props.loading ? (
                            <TableRow>
                                <TableCell colSpan={props.columns.length}>
                                    <Loading />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {page.map((row: any, i) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow
                                            {...row.getRowProps()}
                                            onClick={() => {
                                                if (onClickRow)
                                                    onClickRow(row.original);
                                            }}
                                            hover
                                        >
                                            {withCheckbox && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={
                                                            !!selected.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    i +
                                                                        pageIndex *
                                                                            pageSize
                                                            )
                                                        }
                                                        color={'primary'}
                                                        onChange={(e) =>
                                                            handleCheckbox(
                                                                e,
                                                                i,
                                                                row.original,
                                                                pageIndex
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            )}

                                            {row.cells.map((cell: any) => (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {page.length === 0 && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={props.columns.length}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {props.handleMessageEmpty ||
                                                'No Data'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
                {!props.hidePagination && (
                    <TablePagination<T> instance={instance} />
                )}
            </TableContainer>
        );
    }
);

export default MTable;
