import React from 'react';
import Alert from '@material-ui/lab/Alert';

import auth from 'services/domain/auth';
import { useError } from 'services/util';
import { SignInLayout } from 'components/layout/containers';
import SignInForm from './SigInForm';
import { useDispatch } from 'react-redux';
import { Creators } from 'services/domain/users/reducer';

const SignIn = (props) => {
    const [errors, setError] = useError([]);
    const userProfile = auth.userProperties;
    const dispatch = useDispatch();

    /**
     * preventive:
     * if already logged in should not access this page
     */
    if (userProfile) {
        props.history.push('/');
    }

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            // reset profile store state before login
            dispatch(Creators.updateProfile(undefined));
            const user = await auth.login(values.email, values.password);
            setSubmitting(false);
            if (user) {
                if (user.userData.statusBooking === 'Inactive') {
                    props.history.push('/inactive');
                } else {
                    props.history.push('/');
                }
            }
        } catch (e) {
            if ('messages' in e) {
                if (e.messages instanceof Array) {
                    setError(e.messages);
                }

                if (typeof e.messages === 'string') {
                    setError([e.messages]);
                }
            }
        }
    };

    return (
        <SignInLayout>
            <SignInForm onSubmit={onSubmit}>
                {errors.length > 0 &&
                    errors.map((error) => (
                        <Alert key={error} severity="error">
                            {error}
                        </Alert>
                    ))}
            </SignInForm>
        </SignInLayout>
    );
};

export default SignIn;
