import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function DownloadIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path d="M1.875 14.875V18.125H18.125V14.875" stroke="#414141" strokeLinejoin="round"/>
            <path d="M5.125 10L9.59375 14.875L14.0625 10" stroke="#414141" strokeLinejoin="round"/>
            <line x1="9.5" y1="14.875" x2="9.5" y2="1.875" stroke="#414141"/>
        </SvgIcon>
    );
};
