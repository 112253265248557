import React from 'react';
import { Box } from '@material-ui/core';

const AvailableRedemptionCell = ({ value, row }: { value: any, row: any }) => {
    return (
    <Box width={'220px'} display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'} textAlign="center" margin="auto">
        <span style={{fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '150%'}}>{ row?.original?.redeemLimit - value }</span>
    </Box>
    );
};

export default AvailableRedemptionCell;