import React, { FC, ReactElement } from 'react';
import BackgroundLayout from './BackgroundLayout';

import Background from 'assets/images/background-landscape1.jpg';

interface Props {
    children: ReactElement;
}

const SignInLayout: FC<Props> = ({ children }) => (
    <BackgroundLayout background={Background}>
        {children}
    </BackgroundLayout>
);

export default SignInLayout;
