import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useProfile } from "services/util";
import routeConstants from "../_constant/routes";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";

const NavAccountMobile = () => {
  const location = useLocation();
  const classes = useStyles();
  const profile: any = useProfile();

  // the user is a guest and is active - they should have the Add More Weeks option. Otherwise, that should be removed
  const qualifiesForAddMoreWeeks = profile && profile.role === "guest" && profile.statusBooking === "Active" ? true : false;

  let usableRouteConstants: any = { ...routeConstants };

  if (!qualifiesForAddMoreWeeks) {
    const { ADD_MORE_WEEKS, ...remainingRouteConstants } = routeConstants;

    usableRouteConstants = remainingRouteConstants;
  }

  return (
    <List component="nav" className={classes.navMobile}>
      {Object.values(usableRouteConstants).map(({ path, name, Icon }: any, i) => (
        <ListItem key={i} button component={RouterLink} to={path} selected={path === location.pathname} style={{ padding: "18px 5px" }}>
          <ListItemIcon
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon color="primary" />
            <Typography variant="caption">{name}</Typography>
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default NavAccountMobile;
