import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Inactive = lazy(() => import('./Inactive'));

export default {
    exact: true,
    path: routeConstants.INACTIVE.path,
    component: Inactive,
    layout: LayoutOptions.main,
};
