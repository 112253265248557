import { http } from 'services/data';
import { PaginationInterface } from 'interfaces/common';
import { CookingByIdInterface, UpdateCategoryRequest } from 'interfaces/video';

const endPoints = {
    videoCourse: 'admin/course/video/all',
    videoCourseById: 'admin/course/video/byid',
    addVideoCourse: 'admin/course/video/add',
    updateVideoCourseCategory: 'admin/course/category/update',
    videoCooking: 'admin/cooking/video/all',
    videoCookingById: 'admin/cooking/video/byid',
    addVideoCooking: 'admin/cooking/video/add',
    updateVideoCookingCategory: 'admin/cooking/category/update',
};

export const getVideoCourse = (payload: PaginationInterface) => http.get(endPoints.videoCourse, { params: payload });
export const getVideoCourseById = (payload: CookingByIdInterface) => http.get(endPoints.videoCourseById, { params: payload });
export const postVideoCourse = (payload: any) => http.post(endPoints.addVideoCourse, payload);
export const updateVideoCourseCategory = (payload: UpdateCategoryRequest) => http.post(endPoints.updateVideoCourseCategory, payload);
export const getVideoCooking = (payload: PaginationInterface) => http.get(endPoints.videoCooking, { params: payload });
export const getVideoCookingById = (payload: CookingByIdInterface) => http.get(endPoints.videoCookingById, { params: payload });
export const postVideoCooking = (payload: any) => http.post(endPoints.addVideoCooking, payload);
export const updateVideoCookingCategory = (payload: UpdateCategoryRequest) => http.post(endPoints.updateVideoCookingCategory, payload);
