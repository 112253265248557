import React, { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import InputNumber from 'components/_newDesign/input/InputNumber';
import { useStyles } from './styles'
import ButtonMui from 'components/_newDesign/button/Button';
import { trackMacrosRequest } from 'interfaces/nutritionGoals';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { postTrackMacros } from 'services/domain/users/nutritionGoal';
import ErrorMessage from 'components/_newDesign/alert/ErrorMessage';
import { AvatarGroupComponent } from '../AvatarGroup/AvatarGroup';
import { UserSubmissionListItem } from 'interfaces/user';
interface Props {
    onChange?: (value: any) => void;
    active: boolean;
    preview?: boolean;
    defaultExpanded?: boolean;
    weekly?: boolean;
    data?: {
        text: string,
        achieved: boolean
        protein: number;
        fats: number;
        carbs: number;
    }
    isDone?: boolean;
    userList?: Array<UserSubmissionListItem>;
    submitSucces?: () => void;
    submissionDate?: string;
}
const defaultValue: trackMacrosRequest = {
    achieved: true,
    text: '',
    protein: undefined,
    fats: undefined,
    carbs: undefined,
    submissionDate: moment().utc(true).toString(),
}
const TrackMarcos: FC<Props> = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState<boolean>(false);
    const [expand, setExpand] = React.useState<boolean>(false)
    const [submit, setSubmit] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [form, setForm] = React.useState<trackMacrosRequest>(defaultValue)
    const { enqueueSnackbar } = useSnackbar();
    const onChange = (event) => {
        if (!props.isDone && !props.preview) {
            setChecked(event.target.checked);
            setExpand(event.target.checked)
        }
    }
    const onChangeExpand = (value: boolean) => {
        if (!props.isDone && !props.preview) {
            setChecked(value);
            setExpand(value)
        } else if (props.isDone) {
            setExpand(value)
        }
    }
    const formOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target
        let newValue: any = value
        if((name === 'text') && form[name].length > 254) {
            newValue = form[name].substring(0, 254)
            setForm({ ...form, [name]: newValue })
        } else if ((name === 'protein' 
                    || name === 'fats' 
                    || name === 'carbs') && Number(value) > 999) {
            const valueString = String(form[name]).substring(0, 3)
            newValue = Number(valueString)
            setForm({ ...form, [name]: newValue })
        } else {
            setForm({ ...form, [name]: newValue })
        }
        
    }

    const onSubmit = async() => {
        setSubmit(true)
        try {
            if (!form.carbs || !form.fats || !form.protein) return
            setLoading(true)
            const payload: trackMacrosRequest = {
                ...form,
                submissionDate: props.submissionDate ? props.submissionDate : moment().utc(true).toString()
            }
            await postTrackMacros(payload)
            enqueueSnackbar('Add Track Macros Success', { variant: 'success' })
            if (props.submitSucces) props.submitSucces()
        } catch (error) {
            enqueueSnackbar('Add Track Macros Failed', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (props.data) {
            const newForm = {
                achieved: true,
                text: props.data.text,
                submissionDate: moment().utc(true).toString(),
                carbs: props.data.carbs,
                protein: props.data.protein,
                fats: props.data.fats
            }
            setForm(newForm)
        }
    }, [props.data])
    React.useEffect(() => {
        if (props.isDone) setChecked(true)
    }, [props.isDone])
    React.useEffect(() => {
        if (props.preview) setChecked(true)
    }, [props.preview])
    return (
        <div className={`${props.weekly ? classes.rootWeekly : classes.root}`}>
            <Accordion
                defaultExpanded={props.defaultExpanded || true}
                classes={{ root: classes.accordionRoot }}
                expanded={props.preview || expand}
                onChange={(_e, expanded) => onChangeExpand(expanded)}
            >
                <AccordionSummary
                    aria-controls="action-amount-calories-content"
                    id="action-amount-calories-header"
                    classes={{ content: classes.summaryContent }}
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        checked={checked}
                        onChange={onChange}
                        control={<Checkbox name='caloriesAmountNutrition' color="primary" />}
                        label={
                            <Box display={'flex'} alignItems="center">
                                <SpoonForkIcon />
                                <Typography variant="mov_body1" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    I achieved my Macro targets
                                </Typography>
                            </Box>
                        }
                    />
                    <Box 
                        display={'flex'} 
                        alignItems="center" 
                        justifyContent={'space-between'}
                        padding="2.5px 20px"
                        borderRadius={'10px'}
                        style={{ background: 'rgba(0, 0, 0, 0.05)' }}
                        mb={2}
                        >
                        <Typography
                            variant="mov_body2"
                        >
                            Click to expand/shrink
                        </Typography>
                        {!expand ? (
                            <ExpandMoreIcon fontSize='large'/>
                        ) : (
                            <ExpandLessIcon fontSize='large'/>
                        )}
                    </Box>
                    {!expand && (props.userList !== undefined) && props.userList.length > 0 ? (
                        <Box mt={1} mb={3}>
                            <AvatarGroupComponent userList={props.userList}/>
                        </Box>
                    ):(<></>)}
                </AccordionSummary>
                <AccordionDetails>
                    <Box width={'100%'}>
                        <Box className={classes.itemDetailBox} marginTop="20px">
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    PROTEINS
                                </Typography>
                                <InputNumber
                                    name="protein"
                                    endAdornment={'gr'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    value={props.preview ? 70 : form.protein}
                                    disabled={props.preview || props.isDone}
                                    onChange={formOnChange}
                                />
                            </Box>
                            {submit && !form.protein && (
                                <ErrorMessage message={"Protein is Required"}/>
                            )}
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                                marginTop={'13px'}
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    FATS
                                </Typography>
                                <InputNumber
                                    name="fats"
                                    endAdornment={'gr'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    value={props.preview ? 63 : form.fats}
                                    disabled={props.preview || props.isDone}
                                    onChange={formOnChange}
                                />
                            </Box>
                            {submit && !form.fats && (
                                <ErrorMessage message={"Fats is Required"}/>
                            )}
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                                marginTop={'13px'}
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    CARBS
                                </Typography>
                                <InputNumber
                                    name="carbs"
                                    endAdornment={'gr'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    value={props.preview ? 450 : form.carbs}
                                    disabled={props.preview || props.isDone}
                                    onChange={formOnChange}
                                />
                            </Box>
                            {submit && !form.carbs && (
                                <ErrorMessage message={"Carbs is Required"}/>
                            )}
                            <Box marginTop={'8px'}>
                                <InputText
                                    multiline
                                    name="text"
                                    placeholder="Write notes to your coach..."
                                    style={{ width: '100%' }}
                                    value={props.preview ? 'I made it to fulfill my proteins today! Though I might overdo my carbs and fried craving.' : form.text}
                                    disabled={props.preview || props.isDone}
                                    onChange={formOnChange}
                                    endAdornment={<Typography variant='caption'>{`${form.text.length}/255`}</Typography>}
                                />
                            </Box>
                        </Box>
                        {(!props.preview && !props.isDone) && (
                            <Box width="100%" textAlign="center" mt="20px">
                                <ButtonMui
                                    disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </ButtonMui>
                            </Box>
                        )}
                        {expand && (props.userList !== undefined) && props.userList.length > 0 ? (
                            <Box mt={3}>
                                <AvatarGroupComponent userList={props.userList}/>
                            </Box>
                        ):(<></>)}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default TrackMarcos;
