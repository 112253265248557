import React, { FC } from "react";
import BackgroundLayout from "./BackgroundLayout";

import Background from "assets/images/background-landscape2.jpg";

interface Props {
  children: any;
}

const SignUpLayout: FC<Props> = ({ children }) => <BackgroundLayout background={Background}>{children}</BackgroundLayout>;

export default SignUpLayout;
