import React from 'react';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { useStyles } from './styles';
import dummyAvatar from 'assets/images/dummy-avatar-256.png';

interface Props extends AvatarProps {
    classStyle?: string;
}

const BigAvatar = (props: Props) => {
    const classes = useStyles();
    const { classStyle, src, ...rest } = props;

    return (
        <Avatar {...rest} src={src ?? dummyAvatar} className={clsx(classes.largeAvatar, classStyle)} />
    );

};
export default BigAvatar;
