import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Container = styled.div`
    display: flex;
    height: 60px;
    padding: 10px;
    width: calc(100%-10px);
`;

const ActualInput = styled.input`
    width: calc(100% - 50px);
    border: none;
    border-radius: 8px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px!important;
    font-family: Montserrat;
    outline: none;
    box-shadow: 0 1px silver;
    font-size: 18px;
    line-height: 45px;
`;

const SendButton = styled(Button)`
    min-width: 60px !important;
    width: 50px !important;
    border-radius: 8px !important;
    margin: 0 5px !important;
    margin-right: 0 !important;
    color: white !important;
    padding-left: 20px !important;

    svg {
        margin-left: -3px;
    }
`;

interface MessageInputProps {
    onSendMessage: (message: string) => void;
}

const MessageInput: FC<MessageInputProps> = ({ onSendMessage }) => {
    const [message, setMessage] = useState('');

    const onChange = ({ target }: any) => {
        setMessage(target.value);
    }

    const onKeyPress = (e: any) => {
        if (e.charCode === 13) submitMessage();
    }

    const submitMessage = () => {
        if (!message) return;

        onSendMessage(message);
        setMessage('');
    };

    return (
        <Container>
            <ActualInput
                placeholder="Write message"
                value={message}
                onChange={onChange}
                onKeyPress={onKeyPress}
            />
            <SendButton
                variant="contained"
                color="primary"
                onClick={submitMessage}
            >
                Send
            </SendButton>
        </Container>
    );
}

export default MessageInput;
