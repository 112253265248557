import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Popper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import useStyles from "./styles";
import TableByUser from "./components/TableByUser";
import { DateRange } from "materialui-daterange-picker";
import { getThisWeekRange } from "../../../helper/Date";
import { Autocomplete } from "@material-ui/lab";
import { ExpandMoreIcon, SearchIcon } from "components/icons";
import { getUserByFirstName } from "services/domain/users/users";
import { getCoachUserAsReference } from "services/domain/admin/users";
import TableZoom from "./components/TableZoom";
import TypographyMui from "components/_newDesign/typography/Typography";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "components/_newIcon";
import moment from "moment";
import auth from "services/domain/auth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

enum TabMenu {
  CATEGORY = "category",
  USER = "user",
  ZOOM = "zoom",
}

const CssTextField = withStyles({
  root: {
    background: "#F8F8F8",
    boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)",
    borderRadius: "7px",
    paddingRight: "20px",
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
})(TextField);

const PopperMy = (props: any) => {
  const classes = useStyles();
  return <Popper {...props} className={classes.popperStyle} placement="bottom-start" />;
};

const FocusStyle = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
};

interface ChipData {
  label: string;
  value: string;
  checked?: boolean;
}
interface DataFilter {
  userEmail: string;
  coachId: string[];
}

const Assessment: FC = () => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState<string>(TabMenu.USER);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>(getThisWeekRange());
  const [searchValue, setSearchValue] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [chipData, setChipData] = React.useState<ChipData[]>([]);
  const [coachs, setCoachs] = React.useState<any>([]);
  const [openCoach, setOpenCoach] = React.useState(false);
  const [openCoachZoom, setOpenCoachZoom] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCoach, setSelectedCoach] = React.useState("All Coach");
  const [dataFilter, setDataFilter] = React.useState<DataFilter>({
    coachId: [],
    userEmail: "",
  });
  const [index, setIndex] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValueTab(newValue);
  };

  const getCListCoach = async () => {
    try {
      const result = await getCoachUserAsReference();
      setCoachs(result.data);

      if (result?.data && result.data.length > 0) {
        let data: ChipData[] = [];
        for (let index = 0; index < result.data.length; index++) {
          data.push({
            label: `${result.data[index].name.firstName} ${result.data[index].name.lastName}`,
            value: result.data[index]._id,
            checked: undefined,
          });
        }
        setChipData(data);
      }
    } catch (e) {
    } finally {
    }
  };

  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const payload = {
        firstName: value,
      };
      const rspn = await getUserByFirstName(payload);

      setOptions(rspn.data);
    } catch (error) {
    } finally {
      setLoadingSearch(false);
    }
  };

  const onChangeValue = (event: any, value: any) => {
    setSearchValue(value);
  };

  const coachOnClick = () => {
    setOpenCoach(true);
  };

  const handleDelete = (chipToDelete: ChipData) => () => {
    setLoading(true);
    let origin = chipData;
    const objIndex = origin.findIndex((obj) => obj.value === chipToDelete.value);
    origin[objIndex].checked = false;
    setChipData(origin);
    setTimeout(() => {
      setLoading(false);
    });
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    let origin = chipData;
    const objIndex = origin.findIndex((obj) => obj.value === event.target.value);
    origin[objIndex].checked = event.target.checked;
    setChipData(origin);
    setTimeout(() => {
      setLoading(false);
    });
  };
  const resetFilter = () => {
    setSearchValue(null);
    const origin = chipData;
    const newData = origin.map((chip) => {
      chip.checked = false;
      return chip;
    });
    setChipData(newData);
    setDataFilter({
      userEmail: "",
      coachId: [],
    });
  };

  const onApplyFilter = () => {
    const coachIds = chipData.filter((item) => item.checked === true).map((item) => item.value);
    let email = "";
    if (searchValue) email = searchValue.email;
    setDataFilter({
      userEmail: email,
      coachId: coachIds,
    });
  };

  React.useEffect(() => {
    getCListCoach();
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} open={openCoach} onClose={() => setOpenCoach(false)}>
        <DialogContent>
          <Box style={{ display: "flex" }} height={200}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Choose Coach</FormLabel>
              {!loading && (
                <FormGroup>
                  {chipData.map((coach) => (
                    <FormControlLabel
                      key={coach.value}
                      control={<Checkbox value={coach.value} onChange={handleCheckChange} checked={coach.checked} name={coach.label} />}
                      label={coach.label}
                    />
                  ))}
                </FormGroup>
              )}
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth maxWidth={"sm"} open={openCoachZoom} onClose={() => setOpenCoachZoom(false)}>
        <DialogContent>
          <Box style={{ display: "flex" }} height={200}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Choose Coach</FormLabel>
              {!loading && (
                <FormGroup>
                  {chipData.map((coach) => (
                    <FormControlLabel
                      key={coach.value}
                      control={<Checkbox value={coach.value} onChange={handleCheckChange} checked={coach.checked} name={coach.label} />}
                      label={coach.label}
                    />
                  ))}
                </FormGroup>
              )}
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Paper className={classes.paper} elevation={3}>
            <Tabs className={classes.tabHeader} value={valueTab} onChange={handleChange} indicatorColor="primary" centered>
              <Tab value={TabMenu.USER} label="VIEW BY USER" {...a11yProps(TabMenu.USER)} />
              <Tab value={TabMenu.ZOOM} label="ZOOM ATTENDANCES" {...a11yProps(TabMenu.ZOOM)} />
            </Tabs>

            <TabPanel value={valueTab} index="user">
              <Box display="flex">
                <Box flexGrow={1} display="flex" flexDirection="row-reverse">
                  <Autocomplete
                    value={searchValue}
                    size="small"
                    freeSolo
                    id="autocomplete-search"
                    style={{ width: "100%" }}
                    // open={true}
                    onOpen={() => {
                      inputChange(null, null);
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onChange={onChangeValue}
                    onInputChange={inputChange}
                    getOptionLabel={(option) => option.name.firstName + " " + option.name.lastName}
                    options={options}
                    loading={loadingSearch}
                    PopperComponent={PopperMy}
                    renderInput={(params) => <CssTextField placeholder="Search for ‘something’" {...params} variant="outlined" style={open ? FocusStyle : {}} />}
                  />
                  <SearchIcon
                    style={{
                      position: "absolute",
                      marginTop: "9px",
                      right: "80px",
                      paddingLeft: "5px",
                      marginLeft: "5px",
                    }}
                  />
                </Box>
              </Box>
              <Box display="flex" mt={2}>
                <Box>
                  <Button className={classes.buttonSort} onClick={() => setOpenCoachZoom(true)}>
                    <div className={classes.buttonFont}>Coach</div>
                    &nbsp; &nbsp;
                    <ExpandMoreIcon color="primary" />
                  </Button>
                </Box>
              </Box>
              <Box component="ul" display="flex" justifyContent="start" flexWrap="wrap" pl={0} mt={2} style={{ listStyle: "none" }}>
                {chipData.map((data) => {
                  return (
                    data.checked && (
                      <li key={data.value}>
                        <Chip
                          style={{
                            marginLeft: "10px",
                          }}
                          label={data.label}
                          onDelete={handleDelete(data)}
                        />
                      </li>
                    )
                  );
                })}
              </Box>
              <Box display="flex" mt={2} flexDirection="row-reverse">
                <Box marginLeft={"10px"}>
                  <Button className={classes.buttonApply} color="primary" variant="contained" onClick={() => onApplyFilter()}>
                    <div className={classes.buttonFontApply}>Apply Filter</div>
                  </Button>
                </Box>
                <Box>
                  <Button className={classes.buttonSort} onClick={() => resetFilter()}>
                    <div className={classes.buttonFont}>Reset Filter</div>
                    &nbsp; &nbsp;
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={valueTab} index="zoom">
              <Box mt={2}>
                {auth?.userProperties?.userData?.role === "admin" && (
                  <Box>
                    <Select
                      id="locationSelect"
                      // className={style.underline}
                      // value={row?.original?.approvalStatus}
                      onChange={(e) => setSelectedCoach(String(e.target.value))}
                      value={selectedCoach}
                      disableUnderline
                      displayEmpty
                    >
                      <MenuItem value="All Coach">All Coach</MenuItem>
                      {coachs && coachs.map((coach) => <MenuItem value={coach._id}>{coach.name.firstName}</MenuItem>)}
                    </Select>
                  </Box>
                )}
                <Box display={"flex"} justifyContent="space-between" alignItems={"center"} height={"auto"} width="100%" borderBottom={"2px solid #FFA21E"} textAlign="center">
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" paddingX={"20px"} width="100%">
                    <Box mr={1} flexGrow={1} textAlign="left">
                      <TypographyMui variant="mov_caption1">
                        {moment().add(index, "weeks").startOf("weeks").format("MMM DD, YYYY")}
                        &nbsp;-&nbsp;
                        {moment().add(index, "weeks").endOf("weeks").format("MMM DD, YYYY")}
                      </TypographyMui>
                    </Box>
                    <Box style={{ paddingTop: "10px" }}>
                      <ArrowLeftCircleIcon fill={"#000000"} width={"32px"} height={"32px"} onClick={() => setIndex(index - 1)} style={{ cursor: "pointer" }} />
                      <ArrowRightCircleIcon fill={"#000000"} width={"32px"} height={"32px"} onClick={() => setIndex(index + 1)} style={{ cursor: "pointer" }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={12}>
          {valueTab === TabMenu.USER && <TableByUser dateRangeFilter={dateRangeFilter} dataFilter={dataFilter} />}
          {valueTab === TabMenu.ZOOM && (
            <TableZoom coachId={auth?.userProperties?.userData?.role === "admin" ? selectedCoach : auth?.userProperties?.userData?._id} index={index} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Assessment;
