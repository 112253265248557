import React, { ReactNode } from 'react';
import TopBar from '../../TopBar/TopBar';
import NavigationMenu from '../../LeftMenu/LeftMenu';
import { useStyles } from './styles';
import Feedback from 'components/layout/Feedback';

interface Props {
    children: ReactNode;
}

const DashboardLayout = ({ children }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBar layoutType="dashboard" />
            <NavigationMenu />
            <main className={classes.content}>
                {children}
            </main>
            <Feedback />
        </div>
    );
};

export default DashboardLayout;
