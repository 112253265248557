import React, { FC, useEffect, useState } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Grid,
    Typography,
} from '@material-ui/core';
import useStyles from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { http } from 'services/data';

interface IChart {
    date: string
    mainTask: number
    optionalTask: number
    goal?: number
}

const Chart: FC<any> = props => {
    const classes = useStyles();
    const [chart, setChart] = useState<IChart[]>([]);
    const getData = () =>{
        // setLoading(true)
        http.get('admin/report/weeklyProgram/chart',{
            params:{
                startDate: props.dateRange.startDate,
                endDate: props.dateRange.endDate,
            }
        }).then((res: any) => {
            const { data: { chart, goals } } = res;
            const reformatToChart = chart.map((x: IChart) => ({ ...x as IChart, goal: goals }));

            setChart(reformatToChart);
        }).catch(err=>{
            // setLoading(false)
        });
    }
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [props.dateRange]);
    return (
        <Grid item md={12}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    className={classes.boxHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>PROGRESS CHART</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ResponsiveContainer height={400}>
                        <LineChart data={chart} margin={{ top: 25, bottom: 25 }}>
                            <CartesianGrid />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="bottom" align="center"
                                    wrapperStyle={{
                                        paddingLeft: '20px',
                                    }} />
                            <Line name="Goal" dataKey="goal" strokeWidth={2} stroke="#97412A" />
                            <Line name="Main Task" dataKey="mainTask" strokeWidth={2} stroke="#FFA21E" />
                            <Line name="Optional Task" dataKey="optionalTask" strokeWidth={2} stroke="#FFDAA5" />
                        </LineChart>
                    </ResponsiveContainer>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
export default Chart;
