import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import {
    Box,
    Grid,
    Typography,
    Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
    withStyles,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import Auth from 'services/domain/auth';

import InputText from 'components/_newDesign/input/InputText';
import InputTextArea from 'components/_newDesign/input/InputTextArea';
import SwitchIos from 'components/_newDesign/switch/SwitchIos';
import { ProfileUpdateRequest } from 'interfaces/user';
import { updateUserAbout } from 'services/domain/users';
import { useSnackbar } from 'notistack'
import Button from 'components/_newDesign/button/Button'
import { http } from 'services/data/http';
import { useHistory } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error'

const useStyles = makeStyles((theme: Theme) => ({
    inputCustomWidth: {
        height: '40px',
    },
    buttonCancel: {
        marginLeft: '20px',
    },
    formSwitchContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        flexWrap: "nowrap",
    },
    formOptional: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flexWrap: "nowrap",
    },
    switchTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0.1px',
        color: '#282828',
        textTransform: 'uppercase'
    },
    optional: {
        color: '#9A9A9A', fontSize: '12px', fontWeight: 'normal'
    },
    optionalTitle: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
    },
    optionalDesc: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.25px',
        color: '#000000',
    },
    customWidth: {
        maxWidth: 350,
      },
    toolTipArrow: {
        [theme.breakpoints.down('sm')]: {
            left: '285px !important'
        },
    }
}));

const validationSchema = Yup.object({
    displayName: Yup.string().required('Display Name is required').test(
        "Unique Display Name",
        "Display Name already in use",
        (value) => {
            return new Promise((resolve, reject) => {
                http.get('search/users/displayName', {
                    params: {
                        displayName: value,
                    },
                }).then(res => {
                    // exists
                    if (res.data.length === 0) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }).catch(err => {
                    // note exists
                    resolve(false)
                })
            })
        }
      ),
    bio: Yup.string().required('Bio is required'),
    occupation: Yup.string(),
    age: Yup.number().typeError('You must specify a number'),
    location: Yup.string(),
    beenGuestCheck: Yup.boolean(),
    beenGuestDesc: Yup.string(),
    isShowAbout: Yup.boolean(),
    isOccupationShow: Yup.boolean(),
    isAgeShow: Yup.boolean(),
    isLocationShow: Yup.boolean(),
    weightGoal: Yup.number().typeError('You must specify a number'),
});

const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: '#000000'
    },
    tooltip: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        boxShadow: 'none',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '100%',
        letterSpacing: '0.25px',
        zIndex: 999,
        padding: '16px',
        borderRadius: '8px',
        // [theme.breakpoints.down('sm')]: {
        //     right: '113px',
        // },
    },
  }))(Tooltip);

const ProfileForm: React.FC<any> = (props) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [weightGoalType, SetWeightGoalType] = React.useState<string>('lbs')
    const { enqueueSnackbar } = useSnackbar()
    // eslint-disable-next-line
    const [profile, setProfile] = React.useState<ProfileUpdateRequest>({
        displayName: props.profile?.displayName ?? '',
        bio: props.profile?.bio ?? '',
        occupation: props.profile?.occupation?.occupation ?? '',
        age: props.profile?.age?.age ?? 0,
        location: props.profile?.location?.location ?? '',
        beenGuestCheck: props.profile?.beenGuest?.isCheck ?? true,
        beenGuestDesc: props.profile?.beenGuest?.desc ?? '',
        isShowAbout: props.profile?.isShowAbout ?? true,
        isOccupationShow: props.profile?.occupation?.isOccupationShow ?? true,
        isAgeShow: props.profile?.age?.isAgeShow ?? true,
        isLocationShow: props.profile?.location?.isLocationShow ?? true,
        weightGoal: props.profile?.about?.weightGoal?.data ?? 0
    });
    const user = Auth.userProperties.userData;
    const history = useHistory();
    const [tutorial, setTutorial] = React.useState<boolean>(false)
    const [checkScroll, setCheckScroll] = React.useState<boolean>(true)

    // React.useEffect(() => {
    //     setProfile({
    //         displayName: props.profile?.displayName ?? '',
    //         bio: props.profile?.bio ?? 'Test',
    //         occupation: props.profile?.occupation ?? '',
    //         age: props.profile?.age ?? 0,
    //         location: props.profile?.location ?? '',
    //         beenGuestCheck: false,
    //         beenGuestDesc: props.profile?.beenGuest?.desc ?? '',
    //         isShowAbout: props.profile?.isShowAbout ?? false,
    //         isOccupationShow: props.profile?.isOccupationShow ?? false,
    //         isAgeShow: props.profile?.isAgeShow ?? false,
    //         isLocationShow: props.profile?.isLocationShow ?? false,
    //     });
    // }, [props.profile]);

    const handleSubmit = async (values: ProfileUpdateRequest, { setSubmitting } : FormikHelpers<ProfileUpdateRequest>) => {
        setSubmitting(true);
        // if (user.userData.statusBooking === 'Waiting') localStorage.setItem('temporaryProfileSubmit', 'true')
        try {
            values.weightGoalType = weightGoalType
            // const avatar = await props.checkAvatar()
            // if (!avatar) {
            //     enqueueSnackbar('Submit Profile Failed. Please tet up your profile picture', {variant: 'error'})
            //     return
            // }
            await updateUserAbout(values);
            Auth.updateUserProperties('displayName', values.displayName);
            enqueueSnackbar('Submit Profile Success', {variant: 'success'})
            props.changeStatusStep('step1')
            if (user.statusFlow === '0') history.push('/profile-submitted')
        } catch (error) {
            enqueueSnackbar('Submit Profile Failed', {variant: 'error'})
        } finally {
            setSubmitting(false);
        }
    }

    const handleOnScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
        const scrolledToBottom = scrollTop >= 720
        if (scrolledToBottom) {
            setCheckScroll(false)
            setTutorial(true)
            window.removeEventListener('scroll', handleOnScroll)
        }
    }
    const handleTooltipClose = () => {
        setTutorial(false)
    }

    React.useEffect(() => {
        if (checkScroll) window.addEventListener('scroll', handleOnScroll)
        // eslint-disable-next-line
    }, [checkScroll])

    return (
        <Formik
            enableReinitialize
            initialValues={profile}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, getFieldProps, getFieldMeta, submitCount, setFieldValue }) => (
                <Form noValidate>
                    <ConnectedFocusError />
                    {tutorial && (
                        <div style={
                            {
                                height: '100vh',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                left: 0,
                                top: 0,
                                position: 'fixed',
                                zIndex: 1000
                            }
                        }>

                        </div>
                    )}
                    <Box width={'100%'} maxWidth={475} margin={'auto'} padding={'20px'} borderRadius={'20px'} style={{backgroundColor: '#FAFAFA'}}>
                        <Grid container className={classes.formSwitchContainer} style={{marginTop: '0px'}}>
                            <Grid item md={12} className={classes.switchTitleContainer}>
                                <Typography  className={classes.label}>DISPLAY NAME <span style={{color: '#EF5136'}}>&#9679;</span></Typography>
                            </Grid>
                        </Grid>
                        <Field
                            as={InputText}
                            placeholder="Display Name"
                            id="displayName"
                            autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps('displayName')}
                            meta={getFieldMeta('displayName')}
                            isSubmitted={submitCount > 0}
                        />
                        <Grid container className={classes.formSwitchContainer}>
                            <Grid item md={12} className={classes.switchTitleContainer}>
                                <Typography  className={classes.label}><b>SHORT BIO</b> <span style={{color: '#EF5136'}}>&#9679;</span></Typography>
                            </Grid>
                        </Grid>
                        <Field
                            as={InputTextArea}
                            placeholder="Your story..."
                            id="bio"
                            rows={1}
                            className={classes.inputCustomWidth}
                            {...getFieldProps('bio')}
                            meta={getFieldMeta('bio')}
                            isSubmitted={submitCount > 0}
                        />
                    </Box>
                    <Box width={'100%'} maxWidth={475} margin={'auto'} padding={'20px'} borderRadius={'20px'} style={{backgroundColor: '#FAFAFA'}} marginTop={'30px'}>
                        <Typography  className={classes.optionalTitle}>OPTIONAL</Typography>
                        <Typography  className={classes.optionalDesc}>You may choose to show or hide the following information. </Typography>
                        <br />

                        <div className={classes.label}>Occupation</div>
                        <Grid container className={classes.formOptional}>
                            <Grid item xs={10}>
                                <Field
                                    as={InputText}
                                    placeholder="A Job"
                                    id="occupation"
                                    className={classes.inputCustomWidth}
                                    {...getFieldProps('occupation')}
                                    meta={getFieldMeta('occupation')}
                                    isSubmitted={submitCount > 0}
                                />
                            </Grid>
                            <LightTooltip
                                classes={{ tooltip: classes.customWidth, arrow: classes.toolTipArrow }}
                                interactive
                                arrow
                                title={
                                    <React.Fragment>
                                        <div style={{display: 'flex'}}>
                                            <div>
                                                Click the visibillity toggles on/off to your desired privacy.
                                            </div>
                                            <div onClick={() => handleTooltipClose()}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    marginLeft: '10px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                X
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                open={tutorial}
                            >
                                <Grid item xs={2} style={{textAlign: 'right'}}>
                                    <Field
                                        component={SwitchIos}
                                        color="default"
                                        id="isOccupationShow"
                                        checked={values.isOccupationShow}
                                        {...getFieldProps('isOccupationShow')}
                                    />
                                </Grid>
                            </LightTooltip>
                        </Grid>

                        <div className={classes.label}>Age</div>
                        <Grid container className={classes.formSwitchContainer}>
                            <Grid item xs={10}>
                                <Field
                                    as={InputText}
                                    placeholder="Age"
                                    id="age"
                                    fullWidth={false}
                                    {...getFieldProps('age')}
                                    meta={getFieldMeta('age')}
                                    isSubmitted={submitCount > 0}
                                />
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                <Field
                                    component={SwitchIos}
                                    color="default"
                                    id="isAgeShow"
                                    checked={values.isAgeShow}
                                    {...getFieldProps('isAgeShow')}
                                />
                            </Grid>
                        </Grid>

                        <div className={classes.label}>Location</div>
                        <Grid container className={classes.formSwitchContainer}>
                            <Grid item xs={10}>
                                <Field
                                    as={InputTextArea}
                                    placeholder="A Place"
                                    id="location"
                                    className={classes.inputCustomWidth}
                                    {...getFieldProps('location')}
                                    meta={getFieldMeta('location')}
                                    isSubmitted={submitCount > 0}
                                />
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                <Field
                                    component={SwitchIos}
                                    color="default"
                                    id="isLocationShow"
                                    checked={values.isLocationShow}
                                    {...getFieldProps('isLocationShow')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.formSwitchContainer} justify="space-between">
                            <Box>
                                <div className={classes.label} style={{textTransform: 'uppercase'}}>Have you been guest to Movara?</div>
                                <RadioGroup row name="beenGuestCheck" value={values.beenGuestCheck} onChange={(event) => {
                                    const val = (event.currentTarget.value === 'true')
                                    setFieldValue('beenGuestCheck', val)
                                    }}>
                                    <FormControlLabel value={true} control={<Radio color="primary"/>} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary"/>} label="No" />
                                </RadioGroup>
                            </Box>
                            <Box>

                            </Box>
                        </Grid>
                        <Grid container className={classes.formSwitchContainer} justify="space-between">
                            <Grid item xs={10}>
                                <Field
                                    as={InputText}
                                    placeholder="Summer after highschool"
                                    disabled={!values.beenGuestCheck}
                                    className={classes.inputCustomWidth}
                                    {...getFieldProps('beenGuestDesc')}
                                    meta={getFieldMeta('beenGuestDesc')}
                                    isSubmitted={submitCount > 0}
                                />
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'right'}}>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.formSwitchContainer} justify="space-between">
                            <Box className={classes.switchTitleContainer}>
                                <div className={classes.label} style={{textTransform: 'uppercase'}}>Would you like to show your progress on your about page?</div>
                            </Box>
                            <Box>
                                <Field
                                    component={SwitchIos}
                                    color="default"
                                    id="isShowAbout"
                                    checked={values.isShowAbout}
                                    {...getFieldProps('isShowAbout')}
                                />
                            </Box>
                        </Grid>

                    </Box>
                    <Box pt={7} width="100%" textAlign="center">
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit Profile'}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
}

export default ProfileForm;
