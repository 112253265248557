import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SpoonForkIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 33"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <path d="M8.73627 21.246L8.73627 20.246C8.47105 20.246 8.2167 20.3513 8.02916 20.5389C7.84162 20.7264 7.73627 20.9807 7.73627 21.246L8.73627 21.246ZM10.6841 21.246L11.6841 21.246C11.6841 20.6937 11.2364 20.246 10.6841 20.246L10.6841 21.246ZM10.6841 29.3333L10.6841 30.3333C10.9493 30.3333 11.2037 30.228 11.3912 30.0404C11.5787 29.8529 11.6841 29.5985 11.6841 29.3333H10.6841ZM8.73628 29.3333L7.73628 29.3333C7.73628 29.8856 8.18399 30.3333 8.73628 30.3333L8.73628 29.3333ZM8.71091 11.0725L8.71093 21.2459L10.7109 21.2459L10.7109 11.0724L8.71091 11.0725ZM8.73627 22.246L10.6841 22.246L10.6841 20.246L8.73627 20.246L8.73627 22.246ZM9.68408 21.246L9.68409 29.3333L11.6841 29.3333L11.6841 21.246L9.68408 21.246ZM10.6841 28.3333L8.73628 28.3333L8.73628 30.3333L10.6841 30.3333L10.6841 28.3333ZM9.73628 29.3333L9.73627 21.246L7.73627 21.246L7.73628 29.3333L9.73628 29.3333Z" fill={props.fill || '#6D6D6D'}/>
            <path d="M6.66669 7.73006H7.66669H6.66669ZM11.7536 7.73005C11.7536 8.83871 11.4616 9.36612 11.1789 9.63091C10.8839 9.9072 10.4136 10.0724 9.71056 10.0724L9.71056 12.0724C10.6886 12.0724 11.7398 11.8458 12.5461 11.0906C13.3647 10.3239 13.7536 9.18008 13.7536 7.73005H11.7536ZM9.71056 10.0724C9.00753 10.0724 8.53692 9.9072 8.24172 9.63079C7.95885 9.36594 7.66669 8.83855 7.66669 7.73006H5.66669C5.66669 9.18025 6.05594 10.3241 6.87475 11.0907C7.68122 11.8458 8.73254 12.0724 9.71056 12.0724L9.71056 10.0724ZM8.67837 1.33331V11.0723H10.6784V1.33331H8.67837ZM7.66669 7.73006V1.33331L5.66669 1.33331V7.73006H7.66669ZM11.7536 1.33331V7.73005H13.7536V1.33331H11.7536Z" fill={props.fill || '#6D6D6D'}/>
            <path d="M20.3511 21.2985V20.2985C19.7988 20.2985 19.3511 20.7462 19.3511 21.2985H20.3511ZM22.2563 21.2985H23.2563C23.2563 20.7462 22.8086 20.2985 22.2563 20.2985V21.2985ZM22.2563 29.3333V30.3333C22.5215 30.3333 22.7759 30.228 22.9634 30.0404C23.1509 29.8529 23.2563 29.5985 23.2563 29.3333H22.2563ZM20.3511 29.3333H19.3511C19.3511 29.8856 19.7988 30.3333 20.3511 30.3333V29.3333ZM20.3038 11.2537L20.3038 21.2994L22.3038 21.2994L22.3038 11.2537L20.3038 11.2537ZM20.3511 22.2985H22.2563V20.2985H20.3511V22.2985ZM21.2563 21.2985L21.2563 29.3333H23.2563L23.2563 21.2985H21.2563ZM22.2563 28.3333H20.3511V30.3333H22.2563V28.3333ZM21.3511 29.3333V21.2985H19.3511V29.3333H21.3511ZM17.3828 7.76095C17.3828 9.13337 17.7613 10.2641 18.5113 11.058C19.2646 11.8555 20.2788 12.192 21.3033 12.192L21.3033 10.192C20.7149 10.192 20.2689 10.0061 19.9652 9.68463C19.6581 9.35958 19.3828 8.77483 19.3828 7.76095H17.3828ZM21.3033 12.192C22.3278 12.192 23.342 11.8555 24.0953 11.058C24.8452 10.2641 25.2237 9.13337 25.2237 7.76095H23.2237C23.2237 8.77483 22.9484 9.35958 22.6414 9.68463C22.3377 10.0061 21.8916 10.192 21.3033 10.192L21.3033 12.192ZM25.2237 7.76095C25.2237 6.40677 24.8581 4.94419 24.2536 3.79525C23.692 2.7278 22.7074 1.55045 21.3034 1.55072L21.3038 3.55072C21.513 3.55068 21.9884 3.7853 22.4836 4.72647C22.9359 5.58614 23.2237 6.72884 23.2237 7.76095H25.2237ZM21.3034 1.55072C19.8998 1.55099 18.9152 2.72786 18.3533 3.79535C17.7487 4.94421 17.3828 6.40667 17.3828 7.76095H19.3828C19.3828 6.72893 19.6707 5.58644 20.1232 4.72684C20.6184 3.78586 21.0942 3.55076 21.3038 3.55072L21.3034 1.55072Z" fill={props.fill || '#6D6D6D'}/>

        </SvgIcon>
    );
}
