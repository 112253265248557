import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Paper, Box, createStyles, Theme, Typography, Avatar, IconButton, LinearProgress, withStyles, CircularProgress } from "@material-ui/core";

import { useStyles } from "./styles";
import history from "services/data/history";
import { getBookingById } from "services/domain/admin/bookings";
import { BookingData } from "interfaces/booking";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
// import { Toast } from 'services/util/toast';
import { ArrowBack } from "@material-ui/icons";
import StatusBooking from "components/_newDesign/status/StatusBooking";
import { getUserByEmail } from "services/domain/users";
import { CalendarIcon, VerifiedUserIcon, ClockIcon } from "components/_newIcon";
import Duration from "components/_newDesign/status/StatusDuration";
import TypographyMui from "components/_newDesign/typography/Typography";
import InputText from "components/_newDesign/input/InputText";
import InputTextArea from "components/_newDesign/input/InputTextArea";
import SwitchButton from "components/_newDesign/switch/SwitchIos";
import Button from "components/_newDesign/button/Button";
import ModalConfrimation from "components/_newDesign/alert/ModalConfirmation";
import { rescheduleAproval } from "services/domain/admin/bookings";
import { ApprovalReschedulePaylaod } from "interfaces/booking";
import { useSnackbar } from "notistack";

interface AdminFormState {
  formState: BookingData;
}

type DateRange = {
  startDate: Date;
  endDate: Date;
};

type UserBooking = {
  id: string;
  dateRange: DateRange;
  paymentStatus: string;
};
interface FormikState {
  email: string;
  phoneNumber: string;
  address: string;
  bookingData: UserBooking[];
  firstName: string;
  lastName: string;
}
interface BookingDetails {
  startDate: Date;
  endDate: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
}

const l_startDate = moment()
  .add(7 - moment().isoWeekday(), "days")
  .toDate();
const l_endDate = moment(l_startDate).add(1, "week").toDate();
const initialBookingDetails: BookingDetails = {
  startDate: l_startDate,
  endDate: l_endDate,
  coach_id: "",
  coach_name: "",
  totalWeeks: 1,
};

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 17,
      borderRadius: 10,
      boxShadow: "inset 1px 1px 8px rgba(0, 0, 0, 0.1)",
    },
    colorPrimary: {
      backgroundColor: "#F8F8F8",
    },
    bar: {
      borderRadius: 5,
    },
  }),
)(LinearProgress);

const BookingReschedule = () => {
  const { id } = useParams<{ id: string }>();

  // TODO pretty sure this piece of state is not needed
  // eslint-disable-next-line
  const [bookingDetails, setBookingDetails] = React.useState<BookingDetails>(initialBookingDetails);

  const [reason, setReason] = React.useState<string>("");
  const [approve, setApprove] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [changeCoach, setChangeCoach] = React.useState<boolean>(false);
  const booking = useSelector(({ admin: { formState } }: { admin: AdminFormState }) => {
    return formState;
  });
  if (booking === null || booking === undefined) history.push("/bookings");
  const [userProfile, setUserProfile] = React.useState<any>({});
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fetch = async () => {
    try {
      const user = await getUserByEmail({ email: booking.email || "" });
      setUserProfile(user.data);

      const bookData = await getBookingById(id);
      setBookingDetails(bookData.data);
    } catch (e) {}
  };

  // refresh event should not work, since data is provided from the redux while click the edit button
  React.useEffect(() => {
    if (booking && !booking.email) {
      goBack();
    }
    if (booking && booking.rescheduleStatus === "Approved" && booking.reschedule) {
      setApprove(true);
      setReason(booking.reschedule[booking.reschedule.length - 1].reasonResponse);
    }
    if (booking && booking.rescheduleStatus === "Decline" && booking.reschedule) {
      setApprove(false);
      setReason(booking.reschedule[booking.reschedule.length - 1].reasonResponse);
    }
    if (booking && booking.reschedule && booking.coachName !== booking.reschedule[booking.reschedule.length - 1].coachName) {
      setChangeCoach(true);
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setShowModal(false);
    if (booking && booking.reschedule) {
      try {
        setLoading(true);
        const payload: ApprovalReschedulePaylaod = {
          bookingRescheduleId: booking.reschedule[booking.reschedule.length - 1]._id,
          booking_id: booking._id,
          reasonResponse: reason,
          approval: approve,
        };
        await rescheduleAproval(payload);
        enqueueSnackbar("This request has been approved and changes had been made to guest booking.", { variant: "success" });
        history.goBack();
      } catch (error) {
        console.error("error", error);
        enqueueSnackbar("Somthing wrong, please try again later.", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getColor = (status) => {
    switch (status) {
      case "Need Approval":
        return "#ED9E9A";
      case "Approved":
        return "#C4CE7D";
      case "Decline":
        return "#FFD557";
      default:
        return "rgba(0, 0, 0, 0.05)";
    }
  };

  const ModalContent = () => {
    return (
      <>
        <TypographyMui variant="mov_subtitle1_bold">Are you Sure?</TypographyMui>
        <br />
        <br />
        <TypographyMui variant="mov_body1">
          You’re about to {approve ? "approve" : "decline"} this guest <b>{`${userProfile?.name?.firstName || ""} ${userProfile?.name?.lastName || ""}`}</b> request to
          reschedule active program on their Movara Home Program {booking?.totalWeeks || 0} Weeks.
        </TypographyMui>
        <br />
        {approve && (
          <TypographyMui variant="mov_body1">
            Their program will restart on{" "}
            <b>{moment(booking && booking.reschedule ? booking?.reschedule[booking?.reschedule?.length - 1].startDate : new Date()).format("MMM-DD-YYYY")}</b> with{" "}
            {moment(booking?.endDate).diff(moment(booking?.startDate), "weeks") - (booking?.weekOngoing || 0)} weeks remaining. It will be recorded as separated booking with
            waiting status.
          </TypographyMui>
        )}
        <br />
        <TypographyMui variant="mov_body1">Disclaimer: Please check if the date and coach is correct as you cannot undo this action.</TypographyMui>
      </>
    );
  };

  return (
    <>
      <ModalConfrimation show={showModal} onClose={() => setShowModal(false)} onOk={handleSubmit} content={<ModalContent />} />
      <Box
        maxWidth="100%"
        height="80px"
        style={{
          background: booking ? getColor(booking.rescheduleStatus) : "rgba(0, 0, 0, 0.05)",
          backgroundBlendMode: "multiply",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Box display={"flex"} alignItems="center" style={{ height: "100%", justifyContent: "flex-start" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"} width="40px" height="40px" padding={"10px"}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack style={{ color: "#FFFFFF" }} />
            </IconButton>
          </Box>
          <Box display="flex" ml={1} justifyContent="center" textAlign={"left"}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "150%",
                color: "#FFFFFF",
              }}
            >
              Request to Reschedule Program
            </Typography>
          </Box>
          <Box flexGrow={1} display={"flex"} flexDirection="row-reverse">
            <Box
              display={"flex"}
              padding="12px 16px"
              border="1px solid #E6E6E6"
              borderRadius={"12px"}
              color="#FFFFFF"
              fontSize={"16px"}
              fontWeight={600}
              fontFamily="Montserrat"
              style={{ background: "rgba(0, 0, 0, 0.1)" }}
            >
              {booking?.rescheduleStatus || ""}
            </Box>
          </Box>
        </Box>
      </Box>
      <Paper className={classes.formContainer}>
        <Box maxWidth="756px" marginLeft="auto" marginRight="auto">
          <Box display={"flex"} justifyContent="space-between" alignItems={"start"}>
            <Box display={"flex"} flexDirection={"row"} mb={"42px"}>
              <Box mx={1}>
                <Avatar src={userProfile.avatar ?? dummyAvatar} />
              </Box>
              <Box>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "150%",
                    marginLeft: "8px",
                    paddingBottom: "5px",
                  }}
                >
                  {`${userProfile?.name?.firstName || ""} ${userProfile?.name?.lastName || ""}`}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "150%",
                    color: "#9A9A9A",
                    marginLeft: "8px",
                    paddingBottom: "5px",
                  }}
                >
                  {userProfile?.email || ""}
                </Typography>
                <Box>
                  <StatusBooking status={booking?.statusBooking || ""} />
                </Box>
              </Box>
            </Box>
            <Box width={"120px"} display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"} textAlign="center">
              <Duration
                status={booking?.statusBooking || ""}
                startDate={booking?.startDate || new Date()}
                endDate={booking?.endDate || new Date()}
                totalWeeks={booking?.totalWeeks || 0}
                weekOngoing={booking?.weekOngoing || 0}
              />
            </Box>
          </Box>
          <Box my={3} width="100%" height="auto" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px" px="15px" py="21px">
            <Box display={"flex"} alignItems="center" justifyContent="space-between">
              <TypographyMui variant="mov_overline2">RESCHEDULE REQUEST FORM</TypographyMui>
              <TypographyMui variant="mov_overline2">{booking?.reschedule?.length || 0} time(s)</TypographyMui>
            </Box>
            <Box mt={"25px"} display={"flex"} alignItems="center" justifyContent="space-between">
              <TypographyMui variant="mov_overline2">BOOKING ID</TypographyMui>
              <TypographyMui variant="mov_overline2"># {booking?._id || ""}</TypographyMui>
            </Box>
            {booking && booking.rescheduleStatus && booking.rescheduleStatus === "Need Approval" && (
              <Box mt={"25px"} style={{ background: "white" }} p="15px" borderRadius={"7px"}>
                <Box display={"flex"}>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "120%",
                      textTransform: "uppercase",
                      color: "#414141",
                    }}
                  >
                    Active Program Duration
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                        lineHeight: "150%",
                        color: "#000000",
                      }}
                    >
                      Week {booking?.weekOngoing || 0}
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "150%",
                        color: "#000000",
                      }}
                    >
                      TOTAL {booking?.totalWeeks || moment(booking?.endDate).diff(moment(booking?.startDate), "weeks")} weeks
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "120%",
                        color: "#9A9A9A",
                      }}
                    >
                      {(booking?.totalWeeks || moment(booking?.endDate).diff(moment(booking?.startDate), "weeks")) - (booking?.weekOngoing || 0)} weeks remains
                    </Typography>
                  </Box>
                </Box>
                <Box mt={"10px"} width="100%">
                  <BorderLinearProgress
                    variant="determinate"
                    value={((booking?.weekOngoing || 0) / (booking?.totalWeeks || moment(booking?.endDate).diff(moment(booking?.startDate), "weeks"))) * 100}
                  />
                </Box>
                <Box mt={"10px"} display="flex" alignItems="center">
                  <TypographyMui variant="mov_overline2">COACH </TypographyMui>
                  <Box
                    marginLeft={"8px"}
                    p="4px 8px"
                    borderRadius="20px"
                    style={{
                      background: "rgba(0, 0, 0, 0.05)",
                    }}
                    display="flex"
                    alignItems={"center"}
                  >
                    <Avatar
                      src={dummyAvatar}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "8px",
                      }}
                    />
                    <TypographyMui variant="mov_body2">{booking?.coachName}</TypographyMui>
                  </Box>
                </Box>
              </Box>
            )}

            <Box mt={"25px"} display={"flex"} alignItems="center">
              <TypographyMui
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <VerifiedUserIcon /> &nbsp; COACH
              </TypographyMui>
            </Box>
            {changeCoach ? (
              <Box display={"flex"} alignItems="center" marginTop={"13px"}>
                <Box
                  width={"fit-content"}
                  p="4px 8px"
                  borderRadius="20px"
                  style={{
                    background: "rgba(0, 0, 0, 0.05)",
                  }}
                  display="flex"
                  alignItems={"center"}
                >
                  <Avatar
                    src={dummyAvatar}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                    }}
                  />
                  <TypographyMui variant="mov_body2">{booking?.coachName}</TypographyMui>
                </Box>
                <TypographyMui variant="mov_body2">&nbsp;To&nbsp;</TypographyMui>
                <Box
                  width={"fit-content"}
                  p="4px 8px"
                  borderRadius="20px"
                  style={{
                    background: "rgba(0, 0, 0, 0.05)",
                  }}
                  display="flex"
                  alignItems={"center"}
                >
                  <Avatar
                    src={dummyAvatar}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                    }}
                  />
                  <TypographyMui variant="mov_body2">{booking && booking.reschedule && booking?.reschedule[booking.reschedule.length - 1].coachName}</TypographyMui>
                </Box>
              </Box>
            ) : (
              <Box width={"fit-content"} marginTop={"13px"} p="4px 8px" borderRadius="20px" style={{ background: "rgba(0, 0, 0, 0.05)" }} display="flex" alignItems={"center"}>
                <Avatar
                  src={dummyAvatar}
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <TypographyMui variant="mov_body2">{booking?.coachName}</TypographyMui>
              </Box>
            )}
            <Box mt={"25px"} display={"flex"} alignItems="center">
              <TypographyMui
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <ClockIcon /> &nbsp; SELECT DURATION
              </TypographyMui>
            </Box>
            <Box mt="10px" mb="40px" width={"fit-content"} padding=" 10px 14px" style={{ background: "white" }} borderRadius="10px" border="1px solid #E6E6E6">
              <b>{moment(booking?.endDate).diff(moment(booking?.startDate), "weeks") - (booking?.weekOngoing || 0)} W</b>
            </Box>
            <Box mt={"25px"} display={"flex"} alignItems="center">
              <TypographyMui
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <CalendarIcon /> &nbsp; SELECT DATE
              </TypographyMui>
            </Box>
            <Box ml="15px" mt={"20px"} display={"flex"} alignItems="center">
              <TypographyMui variant="mov_overline2">ORIGINAL DATE</TypographyMui>
            </Box>
            <Box width={"250px"} ml="15px" mt={"14px"} display={"flex"} alignItems="center" justifyContent="space-between">
              <InputText disabled value={`${moment(booking?.startDate).format("MMM-DD-YYYY")} to ${moment(booking?.endDate).format("MMM-DD-YYYY")}`} />
            </Box>
            <Box ml="15px" mt={"14px"} display={"flex"} alignItems="center">
              <TypographyMui variant="mov_overline2">RESTART DATE</TypographyMui>
            </Box>
            <Box width={"250px"} ml="15px" mt={"14px"} display={"flex"} alignItems="center" justifyContent="space-between">
              <InputText
                disabled
                value={`${moment(booking && booking.reschedule ? booking?.reschedule[booking?.reschedule?.length - 1].startDate : new Date()).format(
                  "MMM-DD-YYYY",
                )} to ${moment(booking && booking.reschedule ? booking?.reschedule[booking?.reschedule?.length - 1].endDate : new Date()).format("MMM-DD-YYYY")}`}
              />
            </Box>
            <Box mt={"25px"} display={"flex"} alignItems="center">
              <TypographyMui variant="mov_overline2">REASON TO RESCHEDULE</TypographyMui>
            </Box>
            <Box ml="15px" mt={"14px"} display={"flex"} alignItems="center" justifyContent="space-between">
              <InputTextArea rowsMin={1} disabled value={booking && booking.reschedule ? booking?.reschedule[booking?.reschedule?.length - 1].reasonToReschedule : ""} />
            </Box>
          </Box>
          <Box my={3} width="100%" height="auto" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px" px="15px" py="21px">
            <Box display={"flex"} alignItems="center">
              <Avatar src={dummyAvatar} />
              <TypographyMui variant="mov_subtitle1_bold">&nbsp;Coach Response</TypographyMui>
            </Box>
            <Box ml="15px" display={"flex"} alignItems="center" justifyContent={"space-between"}>
              <TypographyMui variant="mov_body2">Do you approve this request?</TypographyMui>
              {booking && booking.rescheduleStatus && (
                <Box textAlign={"center"}>
                  <SwitchButton
                    disabled={booking && booking.rescheduleStatus !== "Need Approval"}
                    checked={approve}
                    color="default"
                    onChange={(e) => setApprove(e.target.checked)}
                  />
                  <TypographyMui variant="mov_caption1">{approve ? "Approve" : "Decline"}</TypographyMui>
                </Box>
              )}
            </Box>
            <Box mt={"25px"} display={"flex"} alignItems="center">
              <TypographyMui variant="mov_overline2">
                REASON TO {approve ? "APPROVE" : "DECLINE"} <span style={{ color: "#EF5136" }}>&#9679;</span>
              </TypographyMui>
            </Box>
            <Box ml="15px" mt={"14px"} display={"flex"} alignItems="center" justifyContent="space-between">
              <InputTextArea
                disabled={booking && booking.rescheduleStatus !== "Need Approval"}
                color="primary"
                rowsMin={1}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </Box>
          {booking && booking.rescheduleStatus && booking.rescheduleStatus === "Need Approval" && (
            <Box mt={"25px"} display={"flex"} mb={2} justifyContent="center" alignItems={"center"} flexDirection="column" gridGap={"20px"}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                style={{
                  width: "200px",
                  padding: "10px 14px",
                }}
                disabled={reason === "" || !reason || loading}
                onClick={() => setShowModal(true)}
                endIcon={loading ? <CircularProgress size={18} /> : ""}
              >
                Save Change
              </Button>
              <Button
                onClick={() => history.goBack()}
                color="default"
                size="small"
                style={{
                  width: "200px",
                  padding: "10px 14px",
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default BookingReschedule;
