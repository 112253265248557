import MTable from "components/_newDesign/table";
import React, { FC, useCallback, useState, useMemo } from "react";
import { getDailyTotals } from "services/domain/admin/accounting";
import { Column } from "react-table";
import { Paper, Box, Typography, Tooltip } from "@material-ui/core";
import TypographyMui from "components/_newDesign/typography/Typography";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Toast } from "services/util/toast";
import ButtonIcon from "components/button/ButtonIcon";
import { formatCentsToDollars } from "services/util";
// import generateCSV from "services/util/generateCSV";
import downloadJournalEntries from "services/util/handleDownloadJournalEntries/downloadJournalEntries";

const JournalEntries: FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndexMem, setPageIndexMem] = useState<any>(0);
  const [pageSizeMem, setPageSizeMem] = useState<any>(0);
  const [sortByMem, setSortByMem] = useState<any>("asc");
  const [refresh, setRefresh] = useState<boolean>(false);

  const tableComp = React.useRef<any>(null);

  // generates a csv file following the format from KACP-2643
  //! we are now generating excel files - leaving function in case they want it switched back to csvs
  //   const handleClickDownloadCSV = (row: any) => {
  //     try {
  //       // generate a csv file
  //       const csvHeaders = ["GL CODE", "ACCOUNT NAME", "AMOUNT", "DATE", "REF", "MEMO"];

  //       const csvRows = [
  //         ["2002-20", "Checking Account", row.checkingAccountDailyChange, row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
  //         ["7012-00", "Movara Home Expenses", row.movaraHomeExpensesDailyChange, row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
  //         ["3500-15", "Movara Home Unearned Revenue", row.movaraHomeUnearnedRevenueDailyChange, row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
  //         ["6030-99", "Movara Home Revenue", row.movaraHomeRevenueDailyChange, row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
  //         ["6030-98", "Movara Home Refunds", row.movaraHomeRefundsDailyChange, row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
  //       ];

  //       generateCSV(csvHeaders, csvRows, "dailyJournalEntries");
  //     } catch (error) {
  //       console.error("error", error);

  //       const errorToast = Toast({});
  //       errorToast.fire({
  //         icon: "error",
  //         title: "Failed to generate CSV",
  //         text: "Please refresh the page and try again.",
  //       });
  //     }
  //   };

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: "Date Created",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.dateCreated}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Checking Account Daily Change",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.checkingAccountDailyChange}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Checking Account New Total",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.checkingAccountNewTotal}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Expense Daily Change",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeExpensesDailyChange}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Expense New Total",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeExpensesNewTotal}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Unearned Revenue Daily Change",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeUnearnedRevenueDailyChange}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Unearned Revenue New Total",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeUnearnedRevenueNewTotal}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Revenue Daily Change",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeRevenueDailyChange}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Revenue New Total",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeRevenueNewTotal}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Refunds Daily Change",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeRefundsDailyChange}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Movara Home Refunds New Total",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.movaraHomeRefundsNewTotal}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Download as Excel",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">
              <Tooltip title="Download as Excel">
                <ButtonIcon
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    downloadJournalEntries(props.row.original);
                  }}
                  startIcon={<GetAppIcon />}
                />
              </Tooltip>
            </TypographyMui>
          </Box>
        ),
      },
    ],
    [],
  );

  const fetchDailyTotals = useCallback(async ({ pageIndex, pageSize, sortBy }: { pageIndex: any; pageSize: any; sortBy: any }) => {
    try {
      setLoading(true);

      const result = await getDailyTotals({
        existData: pageIndex * pageSize,
        limitData: pageSize,
      });

      const { data } = result;

      const formattedData = data.map((item: any) => {
        return {
          ...item,
          dateCreated: moment(item.dateCreated).subtract(1, "day").format("MM/DD/YYYY hh:mm:ss a"),
          checkingAccountDailyChange: formatCentsToDollars(item.checkingAccountDailyChange),
          checkingAccountNewTotal: formatCentsToDollars(item.checkingAccountNewTotal),
          movaraHomeExpensesDailyChange: formatCentsToDollars(item.movaraHomeExpensesDailyChange),
          movaraHomeExpensesNewTotal: formatCentsToDollars(item.movaraHomeExpensesNewTotal),
          movaraHomeUnearnedRevenueDailyChange: formatCentsToDollars(item.movaraHomeUnearnedRevenueDailyChange),
          movaraHomeUnearnedRevenueNewTotal: formatCentsToDollars(item.movaraHomeUnearnedRevenueNewTotal),
          movaraHomeRevenueDailyChange: formatCentsToDollars(item.movaraHomeRevenueDailyChange),
          movaraHomeRevenueNewTotal: formatCentsToDollars(item.movaraHomeRevenueNewTotal),
          movaraHomeRefundsDailyChange: formatCentsToDollars(item.movaraHomeRefundsDailyChange),
          movaraHomeRefundsNewTotal: formatCentsToDollars(item.movaraHomeRefundsNewTotal),
        };
      });

      setDailyTotals(formattedData);
      setPageCount(result.meta.pagination.totalPage);
      setPageIndexMem(pageIndex);
      setPageSizeMem(pageSize);
      setSortByMem(sortBy);
    } catch (e) {
      console.error(e);

      const errorToast = Toast({});
      errorToast.fire({
        icon: "error",
        title: "Failed to load Journal Entries",
        text: "Please refresh the page to try again.",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Typography variant="h4">Movara Home Journal Entries</Typography>
      <Box mt={3}>
        <Paper>
          <MTable columns={columns} data={dailyTotals} onFetchData={fetchDailyTotals} pageCount={pageCount} refreshPage={refresh} loading={loading} ref={tableComp} />
        </Paper>
      </Box>
    </>
  );
};

export default JournalEntries;
