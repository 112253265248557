import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: theme.spacing(1),
        height: '18px',
        '& .MuiChip-colorPrimary': {
            color: theme.palette.secondary.main,
        },
    },
    block: {
        backgroundColor: '#F8F8F8',
        boxShadow: 'inset 1px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: theme.spacing(2),
    },
    status: {
        borderRadius: theme.spacing(1),
        height: '17px',
        backgroundColor: '#F2F2F2',
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
    },
    progress: {
        borderRadius: theme.spacing(1),
        height: '17px',
        backgroundColor: '#F2F2F2',
        color: '#000000',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
    },
    active: {
        borderRadius: theme.spacing(1),
        height: '17px',
        backgroundColor: '#FFA21E',
        color: '#000000',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '11px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
    },
}));

interface Props extends ChipProps {
    type?: 'normal' | 'block' | 'status' | 'progress' | 'active',
}

const MChip: React.FC<Props> = ({ type = 'normal', ...chipProps }) => {
    const classes = useStyles();
    let className;
    switch (type) {
        case 'block':
            className = classes.block;
            break;
        case 'normal':
            className = classes.root;
            break;
        case 'status':
            className = classes.status;
            break;
        case 'progress':
            className = classes.progress;
            break;
        case 'active':
            className = classes.active;
            break;
        default:
            className = classes.root;
            break;
    }

    return (
        <Chip className={className} {...chipProps} />
    );
};

export default MChip;
