// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
    profile: null,
};

export const { Types, Creators } = createActions({
    updateProfile: ['profile'],
});

const updateProfile = ( state = INITIAL_STATE, { profile } ) => {
    return { ...state, profile };
}

const HANDLERS = {
    [Types.UPDATE_PROFILE]: updateProfile,
};

export default createReducer(INITIAL_STATE, HANDLERS);
