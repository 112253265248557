import React, { FC } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "components/_newDesign/typography/Typography";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { SpoonForkIcon } from "components/_newIcon/";
import { useStyles } from "./styles";
import { IntermittentRequest } from "interfaces/nutritionGoals";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import { postIntermittent } from "services/domain/users/nutritionGoal";
import ButtonMui from "components/_newDesign/button/Button";
import { AvatarGroupComponent } from "../AvatarGroup/AvatarGroup";
import { UserSubmissionListItem } from "interfaces/user";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview?: boolean;
  defaultExpanded?: boolean;
  weekly?: boolean;
  data?: {
    text: string;
    achieved: boolean;
    startFasting: string;
    endFasting: string;
    fastedDuration: number;
  };
  userList?: Array<UserSubmissionListItem>;
  isDone?: boolean;
  submitSucces?: () => void;
  submissionDate?: string;
}
const defaultValue: IntermittentRequest = {
  achieved: true,
  text: "",
  startFasting: "",
  endFasting: "",
  fastedDuration: 12,
  submissionDate: moment().utc(true).toString(),
};
const Intermittan: FC<Props> = (props) => {
  const classes = useStyles();
  const defaultDate = moment().format("YYYY-MM-DD");
  const [checked, setChecked] = React.useState<boolean>(false);
  const [expand, setExpand] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalTime, setTotalTime] = React.useState<{
    minutes: number;
    hours: number;
  }>({
    hours: 12,
    minutes: 0,
  });
  const [form, setForm] = React.useState<IntermittentRequest>(defaultValue);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartTime, setSelectedStartTime] = React.useState<Date | null | Moment>(
    // eslint-disable-next-line
    moment(defaultDate + " " + "06:00"),
  );
  const [selectedEndTime, setSelectedEndTime] = React.useState<Date | null | Moment>(
    // eslint-disable-next-line
    moment(defaultDate + " " + "18:00"),
  );

  const onChange = (event) => {
    if (!props.isDone && !props.preview) {
      setChecked(event.target.checked);
      setExpand(event.target.checked);
    }
  };
  const onChangeExpand = (value: boolean) => {
    if (!props.isDone && !props.preview) {
      setChecked(value);
      setExpand(value);
    } else if (props.isDone) {
      setExpand(value);
    }
  };

  const onSubmit = async () => {
    try {
      if (form.fastedDuration > 18) return;
      form.startFasting = moment(selectedStartTime).toString();
      form.endFasting = moment(selectedEndTime).toString();
      const payload: IntermittentRequest = {
        ...form,
        submissionDate: props.submissionDate ? props.submissionDate : moment().utc(true).toString(),
      };
      setLoading(true);
      await postIntermittent(payload);
      enqueueSnackbar("Add Intermittent Success", { variant: "success" });
      if (props.submitSucces) props.submitSucces();
    } catch (error) {
      enqueueSnackbar("Add Intermittent Failed", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const diffHours = (selectedStartTime, selectedEndTime) => {
    const startTime = moment(selectedStartTime);
    const endTime = moment(selectedEndTime);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asMinutes() / 60);
    const minutes = duration.asMinutes() % 60;
    const realHour = duration.asHours();
    setTotalTime({
      hours: hours,
      minutes: minutes,
    });
    setForm({ ...form, fastedDuration: realHour });
  };

  React.useEffect(() => {
    if (props.data && props.isDone) {
      const newForm = {
        achieved: true,
        text: props.data.text,
        submissionDate: moment().utc(true).toString(),
        startFasting: props.data.startFasting,
        endFasting: props.data.endFasting,
        fastedDuration: props.data.fastedDuration,
      };
      setSelectedStartTime(new Date(props.data.startFasting));
      setSelectedEndTime(new Date(props.data.endFasting));
      diffHours(props.data.startFasting, props.data.endFasting);
      setForm(newForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);
  React.useEffect(() => {
    if (props.isDone) setChecked(true);
  }, [props.isDone]);
  React.useEffect(() => {
    if (props.preview) setChecked(true);
  }, [props.preview]);
  return (
    <div className={`${props.weekly ? classes.rootWeekly : classes.root}`}>
      <Accordion
        defaultExpanded={props.defaultExpanded || true}
        classes={{ root: classes.accordionRoot }}
        expanded={props.preview || expand}
        onChange={(_e, expanded) => onChangeExpand(expanded)}
      >
        <AccordionSummary aria-controls="action-amount-calories-content" id="action-amount-calories-header" classes={{ content: classes.summaryContent }}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            checked={checked}
            onChange={onChange}
            control={<Checkbox name="caloriesAmountNutrition" color="primary" />}
            label={
              <Box display={"flex"} alignItems="center" style={{ marginBottom: "15px" }}>
                <SpoonForkIcon />
                <Typography
                  variant="mov_body1"
                  style={{
                    color: `${props.preview || props.isDone ? "#9A9A9A" : "#414141"}`,
                  }}
                >
                  I intermittent fasted today
                </Typography>
              </Box>
            }
          />
          {!expand && props.userList !== undefined && props.userList.length > 0 ? (
            <Box mt={1} mb={3}>
              <AvatarGroupComponent userList={props.userList} />
            </Box>
          ) : (
            <></>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box width={"100%"}>
            {!props.preview && !props.isDone && (
              <Box width="100%" textAlign="center" mt="20px">
                <ButtonMui disabled={loading} variant="contained" color="primary" onClick={onSubmit}>
                  {loading ? "Submitting..." : "Submit"}
                </ButtonMui>
              </Box>
            )}
            {expand && props.userList !== undefined && props.userList.length > 0 ? (
              <Box my={3}>
                <AvatarGroupComponent userList={props.userList} />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Intermittan;
