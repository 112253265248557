import { http } from 'services/data';
import { 
    BMRFrom,
    BMRResponse,
    LoseWeightForm,
    PoundLoseForm,
    LoseWeightResponse,
    PoundLoseResponse 
} from 'interfaces/simpleMath';

const endPoints = {
    bmr: 'simpleMath/bmr',
    loseWeight: 'simpleMath/loseWeight',
    poundLose: 'simpleMath/poundLose',
};

export const getBMR = async (payload: BMRFrom): Promise<BMRResponse> => await http.post(endPoints.bmr, payload);
export const getLoseWeight = async (payload: LoseWeightForm): Promise<LoseWeightResponse> => await http.post(endPoints.loseWeight, payload);
export const getPoundLose = async (payload: PoundLoseForm): Promise<PoundLoseResponse> => await http.post(endPoints.poundLose, payload);
