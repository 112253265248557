import React from 'react';
import moment from 'moment';

interface Props {
    value: string;
}

const DateCell = ({ value }: Props) => {
    let dateValue = '-';
    if (value) dateValue = moment.utc(value).format('MMM/DD/YYYY');
    return <>{dateValue}</>;
};

export default DateCell;
