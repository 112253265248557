import { BehaviorSubject } from "rxjs";
import { postLogin } from "services/domain/users";
import history from "services/data/history";

export const userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("user")!));

type userData = {
  dispayName: string;
  email: string;
  onboarding: boolean;
  setNewPass: boolean;
  _id: string;
  role: string;
  statusBooking: string;
  statusFlow: string;
  dayLeft: string;
};

const commitingUpdateUserProperties = (userProp: { token: string; userData: userData }) => {
  localStorage.setItem("user", JSON.stringify(userProp));
  userSubject.next(userProp);
};

class Auth {
  login(email: string, password: string) {
    return postLogin({ email, password }).then((user) => {
      commitingUpdateUserProperties(user);

      return user;
    });
  }

  user = userSubject.asObservable();

  get userProperties() {
    return userSubject.value;
  }

  updateUserProperties(key: string, val: string | boolean) {
    const myProperty = this.userProperties;

    if (key === "displayName") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "onboarding") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "setNewPass") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "setNewPass") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "nextStart") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "statusBooking") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "statusFlow") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "dayLeft") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "email") {
      Object.assign(myProperty, {
        userData: { ...myProperty.userData, [key]: val },
      });
    }
    if (key === "lastWeek") {
      Object.assign(myProperty, { userData: { ...myProperty.userData, [key]: val } });
    }

    commitingUpdateUserProperties(myProperty);
  }

  logout() {
    localStorage.removeItem("alreadyLastWeek");
    localStorage.removeItem("temporaryProfileSubmit");
    localStorage.removeItem("user");
    localStorage.removeItem("earlyAccess");
    userSubject.next(null);
    history.push("/signin");
  }
}

export default new Auth();
