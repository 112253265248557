import { http } from 'services/data';

const endPoints = {
    support: 'admin/support/new',
    feedback: 'admin/support/feedback',
};

export interface Support {
    subject: string
    message: string
}

export interface Feedback {
    url: string
    message: string
}

export const sendSupport = async (payload: Support): Promise<any> => await http.post(endPoints.support, payload);
export const sendFeedback = async (payload: Feedback): Promise<any> => await http.post(endPoints.feedback, payload);