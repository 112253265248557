import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

interface Props {
  value: string;
  handleChange: (event: React.ChangeEvent<{}>, val: string) => void;
}

function a11yProps(index: any) {
  return {
    id: `users-tab-${index}`,
    "aria-controls": `users-tabpanel-${index}`,
  };
}

const UserTab: React.FC<Props> = ({ value, handleChange }) => {
  return (
    <Tabs value={value} onChange={handleChange} indicatorColor="primary">
      <Tab value="all" label="All User" {...a11yProps("all")} />
      <Tab value="guest" label="Guest" {...a11yProps("guest")} />
      <Tab value="admin" label="Admin" {...a11yProps("admin")} />
      <Tab value="coach" label="Coach" {...a11yProps("coach")} />
      <Tab value="employee" label="Employee" {...a11yProps("employee")} />
      <Tab value="presenter" label="Presenter" {...a11yProps("presenter")} />
    </Tabs>
  );
};

UserTab.defaultProps = {
  value: "all",
};

export default UserTab;
