import React, { FC, useState } from 'react';
import {
    Box,
    Avatar,
    makeStyles, 
    createStyles, 
    Theme,
    ButtonBase
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import Typography from 'components/_newDesign/typography/Typography';
import UserList from './UserModalList'
import auth from 'services/domain/auth';
import { UserSubmissionList } from 'interfaces/user';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarSmall: {
            width: '24px',
            height: '24px',
            fontSize: '12px'
        },
        avatarGroup: {
            width: '24px',
            height: '24px',
            fontSize: '11px',
            marginLeft: '-6px'
        },
    },
));

export interface AvatarGroupProps {
    userList?: UserSubmissionList
    preview?: boolean
    allowModal?: boolean;
}

export const AvatarGroupComponent: FC<AvatarGroupProps> = ({preview, userList, allowModal }) => {

    const user = auth.userProperties.userData
    const [show, setShow] = useState<boolean>(false);
    const classes = useStyles();
    let userDetails;
    let first = '';
    if (userList && userList?.length > 0) {
        const indexAt =  userList.findIndex(x=>x["_id"] === user._id)
        if(indexAt === -1) {
            first = '' + userList[0].firstName + userList[0].lastName;
        } else {
            first = 'You'
            userList.unshift(userList.splice(indexAt,1)[0]) // Move target user to first index
            userList[0].isTargetGuest = true;
        }
        if (userList.length === 1) {
            userDetails = (
                <Typography variant="mov_body2" align={'left'}> <b>{first}</b> submitted this task. </Typography>
            ) 
        } else if (userList.length === 2) {
            userDetails = (
                <Typography variant="mov_body2" align={'left'}> <b>{first}</b> and <b>{`${userList[1].firstName} ${userList[1].lastName}`}</b> submitted this task. </Typography>
            )
        } else { // More than 2 users submitted this task
            userDetails = (
                <Typography variant="mov_body2" align={'left'}> <b>{first}</b>, <b>{`${userList[1].firstName} ${userList[1].lastName}`}</b>{` and other ${userList.slice(2).length} members submitted this task.`} </Typography>
            )
        }
    }
    return (
        <Box display={'flex'} alignItems="center" mx={3}>
            <Box>
                <ButtonBase disableRipple={true} disableTouchRipple={true} 
                    onClick={(event) => {
                            setShow(true && !!allowModal)
                            event.stopPropagation();
                        }
                    } 
                >
                    <AvatarGroup max={5} classes={{avatar: classes.avatarGroup}}>
                        {
                            userList?.map((item, index) => {
                                return (
                                <Avatar className={classes.avatarSmall} style={{zIndex: index}} alt={item.firstName + item.lastName} src={item.avatar} />)
                            })
                        }
                    </AvatarGroup>
                </ButtonBase>
            </Box>
            <Box marginLeft={'20px'} onClick={(event) => {event.stopPropagation()}}>
                {userDetails}
            </Box>
        <UserList
            onClose={() => setShow(false && !allowModal)}
            show={show}
            userList={userList}
        />
        </Box>
    );
};

AvatarGroupComponent.defaultProps = {
    allowModal: true
}
