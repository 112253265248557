import React, { FC } from "react";
import { Box, Button, createStyles, Divider, Grid, IconButton, makeStyles, Modal, Theme, Radio, RadioGroup, Container, CircularProgress } from "@material-ui/core";
import Typography from "components/_newDesign/typography/Typography";
import { ArrowBack } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import bagImage from "assets/images/bag.jpg";
import hatImage from "assets/images/hat.jpg";
import movaraHomeCreditImage from "assets/images/movara-home-credit.jpg";
import resortCreditImage from "assets/images/resort-credit.jpg";
import tShirtImage from "assets/images/t-shirt.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 0, 2),
      width: "750px",
      maxWidth: "750px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
      borderRadius: "20px",
    },
    header: {
      // width: '805px',
      // maxWidth: '805px',
    },
    "header-item": {
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "36px",
      lineHeight: "100%",
      color: "#000000",
    },
    content: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "150%",
      color: "#000000",
    },
    bodyTitle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "0.15px",
      color: "#414141",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
        letterSpacing: "0.11px",
      },
    },
    body: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "#414141",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "5%",
      },
    },
    headerTitle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "36px",
      /* identical to box height, or 36px */
      letterSpacing: "0.15px",
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "27px",
        letterSpacing: "0.11px",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "20px",
    },
    modalContent: {
      overflow: "auto",
      maxHeight: "60vh",
      minHeight: "60vh",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 0px 16px 0px",
      },
    },
    closeIcon: {
      "& svg": {
        transform: "translateY(-5px)",
        fontSize: "25px",
        color: "#515151",
      },
    },
    arrowIcon: {
      "& svg": {
        transform: "translateY(-2px)",
        fontSize: "30px",
        color: "#515151",
        [theme.breakpoints.down("sm")]: {
          fontSize: "25px",
        },
      },
    },
    textItem: {
      display: "flex",
      alignItems: "center",
    },
    imageItem: {
      width: "265px",
      height: "250px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      borderRadius: "10px",
      marginTop: "20px",
    },
    selectedItem: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    notEnoughPoints: {
      color: "red",
      fontWeight: "bold",
      marginBottom: "10px",
      textAlign: "center",
    },
    circularProgress: {
      width: "20px !important",
      height: "20px !important",
      marginLeft: "10px",
      textAlign: "center",
    },
  }),
);

interface Props {
  show: boolean;
  title: string;
  checkedItem: string;
  redeemingItem: boolean;
  notEnoughPoints: boolean;
  checkedItemPoints: number;
  setCheckedItemPoints: (checkedItemPoints: number) => void;
  setNotEnoughPoints: (notEnoughPoints: boolean) => void;
  setCheckedItem: (checked: string) => void;
  handleClose: () => void;
  handleSubmitItem: () => void;
}

const PointsRedeemModal: FC<Props> = ({
  show,
  checkedItem,
  title,
  notEnoughPoints,
  redeemingItem,
  checkedItemPoints,
  setCheckedItemPoints,
  setNotEnoughPoints,
  setCheckedItem,
  handleSubmitItem,
  handleClose,
}) => {
  const mTitle = title;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setNotEnoughPoints(false);

    switch (value) {
      case "Resort Credit":
      case "Movara Home Credit":
      case "Hat":
      case "T-Shirt":
      case "Bag":
        setCheckedItemPoints(100);
        break;
    }

    setCheckedItem(value);
  };

  const disabledSubmit = () => {
    if (!checkedItem) {
      return true;
    }

    if (redeemingItem) {
      return true;
    }

    return false;
  };

  const closeModel = () => {
    setCheckedItem("");
    setCheckedItemPoints(0);
    setNotEnoughPoints(false);
    handleClose();
  };

  return (
    <Modal
      open={show}
      onClose={closeModel}
      style={{ maxHeight: "100vh" }}
      className={classes.modal}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.7)", backdropFilter: "blur(10px)" } }}
    >
      <div className={classes.paper}>
        <Grid container className={classes.header}>
          <Grid item xs={10} className={classes["header-item"]}>
            <IconButton onClick={closeModel} className={classes.arrowIcon}>
              <ArrowBack />
            </IconButton>
            <Typography className={classes.headerTitle}>{mTitle}</Typography>
          </Grid>
          <Grid item xs={2} className={classes["header-item"]} style={{ justifyContent: "flex-end", paddingRight: "5px" }}>
            <IconButton onClick={closeModel} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr
          style={{
            backgroundColor: "#E6E6E6",
            height: "1px",
            border: "none",
            margin: "0px",
          }}
        />
        <Box className={classes.modalContent}>
          <RadioGroup value={checkedItem} onChange={handleChange}>
            <Box ml="50px">
              <Container maxWidth={false}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <img src={resortCreditImage} alt="check email" className={classes.imageItem} />
                    <Typography className={classes.textItem}>
                      <Radio value="Resort Credit" /> {`Resort Credit (100pts)`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <img src={movaraHomeCreditImage} alt="check email" className={classes.imageItem} />
                    <Typography className={classes.textItem}>
                      <Radio value="Movara Home Credit" /> {`Movara Home Credit (100pts)`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <img src={hatImage} alt="check email" className={classes.imageItem} />
                    <Typography className={classes.textItem}>
                      <Radio value="Hat" />
                      {`Hat (100pts)`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <img src={tShirtImage} alt="check email" className={classes.imageItem} />
                    <Typography className={classes.textItem}>
                      <Radio value="T-Shirt" /> {`T-Shirt (100pts)`}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <img src={bagImage} alt="check email" className={classes.imageItem} />
                    <Typography className={classes.textItem}>
                      <Radio value="Bag" /> {`Bag (100pts)`}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </RadioGroup>
        </Box>
        <Divider />
        <Box>
          <Box mt={3} justifyContent="center" alignItems="center" flexDirection="column" display={"flex"}>
            {checkedItem && (
              <Typography className={classes.selectedItem}>
                You curretly have {checkedItem} selected for {checkedItemPoints} points
              </Typography>
            )}
            {notEnoughPoints && <Typography className={classes.notEnoughPoints}>You curretly do not have enough points to redeem that item!</Typography>}
            <Button
              disabled={disabledSubmit()}
              onClick={() => handleSubmitItem()}
              variant="text"
              style={{
                textTransform: "none",
                width: "80%",
                backgroundColor: disabledSubmit() ? "rgba(255, 161, 30, 0.3)" : "#FFA11E",
                borderRadius: "12px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Continue
              </Typography>
              {redeemingItem && <CircularProgress className={classes.circularProgress} />}
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};
export default PointsRedeemModal;
