import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function BodyFatInOutGoalIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 34"
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path
                d="M28.317 4.20954H25.601V3.86931C25.601 2.28461 24.221 1 22.5191 1H9.04327C7.34143 1 5.9614 2.28461 5.9614 3.86931V4.20954H3.24609C2.00557 4.20954 1 5.14602 1 6.30098V30.9086C1 32.0635 2.00557 33 3.24609 33H28.3176C29.5581 33 30.5637 32.0635 30.5637 30.9086V6.30098C30.5631 5.14602 29.5575 4.20954 28.317 4.20954Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M22.0585 9.84993H9.60923C9.46971 9.84993 9.35718 9.73739 9.35718 9.59788V5.33604C9.35718 5.19652 9.46971 5.08398 9.60923 5.08398H22.0585C22.198 5.08398 22.3105 5.19652 22.3105 5.33604V9.59788C22.3105 9.73739 22.198 9.84993 22.0585 9.84993Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M19.1266 15.6523L12.1238 27.7816M22.0993 25.4241C22.0993 26.5871 21.1565 27.53 19.9934 27.53C18.8304 27.53 17.8875 26.5871 17.8875 25.4241C17.8875 24.261 18.8304 23.3182 19.9934 23.3182C21.1565 23.3182 22.0993 24.261 22.0993 25.4241ZM13.6757 18.0534C13.6757 19.2164 12.7328 20.1593 11.5698 20.1593C10.4067 20.1593 9.46387 19.2164 9.46387 18.0534C9.46387 16.8903 10.4067 15.9475 11.5698 15.9475C12.7328 15.9475 13.6757 16.8903 13.6757 18.0534Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
