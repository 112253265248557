import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function MailIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 23 21" className={classes.root}>
            <path d="M21.8942 1.70361L11.8625 8.92807C11.6135 9.10754 11.2773 9.10754 11.0284 8.92807L0.99707 1.70361" stroke="#414141" strokeMiterlimit="10"/>
            <path d="M21.0877 0.819092H16.7062H11.4461H6.18592H1.8044C1.319 0.819092 0.925781 1.21231 0.925781 1.69771V6.5575V8.47016V10.3828V15.2426C0.925781 15.728 1.319 16.1212 1.8044 16.1212H6.18592H11.4461C11.4461 16.1212 11.4461 17.2225 11.4461 19.8891C14.1509 19.3044 16.5868 17.817 18.4323 16.1212H21.0877C21.5731 16.1212 21.9663 15.728 21.9663 15.2426V10.3828V8.47016V6.5575V1.69771C21.9663 1.21231 21.5731 0.819092 21.0877 0.819092Z" stroke="#414141" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
