import { LazyExoticComponent, ComponentType } from 'react';
import HomeRoute from './Home/route';
import DashboardRoute from './Dashboard/route';
import ProgramsRoute from './Programs/route';
import UsersRoute from './Users/route';
import BookingsRoute from './Bookings/route';
import VideosRoute from './Vidoes/route';
import CommunityFeedsRoute from './CommunityFeeds/route';
import Messages from './Messages/route';
import Support from './Support/route';
import DashboardUser from './DashboardUser/route';
import SinglePost from './SinglePost/route';
import SingleVideo from './SingleVideo/route';
import PauseBookings from './PauseBooking/route';
import Waiting from './Waiting/route';
import Inactive from './Inactive/route';
import LastWeek from './LastWeek/route';
import PromoCode from './PromoCode/route';
import BookingsGuest from './BookingsGuest/route';

interface RouteInterface {
    exact?: boolean;
    path: string;
    component: LazyExoticComponent<ComponentType<any>>;
    layout: 'main' | 'dashboard' | 'single';
    roles?: string[],
}

const routes: RouteInterface[] = [
    HomeRoute,
    DashboardRoute,
    ProgramsRoute,
    UsersRoute,
    BookingsRoute,
    VideosRoute,
    CommunityFeedsRoute,
    Messages,
    Support,
    DashboardUser,
    SinglePost,
    SingleVideo,
    PauseBookings,
    Waiting,
    Inactive,
    LastWeek,
    PromoCode,
    BookingsGuest
];

export default routes;
