import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function UserIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 32" className={classes.root}>
            <path d="M15.2675 12.9786C18.7093 12.9786 21.4994 10.212 21.4994 6.79912C21.4994 3.38628 18.7093 0.619629 15.2675 0.619629C11.8257 0.619629 9.03564 3.38628 9.03564 6.79912C9.03564 10.212 11.8257 12.9786 15.2675 12.9786Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M28.0638 23.8934V29.1805C20.485 32.1131 11.515 32.1131 3.93626 29.1805V23.8934C3.90751 20.1656 6.66313 17.2491 10.8594 15.9383C12.1944 16.5729 13.6888 16.9281 15.2675 16.9281C17.0531 16.9281 18.7319 16.4738 20.1925 15.6743C21.8969 16.0895 23.4 16.7539 24.6231 17.6216C26.7919 19.1592 28.0838 21.3357 28.0638 23.8934Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
        </SvgIcon>
    );
};
