import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function NewspaperIcon({ className, ...props}: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 22 19" className={clsx(classes.root, className ?? className)}>
            <path d="M2.11523 17.9441H21.4999V1.79028" stroke="#414141"/>
            <rect x="0.75" y="0.424927" width="18.8846" height="15.6538" stroke="#414141"/>
            <path d="M7.51998 5.02098C7.51998 6.22113 6.54706 7.19405 5.34691 7.19405C4.14675 7.19405 3.17383 6.22113 3.17383 5.02098C3.17383 3.82082 4.14675 2.8479 5.34691 2.8479C6.54706 2.8479 7.51998 3.82082 7.51998 5.02098Z" stroke="#414141"/>
            <line x1="9.38477" y1="5.57898" x2="17.4617" y2="5.57898" stroke="#414141"/>
            <line x1="7.76953" y1="8.80957" x2="17.4618" y2="8.80957" stroke="#414141"/>
            <line x1="2.92383" y1="12.0403" x2="17.4623" y2="12.0403" stroke="#414141"/>
        </SvgIcon>
    );
};
