import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Grid,
    Button,
    Box,
    Link,
    CircularProgress,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import { MInput, MInputPassword } from 'components/controls';
import movaraLogo from 'assets/images/movara-home-logo-white.png';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

interface Props {
    onSubmit: () => void;
}

const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const SignInForm: FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <Container maxWidth="xs" component="main">
            <Box mt={10} mb={10}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={props.onSubmit}
                >
                    {({
                        isSubmitting,
                        getFieldProps,
                        getFieldMeta,
                        submitCount,
                    }) => (
                        <Form noValidate>
                            <Grid container justify="center">
                                <Box mb={7} textAlign="center">
                                    <img
                                        src={movaraLogo}
                                        alt="movara's logo"
                                        width="60%"
                                    />
                                </Box>
                                <Box mb={7} color="white" textAlign="center">
                                    <Typography color="inherit" variant="h6">
                                        Sign into your Movara Home account
                                    </Typography>
                                </Box>
                                {props.children}
                                <Field
                                    as={MInput}
                                    placeholder="email"
                                    id="email"
                                    autoComplete="email"
                                    autoFocus
                                    {...getFieldProps('email')}
                                    meta={getFieldMeta('email')}
                                    isSubmitted={submitCount > 0}
                                />
                                <Field
                                    as={MInputPassword}
                                    placeholder="password"
                                    id="password"
                                    autoComplete="current-password"
                                    {...getFieldProps('password')}
                                    meta={getFieldMeta('password')}
                                    isSubmitted={submitCount > 0}
                                />
                                <Box width="100%">
                                    <Typography
                                        className={clsx(
                                            classes.commonWhite,
                                            classes['forgot-password']
                                        )}
                                    >
                                        <Link
                                            component={RouterLink}
                                            to="/forgot-password"
                                        >
                                            Forgot Password?
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mt={2}
                                    mb={2}
                                >
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            className={classes.button}
                                            disabled={isSubmitting}
                                        >
                                            Login
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </Box>
                                <Box width="100%">
                                    {/* hide this for a while, since no requirement for this */}
                                    <Typography className={classes.commonWhite}>
                                        Don't have account? &nbsp;
                                        <Link
                                            component={RouterLink}
                                            to="/sign-up"
                                            className={classes.textBold}
                                        >
                                            Sign Up
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Container>
    );
};

export default SignInForm;
