import React, { lazy, useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "services/domain/auth";
import { UserContext, UserContextType } from "../../Store";

interface Props {
  exact?: boolean | undefined;
  path: string;
  component: React.ElementType;
  layout?: React.ElementType | undefined | "main" | "dashboard" | "single";
  roles?: String[];
}

const PrivateRoute: React.FC<Props> = ({ component: Component, layout, roles, path, ...rest }) => {
  const { user, setUser } = useContext(UserContext) as UserContextType;

  let Layout: React.ElementType | undefined | string = layout;
  const isDashboardUser = path === "/dashboard-user";
  const alreadyLastWeek = localStorage.getItem("alreadyLastWeek");
  if (typeof layout === "string") {
    if (layout === "main") {
      Layout = lazy(() => import("components/layout/containers/MainLayout"));
    }
    if (layout === "dashboard") {
      Layout = lazy(() => import("components/layout/containers/DashboardLayout"));
    }
    if (layout === "single") {
      Layout = lazy(() => import("components/layout/containers/SingleLayout"));
    }
  }

  useEffect(() => {
    auth?.userProperties?.userData ? setUser(auth.userProperties.userData) : setUser(null);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const user = auth.userProperties;
        const renderComponent = () => <Component {...props} />;
        const isSetProfile = localStorage.getItem("temporaryProfileSubmit");
        const earlyAccess = localStorage.getItem("earlyAccess");

        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location },
              }}
            />
          );
        }
        /* TODO:
                if (roles && roles.indexOf(user.role) === -1) {
                    return <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }}/>
                }
    */
        if (!user.userData.setNewPass) {
          return (
            <Redirect
              to={{
                pathname: "/set-new-password",
                state: { from: props.location },
              }}
            />
          );
        }
        if (user.userData.statusBooking === "Waiting" && isSetProfile !== "true" && earlyAccess !== "true") {
          return (
            <Redirect
              to={{
                pathname: "/onboarding",
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          earlyAccess !== "true" &&
          user.userData.statusBooking === "Waiting" &&
          !user.userData.onboarding &&
          (user.userData.statusFlow === "1" || user.userData.statusFlow === "2")
        ) {
          return (
            <Redirect
              to={{
                pathname: "/onboarding",
                state: { from: props.location },
              }}
            />
          );
        }
        if (user.userData.statusBooking === "Inactive" && isDashboardUser) {
          return (
            <Redirect
              to={{
                pathname: "/inactive",
                state: { from: props.location },
              }}
            />
          );
        }
        if (user.userData.statusBooking === "Pause" && isDashboardUser) {
          return (
            <Redirect
              to={{
                pathname: "/waiting",
                state: { from: props.location },
              }}
            />
          );
        }
        if (!user.userData.onboarding && user.userData.statusBooking === "Active") {
          return (
            <Redirect
              to={{
                pathname: "/onboarding",
                state: { from: props.location },
              }}
            />
          );
        }
        if (user.userData.statusBooking === "Active" && alreadyLastWeek !== "true" && user.userData.lastWeek) {
          localStorage.setItem("alreadyLastWeek", "true");
          return (
            <Redirect
              to={{
                pathname: "/inactive",
                state: { from: props.location },
              }}
            />
          );
        }
        // TODO: will remove this login in future, will enhance the layout model
        if (Layout) {
          return <Layout>{renderComponent()}</Layout>;
        }

        return renderComponent();
      }}
    />
  );
};

export default PrivateRoute;
