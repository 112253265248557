import React, { FC } from 'react';
import {
    Box,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Modal,
    Theme,
    Avatar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from 'components/_newDesign/typography/Typography';
import { CoachProfil } from 'interfaces/user';
// import { useStyles as useStyle2 } from './styles';
interface Props {
    data?: CoachProfil[];
    onClose: () => void;
    show: boolean;
    onSelelect: (coach: CoachProfil) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 0, 2),
            width: '375px',
            maxWidth: '375px',
            maxHeight: '95%',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
            },
            borderRadius: '20px',
        },
        header: {
            // width: '805px',
            // maxWidth: '805px',
        },
        'header-item': {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '36px',
            lineHeight: '100%',
            color: '#000000',
        },
        content: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#000000',
        },
        'upload-text': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '150%',
            /* identical to box height, or 36px */

            letterSpacing: ' 0.15px',

            color: '#000000',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
        },
        modalContent: {
            padding: '0px 24px 16px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 16px 0px',
            },
        },
        coachBox: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '12px',
            justifyContent: 'center',
        },
        coachCard: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: '12px',
            borderRadius: '10px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer',
        },
        coachSchedule: {
            padding: '4px 8px',
            borderRadius: '20px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '150%',
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.25px',
            color: '#000000',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        coachAvatarBox: {
            textAlign: 'center',
            paddingBottom: 0,
        },
        coachAvatar: {
            width: '96px',
            height: '96px',
        },
    })
);

const CoachModal: FC<Props> = (props) => {
    const classes = useStyles();
    // const classes2 = useStyle2();
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        props.onClose();
    };

    return (
        <Modal
            open={props.show}
            onClose={handleClose}
            style={{ maxHeight: '100vh' }}
            className={classes.modal}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    backdropFilter: 'blur(10px)',
                },
            }}
        >
            <div className={classes.paper}>
                <Grid
                    container
                    className={classes.header}
                    style={{ padding: '0px 20px' }}
                >
                    <Grid item xs={9} className={classes['header-item']}>
                        <ArrowBack
                            onClick={handleClose}
                            style={{ cursor: 'pointer' }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        className={classes['header-item']}
                        style={{ justifyContent: 'flex-end' }}
                    >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box padding={'24px 30px 0px 30px'}>
                    <Box className={classes.coachBox}>
                        {props.data &&
                            props.data.map((item, index) => (
                                <Box
                                    className={classes.coachCard}
                                    key={item._id + index}
                                    onClick={() => props.onSelelect(item)}
                                >
                                    <Box className={classes.coachAvatarBox}>
                                        <Avatar
                                            style={{ margin: 'auto' }}
                                            alt={item.name}
                                            src={item?.avatar}
                                            className={classes.coachAvatar}
                                        />
                                        <Typography variant="mov_body1">
                                            {item?.name}
                                        </Typography>
                                    </Box>
                                    {item.zoomCall && item.zoomCall !== '' ? (
                                        <Box width={'100%'} textAlign="center">
                                            <Typography variant="mov_overline2">
                                                CALL SCHEDULE
                                            </Typography>
                                            <Box
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>
                                                    {
                                                        item.zoomCall?.zoomCall1
                                                            ?.zoomCallDay
                                                    }
                                                </b>
                                                &nbsp;
                                                {
                                                    item.zoomCall?.zoomCall1
                                                        ?.zoomCallTime
                                                }{' '}
                                                (MST)
                                            </Box>
                                            <Box
                                                marginTop={'2px'}
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>
                                                    {
                                                        item.zoomCall?.zoomCall2
                                                            ?.zoomCallDay
                                                    }
                                                </b>
                                                &nbsp;
                                                {
                                                    item.zoomCall?.zoomCall2
                                                        ?.zoomCallTime
                                                }{' '}
                                                (MST)
                                            </Box>
                                            <Box
                                                marginTop={'2px'}
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>
                                                    {
                                                        item.zoomCall?.zoomCall3
                                                            ?.zoomCallDay
                                                    }
                                                </b>
                                                &nbsp;
                                                {
                                                    item.zoomCall?.zoomCall3
                                                        ?.zoomCallTime
                                                }{' '}
                                                (MST)
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box width={'100%'} textAlign="center">
                                            <Typography variant="mov_overline2">
                                                CALL SCHEDULE
                                            </Typography>
                                            <Box
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>Sun</b>&nbsp;6.30 pm (MST)
                                            </Box>
                                            <Box
                                                marginTop={'2px'}
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>Sun</b>&nbsp;6.30 pm (MST)
                                            </Box>
                                            <Box
                                                marginTop={'2px'}
                                                className={
                                                    classes.coachSchedule
                                                }
                                            >
                                                <b>Sun</b>&nbsp;6.30 pm (MST)
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                    </Box>
                </Box>
            </div>
        </Modal>
    );
};
export default CoachModal;
