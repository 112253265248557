import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const CommunityFeeds = lazy(() => import('./CommunityFeeds'));

export default {
    path: routeConstants.COMMUNITY_FEEDS.path,
    component: CommunityFeeds,
    layout: LayoutOptions.dashboard,
};
