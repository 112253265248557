// @ts-nocheck
import { createActions, createReducer } from "reduxsauce";

const INITIAL_STATE = {
    formState: null,
};

export const { Types, Creators } = createActions({
    updateFormState: ["data"],
});

const updateFormState = (state = INITIAL_STATE, { data }) => {
    return { ...state, formState: data };
}

const HANDLERS = {
    [Types.UPDATE_FORM_STATE]: updateFormState,
};

export default createReducer(INITIAL_STATE, HANDLERS);
