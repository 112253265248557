import React, { FC, useEffect, useState } from "react";
import { DateRange, DefinedRange } from "materialui-daterange-picker";
import { Box, Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { useStyles } from "./styles";
import CustomInput from "components/controls/CustomInput/CustomInputPayment";
import { Alert } from "@material-ui/lab";
import { DayPicker } from "react-day-picker";
import "./dayStyle.css";
import { getListCoachByid } from "services/domain/users";
import Skeleton from "@material-ui/lab/Skeleton";
import TypographyMUI from "components/_newDesign/typography/Typography";
import auth from "services/domain/auth";
import { getBookingByEmail } from "services/domain/admin/bookings";

interface BookingDetails {
  startDate: Date;
  endDate: Date;
  originalStartDate?: Date;
  originalEndDate?: Date;
  pauseStartDate?: Date;
  pauseEndDate?: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
}
interface DateRangePickerWrapperProps {
  label?: string;
  margin?: "normal";
  dateRange: DateRange;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  closeOnSelection?: boolean;
  wrapperClassName?: string;
  definedRanges?: DefinedRange[];
  totalWeek: number;
  coachId: string;
  coachName: string;
  pause?: boolean;
  mode?: string;
  booking?: BookingDetails;
}

const DateRangePickerInput: FC<DateRangePickerWrapperProps> = ({
  onChange,
  dateRange,
  label,
  margin,
  closeOnSelection,
  totalWeek,
  coachId,
  coachName,
  pause,
  mode,
  booking,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isLeftisSix, setIsLeftisSix] = useState(false);
  const [isRightisSix, setIsRightisSix] = useState(false);
  const userEmail = auth.userProperties?.userData?.email;

  const [range, setRange] = useState<any>({
    from: new Date(moment(dateRange.startDate, "YYYY-MM-DD").toString()),
    to: new Date(moment(dateRange.endDate, "YYYY-MM-DD").toString()),
  });
  const [showWarming, setShowWarning] = useState(false);
  const classes = useStyles();
  const handleToggle = () => {
    setShow(!show);
  };
  const [month, setMonth] = useState<Date>(new Date());
  const [quotaCoach1, setQuotaCoach1] = React.useState([0, 0, 0, 0, 0]);
  const [quotaCoach2, setQuotaCoach2] = React.useState([0, 0, 0, 0, 0]);
  const [disableWeek, setDisableWeek] = React.useState<string[]>([]);

  React.useEffect(() => {
    const diff = moment(dateRange.startDate, "YYYY-MM-DD").diff(new Date(), "days");
    if (diff < 0) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      setRange({
        from: new Date(moment(dateRange.startDate, "YYYY-MM-DD").toString()),
        to: new Date(moment(dateRange.endDate, "YYYY-MM-DD").toString()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.endDate, dateRange.startDate, totalWeek, coachId]);

  const onSelectDateRange = (range) => {
    if (range && range.from) {
      const diff = moment(range.from).diff(new Date(), "days");
      if (diff < 0) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
    if (range && range.from && range.to) {
      onChange({ startDate: range.from, endDate: range.to });
    }
    setRange(range);
    // eslint-disable-next-line
  };

  const getCoachQuota = async () => {
    try {
      if (!coachId) return;
      setLoading(true);
      let week1 = moment(month).startOf("month").format("YYYY-MM-DD");
      const day = moment(month).startOf("month").format("dddd");
      const numbersOfDay = moment(month).startOf("month").daysInMonth();
      if (day === "Friday" && numbersOfDay === 31) {
        setIsLeftisSix(true);
        week1 = moment(month).startOf("month").add(2, "days").format("YYYY-MM-DD");
      } else if (day === "Saturday" && numbersOfDay >= 30) {
        week1 = moment(month).startOf("month").add(1, "days").format("YYYY-MM-DD");
        setIsLeftisSix(true);
      } else {
        setIsLeftisSix(false);
      }
      const week2 = moment(week1).add(7, "days").format("YYYY-MM-DD");
      const week3 = moment(week2).add(7, "days").format("YYYY-MM-DD");
      const week4 = moment(week3).add(7, "days").format("YYYY-MM-DD");
      const week5 = moment(week4).add(7, "days").format("YYYY-MM-DD");
      const rspn1 = await getListCoachByid(coachId, week1);
      const rspn2 = await getListCoachByid(coachId, week2);
      const rspn3 = await getListCoachByid(coachId, week3);
      const rspn4 = await getListCoachByid(coachId, week4);
      const rspn5 = await getListCoachByid(coachId, week5);
      const diff1 = moment(week1).diff(new Date(), "days");
      const diff2 = moment(week2).diff(new Date(), "days");
      const diff3 = moment(week3).diff(new Date(), "days");
      const diff4 = moment(week4).diff(new Date(), "days");
      const diff5 = moment(week5).diff(new Date(), "days");

      if (rspn1.data[0].schedule.length >= 30) {
        const disableDate = moment(week1).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn2.data[0].schedule.length >= 30) {
        const disableDate = moment(week2).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn3.data[0].schedule.length >= 30) {
        const disableDate = moment(week3).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn4.data[0].schedule.length >= 30) {
        const disableDate = moment(week4).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn5.data[0].schedule.length >= 30) {
        const disableDate = moment(week5).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      setQuotaCoach1([
        diff1 > 0 ? rspn1.data[0].schedule.length : 30,
        diff2 > 0 ? rspn2.data[0].schedule.length : 30,
        diff3 > 0 ? rspn3.data[0].schedule.length : 30,
        diff4 > 0 ? rspn4.data[0].schedule.length : 30,
        diff5 > 0 ? rspn5.data[0].schedule.length : 30,
      ]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getCoachQuota2 = async () => {
    try {
      if (!coachId) return;
      setLoading2(true);
      let week1 = moment(month).add(1, "months").startOf("month").format("YYYY-MM-DD");
      const day = moment(month).add(1, "months").startOf("month").format("dddd");
      const numbersOfDay = moment(month).startOf("month").daysInMonth();
      if (day === "Friday" && numbersOfDay === 31) {
        week1 = moment(month).add(1, "months").startOf("month").add("days", 2).format("YYYY-MM-DD");
        setIsRightisSix(true);
      } else if (day === "Saturday" && numbersOfDay >= 30) {
        week1 = moment(month).add(1, "months").startOf("month").add("days", 1).format("YYYY-MM-DD");
        setIsRightisSix(true);
      } else {
        setIsRightisSix(false);
      }
      const week2 = moment(week1).add(7, "days").format("YYYY-MM-DD");
      const week3 = moment(week2).add(7, "days").format("YYYY-MM-DD");
      const week4 = moment(week3).add(7, "days").format("YYYY-MM-DD");
      const week5 = moment(week4).add(7, "days").format("YYYY-MM-DD");
      const rspn1 = await getListCoachByid(coachId, week1);
      const rspn2 = await getListCoachByid(coachId, week2);
      const rspn3 = await getListCoachByid(coachId, week3);
      const rspn4 = await getListCoachByid(coachId, week4);
      const rspn5 = await getListCoachByid(coachId, week5);
      const diff1 = moment(week1).diff(new Date(), "days");
      const diff2 = moment(week2).diff(new Date(), "days");
      const diff3 = moment(week3).diff(new Date(), "days");
      const diff4 = moment(week4).diff(new Date(), "days");
      const diff5 = moment(week5).diff(new Date(), "days");
      if (rspn1.data[0].schedule.length >= 30) {
        const disableDate = moment(week1).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn2.data[0].schedule.length >= 30) {
        const disableDate = moment(week2).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn3.data[0].schedule.length >= 30) {
        const disableDate = moment(week3).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn4.data[0].schedule.length >= 30) {
        const disableDate = moment(week4).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      if (rspn5.data[0].schedule.length >= 30) {
        const disableDate = moment(week5).startOf("weeks").format("YYYY-MM-DD");
        const allDisable: string[] = disableWeek;
        allDisable.push(disableDate);
        setDisableWeek(allDisable);
      }
      setQuotaCoach2([
        diff1 > 0 ? rspn1.data[0].schedule.length : 30,
        diff2 > 0 ? rspn2.data[0].schedule.length : 30,
        diff3 > 0 ? rspn3.data[0].schedule.length : 30,
        diff4 > 0 ? rspn4.data[0].schedule.length : 30,
        diff5 > 0 ? rspn5.data[0].schedule.length : 30,
      ]);
    } catch (error) {
    } finally {
      setLoading2(false);
    }
  };

  const getRange = (startDate: string, endDate: string) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, "weeks");
    let range: string[] = [];
    for (let i = 0; i < diff; i++) {
      const date = moment(startDate).add(i, "weeks").format("YYYY-MM-DD");
      range.push(date);
    }
    return range;
  };
  const fetch = async (email) => {
    try {
      const result = await getBookingByEmail({ email: email });
      result.data.map((elm) => {
        if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
        if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
        return elm;
      });
      const filteredData = result.data.filter((item) => item.statusBooking === "Waiting" || item.statusBooking === "Active");
      let dates: string[] = [];
      if (filteredData.length !== 0) {
        for (let index = 0; index < filteredData.length; index++) {
          dates = dates.concat(getRange(filteredData[index].startDate, filteredData[index].endDate));
        }
      }
    } catch (e) {
    } finally {
    }
  };
  useEffect(() => {
    if (userEmail) {
      fetch(userEmail);
      setRange({
        from: undefined,
        to: undefined,
      });
      onChange({ startDate: undefined, endDate: undefined });
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getCoachQuota();
    getCoachQuota2();
    // eslint-disable-next-line
  }, [month, coachId]);

  return (
    <>
      <Grid container className={classes.desktopDisplay}>
        <Grid item md={6} xs={6} sm={6} xl={6}>
          <Typography className={classes.label}>{pause ? "Pause" : ""} Start Date</Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} xl={6}>
          <Typography className={classes.label}>{pause ? "Pause" : ""} End Date </Typography>
        </Grid>
        <Grid item md={6} xs={6} sm={6} xl={6} style={{ paddingRight: "20px" }}>
          <CustomInput
            inputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleToggle}>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={moment(range?.from || new Date()).format("DD-MMM-YYYY")}
            fullWidth
            type="text"
          />
        </Grid>
        <Grid item md={6} xs={6} sm={6} xl={6}>
          <CustomInput
            inputProps={{
              readOnly: true,
              startAdornment: <EventIcon />,
            }}
            value={moment(range?.to || new Date()).format("DD-MMM-YYYY")}
            fullWidth
            type="text"
          />
        </Grid>
      </Grid>

      {showWarming && mode !== "edit" && (
        <Box mt={4}>
          <Alert severity="warning">
            Heads up! The start date you’ve selected has already passed, if you continue you will have already missed the first call. Would you like to proceed?
          </Alert>
        </Box>
      )}

      {mode !== "edit" ||
        (pause && (
          <Box mt={4} width="100%" display="flex" justifyContent="center" className={classes.desktopDisplay}>
            <Box height={"auto"} width="fit-content" boxShadow={"1px 1px 8px rgba(65, 65, 65, 0.1)"} borderRadius="20px">
              <Box display={"flex"}>
                {!pause && (
                  <Box display={"flex"} flexDirection="column" pt="58px" px="10px" alignItems={"center"}>
                    <div
                      className={classes.dayCoachAvailable}
                      style={{
                        marginBottom: isLeftisSix ? "63px" : "19px",
                      }}
                    >
                      Coach
                      <br />
                      Availability
                    </div>
                    {quotaCoach1.map((item) => (
                      <div className={`${classes.DayCoachLeft} ${item >= 30 ? "" : classes.DayCoachActive}`}>{loading ? <Skeleton variant="text" /> : 30 - item}</div>
                    ))}
                  </Box>
                )}
                <Box>
                  <DayPicker
                    month={month}
                    onMonthChange={setMonth}
                    className="Selectable"
                    numberOfMonths={2}
                    mode="range"
                    selected={range}
                    onSelect={onSelectDateRange}
                    disabled={(date) => {
                      const isNotSunday = moment(date).isoWeekday() < 7;
                      // if we are pausing, they should not be able to pick dates before this coming sunday
                      const nextSunday = moment(moment().format("MM/DD/YYYY")).day(7);

                      const isBeforeStartDate = moment(date).isBefore(booking?.startDate);

                      const isBeforeNextSunday = moment(date).isBefore(nextSunday);
                      const isInvalid = isBeforeStartDate || isNotSunday || (pause && isBeforeNextSunday);
                      return isInvalid;
                    }}
                  />
                </Box>
                {!pause && (
                  <Box display={"flex"} flexDirection="column" pt="58px" px="10px" alignItems={"center"}>
                    <div
                      className={classes.dayCoachAvailable}
                      style={{
                        marginBottom: isRightisSix ? "63px" : "19px",
                      }}
                    >
                      Coach
                      <br />
                      Availability
                    </div>
                    {quotaCoach2.map((item) => (
                      <div className={`${classes.DayCoachRight} ${item >= 30 ? "" : classes.DayCoachActive}`}>{loading2 ? <Skeleton variant="text" /> : 30 - item}</div>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      <Box display={"flex"} my={"10px"} ml={1}>
        <TypographyMUI style={{ color: "#9A9A9A" }} variant="mov_body2">
          {pause ? "Pause must start and end on a Sunday" : "Program starts every Sunday"}
        </TypographyMUI>
      </Box>
    </>
  );
};
export default DateRangePickerInput;
