import React from 'react';
import { Box } from '@material-ui/core';
import Typography from 'components/_newDesign/typography/Typography';

interface Props {
    status: string;
    ml?: string;
    endDate?: string;
}
const StatusBooking: React.FC<Props> = ({ status, ml, endDate }) => {
    switch (status.toLocaleLowerCase()) {
        case 'active':
            return (
                <Box
                    marginLeft={ml ? ml : '8px'}
                    p="4px 8px"
                    borderRadius="20px"
                    style={{ background: '#FFA11E' }}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'90px'}
                >
                    <Typography variant="mov_body2">
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                </Box>
            );
        case 'waiting':
            return (
                <Box
                    marginLeft={ml ? ml : '8px'}
                    p="4px 8px"
                    borderRadius="20px"
                    style={{ background: '#F8F8F8' }}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'90px'}
                >
                    <Typography variant="mov_body2">
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                </Box>
            );
        case 'inactive':
            return (
                <Box
                    marginLeft={ml ? ml : '8px'}
                    p="4px 8px"
                    borderRadius="20px"
                    style={{ background: '#F8F8F8' }}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'90px'}
                >
                    <Typography variant="mov_body2">
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                </Box>
            );
        case 'pause':
            const value = `Paused until ${endDate || ''}`;
            return (
                <Box
                    marginLeft={ml ? ml : '8px'}
                    p="4px 8px"
                    borderRadius="20px"
                    style={{ background: '#F8F8F8' }}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Typography
                        variant="mov_body2"
                        style={{ color: '#CDCDCD', whiteSpace: 'nowrap' }}
                    >
                        {value}
                    </Typography>
                </Box>
            );

        default:
            return <Box></Box>;
    }
};

export default StatusBooking;
