import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ZoomCallIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            {...props}
            style={{
                color: 'white',
                width: props.width || '16px',
                height: props.height || '16px',
            }}
        >
            <rect x="1.5" y="2.2998" width="13.125" height="9.625" stroke="#6D6D6D" strokeWidth="1.5" strokeLinejoin="round"/>
            <rect x="1.5" y="11.2998" width="13" height="0.5" stroke="#6D6D6D" strokeWidth="1.5" strokeLinejoin="round"/>
            <line x1="8.25" y1="11.7998" x2="8.25" y2="13.9873" stroke="#6D6D6D" strokeWidth="1.5" strokeLinejoin="round"/>
            <line x1="5.5" y1="13.3623" x2="10.3125" y2="13.3623" stroke="#6D6D6D" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M10.1203 9.63082V9.09405C10.1203 8.80933 10.0072 8.53627 9.8059 8.33494C9.60457 8.13361 9.33151 8.02051 9.04679 8.02051H6.89971C6.61499 8.02051 6.34193 8.13361 6.1406 8.33494C5.93928 8.53627 5.82617 8.80933 5.82617 9.09405V9.63082" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.9732 6.94688C8.5661 6.94688 9.04674 6.46624 9.04674 5.87334C9.04674 5.28044 8.5661 4.7998 7.9732 4.7998C7.3803 4.7998 6.89966 5.28044 6.89966 5.87334C6.89966 6.46624 7.3803 6.94688 7.9732 6.94688Z" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.95653 8.1814H3.96619C3.70994 8.1814 3.46419 8.28319 3.28299 8.46439C3.10179 8.64558 3 8.89134 3 9.14759V9.63068" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.93225 7.21534C5.46586 7.21534 5.89844 6.78276 5.89844 6.24915C5.89844 5.71554 5.46586 5.28296 4.93225 5.28296C4.39864 5.28296 3.96606 5.71554 3.96606 6.24915C3.96606 6.78276 4.39864 7.21534 4.93225 7.21534Z" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.0435 8.1814H12.0338C12.2901 8.1814 12.5358 8.28319 12.717 8.46439C12.8982 8.64558 13 8.89134 13 9.14759V9.63068" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.0675 7.21534C10.5339 7.21534 10.1013 6.78276 10.1013 6.24915C10.1013 5.71554 10.5339 5.28296 11.0675 5.28296C11.6011 5.28296 12.0337 5.71554 12.0337 6.24915C12.0337 6.78276 11.6011 7.21534 11.0675 7.21534Z" stroke="#FFA11E" strokeLinecap="round" strokeLinejoin="round"/>

        </SvgIcon>
    );
}
