import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 900,
        },
        cardContentWrapper: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        avatar: {
            // transform: 'translateY(-80px)',
        },
        profileInfo: {
            flex: 1,
            '&.is-edit': {
                '& .icon-setting, & .profile__items': {
                    display: 'none',
                },
            },
            '& .profile__guest-desc span': {
                color: '#C4C4C4',
            }
        },
        container: {
            position: 'relative',
            '&:hover':{
                '& $image': {
                    opacity: 0.6,
                },
                '& $middle': {
                    opacity: 1,
                },

            },
        },
        image: {
            opacity: 1,
            display: 'block',
            transition: '.5s ease',
            backfaceVisibility: 'hidden',
        },
        middle: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        middleLoading: {
            transition: '.5s ease',
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        text: {
            backgroundColor: '#C4C4C4',
            color: 'white',
            fontSize: '16px',
            padding: '16px 32px',
        },
        containerAvatar: {
            backgroundColor: '#E5E5E5',
            transform: 'translateY(-80px)',
            position: 'relative',
            borderRadius: '50%',
            '&:hover':{
                '& $middleAvatar': {
                    opacity: 1,
                },
                '& $avatar': {
                    opacity: 0.6,
                },

            },
        },
        middleAvatar: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: 200
        },
        middleLoadingAvatar: {
            transition: '.5s ease',
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        coverInfo: {
            background: 'white',
            padding: '5px 10px',
            borderRadius: '10px',
            display: 'flex',
            boxShadow: '0px 1.25px 4px rgba(0, 0, 0, 0.25)',
        }
    }),
);
