import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 900,
        },
        cardContentWrapper: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(10),
                paddingRight: theme.spacing(10),
            }
        },
        avatar: {
            transform: 'translateY(-90px)',
        },
        profileInfo: {
            flex: 1,
            '&.is-edit': {
                '& .icon-setting, & .profile__items': {
                    display: 'none',
                },
            },
            '& .profile__guest-desc span': {
                color: '#C4C4C4',
            }
        },
        container: {
            position: 'relative',
            cursor: 'pointer',
            '&:hover':{
                '& $image': {
                    opacity: 0.6,
                },
                '& $middle': {
                    opacity: 1,
                },

            },
        },
        image: {
            opacity: 1,
            display: 'block',
            transition: '.5s ease',
            backfaceVisibility: 'hidden',
        },
        middle: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        middleLoading: {
            transition: '.5s ease',
            opacity: 1,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        text: {
            backgroundColor: '#C4C4C4',
            color: 'white',
            fontSize: '16px',
            padding: '16px 32px',
        },
        containerAvatar: {
            backgroundColor: '#E5E5E5',
            transform: 'translateY(-80px)',
            position: 'relative',
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover':{
                '& $middleAvatar': {
                    opacity: 1,
                },
                '& $avatar': {
                    opacity: 0.6,
                },

            },
        },
        middleAvatar: {
            transition: '.5s ease',
            opacity: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: 200
        },
        coverInfo: {
            background: 'white',
            padding: '5px 10px',
            borderRadius: '10px',
            display: 'flex',
            boxShadow: '0px 1.25px 4px rgba(0, 0, 0, 0.25)',
        },
        profilInfo: {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        avatarCointainer: {
            display: 'flex',
            justifyContent: 'center',
            maxHeight: '65px',
            height: '65px',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                justifyContent: 'unset',
                height: 'auto',
            },
        },
        pointsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        points: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'middle',
            [theme.breakpoints.down('md')]: {
                transform: 'translate(130px, -160px)'
            },
            [theme.breakpoints.up('md')]: {
                transform: 'translateY(-95px)'
            },
        },
        pointsValue: {
            fontFamily: 'Montserrat',
            fontSize: '48px',
            fontWeight: 700,
            lineHeight: '58px',
            color: '#FFA11E',
            [theme.breakpoints.down('sm')]: {
                fontSize: '24px',
                lineHeight: '36px',
                letterSpacing: '0.15px'
            },
        },
        pointsLabel: {
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '14.4px',
            color: '#9A9A9A',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '1.5px'
            },
        }
    }),
);
