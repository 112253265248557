import { http } from "services/data";
import { PaginationInterface } from "interfaces/common";
import { BookingRequest, BookingPauseResponse, ApprovalReschedulePaylaod } from "interfaces/booking";

const endPoints = {
  bookingAll: "admin/booking/all",
  bookingPeriod: "admin/booking/period",
  bookingByEmail: "admin/booking/email",
  bookingById: "admin/booking/id",
  saveBooking: "admin/booking/save",
  pauseBooking: "admin/booking/pause/submit",
  pauseBookingList: "admin/booking/pause/data",
  pauseBookingListByName: "search/users/pause/firstname",
  pauseApproval: "admin/booking/pause/approval/coach",
  rescheduleBooking: "admin/booking/reschedule/submit",
  cancelPause: "admin/booking/pause/cancel",
  cancelReschedule: "admin/booking/reschedule/cancel",
  approvalReschedule: "admin/booking/reschedule/approval/coach",
  approvalPause: "admin/booking/pause/approval/coach",
  bookingRefund: "admin/booking/refund",
};

export const getBookingAll = (payload: PaginationInterface) => http.get(endPoints.bookingAll, { params: payload });
export const getBookingPeriod = (payload: PaginationInterface) => http.get(endPoints.bookingPeriod, { params: payload });
export const getBookingByEmail = (payload: PaginationInterface) => http.get(endPoints.bookingByEmail, { params: payload });
export const getBookingPauseByName = (firstName: string) =>
  http.get(endPoints.pauseBookingListByName, {
    params: { firstName: firstName },
  });
export const getBookingsPause = async (payload: PaginationInterface): Promise<BookingPauseResponse> => await http.get(endPoints.pauseBookingList, { params: payload });
export const saveBooking = (payload: BookingRequest) => http.post(endPoints.saveBooking, payload);
export const pauseBooking = (payload: any) => http.post(endPoints.pauseBooking, payload);
export const rescheduleBooking = (payload: any) => http.post(endPoints.rescheduleBooking, payload);
// export const cancelPause = (payload: any) => http.post(endPoints.cancelPause, payload);
export const cancelReschedule = (payload: any) => http.post(endPoints.cancelReschedule, payload);
// export const pauseAproval = (payload: PauseSubmit) => http.post(endPoints.pauseApproval, payload);
// export const aprovalPause = (payload: ApprovalPausePaylaod) => http.post(endPoints.approvalPause, payload);
export const rescheduleAproval = (payload: ApprovalReschedulePaylaod) => http.post(endPoints.approvalReschedule, payload);
export const getBookingById = (payload: string) => http.get(endPoints.bookingById, { params: { id: payload } });
export const postBookingRefund = (payload: { bookingId: string; email: string; refundAmount: number; cancelBooking: boolean }) => http.post(endPoints.bookingRefund, payload);
