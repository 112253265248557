import React, { FC, useEffect, useState, useRef } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Snackbar, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./styles";
import InfoAlert from "components/alert/InfoAlert";
import InputNumber from "components/_newDesign/input/InputNumber";
import InputText from "components/_newDesign/input/InputText";
import {
  ImageIcon,
  WeightIcon,
  EditIcon,
  WeistIcon,
  QuestionCircleIcon,
  ChestIcon,
  HipIcon,
  BodyFatIcon,
  SpoonForkIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "components/_newIcon";
import AmountCaloriesLabel from "components/_modules/NutritionGoalLabel/AmountCaloriesLabel";
import TrackMarcosLabel from "components/_modules/NutritionGoalLabel/TrackMarcosLabel";
import BalancedMealsLabel from "components/_modules/NutritionGoalLabel/BalancedMealsLabel";
import EliminateLabel from "components/_modules/NutritionGoalLabel/EliminateLabel";
import IntermittantLabel from "components/_modules/NutritionGoalLabel/IntermittantLabel";
import NoSnackingLabel from "components/_modules/NutritionGoalLabel/NoSnackingLabel";
import OtherLabel from "components/_modules/NutritionGoalLabel/OtherLabel";
import OzWaterLabel from "components/_modules/NutritionGoalLabel/OzWaterLabel";
import Typography from "components/_newDesign/typography/Typography";
import { getNutritionGoal } from "../../../helper/question";
import moment from "moment";
import { getUserAssessment } from "services/domain/admin/users";
import { reformatUserDailyTable, reformatUserWeeklyAssesment, reformatUserWeeklyTable } from "../../../helper/WeeklyFormat";
import LoadingComponent from "./WeeklyLoading";
import NoDataComponent from "./WeeklyNoData";
import ButtonMui from "components/_newDesign/button/Button";
import { putWeeklySubmission } from "services/domain/users";
import ImageUploading from "react-images-uploading";

const nutritionGoalOptions = ["caloriesIsCheck", "trackMacros", "intermittentFasting", "plateMethod", "eliminateIsCheck", "othersIsCheck", "waterIsCheck", "snacking"];

interface WeeklyInterface {
  step?: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
  };
  openModalWarning?: () => void;
  changeStatusStep?: (step: string) => void;
  onSubmit?: () => void;
  data?: any;
  isDone?: boolean;
  onShowModal?: () => void;
  startDate: any;
  endDate: any;
  onClose: (putRequest?: boolean) => void;
}

const WeeklyAdmin: FC<WeeklyInterface> = (props) => {
  const classes = useStyles();
  const [form, setForm] = useState<any>();
  const [infoWaist, setInfoWaist] = React.useState<boolean>(false);
  const [infoChest, setInfoChest] = React.useState<boolean>(false);
  const [infoHip, setInfoHip] = React.useState<boolean>(false);
  const [startDateNew, setStartDateNew] = React.useState(props.startDate);
  const [endDateNew, setEndDateNew] = React.useState(props.endDate);
  const [singleUser, setSingleUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showWeekly, setShowWeekly] = React.useState(true);
  const nutritionGoalRef = useRef<HTMLInputElement>(null);
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");
  const [message, setMessage] = React.useState("");
  const [openNotif, setOpenNotif] = React.useState<boolean>(false);
  const currentWeightRef = useRef<HTMLInputElement>(null);
  const goalWeightTextRef = useRef<HTMLInputElement>(null);
  const [posting, setPosting] = React.useState(false);
  const [images, setImages] = React.useState<any[]>([]);
  const [file, setFile] = React.useState<any>(undefined);
  const uploadFieldRef = useRef<HTMLButtonElement>(null);

  const openInfoWaist = () => {
    setInfoWaist(true);
  };

  const openInfoChest = () => {
    setInfoChest(true);
  };

  const openInfoHip = () => {
    setInfoHip(true);
  };

  const getNewDate = (type: string) => {
    setSingleUser(true);
    if (type === "next") {
      // if (onGoingNumber === row.original.name.totalWeeks) return
      // const number = onGoingNumber + 1
      // setOngoingNumber(number)
      const NewStartDate = moment(startDateNew).add(7, "d").format("YYYY-MM-DD");
      const NewEndDate = moment(endDateNew).add(7, "d").format("YYYY-MM-DD");
      setStartDateNew(NewStartDate);
      setEndDateNew(NewEndDate);
    } else {
      // if (onGoingNumber === 1) return
      // const number = onGoingNumber - 1
      // setOngoingNumber(number)
      const NewStartDate = moment(startDateNew).subtract(7, "d").format("YYYY-MM-DD");
      const NewEndDate = moment(endDateNew).subtract(7, "d").format("YYYY-MM-DD");
      setStartDateNew(NewStartDate);
      setEndDateNew(NewEndDate);
    }
  };
  const getSingleData = async () => {
    setLoading(true);
    try {
      const params = {
        startDate: startDateNew,
        endDate: endDateNew,
        existData: 0,
        limitData: 1,
        sortFirstName: 1,
        userEmail: props.data.name.email,
        coachId: [],
      };
      const res = await getUserAssessment(params);
      const mapToTable = res.data.map((item: any) => ({
        id: item.idPost,
        notes: item.notes,
        bodyFatStat: item.bodyFatStat % 1 !== 0 ? item.bodyFatStat.toFixed(2) : item.bodyFatStat,
        weightStat: item.weightStat % 1 !== 0 ? item.weightStat.toFixed(2) : item.weightStat,
        name: {
          username: `${item.firstName} ${item.lastName}`,
          email: item.email,
          progress: item.statusBooking,
          isBeenGuest: item.beenGuest?.isCheck,
          avatar: item.avatar,
          weekOngoing: item.weekOngoing,
          totalWeeks: item.totalWeeks,
          state: item.state,
          bookingStartDate: item.bookingStartDate.slice(0, 10),
          bookingEndDate: item.bookingEndDate.slice(0, 10),
          // status: '1 / 1W',
        },
        newDaily: {
          weekly: reformatUserWeeklyTable(item.data),
          daily: reformatUserDailyTable(item.data),
        },
        allTask: item.data,
        program: {
          percentage: item.progress,
          post: item.totalPost,
          comment: item.totalComments,
          like: item.totalLikes,
        },
        displayName: item.displayName,
        avatar: item.avatar,
        userId: item.userId,
        originalData: reformatUserWeeklyAssesment(item.data),
      }));
      const find = mapToTable[0].allTask.find((task: any) => task.typesWeekly === `week`);
      if (find) {
        setShowWeekly(true);
        init(mapToTable[0]);
      } else {
        setShowWeekly(false);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const openNotification = (message: string, severity: "success" | "info" | "warning" | "error") => {
    setSeverity(severity);
    setMessage(message);
    setOpenNotif(true);
  };

  const handleChange = (e: any) => {
    setImages(e);
    setFile(e[0].file);
  };

  const formOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    console.log(nutritionGoalOptions.includes(value));

    if (nutritionGoalOptions.includes(value)) {
      setForm({ ...form, [name]: value, caloriesAmount: "", waterAmount: "", eliminateText: "", othersText: "" });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleRemoveImage = () => {
    setImages([]);
    setFile(undefined);
  };

  const handleCloseNotif = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };

  const onSubmit = async () => {
    if (!form.currentWeight || !form.nutritionGoal || form.goalWeightText === "") {
      openNotification("Please fill all required field!", "error");
      if (!form.currentWeight) {
        currentWeightRef?.current?.focus();
        return;
      }
      if (!form.nutritionGoal) {
        nutritionGoalRef?.current?.focus();
        return;
      }
      if (form.goalWeightText === "") {
        goalWeightTextRef?.current?.focus();
        return;
      }
    }
    nutritionGoalOptions.forEach((option) => {
      if (form.nutritionGoal === option) {
        form[option] = true;
      } else {
        form[option] = false;
      }
    });

    if (form.nutritionGoal === "caloriesIsCheck" && !form.caloriesAmount) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "waterIsCheck" && !form.waterAmount) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "eliminateIsCheck" && (!form.eliminateText || form.eliminateText === "")) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "othersIsCheck" && (!form.othersText || form.othersText === "")) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }

    if (form.nutritionGoal === "caloriesIsCheck") {
      form.waterAmount = undefined;
      form.eliminateText = "";
      form.othersText = "";
    }
    if (form.nutritionGoal === "waterIsCheck") {
      form.caloriesAmount = undefined;
      form.eliminateText = "";
      form.othersText = "";
    }
    if (form.nutritionGoal === "eliminateIsCheck") {
      form.caloriesAmount = undefined;
      form.waterAmount = undefined;
      form.othersText = "";
    }
    if (form.nutritionGoal === "othersIsCheck") {
      form.caloriesAmount = undefined;
      form.waterAmount = undefined;
      form.eliminateText = "";
    }

    try {
      setPosting(true);

      const formData = new FormData();
      formData.append("id", form.id);
      formData.append("scale", file);
      formData.append("currentWeight", form.currentWeight);
      formData.append("currentWeightType", form.currentWeightType);
      formData.append("goalWeight", form.goalWeight);
      formData.append("goalWeightType", form.goalWeightType);
      formData.append("goalWeightText", form.goalWeightText);
      formData.append("currentWaist", form.currentWaist);
      formData.append("currentWaistType", form.currentWaistType);
      formData.append("currentChest", form.currentChest);
      formData.append("currentChestType", form.currentChestType);
      formData.append("currentHip", form.currentHip);
      formData.append("currentHipType", form.currentHipType);
      formData.append("bodyFat", form.bodyFat);
      formData.append("caloriesAmount", form.caloriesAmount ? form.caloriesAmount : "");
      formData.append("caloriesIsCheck", form.caloriesIsCheck);
      formData.append("trackMacros", form.trackMacros);
      formData.append("intermittentFasting", form.intermittentFasting);
      formData.append("plateMethod", form.plateMethod);
      formData.append("waterAmount", form.waterAmount ? form.waterAmount : "");
      formData.append("waterIsCheck", form.waterIsCheck);
      formData.append("snacking", form.snacking);
      formData.append("eliminateText", form.eliminateText);
      formData.append("eliminateIsCheck", form.eliminateIsCheck);
      formData.append("othersText", form.othersText);
      formData.append("othersIsCheck", form.othersIsCheck);

      await putWeeklySubmission(formData);
      props.onClose(true);
    } catch (error) {
      setPosting(false);
      openNotification("Updated Weekly Assessment Failed!", "error");
    }
  };

  React.useEffect(() => {
    if (singleUser) getSingleData();
    // eslint-disable-next-line
  }, [startDateNew, endDateNew]);

  const init = (data) => {
    const form = {
      id: data.id,
      currentWeight: data.originalData.currentWeight.data || 0,
      currentWeightType: data.originalData.currentWeight.typeData || "lbs",
      goalWeight: data.originalData.goalWeight.data || 0,
      goalWeightType: data.originalData.goalWeight.typeData || "lbs",
      goalWeightText: data.originalData.goalWeight.text || "",
      currentWaist: data.originalData.waistMeasurement.data || 0,
      currentWaistType: data.originalData.waistMeasurement.typeData || "inch",
      currentChest: data.originalData.chestMeasurement.data || 0,
      currentChestType: data.originalData.chestMeasurement.typeData || "inch",
      currentHip: data.originalData.hipMeasurement.data || 0,
      currentHipType: data.originalData.hipMeasurement.typeData || "inch",
      bodyFat: data.originalData.bodyFat.data || 0,
      nutritionGoal: getNutritionGoal(data.originalData?.nutritionGoalWeekly) || undefined,
      caloriesAmount: data.originalData.nutritionGoalWeekly?.calories?.amount || undefined,
      caloriesIsCheck: data.originalData.nutritionGoalWeekly?.calories?.isCheck,
      trackMacros: data.originalData.nutritionGoalWeekly?.trackMacros?.isCheck,
      intermittentFasting: data.originalData.nutritionGoalWeekly?.intermittentFasting?.isCheck,
      plateMethod: data.originalData.nutritionGoalWeekly?.plateMethod?.isCheck,
      waterAmount: data.originalData.nutritionGoalWeekly?.water?.amount || undefined,
      waterIsCheck: data.originalData.nutritionGoalWeekly?.water?.isCheck,
      snacking: data.originalData.nutritionGoalWeekly?.snacking?.isCheck,
      eliminateText: data.originalData.nutritionGoalWeekly?.eliminate?.text || "",
      eliminateIsCheck: data.originalData.nutritionGoalWeekly?.eliminate?.isCheck,
      othersText: data.originalData.nutritionGoalWeekly?.others?.text || "",
      othersIsCheck: data.originalData.nutritionGoalWeekly?.others?.isCheck,
      photo: data.originalData.photoScale.data || "",
    };

    console.log(data);

    setForm(form);
  };
  useEffect(() => {
    if (props.data.originalData && props.data.originalData.nutritionGoalWeekly) init(props.data);
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <>
      <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleCloseNotif} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Box width={"100%"} height={"auto"} boxShadow="1px 1px 8px rgba(65, 65, 65, 0.1)" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center">
        <Box display={"flex"} justifyContent="center" alignItems={"center"} height={"auto"} width="100%" padding={"20px 45px 10px 45px"} textAlign="center">
          <Typography variant="mov_subtitle2_regular">Weekly Questions Submission </Typography>
        </Box>
        <Box display={"flex"} justifyContent="space-between" alignItems={"center"} height={"auto"} width="400px" borderBottom={"2px solid #FFA21E"} textAlign="center">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" paddingX={"20px"} width="100%">
            <Box mr={1} flexGrow={1} textAlign="left">
              <Typography variant="mov_caption1">{moment(startDateNew).format("dddd, MMMM DD YYYY")}</Typography>
            </Box>
            <Box style={{ paddingTop: "10px" }}>
              <ArrowLeftCircleIcon
                fill="#000"
                width={"24px"}
                height={"24px"}
                onClick={() => getNewDate("previous")}
                // style={{cursor: onGoingNumber === 1 ? 'not-allowed' : 'pointer'}}
              />
              <ArrowRightCircleIcon
                fill="#000"
                width={"24px"}
                height={"24px"}
                onClick={() => getNewDate("next")}
                // style={{cursor: onGoingNumber === row.original.name.totalWeeks ? 'not-allowed' : 'pointer'}}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : !showWeekly ? (
        <NoDataComponent />
      ) : (
        <>
          {form && (
            <>
              <Box margin={"42px 50px 42px 50px"} className={classes.weeklyContainer}>
                <Box display={"flex"} mb={2} className={classes.weeklyContentRes2}>
                  <Box display={"flex"}>
                    <Box display={"flex"} alignItems={"start"} p={1}>
                      <ImageIcon />
                    </Box>
                    <Box flex="1" p={1} alignSelf={"center"}>
                      <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                        Upload a photo of your scale{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"} justifyContent={"center"} sx={{ marginBottom: "20px" }}>
                  {!images.length && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <img src={form.photo} width="300px" alt="scale" />
                    </Box>
                  )}
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <ImageUploading value={images} onChange={handleChange} dataURLKey="data_url" acceptType={["jpeg", "jpg", "png"]}>
                    {({ onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                      <>
                        {images.length === 0 ? (
                          <Box width={"300px"} border={"2px dashed rgba(0, 0, 0, 0.1)"} textAlign={"center"} py={4} {...dragProps}>
                            <Typography className={classes.uploadDesc}>Drop files here to upload…</Typography>
                            <Button
                              ref={uploadFieldRef}
                              variant="contained"
                              style={{
                                margin: "auto",
                                boxShadow: "none",
                                border: "1px solid #E6E6E6",
                                borderRadius: "10px",
                                textTransform: "none",
                                marginTop: "10px",
                              }}
                              onClick={onImageUpload}
                            >
                              Browse files
                            </Button>
                          </Box>
                        ) : (
                          <Box display={"flex"} flexDirection={"column"}>
                            <img src={images[0].data_url} width="300px" alt="" />
                            <Button
                              variant="contained"
                              style={{
                                margin: "auto",
                                boxShadow: "none",
                                border: "1px solid #E6E6E6",
                                borderRadius: "10px",
                                textTransform: "none",
                                marginTop: "10px",
                              }}
                              onClick={handleRemoveImage}
                            >
                              Remove
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Box>
                <Box display={"flex"} mt={2} mb={2} className={classes.weeklyContentRes2}>
                  <Box display={"flex"}>
                    <Box display={"flex"} alignItems={"start"} p={1}>
                      <WeightIcon />
                    </Box>
                    <Box flex="1" p={1} alignSelf={"center"}>
                      <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                        What is your current weight?{" "}
                      </Typography>
                      <InputNumber
                        style={{ minWidth: "200px" }}
                        name="currentWeight"
                        ref={currentWeightRef}
                        value={form.currentWeight}
                        onChange={formOnChange}
                        endAdornment={"Lbs"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"} mt={2} mb={2} className={classes.weeklyContentRes2}>
                  <Box display={"flex"}>
                    <Box display={"flex"} alignItems={"start"} p={1}>
                      <WeightIcon />
                    </Box>
                    <Box flex="1" p={1} alignSelf={"center"}>
                      <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                        what is your Goal weight for the week?{" "}
                      </Typography>
                      <InputNumber
                        style={{ minWidth: "200px" }}
                        ref={goalWeightTextRef}
                        name="goalWeight"
                        value={form.goalWeight}
                        onChange={formOnChange}
                        endAdornment={"Lbs"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"} mt={2} mb={2} className={classes.weeklyContentRes2}>
                  <Box display={"flex"}>
                    <Box display={"flex"} alignItems={"start"} p={1}>
                      <SpoonForkIcon />
                    </Box>
                    <Box flex="1" p={1} alignSelf={"center"}>
                      <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                        what is your nutrition Goal of the week?{" "}
                      </Typography>
                      <RadioGroup aria-label="nutrition goal" name="nutritionGoal" value={form.nutritionGoal} onChange={formOnChange} className={classes.radioGroup}>
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="caloriesIsCheck"
                          control={<Radio />}
                          label={
                            <AmountCaloriesLabel
                              preview={true}
                              active={form.nutritionGoal === "caloriesIsCheck"}
                              defaultValue={form.caloriesAmount}
                              // eslint-disable-next-line no-useless-computed-key
                              onChange={(value) =>
                                setForm({
                                  ...form,
                                  ["caloriesAmount"]: value,
                                })
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="trackMacros"
                          control={<Radio />}
                          label={<TrackMarcosLabel preview={true} active={form.nutritionGoal === "trackMacros"} />}
                        />
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="intermittentFasting"
                          control={<Radio />}
                          label={<IntermittantLabel preview={true} active={form.nutritionGoal === "intermittentFasting"} />}
                        />
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="plateMethod"
                          control={<Radio inputRef={nutritionGoalRef} />}
                          label={<BalancedMealsLabel preview={true} active={form.nutritionGoal === "plateMethod"} />}
                        />
                        <FormControlLabel
                          style={{
                            alignItems: "start",
                            marginTop: "10px",
                          }}
                          value="waterIsCheck"
                          control={<Radio />}
                          label={
                            <OzWaterLabel
                              defaultValue={form.waterAmount}
                              preview={true}
                              active={form.nutritionGoal === "waterIsCheck"}
                              // eslint-disable-next-line no-useless-computed-key
                              onChange={(value) =>
                                setForm({
                                  ...form,
                                  ["waterAmount"]: value,
                                })
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="snacking"
                          control={<Radio />}
                          label={<NoSnackingLabel preview={true} active={form.nutritionGoal === "snacking"} />}
                        />
                        <FormControlLabel
                          style={{
                            alignItems: "start",
                            marginTop: "10px",
                          }}
                          value="eliminateIsCheck"
                          control={<Radio />}
                          // eslint-disable-next-line no-useless-computed-key
                          label={
                            <EliminateLabel
                              defaultValue={form.eliminateText}
                              preview={true}
                              active={form.nutritionGoal === "eliminateIsCheck"}
                              onChange={(value) =>
                                setForm({
                                  ...form,
                                  ["eliminateText"]: value,
                                })
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          style={{ alignItems: "start" }}
                          value="othersIsCheck"
                          control={<Radio />}
                          // eslint-disable-next-line no-useless-computed-key
                          label={
                            <OtherLabel
                              defaultValue={form.othersText}
                              preview={true}
                              active={form.nutritionGoal === "othersIsCheck"}
                              onChange={(value) =>
                                setForm({
                                  ...form,
                                  ["othersText"]: value,
                                })
                              }
                            />
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </Box>

                <Box display={"flex"} mb={2}>
                  <Box display={"flex"} alignItems={"center"} p={1}>
                    <EditIcon />
                  </Box>
                  <Box flex="1" p={1} alignSelf={"start"}>
                    <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                      What is your non-scale goal for the week?
                    </Typography>
                    <InputText rows="3" value={form.goalWeightText} multiline onChange={formOnChange} placeholder="Your story..." name="goalWeightText" />
                  </Box>
                </Box>
              </Box>
              <Box margin={"42px 50px 42px 50px"} className={classes.weeklyContainer} marginTop={"16px"}>
                <Box display={"flex"} mb={2}>
                  <Box display={"flex"} alignItems={"center"} p={1}>
                    <Box width="17.58px"></Box>
                  </Box>
                  <Box flex="1" p={1} alignSelf={"center"}>
                    <Typography className={classes.optionalTitle}>OPTIONAL</Typography>
                    <Typography className={classes.optionalDesc}>You may choose to show or hide the following information. </Typography>
                  </Box>
                </Box>

                <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
                  <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoWaist}>
                    <WeistIcon />
                  </Box>
                  <Box p={1} alignSelf={"start"} onMouseEnter={openInfoWaist} minWidth={210}>
                    <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                      What is your waist measurement?
                    </Typography>
                    <InputNumber name="currentWaist" value={form.currentWaist} onChange={formOnChange} endAdornment={"In"} />
                  </Box>
                  <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoWaist}>
                    <QuestionCircleIcon />
                  </Box>
                </Box>
                <Box display={"flex"} mb={1}>
                  <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
                    <InfoAlert
                      onClose={() => setInfoWaist(false)}
                      isOpen={infoWaist}
                      text="Use tape to circle the waist like a belt would. The crease that forms is your natural waistline, located above your belly button and below the rib cage."
                      closeable={true}
                    />
                  </Box>
                </Box>

                <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
                  <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoChest}>
                    <ChestIcon />
                  </Box>
                  <Box p={1} alignSelf={"start"} onMouseEnter={openInfoChest} minWidth={210}>
                    <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                      What is your chest measurement?
                    </Typography>
                    <InputNumber name="currentChest" value={form.currentChest} onChange={formOnChange} endAdornment={"In"} />
                  </Box>
                  <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoChest}>
                    <QuestionCircleIcon />
                  </Box>
                </Box>
                <Box display={"flex"} mb={1}>
                  <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
                    <InfoAlert
                      onClose={() => setInfoChest(false)}
                      isOpen={infoChest}
                      text="Wrap the tape around your bust, under armpits, around shoulder blades and back to the front."
                      closeable={true}
                    />
                  </Box>
                </Box>

                <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
                  <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoHip}>
                    <HipIcon />
                  </Box>
                  <Box p={1} alignSelf={"start"} onMouseEnter={openInfoHip} minWidth={210}>
                    <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                      What is your hip measurement?&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                    <InputNumber name="currentHip" value={form.currentHip} onChange={formOnChange} endAdornment={"In"} />
                  </Box>
                  <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoHip}>
                    <QuestionCircleIcon />
                  </Box>
                </Box>
                <Box display={"flex"} mb={1}>
                  <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
                    <InfoAlert
                      onClose={() => setInfoHip(false)}
                      isOpen={infoHip}
                      text="Make sure to measure the largest part of your buttocks. Begin from one side of the hip, wrap the tape measure around your rear, other hip and back to where it started."
                      closeable={true}
                    />
                  </Box>
                </Box>

                <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
                  <Box display={"flex"} alignItems={"center"} p={1}>
                    <BodyFatIcon />
                  </Box>
                  <Box p={1} alignSelf={"start"} minWidth={210}>
                    <Typography variant="mov_overline2" style={{ textTransform: "uppercase", marginBottom: "14px" }}>
                      What is your body fat percentage?
                    </Typography>
                    <InputNumber name="bodyFat" value={form.bodyFat} onChange={formOnChange} endAdornment={"%"} />
                  </Box>
                </Box>
                <Box sx={{ marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                  <ButtonMui variant="contained" color="primary" onClick={onSubmit} disabled={posting}>
                    Save
                  </ButtonMui>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};
export default WeeklyAdmin;
