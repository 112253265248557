// @ts-nocheck
import { combineReducers } from 'redux';

import admin from 'services/domain/admin/reducer';
import chats from 'services/domain/chats/reducer';
import users from 'services/domain/users/reducer';


export const reducers = combineReducers({
    admin,
    chats,
    users,
});
