import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Home = lazy(() => import('./Home'));

export default {
    exact: true,
    path: routeConstants.HOME.path,
    component: Home,
    layout: LayoutOptions.main,
};
