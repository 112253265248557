import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { drawerWidth } from 'config/constants';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            }
        },
        drawerPaper: {
            width: drawerWidth,
        },
        brand: {
            margin: '40px 0',
        },
        profileName: {
            fontWeight: 300,
            marginTop: '30px',
        },
        profileLink: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '70px',
        },
        'icon-menu': {
            strokeWidth: '0.5px',
        },
        rootList: {
            '& .MuiListItemText-root': {
                color: '#818181',
                fontWeight: 300,
            }
        },
    })
);
