import React, {FC, useEffect} from 'react';
import { 
    Box, 
    Grid, 
    Paper, 
    Typography, 
    Button, 
    Tabs, 
    Tab, 
    Snackbar
 } from '@material-ui/core';
import useStyles from './styles';
import { DateRange } from "materialui-daterange-picker";
import { getDuration, addDuration} from 'services/domain/admin/programPlan'
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import { fUTCToLocalDate } from 'services/util/common';
const Calendar = React.lazy(() => import('./calendar'));
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `wrapped-tab-${index}`, 'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const Duration: FC = () => {
    const classes = useStyles();
    const [tab, setTab] = React.useState(0);
    // eslint-disable-next-line
    const [day, setDay] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sending, setSending] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [dateRangeValues, setDateRangeValues] = React.useState<DateRange>({startDate: new Date(), endDate: new Date()});
    const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">('success');
    const [message, setMessage] = React.useState('');
    // const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
        getDurationData(newValue+1)
    };

    const getDurationData = async (value: number) => {
        setLoading(true)
        try {
            const res = await getDuration({week: value})
            const startDate = fUTCToLocalDate(res.data[0].startDate);
            const endDate = fUTCToLocalDate(res.data[0].endDate);

            setDateRangeValues({
                startDate,
                endDate,
            })
        } catch (error) {
            setDateRangeValues({
                startDate: new Date(),
                endDate: new Date(),
            })
        } finally {
            setLoading(false)
        }
    }

    const calendarChange = (range: DateRange) => {
        setDateRangeValues(range)
    }

    const SetDuration = async () => {
        setSending(true)
        try {
            const payload = {
                week: tab + 1,
                startDate: moment(dateRangeValues?.startDate || '').utc(true),
                endDate: moment(dateRangeValues?.endDate || '').utc(true),
            }
            await addDuration(payload)
            setSeverity('success')
            setMessage('Set Duration Success')
            setOpen(true)
        } catch (error) {
            setSeverity('error')
            setMessage('Set Duration Failed')
            setOpen(true)
        } finally {
            setSending(false)
        }
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    useEffect(() => {
        getDurationData(1);
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={3}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid item md={2}>
                <Grid container>
                    <Grid item md={12} className={classes.videoOption}>
                        <Paper>
                            <Box pt={1} paddingX={3} paddingY={2}>
                                <Tabs
                                    orientation="vertical"
                                    value={tab}
                                    onChange={handleChangeTab}
                                    variant="scrollable"
                                    indicatorColor="primary"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs">

                                    {day.map((item, index) => (
                                        <Tab classes={{ root: classes.tab, wrapper: classes.tabWrapper }} key={index}
                                                label={`Week ${item}`} {...a11yProps(index)} />
                                    ))}
                                </Tabs>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={10}>
                <Paper className={classes.dateRangeClass}>
                    <div className={classes.dateRangeClass}>
                        {day.map((item, index) => (
                            <TabPanel value={tab} index={index} key={index}>
                                <Box paddingX={3} paddingBottom={2}>
                                    <Box pt={1}> 
                                        <Typography>SET DURATION </Typography>
                                    </Box>
                                    <Calendar onCalendarChange={calendarChange} week={tab+1} loading={loading} dateRangeValues={dateRangeValues} />
                                    <Box textAlign="right" pt={1}>
                                        <Button
                                            color="secondary"
                                            type="submit"
                                            variant="contained"
                                            size="medium"
                                            disabled={sending || loading}
                                            onClick={SetDuration}
                                        >
                                            {sending ? 'Saving...' : 'Save'}
                                        </Button>
                                    </Box>
                                </Box>
                            </TabPanel>
                        ))}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Duration;