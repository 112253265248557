import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Paper, Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { SignUpLayout } from 'components/layout/containers';
import { MInputPassword } from 'components/controls';
import { useStyles } from '../ForgotPassword/styles';
import { Toast } from 'services/util/toast';

import movaraLogo from 'assets/images/movara-home-logo.png';

import { editPassword } from 'services/domain/users';
import { EditPassUserRequest } from 'interfaces/user';
import auth from 'services/domain/auth';


const initialValues = {
    newPassword: '',
    oldPassword: 'password',
};

const validationSchema = Yup.object({
    newPassword: Yup.string().required('New Password is required').matches(/^(?=.*\d).{8,}$/,"Must Contain 8 Characters and number"),
    newPassConfirm: Yup.string().oneOf([Yup.ref('newPassword'), ''], 'Password must match'),
});

const ResetPassword = () => {
    const classes = useStyles();
    const [isResetSuccess, setResetStatus] = React.useState(false);
    const history = useHistory();
    const user = auth.userProperties;

    if (user === null || (user.userData.setNewPass && !isResetSuccess)) {
        history.push('signin');
        return null;
    }

    const logout = () => {
        auth.logout();
        history.push('signin');
    }

    const onSubmit = async (values: EditPassUserRequest, { setSubmitting, resetForm }: FormikHelpers<EditPassUserRequest>) => {
        setSubmitting(true);
        try {
            await editPassword({
                oldPassword: 'password',
                newPassword: values.newPassword
            });
            auth.updateUserProperties('setNewPass', true)
            setResetStatus(true);
            resetForm();
            localStorage.clear()
        } catch (e) {
            const toast = Toast({});
            if ('messages' in e) {
                let message = 'Unknown error';

                if (e.messages instanceof Array) {
                    if (typeof e.messages[0] === 'string') {
                        message = e.messages[0];
                    } else {
                        message = e.messages[0]?.error ?? message;
                    }
                };
                
                if (typeof e.messages === 'string') {
                    message = e.messages;
                }

                toast.fire({
                    icon: 'error',
                    title: message,
                });  
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <SignUpLayout>
            <Container maxWidth="md" className={classes["forgot-password__container"]} >
                <Paper>
                    <Box maxWidth={460} marginLeft="auto" marginRight="auto" pt={15} pb={15} textAlign="center">
                        <Box mb={8} display="flex" justifyContent="center">
                            <img src={movaraLogo} alt="movara's logo" />
                        </Box>

                        <Box mb={8}>
                            <Typography align="center" variant="subtitle1">
                                {isResetSuccess ? 'New Password Set' : 'Enter New Password'}
                            </Typography>
                        </Box>

                        {
                            !isResetSuccess
                            && (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting, getFieldProps, getFieldMeta, submitCount }) => (
                                    <Form noValidate>
                                        <Field
                                            as={MInputPassword}
                                            type="password"
                                            placeholder="Enter new password"
                                            label="New Password"
                                            id="newPassword"
                                            autoFocus
                                            {...getFieldProps('newPassword')}
                                            meta={getFieldMeta('newPassword')}
                                            isSubmitted={submitCount > 0}
                                        />
                                        <Typography style={{color: 'red', textAlign: 'left', fontSize: 11}}> {getFieldMeta('newPassword').error} </Typography>
                                        <Field
                                            as={MInputPassword}
                                            type="password"
                                            placeholder="Confirm Password"
                                            label="New Password"
                                            id="newPassConfirm"
                                            // autoFocus
                                            {...getFieldProps('newPassConfirm')}
                                            meta={getFieldMeta('newPassConfirm')}
                                            isSubmitted={submitCount > 0}
                                        />
                                        <Typography style={{color: 'red', textAlign: 'left', fontSize: 11}}> {getFieldMeta('newPassConfirm').error} </Typography>

                                        <Box display="flex" justifyContent="center" mt={14}>
                                            <div className={classes.buttonWrapper}>
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    Update Password
                                                </Button>
                                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                            )
                        }

                        {
                            isResetSuccess
                            && (
                            <>
                                <Typography>
                                    Your password has been changed. Please relogin with new password.
                                </Typography>

                                <Box display="flex" justifyContent="center" mt={14}>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            className={classes.button}
                                            onClick={logout}
                                        >
                                            Back To The Login Page
                                        </Button>
                                    </div>
                                </Box>
                            </>
                            )
                        }
                    </Box>
                </Paper>
            </Container>
        </SignUpLayout>
    );
};

export default ResetPassword;
