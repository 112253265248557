import React from 'react';
import Switch from '@material-ui/core/Switch';
import { SwitchProps } from '@material-ui/core/Switch';
import { useStyles } from './styles';

const ShowHideSwitch: React.FC<SwitchProps> = ({...props}) => {
  const switchStyles = useStyles();
  return (
    <Switch
        {...props}
        classes={switchStyles}
    />
  );
};

export default ShowHideSwitch