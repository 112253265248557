import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Programs = lazy(() => import('./Programs'));

export default {
    path: routeConstants.PLAN_PROGRAM.path,
    component: Programs,
    layout: LayoutOptions.dashboard,
};
