import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function PencilIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="-2.5 -1 18 18" className={classes.active}>
            <path d="M10.2028 1.81816L11.6674 0.353554L13.435 2.12118L11.9704 3.58579L10.2028 1.81816ZM1.39369 10.627L9.39382 2.62691L11.1615 4.39454L3.16132 12.3947L0.412751 13.3756L1.39369 10.627Z" stroke="#FFA21E" strokeWidth="0.5" fill="#fff"/>
        </SvgIcon>
    );
}
