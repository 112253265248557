import React, { FC } from 'react';
import { makeStyles, InputBase, Theme, Typography } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    inputMov: {
        padding: '5px 12px',
        minHeight: 37,
        background: '#FFFFFF',
        boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        minWidth:'100%',
        border: 'none',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.25px',
        color: '#282828'

    },
    inputMovFocused: {
        borderColor: theme.palette.primary.main,
        border: '2px solid red',
    },
    inputClass: {
        '&::placeholder': {
            color: '#282828 !important',
            opacity: 1,
            fontWeight: 400,
        }
    },
}));

const CustomInputPayment: FC<Props & InputBaseProps> = ({ meta, isSubmitted, placeholder, ...props }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <InputBase
                classes={{focused: classes.inputMovFocused, input: classes.inputClass}}
                {...props}
                placeholder={placeholder}
                className={classes.inputMov}/>
            {meta?.touched && meta?.error && (
                <Typography variant="caption" style={{color: '#97412A'}}>{meta.error}</Typography>
            )}
        </React.Fragment>
    );
};

export default CustomInputPayment;
