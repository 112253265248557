import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useProfile } from "services/util";
import routeConstants from "../_constant/routes";
import { useStyles } from "./styles";
import { Tooltip } from "@material-ui/core";

const NavAccount = () => {
  const location = useLocation();
  const classes = useStyles();
  const profile: any = useProfile();

  // the user is a guest and is active - they should have the Add More Weeks option. Otherwise, that should be removed
  const qualifiesForAddMoreWeeks = profile && profile.role === "guest" && profile.statusBooking === "Active" ? true : false;

  let usableRouteConstants: any = { ...routeConstants };

  if (!qualifiesForAddMoreWeeks) {
    const { ADD_MORE_WEEKS, ...remainingRouteConstants } = routeConstants;

    usableRouteConstants = remainingRouteConstants;
  }

  return (
    <List component="nav" className={classes.nav}>
      {Object.values(usableRouteConstants).map(({ path, name, Icon }: any, i) => (
        <Tooltip key={i} title={path === "/add-booking?add-weeks" ? "Please contact your coach to add more weeks" : name}>
          <span>
            <ListItem key={i} button component={RouterLink} to={path} disabled={path === "/add-booking?add-weeks"} selected={path === location.pathname}>
              <ListItemIcon>
                <Icon color="primary" />
              </ListItemIcon>
              <ListItemText>{name}</ListItemText>
            </ListItem>
          </span>
        </Tooltip>
      ))}
    </List>
  );
};

export default NavAccount;
