import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "pages/DashboardUser/components/main/daily/styles";

interface Props {
  active: boolean;
  preview: boolean;
}

const NoSnackingLabel: FC<Props> = ({ active, preview }) => {
  const classes = useStyles();

  return (
    <Box display={"flex"} flexDirection="column" marginTop={"11px"}>
      <Box display={"flex"}>
        <Box flexGrow={1} display="flex" alignItems={"center"} marginLeft={1}>
          <Typography gutterBottom className={classes.nutritionGoalTypo}>
            <span style={{ color: "black" }}>No Snacking</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoSnackingLabel;
