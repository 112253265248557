import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function FlagIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props}
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <g clipPath="url(#clip0)">
                <path d="M19.5703 1.52051H0.429688V18.4795H19.5703V1.52051Z"
                      strokeMiterlimit="10"
                      strokeLinejoin="round"/>
                <path
                    d="M14.9543 18.4752L14.9492 18.4799H0.429688V12.0127L5.17773 6.14551L10.4738 12.8252L14.9543 18.4752Z"
                    strokeMiterlimit="10" strokeLinejoin="round"/>
                <path
                    d="M19.5704 16.4837V18.4798H14.9493L14.9543 18.4751L10.4739 12.8251L13.6032 8.95752L19.5704 16.4837Z"
                    strokeMiterlimit="10" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
