import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    RadioGroup, Radio,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import useStyles from '../styles';

interface Props {
    show: boolean;
    onClose: () => void;
    onFilter: (data: any) => void;
}

const FilterListModal: FC<Props> = ({ show, onClose, onFilter }) => {
    const [filter, setFilter] = useState<any>({
        daily: {
            check: false,
            value: '',
        },
        weekly: {
            check: false,
            value: '',
        },
        status: {
            check: false,
            value: '',
        }
    });
    const classes = useStyles();

    const handleChangeCheckbox = (type: any, event: any) => {
        const current = filter[type];
        const data = {
            ...filter,
            [type]: {
                ...current,
                check: event.target.checked,
            },
        };
        setFilter(data);
    };

    const handleChange = (type: any, event: any) => {
        const current = filter[type];
        const data = {
            ...filter,
            [type]: {
                ...current,
                value: event.target.value,
            },
        };
        setFilter(data);
    };

    return (<Dialog onClose={() => onClose()}
                    aria-labelledby="customized-dialog-title" open={show} fullWidth
                    maxWidth={'md'}>
        <DialogTitle id="customized-dialog-title">
            ASSESSMENT FILTER OPTIONS
        </DialogTitle>
        <DialogContent>
            <Accordion className={classes.filterAccordion}>
                <Box px={'17px'} py={'12px'}>
                    <FormControlLabel
                        aria-label="DAILY ASSESSMENT"
                        control={<Checkbox color="primary"
                                           onChange={(e) => handleChangeCheckbox('daily', e)}
                                           checked={filter.daily.check}/>}
                        label="DAILY ASSESSMENT"
                    />
                </Box>
            </Accordion>
            <Accordion className={classes.filterAccordion}>
                <Box px={'17px'} py={'12px'}>
                    <FormControlLabel
                        aria-label="WEEKLY ASSESSMENT"
                        control={<Checkbox color="primary"
                                           onChange={(e) => handleChangeCheckbox('weekly', e)}
                                           checked={filter.weekly.check}/>}
                        label="WEEKLY ASSESSMENT"
                    />
                </Box>
            </Accordion>
            <Accordion className={classes.filterAccordion} expanded>
                <AccordionSummary>
                    <FormControlLabel
                        aria-label="STATUS"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox color="primary"
                                           onChange={(e) => handleChangeCheckbox('status', e)}
                                           checked={filter.status.check}/>}
                        label="STATUS"
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top" onChange={(e) => handleChange('status', e)} value={filter.status.value} >
                        <FormControlLabel value="seen" control={<Radio color="primary" />} label="Seen" />
                        <FormControlLabel value="approved" control={<Radio color="primary" />} label="Approved" />
                        <FormControlLabel value="not seen yet" control={<Radio color="primary" />} label="Not Yet Seen" />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
        </DialogContent>
        <DialogActions style={{ padding: '24px' }}>
            <Button variant={'outlined'} onClick={() => onClose()}
                    color="secondary">
                Cancel
            </Button>
            <Button variant={'contained'} autoFocus
                    onClick={() => {
                        onClose();
                        onFilter(filter);
                    }} color="secondary">
                Apply
            </Button>
        </DialogActions>
    </Dialog>);
};
export default FilterListModal;