import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    nav: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transform: 'translateX(-56px)',
        width: '215px',
        '& .Mui-selected': {
            borderRight: `2px solid ${theme.palette.primary.main}`,
        },
    },
    navMobile: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transform: 'translateX(-56px)',
        width: 'max-content',
        '& .Mui-selected': {
            borderRight: `2px solid ${theme.palette.primary.main}`,
        },
        position: 'fixed',
        background: 'white',
        top: '25%',
        borderRadius: '0px 10px 10px 0px'
    }
}));
