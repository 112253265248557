import React, { FC, ReactElement } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Footer from 'components/layout/Footer';

interface Props {
    children: ReactElement;
    background: string,
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

const BackgroundLayout: FC<Props> = ({ children, background }) => {
    const classes = useStyles();

    return (
        <Container
            disableGutters
            maxWidth={false}
            className={clsx(classes.root, classes.image)}
            style={{ backgroundImage: `url(${background})`
        }}>
            {children}
            <Footer />
        </Container>
    );
}

export default BackgroundLayout;
