import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';

const Container = styled.div`
    width: ${props => props.theme.size + 'px'};
    height: ${props => props.theme.size + 'px'};
    position: relative;
`;

interface BorderProps {
    rotate: number;
}

const Border = styled.div<BorderProps>`
    width: ${props => props.theme.size + 'px'};
    height: 2px;
    background-color: #414141;
    position: absolute;
    top: 50%;
    transform: ${props => `translateY(-50%) rotate(${props.rotate}deg)`};
`;

interface CloseIconProps {
    size?: number; // set option size here
};

const CloseIcon: FC<CloseIconProps> = ({ size }) => {
    return (
        <ThemeProvider theme={{ size }}>
            <Container>
                <Border rotate={45} />
                <Border rotate={-45} />
            </Container>
        </ThemeProvider>
    );
};

CloseIcon.defaultProps = {
    size: 24,
};

export default CloseIcon;
