import React, { FC, useEffect, useState, useContext } from "react";
import { DateRange, DefinedRange } from "materialui-daterange-picker";
import { Box, Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { useStyles } from "./styles";
import CustomInput from "components/controls/CustomInput/CustomInputPayment";
import { Alert } from "@material-ui/lab";
import { DayPicker } from "react-day-picker";
import "./dayStyle.css";
import ModalWarning from "components/_newDesign/alert/Modal";
import TypographyMUI from "components/_newDesign/typography/Typography";
import auth from "services/domain/auth";
import { getBookingByEmail } from "services/domain/admin/bookings";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import image from "assets/images/calendar.png";
import { ProfileContext, ProfileContextType, BookingEmailContext, BookingEmailContextType } from "../PaymentStore";

interface DateRangePickerWrapperProps {
  label?: string;
  margin?: "normal";
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  closeOnClickOutside?: boolean;
  closeOnSelection?: boolean;
  wrapperClassName?: string;
  definedRanges?: DefinedRange[];
  totalWeek: number;
}
const bookedStyle = { background: "rgba(0, 0, 0, 0.05)" };

const DateRangePickerInput: FC<DateRangePickerWrapperProps> = ({ onChange, label, margin, closeOnSelection, totalWeek, ...otherDateProps }) => {
  const { profile, setProfile } = useContext(ProfileContext) as ProfileContextType;
  const { bookingEmail, setBookingEmail } = useContext(BookingEmailContext) as BookingEmailContextType;

  const value = profile;
  const coach_id = profile.coach_id;

  const [showAlreadyBookedAlert, setShowAlreadyBookedAlert] = useState(false);

  // const userEmail = auth.userProperties?.userData?.email;
  const userEmail = bookingEmail ? bookingEmail : auth.userProperties?.userData?.email;

  //* Clients previous paid for bookings
  const [userBookings, setUserBookings] = React.useState<Date[]>([]);
  const [filteredDataBooking, setFilteredDataBooking] = React.useState<any>([]);
  const [weeks, setWeeks] = useState<any[]>([]);

  const [range, setRange] = useState<any>({
    from: value.startDate ? new Date(moment(value.startDate).toString()) : undefined,
    to: value.startDate ? new Date(moment(value.endDate).toString()) : undefined,
  });
  const [startDateAlreadyPastAlert, setStartDateAlreadyPastAlert] = useState(true);
  const classes = useStyles();

  //TODO WE SHOULDN"T NEED MONTH WITHOUT THE CALENDAR
  const [month, setMonth] = useState<Date>(new Date());

  React.useEffect(() => {
    //* check the difference between the start date and today.
    const startDateIsPastDate = moment(value.startDate).diff(new Date(), "days") < 0;

    //* show message when start date is in past
    setStartDateAlreadyPastAlert(startDateIsPastDate);

    if (userEmail && value && value.startDate && value.endDate && userBookings.length !== 0) {
      const check = userBookings.filter((item) => moment(item).format("YYYY-MM-DD") === moment(value.startDate).format("YYYY-MM-DD"));
      if (check.length > 0) {
        setShowAlreadyBookedAlert(true);
        setRange({
          from: undefined,
          to: undefined,
        });
        onChange({ startDate: undefined, endDate: undefined });
        return;
      }
    }
    if (value && value.startDate && value.endDate) {
      let date = "";
      const diff = moment(value.endDate).diff(value.startDate, "weeks");
      const days: string[] = [];
      for (let index = 0; index < diff; index++) {
        date = moment(range.from)
          .add(7 * index, "days")
          .format("YYYY-MM-DD");
        days.push(`${moment(date).startOf("weeks").format("MMM/DD/YYYY")} - ${moment(date).endOf("weeks").format("MMM/DD/YYYY")}`);
      }
    }
  }, [value.endDate, value.startDate, totalWeek, coach_id]);

  const AlreadyExistingBookingsModalContent = () => {
    return (
      <>
        <TypographyMUI variant="mov_body1">You already have bookings on these dates.</TypographyMUI>
      </>
    );
  };
  const onSelectDateRange = (range) => {
    if (range && range.from) {
      const diff = moment(range.from).diff(new Date(), "days");
      if (diff < 0) {
        setStartDateAlreadyPastAlert(true);
      } else {
        setStartDateAlreadyPastAlert(false);
      }
    }
    if (range && range.from && range.to && range.from.getTime() !== range.to.getTime()) {
      onChange({
        startDate: range.from,
        endDate: range.to,
      });

      let date = "";
      const diff = moment(range.to).diff(range.from, "weeks");
      const start = moment(range.from);
      const end = moment(range.to);
      for (let index = 0; index < filteredDataBooking.length; index++) {
        const startDate = moment(filteredDataBooking[index].startDate);
        const endDate = moment(filteredDataBooking[index].endDate);
        const diff1 = startDate.diff(start, "days");
        const diff2 = endDate.diff(start, "days");
        const diff3 = startDate.diff(end, "days");
        const diff4 = endDate.diff(end, "days");
        if ((diff1 <= 0 && diff2 > 0) || (diff3 < 0 && diff4 >= 0) || (diff1 > 0 && diff4 <= 0)) {
          setShowAlreadyBookedAlert(true);
          setRange({
            from: undefined,
            to: undefined,
          });
          return;
        }
      }
      const days: string[] = [];
      for (let index = 0; index < diff; index++) {
        date = moment(range.from)
          .add(7 * index, "days")
          .format("YYYY-MM-DD");
        days.push(`${moment(date).startOf("weeks").format("MMM/DD/YYYY")} - ${moment(date).endOf("weeks").format("MMM/DD/YYYY")}`);
      }
    }
    setRange(range);
    // eslint-disable-next-line
  };

  const getRange = (startDate: string, endDate: string) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, "days");
    let range: Date[] = [];
    for (let i = 0; i < diff; i++) {
      const date = moment(startDate).add(i, "days").format("YYYY-MM-DD");
      range.push(new Date(date));
    }
    return range;
  };
  const fetch = async (email) => {
    try {
      const result = await getBookingByEmail({ email: email });
      result.data.map((elm) => {
        if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
        if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
        return elm;
      });
      const filteredData = result.data.filter((item) => item.statusBooking === "Waiting" || item.statusBooking === "Active");
      let dates: Date[] = [];
      if (filteredData.length !== 0) {
        for (let index = 0; index < filteredData.length; index++) {
          const days = await getRange(filteredData[index].startDate, filteredData[index].endDate);
          dates = dates.concat(days);
          days.splice(0, 1);
          filteredData[index].days = days;
        }
      }
      // dates may be out of order - we want them in order so we know that the end of the array is the last active dates they have booked
      const sortedDates = dates.sort((a: Date, b: Date) => {
        return a.getTime() > b.getTime() ? 1 : -1;
      });

      setFilteredDataBooking(filteredData);
      setUserBookings(sortedDates);
      const calculatedWeeks = getWeeks(sortedDates);
      setWeeks(calculatedWeeks);
    } catch (e) {
      console.error(e);
    }
  };

  const markDateAsSelected = (date: any) => {
    const weeksCopy = [...weeks];
    const index = weeksCopy.findIndex((week) => week.dateNum === date.dateNum);
    const removedSelected = weeksCopy.map((w) => {
      w.selected = false;
      return { ...w };
    });
    removedSelected[index].selected = true;

    setWeeks(removedSelected);

    //* find end date
    const startDate = moment(removedSelected[index].dateNum).toDate();
    const endDate = moment(removedSelected[index].dateNum).add(totalWeek, "weeks").toDate();
    onSelectDateRange({
      to: endDate,
      from: startDate,
    });
  };

  const getWeeks = (prevBookedDates) => {
    const noWeeks = 4;

    const weeks: any = [];
    let lastWeekBooked = prevBookedDates[prevBookedDates.length - 1];

    //add the 4 weeks to week array
    for (let i = 1; i < noWeeks + 1; i++) {
      const dateFormatted: any = moment(lastWeekBooked).add(i, "weeks").startOf("isoWeek").subtract(1, "days").format("MMMM Do YYYY");
      const dateNum = moment(lastWeekBooked).add(i, "weeks").startOf("isoWeek").subtract(1, "days").format();
      weeks.push({ dateFormatted, dateNum, selected: false });
    }

    return weeks;
  };

  useEffect(() => {
    if (userEmail) {
      fetch(userEmail);
      setRange({
        from: undefined,
        to: undefined,
      });
      onChange({ startDate: undefined, endDate: undefined });
    } else {
      setWeeks(getWeeks([]));
    }

    // eslint-disable-next-line
  }, [bookingEmail]);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, [month, coach_id]);

  React.useEffect(() => {
    if (!profile.startDate) return;

    if (!range || !range.from || !range.to) {
      setProfile({
        ...profile,
        startDate: undefined,
      });
    }
  }, [profile]);

  return (
    <>
      <ModalWarning
        image={<img alt="" src={image} width="260px" />}
        show={showAlreadyBookedAlert}
        onClose={() => setShowAlreadyBookedAlert(false)}
        type="warning"
        content={<AlreadyExistingBookingsModalContent />}
      />
      <Box display={"flex"} my={"10px"} ml={1}>
        <InfoOutlinedIcon />
        <TypographyMUI style={{ color: "#9A9A9A" }} variant="mov_body2">
          Program starts every Sunday
        </TypographyMUI>
      </Box>
      {weeks.map((week, ind) => (
        <Box
          mt="8px"
          key={ind}
          width={"100%"}
          padding="12px"
          borderRadius={"10px"}
          border="1px solid rgba(0, 0, 0, 0.1)"
          textAlign={"center"}
          style={{ cursor: "pointer" }}
          onClick={(e) => markDateAsSelected(week)}
          className={week.selected && classes.circleStepDone}
          // key={week.dateNum}
        >
          <TypographyMUI variant="mov_button1_semibold">{week.dateFormatted}</TypographyMUI>
        </Box>
      ))}

      {range.from && range.to && (
        <>
          <TypographyMUI
            variant="mov_overline2"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {" "}
            YOU WILL HAVE ACCESS TO MOVARA HOME FROM
          </TypographyMUI>

          <Grid container style={{ marginTop: "25px" }}>
            <Grid item md={6} xs={6} sm={6} xl={6}>
              <Typography className={classes.label}>Start Date</Typography>
            </Grid>
            <Grid item md={6} xs={6} sm={6} xl={6}>
              <Typography className={classes.label}>End Date </Typography>
            </Grid>
            <Grid item md={6} xs={6} sm={6} xl={6} style={{ paddingRight: "20px" }}>
              <CustomInput
                inputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled
                value={profile.startDate && moment(profile.startDate).format("MMMM Do YYYY")}
                fullWidth
                type="text"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6} xl={6}>
              <CustomInput
                inputProps={{
                  readOnly: true,
                  startAdornment: <EventIcon />,
                }}
                disabled
                value={profile.endDate && moment(profile.endDate).format("MMMM Do YYYY")}
                fullWidth
                type="text"
              />
            </Grid>
          </Grid>
        </>
      )}
      {startDateAlreadyPastAlert && (
        <Box mt={4}>
          <Alert severity="warning">
            Heads up! The start date you’ve selected has already passed, if you continue you will have already missed the first call. Would you like to proceed?
          </Alert>
        </Box>
      )}

      <Box mt={4} width="100%" display="flex" justifyContent="center" className={classes.mobileDisplay}>
        <Box height={"auto"} width="fit-content" boxShadow={"1px 1px 8px rgba(65, 65, 65, 0.1)"} borderRadius="20px">
          <Box display={"flex"}>
            <Box pl={"10px"}>
              <DayPicker
                modifiers={{ booked: userBookings }}
                modifiersStyles={{ booked: bookedStyle }}
                month={month}
                onMonthChange={setMonth}
                className="Selectable"
                numberOfMonths={1}
                mode="range"
                selected={range}
                onSelect={onSelectDateRange}
                disabled={(date) => {
                  const diff = moment(date).diff(new Date(), "days");
                  // return moment(date).day() !== 0 || diff < 0 || disableWeek.findIndex(item => item === moment(date).format('YYYY-MM-DD')) !== -1 || bokings.findIndex(item => moment(item).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) !== -1
                  return moment(date).day() !== 0 || diff < 0;
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DateRangePickerInput;
