import { categories, taskTypes } from './enum';

const mealsRow = (data: any) => {
    let archived: string[] = [];
    if (data && data.dailyNutritionGoal) {
        for (const property in data.dailyNutritionGoal) {
            if (data.dailyNutritionGoal[property].achieved) archived.push(property);
        }
        if (archived.length > 0) {
            return true;
        } else {
            return false
        }
    }
    return false
};

const postDailyPhotoRow = (data: any) => {
    return Boolean(data.postDailyPhoto?.isCheck || data.postDailyPhoto?.comments);
};

const dailyStretchRow = (data: any) => {
    return Boolean(data.dailyStretch?.isCheck || data.dailyStretch?.comments || data.dailyStretch?.watchVideo);
};

const dailyFitnessRow = (data: any) => {
    return Boolean(data.dailyFitness?.isCheck || data.dailyFitness?.comments || data.dailyFitness?.watchVideo);
};

const dailyPersonalDevRow = (data: any) => {
    return Boolean(data.dailyPersonalDev?.isCheck || data.dailyPersonalDev?.comments || data.dailyPersonalDev?.watchVideo);
};


export const reformatUserDailyTable = (data: any[]) => {
    const days = [
        { label: 'S', title: 'Sunday' },
        { label: 'M', title: 'Monday' },
        { label: 'TU', title: 'Tuesday' },
        { label: 'W', title: 'Wednesday' },
        { label: 'TH', title: 'Thursday' },
        { label: 'F', title: 'Friday' },
        { label: 'S', title: 'Saturday' },
    ];

    const categoriesRow = [
        categories.MEALS,
        categories.PHOTO,
        categories.STRETCH,
        categories.FITNESS,
        categories.PERSONAL_DEV,
    ];

    const sorting = data.sort((a, b) => (a.typesDaily > b.typesDaily ? 1 : -1));
    const rows = categoriesRow.map(category => {
        return days.map((day: any, index) => {
            let value;
            const find = sorting.find((task: any) => task.typesDaily === `day ${index}`);
            if (!find) {
                value = false;
            } else {
                switch (category) {
                    case categories.MEALS:
                        value = mealsRow(find);
                        break;
                    case categories.PHOTO:
                        value = postDailyPhotoRow(find);
                        break;
                    case categories.STRETCH:
                        value = dailyStretchRow(find);
                        break;
                    case categories.FITNESS:
                        value = dailyFitnessRow(find);
                        break;
                    case categories.PERSONAL_DEV:
                        value = dailyPersonalDevRow(find);
                        break;
                    default:
                        break;
                }
            }
            return {
                title: `Day ${index}`,
                type: category,
                status: value,
            };
        });
    });
    return { days, rows };
};

export const reformatUserWeeklyTable = (data: any[]) => {
    let reformat = [{
        name: taskTypes.CURRENT_WEIGHT,
        value: 0,
        unit: '',
    }, {
        name: taskTypes.GOAL_WEIGHT,
        value: 0,
        unit: '',
    }];

    const find = data.find((task: any) => task.typesWeekly === `week`);
    if (find) {
        reformat = reformat.map(item => ({
            ...item,
            value: find[item.name].value || 0,
            unit: find[item.name].unit || '',
        }));
    }
    return reformat;
};

export const reformatUserWeeklyAssesment = (data: any[]) => {
    let format = {
        "photoScale": {
            "data": "https://restapi.rover.id/scale-1626882334015.png",
        },
        "currentWeight": {
            "data": 60,
            "typeData": "kg",
        },
        "goalWeight": {
            "data": 0,
            "typeData": "lbs",
            "text": ""
        },
        "waistMeasurement": {
            "data": 20,
            "typeData": "inch"
        },
        "chestMeasurement": {
            "data": 21,
            "typeData": "inch"
        },
        "hipMeasurement": {
            "data": 22,
            "typeData": "inch",
            "text": ""
        },
        "bodyFat": {
            "data": 60,
            "typeData": "",
        },
        "nutritionGoalWeekly": undefined,
        
    }

    const find = data.find((task: any) => task.typesWeekly === `week`);
    if (find) {
        format = {
            "photoScale": {
                "data": find['photoScale'].value || "",
            },
            "currentWeight": {
                "data": find['currentWeight'].value || 0,
                "typeData": find['currentWeight'].unit || "kg",
            },
            "goalWeight": {
                "data": find['goalWeight'].value || 0,
                "typeData": find['goalWeight'].unit || "lbs",
                "text": find['goalWeight'].comments || ""
            },
            "waistMeasurement": {
                "data": find['waistMeasurement'].value || 0,
                "typeData": find['waistMeasurement'].unit ||  "inch"
            },
            "chestMeasurement": {
                "data": find['chestMeasurement'].value || 0,
                "typeData": find['chestMeasurement'].unit || "inch"
            },
            "hipMeasurement": {
                "data": find['hipMeasurement'].value || 0,
                "typeData": find['hipMeasurement'].unit || "inch",
                "text": ""
            },
            "bodyFat": {
                "data": find['bodyFat'].value || 0,
                "typeData": find['bodyFat'].unit || "",
            },
            "nutritionGoalWeekly": find['weeklyNutritionGoal'] || undefined
        }
    }
    return format;
};

export const logDescriptionTemplate = [
    {
        name: categories.MEALS,
        description: [
            {
                type: 'balanceBreakfast',
                question: 'Ate a balanced breakfast',
                isVideo: false,
            },
            {
                type: 'balanceLunch',
                question: 'Ate a balanced lunch',
                isVideo: false,
            },
            {
                type: 'balanceDinner',
                question: 'Ate a balanced dinner',
                isVideo: false,
            },
            {
                type: 'stayCalories',
                question: 'Achieved my Nutrition goal',
                isVideo: false,
            },
        ],
    },
    {
        name: categories.PHOTO,
        description: [
            {
                type: 'postDailyPhoto',
                question: 'Posted on the Community Page today.',
                isVideo: false,
            },
        ],
    },
    {
        name: categories.STRETCH,
        description: [
            {
                type: 'dailyStretch',
                question: 'Watch Daily Stretch Video 1',
                isVideo: true,
            },
            {
                type: 'dailyStretch',
                question: 'I did my own stretch workout',
                isVideo: false,
            },
        ],
    },
    {
        name: categories.FITNESS,
        description: [
            {
                type: 'dailyFitness',
                question: 'Watch Daily Fitness Video 1',
                isVideo: true,
            },
            {
                type: 'dailyFitness',
                question: 'I did my own fitness workout',
                isVideo: false,
            },
        ],
    },
    {
        name: categories.PERSONAL_DEV,
        description: [
            {
                type: 'dailyPersonalDev',
                question: 'Watch Daily Personal Development',
                isVideo: true,
            },
            {
                type: 'dailyPersonalDev',
                question: 'Spent at least 10 minutes on personal development.',
                isVideo: false,
            },
        ],
    },
]