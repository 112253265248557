import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: 'transparent',
        '& *': {
            stroke: theme.palette.secondary.main,
        },
        '&.MuiSvgIcon-colorPrimary *': {
            stroke: theme.palette.primary.main,
        },
    },
    default: {
        color: 'transparent',
        stroke: theme.palette.secondary.main,
    },
    active: {
        stroke: theme.palette.primary.main,
    },
    disabled: {
        stroke: '#C4C4C4',
    },
    filled: {
        fill: theme.palette.primary.main,
    },
    shadowHover: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
            borderRadius: '10px',
        },
        padding: '3px',
    },
}));
