import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
        },
        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            height: 80,
        },
        bookingTable: {
            maxHeight: 150,
        },
        paper: {
            padding: theme.spacing(1),
            borderRadius: 10,
            height: 'auto',
        },
        header: {
            fontSize: 14,
            marginBottom: 18,
            minHeight: 40,
        },
        grid: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }),
);

export default useStyles;
