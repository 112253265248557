import React, { useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import Statisticts from './component/statisticts';
import Chart from './component/chart';
import OverviewMain from './component/main';
import Feeds from './component/feeds';
import OverviewOptional from './component/optional';
import { http } from 'services/data';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridHeader: {
            marginBottom: theme.spacing(5),
        },
        grid: {
            marginBottom: theme.spacing(2),
        },
        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        boxTitle: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        boxBody: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
        },
        formControl: {
            width: '100%',
        },
        buttonSelected: {
            marginLeft: 5,
            marginRight: 5,
        },
    })
);

const WeeklyProgram = () => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [selectedWeek, setSelectedWeek] = React.useState('1');
    // eslint-disable-next-line
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [report, setReport] = React.useState<'week' | 'year' | 'month'>(
        'week'
    );
    const [dateRangeValues, setDateRangeValues] = React.useState<any>({
        startDate: moment().startOf('week').toString(),
        endDate: moment().endOf('week').toString(),
    });
    const [dataReport, setDataReport] = React.useState<any>();
    const [indicator, setIndicator] = React.useState<number>(0);
    const onChangeReport = (data: 'week' | 'year' | 'month') => {
        setReport(data);
        setIndicator(0);
    };
    const setDateRange = () => {
        if (indicator === 0) {
            setDateRangeValues({
                startDate: moment().startOf(report).toString(),
                endDate: moment().endOf(report).toString(),
            });
        } else if (indicator > 0) {
            setDateRangeValues({
                startDate: moment()
                    .add(indicator, report)
                    .startOf(report)
                    .toString(),
                endDate: moment()
                    .add(indicator, report)
                    .endOf(report)
                    .toString(),
            });
        } else if (indicator < 0) {
            setDateRangeValues({
                startDate: moment()
                    .subtract(Math.abs(indicator), report)
                    .startOf(report)
                    .toString(),
                endDate: moment()
                    .subtract(Math.abs(indicator), report)
                    .endOf(report)
                    .toString(),
            });
        }
    };
    const getData = () => {
        setLoading(true);
        http.get('admin/report/weeklyProgram', {
            params: {
                startDate: dateRangeValues.startDate,
                endDate: dateRangeValues.endDate,
            },
        })
            .then((res: any) => {
                setDataReport(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [dateRangeValues]);
    useEffect(() => {
        setDateRange();
        // eslint-disable-next-line
    }, [indicator, report]);
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.gridHeader}>
                    <Grid item md={12}>
                        <Typography variant="h5">
                            Weekly Program Report
                        </Typography>
                    </Grid>
                </Grid>
                <Paper elevation={3}>
                    <Grid container>
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.boxHeader}
                        >
                            <Box
                                height={80}
                                className={classes.boxTitle}
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                            >
                                <Box flexGrow={1} textAlign="left">
                                    <IconButton
                                        id={`weekly-report-arrow-left`}
                                        onClick={() =>
                                            setIndicator(indicator - 1)
                                        }
                                    >
                                        <KeyboardArrowLeftIcon color="disabled" />
                                    </IconButton>
                                </Box>
                                <Box flexGrow={1}>
                                    <Button
                                        color={
                                            report === 'week'
                                                ? 'primary'
                                                : 'default'
                                        }
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        className={classes.buttonSelected}
                                        onClick={() => onChangeReport('week')}
                                    >
                                        1 W
                                    </Button>
                                    <Button
                                        color={
                                            report === 'month'
                                                ? 'primary'
                                                : 'default'
                                        }
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        className={classes.buttonSelected}
                                        onClick={() => onChangeReport('month')}
                                    >
                                        1 M
                                    </Button>
                                    <Button
                                        color={
                                            report === 'year'
                                                ? 'primary'
                                                : 'default'
                                        }
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        className={classes.buttonSelected}
                                        onClick={() => onChangeReport('year')}
                                    >
                                        1 Y
                                    </Button>
                                </Box>
                                <Box flexGrow={1} textAlign="right">
                                    <IconButton
                                        id={`weekly-report-arrow-right`}
                                        onClick={() =>
                                            setIndicator(indicator + 1)
                                        }
                                    >
                                        <KeyboardArrowRightIcon color="disabled" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.boxBody}
                        >
                            <Box
                                height={40}
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                            >
                                <Box flexGrow={1}>
                                    <Typography>
                                        {moment(
                                            dateRangeValues.startDate
                                        ).format('DD-MMM-YYYY')}{' '}
                                        -{' '}
                                        {moment(dateRangeValues.endDate).format(
                                            'DD-MMM-YYYY'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {loading ? (
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        display="flex"
                        pb={1}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress color="primary" />
                    </Box>
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12} sm={12} md={12}>
                        <Statisticts data={dataReport?.dataUpperBar} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Grid container spacing={3}>
                                    {report === 'week' && (
                                        <Chart dateRange={dateRangeValues} />
                                    )}
                                    <Feeds data={dataReport?.overviewComFeed} />
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Grid container spacing={3}>
                                    <OverviewMain
                                        data={dataReport?.overviewMainTask}
                                    />
                                    <OverviewOptional
                                        data={dataReport?.overviewOptionalTask}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default WeeklyProgram;
