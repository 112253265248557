import React, { FC } from 'react';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon, MorningIcon, NightIcon, AfternoonIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import { useStyles } from '../styles'

interface Props {
    data?: {
        text: string,
        balanceBreakfast: {
            isCheck: boolean,
            text: string
        },
        balanceLunch: {
            isCheck: boolean,
            text: string
        },
        balanceDinner: {
            isCheck: boolean,
            text: string
        },
        achieved: boolean
    } | any
}
const BalancedMeals: FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <Box>
            <Box>
                <FormControlLabel
                    aria-label="Acknowledge"
                    checked={true}
                    control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                    label={
                        <Box display={'flex'} alignItems="center">
                            <SpoonForkIcon />
                            <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                I followed the plate method for all three of my meals today
                            </Typography>
                        </Box>
                    }
                />
                {props.data?.text && (
                    <InputText
                    multiline
                    name="text"
                    placeholder="Write notes to your coach..."
                    style={{ width: '100%' }}
                    disabled={true}
                    value={props.data?.text}
                    endAdornment={<Typography variant='caption'>{`${props.data?.text.length}/255`}</Typography>}
                />
                )}
            </Box>
            <Box>
                <Box width={'100%'}>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="32px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={true}
                                control={<Checkbox name="breackfast" color="primary" />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Breakfast
                                    </Typography>
                                }
                            />
                            <MorningIcon />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                name="balanceBreakfastText"
                                value={props.data?.balanceBreakfast.text}
                                endAdornment={<Typography variant='caption'>{`${props.data?.balanceBreakfast.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="12px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={true}
                                control={<Checkbox color="primary" name='lunch' />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Lunch
                                    </Typography>
                                }
                            />
                            <AfternoonIcon />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                name="balanceLunchText"
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                value={props.data?.balanceLunch.text}
                                
                                endAdornment={<Typography variant='caption'>{`${props.data?.balanceLunch.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="12px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={true}
                                control={<Checkbox color="primary" name='dinner' />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Dinner
                                    </Typography>
                                }
                            />
                            <NightIcon />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                name="balanceDinnerText"
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                value={props.data?.balanceDinner.text}
                                endAdornment={<Typography variant='caption'>{`${props.data?.balanceDinner.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BalancedMeals;
