import React, { FC } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "components/_newDesign/typography/Typography";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { SpoonForkIcon } from "components/_newIcon/";
import { useStyles } from "./styles";
import ButtonMui from "components/_newDesign/button/Button";
import { BlanceMealsRequest } from "interfaces/nutritionGoals";
import moment from "moment";
import { useSnackbar } from "notistack";
import { postbalanceMeals } from "services/domain/users/nutritionGoal";
import { AvatarGroupComponent } from "../AvatarGroup/AvatarGroup";
import { UserSubmissionListItem } from "interfaces/user";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview?: boolean;
  defaultExpanded?: boolean;
  weekly?: boolean;
  isDone?: boolean;
  submitSucces?: () => void;
  data?: {
    text: string;
    balanceBreakfast: {
      isCheck: boolean;
      text: string;
    };
    balanceLunch: {
      isCheck: boolean;
      text: string;
    };
    balanceDinner: {
      isCheck: boolean;
      text: string;
    };
    achieved: boolean;
  };
  userList?: Array<UserSubmissionListItem>;
  submissionDate?: string;
}

const defaultValue: BlanceMealsRequest = {
  achieved: true,
  text: "",
  balanceBreakfastIsCheck: true,
  balanceBreakfastText: "",
  balanceLunchIsCheck: true,
  balanceLunchText: "",
  balanceDinnerIsCheck: true,
  balanceDinnerText: "",
  submissionDate: moment().utc(true).toString(),
};
const BalancedMeals: FC<Props> = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<boolean>(false);
  const [expand, setExpand] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<BlanceMealsRequest>(defaultValue);
  const { enqueueSnackbar } = useSnackbar();
  const onChange = (event) => {
    if (!props.isDone && !props.preview) {
      setChecked(event.target.checked);
      setExpand(event.target.checked);
    }
  };
  const onChangeExpand = (value: boolean) => {
    if (!props.isDone && !props.preview) {
      setChecked(value);
      setExpand(value);
    } else if (props.isDone) {
      setExpand(value);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload: BlanceMealsRequest = {
        ...form,
        submissionDate: props.submissionDate ? props.submissionDate : moment().utc(true).toString(),
      };
      await postbalanceMeals(payload);
      enqueueSnackbar("Add Balance Meals Success", {
        variant: "success",
      });
      if (props.submitSucces) props.submitSucces();
    } catch (error) {
      enqueueSnackbar("Add Balance Meals Failed", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.data) {
      const newForm = {
        achieved: true,
        text: props.data.text,
        balanceBreakfastIsCheck: true,
        balanceBreakfastText: props.data.balanceBreakfast.text,
        balanceLunchIsCheck: true,
        balanceLunchText: props.data.balanceLunch.text,
        balanceDinnerIsCheck: true,
        balanceDinnerText: props.data.balanceDinner.text,
        submissionDate: moment().utc(true).toString(),
      };
      setForm(newForm);
      // if (props.data.achieved)
    }
  }, [props.data]);
  React.useEffect(() => {
    if (props.isDone) setChecked(true);
  }, [props.isDone]);
  React.useEffect(() => {
    if (props.preview) setChecked(true);
  }, [props.preview]);
  return (
    <div className={`${props.weekly ? classes.rootWeekly : classes.root}`}>
      <Accordion
        defaultExpanded={props.defaultExpanded}
        classes={{ root: classes.accordionRoot }}
        expanded={props.preview || expand}
        onChange={(_e, expanded) => onChangeExpand(expanded)}
      >
        <AccordionSummary aria-controls="action-amount-calories-content" id="action-amount-calories-header" classes={{ content: classes.summaryContent }}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            checked={checked}
            onChange={onChange}
            control={<Checkbox name="caloriesAmountNutrition" color="primary" />}
            label={
              <Box display={"flex"} alignItems="center">
                <SpoonForkIcon />
                <Typography
                  variant="mov_body1"
                  style={{
                    color: `${props.preview || props.isDone ? "#9A9A9A" : "#414141"}`,
                  }}
                >
                  I followed the plate method for all three of my meals today
                </Typography>
              </Box>
            }
          />

          {!expand && props.userList !== undefined && props.userList.length > 0 ? (
            <Box mt={1} mb={3}>
              <AvatarGroupComponent userList={props.userList} />
            </Box>
          ) : (
            <></>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box width={"100%"}>
            <Box className={classes.itemDetailBox} marginTop="12px">
              {!props.preview && !props.isDone && (
                <Box width="100%" textAlign="center" mt="20px">
                  <ButtonMui disabled={loading} variant="contained" color="primary" onClick={onSubmit}>
                    {loading ? "Submitting..." : "Submit"}
                  </ButtonMui>
                </Box>
              )}
              {expand && props.userList !== undefined && props.userList.length > 0 ? (
                <Box mt={3}>
                  <AvatarGroupComponent userList={props.userList} />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BalancedMeals;
