import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function HipMeasurementInOutIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 34 32"
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path
                d="M10.0775 1C10.0775 1 9.93343 4.57593 8.3616 8.36144C7.9523 9.33351 7.37498 10.5033 7.05826 12.1211M6.47539 15.4347C5.92525 19.6525 6.18722 25.1408 8.79385 31.5068M26.8569 30.1707C26.6997 30.616 26.5294 31.0614 26.346 31.5068M25.0624 1C25.0624 1 25.2065 4.57593 26.7783 8.36144C27.214 9.42283 27.7355 10.8463 28.1477 12.5832M28.6383 15.2513C29.1492 19.0106 29.0313 23.7786 27.1713 29.3062M7.94244 9.78919H24.2241M25.1541 9.78919H27.3022M18.1594 16.7184C18.1594 16.7184 15.0157 24.2239 15.3039 30.5113M15.8016 18.4343L16.509 21.2898M6.78976 24.2894H25.5863M26.7259 24.2894H28.4025"
                strokeWidth="0.5" strokeMiterlimit="10"/>
            <path
                d="M17.5044 6.00386C17.7142 6.00386 17.8842 5.83379 17.8842 5.624C17.8842 5.41421 17.7142 5.24414 17.5044 5.24414C17.2946 5.24414 17.1245 5.41421 17.1245 5.624C17.1245 5.83379 17.2946 6.00386 17.5044 6.00386Z"
                strokeWidth="0.5"/>
            <path
                d="M3.06959 14.9242H33V13.1035H3.8948C2.29677 13.1035 1 14.4003 1 15.9983V17.0724M3.06959 14.9242C1.92659 14.9242 1 15.8508 1 16.9938C1 18.1368 1.92659 19.0634 3.06959 19.0634C4.21259 19.0634 5.13917 18.1368 5.13917 16.9938C5.13917 15.8508 4.21259 14.9242 3.06959 14.9242ZM31.3627 14.1645V13.1821M29.9349 14.1645V13.1821M28.5072 14.1645V13.1821M27.0794 14.1645V13.1821M25.6517 14.1645V13.1821M24.2239 14.1645V13.1821M22.7962 14.1645V13.1821M21.3684 14.1645V13.1821M19.9275 14.1645V13.1821M18.4998 14.1645V13.1821M17.072 14.1645V13.1821M15.6443 14.1645V13.1821M14.2165 14.1645V13.1821M12.7888 14.1645V13.1821M11.361 14.1645V13.1821M9.93328 14.1645V13.1821M8.50553 14.1645V13.1821M7.07777 14.1645V13.1821M5.65002 14.1645V13.1821M4.20917 14.1645V13.1821M2.78142 14.1645V13.1821M3.67212 16.9938C3.67212 17.3483 3.38477 17.6356 3.03029 17.6356C2.67582 17.6356 2.38846 17.3483 2.38846 16.9938C2.38846 16.6393 2.67582 16.352 3.03029 16.352C3.38477 16.352 3.67212 16.6393 3.67212 16.9938Z"
                strokeWidth="0.5" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
