import PrivateRoute from './PrivateRoute';

export default PrivateRoute;
// import React from 'react';
// import {
//   Route,
//   Redirect,
// } from 'react-router-dom';

// // interface IProps {
// //   children: React.ReactNode,
// // }

// // export default function PrivateRoute({ children, ...rest }: IProps) {
// export default function PrivateRoute({ children, ...rest } {
//   return (
//     // <Route
//     //   {...rest}
//     //   render={({ location }) =>
//     //     fakeAuth.isAuthenticated ? (
//     //       children
//     //     ) : (
//     //         <Redirect
//     //           to={{
//     //             pathname: "/login",
//     //             state: { from: location }
//     //           }}
//     //         />
//     //       )
//     //   }
//     // />
//     <div>Private Route</div>
//   );
// }