import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Videos = lazy(() => import('./Videos'));

export default {
    path: routeConstants.VIDEOS.path,
    component: Videos,
    layout: LayoutOptions.dashboard,
};
