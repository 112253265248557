import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PopUpIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            {...props}
            style={{
                // color: 'white',
                width: props.width || '16px',
                height: props.height || '16px',
            }}
        >
            <g clipPath="url(#clip0_841_25989)">
            <path d="M12.8285 7.07104V2.35699H8.11444" stroke="#6D6D6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.8285 2.357L7.17163 8.01385" stroke="#6D6D6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.75621 4.08958C2.85622 3.98956 2.99188 3.93337 3.13333 3.93337H4.33339C4.70158 3.93337 5.00006 3.63489 5.00006 3.2667C5.00006 2.89851 4.70158 2.60004 4.33339 2.60004H3.13333C2.63826 2.60004 2.16346 2.7967 1.8134 3.14677C1.46333 3.49684 1.26666 3.97163 1.26666 4.4667V12.8667C1.26666 13.3618 1.46333 13.8366 1.8134 14.1866C2.16346 14.5367 2.63826 14.7334 3.13333 14.7334H10.898C11.4902 14.7334 12.0893 14.5814 12.5606 14.2732C13.0313 13.9655 13.4 13.4751 13.4 12.8667V11.6667C13.4 11.2985 13.1015 11.0001 12.7333 11.0001C12.3651 11.0001 12.0667 11.2985 12.0667 11.6667V12.8667C12.0667 12.8949 12.0487 13.0149 11.831 13.1573C11.6139 13.2992 11.2794 13.4 10.898 13.4H3.13333C2.99188 13.4 2.85623 13.3438 2.75621 13.2438C2.65619 13.1438 2.6 13.0082 2.6 12.8667V4.4667C2.6 4.32525 2.65619 4.1896 2.75621 4.08958Z" fill="#6D6D6D"/>
            </g>
            <defs>
            <clipPath id="clip0_841_25989">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>

        </SvgIcon>
    );
}
