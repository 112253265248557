import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '1440px',
      height: '100vh',
      margin: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    errStyle: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '72px',
        lineHeight: '85px',
        textAlign: 'center',
        color: '#FFA21E',
    },
    errStyle2: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '21px',
        lineHeight: '25px',
        textAlign: 'center',
    },
    buttonStyle: {
        width: '263px',
        height: '61px',
        background: '#FFA21E',
        borderRadius: '50px',
        margin: 'auto',
        display: 'flex',
        justifyContent:"center", 
        alignItems:"center",
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '21px',
        lineHeight: '25px',
        textAlign: 'center',
        color: '#ffffff',
        marginTop: '15px',
        cursor: 'pointer'

    }
  }),
);

const NotFound: React.FC<{}> = () => {
    const classes = useStyles()
    const history = useHistory()
    return (
        <div className={classes.root}>
            <Box height='100%' width="100%" display="flex" justifyContent="center" alignItems="center" alignContent='space-between'>
                <Box width="100%" >
                    <div className={classes.errStyle}>Error 404</div>
                    <div className={classes.errStyle2}>The requested URL was not found on this server.</div>
                    <div className={classes.buttonStyle} onClick={() => history.goBack()}>
                        Back to previous page
                    </div>
                </Box>
                <Box width="100%" >
                    <img src="https://api-app.movarahome.com/404_graphic.png" alt=''/>
                </Box>
            </Box>
        </div>
    )
}
export default NotFound