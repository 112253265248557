import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    formContainer: {
        padding: theme.spacing(10),
        paddingTop: '50px'
    },
    fieldContainer: {
        marginTop: '20px',
    },
    label: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: "1.5px",
        textTransform: "uppercase",
        marginBottom: '14px',
    },
    inputCustomWidth: {
        height: '45px',
        padding: '8px 16px !important',
    },
    titleContainer: {
        marginBottom: theme.spacing(10),
    },
    buttonCancel: {
        marginLeft: '16px',
    },
    userId: {
        marginTop: theme.spacing(6),
    },
    popperStyle: {
        width: '300px !important',
        marginTop: '-10px !important',
        '& .MuiAutocomplete-paper': {
            borderRadius: '0px 0px 8px 8px',
            borderTop: '1px solid transparent',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
            backgroundColor: '#f9f9f9',
            padding:'0px 10px',
            '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option': {
                    paddingLeft: '5px'
                },
                '& .MuiAutocomplete-option[data-focus="true"]': {
                    backgroundColor: '#FFEACC',
                    borderRadius: '7px',
                }
            }
        }
    },
}));