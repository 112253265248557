import React, { FC, useEffect, useState } from "react";
import { Dialog, DialogContent, makeStyles, Theme, createStyles } from "@material-ui/core";
import Weekly from "components/_modules/WeeklyAssessment/WeeklyAdmin";
interface Props {
  show: boolean;
  data: any;
  onClose: (putRequest?: boolean) => void;
  startDate: any;
  endDate: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#FFFFFF",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
      borderRadius: "20px",
    },
    contentRoot: {
      padding: "0px !important",
    },
  }),
);

const UserWeeklyModal: FC<Props> = ({ show, data, onClose, startDate, endDate }) => {
  // eslint-disable-next-line
  const [notes, setNotes] = useState<string>("");
  const classes = useStyles();
  useEffect(() => {
    if (show) {
      setNotes(data.notes);
    }
  }, [show, data]);

  return (
    <Dialog fullWidth maxWidth={"sm"} open={show} onClose={() => onClose()} classes={{ paper: classes.root }}>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <Weekly onClose={onClose} isDone={true} data={data} startDate={startDate} endDate={endDate} />
      </DialogContent>
    </Dialog>
  );
};
export default UserWeeklyModal;
