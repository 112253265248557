import React from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
    Paper,
    Box,
    Typography,
    InputLabel,
    MenuItem,
    Button,
} from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import TotalWeekIndicator from 'components/_modules/booking/TotalWeekIndicator';
import DateRangePicker from 'components/controls/DateRangePicker';
import AddIcon from 'components/icons/AddIcon';

import { useBookingInfoStyles as useStyles } from './styles';

type DateRange = {
    startDate: Date;
    endDate: Date;
};

type UserBooking = {
    id?: string;
    dateRange: DateRange;
    paymentStatus: string;
};

interface BookingInfoProps {
    bookingData: UserBooking[];
    setFieldValue?: (field: string, value: any) => void;
    minItemCount?: number;
    maxItemCount?: number;
}

const bookingDefault = {
    dateRange: {
        startDate: new Date(),
        endDate: new Date(),
    },
    paymentStatus: '',
};

const BookingInfo = ({
    bookingData,
    setFieldValue,
    minItemCount,
    maxItemCount,
}: BookingInfoProps) => {
    const classes = useStyles();

    const addNewBooking = () => {
        if (setFieldValue) {
            setFieldValue('bookingData', [
                ...bookingData,
                { ...bookingDefault },
            ]);
        }
    };

    const removeBookingItem = (itemIndex: number) => {
        if (setFieldValue) {
            setFieldValue(
                'bookingData',
                bookingData.filter((item, idx) => idx !== itemIndex)
            );
        }
    };

    const dateRangeChangeHandle = (val: any, index: number) => {
        if (setFieldValue) {
            setFieldValue(`bookingData.${index}.dateRange`, val);
        }
    };

    const onWeekItemClick = (weekNumber: number, bookingInfoIndex: number) => {
        const nextMondayDayCount = 7 - moment().isoWeekday();
        const startDate = moment().add(nextMondayDayCount, 'days');
        const endDate = moment(startDate).add(weekNumber, 'weeks');
        const metric = weekNumber === 1 ? 'week' : 'weeks';

        Swal.fire({
            confirmButtonText: 'Yes',
            showCancelButton: true,
            html: `<div style="font-family: Montserrat; font-size: 16px;">This action will update booking's date range to <br/><b>${
                startDate.format('dddd, DD-MMM-YYYY') +
                '</b> until <b>' +
                endDate.format('dddd, DD-MMM-YYYY')
            }</b>,<br/>with total <b>${weekNumber}</b> ${metric},<br/>are you sure ?</div>`,
            confirmButtonColor: '#FFA21E',
        }).then((result) => {
            if (result.isConfirmed && setFieldValue) {
                setFieldValue(`bookingData.${bookingInfoIndex}.dateRange`, {
                    startDate,
                    endDate,
                });
            }
        });
    };

    return (
        <Paper className={classes.bookingDetailWrapper}>
            {bookingData.map((booking, index) => (
                <React.Fragment key={index}>
                    <Box mb={2}>
                        <InputLabel>Booking ID</InputLabel>
                        <Typography>{booking.id || ''}</Typography>
                    </Box>

                    <InputLabel>Options</InputLabel>
                    <TotalWeekIndicator
                        startDate={booking.dateRange.startDate}
                        endDate={booking.dateRange.endDate}
                        showRecurringOption={false}
                        recurring={false}
                        onItemClick={(weekNumber) =>
                            onWeekItemClick(weekNumber, index)
                        }
                    />

                    <DateRangePicker
                        value={booking.dateRange}
                        onChange={(val) => {
                            dateRangeChangeHandle(val, index);
                        }}
                        label="Duration Date"
                        margin="normal"
                        definedRanges={[]}
                        closeOnSelection
                    />
                    <Field
                        component={TextField}
                        name={`bookingData.${index}.paymentStatus`}
                        inputProps={{
                            id: `bookingData.${index}.paymentStatus`,
                        }}
                        label="Payment Status (*)"
                        select
                        margin="normal"
                        fullWidth
                    >
                        <MenuItem value="paid">Paid</MenuItem>
                        <MenuItem value="unpaid">Unpaid</MenuItem>
                        <MenuItem value="comp">Comp</MenuItem>
                        <MenuItem value="movara_challenge">
                            Movara Challenge
                        </MenuItem>
                    </Field>

                    <Box display="flex" justifyContent="flex-end">
                        {(minItemCount === undefined ||
                            (minItemCount !== undefined &&
                                minItemCount < bookingData.length)) && (
                            <Button
                                className={classes.removeButton}
                                onClick={() => {
                                    removeBookingItem(index);
                                }}
                            >
                                Remove Booking Item
                            </Button>
                        )}
                    </Box>
                </React.Fragment>
            ))}

            <Box mt={4}>
                {(maxItemCount === undefined ||
                    (maxItemCount !== undefined &&
                        maxItemCount > bookingData.length)) && (
                    <Button color="primary" onClick={addNewBooking}>
                        ADD BOOKING
                        <AddIcon color="primary" className={classes.addIcon} />
                    </Button>
                )}
            </Box>
        </Paper>
    );
};

BookingInfo.defaultProps = {
    bookingData: [{ ...bookingDefault }],
};

export default BookingInfo;
