import React from "react";

import ButtonIcon from "components/button/ButtonIcon";
import { PencilIcon, CalendarDotIcon } from "components/_newIcon";
import { Box, Tooltip } from "@material-ui/core";
import auth from "services/domain/auth";

const ActionCell = ({
  actionEdit,
  actionPause,
  actionReschedule,
  row,
}: {
  actionEdit: (row: any) => void;
  actionPause: (row: any) => void;
  actionReschedule: (row: any) => void;
  row: any;
}) => {
  const renderButton = (booking) => {
    if (booking.pauseStatus) {
      switch (booking.pauseStatus) {
        case "Need Approval":
          return (
            <Tooltip title="Request Pause - Need response">
              <ButtonIcon
                onClick={() => actionPause(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#EF5136",
                  border: "1px solid #E6E6E6",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#FFFFFF" />}
              />
            </Tooltip>
          );
        case "Approved":
          return (
            <Tooltip title="Request Pause - Request is approved">
              <ButtonIcon
                onClick={() => actionPause(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#C4CE7D",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#FFFFFF" />}
              />
            </Tooltip>
          );
        case "Cancelled":
          return (
            <Tooltip title="Request Pause - Request is cancelled">
              <ButtonIcon
                // onClick={() => actionPause(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#F2F2F2",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#CDCDCD" />}
              />
            </Tooltip>
          );
        case "Decline":
          return (
            <Tooltip title="Request Pause - Request is declined">
              <ButtonIcon
                onClick={() => actionPause(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#FFD557",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#515151" />}
              />
            </Tooltip>
          );
        default:
          return null;
      }
    } else if (booking.rescheduleStatus) {
      switch (booking.rescheduleStatus) {
        case "Need Approval":
          return (
            <Tooltip title="Request Reschedule - Need response">
              <ButtonIcon
                onClick={() => actionReschedule(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#EF5136",
                  border: "1px solid #E6E6E6",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#FFFFFF" />}
              />
            </Tooltip>
          );
        case "Approved":
          return (
            <Tooltip title="Request Reschedule - Request is approved">
              <ButtonIcon
                onClick={() => actionReschedule(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#C4CE7D",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#FFFFFF" />}
              />
            </Tooltip>
          );
        case "Cancelled":
          return (
            <Tooltip title="Request Reschedule - Request is cancelled">
              <ButtonIcon
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#F2F2F2",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#CDCDCD" />}
              />
            </Tooltip>
          );
        case "Decline":
          return (
            <Tooltip title="Request Reschedule - Request is declined">
              <ButtonIcon
                onClick={() => actionReschedule(row)}
                style={{
                  borderRadius: "10px",
                  padding: "10px 14px",
                  backgroundColor: "#FFD557",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                }}
                variant="outlined"
                startIcon={<CalendarDotIcon fill="#515151" />}
              />
            </Tooltip>
          );
        default:
          return null;
      }
    } else {
      return (
        <Tooltip title="There is No request">
          <ButtonIcon
            style={{
              borderRadius: "10px",
              padding: "10px 14px",
              backgroundColor: "#F2F2F2",
            }}
            variant="outlined"
            startIcon={<CalendarDotIcon fill="#CDCDCD" />}
          />
        </Tooltip>
      );
    }
  };
  return (
    <Box textAlign={"center"}>
      {(auth?.userProperties?.userData?.role === "admin" || auth?.userProperties?.userData?.role === "coach") && (
        <ButtonIcon variant="text" onClick={() => actionEdit(row)} startIcon={<PencilIcon />} />
      )}
      {renderButton(row.original)}
    </Box>
  );
};

export default ActionCell;
