import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function CookIcon(props: SvgIconProps & Status) {
    const { isActive } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();
    return (
        <SvgIcon  {...props}
                  className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active}`}>
            <path
                d="M15.6086 7.83164C15.6086 9.13539 12.8833 10.1923 9.52048 10.1923C6.1583 10.1923 3.43298 9.13539 3.43298 7.83164C3.43298 6.52789 6.15861 5.4707 9.52048 5.4707C12.883 5.4707 15.6086 6.52789 15.6086 7.83164Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round" />
            <path
                d="M15.6086 7.83203V12.2564C15.6086 13.5602 12.8833 14.617 9.52048 14.617C6.1583 14.617 3.43298 13.5602 3.43298 12.2564V7.83203C3.43298 9.13578 6.15861 10.1927 9.52048 10.1927C12.883 10.1923 15.6086 9.13547 15.6086 7.83203Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M5.02566 6.01633L3.43347 4.42383" strokeWidth="0.5" strokeMiterlimit="10"
                  strokeLinejoin="round" />
            <path d="M1.20479 1.38237L0.391846 2.19531L2.98425 4.78772L3.79719 3.97477L1.20479 1.38237Z"
                  strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round" />
        </SvgIcon>
    );
}
