import moment from 'moment';

export const getThisWeekRange = () => {
    const startDate = moment().startOf('week').toDate();
    const endDate = moment().endOf('week').toDate();
    return { startDate, endDate };
};

export const checkTodayIsSunday = (date: string) => {
    return moment(date).weekday() === 0;
};