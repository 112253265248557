import React, { useState, createContext } from 'react';

export type UserContextType = {
    user: any;
    setUser: (user: any) => void;
};

export const UserContext = createContext<UserContextType | null>(null);

type Props = {
    children: JSX.Element;
};

export default function Store({ children }: Props) {
    const [user, setUser] = useState<any>({});

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}
