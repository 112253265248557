import React from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { Button, Box, Grid, Typography, MenuItem, FormControl, Select, TextField } from "@material-ui/core";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import Auth from "services/domain/auth";
import history from "services/data/history";
import { ProfileUpdateRequest } from "interfaces/user";
import { updateUserAbout } from "services/domain/users";
import { Toast } from "services/util/toast";
import CustomInput from "components/controls/CustomInput/CustomInput";
import MtextArea from "components/controls/CustomInput/MtextArea";
import ShowHideSwitch from "components/switch/ShowHideSwicth";
import YesNoSwicth from "components/switch/YesNoSwicth";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { PopUpIcon } from "components/_newIcon";
import PreviewModal from "../components/previewModal";

const useStyles = makeStyles((theme: Theme) => ({
  inputCustomWidth: {
    height: "45px",
  },
  buttonCancel: {
    marginLeft: "20px",
  },
  formSwitchContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  zoomFormSwitchContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  switchTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  zoomSwitchTitleContainer: {
    textAlign: "start",
    marginTop: "10px",
  },
  previewSwitchTitleContainer: {
    alignItems: "center",
    marginTop: "10px",
    textAlign: "start",
  },
  label: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.05em",
    color: "#000000",
  },
  optional: {
    color: "#9A9A9A",
    fontSize: "12px",
    fontWeight: "normal",
  },
  boxOne: {
    backgroundColor: "#FAFAFA",
    padding: "23px",
    marginBottom: "5vh",
  },
  boxTwo: {
    backgroundColor: "#FAFAFA",
    padding: "23px",
  },
  boxThree: {
    backgroundColor: "#FAFAFA",
    padding: "23px",
  },
  zoomLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    color: "#000000",
    textAlign: "start",
    marginBottom: "1vh",
  },
  zoomSubLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.25px",
    color: "#000000",
    textAlign: "start",
  },
  popUpContainer: {
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "13vh",
  },
  zoomContainer: {
    display: "-webkit-inline-box",
  },
  timeZoneLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.05em",
    color: "#000000",
    marginTop: "5px",
    width: "31vh",
  },
  dateSelect: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "15px",
    },
  },
  dateSelectChoice: {
    fontWeight: 600,
  },
  previewLabel: {
    width: "100%",
  },
}));

const CustomToggle = withStyles({
  root: {
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "46px",
    background: "#F8F8F8",
    boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.2) !important",
    borderRadius: "7px",
  },
  label: {
    textTransform: "lowercase",
  },
  selected: {
    background: "linear-gradient(0deg, #FFA21E, #FFA21E), #FFFFFF !important",
    backgroundBlendMode: "multiply, normal !important",
    boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.2) !important",
    color: "white !important",
  },
})(ToggleButton);

const validationSchema = Yup.object({
  displayName: Yup.string().required("Display Name is required"),
  bio: Yup.string().required("Bio is required"),
  occupation: Yup.string(),
  age: Yup.number().typeError("You must specify a number"),
  location: Yup.string(),
  beenGuestCheck: Yup.boolean(),
  beenGuestDesc: Yup.string(),
  isShowAbout: Yup.boolean(),
  weightGoal: Yup.number().typeError("You must specify a number"),
});

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const ProfileForm: React.FC<{ profile: any; fetchProfile: () => void }> = (props) => {
  const classes = useStyles();
  const [weightGoalType, SetWeightGoalType] = React.useState<string>("lbs");
  const [profile, setProfile] = React.useState({
    displayName: props.profile?.displayName ?? "",
    bio: props.profile?.about?.bio ?? "",
    occupation: props.profile?.about?.occupation?.occupation ?? "",
    age: props.profile?.about?.age?.age ?? 0,
    location: props.profile?.about?.location?.location ?? "",
    beenGuestCheck: props.profile?.about?.beenGuest?.isCheck ?? false,
    beenGuestDesc: props.profile?.about?.beenGuest?.desc ?? "",
    isShowAbout: props.profile?.about?.isShowAbout ?? false,
    isOccupationShow: props.profile?.about?.occupation?.isOccupationShow ?? false,
    isAgeShow: props.profile?.about?.age?.isAgeShow ?? false,
    isLocationShow: props.profile?.about?.location?.isLocationShow ?? false,
    weightGoal: props.profile?.about?.weightGoal?.data ?? 0,
    role: props.profile?.role ?? "",
    zoomLink: props.profile?.about?.zoomLink ?? "",
    zoomLinkMidWeek: props.profile?.about?.zoomLinkMidWeek ?? "",
    avatar: props.profile?.avatar?.path ?? "",
  });
  const [zoomCallDay1, setZoomCallDay1] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall1?.zoomCallDay || "Sun");
  const [zoomCallDay2, setZoomCallDay2] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall2?.zoomCallDay || "Sun");
  const [zoomCallDay3, setZoomCallDay3] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall3?.zoomCallDay || "Sun");
  const [zoomCallTime1, setZoomCallTime1] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall1?.zoomCallTime || "17:30");
  const [zoomCallTime2, setZoomCallTime2] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall2?.zoomCallTime || "17:30");
  const [zoomCallTime3, setZoomCallTime3] = React.useState<string>(props.profile?.about?.zoomCall?.zoomCall3?.zoomCallTime || "17:30");
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setProfile({
      displayName: props.profile?.displayName ?? "",
      bio: props.profile?.about?.bio ?? "",
      occupation: props.profile?.about?.occupation?.occupation ?? "",
      age: props.profile?.about?.age?.age ?? 0,
      location: props.profile?.about?.location?.location ?? "",
      beenGuestCheck: props.profile?.about?.beenGuest?.isCheck ?? false,
      beenGuestDesc: props.profile?.about?.beenGuest?.desc ?? "",
      isShowAbout: props.profile?.about?.isShowAbout ?? false,
      isOccupationShow: props.profile?.about?.occupation?.isOccupationShow ?? false,
      isAgeShow: props.profile?.about?.age?.isAgeShow ?? false,
      isLocationShow: props.profile?.about?.location?.isLocationShow ?? false,
      weightGoal: props.profile?.about?.weightGoal?.data ?? 0,
      role: props.profile?.role ?? "",
      zoomLink: props.profile?.about?.zoomLink ?? "",
      zoomLinkMidWeek: props.profile?.about?.zoomLinkMidWeek ?? "",
      avatar: props.profile?.avatar?.path ?? "",
    });
    SetWeightGoalType(props.profile?.about?.weightGoal?.typeData || "lbs");
    setZoomCallDay1(props.profile?.about?.zoomCall?.zoomCall1?.zoomCallDay || "Sun");
    setZoomCallDay2(props.profile?.about?.zoomCall?.zoomCall2?.zoomCallDay || "Sun");
    setZoomCallDay3(props.profile?.about?.zoomCall?.zoomCall3?.zoomCallDay || "Sun");
    setZoomCallTime1(props.profile?.about?.zoomCall?.zoomCall1?.zoomCallTime || "17:30");
    setZoomCallTime2(props.profile?.about?.zoomCall?.zoomCall2?.zoomCallTime || "17:30");
    setZoomCallTime3(props.profile?.about?.zoomCall?.zoomCall3?.zoomCallTime || "17:30");
  }, [props.profile]);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: ProfileUpdateRequest, { setSubmitting }: FormikHelpers<ProfileUpdateRequest>) => {
    setSubmitting(true);
    try {
      values.weightGoalType = weightGoalType;
      values.zoomCallTime1 = zoomCallTime1;
      values.zoomCallDay1 = zoomCallDay1;
      values.zoomCallTime2 = zoomCallTime2;
      values.zoomCallDay2 = zoomCallDay2;
      values.zoomCallTime3 = zoomCallTime3;
      values.zoomCallDay3 = zoomCallDay3;
      const toast = Toast({ onClose: goBack });
      await updateUserAbout(values);

      Auth.updateUserProperties("displayName", values.displayName);

      toast.fire({
        icon: "success",
        title: "Your profile has been updated successfully",
      });
      props.fetchProfile();
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Update profile failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const goalWeightTypeOnChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    SetWeightGoalType(value);
  };

  return (
    <Formik enableReinitialize initialValues={profile} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, values, getFieldProps, getFieldMeta, submitCount }) => (
        <Form noValidate>
          {/* NEW VIEW FOR EDIT PROFILE IF COACH */}
          {(values.role === "coach" || values.role === "presenter") && [
            <Box pt={7} width="100%" textAlign="center" className={classes.boxOne}>
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={12} className={classes.switchTitleContainer}>
                  <Typography className={classes.label}>
                    DISPLAY NAME <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
              </Grid>

              <Field
                as={CustomInput}
                placeholder="Display Name"
                id="displayName"
                autoFocus
                className={classes.inputCustomWidth}
                {...getFieldProps("displayName")}
                meta={getFieldMeta("displayName")}
                isSubmitted={submitCount > 0}
              />
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={12} className={classes.previewSwitchTitleContainer}>
                  <Typography className={classes.label}>
                    SHORT BIO <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Field
                  as={MtextArea}
                  placeholder="Short Bio"
                  id="bio"
                  rows={3}
                  className={classes.inputCustomWidth}
                  {...getFieldProps("bio")}
                  meta={getFieldMeta("bio")}
                  isSubmitted={submitCount > 0}
                />
              </Grid>
              {values.role === "coach" && (
                <Grid container className={classes.formSwitchContainer}>
                  <Grid item md={12} className={classes.zoomSwitchTitleContainer}>
                    <Typography className={classes.previewLabel}>The description written here will also show up on your Coach pop up page.</Typography>
                  </Grid>
                  <Grid item md={12} className={classes.zoomSwitchTitleContainer}>
                    <Button
                      variant="contained"
                      style={{
                        margin: "auto",
                        boxShadow: "none",
                        border: "1px solid #E6E6E6",
                        borderRadius: "10px",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <PopUpIcon /> &nbsp;<u>See how it’ll look</u>
                    </Button>
                    <PreviewModal
                      data={profile}
                      open={showModal}
                      onClose={() => setShowModal(false)}
                      onSelect={(data) => {
                        setShowModal(false);
                        // setSelectedId(data?._id);
                        // props.selectCoach(data);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>,
            <Box pt={7} width="100%" textAlign="center" className={classes.boxOne}>
              <div className={classes.zoomLabel}>OPTIONAL</div>
              <div className={classes.zoomSubLabel}>You may choose to show or hide the following information. </div>
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>Occupation</div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isOccupationShow" checked={values.isOccupationShow} {...getFieldProps("isOccupationShow")} />
                </Grid>
              </Grid>
              <Field
                as={CustomInput}
                placeholder="A Job"
                id="occupation"
                className={classes.inputCustomWidth}
                {...getFieldProps("occupation")}
                meta={getFieldMeta("occupation")}
                isSubmitted={submitCount > 0}
              />
              <Grid container className={classes.formSwitchContainer} justify="space-between">
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>Age</div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isAgeShow" checked={values.isAgeShow} {...getFieldProps("isAgeShow")} />
                </Grid>
              </Grid>
              <Field as={CustomInput} placeholder="Age" id="age" fullWidth={false} {...getFieldProps("age")} meta={getFieldMeta("age")} isSubmitted={submitCount > 0} />
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>Location</div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isLocationShow" checked={values.isLocationShow} {...getFieldProps("isLocationShow")} />
                </Grid>
                <Field
                  as={MtextArea}
                  placeholder="A Place"
                  id="location"
                  className={classes.inputCustomWidth}
                  {...getFieldProps("location")}
                  meta={getFieldMeta("location")}
                  isSubmitted={submitCount > 0}
                />
              </Grid>
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>Have you been guest to Movara, if so, when ?</Typography>
                </Grid>
                <Grid item md={2}>
                  <Field component={YesNoSwicth} color="primary" id="beenGuestCheck" checked={values.beenGuestCheck} {...getFieldProps("beenGuestCheck")} />
                </Grid>
              </Grid>
              <Field
                as={CustomInput}
                placeholder="Summer after highschool"
                className={classes.inputCustomWidth}
                {...getFieldProps("beenGuestDesc")}
                meta={getFieldMeta("beenGuestDesc")}
                isSubmitted={submitCount > 0}
              />
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>Would you like to show your progress on your about page?</Typography>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isShowAbout" checked={values.isShowAbout} {...getFieldProps("isShowAbout")} />
                </Grid>
              </Grid>
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>What is your long-term healthy weight goal?</Typography>
                </Grid>
                <Grid item md={2}>
                  <ToggleButtonGroup value={weightGoalType} exclusive onChange={goalWeightTypeOnChange} aria-label="Weight" style={{ marginLeft: 23 }}>
                    <CustomToggle value="kg" aria-label="kg">
                      kg
                    </CustomToggle>
                    <CustomToggle value="lbs" aria-label="lbs">
                      lbs
                    </CustomToggle>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Field
                as={CustomInput}
                placeholder="Weight Goal"
                id="weightGoal"
                fullWidth={false}
                {...getFieldProps("weightGoal")}
                meta={getFieldMeta("weightGoal")}
                isSubmitted={submitCount > 0}
              />
            </Box>,
            <Box pt={7} width="100%" textAlign="center">
              <Button color="primary" type="submit" variant="contained" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>,
          ]}
          {/* OLD VIEW FOR EDIT PROFILE FOR NON COACH */}
          {values.role !== "coach" &&
            values.role !== "presenter" && [
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={12} className={classes.switchTitleContainer}>
                  <Typography className={classes.label}>Display Name *</Typography>
                </Grid>
              </Grid>,

              <Field
                as={CustomInput}
                placeholder="Display Name"
                id="displayName"
                autoFocus
                className={classes.inputCustomWidth}
                {...getFieldProps("displayName")}
                meta={getFieldMeta("displayName")}
                isSubmitted={submitCount > 0}
              />,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={12} className={classes.switchTitleContainer}>
                  <Typography className={classes.label}>Why Are you Here? *</Typography>
                </Grid>
              </Grid>,
              <Field
                as={MtextArea}
                placeholder="Why Are you Here?"
                id="bio"
                rows={3}
                className={classes.inputCustomWidth}
                {...getFieldProps("bio")}
                meta={getFieldMeta("bio")}
                isSubmitted={submitCount > 0}
              />,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>
                    Occupation <b className={classes.optional}>(Optional)</b>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isOccupationShow" checked={values.isOccupationShow} {...getFieldProps("isOccupationShow")} />
                </Grid>
              </Grid>,
              <Field
                as={CustomInput}
                placeholder="A Job"
                id="occupation"
                className={classes.inputCustomWidth}
                {...getFieldProps("occupation")}
                meta={getFieldMeta("occupation")}
                isSubmitted={submitCount > 0}
              />,
              <Grid container className={classes.formSwitchContainer} justify="space-between">
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>
                    Age <b className={classes.optional}>(Optional)</b>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isAgeShow" checked={values.isAgeShow} {...getFieldProps("isAgeShow")} />
                </Grid>
              </Grid>,
              <Field as={CustomInput} placeholder="Age" id="age" fullWidth={false} {...getFieldProps("age")} meta={getFieldMeta("age")} isSubmitted={submitCount > 0} />,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <div className={classes.label}>
                    Location <b className={classes.optional}>(Optional)</b>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isLocationShow" checked={values.isLocationShow} {...getFieldProps("isLocationShow")} />
                </Grid>
              </Grid>,
              <Field
                as={MtextArea}
                placeholder="A Place"
                id="location"
                className={classes.inputCustomWidth}
                {...getFieldProps("location")}
                meta={getFieldMeta("location")}
                isSubmitted={submitCount > 0}
              />,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>Have you been guest to Movara, if so, when ?</Typography>
                </Grid>
                <Grid item md={2}>
                  <Field component={YesNoSwicth} color="primary" id="beenGuestCheck" checked={values.beenGuestCheck} {...getFieldProps("beenGuestCheck")} />
                </Grid>
              </Grid>,
              <Field
                as={CustomInput}
                placeholder="Summer after highschool"
                className={classes.inputCustomWidth}
                {...getFieldProps("beenGuestDesc")}
                meta={getFieldMeta("beenGuestDesc")}
                isSubmitted={submitCount > 0}
              />,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>Would you like to show your progress on your about page?</Typography>
                </Grid>
                <Grid item md={2}>
                  <Field component={ShowHideSwitch} color="primary" id="isShowAbout" checked={values.isShowAbout} {...getFieldProps("isShowAbout")} />
                </Grid>
              </Grid>,
              <Grid container className={classes.formSwitchContainer}>
                <Grid item md={10} className={classes.switchTitleContainer}>
                  <Typography>What is your long-term healthy weight goal?</Typography>
                </Grid>
                <Grid item md={2}>
                  <ToggleButtonGroup value={weightGoalType} exclusive onChange={goalWeightTypeOnChange} aria-label="Weight" style={{ marginLeft: 23 }}>
                    <CustomToggle value="kg" aria-label="kg">
                      kg
                    </CustomToggle>
                    <CustomToggle value="lbs" aria-label="lbs">
                      lbs
                    </CustomToggle>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>,
              <Field
                as={CustomInput}
                placeholder="Weight Goal"
                id="weightGoal"
                fullWidth={false}
                {...getFieldProps("weightGoal")}
                meta={getFieldMeta("weightGoal")}
                isSubmitted={submitCount > 0}
              />,
              <Box pt={7} width="100%" textAlign="center">
                <Button color="primary" type="submit" variant="contained" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Box>,
            ]}
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
