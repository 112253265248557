import React, { FC, ReactNode } from 'react';
import {
    Box,
    createStyles,
    // Grid,
    // IconButton,
    makeStyles,
    Modal,
    Theme,
} from '@material-ui/core';
import {  AlertCircleIcon } from 'components/_newIcon';
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Typography from 'components/_newDesign/typography/Typography';
import ButtonMui from 'components/_newDesign/button/Button'
interface Props {
    show: boolean;
    title?: string;
    content?: string | ReactNode
    image?: string | ReactNode
    onClose: () => void;
    onOk?: () => void;
    type:
        | 'warning'
        | 'confirmation'
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: 'white',
            boxShadow: '0px 15px 60px rgba(0, 0, 0, 0.15)',
            padding: theme.spacing(2, 0, 2),
            width: '623px',
            maxWidth: '623px',
            maxHeight: '95%',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
            },
            borderRadius: '20px',
        },
        header: {
            // width: '805px',
            // maxWidth: '805px',
        },
        'header-item': {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '36px',
            lineHeight: '100%',
            color: '#000000',
        },
        content: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#000000',
        },
        'upload-text': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '150%',
            /* identical to box height, or 36px */

            letterSpacing: ' 0.15px',

            color: '#000000',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
        },
        modalContent: {
            padding: '0px 24px 16px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 16px 0px',
            },
        }
    })
);

const ModalGeneral: FC<Props> = ({ show, onClose, title, type, onOk, content, image }) => {
    const classes = useStyles();
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        onClose();
    };
    // const handleOk = () => {
    //     onOk && onOk();
    // };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            style={{ maxHeight: '100vh' }}
            className={classes.modal}
            BackdropProps={{ style: {backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(10px)'} }}
        >
            <div className={classes.paper}>

                <Box padding={"24px 30px 0px 30px"} textAlign="center">
                    {image ? image : <AlertCircleIcon />}
                </Box>
                <Box width={'100%'} className={classes.modalContent} mt="32px">
                    {content}
                </Box>
                <hr
                    style={{
                        backgroundColor: '#E6E6E6',
                        height: '1px',
                        border: 'none',
                    }}
                />
                <div style={{ padding: '20px 20px', textAlign: 'center' }}>

                    <ButtonMui
                        variant="contained"
                        color="primary"
                        className={classes['upload-button']}
                        onClick={handleClose}
                    >
                        OK
                    </ButtonMui>
                </div>
            </div>
        </Modal>
    );
};
export default ModalGeneral;
