import React from 'react';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { Container, Paper, Box, Typography, Button, CircularProgress, Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { SignUpLayout } from 'components/layout/containers';
import { useStyles } from '../ForgotPassword/styles';
import { Toast } from 'services/util/toast';

import movaraLogo from 'assets/images/movara-home-logo.png';

import { resetPassword } from 'services/domain/users';
import CustomInputPaymentPassword from 'components/controls/CustomInput/CustomInputPaymentPassword';

type ResetInterface =  {
    newPass: string;
    newPassConfirm: string;
}

const initialValues = {
    newPass: '',
    newPassConfirm: ''
};

const validationSchema = Yup.object({
    newPass: Yup.string().required('New Password is required').matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,"Must Contain 8 Characters and number"),
    newPassConfirm: Yup.string().required('Confirmation Password is required').oneOf([Yup.ref('newPass')], 'Password must match'),
});

const ResetPassword = () => {
    const classes = useStyles();
    const [isResetSuccess, setResetStatus] = React.useState(false);
    const { token } = useParams<{ token: string }>();
    const { email } = useParams<{ email: string }>();
    const history = useHistory();

    if (!token) {
        history.push('/signin');
        return null;
    }

    const onSubmit = async (values: ResetInterface) => {
        // setSubmitting(true);
        try {
            await resetPassword({
                resetLink: token,
                newPass: values.newPass
            });
            setResetStatus(true);
            // resetForm();
        } catch (e) {
            const toast = Toast({});
            if ('messages' in e) {
                let message = 'Unknown error';

                if (e.messages instanceof Array) {
                    if (typeof e.messages[0] === 'string') {
                        message = e.messages[0];
                    } else {
                        message = e.messages[0]?.error ?? message;
                    }
                };
                
                if (typeof e.messages === 'string') {
                    message = e.messages;
                }

                if (message === 'The link used to reset the password has expired') {
                    history.push('/token-expired/'+email);
                } else {
                    toast.fire({
                        icon: 'error',
                        title: message,
                    });   
                }
            }
        } finally {
            // setSubmitting(false);
        }
    }

    return (
        <SignUpLayout>
            <Container maxWidth="md" className={classes["forgot-password__container"]} >
                <Paper>
                    <Box maxWidth={460} marginLeft="auto" marginRight="auto" pt={15} pb={15}>
                        <Box mb={8} display="flex" justifyContent="center">
                            <img src={movaraLogo} alt="movara's logo" />
                        </Box>

                        <Box mb={8}>
                            <Typography align="center" variant="subtitle1">
                                {isResetSuccess ? 'Forgot Password' : 'Enter New Password'}
                            </Typography>
                        </Box>

                        {
                            !isResetSuccess
                            && (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                validateOnBlur={true}
                            >
                                {({ isSubmitting, getFieldProps, getFieldMeta, submitCount }) => (
                                    <Form noValidate>
                                        <Grid container style={{padding: '20px'}}>
                                            <Grid item md={12} xs={12} sm={12} xl={12}>
                                                <Typography>Password <span style={{ color: '#97412A' }}>*</span> </Typography>
                                            </Grid>
                                            <Grid item md={12} xs={12} sm={12} xl={12}>
                                                <Field
                                                    as={CustomInputPaymentPassword}
                                                    type="password"
                                                    placeholder="Type in here"
                                                    id="newPass"
                                                    // autoFocus
                                                    // className={classes.inputCustomWidth}
                                                    {...getFieldProps('newPass')}
                                                    meta={getFieldMeta('newPass')}
                                                    isSubmitted={submitCount > 0} />
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{padding: '20px'}}>
                                            <Grid item md={12} xs={12} sm={12} xl={12}>
                                                <Typography>Confirm Password <span style={{ color: '#97412A' }}>*</span> </Typography>
                                            </Grid>
                                            <Grid item md={12} xs={12} sm={12} xl={12}>
                                                <Field
                                                    as={CustomInputPaymentPassword}
                                                    placeholder="Type in here"
                                                    id="newPassConfirm"
                                                    type="password"
                                                    // autoFocus
                                                    // className={classes.inputCustomWidth}
                                                    {...getFieldProps('newPassConfirm')}
                                                    meta={getFieldMeta('newPassConfirm')}
                                                     />
                                            </Grid>
                                        </Grid>

                                        <Box display="flex" justifyContent="center" mt={14}>
                                            <div className={classes.buttonWrapper}>
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    Update Password
                                            </Button>
                                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                            )
                        }

                        {
                            isResetSuccess
                            && (
                            <>
                                <Typography>
                                    Your password has been changed.
                                </Typography>

                                <Box display="flex" justifyContent="center" mt={14}>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            className={classes.button}
                                            component={RouterLink}
                                            to="/signin"
                                        >
                                            Back To Login Page
                                        </Button>
                                    </div>
                                </Box>
                            </>
                            )
                        }
                    </Box>
                </Paper>
            </Container>
        </SignUpLayout>
    );
};

export default ResetPassword;
