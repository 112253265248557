import { lazy } from 'react';
import routeConstants from '../_constant/routes';

const Account = lazy(() => import('../Account/Account'));
const AccountPassword = lazy(() => import('../Password/AccountPassword'));
const AccountPrivacy = lazy(() => import('../Privacy/AccountPrivacy'));
// const AccountBooking = lazy(() => import('../Booking/AccountBooking'));

export const routes = [
    {
        path: routeConstants.ACCOUNT_SETTING.path,
        component: Account,
    },
    {
        path: routeConstants.ACCOUNT_PASSWORD.path,
        component: AccountPassword,
    },
    {
        path: routeConstants.ACCOUNT_PRIVACY.path,
        component: AccountPrivacy,
    },
    // {
    //     path: routeConstants.ACCOUNT_BOOKING.path,
    //     component: AccountBooking,
    // },
];
