import { SpoonForkIcon } from '../components/icons/SpoonForkIcon';
import React from 'react';
import { WeightInIcon } from '../components/icons/WeightInIcon';
import { WeightGoalIcon } from '../components/icons/WeightGoalIcon';
import { WaistMeasurementInOutIcon } from '../components/icons/WaistMeasurementInOutIcon';
import { ChestMeasurementInOutIcon } from '../components/icons/ChestMeasurementInOutIcon';
import { HipMeasurementInOutIcon } from '../components/icons/HipMeasurementInOutIcon';
import { BodyFatInOutGoalIcon } from '../components/icons/BodyFatInOutGoalIcon';
import { StretchedIcon, BurbleIcon, PersonalIcon } from '../components/_newIcon';

export const guestQuestionsTemplate = [
    {
        type: 'dailyStretch',
        icon: <StretchedIcon />,
        question: 'Stretched for at least 10-15 minutes.',
        description: 'Click to watch Daily Stretch Video 1',
        placeholders: 'Additional comments on workout (optional)...',
        submit: true,
        checkbox: true,
        readOnly: false,
        isSub: false,
    },
    {
        type: 'dailyFitness',
        icon: <BurbleIcon />,
        question: 'Performed at least 20-30 minutes of physical activity.',
        description: '10-15 min',
        placeholders: 'Additional comments on workout (optional)...',
        submit: true,
        checkbox: true,
        readOnly: false,
        isSub: false,
    },
    {
        type: 'dailyPersonalDev',
        icon: <PersonalIcon/>,
        question: 'Spent at least 10 minutes on personal development',
        description: '10-15 min',
        placeholders: 'Additional comments on workout (optional)...',
        submit: true,
        checkbox: true,
        readOnly: false,
        isSub: false,
    },
    // {
    //     type: 'dailyPhoto',
    //     icon: <ImageIcon fontSize={'large'}/>,
    //     question: 'Posted on the Community Page today',
    //     description: '',
    //     placeholders: '',
    //     submit: false,
    //     checkbox: false,
    //     readOnly: true,
    //     isSub: false,
    // },
];
export const guestQuestionsTemplateAte = [
    {
        type: 'stayCalories',
        icon: <SpoonForkIcon fontSize={'large'}/>,
        question: 'Achieved my Nutrition goal',
        description: '',
        placeholders: '',
        submit: true,
        checkbox: true,
        readOnly: false,
        isSub: false,
        childrens: [
            {
                type: 'balanceBreakfast',
                icon: <SpoonForkIcon fontSize={'large'}/>,
                question: 'Ate a balanced breakfast',
                description: '',
                placeholders: 'Additional comments on breakfast (optional)...',
                submit: true,
                checkbox: false,
                readOnly: false,
                isSub: true,
            },
            {
                type: 'balanceLunch',
                icon: <SpoonForkIcon fontSize={'large'}/>,
                question: 'Ate a balanced lunch',
                description: '',
                placeholders: 'Additional comments on lunch (optional)...',
                submit: true,
                checkbox: false,
                readOnly: false,
                isSub: true,
            },
            {
                type: 'balanceDinner',
                icon: <SpoonForkIcon fontSize={'large'}/>,
                question: 'Ate a balanced dinner',
                description: '',
                placeholders: 'Additional comments on dinner (optional)...',
                submit: true,
                checkbox: false,
                readOnly: false,
                isSub: true,
            },
        ]
    },
    
];

export const guestQuestionsWeightTemplate = [
    {
        type: 'currentWeight',
        icon: <WeightInIcon fontSize={'large'}/>,
        question: 'What is your current weight?',
        group: '',
        isNumber: true,
    },
    {
        type: 'goalWeight',
        icon: <WeightGoalIcon fontSize={'large'}/>,
        question: 'What is your goal weight for the week? enter your current number if you are at your desired weight',
        group: '',
        isNumber: true,
    },
    // {
    //     type: '',
    //     icon: <SpoonForkIcon fontSize={'large'}/>,
    //     question: 'What is your non-scale goal for the week?',
    //     group: '',
    //     isNumber: false,
    // },
    {
        type: 'waistMeasurement',
        icon: <WaistMeasurementInOutIcon fontSize={'large'}/>,
        question: 'What is your waist measurement?',
        group: 'optional',
        isNumber: true,
    },
    {
        type: 'chestMeasurement',
        icon: <ChestMeasurementInOutIcon fontSize={'large'}/>,
        question: 'What is your chest measurement?',
        group: 'optional',
        isNumber: true,
    },
    {
        type: 'hipMeasurement',
        icon: <HipMeasurementInOutIcon fontSize={'large'}/>,
        question: 'What is your hip measurement?',
        group: 'optional',
        isNumber: true,
    },
    {
        type: 'bodyFat',
        icon: <BodyFatInOutGoalIcon fontSize={'large'}/>,
        question: 'What is your body fat percentage?',
        group: 'optional',
        isNumber: true,
    },
];

export const reformatPayloadQuestion = (type: string, payload: any) => {
    let endpoint;
    switch (type) {
        case 'balanceBreakfast':
            endpoint = 'breakfast';
            payload = {
                balanceBreakfastCheck: true,
                balanceBreakfastComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        case 'balanceLunch':
            endpoint = 'lunch';
            payload = {
                balanceLunchCheck: true,
                balanceLunchComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        case 'balanceDinner':
            endpoint = 'dinner';
            payload = {
                balanceDinnerCheck: true,
                balanceDinnerComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        case 'stayCalories':
            endpoint = 'calories';
            payload = {
                calories: true,
            };
            return { endpoint, payload };
        case 'dailyStretch':
            endpoint = 'stretch';
            payload = {
                dailyStretchDidOwnCheck: true,
                dailyStretchDidOwnComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        case 'dailyFitness':
            endpoint = 'fitness';
            payload = {
                dailyFitnessDidOwnCheck: true,
                dailyFitnessDidOwnComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        case 'dailyPersonalDev':
            endpoint = 'personalDev';
            payload = {
                dailyPersonalDevDidOwnCheck: true,
                dailyPersonalDevDidOwnComments: payload.value ? payload.value : '',
            };
            return { endpoint, payload };
        default:
            return null;
    }
};

export const getNutritionGoal = (data: any) => {
    let nutritionGoal = '';
    for (const key in data) {
        if (data[key].isCheck === true) {
            switch (key) {
                case 'calories':
                    nutritionGoal = 'caloriesIsCheck'
                    return nutritionGoal
                case 'trackMacros':
                    nutritionGoal = 'trackMacros'
                    return nutritionGoal
                case 'plateMethod':
                    nutritionGoal = 'plateMethod'
                    return nutritionGoal
                case 'intermittentFasting':
                    nutritionGoal = 'intermittentFasting'
                    return nutritionGoal
                case 'water':
                    nutritionGoal = 'waterIsCheck'
                    return nutritionGoal
                case 'snacking':
                    nutritionGoal = 'snacking'
                    return nutritionGoal
                case 'eliminate':
                    nutritionGoal = 'eliminateIsCheck'
                    return nutritionGoal
                case 'others':
                    nutritionGoal = 'othersIsCheck'
                    return nutritionGoal    
                default:
                    return nutritionGoal;
            }
        }
    }
}
