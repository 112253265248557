import { useContext } from 'react'

import TabMenuContext from '../context/tabMenu.context'

const useTab = () => {
    const { currentTab, setCurrentTab} = useContext(TabMenuContext)

    return { currentTab, setCurrentTab }
}

export default useTab
