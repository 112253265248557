
import { Typography as MuiTypography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const style = (theme) => ({
  mov_h1: {
    fontSize: 64,
    fontWeight: 700,
    lineHeight: '120%',
  },
  mov_h2: {
    fontSize: 64,
    fontWeight: 400,
    lineHeight: '120%',
  },
  mov_h3: {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '56px',
    // letterSpacing: "0.1em",
  },
  mov_h4: {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: '36px',
    // letterSpacing: "0.1em",
  },
  mov_h5: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: "1px",
  },
  mov_h6: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "1px",
  },
  mov_subtitle1_bold: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: "0.15px",
  },
  mov_subtitle1_regular: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "0.15px",
  },
  mov_subtitle2_bold: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: "0.1px",
  },
  mov_subtitle2_regular: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "0.1px",
  },
  mov_body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "0.25px",
  },
  mov_body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "0.25px",
  },
  mov_button1_bold: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: "1.25px",
    textTrans: "uppercase",
  },
  mov_button1_regular: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "1.25px",
    textTrans: "uppercase",
  },
  mov_button1_semibold: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '100%',
    letterSpacing: "0.25px",
  },
  mov_button2_bold: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: "0.02em",
    textTrans: "uppercase",
  },
  mov_button2_regular: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: "0.1px",
    textTrans: "uppercase",
  },
  mov_button2_semibold: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '100%',
    letterSpacing: "0.02em",
  },
  mov_caption1: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '120%',
    letterSpacing: "0.4px",
  },
  mov_caption2: {
    fontSize: 9,
    fontWeight: 400,
    lineHeight: '120%',
    letterSpacing: "0.4px",
  },
  mov_overline1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '120%',
    letterSpacing: "2.5px",
    textTrans: "uppercase",
  },
  mov_overline2: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '120%',
    letterSpacing: "1.5px",
    textTrans: "uppercase",
  },
  mov_extrabold18: {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '100%',
  },
  mov_heading3_bold: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '120%',
  }
});

/**
 * Overrides default material UI typography with some additional variants
 */
const Typography = ({ variant, classes, className, ...props }) => {
  const isCustom = Object.keys(classes).indexOf(variant) > -1;
  return (
    <MuiTypography
      className={isCustom ? clsx(classes[variant], className) : className}
      variant={isCustom ? undefined : variant}
      {...props}
      
    />
  );
};

export default withStyles(style)(Typography);