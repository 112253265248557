import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function FileIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 32" fill="none" className={classes.root}>
            <path d="M27.905 3.8533H25.3257V3.53018C25.3257 2.02518 24.015 0.805176 22.3988 0.805176H9.60066C7.98441 0.805176 6.67379 2.02518 6.67379 3.53018V3.8533H4.09504C2.91691 3.8533 1.96191 4.74268 1.96191 5.83955V29.2096C1.96191 30.3064 2.91691 31.1958 4.09504 31.1958H27.9057C29.0838 31.1958 30.0388 30.3064 30.0388 29.2096V5.83955C30.0382 4.74268 29.0832 3.8533 27.905 3.8533Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M21.9609 9.21082H10.1378C10.0053 9.21082 9.89844 9.10395 9.89844 8.97145V4.92395C9.89844 4.79145 10.0053 4.68457 10.1378 4.68457H21.9609C22.0934 4.68457 22.2003 4.79145 22.2003 4.92395V8.97145C22.2003 9.10395 22.0934 9.21082 21.9609 9.21082Z" fill="#FFA21E" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.8516 13.8296V27.1308" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
