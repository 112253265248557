import * as React from 'react';
import {ConfirmationDialog, ConfirmationOptions} from './ConfirmationDialog';

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useConfirmation = () =>
    React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = (props: any) => {
    const {children} = props;
    const [
        confirmationState,
        setConfirmationState,
    ] = React.useState<ConfirmationOptions | null>(null);
    const [
        openConfirm,
        setOpenConfirm,
    ] = React.useState<boolean>(false);

    const awaitingPromiseRef = React.useRef<{
        resolve: () => void;
        reject: () => void;
    }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);
        setOpenConfirm(true)
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = {resolve, reject};
        });
    };

    const handleClose = () => {
        if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }
        setOpenConfirm(false)
        // setConfirmationState(null);
    };

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }
        setOpenConfirm(false)
        // setConfirmationState(null);
    };

    return (
        <>
            <ConfirmationServiceContext.Provider
                value={openConfirmation}
                children={children}
            />

            <ConfirmationDialog
                open={openConfirm}
                onSubmit={handleSubmit}
                onClose={handleClose}
                variant={confirmationState ? confirmationState?.variant : 'info'}
                title={confirmationState ? confirmationState?.title : ''}
                description={confirmationState ? confirmationState?.description : ''}
                confirmBtnText={confirmationState?.confirmBtnText}
                cancelBtnText={confirmationState?.cancelBtnText}
            />
        </>
    );
};
