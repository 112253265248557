import React, { useContext } from "react";
import { Autocomplete } from "@material-ui/lab";
import { searchUserByEmail } from "services/domain/users";
import { Box, makeStyles, Popper, TextField, Theme, withStyles } from "@material-ui/core";
import { BookingEmailContext, BookingEmailContextType } from "../PaymentStore";

const useStyles = makeStyles((theme: Theme) => ({
  popperStyle: {
    width: "300px !important",
    marginTop: "-10px !important",
    "& .MuiAutocomplete-paper": {
      borderRadius: "0px 0px 8px 8px",
      borderTop: "1px solid transparent",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
      backgroundColor: "#f9f9f9",
      padding: "0px 10px",
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option": {
          paddingLeft: "5px",
        },
        '& .MuiAutocomplete-option[data-focus="true"]': {
          backgroundColor: "#FFEACC",
          borderRadius: "7px",
        },
      },
    },
  },
  onFocusStyle: {
    backgroundColor: "white",
    boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
  },
  buttonTrash: {
    color: "transparent",
    "& *": {
      stroke: theme.palette.error.main,
    },
    "&.MuiSvgIcon-colorPrimary *": {
      stroke: theme.palette.error.main,
    },
  },
}));

const CssTextField = withStyles({
  root: {
    background: "#F8F8F8",
    boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)",
    borderRadius: "7px",
    paddingRight: "20px",
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
})(TextField);

const FocusStyle = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
};

const EmailSearch: React.FC<any> = (props) => {
  const [searchValue, setSearchValue] = React.useState(null);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const { bookingEmail, setBookingEmail } = useContext(BookingEmailContext) as BookingEmailContextType;

  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const rspn = await searchUserByEmail(value);
      setOptions(rspn.data);
    } catch (error) {
    } finally {
      setLoadingSearch(false);
    }
  };

  const onChangeValue = (event: any, value: any) => {
    setSearchValue(value);
    props.onSearch(value);
    setBookingEmail(value.email);
  };

  const PopperMy = (props: any) => {
    const classes = useStyles();
    return <Popper {...props} className={classes.popperStyle} placement="bottom-start" />;
  };

  return (
    <Box mt={3} display="flex">
      <Autocomplete
        value={searchValue}
        size="small"
        freeSolo
        id="autocomplete-search"
        style={{ width: 300 }}
        // open={true}
        onOpen={() => {
          // inputChange(null, null);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={onChangeValue}
        onInputChange={inputChange}
        // getOptionSelected={(option, value) => option._id === value._id}
        getOptionLabel={(option) => option.email}
        options={options}
        loading={loadingSearch}
        PopperComponent={PopperMy}
        renderInput={(params) => <CssTextField placeholder="Search email" {...params} variant="outlined" style={open ? FocusStyle : {}} />}
      />
    </Box>
  );
};

export default EmailSearch;
