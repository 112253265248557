import React, { FC, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Avatar, Box, FormControl, Grid, IconButton, InputAdornment, Snackbar, Typography, TextareaAutosize } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "pages/DashboardUser/components/comunityFeeds/styles";
import { posting } from "services/domain/admin/comunityFeeds";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { ImageActiveIcon } from "components/icons";
import { useProfile } from "services/util";
import moment from "moment";
import ButtonMui from "components/_newDesign/button/Button";

const Post: FC<any> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const classes = useStyles();
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");
  const [message, setMessage] = React.useState("");
  const [openNotif, setOpenNotif] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<any>(undefined);
  const [descPhoto, setDescPhoto] = React.useState<string>("");
  const profile = useProfile();

  const handleCloseNotif = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };

  const onChangeFile = (e: any) => {
    setFile(e.target.files[0]);
  };
  const postPhoto = async () => {
    try {
      if (!props.step.step1 || !props.step.step2) {
        props.openModalWarning();
        return;
      }
      if (descPhoto === "") return;
      setLoading(true);
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("descPhoto", descPhoto);
      formData.append("postDate", moment().utc(true).toString());
      await posting(formData);
      setSeverity("success");
      setMessage("Send Daily Photo Success");
      setOpenNotif(true);
      setDescPhoto("");
      setFile(undefined);
      props.changeStatusStep("step3");
    } catch (error) {
      setSeverity("error");
      setMessage("Send Daily Photo Failed");
      setOpenNotif(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {}, []);
  return (
    <Grid container>
      <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleCloseNotif} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid item xs={12} sm={12} md={12}>
        <Paper elevation={3} className={classes.paperPost}>
          <Grid item md={12}>
            <Box display="inline-block" paddingLeft={4} paddingRight={2} paddingTop={2} width="100%">
              <Box display="flex">
                <InputAdornment position="start" className={classes.avatarPost}>
                  <Avatar src={profile?.avatar?.path || dummyAvatar} className={classes.avatar} />
                </InputAdornment>
                <FormControl variant="outlined" fullWidth>
                  <TextareaAutosize
                    className={classes.textarea}
                    id="outlined-adornment-post-feed-dashboard"
                    name="name"
                    placeholder="Share something from your day with the community!"
                    rows={5}
                    value={descPhoto}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescPhoto(e.target.value)}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box display="flex" paddingX={2} paddingBottom={2} alignContent="space-between">
              <Box flexGrow={1} textAlign="left" display="flex">
                <Box>
                  <div className={classes.uploadButton}>
                    <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={onChangeFile} />
                    <label htmlFor="icon-button-file">
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <ImageActiveIcon viewBox="0 0 27 27" />
                      </IconButton>
                    </label>
                  </div>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography>{file && file.name}</Typography>
                </Box>
              </Box>
              <Box flexGrow={1} textAlign="right">
                <ButtonMui className={classes.buttonStyle} disabled={loading} variant="contained" color="primary" onClick={postPhoto}>
                  {loading ? "Posting..." : "Post"}
                </ButtonMui>
              </Box>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Post;
