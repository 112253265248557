import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function CalInIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <g clip-path="url(#clip0)">
            <path d="M4.33057 5.15615C3.09018 7.28549 2.45836 8.1515 2.73938 11.0686C3.02071 13.9923 5.47728 15.5696 8.02815 15.5696C11.2545 15.5696 13.5068 13.7653 13.6332 10.3044C13.7614 6.79418 12.6147 6.30286 10.1811 4.26688C7.74745 2.2309 8.5311 1.2247 8.72976 0.569611C5.64074 2.17243 5.14943 4.64126 5.64074 6.67724C5.80074 7.33956 5.52381 8.7324 5.52381 8.7324C5.52381 8.7324 3.83894 7.02836 4.33057 5.15615Z" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </SvgIcon>

    );
};
