import React, { ReactNode } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import TopBar from '../TopBar/TopBar';
import Footer from 'components/layout/Footer';
import Feedback from 'components/layout/Feedback';

interface Props {
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    }
}));

const MainLayout = ({ children }: Props) => {
    const classes = useStyles();
    return (
        <Container
            className={classes.root}
            maxWidth={false}
            disableGutters
        >
            <TopBar />
            {children}
            <Feedback />
            <Footer />
        </Container>
    );
};

export default MainLayout;