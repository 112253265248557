import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "900px",
      background: "#FAFAFA",
      boxShadow: "0px 15px 60px rgba(0, 0, 0, 0.15)",
      borderRadius: "20px",
      padding: "90px 64px",
      marginTop: "140px",
      [theme.breakpoints.down("sm")]: {
        padding: "15px",
        marginTop: "80px",
      },
    },
    root2: {
      marginTop: "140px",
      maxWidth: "900px",
      background: "#FAFAFA",
      boxShadow: "0px 15px 60px rgba(0, 0, 0, 0.15)",
      borderRadius: "20px",
    },
    button: {
      padding: "11px !important",
    },
    buttonDefault: {
      border: "1px solid #E6E6E6",
      width: "250px",
    },
    collapsible: {
      transition: "max-height 0.2s ease-out",
    },
    coachBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: "12px",
      justifyContent: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        flexDirection: "column",
      },
    },
    coachCard: {
      display: "flex",
      flexDirection: "column",
      width: "192px",
      padding: "12px",
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        flexDirection: "row",
      },
    },
    coachSchedule: {
      padding: "4px 8px",
      borderRadius: "20px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "150%",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25px",
      color: "#000000",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    coachAvatarBox: {
      textAlign: "center",
      paddingBottom: "8px",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 0,
        paddingRight: "8px",
      },
    },
    cardRoot: {
      borderRadius: "20px",
    },
    DayCoachLeft: {
      fontFamily: "Montserrat",
      display: "table-cell",
      padding: "0.5em",
      borderRadius: "50%",
      verticalAlign: "middle",
      textAlign: "center",
      border: "1.5px solid #F1F1F1",
      width: "38px !important",
      marginBottom: "7px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#CDCDCD",
      "&::after": {
        top: "unset",
        width: "14px",
        content: '""',
        display: "block",
        zIndex: 1,
        position: "absolute",
        height: "1px",
        background: "#bbbbbb",
        marginTop: "-11px",
        marginLeft: "29px",
      },
    },
    DayCoachRight: {
      fontFamily: "Montserrat",
      display: "table-cell",
      padding: "0.5em",
      borderRadius: "50%",
      verticalAlign: "middle",
      textAlign: "center",
      border: "1.5px solid #F1F1F1",
      width: "38px !important",
      marginBottom: "7px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#CDCDCD",
      "&::after": {
        top: "unset",
        width: "14px",
        content: '""',
        display: "block",
        zIndex: 1,
        position: "absolute",
        height: "1px",
        background: "#bbbbbb",
        marginTop: "-11px",
        marginLeft: "-22px",
      },
    },
    dayCoachAvailable: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "8px",
      lineHeight: "120%",
      textAlign: "center",
      marginBottom: "19px",
    },
    DayCoachActive: {
      background: "#41C6D5",
      fontWeight: 600,
      color: "#000000",
    },
    coachAvatar: {
      width: "96px",
      height: "96px",
    },
    mobileDisplay: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    desktopDisplay: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    label: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontSize: 10,
      fontWeight: 400,
      lineHeight: "120%",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      marginBottom: "14px",
    },
    legendBooked: {
      fontFamily: "Montserrat",
      display: "table-cell",
      padding: "0.5em",
      borderRadius: "50%",
      verticalAlign: "middle",
      textAlign: "center",
      width: "38px !important",
      height: "38px !important",
      marginBottom: "7px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#CDCDCD",
      background: "rgba(0, 0, 0, 0.05)",
      "&::after": {
        top: "unset",
        width: "14px",
        content: '""',
        display: "block",
        zIndex: 1,
        position: "absolute",
        height: "1px",
        background: "#fafafa",
        marginTop: "-11px",
        marginLeft: "29px",
      },
    },
    legendSelected: {
      fontFamily: "Montserrat",
      display: "table-cell",
      padding: "0.5em",
      borderRadius: "50%",
      verticalAlign: "middle",
      textAlign: "center",
      width: "38px !important",
      height: "38px !important",
      marginBottom: "7px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#CDCDCD",
      background: "#FFA11E",
      "&::after": {
        top: "unset",
        width: "14px",
        content: '""',
        display: "block",
        zIndex: 1,
        position: "absolute",
        height: "1px",
        background: "#fafafa",
        marginTop: "-11px",
        marginLeft: "29px",
      },
    },
  }),
);
export default useStyles;
