import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Paper, Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { SignUpLayout } from 'components/layout/containers';
import { MInput } from 'components/controls';
import { useStyles } from './styles';
import { Toast } from 'services/util/toast';

import movaraLogo from 'assets/images/movara-home-logo.png';

import { forgotPassword } from 'services/domain/users';
import { ForgotPasswordRequest } from 'interfaces/user';
import { http } from 'services/data/http';

const initialValues = {
    email: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required').test(
        "Checking Email",
        "Your email not registered or need click activation email",
        (value) => {
            return new Promise((resolve, reject) => {
                http.get('search/users/email', {
                    params: {
                        email: value,
                    },
                }).then(res => {
                    // exists
                    if (res.data.length === 0) {
                        resolve(false)
                    } else {
                        resolve(true)
                    }
                }).catch(err => {
                    // note exists
                    resolve(true)
                })
            })
        }
      ),
});

const ForgotPassword = () => {
    const classes = useStyles();
    const [isForgotSuccess, setForgotStatus] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const onSubmit = async (values: ForgotPasswordRequest, { setSubmitting, resetForm }: FormikHelpers<ForgotPasswordRequest>) => {
        setSubmitting(true);
        try {
            await forgotPassword(values);
            setEmail(values.email);
            setForgotStatus(true);
            resetForm();
        } catch (e) {
            const toast = Toast({});
            if ('messages' in e) {
                let message = 'Unknown error';

                if (e.messages instanceof Array) {
                    message = e.messages[0];
                };
                
                if (typeof e.messages === 'string') {
                    message = e.messages;
                }

                toast.fire({
                    icon: 'error',
                    title: message,
                });  
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <SignUpLayout>
            <Container maxWidth="md" className={classes["forgot-password__container"]} >
                <Paper>
                    <Box maxWidth={460} marginLeft="auto" marginRight="auto" pt={15} pb={15}>
                        <Box mb={8} display="flex" justifyContent="center">
                            <img src={movaraLogo} alt="movara's logo" />
                        </Box>

                        <Box mb={8}>
                            <Typography align="center" variant="subtitle1">
                                Forgot Password
                            </Typography>
                        </Box>

                        {
                            !isForgotSuccess
                            && (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ isSubmitting, getFieldProps, getFieldMeta, submitCount, errors, values}) => (
                                    <Form>
                                        <Field
                                            as={MInput}
                                            placeholder="Enter your email address"
                                            label="To reset your password. Please enter your email address below:"
                                            id="email"
                                            autoComplete="email"
                                            autoFocus
                                            {...getFieldProps('email')}
                                            meta={getFieldMeta('email')}
                                            isSubmitted={true}
                                        />

                                        <Box display="flex" justifyContent="center" mt={14}>
                                            <div className={classes.buttonWrapper}>
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    className={classes.button}
                                                    disabled={isSubmitting || errors.email !== undefined || values.email === ''}
                                                >
                                                    Reset My Password
                                            </Button>
                                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                            )
                        }

                        {
                            isForgotSuccess
                            && (
                            <>
                                <Typography>
                                    We've sent you an email to {email}. Please click the link provided at your email to change password.
                                </Typography>

                                <Box display="flex" justifyContent="center" mt={14}>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            className={classes.button}
                                            component={RouterLink}
                                            to="/signin"
                                        >
                                            Back To Login Page
                                        </Button>
                                    </div>
                                </Box>
                            </>
                            )
                        }
                    </Box>
                </Paper>
            </Container>
        </SignUpLayout>
    );
};

export default ForgotPassword;
