import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DailyTaskIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99967 5.33335C5.38481 5.33335 4.83934 5.53122 4.47558 5.82223C4.11954 6.10706 3.99967 6.42131 3.99967 6.66669V25.3334C3.99967 25.5787 4.11954 25.893 4.47558 26.1778C4.83934 26.4688 5.38481 26.6667 5.99967 26.6667H25.9997C26.6145 26.6667 27.16 26.4688 27.5238 26.1778C27.8798 25.893 27.9997 25.5787 27.9997 25.3334V6.66669C27.9997 6.42131 27.8798 6.10706 27.5238 5.82223C27.16 5.53122 26.6145 5.33335 25.9997 5.33335H5.99967ZM2.80972 3.73991C3.69621 3.03072 4.84642 2.66669 5.99967 2.66669H25.9997C27.1529 2.66669 28.3031 3.03072 29.1896 3.73991C30.0838 4.45527 30.6663 5.49758 30.6663 6.66669V25.3334C30.6663 26.5025 30.0838 27.5448 29.1896 28.2601C28.3031 28.9693 27.1529 29.3334 25.9997 29.3334H5.99967C4.84642 29.3334 3.69621 28.9693 2.80972 28.2601C1.91552 27.5448 1.33301 26.5025 1.33301 25.3334V6.66669C1.33301 5.49758 1.91552 4.45527 2.80972 3.73991Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 10.6667C6.66602 9.93033 7.26297 9.33337 7.99935 9.33337H9.33268C10.0691 9.33337 10.666 9.93033 10.666 10.6667C10.666 11.4031 10.0691 12 9.33268 12H7.99935C7.26297 12 6.66602 11.4031 6.66602 10.6667Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 16C6.66602 15.2636 7.26297 14.6667 7.99935 14.6667H9.33268C10.0691 14.6667 10.666 15.2636 10.666 16C10.666 16.7364 10.0691 17.3334 9.33268 17.3334H7.99935C7.26297 17.3334 6.66602 16.7364 6.66602 16Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 21.3334C6.66602 20.597 7.26297 20.0001 7.99935 20.0001H9.33268C10.0691 20.0001 10.666 20.597 10.666 21.3334C10.666 22.0698 10.0691 22.6667 9.33268 22.6667H7.99935C7.26297 22.6667 6.66602 22.0698 6.66602 21.3334Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 12H13.333V9.33337H23.9997V12Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 17.3334H13.333V14.6667H23.9997V17.3334Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9997 22.6667H13.333V20.0001H23.9997V22.6667Z"
                fill="#6D6D6D"
            />
        </SvgIcon>
    );
}
