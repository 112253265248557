import React, { FC, useCallback, useEffect, useRef, useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import MTable from "../../../../components/MTable";
import { Column } from "react-table";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import UserLogModal from "./UserLogModal";
import UserChartModal from "../UserChartModal";
// import { UserActionCell, UserDailyCell, UserNameCell, UserProgramCell, UserWeeklyCell } from './TableCell';
import { reformatUserDailyTable, reformatUserWeeklyTable, reformatUserWeeklyAssesment } from "../helper";
import UserNoteModal from "./UserNoteModal";
import { Box, Button, Grid, Snackbar } from "@material-ui/core";
import FilterListModal from "./FilterListModal";
import ChatModal from "./ChatModal";
import { Creators } from "services/domain/chats/reducer";
import { getUserAssessment } from "services/domain/admin/users";
import UserWeeklyModal from "./UserWeeklyModal";
import SendIcon from "@material-ui/icons/Send";
import { Alert } from "@material-ui/lab";

const UserActionCell = lazy(() => import("./tableCell/UserActionCell"));
const UserDailyCell = lazy(() => import("./tableCell/UserDailyCell"));
const UserNameCell = lazy(() => import("./tableCell/UserNameCell"));
interface Props {
  dateRangeFilter: DateRange;
  dataFilter: any;
}

const TableByUser: FC<Props> = ({ dateRangeFilter, dataFilter }) => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [modalLog, setModalLog] = useState(false);
  const [modalChart, setModalChart] = useState(false);
  const [modalNotes, setModalNotes] = useState(false);
  const [modalWeekly, setModalWeekly] = useState(false);
  const [row, setRow] = useState({});
  const [rowTask, setRowTask] = useState({});
  const chatModalRef = useRef();
  const tableComp = useRef<any>(null);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [tableFilter, setTableFilter] = useState({});
  const [emailData, setEmailData] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [startDateNew, setStartDateNew] = React.useState(moment().startOf("week").format("YYYY-MM-DD"));
  const [endDateNew, setEndDateNew] = React.useState(moment().endOf("week").format("YYYY-MM-DD"));
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");
  const [message, setMessage] = React.useState("");
  const [openNotif, setOpenNotif] = React.useState<boolean>(false);

  useEffect(() => {
    if (tableComp && tableComp.current) tableComp.current.forceFetch();
  }, [dateRangeFilter, tableFilter, dataFilter]);

  useEffect(() => {
    return () => {
      dispatch(Creators.updateChatAssessmentUser("", "", "", ""));
    };
  });

  const handleClickChart = (rowData: any) => {
    setRow(rowData);
    setModalChart(true);
  };

  const handleClickAssesment = (rowData: any, startNewDate: any, endNewDate: any) => {
    setRow(rowData);
    setStartDateNew(startNewDate);
    setEndDateNew(endNewDate);
    setModalWeekly(true);
  };

  const handleClickTaskButton = (category: string, rowData: any, index: number, startNewDate: any) => {
    setStartDateNew(startNewDate);
    setRowTask({ category: category, data: rowData, index: index });
    setModalLog(true);
  };

  const handleClickNotes = (rowData: any) => {
    setRow(rowData);
    setModalNotes(true);
  };

  const handleCloseNotes = () => {
    tableComp.current.forceFetch();
    setModalNotes(false);
  };

  const handleClickMessage = (rowData: any) => {
    dispatch(Creators.updateChatAssessmentUser(rowData?.userId ?? "", rowData?.name?.username ?? "", rowData?.displayName ?? "", rowData?.avatar ?? ""));
    // @ts-ignore
    chatModalRef.current.openChatMessage();
  };

  const handleClickEmail = (rowData: any) => {
    window.location.href = `mailto:${rowData.name.email}`;
  };

  const handleClickUserProfile = (rowData: any) => {
    const id = rowData?.userId;
    //redirect user to selected user profile
    window.location.href = `/user-profile/${id}`;
  };

  const handleFilter = (filter: any) => {
    const { daily, weekly, status } = filter;
    setTableFilter({
      daily: daily.check,
      weekly: weekly.check,
      status: status.check ? status.value : "",
    });
  };

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy }) => {
      try {
        const params = {
          startDate: moment().startOf("week").format("YYYY-MM-DD"),
          endDate: moment().endOf("week").format("YYYY-MM-DD"),
          existData: pageIndex * pageSize,
          limitData: pageSize,
          sortFirstName: 1,
          coachId: dataFilter.coachId,
          userEmail: dataFilter.userEmail,
          ...tableFilter,
        };
        const res = await getUserAssessment(params);
        const mapToTable = res.data.map((item: any) => {
          return {
            id: item.idPost,
            notes: item.notes,
            bodyFatStat: item.bodyFatStat % 1 !== 0 ? item.bodyFatStat.toFixed(2) : item.bodyFatStat,
            weightStat: item.weightStat % 1 !== 0 ? item.weightStat.toFixed(2) : item.weightStat,
            name: {
              username: `${item.firstName} ${item.lastName}`,
              email: item.email,
              progress: item.statusBooking,
              isBeenGuest: item.beenGuest?.isCheck,
              avatar: item.avatar,
              weekOngoing: item.weekOngoing,
              totalWeeks: item.totalWeeks,
              state: item.state,
              bookingStartDate: item.bookingStartDate.slice(0, 10),
              bookingEndDate: item.bookingEndDate.slice(0, 10),
              phoneNumber: item.phoneNumber,
              // status: '1 / 1W',
            },
            newDaily: {
              weekly: reformatUserWeeklyTable(item.data),
              daily: reformatUserDailyTable(item.data),
            },
            allTask: item.data,
            program: {
              percentage: item.progress,
              post: item.totalPost,
              comment: item.totalComments,
              like: item.totalLikes,
              totalPoints: item.totalPoints,
            },
            displayName: item.displayName,
            avatar: item.avatar,
            userId: item.userId,
            originalData: reformatUserWeeklyAssesment(item.data),
          };
        });

        setData(mapToTable);
        setPageCount(res.meta.pagination.totalPage);
      } catch (error) {
        console.error("error", error);
      }
      // eslint-disable-next-line
    },
    [dateRangeFilter, tableFilter, dataFilter],
  );

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Cell: (props) => (
          <Suspense fallback={<p>Loading...</p>}>
            <UserNameCell value={props.value} row={props.row} />
          </Suspense>
        ),
      },
      // {
      //     Header: 'WEEKLY',
      //     accessor: 'weekly',
      //     Cell: ((props) => (
      //         <Suspense fallback={<p>Loading...</p>}>
      //             <UserWeeklyCell value={props.value}
      //                 row={props.row}
      //                 onClickChart={handleClickChart}
      //                 onClickWeeekly={handleClickAssesment}
      //             />
      //         </Suspense>
      //     )),
      // },
      {
        Header: "WEEKLY? DAILY",
        accessor: "newDaily",
        Cell: (props) => (
          <Suspense fallback={<p>Loading...</p>}>
            <UserDailyCell
              value={props.value}
              row={props.row}
              onClickLog={handleClickTaskButton}
              onClickChart={handleClickChart}
              onClickWeeekly={handleClickAssesment}
              startDate={moment().startOf("week").format("YYYY-MM-DD")}
              endDate={moment().endOf("week").format("YYYY-MM-DD")}
            />
          </Suspense>
        ),
      },
      // {
      //     Header: 'PROGRAM',
      //     accessor: 'program',
      //     Cell: ((props) => (
      //         <Suspense fallback={<p>Loading...</p>}>
      //             <UserProgramCell value={props.value}
      //                 row={props.row}
      //             />
      //         </Suspense>
      //     )),
      // },
      {
        Header: "ACTION",
        Cell: (props) => (
          <Suspense fallback={<p>Loading...</p>}>
            <UserActionCell
              value={props.value}
              row={props.row}
              onClickUserProfile={handleClickUserProfile}
              onClickNotes={handleClickNotes}
              onClickMessage={handleClickMessage}
              onClickEmail={handleClickEmail}
            />
          </Suspense>
        ),
      },
      // eslint-disable-next-line
    ],
    [],
  );

  const handleCloseNotif = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };

  const openNotification = (message: string, severity: "success" | "info" | "warning" | "error") => {
    setSeverity(severity);
    setMessage(message);
    setOpenNotif(true);
  };

  const handleCloseUserWeeklyModal = (putRequest?: boolean) => {
    if (putRequest) {
      tableComp.current.forceFetch();
      setModalWeekly(false);
      openNotification("Updated Weekly Assessment Success!", "success");
    } else {
      setModalWeekly(false);
    }
  };

  const setTableCheckbox = (data) => {
    let allEmail = [];
    allEmail = data.filter((row) => row.row !== undefined).map((row) => row?.row?.name.email);
    setEmailData(allEmail.toString());
  };

  const sendAllEmail = () => {
    window.location.href = `mailto:${emailData}`;
    setEmailData(null);
  };

  return (
    <>
      <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleCloseNotif} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} direction="row" justify="flex-end">
        <Grid item md={12}>
          <Box textAlign="right">
            <Box component="div" display="inline" mr={1}>
              <Button disabled={!emailData} variant="contained" color="primary" startIcon={<SendIcon />} onClick={() => sendAllEmail()}>
                Send Email
              </Button>
              <FilterListModal show={showFilterModal} onFilter={handleFilter} onClose={() => setShowFilterModal(false)} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <MTable
        columns={columns}
        data={data}
        onFetchData={fetchData}
        pageCount={pageCount}
        ref={tableComp}
        pageSize={50}
        withCheckbox
        onChangeCheckbox={(data: any) => setTableCheckbox(data)}
      />
      <UserLogModal show={modalLog} data={rowTask} onClose={() => setModalLog(false)} startDate={startDateNew} />
      <UserChartModal show={modalChart} data={row} onClose={() => setModalChart(false)} />
      <UserNoteModal show={modalNotes} data={row} onClose={() => setModalNotes(false)} onCloseWithFetch={handleCloseNotes} />

      <ChatModal ref={chatModalRef} />
      <UserWeeklyModal startDate={startDateNew} endDate={endDateNew} show={modalWeekly} data={row} onClose={handleCloseUserWeeklyModal} />
    </>
  );
};
export default TableByUser;
