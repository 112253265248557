import { http } from 'services/data';
import { 
    DailyTaskPayload, 
    DailyVideoResponse, 
    DailyTaskForm, 
    DurationAddResponse,
    DurationGetPayload,
    DurationResponse,
    DurationAddForm,
    DailyTaskresponse,
} from 'interfaces/programPlan';

const endPoints = {
    stretch: 'admin/video/daily/stretch',
    fitness: 'admin/video/daily/fitness',
    personalDev: 'admin/video/daily/personalDev',
    getDailyTask: 'admin/video/dailytasks',
    addDailyTask: 'admin/video/dailytasks/new',
    duration: 'admin/duration',
};

export const getDailyStretch = async (): Promise<DailyVideoResponse> => await http.get(endPoints.stretch);
export const getDailyFitness = async (): Promise<DailyVideoResponse> => await http.get(endPoints.fitness);
export const getDailypersonalDev = async (): Promise<DailyVideoResponse> => await http.get(endPoints.personalDev);
export const getDailyTask = async (payload: DailyTaskPayload): Promise<DailyTaskresponse> => await http.get(endPoints.getDailyTask, {params: payload});
export const addDailyTask = async (payload: DailyTaskForm): Promise<any> => await http.post(endPoints.addDailyTask, payload);
export const getDuration = async (payload: DurationGetPayload): Promise<DurationResponse> => await http.get(endPoints.duration, {params: payload});
export const addDuration = async (payload: DurationAddForm): Promise<DurationAddResponse> => await http.post(endPoints.duration, payload);
