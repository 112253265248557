import React, { useState, useEffect } from 'react';
import { SignUpLayout } from 'components/layout/containers';
import movaraLogo from 'assets/images/movara-home-logo.png';
import { Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useParams, useHistory } from 'react-router-dom';
import { http } from 'services/data';

const Activate = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<boolean>(false);
    const [expired, setExpired] = useState<boolean>(false)
    const { token } = useParams<{ token: string }>();
    const classes = useStyles();
    const history = useHistory();

    const activateAccount = () => {
        setLoading(true)
        http.post('register/activate', {
            token: token
        }).then(res => {
            setStatus(true);
        }).catch((e) => {
            if(e.messages[0] === 'email Already Exist!'){
                setExpired(false)
            } else {
                setExpired(true)
            }
            setStatus(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        activateAccount()
        // eslint-disable-next-line
    }, [token]);

    return (
        <SignUpLayout>
            <Container maxWidth="md">
                <Box my={15}>
                    <Paper>
                        <Box px={10} pt={10} pb={10} my={10}>
                            {loading ? (
                                <Box display="flex" 
                                    pb={1}
                                    className={classes.loadingPost}
                                    alignItems="center"
                                    justifyContent="center">
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box mb={5} display="flex" justifyContent="center">
                                            <img src={movaraLogo} alt="movara's logo" />
                                        </Box>
                                        <Box mb={5} display="flex" justifyContent="center">
                                            <Typography align="center" className={classes.title}>
                                                Activate Account
                                            </Typography>
                                        </Box>
                                        <Box mb={5} display="flex" justifyContent="center">
                                            <Typography align="center" className={classes.content}>
                                                {status ? 'Your Account has been activated' : expired ? 'Activate Failed. Your link is incorrect or expired' : 'Your Account already Activate.'}
                                            </Typography>
                                        </Box>
                                        <Box mb={5} display="flex" justifyContent="center">
                                            <Button
                                                color="primary"
                                                type="submit"
                                                variant="contained"
                                                className={classes.button}
                                                onClick={() => history.push(`/signin`)}
                                            >
                                                Go To Login
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </SignUpLayout>
    );
};

export default Activate;
