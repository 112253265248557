/**
 * Regular button, but only show the icon. Difference with material-ui's IconButton is the shape
 */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ButtonIcon = withStyles({
    root: {
        minWidth: 'unset',
        padding: '6px 10px',
    },
    startIcon: {
        marginRight: '-4px',
    }
})(Button);

export default ButtonIcon;
