import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '36px',
        fontStyle: 'inherit',
        fontWeight: 700,
        lineHeight: '120%',
        letterSpacing: '1px',

    },
    content: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '150%',
        letterSpacing: '0.25px',
    },
    contentStep: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },
    subtitle: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    subtitleStep: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        },

    },
    button: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        textTransform: 'none',
        boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.5), 1px 1px 6px rgba(0, 0, 0, 0.25), inset 1px 1px 3px rgba(255, 218, 165, 0.6)',
        borderRadius: '7px',
    },
    loadingPost: {
        width: '100% !important'
    },
    listDay: {
        display: 'inline-flex',
        paddingLeft: 0,
        '& li': {
            display: 'inline',
            position: 'relative',
            paddingLeft: '70px',
            paddingRight: '70px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '182px',
                minWidth: '140px',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '18px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    listDayStep2: {
        display: 'inline-flex',
        paddingLeft: 0,
        '& li': {
            display: 'inline',
            position: 'relative',
            paddingLeft: '70px',
            paddingRight: '70px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '182px',
                minWidth: '140px',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '18px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
            
        },
    },
    listDayStep: {
        display: 'flex',
        paddingLeft: 0,
        width: '100%',
        justifyContent:'space-between',
        '& li': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '100%',
                width: '100%',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '11px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    listDayStepAllDone: {
        display: 'flex',
        paddingLeft: 0,
        width: '100%',
        justifyContent:'space-between',
        '& li': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '100%',
                width: '50%',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '11px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    listDayVertical: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 0,
        '& li': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '48px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:first-child)::before': {
                content: '\'\'',
                minWidth: 1,
                height: 50,
                background: '#C4C4C4',
                position: 'absolute',
                top: -49,
            },
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    listToday: {
        '&:not(:last-child)::before': {
            background: `${theme.palette.primary.main} !important`,
        },
    },
    circle: {
        borderRadius: '7px',
        width: '112px',
        height: '36px',
        background: '#FFFFFF',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box', 

    },
    circle2: {
        borderRadius: '7px',
        width: '112px',
        height: '36px',
        textAlign: 'center',
        background: '#FFFFFF',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box', 
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '7px',
            width: '80px',
            height: '23px',
            textAlign: 'center',
            fontSize: '14px',
        },

    },
    circleEnd: {
        width: '62px',
        height: '36px',
        background: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            width: '23px',
            height: '36px',
        },
    },
    circleActive: {
        borderColor: theme.palette.primary.main,
    },
    circleToday: {
        // borderColor: '#E5E5E5',
        // filter: 'drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.1))',
    },
    whenStepOn: {
        marginBottom: 150,
    },
    whenStepComplete: {
        marginBottom: 300,
    },
    weeklyPadding: {
        padding: '24px 48px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px 24px',
        },
    },
    containerOnboard: {
        [theme.breakpoints.down('sm')]: {
            padding: '20px 10px 10px 10px',
        },
    },
    circleComplete: {
        height: '62px',
        width: '62px',
        [theme.breakpoints.down('sm')]: {
            height: '23px',
            width: '23px',
        },
    }, 
    successDesc: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px'
        },
    },
    successDescButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px'
        },
    },

    //continue onboard class
    onBoardTitle: {
        paddingRight: '64px', 
        paddingLeft: '64px', 
        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px', 
            paddingLeft: '10px', 
            fontSize: '24px'
        },
    },
    onBoardDesc: {
        paddingRight: '176px', 
        paddingLeft: '176px', 
        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px', 
            paddingLeft: '10px', 
            fontSize: '24px',
        },
    },

    //Profile Submitted css
    profileTitle: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '36px',
        lineHeight: '120%',
        letterSpacing: '1px',
        color: '#000000',
    },
    profileDesc: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.25px',
        color: '#000000',
        textAlign: 'center',
    },
    newStepCircle: {
        backgroundColor: 'white',
        color: '#CDCDCD',
        fontSize: '14px',
        fontWeight: 600,
        margin: 'auto'
    },
    newStepCircleMain: {
        backgroundColor: '#FFF8E2',
        color: '#CDCDCD',
        fontSize: '14px',
        fontWeight: 600,
        margin: 'auto'
    },
    newStepCircleDone: {
        backgroundColor: '#FFA11E',
        color: '#000000',
        fontSize: '14px',
        fontWeight: 600,
        margin: 'auto'
    },
    newStepDesc: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0.25px',
        color: '#9A9A9A',

    }
}));