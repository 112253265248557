import React from 'react';

import ButtonIcon from 'components/button/ButtonIcon';
// import CreateOutlined from '@material-ui/icons/CreateOutlined';
import { PencilIcon } from 'components/_newIcon'
import { Box } from '@material-ui/core';

const ActionCell = ({ actionEdit, row }: { actionEdit: (row: any) => void, row: any }) => (
    <Box textAlign={'center'}>
        <ButtonIcon
            variant="text"
            onClick={() => actionEdit(row)}
            startIcon={<PencilIcon />}
        />
    </Box>
    
);

export default ActionCell;
