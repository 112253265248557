import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import { Box, Grid, Typography, createStyles, Radio, FormControlLabel, CircularProgress, Avatar } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import auth from "services/domain/auth";

import CustomInput from "components/controls/CustomInput/CustomInputPayment";
import { useSnackbar } from "notistack";
import { RadioGroup } from "formik-material-ui";
import { useStyles } from "./styles";
import BookingInfo from "./BookingInfo";
import moment from "moment";
import { http } from "services/data/http";
import { getUser } from "services/domain/users";
import CustomInputPaymentPassword from "components/controls/CustomInput/CustomInputPaymentPassword";
import EmailSearch from "./EmailSearch";
import SelectCoach from "./SelectCoach";
import TypographyMui from "components/_newDesign/typography/Typography";
import ButtonMui from "components/_newDesign/button/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { ConnectedFocusError } from "focus-formik-error";
import checkPrice from "./checkPrice";
import { ProfileContext, ProfileContextType, ProfileInterface } from "../PaymentStore";

const RadioGroupRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "row",
    },
  }),
)(RadioGroup);

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  gender: "male",
  dateOfBirth: "2021-07-25",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  startDate: undefined,
  endDate: undefined,
  nameOnCard: "",
  index: 1,
  amount: 150,
  perweek: 150,
  password: "",
  newPassword: "",
  newPassConfirm: "",
  coach_id: "",
  coachName: "",
};

//*YUP is a schema validation library https://www.npmjs.com/package/yup
const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  // phoneNumber: Yup.string().required('Phone Number is required'),
  email: Yup.string()
    .email()
    .required("Email is required")
    .test("Unique Email", "Email already in use", (value) => {
      return new Promise((resolve, reject) => {
        http
          .get("search/users/email", {
            params: {
              email: value,
            },
          })
          .then((res) => {
            // exists
            if (res.data.length === 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            // note exists
            resolve(false);
          });
      });
    }),
  gender: Yup.string().required("Gender is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .matches(/^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, "Must Contain 8 Characters and number"),
  newPassConfirm: Yup.string()
    .required("Password Confirmation is required")
    .oneOf([Yup.ref("newPassword"), ""], "Password must match"),
  zipCode: Yup.string().required("ZIP Code is required").matches(/^\d+$/, "Only digits allowed"),
});

const ProfileForm: React.FC<any> = (props) => {
  const classes = useStyles();

  const hasAddWeeksQuery = props.hasAddWeeksQuery || false;

  //Snack bars are the notification boxes usually called toasts https://www.npmjs.com/package/notistack
  const { enqueueSnackbar } = useSnackbar();

  //Previously saved values are saved to local storage
  const tempDataProfile = localStorage.getItem("profiletemp");
  // const [profile, setProfile] = React.useState<ProfileInterface>(tempDataProfile === null ? initialValues : JSON.parse(tempDataProfile));
  const { profile, setProfile } = useContext(ProfileContext) as ProfileContextType;
  const [showSpinner, setShowSpinner] = React.useState<boolean>(true);
  const [showSelectDurationErr, setShowSelectDurationErr] = React.useState<boolean>(true);
  const [showSelectStartDateErr, setShowSelectStartDateErr] = React.useState<boolean>(true);

  const handleSubmit = async (values: ProfileInterface) => {
    if (auth?.userProperties?.userData?.role === "admin" && profile.email === "") {
      enqueueSnackbar("please input email for user", {
        variant: "error",
      });
      return;
    }
    // if they select a weeks range it will set profile.endDate
    if (!profile.endDate /*  || !profile.endDate */) {
      setShowSelectDurationErr(true);
      return;
    }
    // if they select a startDate, it will set profile.startDate
    if (!profile.startDate) {
      setShowSelectStartDateErr(true);
      return;
    }
    let dataSend = values;
    dataSend.startDate = profile.startDate;
    dataSend.endDate = profile.endDate;
    dataSend.nameOnCard = `${dataSend.firstName} ${dataSend.lastName}`;
    dataSend.index = profile.index;
    dataSend.amount = profile.amount;
    dataSend.perweek = profile.perweek;

    props.onProfileSubmit(dataSend);
    props.changeStatusStep(1);
    localStorage.setItem("profiletemp", JSON.stringify(profile));
  };

  const getStringOrderDetail = (profile) => {
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    return (
      <>
        <div className={classes.orderDesc}>
          {weeks} {weeks === 1 ? "week" : "weeks"} of Movara Home program {price !== undefined ? `$${price.perweek}/week` : ""}
        </div>
      </>
    );
  };
  const totalAmount = (profile) => {
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    if (price !== undefined) {
      return weeks * price?.perweek;
    } else {
      return 0;
    }
  };

  const onBookingUpdate = (profile) => {
    if (!profile.endDate) {
      setShowSelectDurationErr(true);
    } else {
      setShowSelectDurationErr(false);
    }
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    if (price !== undefined) {
      setProfile({
        ...profile,
        index: price.index,
        amount: price.amount,
        perweek: price.perweek,
      });
    }
  };

  const getProfile = async () => {
    try {
      const { data } = await getUser({
        user_id: auth.userProperties?.userData?._id,
      });

      setProfile({
        firstName: data.name.firstName,
        lastName: data.name.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        gender: data.gender || "male",
        dateOfBirth: data.dob || "2021-07-25",
        address: data.address,
        city: data.city || "",
        state: data.state || "",
        zipCode: data.zipCode || "",
        startDate: undefined,
        endDate: undefined,
        nameOnCard: `${data.name.firstName} ${data.name.lastName}`,
        index: 1,
        amount: 150,
        perweek: 150,
        password: "",
        coach_id: "",
        coachName: "",
      });

      setShowSpinner(false);
    } catch (error) {}
  };
  const setProfileFromSearch = (data) => {
    if (data === null) {
      setProfile(initialValues);
      return;
    }

    setProfile({
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      phoneNumber: data.phoneNumber,
      email: data.email,
      gender: data.gender || "male",
      dateOfBirth: data.dob || "2021-07-25",
      address: data.address,
      city: data.city || "",
      state: data.state || "",
      zipCode: data.zipCode || "",
      startDate: undefined,
      endDate: undefined,
      nameOnCard: `${data.firstName || ""} ${data.lastName || ""}`,
      index: 1,
      amount: 150,
      perweek: 150,
      password: "",
      coach_id: profile.coach_id,
      coachName: profile.coachName,
    });
  };

  const onSelectCoach = (data) => {
    setProfile({ ...profile, coach_id: data._id, coachName: data.name });
  };

  React.useEffect(() => {
    const initialProfileValue = tempDataProfile === null ? initialValues : JSON.parse(tempDataProfile);
    setProfile(initialProfileValue);

    if (auth.userProperties === null) {
      setShowSpinner(false);
    } else if (auth.userProperties.userData.role !== "admin" && !hasAddWeeksQuery) {
      getProfile();
    } else {
      setShowSpinner(false);
    }
  }, []);

  React.useEffect(() => {
    if (!profile.startDate) {
      setShowSelectStartDateErr(true);
    } else {
      setShowSelectStartDateErr(false);
    }
  }, [profile]);

  return (
    <>
      {showSpinner ? (
        <Box
          display="flex"
          pt="10"
          // className={classes.loadingPost}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Formik
          enableReinitialize
          initialValues={profile}
          validationSchema={auth.userProperties === null ? validationSchema : undefined}
          onSubmit={handleSubmit}
          // validateOnBlur={true}
          validate={(values) => {
            const weeks = moment(values.endDate).diff(moment(values.startDate), "weeks");
            const price = checkPrice(weeks);
            if (price !== undefined) {
              setProfile({
                ...values,
                index: price.index,
                amount: price.amount,
                perweek: price.perweek,
              });
            }
          }}
        >
          {({ isSubmitting, errors, getFieldProps, getFieldMeta, submitCount, touched }) => (
            <>
              <Form noValidate>
                <ConnectedFocusError />
                <Box className={classes.cardContentWrapper} mb={"30px"}>
                  {auth.userProperties === null && <TypographyMui variant="mov_subtitle1_bold">Personal information</TypographyMui>}
                  {auth?.userProperties?.userData?.role === "admin" ? (
                    <Grid container className={classes.formContainer}>
                      <Grid item md={12} xs={12} sm={12} xl={12}>
                        <Typography className={classes.label}>
                          Search Email{" "}
                          <span
                            style={{
                              color: "#EF5136",
                            }}
                          >
                            &#9679;
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item md={12} xs={12} sm={12} xl={12}>
                        <EmailSearch onSearch={(data) => setProfileFromSearch(data)} />
                      </Grid>
                    </Grid>
                  ) : (
                    auth?.userProperties?.userData?.role === "guest" && (
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <Avatar src={auth?.userProperties?.userData?.avatar ? auth?.userProperties?.userData?.avatar : dummyAvatar} />
                        </Box>
                        <Box ml={1}>
                          <TypographyMui variant="mov_body1">{auth?.userProperties?.userData?.displayName}</TypographyMui>
                          <TypographyMui variant="mov_caption1">{auth?.userProperties?.userData?.email}</TypographyMui>
                        </Box>
                      </Box>
                    )
                  )}
                  {auth.userProperties === null && (
                    <>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            First Name{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="Type in here"
                            id="firstName"
                            autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("firstName")}
                            meta={getFieldMeta("firstName")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>

                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            Last Name{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="Type in here"
                            id="lastName"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("lastName")}
                            meta={getFieldMeta("lastName")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>

                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            Email Address{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="user@email.com"
                            id="email"
                            // autoFocus

                            className={classes.inputCustomWidth}
                            {...getFieldProps("email")}
                            meta={getFieldMeta("email")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            Password{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>{" "}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInputPaymentPassword}
                            type="password"
                            placeholder="Type in here"
                            id={"newPassword"}
                            autoComplete="off"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("newPassword")}
                            meta={getFieldMeta("newPassword")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            Confirm Password{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>{" "}
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInputPaymentPassword}
                            placeholder="Type in here"
                            id="newPassConfirm"
                            type="password"
                            autoComplete="off"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("newPassConfirm")}
                            meta={getFieldMeta("newPassConfirm")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>
                            Gender{" "}
                            <span
                              style={{
                                color: "#EF5136",
                              }}
                            >
                              &#9679;
                            </span>
                          </Typography>
                        </Grid>

                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Field component={RadioGroupRow} name="gender" id="gender">
                            <Grid item md={3}>
                              <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                            </Grid>
                            <Grid item md={9}>
                              <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                            </Grid>
                          </Field>
                          <Grid item md={12} xs={12} sm={12} xl={12}>
                            <TypographyMui
                              variant="mov_body2"
                              style={{
                                color: "##9A9A9A",
                              }}
                            >
                              (We need it to calculate BMR )
                            </TypographyMui>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>birthday date</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            type="date"
                            id="dateOfBirth"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("dateOfBirth")}
                            meta={getFieldMeta("dateOfBirth")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <TypographyMui variant="mov_subtitle1_bold" style={{ marginTop: "30px" }}>
                        Contact information
                      </TypographyMui>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>Phone Number</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="+1"
                            id="phoneNumber"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("phoneNumber")}
                            meta={getFieldMeta("phoneNumber")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>Address</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="Type in here"
                            id="address"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("address")}
                            meta={getFieldMeta("address")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <Typography className={classes.label}>City</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} xl={6}>
                          <Field
                            as={CustomInput}
                            placeholder="Type in here"
                            id="city"
                            // autoFocus
                            className={classes.inputCustomWidth}
                            {...getFieldProps("city")}
                            meta={getFieldMeta("city")}
                            isSubmitted={submitCount > 0}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className={classes.formContainer}>
                        <Grid item md={6} xs={12}>
                          <Grid container className={classes.formContainer}>
                            <Grid item md={12} xs={12} sm={12} xl={12}>
                              <Typography className={classes.label}>State</Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Field
                                as={CustomInput}
                                placeholder="Type in here"
                                id="state"
                                // autoFocus
                                className={classes.inputCustomWidth}
                                {...getFieldProps("state")}
                                meta={getFieldMeta("state")}
                                isSubmitted={submitCount > 0}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.zipClass}>
                          <Grid container className={classes.formContainer}>
                            <Grid item md={12} xs={12} sm={12} xl={12}>
                              <Typography className={classes.label}>
                                ZIP Code{" "}
                                <span
                                  style={{
                                    color: "#EF5136",
                                  }}
                                >
                                  &#9679;
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Field
                                as={CustomInput}
                                placeholder="Type in here"
                                id="zipCode"
                                // autoFocus
                                className={classes.inputCustomWidth}
                                {...getFieldProps("zipCode")}
                                meta={getFieldMeta("zipCode")}
                                isSubmitted={submitCount > 0}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
                <Box className={classes.cardContentWrapper} mb={15}>
                  <TypographyMui variant="mov_subtitle1_bold" style={{ marginTop: "30px" }}>
                    Booking
                  </TypographyMui>

                  <BookingInfo
                    showSelectDurationErr={showSelectDurationErr}
                    showSelectStartDateErr={showSelectStartDateErr}
                    bookingData={profile}
                    onDateRangeChange={(data: ProfileInterface) => onBookingUpdate(data)}
                  />
                  <SelectCoach selectCoach={onSelectCoach} />
                  <Box
                    style={{ backgroundColor: "white" }}
                    width="100%"
                    pt={"30px"}
                    px={"100px"}
                    pb={"20px"}
                    display="flex"
                    justifyContent="center"
                    flexDirection="row"
                    position="fixed"
                    bottom={0}
                    left={0}
                    zIndex={1000}
                    className={classes.footerStyle}
                    // zIndex={1000}
                  >
                    <Box width="30%" textAlign="left">
                      <div className={classes.orderDetail}>order details:</div>
                      {getStringOrderDetail(profile)}
                    </Box>
                    <Box width="30%" textAlign="center" margin="auto">
                      <Box pt={1} width="100%" textAlign="center">
                        <ButtonMui
                          color="primary"
                          type="submit"
                          variant="contained"
                          size="small"
                          disabled={isSubmitting}
                          endIcon={
                            <ArrowForwardIcon
                              style={{
                                color: "#6D6D6D",
                              }}
                            />
                          }
                        >
                          Checkout
                        </ButtonMui>
                      </Box>
                    </Box>
                    <Box width="30%" textAlign="right">
                      <div className={classes.orderDetail}>Estimated Total:</div>
                      <div className={classes.orderTotal}>${totalAmount(profile)}</div>
                      <TypographyMui className={classes.orderNotes}>* The price hasn’t been included with tax and shipment fee.</TypographyMui>
                    </Box>
                  </Box>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
