import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Paper, Box, InputLabel, Button, Grid, MenuItem, Typography, FormControl, InputBase, IconButton, FormControlLabel } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import * as Yup from "yup";
import UserIcon from "components/icons/UserIcon";
import { TrashIcon } from "components/icons/TrashIcon/";
import { phoneRegExp } from "services/util/regex";
import history from "services/data/history";
import { updateUser, deleteUser } from "services/domain/admin/users";
import { getUserById } from "services/domain/admin/users";
import { useStyles } from "./styles";
import { UserRequestDefault } from "interfaces/user";
import { Toast } from "services/util/toast";
import { useConfirmation } from "../../components/dialog/ConfirmationService";
import SwitchIos from "components/_newDesign/switch/SwitchIos";

const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  role: Yup.string().required("Role is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  // address: Yup.string().required('Address is required'),
});

const initialValues = {
  email: "",
  role: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  address: "",
  visibility: false,
  paymentRate: 25,
  isEmployee: false,
};

const UserEdit = () => {
  const { id } = useParams<{ id: string }>();

  const [user, setUser] = useState({ ...initialValues });
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const classes = useStyles();
  const confirm = useConfirmation();

  const fetchUser = async () => {
    try {
      const result = await getUserById({ id });

      const {
        name: { firstName, lastName },
        role,
        email,
        phoneNumber,
        address,
        visibility,
        paymentRate,
        isEmployee,
      } = result.data;

      setUser({
        firstName,
        lastName,
        role,
        email,
        phoneNumber,
        address,
        visibility,
        paymentRate: paymentRate / 100 || 25,
        isEmployee,
      });
    } catch (e) {
      console.error("Error fetching user: ", e);
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: UserRequestDefault, { setSubmitting }: FormikHelpers<UserRequestDefault>) => {
    setSubmitting(true);
    try {
      const toast = Toast({ onClose: goBack });
      await updateUser({ idUser: id, ...values });

      toast
        .fire({
          icon: "success",
          title: "User record has been updated",
        })
        .then(() => {
          history.goBack();
        });
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Update user failed",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const onDeleteUser = async () => {
    setIsDeleting(true);
    try {
      const toast = Toast({ onClose: goBack });
      await deleteUser(id);

      toast.fire({
        icon: "success",
        title: "User has been deleted",
      });
    } catch (error) {
      const toast = Toast({});
      toast.fire({
        icon: "error",
        title: "Update user failed",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const onClickDelete = () => {
    confirm({
      variant: "danger",
      catchOnCancel: false,
      title: "Are you sure?",
      description: "Are you sure to delete this user?",
      confirmBtnText: "Delete",
    })
      .then(() => {
        onDeleteUser();
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <Paper className={classes.formContainer}>
      <Box maxWidth="430px" marginLeft="auto" marginRight="auto">
        <Formik enableReinitialize initialValues={user} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isSubmitting, values, getFieldProps }) => {
            return (
              <Form>
                <Grid container alignItems="center" justify="center" className={classes.titleContainer}>
                  <UserIcon />
                  <Typography component="span">&nbsp;&nbsp;&nbsp;Edit User Info</Typography>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" className={classes.deleteContainer}>
                  <IconButton color="primary" aria-label="upload picture" component="span" onClick={onClickDelete}>
                    <TrashIcon className={classes.buttonTrash} />
                  </IconButton>
                </Grid>
                <Grid container>
                  <Grid item md={5}>
                    <FormControl margin="normal" className={classes.formControl}>
                      <InputLabel>ID</InputLabel>
                      <InputBase value={id} disabled />
                    </FormControl>
                  </Grid>
                  <Grid item md={7}>
                    <Field
                      component={TextField}
                      name="role"
                      inputProps={{
                        id: "role",
                      }}
                      label="User Role"
                      select
                      margin="normal"
                      fullWidth
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="coach">Coach</MenuItem>
                      <MenuItem value="guest">Guest</MenuItem>
                      <MenuItem value="presenter">Presenter</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Field component={Checkbox} type="checkbox" name="isEmployee" label="Is a Movara employee" />}
                  label="Is a Movara employee"
                  name="isEmployee"
                />

                <Field component={TextField} type="text" label="First Name (*)" name="firstName" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Last Name (*)" name="lastName" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Email Address (*)" name="email" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Phone Number (*)" name="phoneNumber" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Address" name="address" margin="normal" fullWidth />

                {values.role === "coach" && (
                  <>
                    <Box>
                      <div className={classes.visibilityButton}>Visibility on Payment</div>
                      <Field
                        component={SwitchIos}
                        label={values.visibility ? "Enabled" : "Disabled"}
                        color="default"
                        style={{
                          color: values.visibility ? "#ffffff" : "#afafaf",
                        }}
                        id="visibility"
                        checked={values.visibility}
                        {...getFieldProps("visibility")}
                      />
                      <span>{values.visibility ? "Enabled" : "Disabled"}</span>
                    </Box>
                    <Box>
                      <Field
                        component={TextField}
                        type="number"
                        label="Payment Rate in USD ($)"
                        name="paymentRate"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          min: 0,
                          step: 0.01,
                        }}
                      />
                    </Box>
                  </>
                )}

                <Box mt={10}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || isDeleting}>
                    Update Account
                  </Button>
                  <Button onClick={goBack} className={classes.buttonCancel} disabled={isSubmitting || isDeleting}>
                    Cancel
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default UserEdit;
