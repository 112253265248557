import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowLeftIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 32 32"
            style={{
                color: props.color || 'black',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <g clip-path="url(#clip0_1083_23382)">
                <path
                    d="M26.6666 14.6663H10.4399L17.8933 7.21301L15.9999 5.33301L5.33325 15.9997L15.9999 26.6663L17.8799 24.7863L10.4399 17.333H26.6666V14.6663Z"
                    fill={props.fill || "#515151"}
                />
            </g>
            <defs>
                <clipPath id="clip0_1083_23382">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
