import {
    Box, Dialog,
    DialogContent,
    IconButton, makeStyles, Theme, Typography, createStyles
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import useStyles from '../styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { categories } from '../enum';
import { logDescriptionTemplate } from '../helper';
import moment from 'moment';
import DailyNutritionDoalAdmin from 'components/_modules/WeeklyAssessment/DailyNutritionDoalAdmin';


interface Props {
    show: boolean;
    onClose: () => void;
    data: any;
    startDate?: any;
}

const useStylesNew = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#FFFFFF',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
            borderRadius: '20px',
        },
        contentRoot: {
            padding: '0px !important',
        }
    })
);

const UserLogModal: FC<Props> = ({ show, data, onClose, startDate }) => {
    const classes = useStyles();
    const classesNew = useStylesNew();
    const categoriesRow = [
        { name: categories.MEALS, label: 'DAILY FOOD LOG' },
        { name: categories.PHOTO, label: 'DAILY PHOTO LOG' },
        { name: categories.STRETCH, label: 'DAILY STRETCH LOG' },
        { name: categories.FITNESS, label: 'DAILY FITNESS LOG' },
        { name: categories.PERSONAL_DEV, label: 'DAILY PERSONAL DEVELOPMENT LOG' },
    ];
    // eslint-disable-next-line
    const [modalCategories, setModalCategories] = useState(categoriesRow);
    const [showLog, setShowLog] = useState(0);
    const [descriptions, setDescriptions] = useState<[]>([]);
    const [task, setTask] = useState<any>({});

    useEffect(() => {
        if (show) {
            const { data: { allTask }, index } = data;

            const findDayTaskByIndex = allTask.find((task: any) => task.typesDaily === `day ${index}`);
            setTask(findDayTaskByIndex);

            const find: any = logDescriptionTemplate.find((item: any) => (item.name === modalCategories[showLog].name));
            setDescriptions(find.description);
        }
        // eslint-disable-next-line
    }, [show, showLog]);

    useEffect(() => {
        if (show) {
            const { category } = data;
            const showLogIndex = categoriesRow.findIndex((item) => item.name === category);
            setShowLog(showLogIndex);
        }
        // eslint-disable-next-line
    }, [show]);

    const isChecked = (type: string, isVideo: boolean) => {
        let status = false;
        if (task && task.hasOwnProperty(type)) {
            if (!isVideo && task[type].hasOwnProperty('isCheck')) {
                status = task[type].isCheck;
            } else if (isVideo && task[type].hasOwnProperty('watchVideo')) {
                status = task[type].watchVideo;
            }
        }
        return status;
    };

    const getComment = (type: string) => {
        let comment = '';
        if (task && task.hasOwnProperty(type) && task[type].hasOwnProperty('comments')) comment = task[type].comments;
        return comment;
    };


    return (
        <>
        {data.category === 'MEALS' ? (
            <Dialog
            fullWidth
            maxWidth={'sm'}
            open={show}
            onClose={onClose}
            classes={{ paper: classesNew.root}}
        >
            <DialogContent classes={{ root: classesNew.contentRoot}}>
                <DailyNutritionDoalAdmin data={data} startDate={startDate}/>
            </DialogContent>
        </Dialog>
        ) : (
            <Dialog onClose={() => onClose()}
                aria-labelledby="customized-dialog-title" open={show} fullWidth
                maxWidth={'sm'}>
                    <MuiDialogTitle disableTypography className={classes.modalRoot}>
                        <IconButton className={classes.modalPrevButton} onClick={() => setShowLog(showLog - 1)}
                                    disabled={showLog === 0}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <Box textAlign={'center'}>
                            <Typography variant="h6">{modalCategories[showLog].label}</Typography>
                        </Box>
                        <IconButton className={classes.modalNextButton} onClick={() => setShowLog(showLog + 1)}
                                    disabled={showLog === categoriesRow.length - 1}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent>
                        {!task ? (
                            <Box display={'flex'} height={'200px'} justifyContent={'center'} alignItems={'center'}>
                                <Typography gutterBottom color={'textSecondary'}>No Entries</Typography>
                            </Box>
                        ) : (
                            <>
                                <Box textAlign={'center'}
                                    mb={3}><Typography>{moment(task?.submissionDate).format('dddd, MMMM Do YYYY')}</Typography>
                                </Box>
                                {descriptions.map((item: any, index: number) => (
                                    <Box display={'flex'} key={index}>
                                        <Box display={'flex'} p={1}>
                                            <FiberManualRecordIcon
                                                color={isChecked(item?.type, item?.isVideo) ? 'primary' : 'disabled'}/>
                                        </Box>
                                        <Box flex="1" p={1}>
                                            <Typography gutterBottom>{item?.question}</Typography>
                                            <Typography gutterBottom
                                                        color={'textSecondary'}>{getComment(item?.type)}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </>
                        )}

                    </DialogContent>
                </Dialog>
        )}
        </>
        
    );
};
export default UserLogModal;