import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Waiting = lazy(() => import('./Waiting'));

export default {
    exact: true,
    path: routeConstants.WAITING.path,
    component: Waiting,
    layout: LayoutOptions.single,
};
