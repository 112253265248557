import React, { useState, useEffect } from 'react';
import { SignUpLayout } from 'components/layout/containers';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { http } from 'services/data';
import GetStarted from './component/GetStarted';
import StepOnBoarding from './component/StepOnBoarding';
import ContinueOnboarding from './ContinueOnboarding';
import Feedback from 'components/layout/Feedback';
import Auth from 'services/domain/auth';

const OnBoarding = () => {
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line
    const [status, setStatus] = useState<boolean>(false);
    const [getStarted, setGetStarted] = useState<boolean>(true);
    // const [profileSubmitted, setProfileSubmitted] = useState<boolean>(true);
    const [isCompleted, setIscompleted] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const classes = useStyles();
    const history = useHistory();
    const user = Auth.userProperties;

    const getOnboardingInfo = () => {
        setLoading(true);
        http.get('onboarding')
            .then((res) => {
                setData(res.data);

                if (
                    res.data.step1.bio !== undefined &&
                    res.data.step1.displayName !== undefined &&
                    res.data.step2 === null &&
                    res.data.step3 === null &&
                    user.userData.statusBooking === 'Waiting' &&
                    user.userData.statusFlow === '0'
                ) {
                    localStorage.setItem('temporaryProfileSubmit', 'true');
                    history.push('/waiting');
                }
                if (
                    res.data.step1.bio !== undefined &&
                    res.data.step1.displayName !== undefined &&
                    res.data.step2 !== null &&
                    res.data.step3 !== null
                ) {
                    localStorage.setItem('earlyAccess', 'true');
                    history.push('/waiting');
                }
            })
            .catch((e) => {
                setStatus(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChangeGetStarted = () => {
        setGetStarted(false);
    };

    const onCompleted = () => {
        setIscompleted(true);
    };

    useEffect(() => {
        getOnboardingInfo();
        // eslint-disable-next-line
    }, []);

    return (
        <div
            className={
                getStarted
                    ? ''
                    : isCompleted
                    ? classes.whenStepComplete
                    : user.userData.statusFlow !== '0'
                    ? classes.whenStepOn
                    : ''
            }
        >
            <SignUpLayout>
                {getStarted &&
                (user.userData.statusFlow === '0' ||
                    user.userData.statusFlow === '2') ? (
                    <GetStarted
                        loading={loading}
                        data={data}
                        changeStarted={onChangeGetStarted}
                    />
                ) : getStarted && user.userData.statusFlow === '1' ? (
                    <ContinueOnboarding
                        loading={loading}
                        data={data}
                        changeStarted={onChangeGetStarted}
                    />
                ) : (
                    <StepOnBoarding
                        onCompleted={onCompleted}
                        data={data}
                        onboardingInfo={getOnboardingInfo}
                    />
                )}
            </SignUpLayout>
            <Feedback />
        </div>
    );
};

export default OnBoarding;
