import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CloseIcon(props: SvgIconProps) {
    return (
        <SvgIcon
        viewBox="0 0 24 24"
            {...props}
            style={{
                color: 'white',
                width: props.width || '24px',
                height: props.height || '24px',
            }}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM16.4996 15.3241C16.4995 15.0123 16.3755 14.7133 16.155 14.4929L13.662 11.9999L16.155 9.50694C16.2641 9.39782 16.3507 9.26826 16.4098 9.12568C16.4688 8.98309 16.4992 8.83027 16.4992 8.67594C16.4992 8.52161 16.4688 8.36879 16.4098 8.22621C16.3507 8.08363 16.2641 7.95407 16.155 7.84494C16.0459 7.73582 15.9163 7.64925 15.7738 7.59019C15.6312 7.53113 15.4783 7.50073 15.324 7.50073C15.1697 7.50073 15.0169 7.53113 14.8743 7.59019C14.7317 7.64925 14.6021 7.73582 14.493 7.84494L12 10.3379L9.50602 7.84494C9.28301 7.6377 8.98832 7.52501 8.68393 7.53059C8.37954 7.53616 8.08918 7.65956 7.87391 7.87483C7.65864 8.0901 7.53524 8.38047 7.52966 8.68486C7.52409 8.98925 7.63677 9.28394 7.84402 9.50694L10.337 11.9999L7.84402 14.4929C7.62351 14.7135 7.49963 15.0126 7.49963 15.3244C7.49963 15.6363 7.62351 15.9354 7.84402 16.1559C7.95293 16.2655 8.08242 16.3524 8.22503 16.4117C8.36764 16.471 8.52057 16.5015 8.67502 16.5015C8.82947 16.5015 8.9824 16.471 9.12501 16.4117C9.26762 16.3524 9.39711 16.2655 9.50602 16.1559L12 13.6619L14.493 16.1559C14.7135 16.3763 15.0126 16.5001 15.3244 16.5C15.6362 16.4999 15.9351 16.376 16.1555 16.1554C16.3759 15.9349 16.4997 15.6359 16.4996 15.3241Z" fill="black" fillOpacity="0.1"/>
        </SvgIcon>
    );
}
