import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
        },
        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        boxTitle: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: theme.palette.primary.main,
        },
        contentPost: {
            // marginBottom: theme.spacing(3),
        },
        username: {
            fontWeight: 500,
        },
        gridHeader: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        grid: {
            marginBottom: theme.spacing(2),
        },
        boxBody: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        formControl: {
            width: '100%',
        },
        alertCss: {
            borderLeft: 'solid 5px',
            borderLeftColor: theme.palette.primary.main,
            marginTop: 20
        },
        buttonFilter: {
            backgroundColor: '#fff',
        },
        closeButton: {
            textAlign: 'right',
            color: theme.palette.grey[500],
            paddingRight: theme.spacing(2)
        },
        dialogClass: {
            // width: '500px',
        },
        labelFilter: {
            boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '7px',
            width: '400px',
            marginBottom: 5,
            marginLeft: 0,
            marginRight: 0
        },
        inputComment: {
            width: '100%',
            boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
            borderRadius: '7px',
            height: '72px'
        },
        loadingPost: {
            width: '100% !important'
        },
        dividerTopPost: {
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 20px rgba(0, 0, 0, 0.1)',
            height: '11px',
            width: '99%',
            margin: 'auto'
        },
        itemMenu: {
            justifyContent: "center"
        },
        avatarPost: {
            marginTop: '10px',
            marginRight: '15px',
        },
        uploadButton: {
            '& > *': {
              margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
        inputPost: {
            boxShadow: 'none',
            padd: '20px 20px 0px 20px',
            width: '100%',
            alignItems: 'start',
        },
        paperPost: {
            background: '#FFFFFF',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
        },
        comfeedTitle: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: '21px',
            letterSpacing: '0.05em',
        },
        comfeedTopPost: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.05em',

        },
        buttonStyle: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0.05em',
            textTransform: 'none',
            height: '35px',
            width: '92px',
            borderRadius: '7px',


        },
        textarea: {
            fontWeight: 600,
            resize: "vertical",
            width: "100%",
            border: "none",
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
            boxShadow: 'none',
            marginBottom: '10px',
            "&:focus": {
                outlineOffset: '0px',
                outlineColor: 'transparent'
            },
            '&::placeholder': {
                color: '#282828',
                opacity: 1
            }
        },
        inputClass: {
            '&::placeholder': {
                color: '#282828',
                opacity: 1
            }
        },
    }),
);
export default useStyles;
