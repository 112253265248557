import React, { FC } from 'react';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
// import { useStyles } from '../styles'

interface Props {
    data: {
        text: string,
        achieved: boolean
    }
}
const NoSnacking: FC<Props> = (props) => {
    // const classes = useStyles();
    
    return (
        <Box>
            <Box>
                <FormControlLabel
                    aria-label="Acknowledge"
                    checked={true}
                    control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                    label={
                        <Box display={'flex'} alignItems="center">
                            <SpoonForkIcon />
                            <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                I did not snack at all today
                            </Typography>
                        </Box>
                    }
                />
                <InputText
                    multiline
                    name="text"
                    placeholder="Write notes to your coach..."
                    style={{ width: '100%' }}
                    disabled={true}
                    value={props.data.text}
                    endAdornment={<Typography variant='caption'>{`${props.data.text.length}/255`}</Typography>}
                />
            </Box>
        </Box>
    );
};

export default NoSnacking;
