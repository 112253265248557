import { http } from 'services/data';
import { 
    CaloriesAmountRequest,
    NutritionGoalsResponse,
    BlanceMealsRequest,
    EliminateRequest,
    SnackingRequest,
    OthersRequest,
    WaterRequest,
    trackMacrosRequest,
    IntermittentRequest
} from 'interfaces/nutritionGoals';

const endPoints = {
    amountCalories: 'admin/assessments/ng/caloriesAmount',
    balanceMeals: 'admin/assessments/ng/balanceMeals',
    balanceEliminate: 'admin/assessments/ng/eliminate',
    snacking: 'admin/assessments/ng/snacking',
    others: 'admin/assessments/ng/others',
    water: 'admin/assessments/ng/water',
    trackMacros: 'admin/assessments/ng/trackMacros',
    intermittent: 'admin/assessments/ng/intermittentFasting',
};

export const postCaloriesAmount = async (data: CaloriesAmountRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.amountCalories, data)
export const postbalanceMeals = async (data: BlanceMealsRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.balanceMeals, data)
export const posteliminate = async (data: EliminateRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.balanceEliminate, data)
export const postSnacking = async (data: SnackingRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.snacking, data)
export const postOthers = async (data: OthersRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.others, data)
export const postWater = async (data: WaterRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.water, data)
export const postTrackMacros = async (data: trackMacrosRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.trackMacros, data)
export const postIntermittent = async (data: IntermittentRequest): Promise<NutritionGoalsResponse> => await http.post(endPoints.intermittent, data)