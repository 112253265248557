import React, { useState, createContext } from "react";

export interface ProfileInterface {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
  dateOfBirth: string | Date;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  startDate?: Date | string | moment.Moment;
  endDate?: Date | string | moment.Moment;
  nameOnCard: string;
  index: number;
  amount: number;
  perweek: number;
  password: string;
  coach_id: string;
  coachName: string;
  recurring?: boolean;
}

const initialProfileValue = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  gender: "male",
  dateOfBirth: "2021-07-25",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  startDate: undefined,
  endDate: undefined,
  nameOnCard: "",
  index: 1,
  amount: 150,
  perweek: 150,
  password: "",
  newPassword: "",
  newPassConfirm: "",
  coach_id: "",
  coachName: "",
};

export type ProfileContextType = {
  profile: ProfileInterface;
  setProfile: (profile: ProfileInterface) => void;
};

export type BookingEmailContextType = {
  bookingEmail: string;
  setBookingEmail: (bookingEmail: string) => void;
};

export type CurrentBookingType = {
  currentBooking: any;
  setCurrentBooking: (currentBooking: any) => void;
};

export const ProfileContext = createContext<ProfileContextType | null>(null);
export const BookingEmailContext = createContext<BookingEmailContextType | null>(null);
export const CurrentBookingContext = createContext<CurrentBookingType | null>(null);

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function PaymentStore({ children }: Props) {
  const [profile, setProfile] = useState<any>(initialProfileValue);
  const [bookingEmail, setBookingEmail] = useState("");
  const [currentBooking, setCurrentBooking] = useState<any>(null);

  return (
    <ProfileContext.Provider value={{ profile, setProfile }}>
      <BookingEmailContext.Provider value={{ bookingEmail, setBookingEmail }}>
        <CurrentBookingContext.Provider value={{ currentBooking, setCurrentBooking }}>{children}</CurrentBookingContext.Provider>
      </BookingEmailContext.Provider>
    </ProfileContext.Provider>
  );
}
