import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeline: {
            width: "100% !important",
            paddingLeft: 20,
            listStyle: 'none',
            '& li': {
                position: 'relative',
                padding: '10px 20px 10px 20px',
                '&::before': {
                    content: "''",
                    width: 10,
                    height: 10,
                    borderRadius: 50,
                    background: '#E5E5E5',
                    position: 'absolute',
                    left: 0,
                    top: 22,
                    transform: 'translateY(-40%)',
                },
                '&:not(:last-child)::after': {
                    content: "''",
                    width: 2,
                    height: "100%",
                    background: '#E5E5E5',
                    position: 'absolute',
                    left: 4,
                    top: '50%'
                }
            },
        },
        timelineTimeLabel:{
            fontSize: 12,
            component: 'subtitle'
        },
        cardHeader: {
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '14px',
            letterSpacing: '0.02em'
        },
        earnPointsHeader: {
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: '0.25px',
            paddingLeft: '3px',
        },
        pointsValue: {
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: '36px',
            lineHeight: '43.2px',
            letterSpacing: '1px',
        },
        pointsLabel: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20.8px',
            letterSpacing: '0.15px',
        },
        cardHeaderBox: {
            height: '36px',
            backgroundColor: '#E6E6E6'
        }

    }),
);
export default useStyles;
