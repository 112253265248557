import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@material-ui/core";
import useStyles from "./styles";
import DetailModal from "./DetailModal";
import { CoachProfil } from "interfaces/user";
import { getListCoach, getListCoachAll } from "services/domain/users";
import { VerifiedUserIcon, SmileIcon } from "components/_newIcon";
import Typography from "components/_newDesign/typography/Typography";
import CoachModal from "./CoachModal";
import convertTimeToUserTZ from "services/util/convertTimeToUserTZ";

const SelectCoach: React.FC<any> = (props) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showAllCoach, setShowAllCoach] = useState(false);
  const [listCoach, setListCoach] = useState<CoachProfil[]>([]);
  const [listedThreeCoach, setListedThreeCoach] = useState<CoachProfil[]>([]);
  const [selectedCoach, setSelectedCoach] = useState<CoachProfil>();
  const [selectedId, setSelectedId] = useState<string>();

  const getCoaches = async () => {
    try {
      const rspn = props.edit ? await getListCoachAll() : await getListCoach();

      setListCoach(rspn.data);
      setListedThreeCoach(rspn.data);
    } catch (error) {
    } finally {
    }
  };

  const onClickModal = (coach) => {
    setSelectedCoach(coach);
    setShowModal(true);
  };
  const onSelectModalCoach = (data: CoachProfil) => {
    const checkCode = listedThreeCoach.filter((coach) => coach._id === data._id);
    if (checkCode.length !== 0) {
      setSelectedId(data._id);
      props.selectCoach(data);
    } else {
      const coachthree = listedThreeCoach;
      coachthree[listedThreeCoach.length - 1] = data;
      setListedThreeCoach(coachthree);
      setSelectedId(data._id);
      props.selectCoach(data);
    }
    setShowAllCoach(false);
  };

  useEffect(() => {
    getCoaches();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      const coach = listCoach.filter((data) => data._id === props.defaultValue);

      props.selectCoach(coach.length === 0 ? listCoach[0] : coach[0]);
      setSelectedId(coach.length === 0 ? listCoach[0]._id : coach[0]._id);
    }
  }, [listCoach]);
  return (
    <>
      <Typography
        variant="mov_overline2"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <VerifiedUserIcon /> SELECT COACH
      </Typography>
      <Box mt="14px" maxWidth={"600px"} margin="auto" display={"flex"} flexDirection="column">
        <Box className={classes.coachBox}>
          {listedThreeCoach.map((item, index) => (
            <Box
              className={classes.coachCard}
              key={item._id + index}
              onClick={() => onClickModal(item)}
              style={{
                backgroundColor: selectedId === item._id ? "#ffa21e" : "white",
              }}
            >
              <Box className={classes.coachAvatarBox}>
                <Avatar style={{ margin: "auto" }} alt={item.name} src={item?.avatar} className={classes.coachAvatar} />
                <Typography variant="mov_body1">{item?.name}</Typography>
              </Box>
              {item.zoomCall && item.zoomCall !== "" ? (
                <Box width={"100%"} textAlign="center">
                  <Typography variant="mov_overline2">CALL SCHEDULE</Typography>
                  <Box className={classes.coachSchedule}>
                    <b>{convertTimeToUserTZ(item.zoomCall?.zoomCall1?.zoomCallTime, item.zoomCall?.zoomCall1?.zoomCallDay).convertedDay}</b>
                    &nbsp;
                    {convertTimeToUserTZ(item.zoomCall?.zoomCall1?.zoomCallTime, item.zoomCall?.zoomCall1?.zoomCallDay).convertedTime}
                  </Box>
                  <Box marginTop={"2px"} className={classes.coachSchedule}>
                    <b>{convertTimeToUserTZ(item.zoomCall?.zoomCall2?.zoomCallTime, item.zoomCall?.zoomCall2?.zoomCallDay).convertedDay}</b>
                    &nbsp;
                    {convertTimeToUserTZ(item.zoomCall?.zoomCall2?.zoomCallTime, item.zoomCall?.zoomCall2?.zoomCallDay).convertedTime}
                  </Box>
                  <Box marginTop={"2px"} className={classes.coachSchedule}>
                    <b>{convertTimeToUserTZ(item.zoomCall?.zoomCall3?.zoomCallTime, item.zoomCall?.zoomCall3?.zoomCallDay).convertedDay}</b>
                    &nbsp;
                    {convertTimeToUserTZ(item.zoomCall?.zoomCall3?.zoomCallTime, item.zoomCall?.zoomCall2?.zoomCallDay).convertedTime}
                  </Box>
                </Box>
              ) : (
                <Box width={"100%"} textAlign="center">
                  <Typography variant="mov_overline2">CALL SCHEDULE</Typography>
                  <Box className={classes.coachSchedule}>
                    <b>Sun</b>&nbsp;6.30 pm (MST)
                  </Box>
                  <Box marginTop={"2px"} className={classes.coachSchedule}>
                    <b>Sun</b>&nbsp;6.30 pm (MST)
                  </Box>
                  <Box marginTop={"2px"} className={classes.coachSchedule}>
                    <b>Sun</b>&nbsp;6.30 pm (MST)
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          mt="8px"
          width={"100%"}
          padding="12px"
          borderRadius={"10px"}
          border="1px solid rgba(0, 0, 0, 0.1)"
          textAlign={"center"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            const number = Math.floor(Math.random() * listedThreeCoach.length);
            setSelectedId(listedThreeCoach[number]._id);
            props.selectCoach(listedThreeCoach[number]);
          }}
        >
          <SmileIcon />
          <Typography style={{ fontWeight: 700 }} variant="mov_subtitle2_regular">
            I don’t mind
          </Typography>
          <Typography variant="mov_body1">Pick me any coach...</Typography>
        </Box>
        <Box
          mt="8px"
          width={"100%"}
          padding="12px"
          borderRadius={"10px"}
          border="1px solid rgba(0, 0, 0, 0.1)"
          textAlign={"center"}
          style={{ cursor: "pointer" }}
          onClick={() => setShowAllCoach(true)}
        >
          <Typography variant="mov_button1_semibold">See Coach List</Typography>
        </Box>
      </Box>
      <DetailModal
        data={selectedCoach}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSelect={(data) => {
          setSelectedId(data?._id);
          setShowModal(false);
          props.selectCoach(data);
        }}
      />
      <CoachModal show={showAllCoach} onClose={() => setShowAllCoach(false)} data={listCoach} onSelelect={(coach) => onSelectModalCoach(coach)} />
    </>
  );
};

export default SelectCoach;
