import React from "react";
import { CardHeader, Avatar, Typography, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";

import dummyAvatar from "assets/images/dummy-avatar-256.png";
import MChip from "components/MChip";

const useStyles = makeStyles((theme: Theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  statusWrapper: {
    marginTop: theme.spacing(1),
  },
}));

interface SubHeaderProps {
  email: string;
  statusBooking: string;
  beenGuest: boolean;
}

const SubHeader = ({ email, beenGuest, statusBooking }: SubHeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography>{email}</Typography>
      <Grid container spacing={1} className={classes.statusWrapper}>
        {beenGuest !== undefined && beenGuest !== null && (
          <Grid item>
            <MChip label={beenGuest ? "RECURRING" : "NEW"} variant="outlined" color="primary" />
          </Grid>
        )}
        {statusBooking && (
          <Grid item>
            <MChip label={_.upperCase(statusBooking)} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const UserNameCell = ({ value, row }: { value: any; row: any }) => {
  const classes = useStyles();
  const {
    original: { avatar, ...otherRowProps },
  } = row;

  return (
    <CardHeader
      title={`${value.firstName} ${value.lastName}`}
      subheader={<SubHeader {...otherRowProps} />}
      avatar={<Avatar src={avatar?.path ?? dummyAvatar} className={classes.small} />}
    />
  );
};

export default UserNameCell;
