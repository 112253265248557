/**
 * Currently is not possible to use linear progress, because we cant defer the unmount as per this note made. The needs is set the progress to 100% before unmounted.
 */

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import ProgressLinear from 'components/progress/Linear';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center', // if possible to switch to progress linear remove align items config
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const LayoutProgress: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress color="primary" />
        </div>
    );
};

export default LayoutProgress;
