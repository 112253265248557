/**
 * @description Generates a CSV file from the given data and headers.
 *
 * @param headers an array of strings that will be used as the headers of the CSV file.
 * @param data  an array of arrays that will be used as the data of the CSV file.
 * @param filename the name of the file that will be generated.
 */

export default function generateCSV(headers: string[], data: any[], filename: string) {
  try {
    const csvContent = "data:text/csv;charset=utf-8," + headers.join(",") + "\n" + data.join("\n"); // rows

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".

    link.remove();
  } catch (error) {
    console.error("Error in generateCSV.ts: ", error);

    throw new Error("Failed to generate CSV file.");
  }
}
