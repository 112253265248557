import React, { FC, useState, Suspense } from 'react';
import {
    Box, Button,
    Grid,
    Paper, Tab, Tabs,
    Typography,
} from '@material-ui/core';
import useStyles from './styles';
import DateRangePickerInput from '../../../components/controls/DateRangePicker';
import { DateRange } from 'materialui-daterange-picker';
import { getThisWeekRange } from '../../../helper/Date';
import Loading from '../../../components/progress/Loading';

const VideoList = React.lazy(() => import('./VideoList'));
const CourseList = React.lazy(() => import('./CourseList'));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export enum tabVideoTypes{
    COURSE= 'course',
    COOKING = 'cooking',
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography gutterBottom component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface userListInterface {
    createdDate: Date
    email: string
    lastLogin: Date
    name: { firstName: string, lastName: string }
    phoneNumber: number
    statusBooking: null
}

const Video: FC = () => {
    const [selectedList, setSelectedList] = React.useState({});
    const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>(getThisWeekRange());
    const classes = useStyles();
    const [tabIndex, setTabIndex] = React.useState<string>(tabVideoTypes.COURSE);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setTabIndex(newValue);
        setSelectedList({
            rating: 0,
            title: '',
            vimeoId: '',
        });
    };

    const handleSelectedList = (data:any) => {
        setSelectedList(data);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Box my={2}><Typography gutterBottom variant="h4">Video Performance</Typography></Box>
                </Grid>

                <Grid item md={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Tabs className={classes.tabHeader} value={tabIndex} onChange={handleChange}
                              indicatorColor="primary" centered>
                            <Tab value={tabVideoTypes.COURSE} label="COURSE VIDEOS" {...a11yProps(tabVideoTypes.COURSE)} />
                            <Tab value={tabVideoTypes.COOKING} label="COOKING VIDEOS" {...a11yProps(tabVideoTypes.COOKING)} />
                        </Tabs>

                        <TabPanel value={tabIndex} index={tabVideoTypes.COURSE}>
                            <Box display="flex" alignItems="center">
                                <Box mr={2} alignItems="center">
                                    <Button variant="contained" color="primary">BY THIS WEEK</Button>
                                </Box>
                                <Box boxShadow={2} px={2} py={'2px'} display="flex" alignItems="center">
                                    <Box mr={3}>
                                        <Typography gutterBottom variant={'button'}>BY DURATION</Typography>
                                    </Box>
                                    <Box>
                                        <DateRangePickerInput
                                            onChange={(date) => {
                                                setDateRangeFilter(date);
                                            }}
                                            value={dateRangeFilter}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={tabVideoTypes.COOKING}>
                            <Box display="flex" alignItems="center">
                                <Box mr={2} alignItems="center">
                                    <Button variant="contained" color="primary">BY THIS WEEK</Button>
                                </Box>
                                <Box boxShadow={2} px={2} py={'2px'} display="flex" alignItems="center">
                                    <Box mr={3}>
                                        <Typography gutterBottom variant={'button'}>BY DURATION</Typography>
                                    </Box>
                                    <Box>
                                        <DateRangePickerInput
                                            onChange={(date) => {
                                                setDateRangeFilter(date);
                                            }}
                                            value={dateRangeFilter}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </TabPanel>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <Suspense fallback={<Loading/>}>
                        <VideoList onSelected={handleSelectedList} tabIndex={tabIndex}/>
                    </Suspense>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                    <Suspense fallback={<Loading/>}>
                        <CourseList selectedList={selectedList} tabIndex={tabIndex}/>
                    </Suspense>
                </Grid>
            </Grid>
        </>
    );
};
export default Video;
