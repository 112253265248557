import React, { FC } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Grid,
    Typography,
    Paper,
    Box
} from '@material-ui/core';
import useStyles from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    ThumbIcon,
    CommentIcon,
    PostIcon,
    InfoIcon,
} from 'components/icons';

const Feeds: FC<any> = props => {
    const classes = useStyles();

    return (
        <Grid item md={12}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    className={classes.boxHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={{ fontWeight: 600}}>OVERVIEW COMMUNITY PAGE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item md={4} xs={12} sm={6}>
                            <Paper className={classes.paper} elevation={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.header}>TOTAL POST</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Box position="relative" top={7}>
                                            <PostIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography align="right">
                                            <Typography component="span"
                                                        variant="h4">{props.data?.totalPost ? props.data?.totalPost : 0}</Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <Paper className={classes.paper} elevation={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.header}>TOTAL COMMENTS</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Box position="relative" top={7}>
                                            <CommentIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography align="right">
                                            <Typography component="span"
                                                        variant="h4"><Typography align='right' variant="h4">{props.data?.totalComments ? props.data?.totalComments : 0}</Typography></Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <Paper className={classes.paper} elevation={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.header}>TOTAL LIKES</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Box position="relative" top={7}>
                                            <ThumbIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography align='right' variant="h4">{props.data?.totalLikes ? props.data?.totalLikes : 0}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <Paper className={classes.paper} elevation={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.header}>REPORTED POST(S)</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Box position="relative" top={7}>
                                            <InfoIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography align="right">
                                            <Typography component="span"
                                                        variant="h4"><Typography align='right' variant="h4">{props.data?.reportedPost ? props.data?.reportedPost : 0}</Typography></Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={4} xs={12} sm={6}>
                            <Paper className={classes.paper} elevation={3}>
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.header}>FEATURED TOP POST(S)</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Box position="relative" top={7}>
                                            <InfoIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography align="right">
                                            <Typography component="span"
                                                        variant="h4">{props.data?.featureTopPost ? props.data?.featureTopPost : 0}</Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
export default Feeds;
