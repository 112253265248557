export enum categories {
    MEALS = 'MEALS',
    PHOTO = 'PHOTO',
    STRETCH = 'STRETCH',
    FITNESS = 'FITNESS',
    PERSONAL_DEV = 'PERSONAL DEV',
}

export enum taskTypes {
    CURRENT_WEIGHT = 'currentWeight',
    GOAL_WEIGHT = 'goalWeight',
}