import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function FlagIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path d="M25.9934 22.6443H0.570829C0.284437 22.6443 0.052002 22.4343 0.052002 22.1755V1.82428C0.052002 1.56553 0.284437 1.35553 0.570829 1.35553H25.9934C26.2798 1.35553 26.5122 1.56553 26.5122 1.82428V22.1751C26.5122 22.4343 26.2798 22.6443 25.9934 22.6443ZM1.08966 21.7068H25.4745V2.29303H1.08966V21.7068Z" fill="#FFA21E"/>
            <path d="M19.8556 22.6444H0.570829C0.284437 22.6444 0.052002 22.4344 0.052002 22.1756V14.415C0.052002 14.3076 0.0929893 14.2036 0.1677 14.1201L6.47405 7.07997C6.57263 6.96982 6.72101 6.90607 6.87718 6.90607C6.87821 6.90607 6.87873 6.90607 6.87977 6.90607C7.03749 6.907 7.18588 6.97216 7.28394 7.08372L14.3182 15.0994L20.2692 21.8794C20.4404 22.0744 20.4129 22.3561 20.2069 22.5211L20.2001 22.5267C20.1047 22.6022 19.9822 22.6444 19.8556 22.6444ZM1.08966 21.7069H18.7936L13.5047 15.6811L6.87303 8.12435L1.08966 14.5809V21.7069Z" fill="#FFA21E"/>
            <path d="M25.993 22.6443H19.8553C19.6607 22.6443 19.4849 22.5464 19.3961 22.3936L13.5044 15.6811C13.3534 15.509 13.3549 15.2658 13.5075 15.0951L17.6638 10.4541C17.7624 10.3439 17.9107 10.2802 18.0669 10.2802C18.068 10.2802 18.0685 10.2802 18.0695 10.2802C18.2272 10.2811 18.3756 10.3462 18.4737 10.4578L26.3993 19.4887C26.4719 19.5717 26.5113 19.6743 26.5113 19.7798V22.1751C26.5118 22.4343 26.2794 22.6443 25.993 22.6443ZM20.1173 21.7068H25.4742V19.9434L18.0633 11.4989L14.5757 15.3928L20.1173 21.7068Z" fill="#FFA21E"/>
        </SvgIcon>
    );
};
