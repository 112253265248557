import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    formTitle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },
    paperMobile: {
        height: 'calc(100vh - 70px)',
        overflow: 'scroll',
        background: '#E5E5E5'
    },
    viewMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    viewDesktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));
