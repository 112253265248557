import React, { FC } from 'react';
import { makeStyles, TextareaAutosize, Theme, Typography } from '@material-ui/core';
import { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    textAreaMov: {
        padding: '12px 16px',
        minHeight: '64px',
        background: '#FFFFFF',
        boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        minWidth:'100%',
        border: 'none',
        fontWeight: 600,
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        '&::placeholder': {
            color: '#282828',
            opacity: 1,
            fontWeight: 500
        }
    },
    disableClass: {
        background: '#F2F2F2',
        boxShadow: 'none',
    },
    inputClass: {
        '&::placeholder': {
            color: '#282828',
            opacity: 1,
            fontWeight: 500
        }
    },
}));

const InputTextArea: FC<Props & TextareaAutosizeProps> = React.forwardRef(({ meta, isSubmitted, placeholder, ...props }, ref) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <TextareaAutosize
                ref={ref}
                {...props}
                aria-label="minimum height"
                placeholder={placeholder} 
                className={`${classes.textAreaMov} ${props.disabled ? classes.disableClass : ''}`}/>
            {meta?.touched && meta?.error && (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            )}
        </React.Fragment>
    );
});

export default InputTextArea;
