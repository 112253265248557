import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function EditIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 33"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <path
                d="M14.6666 5.33301H5.33329C4.62605 5.33301 3.94777 5.61396 3.44767 6.11406C2.94758 6.61415 2.66663 7.29243 2.66663 7.99967V26.6663C2.66663 27.3736 2.94758 28.0519 3.44767 28.552C3.94777 29.0521 4.62605 29.333 5.33329 29.333H24C24.7072 29.333 25.3855 29.0521 25.8856 28.552C26.3857 28.0519 26.6666 27.3736 26.6666 26.6663V17.333"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.6666 3.33331C25.1971 2.80288 25.9165 2.50488 26.6666 2.50488C27.4168 2.50488 28.1362 2.80288 28.6666 3.33331C29.1971 3.86374 29.4951 4.58316 29.4951 5.33331C29.4951 6.08346 29.1971 6.80288 28.6666 7.33331L16 20L10.6666 21.3333L12 16L24.6666 3.33331Z"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
