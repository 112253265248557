import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function VerifiedUserIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            fill="none"
            viewBox="0 0 24 24"
            {...props}
            style={{
                color: 'transparent',
                width: props.width || '24px',
                height: props.height || '24px',
            }}
        >
            <rect x="1" y="1" width="22" height="22" rx="11" fill="#FFA11E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11.9897C6 8.4219 8.56653 5.33331 12.1655 5.33331C13.7845 5.33331 14.9751 5.75992 15.8683 6.39287C16.6701 6.96103 17.1605 7.65398 17.4492 8.06184C17.4703 8.09171 17.4904 8.12005 17.5094 8.1467L15.3387 9.69567C15.3284 9.68123 15.3182 9.66686 15.308 9.65257C15.0045 9.22694 14.7481 8.86736 14.3265 8.56866C13.9236 8.28311 13.2828 7.99998 12.1655 7.99998C10.2917 7.99998 8.66667 9.62887 8.66667 11.9897C8.66667 14.2732 10.5514 16 12.2979 16C13.3009 16 14.0742 15.7087 14.6046 15.3617C14.8722 15.1867 15.0713 15.0018 15.2054 14.8411C15.2721 14.7612 15.3183 14.6925 15.3483 14.6399C15.3768 14.5898 15.3857 14.5629 15.3857 14.5629L17.9478 15.3023C17.6982 16.1677 16.9718 16.9997 16.0644 17.5933C15.111 18.217 13.8324 18.6666 12.2979 18.6666C8.96972 18.6666 6 15.6346 6 11.9897Z"
                fill="white"
            />
            <rect
                x="1"
                y="1"
                width="22"
                height="22"
                rx="11"
                stroke="white"
                strokeWidth="2"
            />
        </SvgIcon>
    );
}
