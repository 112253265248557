import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';

const DurationCell = ({ value, row }: { value: any, row: any }) => {
    return (
        <Box textAlign="center">
            <div
                style={{
                    margin: 'auto',
                    width: '68px',
                    height: '29px',
                    background: '#FAFAFA',
                    boxShadow:
                        'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '7px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '150%',
                    letterSpacing: '0.05em',
                    color: '#000000',
                }}
            >
                <span
                    style={{ fontWeight: 600 }}
                >
                    {moment().diff(
                        row?.original?.startDate,
                        'week'
                    ) > 0 &&
                    moment().diff(
                        row?.original?.startDate,
                        'week'
                    ) <=
                        moment(
                            row?.original?.endDate
                        ).diff(
                            row?.original?.startDate,
                            'weeks'
                        )
                        ? moment().diff(
                            row?.original?.startDate,
                                'week'
                            ) + 1
                        : '0'}{' '}
                    &nbsp;
                </span>{' '}
                <span
                    style={{ fontWeight: 400 }}
                >
                    {' '}
                    /{' '}
                    {moment(
                        row?.original?.endDate
                    ).diff(
                        row?.original?.startDate,
                        'weeks'
                    )}{' '}
                </span>
                <span
                    style={{ fontWeight: 300 }}
                >
                    &nbsp;W
                </span>
            </div>
            <div
                style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '9px',
                    lineHeight: '150%',
                    letterSpacing: '0.05em',
                    color: '#000000',
                }}
            >
                {moment(
                    row?.original?.startDate
                ).format('MMM DD,YYYY')}
            </div>
            <div
                style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '9px',
                    lineHeight: '150%',
                    letterSpacing: '0.05em',
                    color: '#000000',
                }}
            >
                -
            </div>
            <div
                style={{
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '9px',
                    lineHeight: '150%',
                    letterSpacing: '0.05em',
                    color: '#000000',
                }}
            >
                {moment(row?.original?.endDate).format(
                    'MMM DD,YYYY'
                )}
            </div>
        </Box>
    );
};

export default DurationCell;
