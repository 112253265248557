import React from 'react';
import { Box } from '@material-ui/core';
import Typography from 'components/_newDesign/typography/Typography';
import moment from 'moment';

interface Props {
    status: string;
    startDate: Date | string;
    endDate: Date | string;
    totalWeeks?: number;
    weekOngoing?: number;
}
const StatusBooking: React.FC<Props> = ({
    status,
    startDate,
    endDate,
    totalWeeks,
    weekOngoing,
}) => {
    switch (status.toLowerCase()) {
        case 'active':
            return (
                <>
                    <Box
                        p="1px 8px"
                        borderRadius="20px"
                        style={{ background: '#FFFFFF' }}
                        display="flex"
                        alignItems={'center'}
                        border="2px solid #FFA11E"
                    >
                        <Typography variant="mov_body2">
                            <b>{weekOngoing}</b>{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                /
                            </span>{' '}
                            {totalWeeks}{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                w
                            </span>
                        </Typography>
                    </Box>
                    <Typography
                        variant="mov_caption1"
                        style={{ marginTop: '8px' }}
                    >
                        {`${moment(startDate).format('MMM/DD/YY')} - ${moment(
                            endDate
                        ).format('MMM/DD/YY')}`}
                    </Typography>
                </>
            );
        case 'waiting':
            return (
                <>
                    <Box
                        p="1px 8px"
                        borderRadius="20px"
                        style={{ background: '#FFFFFF' }}
                        display="flex"
                        alignItems={'center'}
                        border="2px solid #FFA11E"
                    >
                        <Typography variant="mov_body2">
                            <b>{weekOngoing}</b>{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                /
                            </span>{' '}
                            {totalWeeks}{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                w
                            </span>
                        </Typography>
                    </Box>
                    <Typography
                        variant="mov_caption1"
                        style={{ marginTop: '8px' }}
                    >
                        {`${moment(startDate).format('MMM/DD/YY')} - ${moment(
                            endDate
                        ).format('MMM/DD/YY')}`}
                    </Typography>
                </>
            );
        case 'inactive':
        case 'pause':
            return (
                <>
                    <Box
                        p="1px 8px"
                        borderRadius="20px"
                        style={{ background: 'rgba(0, 0, 0, 0.1)' }}
                        display="flex"
                        alignItems={'center'}
                    >
                        <Typography variant="mov_body2">
                            {weekOngoing}{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                /
                            </span>{' '}
                            {totalWeeks}{' '}
                            <span
                                style={{
                                    color: '#9A9A9A',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                }}
                            >
                                w
                            </span>
                        </Typography>
                    </Box>
                    <Typography
                        variant="mov_caption1"
                        style={{ marginTop: '8px' }}
                    >
                        {`${moment(startDate).format('MMM/DD/YY')} - ${moment(
                            endDate
                        ).format('MMM/DD/YY')}`}
                    </Typography>
                </>
            );

        default:
            return <Box></Box>;
    }
};

export default StatusBooking;
