import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  AddCircleIcon,
  BagIcon,
  CommentIcon,
  DashboardIcon,
  GraphicIcon,
  InfoIcon,
  NewspaperIcon,
  NotesIcon,
  PlayCircleIcon,
  SearchIcon,
  UserIcon,
  PromoCodeIcon,
} from "components/icons";
import { useStyles } from "./styles";
import { getTokenPayload } from "services/util";
import AccountingDropdown from "./AccountingDropdown";

export default function Navigation() {
  const classes = useStyles();
  const userLoggedRole = getTokenPayload("role");

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.rootList}>
      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon className={classes["icon-menu"]} />
        </ListItemIcon>
        <ListItemText primary="DASHBOARD" />
      </ListItem>
      <ListItem button component={Link} to="/reports/assessment">
        <ListItemIcon>
          <NotesIcon className={classes["icon-menu"]} />
        </ListItemIcon>
        <ListItemText primary="ACCOUNTABILITY" />
      </ListItem>
      <ListItem button component={Link} to="/reports/weekly-program">
        <ListItemIcon>
          <GraphicIcon className={classes["icon-menu"]} />
        </ListItemIcon>
        <ListItemText primary="WEEKLY PROGRAM" />
      </ListItem>
      <ListItem button component={Link} to="/plan-program">
        <ListItemIcon>
          <AddCircleIcon className={classes["icon-menu"]} />
        </ListItemIcon>
        <ListItemText primary="PLAN PROGRAM" />
      </ListItem>
      {["admin"].includes(userLoggedRole) && (
        <>
          {/* start manage */}
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <UserIcon className={classes["icon-menu"]} />
            </ListItemIcon>
            <ListItemText primary="MANAGE USERS" />
          </ListItem>
          <ListItem button component={Link} to="/bookings">
            <ListItemIcon>
              <BagIcon className={classes["icon-menu"]} />
            </ListItemIcon>
            <ListItemText primary="MANAGE BOOKINGS" />
          </ListItem>

          <ListItem button component={Link} to="/videos">
            <ListItemIcon>
              <PlayCircleIcon className={classes["icon-menu"]} />
            </ListItemIcon>
            <ListItemText primary="MANAGE VIDEOS" />
          </ListItem>
          <ListItem button component={Link} to="/community-feeds">
            <ListItemIcon>
              <NewspaperIcon className={classes["icon-menu"]} />
            </ListItemIcon>
            <ListItemText primary="COMMUNITY FEEDS" />
          </ListItem>
          <ListItem button component={Link} to="/promo-code">
            <ListItemIcon>
              <PromoCodeIcon className={classes["icon-menu"]} />
            </ListItemIcon>
            <ListItemText primary="PROMOTION CODE" />
          </ListItem>

          <AccountingDropdown />
          {/* end manage */}
        </>
      )}
      {["coach"].includes(userLoggedRole) && (
        <ListItem button component={Link} to="/bookings">
          <ListItemIcon>
            <BagIcon className={classes["icon-menu"]} />
          </ListItemIcon>
          <ListItemText primary="MANAGE BOOKINGS" />
        </ListItem>
      )}
      {/* start reports */}
      <ListItem button component={Link} to="/reports/guest">
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary="GUEST" />
      </ListItem>
      <ListItem button component={Link} to="/reports/video">
        <ListItemIcon>
          <CommentIcon withDot />
        </ListItemIcon>
        <ListItemText primary="VIDEO" />
      </ListItem>
      {/* end reports */}
      <ListItem button component={Link} to="/support">
        <ListItemIcon>
          <InfoIcon className={classes["icon-menu"]} />
        </ListItemIcon>
        <ListItemText primary="SUPPORT" />
      </ListItem>
    </List>
  );
}
