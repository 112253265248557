// @ts-nocheck
import React, { FC } from 'react';
import Input, { InputProps } from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Styled } from 'components/controls/MInput';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
}

const StyledInput = Styled(Input);

const MInputPassword: FC<Props & InputProps> = ({ meta, isSubmitted, type, margin, ...props }) => {
    const [isShowPassword, setShowPassword] = React.useState(false);
    let errorText = '';
    if ( meta && meta.error && meta.touched) {
        errorText = meta.error;
    }
    let error = !!errorText;

    if ('isSubmitted' !== undefined) {
        error = error && isSubmitted!;
        
        if (!isSubmitted) {
            errorText = '';
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!isShowPassword);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl {...{ fullWidth: props.fullWidth, margin}} >
            <StyledInput
                {...props}
                type={isShowPassword ? 'text': 'password'}
                helperText={errorText}
                error={error}
                InputLabelProps={{
                    shrink: true,
                }}
                disableUnderline
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {isShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

MInputPassword.defaultProps = {
    fullWidth: true,
    margin: 'normal',
};

export default MInputPassword;
