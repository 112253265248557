import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(( theme: Theme ) =>
    createStyles({
        'select_label': {
            fontSize: theme.spacing(2),
        }
    })
);

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 32px;
`;
