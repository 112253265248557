import React from 'react';
import {
    Switch,
    Link as RouterLink,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { Box, Grid, Paper, Typography, Link } from '@material-ui/core';

import PrivateRoute  from 'components/core/PrivateRoute'
import { BigAvatar } from 'components/layout/Avatar';
import NavAccount from './_components/NavAccount';
import NavAccountMobile from './_components/NavAccountMobile';

import { routeConstants } from './_constant';
import { routes } from './_config';
import { useProfile } from 'services/util';
import { useStyles } from './styles';
import ImageModal from 'components/modal/ImageModal';

const AccountLayout = () => {
    const profile = useProfile();
    const location = useLocation();
    const classes = useStyles();
    const [image, setImage] = React.useState<string>('');
    const [show, setShow] = React.useState<boolean>(false);

    const findPageMeta = () => {
        return Object.values(routeConstants).find(route => route.path === location?.pathname);
    }

    const pageTitle = findPageMeta()?.name;
    const handleClickImage = (image) => {
        if (image && image !== ''){
            setImage(image)
            setTimeout(() => {
                setShow(true)
            }, 100);
        } 
    }
    return (
        <>
            <ImageModal image={image} show={show} onClose={() => setShow(false)}/>
            <Box mx="auto" my={15} maxWidth={900} className={classes.viewDesktop}>
                <Paper>
                    <Box p={7}>
                        <Grid container spacing={0}>
                            <Grid item md={3} style={{paddingRight:'30px'}}>
                                <Typography style={{paddingRight:'30px'}} variant="h6" align="center">
                                    {pageTitle}
                                </Typography>

                                <Box mt={5} display="flex" justifyContent="center">
                                    <BigAvatar src={profile?.avatar?.path} onClick={() => handleClickImage(profile?.avatar?.path)}/>
                                </Box>

                                <Box mt={5} style={{paddingRight:'30px'}}>
                                    <Typography variant="h6" align="center">
                                        User Profile
                                    </Typography>
                                </Box>

                                <Box m={0} style={{paddingRight:'30px'}}>
                                    <Typography variant="subtitle2" align="center">
                                        <Link component={RouterLink} to="/profile">
                                            View Profile
                                        </Link>
                                    </Typography>
                                </Box>

                                <Box mt={5}>
                                    <NavAccount />
                                </Box>
                            </Grid>
                            <Grid item md={9}>
                                <React.Suspense fallback={<h1>Loading...</h1>}>
                                    <Switch>
                                        {routes.map((route, i) => (
                                            <PrivateRoute key={i} {...route}/>
                                        ))}
                                        <Redirect to={routes[0].path} />
                                    </Switch>
                                </React.Suspense>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            <Box mx="auto" width="100%" className={classes.viewMobile}>
                <Paper className={classes.paperMobile}>
                    <Box pt={7} pl={7} pb={7} pr={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={1} sm={1} md={1}>
                                <Box mt={2}>
                                    <NavAccountMobile />
                                </Box>
                            </Grid>
                            <Grid item xs={11} sm={11} md={11}>
                                <React.Suspense fallback={<h1>Loading...</h1>}>
                                    <Switch>
                                        {routes.map((route, i) => (
                                            <PrivateRoute key={i} {...route}/>
                                        ))}
                                        <Redirect to={routes[0].path} />
                                    </Switch>
                                </React.Suspense>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </>

    );
};

export default React.memo(AccountLayout);
