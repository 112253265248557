import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    formContainer: {
        padding: theme.spacing(10),
    },
    titleContainer: {
        marginBottom: theme.spacing(10),
    },
    deleteContainer: {
        marginBottom: theme.spacing(2),
    },
    buttonCancel: {
        marginLeft: '16px',
    },
    formControl: {
        '& label + .MuiInputBase-formControl': {
            marginTop: theme.spacing(2),
        },
    },
    popperStyle: {
        width: '300px !important',
        marginTop: '-10px !important',
        '& .MuiAutocomplete-paper': {
            borderRadius: '0px 0px 8px 8px',
            borderTop: '1px solid transparent',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
            backgroundColor: '#f9f9f9',
            padding:'0px 10px',
            '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option': {
                    paddingLeft: '5px'
                },
                '& .MuiAutocomplete-option[data-focus="true"]': {
                    backgroundColor: '#FFEACC',
                    borderRadius: '7px',
                }
            }
        }
    },
    onFocusStyle: {
        backgroundColor: 'white',
        boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
    },
    buttonTrash: {
        color: 'transparent',
        '& *': {
            stroke: theme.palette.error.main,
        },
        '&.MuiSvgIcon-colorPrimary *': {
            stroke: theme.palette.error.main, 
        },
    },
    visibilityButton: {
        color: 'gray',
        padding: '0',
        marginTop: '16px',
        fontSize: '0.65rem',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontWeight: 400,
        lineHeight: '100%',
    }
}));