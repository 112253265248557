import React, { FC, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { http } from "../../../../services/data";

interface Props {
  show: boolean;
  data: any;
  onClose: () => void;
  onCloseWithFetch: () => void;
}

const UserNoteModal: FC<Props> = ({ show, data, onClose, onCloseWithFetch }) => {
  const [notes, setNotes] = useState<string>("");
  useEffect(() => {
    if (show) {
      setNotes(data.notes);
    }
  }, [show, data]);

  const submit = () => {
    try {
      if (!notes.trim().length) throw new Error("notes cant be null");
      http
        .post("admin/assessments/notes", {
          userId: data.userId,
          notes: notes,
        })
        .then(() => onCloseWithFetch())
        .catch((e) => console.error(e));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog fullWidth maxWidth={"xs"} open={show} onClose={onClose}>
      <DialogContent>
        <TextField placeholder="Notes" multiline variant="outlined" rows={4} fullWidth onChange={(e) => setNotes(e.target.value)} value={notes} />
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" variant={"contained"}>
          Save Notes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserNoteModal;
