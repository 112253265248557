import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Modal, Theme, Paper } from '@material-ui/core';
import { CloseIcon } from 'components/_newIcon/';
import Button from 'components/_newDesign/button/Button'
import image from 'assets/images/calendar.png';
interface Props {
    show: boolean;
    title: string;
    content?: string;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: '100%',
            maxWidth: '900px',
            maxHeight: '100vh',
            padding: '22.5px',
            overflow: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#FAFAFA',
            boxShadow:' 0px 15px 60px rgba(0, 0, 0, 0.12)',
            borderRadius: '12px',
        },
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '150%',
            color: '#000000',
            letterSpacing: '0.1px'
        },
        content: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#414141',
            letterSpacing: '0.25px',
            marginTop: '6px'
        }
    }),
);

const ModalWarning: FC<Props> = ({ show, onClose, title, content }) => {
    const classes = useStyles();
    const handleClose = () => {
        onClose()
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            style={{maxHeight: '100vh'}}
        >
           <Paper className={classes.paper}>
                <Box display={'flex'} alignItems="start">
                    <Box>
                        <img alt='' src={image} width="260px"/>
                    </Box>
                    <Box flexGrow={1} px={'18.5px'}>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.content}>{content}</div>
                        <div style={{marginTop: '6px'}}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={handleClose}
                                // style={{marginRight: '10px'}}
                            >
                                OK
                            </Button>
                        </div>
                    </Box>
                    <Box>
                        <CloseIcon onClick={handleClose}/>
                    </Box>
                </Box>
           </Paper>
        </Modal>
    );
};
export default ModalWarning;