import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import expired from 'assets/images/expired.png';
import { forgotPassword } from 'services/domain/users';
import { Toast } from 'services/util/toast';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '1440px',
            height: '100vh',
            margin: 'auto',
            paddingLeft: '50px',
            paddingRight: '50px',
        },
        errStyle: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '72px',
            lineHeight: '85px',
            textAlign: 'left',
            color: '#FFA21E',
            marginTop: '20px',
        },
        errStyle2: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '21px',
            lineHeight: '25px',
            textAlign: 'left',
            marginTop: '20px',
        },
        buttonStyle: {
            width: '276px',
            height: '48px',
            background: '#FFA21E',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '25px',
            textAlign: 'center',
            color: '#000000',
            marginTop: '20px',
            cursor: 'pointer',
        },
    })
);

const LinkExpired: React.FC<{}> = () => {
    const classes = useStyles();
    const history = useHistory();
    const { email } = useParams<{ email: string }>();
    const onSubmit = async () => {
        const toast = Toast({});
        try {
            await forgotPassword({email: email});
            toast.fire({
                icon: 'success',
                title: `Weve sent you an email to ${email}. Please click the link provided at your email to change password.`,
                timer: 7000,
            }).then(() => {
                history.push('/signin');
            })
        } catch (e) {
            toast.fire({
                icon: 'error',
                title: 'Resend link failed. Please try again later.',
            });
        }
    }
    return (
        <div className={classes.root}>
            <Box
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignContent="space-between"
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box width="100%" textAlign={'left'}>
                            <div className={classes.errStyle}>Oops!</div>
                            <div className={classes.errStyle2}>
                                Seems like the reset password link you have has
                                expired.
                            </div>
                            <div
                                className={classes.buttonStyle}
                                onClick={() => onSubmit()}
                            >
                                Resend Reset Password Link
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box width="100%" textAlign={'center'}>
                            <img width={'100%'} src={expired} alt="" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
export default LinkExpired;
