import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowRightCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 20 20"
            style={{
                color: props.color || 'black',
                width: props.width || '20px',
                height: props.height || '20px',
            }}
        >
            <path
                d="M9.47827 12.4652L9.65505 12.642L9.83183 12.4652L13.0318 9.26518C13.1022 9.19485 13.1417 9.09947 13.1417 9.00001C13.1417 8.90056 13.1022 8.80518 13.0318 8.73485L9.83183 5.53485L9.65505 5.35807L9.47828 5.53485L9.3015 5.71162L9.12472 5.8884L9.3015 6.06518L11.8613 8.62501L4.23333 8.62501H3.98333V8.87501V9.12501V9.37501H4.23333L11.8613 9.37501L9.3015 11.9348L9.12472 12.1116L9.3015 12.2884L9.47827 12.4652ZM8.5 17.25C13.0563 17.25 16.75 13.5563 16.75 9C16.75 4.44365 13.0563 0.75 8.5 0.75C3.94365 0.75 0.25 4.44365 0.25 9C0.25 13.5563 3.94365 17.25 8.5 17.25ZM16 9C16 13.1421 12.6421 16.5 8.5 16.5C4.35786 16.5 1 13.1421 1 9C1 4.85786 4.35786 1.5 8.5 1.5C12.6421 1.5 16 4.85786 16 9Z"
                fill={props.fill || "#B3B3B3"}
                stroke={props.stroke || "#B3B3B3"}
                strokeWidth="0.5"
            />
        </SvgIcon>
    );
}
