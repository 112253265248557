import React, { useEffect, useState } from "react";
import moment from "moment";
import { Paper, Box, InputLabel, Button, Grid, MenuItem, Typography, FormControl, InputBase, FormControlLabel } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { Checkbox, TextField } from "formik-material-ui";
import * as Yup from "yup";
import UserIcon from "components/icons/UserIcon";
import BookingInfo from "components/_modules/booking/BookingInfo";
import { phoneRegExp } from "services/util/regex";
import history from "services/data/history";
import { createUser } from "services/domain/admin/users";
import { useStyles } from "./styles";
import { UserRequestDefault } from "interfaces/user";
import { Toast } from "services/util/toast";
import { getTimeDiff } from "services/util/common";
import { coachOptions, getCoachUserAsReference } from "services/domain/admin/users";
import { http } from "services/data/http";

type DateRange = {
  startDate: Date;
  endDate: Date;
};

type UserBooking = {
  dateRange: DateRange;
  paymentStatus: string;
};
interface UserFormikState extends UserRequestDefault {
  bookingData?: UserBooking[];
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("Email is required")
    .test("Unique Email", "Email already in use", (value) => {
      return new Promise((resolve, reject) => {
        http
          .get("search/users/email", {
            params: {
              email: value,
            },
          })
          .then((res) => {
            // exists
            if (res.data.length === 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            // note exists
            resolve(false);
          });
      });
    }),
  role: Yup.string().required("Role is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  // address: Yup.string().required('Address is required'),
  coachId: Yup.string().when("role", {
    is: (role) => role && role === "guest",
    then: Yup.string().required("Coach is required"),
  }),
  bookingData: Yup.array().when("role", {
    is: (role) => role && role === "guest",
    then: Yup.array().of(
      Yup.object().shape({
        dateRange: Yup.object({
          startDate: Yup.date(),
          endDate: Yup.date(),
        }),
        paymentStatus: Yup.string().when("dateRange", {
          is: (dateRange) => !!dateRange,
          then: Yup.string().required("Payment status is required"),
        }),
      }),
    ),
  }),
});

const initialValues = {
  email: "",
  role: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  address: "",
  coachId: "",
  bookingData: [
    {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      paymentStatus: "",
    },
  ],
  isEmployee: false,
};

const UserAddNew = () => {
  const classes = useStyles();
  const [listCoach, setListCoach] = useState<[string, string][]>([]);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: UserFormikState, { setSubmitting }: FormikHelpers<UserFormikState>) => {
    setSubmitting(true);
    try {
      const { bookingData, ...payload } = values;

      let transformBookingData;
      const toast = Toast({ onClose: goBack });

      if (payload.role === "guest" && bookingData && bookingData.length > 0) {
        transformBookingData = bookingData.map(({ dateRange, ...booking }) => ({
          ...booking,
          startDate: moment(dateRange.startDate).utc(true),
          endDate: moment(dateRange.endDate).utc(true),
          totalWeeks: getTimeDiff({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }),
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          phoneNumber: payload.phoneNumber,
          address: payload.address,
          coachId: payload.coachId,
        }));
      }

      await createUser({
        ...payload,
        bookingData: transformBookingData,
      });

      toast.fire({
        icon: "success",
        title: "New user has been added",
      });
    } catch (e) {
      console.error("error in handleSubmit: ", e);

      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Failed to add new user",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getCoaches = async () => {
    try {
      const result = await getCoachUserAsReference();

      if (result?.data && result.data.length > 0) {
        setListCoach(coachOptions(result.data));
      }
    } catch (e) {
      console.error("error in getCoaches: ", e);
    }
  };

  useEffect(() => {
    getCoaches();
  }, []);

  return (
    <Paper className={classes.formContainer}>
      <Box maxWidth="430px" marginLeft="auto" marginRight="auto">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form>
                <Grid container alignItems="center" justify="center" className={classes.titleContainer}>
                  <UserIcon />
                  <Typography component="span">&nbsp;&nbsp;&nbsp;Add New User</Typography>
                </Grid>
                <Grid container>
                  <Grid item md={5}>
                    <FormControl margin="normal" className={classes.formControl}>
                      <InputLabel>ID</InputLabel>
                      <InputBase />
                    </FormControl>
                  </Grid>
                  <Grid item md={7}>
                    <Field
                      component={TextField}
                      name="role"
                      inputProps={{
                        id: "role",
                      }}
                      label="User Role (*)"
                      select
                      margin="normal"
                      fullWidth
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="coach">Coach</MenuItem>
                      <MenuItem value="guest">Guest</MenuItem>
                      <MenuItem value="presenter">Presenter</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={<Field component={Checkbox} type="checkbox" name="isEmployee" label="Is a Movara employee" />}
                  label="Is a Movara employee"
                  name="isEmployee"
                />

                <Field component={TextField} type="text" label="First Name (*)" name="firstName" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Last Name (*)" name="lastName" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Email Address (*)" name="email" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Phone Number (*)" name="phoneNumber" margin="normal" fullWidth />
                <Field component={TextField} type="text" label="Address" name="address" margin="normal" fullWidth />
                {values.role === "guest" && (
                  <Field
                    component={TextField}
                    name="coachId"
                    inputProps={{
                      id: "coachId",
                    }}
                    label="Coach (*)"
                    select
                    margin="normal"
                    fullWidth
                  >
                    {listCoach.map((coach) => (
                      <MenuItem key={coach[0]} value={coach[0]}>
                        {coach[1]}
                      </MenuItem>
                    ))}
                  </Field>
                )}

                {values.role === "guest" && <BookingInfo bookingData={values.bookingData} setFieldValue={setFieldValue} />}

                <Box mt={10}>
                  <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                    Create Account
                  </Button>
                  <Button onClick={goBack} className={classes.buttonCancel} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default UserAddNew;
