import React, { useEffect, useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import CustomInput from "components/controls/CustomInput/CustomInputPayment";
import { useSnackbar } from "notistack";
import { useStyles } from "../Form/styles";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { saveBooking } from "services/domain/admin/bookings";
import moment from "moment";
import { postRegister, paymentStripe, createStripeCustomer, createStripeSubscription, getStripeCustomerByEmail } from "services/domain/users";
import Auth from "services/domain/auth";
import * as Yup from "yup";
import ButtonMui from "components/_newDesign/button/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TypographyMui from "components/_newDesign/typography/Typography";
import { ConnectedFocusError } from "focus-formik-error";
import { getPromoCodeDetails } from "services/domain/admin/promocodes";
import { AlertIcon } from "components/_newIcon/";
import { SuccessCircleCheckIcon } from "components/_newIcon/";
import { ProfileContext } from "../PaymentStore";
import BookingInfo from "../Form/BookingInfo";
import checkPrice from "../Form/checkPrice";

declare global {
  interface Window {
    gtag: any;
  }
}

interface ProfileInterface {
  nameOnCard: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  gender: string;
  dateOfBirth: string | Date;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  startDate: Date | string;
  endDate: Date | string;
  password: string;
  newPassword: string;
  coach_id: string;
  coachName: string;
  recurring: boolean;
}

interface PromoCodeInterface {
  _id: string;
  startDate: Date | string;
  expiryDate: Date | string;
  isActive: boolean;
  isRedeemed: boolean;
  rate: number;
  type: string;
  redeems: number;
  redeemLimit: number;
}

const validationSchema = Yup.object({
  zipCode: Yup.string().required("ZIP Code is required").matches(/^\d+$/, "Only digits allowed"),
  nameOnCard: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
});

let currPromoCode = "";

const CheckoutContainer: React.FC<any> = (props) => {
  const hasAddWeeksQuery = props.hasAddWeeksQuery || false;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { profile, setProfile } = useContext(ProfileContext) as any;
  const stripeCustIdRef = React.useRef<string>("");
  const stripeSubIdRef = React.useRef<string>("");

  const stripe = useStripe();
  const elements = useElements();
  const [promoCodeDetails, setPromoCodeDetails] = useState<PromoCodeInterface>();
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeDiscount, setPromoCodeDiscount] = useState<number>(0);
  const [promoCodeDiscountType, setPromoCodeDiscountType] = useState<string>("");
  const [promoCodeDiscountStatus, setPromoCodeDiscountStatus] = useState<string>("");
  const [promoCodeDiscountActive, setPromoCodeDiscountActive] = useState<boolean>(false);
  const [showSelectDurationErr, setShowSelectDurationErr] = useState<boolean>(true);
  const [showSelectStartDateErr, setShowSelectStartDateErr] = useState<boolean>(true);

  const handleSubmit = async (values: ProfileInterface) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        enqueueSnackbar("Something wrong. Please try again.", {
          variant: "error",
        });
        return;
      }

      // create a payment method in stripe (card), used for one-time and recurring payments
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement) || { token: "" },
        billing_details: {
          name: values.nameOnCard,
          address: {
            city: values.city,
            state: values.state,
            line1: values.address,
            postal_code: values.zipCode,
          },
          email: values.email,
        },
      });

      if (payload.error) {
        enqueueSnackbar(payload.error.message, { variant: "error" });
      } else {
        // enqueueSnackbar('Payment Success', {variant: 'success'})
        // props.changeStatusStep(2)
        const amount = Number(applyDiscount(profile.index * profile.perweek).toFixed(2));
        if (amount > 0) {
          if (profile.recurring) {
            // if the user chose a recurring plan, create a stripe customer

            // check if there is a stripe customer for this email already
            const existingCustomer = await getStripeCustomerByEmail({ email: profile.email });

            // if a customer already exists, use that customer id
            if (existingCustomer.data.length) {
              stripeCustIdRef.current = existingCustomer.data[0].id;
            } else {
              // otherwise, create a new customer
              const custRes = await createStripeCustomer({
                email: profile.email,
                name: `${values.firstName} ${values.lastName}`,
                payment_method: payload.paymentMethod.id,
              });

              stripeCustIdRef.current = custRes.data.id;
            }

            //? right now we only have one plan (4W Recurring), but we'll need to change this when we have more
            const priceId = process.env.REACT_APP_RECURRING_PRICE_ID_FOUR_WEEKS || "";

            if (!priceId) {
              throw new Error("An error occurred. Please try again.");
            }

            const subRes = await createStripeSubscription({
              customer: stripeCustIdRef.current,
              priceId,
            });

            const { status, subscriptionId: subId, clientSecret } = subRes.data;

            stripeSubIdRef.current = subId;

            if (status === "requires_confirmation") {
              // Use Stripe.js to handle required card action
              const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: payload.paymentMethod.id,
              });

              if (error) {
                // The card action has failed -- ask your customer for a new payment method.

                console.error("error", error);

                throw new Error(error.message);
              } else {
                // The card action has succeeded.
              }
            }
          } else {
            const rspn = await paymentStripe({
              amount: amount,
              currency: "USD",
              receipt_email: values.email,
              payment_method: payload.paymentMethod.id,
            });
            // await paymentStripeData({
            //     data: payload.paymentMethod,
            // })
            window.gtag("event", "purchase", {
              transaction_id: rspn.data.id,
              affiliation: "Movara Online Payment",
              value: amount,
              currency: "USD",
              tax: 0,
              shipping: 0,
              email: profile.email,
              phoneNumber: values.phoneNumber,
              firstName: values.firstName,
              lastName: values.lastName,
              gender: values.gender,
              dob: values.dateOfBirth,
              city: values.city,
              state: values.state,
              zip: values.zipCode,
              startDate: moment(profile.startDate).format("YYYY-MM-DD"),
              endDate: moment(profile.endDate).format("YYYY-MM-DD"),
              totalWeeks: profile.index,
            });
          }
        }
        if (Auth.userProperties === null) {
          // if user is not logged in, create a new user (creates a new booking for them too)
          const postUser = {
            email: profile.email,
            phoneNumber: values.phoneNumber,
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
            dob: values.dateOfBirth,
            city: values.city,
            state: values.state,
            zip: values.zipCode,
            paymentStatus: "PAID",
            startDate: moment(profile.startDate).format("YYYY-MM-DD"),
            endDate: moment(profile.endDate).format("YYYY-MM-DD"),
            totalWeeks: profile.index,
            amount: amount,
            password: values.newPassword,
            stripeData: payload.paymentMethod,
            coach_id: profile.coach_id,
            coachName: profile.coachName,
            promoCode: promoCode,
            promoCodeId: promoCodeDetails?._id,
            promoRate: promoCodeDiscount,
            promoCodeType: promoCodeDiscountType,
            bookingIsRecurring: profile.recurring,
            stripeCustomerId: stripeCustIdRef.current,
            stripeSubscriptionId: stripeSubIdRef.current,
          };

          await postRegister(postUser);
        } else {
          // when we are only adding weeks - we will handle posting differently
          if (hasAddWeeksQuery) {
            const startDate = moment(profile.startDate).format("YYYY-MM-DD");
            const endDate = moment(profile.endDate).format("YYYY-MM-DD");

            await saveBooking({
              bookId: props.activeBookingId,
              stripeData: payload.paymentMethod,
              email: profile.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
              address: values.address,
              paymentStatus: "Paid",
              startDate: startDate,
              endDate: endDate,
              totalWeeks: profile.index,
              amount: amount,
              coach_id: profile.coach_id,
              coach_name: profile.coachName,
              promoCode: promoCode,
              promoCodeId: promoCodeDetails?._id,
              promoRate: promoCodeDiscount,
              promoCodeType: promoCodeDiscountType,
              bookingIsRecurring: profile.recurring,
              stripeCustomerId: stripeCustIdRef.current,
              stripeSubscriptionId: stripeSubIdRef.current,
            });
          } else {
            // if user is an existing user, just save the booking
            await saveBooking({
              bookId: "",
              stripeData: payload.paymentMethod,
              bookingData: [
                {
                  email: profile.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phoneNumber: values.phoneNumber,
                  address: values.address,
                  paymentStatus: "Paid",
                  startDate: moment(profile.startDate).format("YYYY-MM-DD"),
                  endDate: moment(profile.endDate).format("YYYY-MM-DD"),
                  totalWeeks: profile.index,
                  amount: amount,
                  coach_id: profile.coach_id,
                  coachName: profile.coachName,
                  coach_name: profile.coachName,
                  promoCode: promoCode,
                  promoCodeId: promoCodeDetails?._id,
                  promoRate: promoCodeDiscount,
                  promoCodeType: promoCodeDiscountType,
                  bookingIsRecurring: profile.recurring,
                  stripeCustomerId: stripeCustIdRef.current,
                  stripeSubscriptionId: stripeSubIdRef.current,
                },
              ],
            });
          }
        }
        enqueueSnackbar("Payment Success", { variant: "success" });
        props.changeStatusStep(2);
        localStorage.removeItem("profiletemp");
      }
    } catch (error) {
      enqueueSnackbar("Error Payment", { variant: "error" });
      console.error("error", error);
    }
    // event.preventDefault();
  };

  const getStringOrderDetail = (profile) => {
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    const totalPrice = weeks * price?.perweek;
    return (
      <>
        <div className={classes.orderDesc}>
          {weeks} {weeks === 1 ? "week" : "weeks"} of Movara Home program <span style={{ float: "right" }}>${totalPrice}</span>
        </div>
        <div className={classes.orderDesc}>
          - <span style={{ float: "right" }}>{price !== undefined ? `$${price.perweek}/week` : ""}</span>
        </div>
      </>
    );
  };
  const totalAmount = (profile) => {
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    if (price !== undefined) {
      return applyDiscount(weeks * price?.perweek).toFixed(2);
    } else {
      return 0;
    }
  };

  const applyDiscount = (price) => {
    if (!promoCodeDiscountActive) return price;
    if (promoCodeDiscountType === "cash") return price - promoCodeDiscount > 0 ? price - promoCodeDiscount : 0;
    if (promoCodeDiscountType === "percent") return price - percentage(price, promoCodeDiscount) > 0 ? price - percentage(promoCodeDiscount, price) : 0;
  };

  function percentage(total, percentage) {
    return (total / 100) * percentage;
  }

  const getSubTotal = (profile) => {
    const weeks = moment(profile.endDate).diff(moment(profile.startDate), "weeks");
    const price = checkPrice(weeks);
    const totalPrice = weeks * price?.perweek;
    const discountAmount = totalPrice - applyDiscount(totalPrice);
    return (
      <>
        <div className={classes.orderDesc}>
          Subtotal{" "}
          <span style={{ float: "right" }}>
            {" "}
            ${totalPrice} {promoCodeDiscountActive ? `- $${discountAmount}` : ""}
          </span>
        </div>
      </>
    );
  };

  const onChange = async ({ target }: any) => {
    target.value = target.value.toUpperCase();
    currPromoCode = target.value;
    try {
      setPromoCode(target.value);
      let code = target.value;
      let email = profile.email;
      let res = await getPromoCodeDetails({ code, email });
      if (res.data && res.data.length > 0) {
        const data = res.data[0];
        const { _id, startDate, expiryDate, isActive, isRedeemed, rate, type, redeems, redeemLimit } = data;
        setPromoCodeDetails({
          _id,
          startDate,
          expiryDate,
          isActive,
          isRedeemed,
          rate,
          type,
          redeems,
          redeemLimit,
        });
      } else {
        const blankSate = {
          _id: "",
          startDate: "",
          expiryDate: "",
          isActive: false,
          isRedeemed: false,
          rate: 0,
          type: "",
          redeems: 0,
          redeemLimit: 0,
        };
        const { _id, startDate, expiryDate, isActive, isRedeemed, rate, type, redeems, redeemLimit } = blankSate;
        setPromoCodeDetails({
          _id,
          startDate,
          expiryDate,
          isActive,
          isRedeemed,
          rate,
          type,
          redeems,
          redeemLimit,
        });
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onBookingUpdate = (updateProfileData) => {
    if (!updateProfileData.endDate) {
      setShowSelectDurationErr(true);
    } else {
      setShowSelectDurationErr(false);
    }
    const weeks = moment(updateProfileData.endDate).diff(moment(updateProfileData.startDate), "weeks");
    const price = checkPrice(weeks);
    if (price !== undefined) {
      setProfile({
        ...updateProfileData,
        index: price.index,
        amount: price.amount,
        perweek: price.perweek,
      });
    }
  };

  const applyPromoCode = async () => {
    try {
      if (typeof promoCodeDetails?.startDate === "string" && promoCodeDetails?.startDate.length > 0) {
        const expiryDate = moment(promoCodeDetails?.expiryDate).format("YYYY-MM-DD");
        const dateToday = moment(new Date()).format("YYYY-MM-DD");
        if (!promoCodeDetails?.isActive) {
          setPromoCodeDiscountStatus("Invalid");
          setPromoCodeDiscountActive(false);
          setPromoCodeDiscount(0);
        } else if (dateToday > expiryDate) {
          setPromoCodeDiscountStatus("Expired");
          setPromoCodeDiscountActive(false);
          setPromoCodeDiscount(0);
        } else if (promoCodeDetails?.redeems >= promoCodeDetails?.redeemLimit) {
          setPromoCodeDiscountStatus("Limit");
          setPromoCodeDiscountActive(false);
          setPromoCodeDiscount(0);
        } else if (promoCodeDetails?.isRedeemed) {
          setPromoCodeDiscountStatus("Redeemed");
          setPromoCodeDiscountActive(false);
          setPromoCodeDiscount(0);
        } else if (promoCodeDetails?.isActive) {
          setPromoCodeDiscountStatus("Success");
          setPromoCodeDiscountActive(true);
          setPromoCodeDiscountType(promoCodeDetails?.type);
          setPromoCodeDiscount(promoCodeDetails?.rate);
        } else {
          setPromoCodeDiscountStatus("Invalid");
          setPromoCodeDiscountActive(false);
          setPromoCodeDiscount(0);
        }
      } else if (currPromoCode.length > 0) {
        setPromoCodeDiscountStatus("Invalid");
        setPromoCodeDiscountActive(false);
        setPromoCodeDiscount(0);
      } else {
        setPromoCodeDiscountStatus("");
        setPromoCodeDiscountActive(false);
        setPromoCodeDiscount(0);
      }
    } catch (e) {}
  };

  useEffect(() => {
    applyPromoCode();

    // eslint-disable-next-line
  }, [promoCodeDetails]);

  return (
    <>
      <Formik enableReinitialize initialValues={profile} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, getFieldProps, getFieldMeta, submitCount }) => (
          <Form noValidate>
            <ConnectedFocusError />
            <Box className={classes.cardContentWrapper} mb={"30px"}>
              <Grid container className={classes.formContainer}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Typography className={classes.label}>
                    Name on card <span style={{ color: "#EF5136" }}>&#9679;</span>
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Field
                    as={CustomInput}
                    placeholder="Type in here"
                    id="nameOnCard"
                    autoFocus
                    className={classes.inputCustomWidth}
                    {...getFieldProps("nameOnCard")}
                    meta={getFieldMeta("nameOnCard")}
                    isSubmitted={submitCount > 0}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formContainer}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Typography className={classes.label}>
                    Email Address <span style={{ color: "#EF5136" }}>&#9679;</span>
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Field
                    as={CustomInput}
                    placeholder="user@email.com"
                    id="email"
                    autoFocus
                    className={classes.inputCustomWidth}
                    {...getFieldProps("email")}
                    meta={getFieldMeta("email")}
                    isSubmitted={submitCount > 0}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formContainer}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Typography className={classes.label}>Address</Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Field
                    as={CustomInput}
                    placeholder="Type in here"
                    id="address"
                    autoFocus
                    className={classes.inputCustomWidth}
                    {...getFieldProps("address")}
                    meta={getFieldMeta("address")}
                    isSubmitted={submitCount > 0}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formContainer}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Typography className={classes.label}>City</Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Field
                    as={CustomInput}
                    placeholder="Type in here"
                    id="city"
                    autoFocus
                    className={classes.inputCustomWidth}
                    {...getFieldProps("city")}
                    meta={getFieldMeta("city")}
                    isSubmitted={submitCount > 0}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formContainer}>
                <Grid item md={5} xs={12}>
                  <Grid container className={classes.formContainer}>
                    <Grid item md={12} xs={12} sm={12} xl={12}>
                      <Typography className={classes.label}>State</Typography>
                    </Grid>
                    <Grid item md={11} xs={12}>
                      <Field
                        as={CustomInput}
                        placeholder="Type in here"
                        id="state"
                        autoFocus
                        className={classes.inputCustomWidth}
                        {...getFieldProps("state")}
                        meta={getFieldMeta("state")}
                        isSubmitted={submitCount > 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={7} xs={12}>
                  <Grid container className={classes.formContainer}>
                    <Grid item md={12} xs={12} sm={12} xl={12}>
                      <Typography className={classes.label}>
                        ZIP Code <span style={{ color: "#EF5136" }}>&#9679;</span>
                      </Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Field
                        as={CustomInput}
                        placeholder="Type in here"
                        id="zipCode"
                        autoFocus
                        className={classes.inputCustomWidth}
                        {...getFieldProps("zipCode")}
                        meta={getFieldMeta("zipCode")}
                        isSubmitted={submitCount > 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* TODO - add BookingInfo here */}
              {hasAddWeeksQuery && (
                <BookingInfo
                  showSelectDurationErr={showSelectDurationErr}
                  showSelectStartDateErr={showSelectStartDateErr}
                  bookingData={profile}
                  onDateRangeChange={(data: ProfileInterface) => onBookingUpdate(data)}
                />
              )}

              <Grid container className={classes.formContainer}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Typography className={classes.label}>
                    Card details <span style={{ color: "#EF5136" }}>&#9679;</span>
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          color: "#424770",
                          letterSpacing: "0.025em",
                          fontFamily: "Montserrat,Roboto,Helvetica,Arial,sans-serif",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                          padding: "3px 16px",
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                    className={classes.inputMovStripe}
                  />
                </Grid>
              </Grid>

              <Grid item md={5} xs={12}>
                <Grid container className={classes.formContainer}>
                  <Grid item md={12} xs={12} sm={12} xl={12}>
                    <Typography className={classes.label}>PROMO CODE</Typography>
                  </Grid>
                  <Grid item md={11} xs={12} className={classes.promoCodeGrid}>
                    <Field
                      as={CustomInput}
                      placeholder="Enter code here"
                      id="promoCode"
                      autoComplete="off"
                      style={{
                        backgroundColor: promoCodeDiscountStatus === "Success" ? "#F5F7E8" : promoCodeDiscountStatus === "" ? "" : "#FFECEE",
                        color: promoCodeDiscountStatus === "Success" ? "#849733" : promoCodeDiscountStatus === "" ? "" : "#EF5136",
                      }}
                      // className={classes.promoCodeGrid}
                      {...getFieldProps("promoCode")}
                      meta={getFieldMeta("promoCode")}
                      onChange={onChange}
                    />
                  </Grid>

                  <div className={classes.promoCodeStatus}>
                    {promoCodeDiscountStatus === "Expired" ? (
                      <span className={classes.invalid}>
                        {" "}
                        <AlertIcon />{" "}
                      </span>
                    ) : promoCodeDiscountStatus === "Limit" ? (
                      <span className={classes.invalid}>
                        {" "}
                        <AlertIcon />{" "}
                      </span>
                    ) : promoCodeDiscountStatus === "Invalid" ? (
                      <span className={classes.invalid}>
                        {" "}
                        <AlertIcon />{" "}
                      </span>
                    ) : promoCodeDiscountStatus === "Redeemed" ? (
                      <span className={classes.invalid}>
                        {" "}
                        <AlertIcon />{" "}
                      </span>
                    ) : promoCodeDiscountStatus === "Success" ? (
                      <span className={classes.success}>
                        {" "}
                        <SuccessCircleCheckIcon />{" "}
                      </span>
                    ) : (
                      ""
                    )}

                    {promoCodeDiscountStatus === "Expired" ? (
                      <span className={classes.invalidStatus}> Promotion code has expired</span>
                    ) : promoCodeDiscountStatus === "Limit" ? (
                      <span className={classes.invalidStatus}> Promotion code limit has been reached</span>
                    ) : promoCodeDiscountStatus === "Invalid" ? (
                      <span className={classes.invalidStatus}> Invalid Promo Code</span>
                    ) : promoCodeDiscountStatus === "Redeemed" ? (
                      <span className={classes.invalidStatus}> You already used this promocode.</span>
                    ) : promoCodeDiscountStatus === "Success" ? (
                      <span className={classes.successStatus}>Promotion code applied</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
              <Box
                style={{ backgroundColor: "white" }}
                width="100%"
                pt={"30px"}
                px={"100px"}
                pb={"20px"}
                display="flex"
                justifyContent="center"
                flexDirection="row"
                position="fixed"
                bottom={0}
                left={0}
                className={classes.footerStyle}
                // zIndex={1000}
              >
                <Box width="30%" textAlign="left">
                  <div className={classes.orderDetail}>order details:</div>
                  {getStringOrderDetail(profile)}
                  {getSubTotal(profile)}
                  <div className={classes.orderDesc}>
                    Promotion Discount{" "}
                    <span
                      style={{
                        float: "right",
                        color: "green",
                      }}
                    >
                      {promoCodeDiscountActive ? `-${promoCodeDiscount}${promoCodeDiscountType === "percent" ? "%" : "$"}` : 0}
                    </span>
                  </div>
                </Box>
                <Box width="30%" textAlign="center" margin="auto">
                  <Box pt={1} width="100%" textAlign="center">
                    <ButtonMui
                      color="primary"
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={isSubmitting}
                      endIcon={
                        isSubmitting ? (
                          <CircularProgress size={18} />
                        ) : (
                          <ArrowForwardIcon
                            style={{
                              color: "#6D6D6D",
                            }}
                          />
                        )
                      }
                    >
                      Checkout
                    </ButtonMui>
                  </Box>
                </Box>
                <Box width="30%" textAlign="right">
                  <div className={classes.orderDetail}>Estimated Total:</div>
                  <div className={classes.orderTotal}>${totalAmount(profile)}</div>
                  <TypographyMui className={classes.orderNotes}>* The price hasn’t been included with tax and shipment fee.</TypographyMui>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CheckoutContainer;
