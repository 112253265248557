import React, { FC } from "react";
import { Avatar, Box, ButtonBase, createStyles, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Modal, Theme } from "@material-ui/core";
import { CloseIcon } from "components/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "components/_newDesign/typography/Typography";
import { ArrowBack } from "@material-ui/icons";
import auth from "services/domain/auth";
import { useHistory } from "react-router-dom";
import { UserSubmissionList } from "interfaces/user";
interface Props {
  show: boolean;
  value?: string;
  title?: string;
  onClose: () => void;
  userList?: UserSubmissionList;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 0, 2),
      width: "623px",
      maxWidth: "623px",
      maxHeight: "95%",
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
      borderRadius: "20px",
    },
    header: {
      // width: '805px',
      // maxWidth: '805px',
    },
    "header-item": {
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "36px",
      lineHeight: "100%",
      color: "#000000",
    },
    content: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "150%",
      color: "#000000",
    },
    "upload-text-web": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "100%",
      /* identical to box height, or 36px */
      letterSpacing: " 0.15px",
      color: "#000000",
    },
    "upload-text-mb": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "100%",
      /* identical to box height, or 36px */
      letterSpacing: " 0.15px",
      color: "#000000",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "20px",
    },
    modalContent: {
      padding: "0px 24px 16px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 0px 16px 0px",
      },
    },
    avatarSmall: {
      width: "40px",
      height: "40x",
      fontSize: "12px",
    },
  }),
);

const UserModalList: FC<Props> = ({ show, onClose, title, userList }) => {
  const mTitle = title || "View Task Submissions";
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  const goToProfile = (id: string) => {
    if (id === auth.userProperties?.userData?._id) {
      history.push(`/profile`);
    } else {
      history.push(`/user-profile/${id}`);
    }
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      style={{ maxHeight: "100vh" }}
      className={classes.modal}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.7)", backdropFilter: "blur(10px)" } }}
    >
      <div className={classes.paper}>
        <Grid container className={classes.header} style={{ padding: "0px 20px" }}>
          <Grid item xs={10} className={classes["header-item"]}>
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <Typography className={matches ? classes["upload-text-mb"] : classes["upload-text-web"]}>{mTitle}</Typography>
          </Grid>
          <Grid item xs={2} className={classes["header-item"]} style={{ justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr
          style={{
            backgroundColor: "#E6E6E6",
            height: "1px",
            border: "none",
          }}
        />
        <Box width={"100%"} className={classes.modalContent}>
          <List>
            {userList?.map((item, index) => {
              return (
                <>
                  <ButtonBase onClick={() => goToProfile(item._id)} disableRipple={true} disableTouchRipple={true}>
                    <Box>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.avatarSmall} style={{ zIndex: index }} alt={item.firstName + item.lastName} src={item.avatar} />
                        </ListItemAvatar>
                        <ListItemText primary={item.hasOwnProperty("isTargetGuest") && item.isTargetGuest ? "You" : `${item.firstName} ${item.lastName}`} />
                      </ListItem>
                    </Box>
                  </ButtonBase>
                  <Box ml={2}>
                    <Divider />
                  </Box>
                </>
              );
            })}
          </List>
        </Box>
      </div>
    </Modal>
  );
};
export default UserModalList;
