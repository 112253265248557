import React from 'react';
import {
    IconButton, Badge, Menu, MenuItem, Typography, Button, CircularProgress,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Waypoint } from 'react-waypoint';

import NotificationItem from './NotificationItem';
import { NotificationIcon } from 'components/icons';
import { getNotifications, updateNotifications } from 'services/domain/users';
import { NotificationInterface } from 'interfaces/user';


const useStyles = makeStyles((theme: Theme) => ({
    'notification-list-scroller': {
        maxHeight: 73 * 4.5,
        overflow: 'auto',
    },
    'mark-as-read-wrapper': {
        textAlign: 'right',
        borderColor: '#E5E5E5',
        borderStyle: 'solid',
        borderWidth: '1px 0px',
        '& Button': {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    'load-more-wrapper': {
        // textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& Button': {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

const LIMIT_DATA = 5;

const Notification = React.forwardRef((props, ref) => {
    const [ isNotifOpen, setIsNotifOpen ] = React.useState(false);
    const [notifications, setNotifications] =  React.useState<NotificationInterface[]>([]);
    const [totalUnread, setTotalUnread] = React.useState(0);
    const [currentPageIndex, setCurrentPageIndex] = React.useState<null | number>(null);
    const [totalPage, setTotalPage] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const classes = useStyles();

    const fetchNotifications = async(page: number) => {
        setIsLoading(true);
        try {
            const result = await getNotifications({ existData: page * LIMIT_DATA, limitData: LIMIT_DATA });
            const newTotalPage = result?.meta?.pagination?.totalPage;

            setTotalUnread(result.totalUnread);
            setCurrentPageIndex(page);

            if (newTotalPage && totalPage !== newTotalPage) {
                setTotalPage(newTotalPage);
            }

            if ('data' in result && result.data.length > 0) {
                setNotifications(prevNotifications => [...prevNotifications, ...result.data]);
            }
        } catch (e) {
            // TODO: set some error handle here
        } finally {
            setIsLoading(false);
        }
    }

    const updateStatusNotification = async (notifId?: string, afterUpdateCallback?: () => void) => {
        try {
            let payload;

            if (notifId) payload = { notif_id: notifId };

            await updateNotifications(payload);
            const updatedNotifications = notifications.map(notification => Object.assign({}, notification, {
                read: notification._id === notifId || !notifId ? true : notification.read,
            }));
            setNotifications(updatedNotifications);

            if (notifId && afterUpdateCallback) afterUpdateCallback();
            if (!notifId && !afterUpdateCallback) setTotalUnread(0);
        } catch(e) {
        }
        finally {}
    }

    React.useEffect(() => {
        fetchNotifications(0);
        // eslint-disable-next-line
    }, []);

    React.useImperativeHandle(
        ref,
        () => ({
            updateNotification(content: NotificationInterface) {
                setNotifications(prevNotifications => [content, ...prevNotifications]);
                setTotalUnread(prevTotalUnread => prevTotalUnread + 1);
            }
        }),
    )

    const handleOpenNotification = () => {
        setIsNotifOpen(true);
    };

    const handleCloseNotification = () => {
        setIsNotifOpen(false);
    };

    const renderNotificationList = (
        <Menu
            anchorEl={anchorRef.current}
            open={isNotifOpen}
            onClose={handleCloseNotification}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            getContentAnchorEl={null}
        >
            {/* menu header */}
            <MenuItem disabled>
                <Typography>
                    NOTIFICATIONS
                </Typography>
            </MenuItem>
            <div className={classes["mark-as-read-wrapper"]}>
                <Button color="primary" onClick={() => { updateStatusNotification(); }} disabled={totalUnread === 0} >
                    MARK ALL AS READ
                </Button>
            </div>
            {/* end of menu header */}

            <div className={classes["notification-list-scroller"]}>
            {notifications.map(notification => (
                <NotificationItem
                    key={notification._id}
                    {...notification}
                    updateStatusNotification={updateStatusNotification}
                />
            ))}

                {
                    currentPageIndex !== null && totalPage > currentPageIndex + 1
                    && (
                        <Waypoint
                            onEnter={() => { if (currentPageIndex !== null ) fetchNotifications(currentPageIndex + 1); }}
                        />
                    )
                }
            </div>

            {/* load more */}
            <div className={classes["load-more-wrapper"]}>
                <Button color="primary" disabled={isLoading || (currentPageIndex !== null && totalPage === currentPageIndex + 1)} onClick={() => { if (currentPageIndex !== null) fetchNotifications(currentPageIndex + 1) }}>
                    LOAD MORE
                </Button>
                {
                    isLoading
                    && (
                        <CircularProgress color="primary" size={20} />
                    )
                }
            </div>
        </Menu>
    );

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-label={`show ${totalUnread} new notifications`}
                onClick={handleOpenNotification}
                color="inherit"
            >
                <Badge badgeContent={totalUnread} color="primary">
                    <NotificationIcon />
                </Badge>
            </IconButton>
            {renderNotificationList}
        </>
    );
});

export default Notification;
