import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function CircleCheckIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props}
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}>
            <path
                d="M15.75 31.25C24.3104 31.25 31.25 24.3104 31.25 15.75C31.25 7.18959 24.3104 0.25 15.75 0.25C7.18959 0.25 0.25 7.18959 0.25 15.75C0.25 24.3104 7.18959 31.25 15.75 31.25Z"
                strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M6.91162 15.8409L13.2626 22.1926L25.0884 9.81055" strokeMiterlimit="10"
                  strokeLinejoin="round"/>
        </SvgIcon>
    );
};