import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '0px 30px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px  10px',
            },
        },
        rootWeekly: {
            width: '100%',
        },
        accordionRoot: {
            backgroundColor: '#FAFAFA',
            boxShadow: '1px 1px 8px rgba(65, 65, 65, 0.1)',
            borderRadius: '8px !important',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        summaryContent: {
            display: 'block',
            marginBottom: '0px !important',
        },
        itemDetailBox: {
            padding: '8px 12px 8px 8px',
            background: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '8px',
        },
        itemDetailNoBGBox: {
            padding: '8px 12px 8px 8px',
            borderRadius: '8px',
        },
        avatarSmall: {
            width: '24px',
            height: '24px',
            fontSize: '12px'
        },
        avatarGroup: {
            width: '24px',
            height: '24px',
            fontSize: '11px',
            marginLeft: '-6px'
        },
        inputMov: {
            padding: '5px 0px 5px 16px',
            minHeight: 40,
            width: '95px',
            background: '#FFFFFF',
            boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            border: 'none',
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        },
        inputMovFocused: {
            borderColor: theme.palette.primary.main,
            border: '2px solid red',
        },
        inputDisableMov: {
            background: '#F5F5F5',
            boxShadow: 'none !important'
        },
        inputDateTimeMov: {
            padding: '5px 0px 5px 16px',
            minHeight: 40,
            background: '#FFFFFF',
            boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            border: 'none',
            fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
            width: '125px',
        },
    },
));
