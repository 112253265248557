import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function StretchIcon(props: SvgIconProps & Status) {
    const { isActive } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();
    return (
        <SvgIcon {...props} className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active}`}>
            <path
                d="M5.9305 6.79124L6.58392 10.968L6.549 11.0597L3.47966 12.4998C3.42961 12.5664 3.39377 12.6437 3.37431 12.7261L2.51143 16.4016C2.45953 16.6221 2.58929 16.8446 2.80091 16.8986L3.15187 16.9881C3.3635 17.0419 3.57728 16.907 3.64772 16.7107L4.61502 13.3959L7.55214 12.5178C8.32882 13.6319 8.68658 13.9302 9.12219 14.4111C9.6199 14.9561 10.0546 15.1418 11.1365 15.9043C11.7791 16.3572 12.1665 16.5858 12.625 16.8584C12.8613 16.9991 13.0702 17.0461 13.1854 16.9305L13.4394 16.6447C13.5932 16.3724 13.423 16.205 13.2762 16.0543C12.2898 15.0411 11.3994 14.6197 10.4608 13.722C10.0453 13.0647 9.11045 11.2609 9.05391 10.7887L8.38443 6.50798M5.9305 6.79124L8.38443 6.50798M5.9305 6.79124L3.93779 9.28181L6.27617 10.7245M8.38443 6.50798L10.4714 3.88438L8.62549 1M7.84924 4.28237C7.84924 5.05496 7.24811 5.68126 6.50657 5.68126C5.76504 5.68126 5.16391 5.05496 5.16391 4.28237C5.16391 3.50978 5.76504 2.88347 6.50657 2.88347C7.24811 2.88347 7.84924 3.50978 7.84924 4.28237Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
