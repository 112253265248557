import React from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ChatContext } from '../hooks/ChatContext';
import ChatScreen from './ChatScreen';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '450px',
        height: '600px',
    },
}));

const ChatModal = React.forwardRef((props, ref) => {
    const [isShowModal, setIsShowModal] = React.useState(false);
    const classes = useStyles();

    const toggleShow = () => {
        setIsShowModal(prevIsShow => !prevIsShow);
    }

    React.useImperativeHandle(
        ref,
        () => ({
            openChatMessage: () => {
                setIsShowModal(true);
            },
        }),
    )

    return (
        <Modal
            open={isShowModal}
            onClose={() => { setIsShowModal(false); }}
            disableBackdropClick
            style={{ top: 'unset', left: 'unset', right: '16px', bottom: '16px' }}
            className={classes.root}
        >
            <ChatContext.Provider
                value={{
                    handleCloseChat: toggleShow,
                }}
            >
                <ChatScreen />
            </ChatContext.Provider>
        </Modal>
    );
});

export default ChatModal;
