import React, { FC } from 'react';
import { makeStyles, InputBase, Theme, Typography, InputAdornment } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
    endAdornment?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    inputMov: {
        padding: '5px 16px',
        minHeight: 40,
        background: '#FFFFFF',
        boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        minWidth:'100%',
        border: 'none',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
    },
    inputMovFocused: {
        borderColor: theme.palette.primary.main,
        border: '2px solid red',
    },
    inputDisableMov: {
        background: '#F5F5F5',
        boxShadow: 'none !important'
    },
    inputClass: {
        '&::placeholder': {
            color: '#282828',
            opacity: 1
        }
    },
}));

const InputNumber: FC<Props & InputBaseProps> =  React.forwardRef(({ meta, isSubmitted, placeholder, ...props }, ref) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <InputBase
                inputRef={ref}
                type='number'
                endAdornment={<InputAdornment position="end">{props.endAdornment || ''}</InputAdornment>}
                classes={{focused: classes.inputMovFocused, disabled: classes.inputDisableMov, input: classes.inputClass}}
                {...props}
                placeholder={placeholder}
                className={classes.inputMov}/>
            {meta?.touched && meta?.error && (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            )}
        </React.Fragment>
    );
});

export default InputNumber;
