import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Badge } from "@material-ui/core";
import { MailIcon } from "components/icons";

import { getChatList } from "services/domain/chats";
import { Creators } from "services/domain/chats/reducer";
import auth from "services/domain/auth";
import { ChatStore, ChatListItem } from "interfaces/chat";

interface MessageAlertProps {}

interface NotifWithChatActiveStatus extends ChatListItem {
  isChatListActive: boolean;
}

const MessageAlert = React.forwardRef((props, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUserId = auth.userProperties?.userData?._id;
  const { countMessage, chatList } = useSelector((state: { chats: ChatStore }) => ({
    countMessage: _.sum(state.chats?.chatList.map((item) => (item.unread > 0 ? 1 : 0))),
    chatActiveUser: state.chats.chatActiveUser,
    chatList: state.chats.chatList,
  }));

  React.useImperativeHandle(ref, () => ({
    handleChatListUpdate,
  }));

  const handleChatListUpdate = (message: NotifWithChatActiveStatus) => {
    let chatItemExist = false;
    // @ts-ignore
    const updatedChatList = chatList.reduce((prev: ChatListItem[], chatListItem: ChatListItem, index: number) => {
      if ((message.from === chatListItem.from && chatListItem.to === loggedInUserId) || (message.from === chatListItem.to && chatListItem.from === loggedInUserId)) {
        chatItemExist = true;

        return [
          Object.assign({}, chatListItem, {
            message: message.message,
            timeStamp: message.timeStamp,
            unread: message.isChatListActive ? 0 : chatListItem.unread + 1,
          }),
          ...prev,
        ];
      }

      // chat list is not exists aka new
      if (chatList.length === index + 1 && !chatItemExist) {
        const { isChatListActive, ...newMessage } = message;
        return [newMessage, chatListItem, ...prev];
      }

      return [...prev, chatListItem];
    }, []);

    dispatch(Creators.updateChatList(updatedChatList));
  };

  // React.useEffect(() => {
  //     const subscribtion = chatObservable.subscribe((message) => {
  //         if (message) {
  //             handleChatListUpdate(message!);
  //         }
  //     });

  //     return () => {
  //         subscribtion.unsubscribe();
  //     }
  // }, [chatList]);

  React.useEffect(() => {
    const fetchChatList = async () => {
      try {
        const result = await getChatList({
          existData: 0,
          limitData: 30,
        });
        // @ts-ignore
        dispatch(Creators.updateChatList(result.data));
      } catch (e) {
        // TODO: handle some error here
      }
    };

    fetchChatList();
    // eslint-disable-next-line
  }, []);

  const goToMessage = React.useCallback(() => {
    history.push("/messages");
    // eslint-disable-next-line
  }, []);

  return (
    <IconButton aria-label={`show ${countMessage} message`} color="inherit" onClick={goToMessage}>
      <Badge badgeContent={countMessage} color="primary">
        <MailIcon />
      </Badge>
    </IconButton>
  );
});

export default MessageAlert;
