import React, { FC } from 'react';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Modal,
    Theme,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from 'components/_newDesign/typography/Typography';
import { ArrowBack } from '@material-ui/icons';

interface Props {
    show: boolean;
    value?: string;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 0, 2),
            width: '897px',
            maxWidth: '897px',
            maxHeight: '100%',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
                minHeight: '80%',
                marginY: '5px'
            },
            borderRadius: '20px',
        },
        header: {
            // width: '805px',
            // maxWidth: '805px',
        },
        'header-item': {
            display: 'flex',
            alignItems: 'center',
        },
        'upload-text-web': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '150%',
            /* identical to box height, or 36px */
            letterSpacing: ' 0.15px',
            color: '#000000',
        },
        'upload-text-mb': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '150%',
            /* identical to box height, or 36px */
            letterSpacing: ' 0.15px',
            color: '#000000',
        },
        modalContent: {
            padding: '0px 24px 16px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 16px 0px',
            },
        },
        pointNumber: {
            width:'28px',
            height:'28px', 
            borderRadius: '50%', 
            backgroundColor: 
            '#f7f7f7',
            fontSize:'16px', 
            fontWeight:600,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        pointsLabel: {
            width:'28px', 
            fontSize:'9px', 
            fontWeight: 600,
            align: 'center'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
        },
    })
);

const EarnPointsInfoModal: FC<Props> = ({ show, onClose }) => {

    const mTitle = 'How to earn points?';
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = (event) => {
        event.stopPropagation();
        onClose();
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            style={{ maxHeight: '100vh' }}
            className={classes.modal}
            BackdropProps={{ style: {backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(10px)'} }}
        >
            <div className={classes.paper}>
                <Grid
                    container
                    className={classes.header}
                    // style={{ padding: '0px 20px' }}
                >
                    <Grid item xs={10} className={classes['header-item']}>
                        <IconButton onClick={handleClose}>
                            <ArrowBack />
                        </IconButton>
                        <Typography className={matches? classes['upload-text-mb'] : classes['upload-text-web']}>
                            {mTitle}
                        </Typography>
                    </Grid>
                </Grid>
                <hr
                    style={{
                        backgroundColor: '#E6E6E6',
                        height: '1px',
                        border: 'none',
                    }}
                />
                <Box width={'100%'} className={classes.modalContent}>
                    <Box mt={2} mx={3}>
                        <Typography style={{color: '#282828', fontWeight: 600}}>Check how many points you get by completing tasks.</Typography>
                    </Box>
                    <Box maxWidth={'100%'} mx={3}>
                    <Grid container direction='row'>

                        <Grid item style={{minWidth:'100%'}}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' my={1}>
                                <Box width={'90%'}>
                                        <Typography style={{color: '#282828', fontWeight: 600}}>Checking one of the 4 boxes</Typography>
                                        <Typography 
                                            style={{color: '#282828', fontWeight: 400}}
                                        >Under Daily tab, there are 4 main tasks you have to check everyday. For every 1 task you checked, you'll get 1 point.</Typography>
                                </Box>
                                <Box width={'10%'} style={{
                                    display: 'flex',
                                    justifyContent:'center',
                                    alignItems: 'center'
                                }}>
                                    <Box display={'flex'} justifyContent={'flex-end'} flexDirection='column'>
                                        <Typography align='center' className= {classes.pointNumber}>1</Typography>
                                        <Typography align="center" className={classes.pointsLabel}>pts</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider/>
                        </Grid>

                        <Grid item style={{minWidth:'100%'}}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' my={1}>
                                <Box width={'90%'}>
                                        <Typography style={{color: '#282828', fontWeight: 600}}>Attending a Zoom Call</Typography>
                                        <Typography 
                                            style={{color: '#282828', fontWeight: 400}}
                                        >We have 3 zoom calls in a week, on every Sunday and two support calls with your coach's group. So, make sure you attend all of them for every 1 zoom call, you'll get 5 points.</Typography>
                                </Box>
                                <Box width={'10%'} style={{
                                    display: 'flex',
                                    justifyContent:'center',
                                    alignItems: 'center'
                                }}>
                                    <Box display={'flex'} justifyContent={'flex-end'} flexDirection='column'>
                                        <Typography align='center' className= {classes.pointNumber}>1</Typography>
                                        <Typography align="center" className={classes.pointsLabel}>pts</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider/>
                        </Grid>

                        <Grid item style={{minWidth:'100%'}}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' my={1}>
                                <Box width={'90%'}>
                                        <Typography style={{color: '#282828', fontWeight: 600}}>Completing Weekly Assessment</Typography>
                                        <Typography 
                                            style={{color: '#282828', fontWeight: 400}}
                                        >It's only once in every beginning of the week. Find the Weekly Assessment form on the top list of your    Daily tab.</Typography>
                                </Box>
                                <Box width={'10%'} style={{
                                    display: 'flex',
                                    justifyContent:'center',
                                    alignItems: 'center'
                                }}>
                                    <Box display={'flex'} justifyContent={'flex-end'} flexDirection='column'>
                                        <Typography align='center' className= {classes.pointNumber}>1</Typography>
                                        <Typography align="center" className={classes.pointsLabel}>pts</Typography>
                                    </Box>
                                    
                                </Box>
                            </Box>
                            <Divider/>
                        </Grid>

                        <Grid item style={{minWidth:'100%'}}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent='space-between' my={1}>
                                <Box width={'90%'}>
                                        <Typography style={{color: '#282828', fontWeight: 600}}>Posting to the Community Page Feed</Typography>
                                        <Typography 
                                            style={{color: '#282828', fontWeight: 400}}
                                        >We're supporting each other here, so don't be shy about posting your daily tips, activities, feelings, well, basically anything—you decide. Every 1 post equals 1 point. Points available up to 3 per day!</Typography>
                                </Box>
                                <Box width={'10%'} style={{
                                    display: 'flex',
                                    justifyContent:'center',
                                    alignItems: 'center'
                                }}>
                                    <Box display={'flex'} justifyContent={'flex-end'} flexDirection='column'>
                                        <Typography align='center' className= {classes.pointNumber}>1</Typography>
                                        <Typography align="center" className={classes.pointsLabel}>pts</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider/>
                        </Grid>

                    </Grid>
                    </Box>

                </Box>
            </div>
        </Modal>
    );
};
export default EarnPointsInfoModal;
