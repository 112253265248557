import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function LveIcon(props: SvgIconProps & Status) {
    const { isActive, isShadowHover } = props;
    const globalClasses = globalStyles();
    return (
        <SvgIcon {...props} className={`${globalClasses.default} ${isActive && globalClasses.active} ${isActive && globalClasses.filled} ${isShadowHover && globalClasses.shadowHover}`}>
            <path d="M14.4721 1.28218C10.3099 -0.978063 9.00008 4.51173 9.00008 4.51173C9.00008 4.51173 7.69026 -0.978063 3.5281 1.28218C-0.634056 3.54071 1.70433 8.33795 2.50693 9.8748C4.18253 13.0831 7.91333 16.4863 8.99976 16.4863C10.3086 16.4863 13.8173 13.0831 15.4926 9.8748C16.2955 8.33795 18.6342 3.54071 14.4721 1.28218Z" 
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
