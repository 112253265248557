import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function PortionIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path d="M7.5 15.4157C11.366 15.4157 14.5 12.2817 14.5 8.41574H7.5V1.41574C3.63401 1.41574 0.5 4.54975 0.5 8.41574C0.5 12.2817 3.63401 15.4157 7.5 15.4157Z" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.5 7.49997C15.5 3.63398 12.366 0.499969 8.5 0.499969V7.49997H15.5Z" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>

);
};
