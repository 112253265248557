import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Paper, Box, Button, Grid, Typography, IconButton, Checkbox } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useStyles } from "./styles";
import history from "services/data/history";
import { savePromoCode, getPromoCodeById } from "services/domain/admin/promocodes";
import { Toast } from "services/util/toast";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MtextArea from "components/controls/CustomInput/MtextArea";
import SwitchIos from "components/_newDesign/switch/SwitchIos";
import { http } from "services/data/http";
import { ArrowBack } from "@material-ui/icons";
import CustomInput from "components/controls/CustomInput/CustomInput";

interface FormikState {
  _id: string;
  code: string;
  redeemLimit: string;
  description: string;
  isActive: boolean;
}

const validationSchema = Yup.object({
  code: Yup.string()
    .required("Promo code is required")
    .test("Unique Promo Code", "Promo code already in use", (value) => {
      return new Promise((resolve, reject) => {
        http
          .get("search/promocode", {
            params: {
              code: value,
            },
          })
          .then((res) => {
            // exists
            res.data.length === 0 ? resolve(true) : res.data[0].code === originalCode ? resolve(true) : resolve(false);
          })
          .catch((err) => {
            // note exists
            resolve(false);
          });
      });
    })
    .matches(/^\S*$/, "No Spaces in the promocode")
    .matches(/^[A-Z0-9]{1,15}$/, "Only Uppercase Character & 15 Characters limit"),
  redeemLimit: Yup.string().required("Usage limit is required").matches(/^\d+$/, "Numbers only"),
  description: Yup.string().required("description is required"),
});

const initialValues = {
  _id: "",
  code: "",
  redeemLimit: "",
  description: "",
  isActive: false,
};

let originalCode = initialValues.code;

const PromoCodeEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState({ ...initialValues });
  const [startDate, setStartDate] = React.useState<Date | moment.Moment>(moment().add(1, "days"));
  const [expiryDate, setExpiryDate] = React.useState<Date | moment.Moment>(moment().add(2, "days"));
  const [noExpiry, setNoExpiry] = React.useState<boolean>(false);
  const classes = useStyles();

  // refresh event should not work, since data is provided from the redux while click the edit button
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const fetchUser = async () => {
    try {
      const result = await getPromoCodeById({ id });
      const { _id, code, redeemLimit, description, isActive, startDate, expiryDate, noExpiry = false } = result.data;

      originalCode = code;
      setUser({ _id, code, redeemLimit, description, isActive });
      setStartDate(startDate);
      setExpiryDate(expiryDate);
      setNoExpiry(noExpiry);
    } catch (e) {
      // TODO: handle error here
    } finally {
      // TODO: handle final here
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    setSubmitting(true);
    try {
      const { _id, code, redeemLimit, description, isActive } = values;

      const toast = Toast({ onClose: goBack });

      await savePromoCode({
        codeId: _id,
        code: code,
        description: description,
        redeemLimit: redeemLimit,
        startDate: startDate,
        expiryDate: expiryDate,
        isActive: isActive,
        noExpiry: noExpiry,
      });

      toast.fire({
        icon: "success",
        title: "Promo code has been updated",
      });
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Update promo code failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleStartDateChange = (startDate) => {
    setStartDate(moment(startDate));
  };

  const handleExpiryDateChange = (expiryDate) => {
    setExpiryDate(moment(expiryDate));
  };

  const handleNoExpiryChange = () => {
    setNoExpiry(!noExpiry);

    // set expiry date to start date but 2099
    if (!noExpiry) {
      setExpiryDate(moment(startDate).set("year", 2099));
    } else {
      setExpiryDate(moment(startDate).add(1, "days"));
    }
  };

  return (
    <>
      <Box
        maxWidth="100%"
        height="80px"
        style={{
          background: "rgba(0, 0, 0, 0.05)",
          backgroundBlendMode: "multiply",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Grid container direction="row" alignItems="center" style={{ height: "100%", justifyContent: "flex-start" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"} width="40px" height="40px" padding={"10px"}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box display="flex" ml={1} justifyContent="center" textAlign={"left"}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "150%",
                color: "#414141",
              }}
            >
              Edit Promotion Code
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Paper className={classes.formContainer}>
        <Box display="flex" justifyContent="center" marginLeft="auto" marginRight="auto">
          <Formik enableReinitialize initialValues={user} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values, submitCount, getFieldProps, getFieldMeta }) => {
              return (
                <Form>
                  <Box pt={7} width="75vh" textAlign="center" className={classes.boxOne}>
                    <Grid container alignItems="center" justify="center" className={classes.titleContainer}>
                      <div className={classes.actionLabel}>Promotion Code Information</div>
                    </Grid>

                    <Typography className={classes.label} style={{ marginTop: "10px" }}>
                      PROMOTION CODE <span className={classes.required}>*</span>
                    </Typography>

                    <div style={{ width: "50vh", textAlign: "start" }}>
                      <Field
                        as={CustomInput}
                        placeholder="Name of promotion code"
                        id="code"
                        autoFocus
                        className={classes.promoCodeHeader}
                        {...getFieldProps("code")}
                        meta={getFieldMeta("code")}
                      />
                    </div>
                    <Typography style={{ margin: "10px 0px 15px", opacity: 0.5, display: "flex" }}>Use alphanumeric characters with no spacing</Typography>
                    <Typography className={classes.label} style={{ marginTop: "10px" }}>
                      USAGE LIMIT <span className={classes.required}>*</span>
                    </Typography>

                    <div style={{ width: "50vh", textAlign: "start" }}>
                      <Field
                        component={CustomInput}
                        type="text"
                        // label={<div className={classes.promoHeaderField}> USAGE LIMIT <span className={classes.required}>*</span></div>}
                        placeholder="Maximum allowable redemption"
                        id="redeemLimit"
                        margin="normal"
                        className={classes.promoCodeHeader}
                        {...getFieldProps("redeemLimit")}
                        meta={getFieldMeta("redeemLimit")}
                        fullWidth
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "start", display: "flex" }}>
                      <div style={{ width: "35%", textAlign: "start", marginRight: "40px" }}>
                        <Typography className={classes.label} style={{ marginTop: "20px" }}>
                          START DATE <span className={classes.required}>*</span>
                        </Typography>
                        <KeyboardDatePicker
                          style={{ width: "100%", marginTop: "10px" }}
                          className={classes.datePicker}
                          variant="inline"
                          format="DD-MMM-yyyy"
                          value={startDate}
                          InputAdornmentProps={{ position: "start" }}
                          autoOk={true}
                          onChange={(date) => handleStartDateChange(date)}
                          minDate={moment(startDate).subtract(1, "days")}
                        />
                      </div>

                      {noExpiry ? (
                        <></>
                      ) : (
                        <div style={{ width: "35%", textAlign: "start" }}>
                          <Typography className={classes.label} style={{ marginTop: "20px" }}>
                            EXPIRY DATE <span className={classes.required}>*</span>
                          </Typography>
                          <KeyboardDatePicker
                            style={{ width: "100%", marginTop: "10px" }}
                            className={classes.datePicker}
                            variant="inline"
                            format="DD-MMM-yyyy"
                            value={expiryDate}
                            InputAdornmentProps={{ position: "start" }}
                            autoOk={true}
                            onChange={(date) => handleExpiryDateChange(date)}
                            minDate={moment(startDate).add(1, "days")}
                            minDateMessage="Expiry date should not be before Start Date"
                          />
                        </div>
                      )}
                    </div>

                    <div style={{ width: "35%", textAlign: "start" }}>
                      <Typography className={classes.label} style={{ marginTop: "20px" }}>
                        This promo code doesn't expire
                      </Typography>
                      <Checkbox checked={noExpiry} color={"primary"} onChange={handleNoExpiryChange} />
                    </div>

                    <Grid container className={classes.formSwitchContainer}>
                      <Grid item md={12} className={classes.switchTitleContainer}>
                        <Typography className={classes.label}>
                          DESCRIPTION <span className={classes.required}>*</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Field
                      as={MtextArea}
                      placeholder="Description"
                      id="description"
                      rows={1}
                      className={classes.inputCustomWidth}
                      {...getFieldProps("description")}
                      meta={getFieldMeta("description")}
                    />
                    <Grid container className={classes.formSwitchContainer}>
                      <Grid item md={12} className={classes.switchTitleContainer}>
                        <Typography className={classes.label}>PROMOTION CODE STATUS</Typography>
                      </Grid>
                    </Grid>
                    <div style={{ width: "35%", textAlign: "start" }}>
                      <Field
                        component={SwitchIos}
                        label={values.isActive ? "Active" : "Inactive"}
                        color="default"
                        style={{ color: values.isActive ? "#FFFFFF" : "#AFAFAF" }}
                        id="isActive"
                        checked={values.isActive}
                        {...getFieldProps("isActive")}
                      />
                      <span>{values.isActive ? "Active" : "Inactive"}</span>
                    </div>
                  </Box>
                  <Box mt={8} display="flex" justifyContent="center" alignItems={"center"}>
                    <Button onClick={goBack} className={classes.buttonCancel} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button variant="contained" className={classes.buttonCreate} type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};

export default PromoCodeEdit;
