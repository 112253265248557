import React, { FC } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
    label?: string;
    value? : string;
}


const CustomText: FC<Props & InputBaseProps> = ({ meta, isSubmitted, placeholder, label, value, ...props }) => {
    return (
        <React.Fragment>
            <Grid container direction='column'>
            <Grid item>
                <Typography style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontSize: '10px',
                    fontWeight: 400,
                    lineHeight: '120%',
                    letterSpacing: '1.5px',
                    color: '#414141'
                }}
                >
                    {label?.toUpperCase()}
                </Typography>
            </Grid>
            <Grid item>
                <Box mt={'14px'} 
                    width={'100%'} 
                    height={'40px'}
                    borderRadius={'12px'}
                    display='flex'
                    alignItems={'center'}
                    style={{
                        backgroundColor: '#F2F2F2',
                    }}>
                    <Typography style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '150%',
                        letterSpacing: '0.25px',
                        color: '#414141',
                        paddingLeft: '12px'
                    }}>{ value }</Typography>
                </Box>
            </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default CustomText;
