import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        fontWeight: 600,
    }
}));

interface ChipDateProps {
    label: string;
}

const ChipDate: React.FC<ChipDateProps> = ({ label }) => {
    const classes = useStyles();

    return (
        <Chip
            className={classes.root}
            label={label}
        />
    );
}

export default ChipDate;
