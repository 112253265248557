import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const PuseBookings = lazy(() => import('./PuseBookings'));

export default {
    path: routeConstants.PAUSE_BOOKINGS.path,
    component: PuseBookings,
    layout: LayoutOptions.dashboard,
};
