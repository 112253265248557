import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const LastWeek = lazy(() => import('./LastWeek'));

export default {
    exact: true,
    path: routeConstants.LASTWEEK.path,
    component: LastWeek,
    layout: LayoutOptions.main,
};
