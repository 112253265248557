import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const SinglePost = lazy(() => import('./SinglePost'));

export default {
    path: routeConstants.SINGLE_POST.path,
    component: SinglePost,
    layout: LayoutOptions.single,
    roles: ['user', 'admin']
};
