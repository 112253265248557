import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MenuItem, Avatar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import ellipsis from 'text-ellipsis';

import { NotificationInterface } from 'interfaces/user';
import dummyAvatar from 'assets/images/dummy-avatar-256.png';

const Container = styled.div`
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #E5E5E5;
    padding-top: 18px;
    padding-bottom: 14px;
`;

const AvatarWrapper = styled.div`
    flex: 0 0 auto;
    margin-right: 10px;
    color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const DisplayName = styled.span`
    font-weight: 500;
`;

const ContentWrapper = styled.div`
    flex: 1 1 auto;
    font-weight: 300;
`;

const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const TimeInfo = styled.div`
    color: #9A9A9A;
`;

const StatusCircle = styled.div`
    width: 16px;
    height: 16px;

    ${((props: { isActive: boolean }) =>
        props.isActive
        ? css`
            background-color: #FFA21E;
            box-shadow: 0px 1.25px 4px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
        `
        : ''
    )}
`;

const getTime = (timeStamp: Date) => {
    const timeDiff = (new Date().getTime() - moment(timeStamp).toDate().getTime()) / 1000 / 3600;

    if (timeDiff > 30 * 24) {
        return moment(timeStamp).format('DD/MM/YYYY');
    }

    if (timeDiff >= 7 * 24) {
        const totalWeek = Math.floor(timeDiff / 7 / 24);

        if (totalWeek === 1) {
            return 'a week ago';
        }

        return `${totalWeek} weeks ago`;
    }

    if (timeDiff >= 24) {
        const totalDay = Math.floor(timeDiff / 24);

        if (totalDay === 1) {
            return 'a day ago';
        }

        return `${totalDay} days ago`;
    }

    return moment(timeStamp).format('hh:mm A');
}

const useStyles = makeStyles((theme: Theme) => ({
    'notification-item-menu': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

interface NotificationItemInterface extends NotificationInterface {
    updateStatusNotification: (notifId: string, afterUpdateCallback: () => void) => void,
}

const NotificationItem = (props: NotificationItemInterface) => {
    const history = useHistory();
    const classes = useStyles();

    const goToPost = (id: string) => {
        history.push(`/single-post/${id}`);
    }

    const handleClickItem = () => {
        if (!props.read) {
            props.updateStatusNotification(props._id, () => goToPost(props.postId));
        } else {
            goToPost(props.postId);
        }
    }

    return (
        <MenuItem onClick={handleClickItem} className={classes["notification-item-menu"]}>
            <Container>
                <AvatarWrapper>
                    <Avatar
                        src={props?.avatar || dummyAvatar}
                        className={classes.avatar}
                    />
                </AvatarWrapper>
                <ContentWrapper>
                    <div>
                        <DisplayName>
                            {props.displayName}
                        </DisplayName>
                        {' '}
                        {props.action}
                    </div>
                    
                    {
                        props.message
                        && (
                            <div>
                                {ellipsis(props.message, 50)}
                            </div>
                        )
                    }

                    <TimeInfo>
                        {getTime(props.timeStamp)}
                    </TimeInfo>
                </ContentWrapper>
                <StatusWrapper>
                    {
                        props.read === false && <StatusCircle isActive/>
                    }
                </StatusWrapper>
            </Container>
        </MenuItem>
    );
};

export default NotificationItem;
