import React, { FC, useState } from 'react';
import { getDailyTotals } from 'services/domain/admin/accounting';
import { Paper, Box, Typography, Tooltip, Button } from '@material-ui/core';
import moment from 'moment';
import { Toast } from 'services/util/toast';
import { formatCentsToDollars } from 'services/util';
import { DayPicker, DateRange } from 'react-day-picker';
import generateCSV from 'services/util/generateCSV';
import Loading from 'components/progress/Loading';

// default to start of yesterday to start of today
const rangeDefault: DateRange = {
    from: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, 'day')
        .toDate(),
    to: moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
};

const UnearnedRevenue: FC<{}> = () => {
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange | undefined>(
        rangeDefault
    );
    const [month, setMonth] = useState<Date>(new Date());

    const fetchDailyTotals = async ({
        startDate,
        endDate,
    }: {
        startDate: any;
        endDate: any;
    }) => {
        try {
            const res = await getDailyTotals({
                startDate,
                endDate,
            });

            return res.data;
        } catch (e) {
            console.error(e);

            throw new Error('Failed to fetch daily totals');
        }
    };

    // handles the click event for the generate report button
    const handleGenerateReportClick = async () => {
        try {
            setLoading(true);

            // if an invalid date range is selected, show an error toast
            if (!dateRange || !dateRange.from || !dateRange.to) {
                const errorToast = Toast({});
                errorToast.fire({
                    icon: 'error',
                    title: 'Failed to generate report',
                    text: 'Please select a date range and try again.',
                });

                return;
            }

            // fetch the data for the selected date range
            const data = await fetchDailyTotals({
                startDate: dateRange.from,
                endDate: moment(dateRange.to).add(1, 'day').toDate(),
            });

            const csvHeaders = ['Date', 'Daily Change', 'New Total'];

            const csvData = data.map((row: any) => {
                return [
                    moment(row.dateCreated).format('MM/DD/YYYY'),
                    formatCentsToDollars(
                        row.movaraHomeUnearnedRevenueDailyChange
                    ),
                    formatCentsToDollars(row.movaraHomeUnearnedRevenueNewTotal),
                ];
            });

            // unearnedRevenueMMDDYYYY-MMDDYYYY.csv
            const filename = `unearnedRevenue${moment(dateRange.from).format(
                'MMDDYYYY'
            )}-${moment(dateRange.to).format('MMDDYYYY')}.csv`;

            // generate the csv file (auto downloads)
            generateCSV(csvHeaders, csvData, filename);
        } catch (error) {
            console.error(error);

            const errorToast = Toast({});
            errorToast.fire({
                icon: 'error',
                title: 'Failed to generate report',
                text: 'Please refresh the page and then try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Typography variant="h4">Unearned Revenue Reporting</Typography>
            <Box mt={3}>
                <Paper>
                    <Box
                        p={3}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {dateRange && dateRange?.from && dateRange?.to ? (
                            <Typography variant="h5" color="primary">
                                {moment(dateRange.from).format('MM/DD/YYYY')} to{' '}
                                {moment(dateRange.to).format('MM/DD/YYYY')}
                            </Typography>
                        ) : null}

                        <DayPicker
                            month={month}
                            onMonthChange={setMonth}
                            className="Selectable"
                            mode="range"
                            selected={dateRange}
                            onSelect={setDateRange}
                        />
                        {loading ? (
                            <Loading />
                        ) : (
                            <Tooltip title="Generate Unearned Revenue Report">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleGenerateReportClick}
                                    disabled={!dateRange || !dateRange?.to}
                                >
                                    Generate Report
                                </Button>
                            </Tooltip>
                        )}
                    </Box>
                </Paper>
            </Box>
        </>
    );
};

export default UnearnedRevenue;
