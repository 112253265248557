import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { http } from "../../../../services/data";
import iconListFinder from "assets/images/icons/list-finder.png";
import iconUser from "assets/images/icons/user.png";
import iconFile from "assets/images/icons/file.png";
import useStyles from "./styles";
import { useProfile } from "services/util";
interface IStatistics {
  assessmentsDailyComplete: IDetail;
  assessmentsWeeklyComplete: IDetail;
  guestThisWeek: IDetail;
  reportedPost: IDetail;
  unAssigned: IDetail;
}

interface IDetail {
  max?: number;
  value: number;
}

const statisticState = {
  assessmentsDailyComplete: {
    max: 0,
    value: 0,
  },
  assessmentsWeeklyComplete: {
    max: 0,
    value: 0,
  },
  guestThisWeek: {
    max: 0,
    value: 0,
  },
  postUpload: {
    value: 0,
  },
  reportedPost: {
    value: 0,
  },
  unAssigned: {
    value: 0,
  },
};

const Statistic: FC = () => {
  const [statistic, setStatistic] = useState<IStatistics>(statisticState);

  // TODO when the /dashboard page is refreshed, this is null, so the page is blank
  const profile = useProfile();

  useEffect(() => {
    http.get("admin/dashboard").then((res) => {
      setStatistic(res);
    });
  }, []);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container>
              <Grid item md={12}>
                <Typography className={classes.header}>GUESTS THIS WEEK</Typography>
              </Grid>
              <Grid item md={6}>
                <Box position="relative" top={14}>
                  <img src={iconUser} alt="user-icon" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography align="right">
                  <Typography component="span" variant="h4">
                    {statistic?.guestThisWeek?.value || 0}
                  </Typography>
                  {profile.role !== "admin" && (
                    <>
                      <Typography component="span">/</Typography>
                      <Typography component="span">{statistic?.guestThisWeek?.max || 0}</Typography>
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container>
              <Grid item md={12}>
                <Typography className={classes.header}>COMPLETED WEEKLY ASSESSMENT</Typography>
              </Grid>
              <Grid item md={6}>
                <Box position="relative" top={14}>
                  <img src={iconListFinder} alt="list-finder-icon" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography align="right">
                  <Typography component="span" variant="h4">
                    {statistic?.assessmentsWeeklyComplete?.value || 0}
                  </Typography>
                  <Typography component="span">/</Typography>
                  <Typography component="span">{statistic?.assessmentsWeeklyComplete?.max || 0}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container>
              <Grid item md={12}>
                <Typography className={classes.header}>POST UPLOAD TODAY</Typography>
              </Grid>
              <Grid item md={6}>
                <Box position="relative" top={14}>
                  <img src={iconFile} alt="file-icon" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography align="right" variant="h4">
                  {statistic?.reportedPost?.value || 0}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container>
              <Grid item md={12}>
                <Typography className={classes.header}>DAILY ASSESSMENT</Typography>
              </Grid>
              <Grid item md={6}>
                <Box position="relative" top={14}>
                  <img src={iconListFinder} alt="list-finder-icon" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography align="right">
                  <Typography component="span" variant="h4">
                    {statistic?.assessmentsDailyComplete?.value || 0}
                  </Typography>
                  <Typography component="span">/</Typography>
                  <Typography component="span">{statistic?.assessmentsDailyComplete?.max || 0}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Statistic;
