import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

export interface ConfirmationOptions {
    catchOnCancel?: boolean;
    variant: "danger" | "info";
    title: string;
    description: string;
    confirmBtnText?: string;
    cancelBtnText?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                          open,
                                                                          title,
                                                                          variant,
                                                                          description,
                                                                          onSubmit,
                                                                          onClose,
                                                                          confirmBtnText,
                                                                          cancelBtnText
                                                                      }) => {
    return (
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {variant === "danger" && (
                    <>
                        <Button color="default" onClick={onClose} autoFocus>
                            {cancelBtnText || 'Cancel'}
                        </Button>
                        <Button color="primary" onClick={onSubmit}>
                            {confirmBtnText || 'Yes, I agree'}
                        </Button>
                    </>
                )}

                {variant === "info" && (
                    <Button color="primary" onClick={onSubmit}>
                        {confirmBtnText || 'OK'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};