/* icons */
import UserIcon from 'components/icons/UserIcon';
import PasswordIcon from 'components/icons/PasswordIcon';
import PadlockIcon from 'components/icons/PadlockIcon';
import AddIcon from 'components/icons/AddIcon';

export default Object.freeze({
    ACCOUNT_SETTING: {
        path: '/account/setting',
        name: 'Account',
        Icon: UserIcon,
    },
    ACCOUNT_PASSWORD: {
        path: '/account/manage-password',
        name: 'Password',
        Icon: PasswordIcon,
    },
    ACCOUNT_PRIVACY: {
        path: '/account/manage-privacy',
        name: 'Privacy',
        Icon: PadlockIcon,
    },
    ADD_MORE_WEEKS: {
        path: '/add-booking?add-weeks',
        name: 'Add More Weeks',
        Icon: AddIcon,
    },
    // ACCOUNT_BOOKING: {
    //     path: '/account/bookings',
    //     name: 'Booking',
    //     Icon: BagIcon,
    // },
});
