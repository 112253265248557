import React from 'react';
import movaraLogo from 'assets/images/movara-logo-new.png';
import { Badge, Box, CircularProgress, Container, Grid, Paper, Typography, Avatar, createStyles, withStyles, Theme } from '@material-ui/core';
import { useStyles } from '../styles';
// import auth from 'services/domain/auth';
import BigAvatar from 'components/layout/Avatar/BigAvatar';
import Button from 'components/_newDesign/button/Button'
import MImageUploader from 'components/MImageUploader';
import { uploadAvatar } from 'services/domain/users';
import { useSnackbar } from 'notistack';

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }),
)(Avatar);

const GetStarted: React.FC<any> = props => {
    const classes = useStyles();
    // const user = auth.userProperties;
    const uploadAvatarRef = React.useRef(null);
    const [imgAvatar, setImgAvatar] = React.useState<any>(undefined);
    const { enqueueSnackbar } = useSnackbar()

    const changeStarted = () => {
        props.changeStarted()
    }

    const avatarClicHandler = () => {
        (uploadAvatarRef as any)?.current.open();
    }

    const postAvatar = async (image: any, doneCallback: () => void) => {
        try {
            const formData = new FormData();
            formData.append('avatar', image)
            const rspn = await uploadAvatar(formData)
            setImgAvatar(rspn.data.path)
            doneCallback();
            setTimeout(() => {
                enqueueSnackbar('Upload Avatar Success', {variant: 'success'});
                (uploadAvatarRef as any)?.current.close();
            }, 300);
        } catch (error) {
            enqueueSnackbar('Upload Avatar Failed', {variant: 'error'})
        } finally {
        }
    }

    React.useEffect(() => {
        if (props.data?.step1?.avatar.path) setImgAvatar(props.data?.step1?.avatar.path)
    }, [props.data])
    return (
        <Container maxWidth="lg">
            <MImageUploader ref={uploadAvatarRef} title="Upload Profile Picture" defaultImage={imgAvatar} onUpload={postAvatar}/>
            <Box my={15}>
                <Paper>
                    <Box px={10} pt={10} pb={10} my={10} className={classes.containerOnboard}>
                        <Grid container>
                            {props.loading ? (
                                <Box display="flex"
                                    pt="10"
                                    className={classes.loadingPost}
                                    alignItems="center"
                                    justifyContent="center">
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <Grid item xs={12}>
                                    <Box mb={5} display="flex" justifyContent="center">
                                        <img style={{maxWidth: '400px', width: '100%'}} src={movaraLogo} alt="movara's logo" />
                                    </Box>
                                    <Box mb={5} display="flex" justifyContent="center">
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                        }}
                                        onClick={avatarClicHandler}
                                        badgeContent={<SmallAvatar style={{width: '56px', height: '56px'}} alt="+" src="/static/images/avatar/1.jpg" />}
                                    >
                                        <BigAvatar src={imgAvatar} style={{width: '256px', height: '256px', cursor: 'pointer'}} onClick={avatarClicHandler} />
                                    </Badge>
                                    </Box>
                                    <Box mb={5} display="flex" justifyContent="center">
                                        <Typography align="center" className={classes.title}>
                                            Welcome to Movara Home, {props.data.firstName}!
                                        </Typography>
                                    </Box>
                                    <Box mb={5} display="flex" justifyContent="center">
                                        <Typography align="center" className={classes.content}>
                                            Please add a photo then click get started.
                                        </Typography>
                                    </Box>

                                    <Box mb={5} display="flex" justifyContent="center">
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            onClick={changeStarted}
                                        >
                                            Get Started
                                        </Button>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default GetStarted;
