import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
        },
        listItem: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            height: 106,
            '&.Mui-selected': {
                background: 'none',
                '&:hover': {
                    background: 'none',
                },
                '&::after': {
                    content: '\'\'',
                    width: 8,
                    height: 90,
                    background: theme.palette.primary.main,
                    position: 'relative',
                    left: 16,
                },
            },
        },

        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            height: 80,
        },
        bookingTable: {
            maxHeight: 150,
        },
        weeksTableIcon: {
            '& th': {
                textAlign: 'center',
            },
            '& tbody': {
                '& td': {
                    textAlign: 'center',
                },
            },
        },
        buttonUsersDaily: {
            padding: 0
        },
        buttonUsersDailyHead: {
            padding: 0,
            fontSize: '9px',
            lineHeight: '100%',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.05em',
            color: '#B3B3B3',
        },
    }),
);

export default useStyles;
