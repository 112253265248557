import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function HeadIcon(props: SvgIconProps & Status) {
    const { isActive } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();
    return (
        <SvgIcon {...props} className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active}`}>
            <path
                d="M13.5 5.96012C13.5 3.22079 11.2119 1 8.3892 1C5.56649 1 3.27872 3.22046 3.27872 5.96012C3.27872 5.96012 3.22646 7.64024 3.01677 8.20007C2.80708 8.76022 2.2043 9.29329 2.67594 9.45347C3.14758 9.61333 3.67181 9.80026 3.85521 10.1467C4.03861 10.4935 4.01231 12.3603 5.40159 12.4666C6.79088 12.5729 7.10509 12.5465 7.31478 13.3465C7.52447 14.1464 7.44591 15.6132 6.60715 17C8.17951 16.3067 11.7965 14.3865 11.7965 14.3865C11.7965 14.3865 11.4297 13.1063 11.5868 11.6931C11.7439 10.2798 12.7138 8.49302 13.0806 7.66634C13.4477 6.83998 13.5 6.70655 13.5 5.96012Z"
                strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
