import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const DashboardUser = lazy(() => import('./DashboardUser'));

export default {
    path: routeConstants.DASHBOARD_USER.path,
    component: DashboardUser,
    layout: LayoutOptions.main,
    roles: ['user', 'admin']
};
