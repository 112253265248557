import React, { FC, useEffect, useState, useRef } from "react";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Snackbar } from "@material-ui/core";
import useStyles from "./styles";
import { Alert } from "@material-ui/lab";
import { logPoint, postWeeklySubmission } from "services/domain/users";
import InfoAlert from "components/alert/InfoAlert";
import moment from "moment";
import InputNumber from "components/_newDesign/input/InputNumber";
import InputText from "components/_newDesign/input/InputText";
import { ImageIcon, WeightIcon, EditIcon, WeistIcon, QuestionCircleIcon, ChestIcon, HipIcon, BodyFatIcon, SpoonForkIcon } from "components/_newIcon";
import ImageUploading from "react-images-uploading";
import ButtonMui from "components/_newDesign/button/Button";
import AmountCaloriesLabel from "components/_modules/NutritionGoalLabel/AmountCaloriesLabel";
import TrackMarcosLabel from "components/_modules/NutritionGoalLabel/TrackMarcosLabel";
import BalancedMealsLabel from "components/_modules/NutritionGoalLabel/BalancedMealsLabel";
import EliminateLabel from "components/_modules/NutritionGoalLabel/EliminateLabel";
import IntermittantLabel from "components/_modules/NutritionGoalLabel/IntermittantLabel";
import NoSnackingLabel from "components/_modules/NutritionGoalLabel/NoSnackingLabel";
import OtherLabel from "components/_modules/NutritionGoalLabel/OtherLabel";
import OzWaterLabel from "components/_modules/NutritionGoalLabel/OzWaterLabel";
import ErrorMessage from "components/_newDesign/alert/ErrorMessage";
import Typography from "components/_newDesign/typography/Typography";
import auth from "services/domain/auth";
import { getNutritionGoal } from "../../../helper/question";

interface WeeklyInterface {
  step?: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
  };
  openModalWarning?: () => void;
  changeStatusStep?: (step: string) => void;
  onSubmit?: () => void;
  data?: any;
  isDone?: boolean;
  onShowModal?: () => void;
}

const defaultForm: any = {
  currentWeight: undefined,
  currentWeightType: "lbs",
  goalWeight: undefined,
  goalWeightType: "lbs",
  goalWeightText: "",
  currentWaist: 0,
  currentWaistType: "inch",
  currentChest: 0,
  currentChestType: "inch",
  currentHip: 0,
  currentHipType: "inch",
  bodyFat: 0,
  nutritionGoal: undefined,
  caloriesAmount: undefined,
  caloriesIsCheck: false,
  trackMacros: false,
  intermittentFasting: false,
  plateMethod: false,
  waterAmount: undefined,
  waterIsCheck: false,
  snacking: false,
  eliminateText: "",
  eliminateIsCheck: false,
  othersText: "",
  othersIsCheck: false,
};

const nutritionGoalOptions = ["caloriesIsCheck", "trackMacros", "intermittentFasting", "plateMethod", "eliminateIsCheck", "othersIsCheck", "waterIsCheck", "snacking"];

const Weekly: FC<WeeklyInterface> = (props) => {
  const classes = useStyles();
  const [form, setForm] = useState<any>(defaultForm);
  const [file, setFile] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [initing, setIniting] = React.useState<boolean>(false);
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");
  const [message, setMessage] = React.useState("");
  const [openNotif, setOpenNotif] = React.useState<boolean>(false);
  const [infoWaist, setInfoWaist] = React.useState<boolean>(false);
  const [infoChest, setInfoChest] = React.useState<boolean>(false);
  const [infoHip, setInfoHip] = React.useState<boolean>(false);
  const [images, setImages] = React.useState<any[]>([]);
  const [submit, setSubmit] = React.useState<boolean>(false);

  const uploadFieldRef = useRef<HTMLButtonElement>(null);
  const currentWeightRef = useRef<HTMLInputElement>(null);
  // const goalWeightRef = useRef<HTMLInputElement>(null);
  const nutritionGoalRef = useRef<HTMLInputElement>(null);
  const goalWeightTextRef = useRef<HTMLInputElement>(null);

  const handleCloseNotif = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };

  const formOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleRemoveImage = () => {
    setImages([]);
    setFile(undefined);
  };

  const handleChange = (e: any) => {
    setImages(e);
    setFile(e[0].file);
  };

  const openNotification = (message: string, severity: "success" | "info" | "warning" | "error") => {
    setSeverity(severity);
    setMessage(message);
    setOpenNotif(true);
  };

  const onSubmit = async () => {
    if (props.step && props.openModalWarning && !props.step.step1) {
      props.openModalWarning();
      return;
    }
    if (auth.userProperties.userData.statusBooking === "Waiting" && props.onShowModal) {
      props.onShowModal();
      return;
    }
    setSubmit(true);

    if (
      !form.currentWeight ||
      // ! No longer using goal weight - keeping commented if client asks for goal weight to be put back
      // !form.goalWeight ||
      !file ||
      !form.nutritionGoal ||
      form.goalWeightText === ""
    ) {
      openNotification("Please fill all required field!", "error");
      if (!form.currentWeight) {
        currentWeightRef?.current?.focus();
        return;
      }
      // ! No longer using goal weight - keeping commented if client asks for goal weight to be put back
      // if (!form.goalWeight) {
      //     goalWeightRef?.current?.focus();
      //     return;
      // }
      if (!file) {
        uploadFieldRef?.current?.focus();
        return;
      }
      if (!form.nutritionGoal) {
        nutritionGoalRef?.current?.focus();
        return;
      }
      if (form.goalWeightText === "") {
        goalWeightTextRef?.current?.focus();
        return;
      }
    }
    nutritionGoalOptions.forEach((option) => {
      if (form.nutritionGoal === option) {
        form[option] = true;
      } else {
        form[option] = false;
      }
    });

    if (form.nutritionGoal === "caloriesIsCheck" && !form.caloriesAmount) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "waterIsCheck" && !form.waterAmount) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "eliminateIsCheck" && (!form.eliminateText || form.eliminateText === "")) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }
    if (form.nutritionGoal === "othersIsCheck" && (!form.othersText || form.othersText === "")) {
      nutritionGoalRef?.current?.focus();
      openNotification("Please fill all required field!", "error");
      return;
    }

    if (form.nutritionGoal === "caloriesIsCheck") {
      form.waterAmount = undefined;
      form.eliminateText = "";
      form.othersText = "";
    }
    if (form.nutritionGoal === "waterIsCheck") {
      form.caloriesAmount = undefined;
      form.eliminateText = "";
      form.othersText = "";
    }
    if (form.nutritionGoal === "eliminateIsCheck") {
      form.caloriesAmount = undefined;
      form.waterAmount = undefined;
      form.othersText = "";
    }
    if (form.nutritionGoal === "othersIsCheck") {
      form.caloriesAmount = undefined;
      form.waterAmount = undefined;
      form.eliminateText = "";
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("scale", file);
      formData.append("currentWeight", form.currentWeight);
      formData.append("currentWeightType", form.currentWeightType);
      formData.append("goalWeight", form.goalWeight);
      formData.append("goalWeightType", form.goalWeightType);
      formData.append("goalWeightText", form.goalWeightText);
      formData.append("currentWaist", form.currentWaist);
      formData.append("currentWaistType", form.currentWaistType);
      formData.append("currentChest", form.currentChest);
      formData.append("currentChestType", form.currentChestType);
      formData.append("currentHip", form.currentHip);
      formData.append("currentHipType", form.currentHipType);
      formData.append("bodyFat", form.bodyFat);
      formData.append("submissionDate", moment().utc(true).toString());
      formData.append("caloriesAmount", form.caloriesAmount ? form.caloriesAmount : "");
      formData.append("caloriesIsCheck", form.caloriesIsCheck);
      formData.append("trackMacros", form.trackMacros);
      formData.append("intermittentFasting", form.intermittentFasting);
      formData.append("plateMethod", form.plateMethod);
      formData.append("waterAmount", form.waterAmount ? form.waterAmount : "");
      formData.append("waterIsCheck", form.waterIsCheck);
      formData.append("snacking", form.snacking);
      formData.append("eliminateText", form.eliminateText);
      formData.append("eliminateIsCheck", form.eliminateIsCheck);
      formData.append("othersText", form.othersText);
      formData.append("othersIsCheck", form.othersIsCheck);
      await postWeeklySubmission(formData);
      await logPoint({
        userId: auth.userProperties.userData._id,
        points: 1,
        activity: `${auth.userProperties.userData.displayName} completed weekly assessment`,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      openNotification("Send Weekly Assessment Success!", "success");
      setForm(defaultForm);
      setFile(undefined);
      props.changeStatusStep && props.changeStatusStep("step2");
      props.onSubmit && props.onSubmit();
    } catch (error) {
      openNotification("Send Weekly Assessment Failed!", "error");
    } finally {
      setLoading(false);
      setSubmit(false);
    }
  };

  const openInfoWaist = () => {
    setInfoWaist(true);
  };

  const openInfoChest = () => {
    setInfoChest(true);
  };

  const openInfoHip = () => {
    setInfoHip(true);
  };

  const init = () => {
    setIniting(true);
    const data = {
      currentWeight: props.data.currentWeight.data || 0,
      currentWeightType: props.data.currentWeight.typeData || "lbs",
      goalWeight: props.data.goalWeight.data || 0,
      goalWeightType: props.data.goalWeight.typeData || "lbs",
      goalWeightText: props.data.goalWeight.text || "",
      currentWaist: props.data.waistMeasurement.data || 0,
      currentWaistType: props.data.waistMeasurement.typeData || "inch",
      currentChest: props.data.chestMeasurement.data || 0,
      currentChestType: props.data.chestMeasurement.typeData || "inch",
      currentHip: props.data.hipMeasurement.data || 0,
      currentHipType: props.data.hipMeasurement.typeData || "inch",
      bodyFat: props.data.bodyFat.data || 0,
      nutritionGoal: getNutritionGoal(props.data?.nutritionGoalWeekly) || undefined,
      caloriesAmount: props.data.nutritionGoalWeekly?.calories?.amount || undefined,
      caloriesIsCheck: props.data.nutritionGoalWeekly?.calories?.isCheck,
      trackMacros: props.data.nutritionGoalWeekly?.trackMacros?.isCheck,
      intermittentFasting: props.data.nutritionGoalWeekly?.intermittentFasting?.isCheck,
      plateMethod: props.data.nutritionGoalWeekly?.plateMethod?.isCheck,
      waterAmount: props.data.nutritionGoalWeekly?.water?.amount || undefined,
      waterIsCheck: props.data.nutritionGoalWeekly?.water?.isCheck,
      snacking: props.data.nutritionGoalWeekly?.snacking?.isCheck,
      eliminateText: props.data.nutritionGoalWeekly?.eliminate?.text || "",
      eliminateIsCheck: props.data.nutritionGoalWeekly?.eliminate?.isCheck,
      othersText: props.data.nutritionGoalWeekly?.others?.text || "",
      othersIsCheck: props.data.nutritionGoalWeekly?.others?.isCheck,
    };
    setForm(data);
    setTimeout(() => {
      setIniting(false);
    }, 1000);
  };
  useEffect(() => {
    if (props.data) init();
    // eslint-disable-next-line
  }, [props.data]);
  return (
    <>
      <Box width="100%" className={classes.weeklyContainer}>
        <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseNotif} severity={severity}>
            {message}
          </Alert>
        </Snackbar>

        <Box display={"flex"} mb={2} className={classes.weeklyContentRes2}>
          <Box display={"flex"}>
            <Box display={"flex"} alignItems={"start"} p={1}>
              <ImageIcon />
            </Box>
            <Box flex="1" p={1} alignSelf={"center"}>
              {props.isDone ? (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                    color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
                  }}
                >
                  Upload a photo of your feet on the scale{" "}
                </Typography>
              ) : (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                  }}
                >
                  UPLOAD A PHOTO OF YOUR FEET ON THE SCALE
                  <span style={{ color: "#EF5136" }}>&#9679;</span>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <ImageUploading value={images} onChange={handleChange} dataURLKey="data_url" acceptType={["jpeg", "jpg", "png"]}>
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
              <>
                {props.isDone ? (
                  <Box display={"flex"} flexDirection={"column"}>
                    <img src={props?.data?.photoScale?.data} width="300px" alt="" />
                  </Box>
                ) : images.length === 0 ? (
                  <Box width={"300px"} border={"2px dashed rgba(0, 0, 0, 0.1)"} textAlign={"center"} py={4} {...dragProps}>
                    <Typography className={classes.uploadDesc}>Drop files here to upload…</Typography>
                    <Button
                      ref={uploadFieldRef}
                      variant="contained"
                      style={{
                        margin: "auto",
                        boxShadow: "none",
                        border: "1px solid #E6E6E6",
                        borderRadius: "10px",
                        textTransform: "none",
                        marginTop: "10px",
                      }}
                      onClick={onImageUpload}
                    >
                      Browse files
                    </Button>
                  </Box>
                ) : (
                  <Box display={"flex"} flexDirection={"column"}>
                    <img src={images[0].data_url} width="300px" alt="" />
                    <Button
                      variant="contained"
                      style={{
                        margin: "auto",
                        boxShadow: "none",
                        border: "1px solid #E6E6E6",
                        borderRadius: "10px",
                        textTransform: "none",
                        marginTop: "10px",
                      }}
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </Button>
                  </Box>
                )}
              </>
            )}
          </ImageUploading>
        </Box>
        {submit && !file && <ErrorMessage style={{ marginLeft: "55px" }} message={"File is Required"} />}

        <Box display={"flex"} mt={2} mb={2} className={classes.weeklyContentRes2}>
          <Box display={"flex"}>
            <Box display={"flex"} alignItems={"start"} p={1}>
              <WeightIcon />
            </Box>
            <Box flex="1" p={1} alignSelf={"center"}>
              {props.isDone ? (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                    color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
                  }}
                >
                  Current Weight
                </Typography>
              ) : (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                  }}
                >
                  What is your current weight? <span style={{ color: "#EF5136" }}>&#9679;</span>
                </Typography>
              )}
              <InputNumber disabled={props.isDone} ref={currentWeightRef} name="currentWeight" value={form.currentWeight} onChange={formOnChange} endAdornment={"Lbs"} />
              {submit && !form.currentWeight && <ErrorMessage message={"Current weight is Required"} />}
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} mt={2} mb={2} className={classes.weeklyContentRes2}>
          <Box display={"flex"}>
            <Box display={"flex"} alignItems={"start"} p={1}>
              <SpoonForkIcon />
            </Box>
            <Box flex="1" p={1} alignSelf={"center"}>
              {props.isDone ? (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                    color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
                  }}
                >
                  what is your nutrition Goal of the week?
                </Typography>
              ) : (
                <Typography
                  variant="mov_overline2"
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "14px",
                  }}
                >
                  what is your nutrition Goal of the week? <span style={{ color: "#EF5136" }}>&#9679;</span>
                </Typography>
              )}
              {submit && !form.nutritionGoal && <ErrorMessage message={"Nutrition Goal is Required"} />}
              {!initing && !props.isDone ? (
                <RadioGroup aria-label="nutrition goal" name="nutritionGoal" value={form.nutritionGoal} onChange={formOnChange} className={classes.radioGroup}>
                  <FormControlLabel
                    style={{ alignItems: "start" }}
                    disabled={props.isDone}
                    value="caloriesIsCheck"
                    control={<Radio />}
                    label={
                      <AmountCaloriesLabel
                        isDone={props.isDone}
                        preview={true}
                        active={form.nutritionGoal === "caloriesIsCheck"}
                        defaultValue={form.caloriesAmount}
                        // eslint-disable-next-line no-useless-computed-key
                        onChange={(value) =>
                          setForm({
                            ...form,
                            ["caloriesAmount"]: value,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{ alignItems: "start" }}
                    value="trackMacros"
                    control={<Radio />}
                    label={<TrackMarcosLabel preview={true} active={form.nutritionGoal === "trackMacros"} />}
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{ alignItems: "start" }}
                    value="intermittentFasting"
                    control={<Radio />}
                    label={<IntermittantLabel preview={true} active={form.nutritionGoal === "intermittentFasting"} />}
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{ alignItems: "start" }}
                    value="plateMethod"
                    control={<Radio inputRef={nutritionGoalRef} />}
                    label={<BalancedMealsLabel preview={true} active={form.nutritionGoal === "plateMethod"} />}
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{
                      alignItems: "start",
                      marginTop: "10px",
                    }}
                    value="waterIsCheck"
                    control={<Radio />}
                    label={
                      <OzWaterLabel
                        defaultValue={form.waterAmount}
                        isDone={props.isDone}
                        preview={true}
                        active={form.nutritionGoal === "waterIsCheck"}
                        // eslint-disable-next-line no-useless-computed-key
                        onChange={(value) =>
                          setForm({
                            ...form,
                            ["waterAmount"]: value,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{ alignItems: "start" }}
                    value="snacking"
                    control={<Radio />}
                    label={<NoSnackingLabel preview={true} active={form.nutritionGoal === "snacking"} />}
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{
                      alignItems: "start",
                      marginTop: "10px",
                    }}
                    value="eliminateIsCheck"
                    control={<Radio />}
                    // eslint-disable-next-line no-useless-computed-key
                    label={
                      <EliminateLabel
                        defaultValue={form.eliminateText}
                        isDone={props.isDone}
                        preview={true}
                        active={form.nutritionGoal === "eliminateIsCheck"}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            ["eliminateText"]: value,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={props.isDone}
                    style={{ alignItems: "start" }}
                    value="othersIsCheck"
                    control={<Radio />}
                    // eslint-disable-next-line no-useless-computed-key
                    label={
                      <OtherLabel
                        defaultValue={form.othersText}
                        isDone={props.isDone}
                        preview={true}
                        active={form.nutritionGoal === "othersIsCheck"}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            ["othersText"]: value,
                          })
                        }
                      />
                    }
                  />
                </RadioGroup>
              ) : (
                <RadioGroup aria-label="nutrition goal" name="nutritionGoal" value={form.nutritionGoal} className={classes.radioGroup}>
                  {form.caloriesIsCheck && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      disabled={true}
                      value="caloriesIsCheck"
                      checked={true}
                      control={<Radio color="primary" />}
                      label={
                        <AmountCaloriesLabel
                          isDone={true}
                          preview={true}
                          active={false}
                          defaultValue={form.caloriesAmount}
                          // eslint-disable-next-line no-useless-computed-key
                          // onChange={(value => setForm({ ...form, ['caloriesAmount']: value }))}
                        />
                      }
                    />
                  )}
                  {form.trackMacros && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      value="trackMacros"
                      checked={true}
                      control={<Radio color="primary" />}
                      label={<TrackMarcosLabel preview={true} active={false} />}
                    />
                  )}
                  {form.intermittentFasting && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      value="intermittentFasting"
                      checked={true}
                      control={<Radio color="primary" />}
                      label={<IntermittantLabel preview={true} active={false} />}
                    />
                  )}
                  {form.plateMethod && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      value="plateMethod"
                      checked={true}
                      control={<Radio color="primary" />}
                      label={<BalancedMealsLabel preview={true} active={false} />}
                    />
                  )}
                  {form.waterIsCheck && (
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginTop: "10px",
                      }}
                      value="waterIsCheck"
                      control={<Radio color="primary" />}
                      checked={true}
                      label={
                        <OzWaterLabel
                          defaultValue={form.waterAmount}
                          isDone={props.isDone}
                          preview={true}
                          active={false}
                          // eslint-disable-next-line no-useless-computed-key
                          onChange={(value) =>
                            setForm({
                              ...form,
                              ["waterAmount"]: value,
                            })
                          }
                        />
                      }
                    />
                  )}
                  {form.snacking && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      value="snacking"
                      checked={true}
                      control={<Radio color="primary" />}
                      label={<NoSnackingLabel preview={true} active={false} />}
                    />
                  )}
                  {form.eliminateIsCheck && (
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginTop: "10px",
                      }}
                      checked={true}
                      value="eliminateIsCheck"
                      control={<Radio color="primary" />}
                      // eslint-disable-next-line no-useless-computed-key
                      label={
                        <EliminateLabel
                          defaultValue={form.eliminateText}
                          isDone={props.isDone}
                          preview={true}
                          active={false}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              ["eliminateText"]: value,
                            })
                          }
                        />
                      }
                    />
                  )}
                  {form.othersIsCheck && (
                    <FormControlLabel
                      style={{ alignItems: "start" }}
                      value="othersIsCheck"
                      checked={true}
                      control={<Radio color="primary" />}
                      // eslint-disable-next-line no-useless-computed-key
                      label={
                        <OtherLabel
                          defaultValue={form.othersText}
                          isDone={props.isDone}
                          preview={true}
                          active={false}
                          onChange={(value) =>
                            setForm({
                              ...form,
                              ["othersText"]: value,
                            })
                          }
                        />
                      }
                    />
                  )}
                </RadioGroup>
              )}
            </Box>
          </Box>
        </Box>

        <Box display={"flex"} mb={2}>
          <Box display={"flex"} alignItems={"center"} p={1}>
            <EditIcon />
          </Box>
          <Box flex="1" p={1} alignSelf={"start"}>
            <Typography
              variant="mov_overline2"
              style={{
                textTransform: "uppercase",
                marginBottom: "14px",
              }}
            >
              <b>
                What is your non-scale goal for the week? <span style={{ color: "#EF5136" }}>&#9679;</span>
              </b>
            </Typography>
            <InputText
              disabled={props.isDone}
              ref={goalWeightTextRef}
              rows="3"
              value={form.goalWeightText}
              onChange={formOnChange}
              multiline
              placeholder="Your story..."
              name="goalWeightText"
            />
            {submit && !form.goalWeightText && <ErrorMessage message={"Non-scale goal weight is Required"} />}
          </Box>
        </Box>
      </Box>
      <Box width="100%" className={classes.weeklyContainer} marginTop={"16px"}>
        <Box display={"flex"} mb={2}>
          <Box display={"flex"} alignItems={"center"} p={1}>
            <Box width="17.58px"></Box>
          </Box>
          <Box flex="1" p={1} alignSelf={"center"}>
            <Typography className={classes.optionalTitle}>OPTIONAL</Typography>
            <Typography className={classes.optionalDesc}>You may choose to show or hide the following information. </Typography>
          </Box>
        </Box>
        <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
          <Box display={"flex"} alignItems={"center"} p={1}>
            <BodyFatIcon />
          </Box>
          <Box p={1} alignSelf={"start"} minWidth={210}>
            <Typography
              variant="mov_overline2"
              style={{
                textTransform: "uppercase",
                marginBottom: "14px",
                color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
              }}
            >
              Body fat
            </Typography>
            <InputNumber disabled={props.isDone} name="bodyFat" value={form.bodyFat} onChange={formOnChange} endAdornment={"%"} />
          </Box>
        </Box>
        <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
          <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoWaist}>
            <WeistIcon />
          </Box>
          <Box p={1} alignSelf={"start"} onMouseEnter={openInfoWaist} minWidth={210}>
            <Typography
              variant="mov_overline2"
              style={{
                textTransform: "uppercase",
                marginBottom: "14px",
                color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
              }}
            >
              Waist measurement
            </Typography>
            <InputNumber disabled={props.isDone} name="currentWaist" value={form.currentWaist} onChange={formOnChange} endAdornment={"In"} />
          </Box>
          <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoWaist}>
            <QuestionCircleIcon />
          </Box>
        </Box>
        <Box display={"flex"} mb={1}>
          <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
            <InfoAlert
              onClose={() => setInfoWaist(false)}
              isOpen={infoWaist}
              text="Use tape to circle the waist like a belt would. The crease that forms is your natural waistline, located above your belly button and below the rib cage."
              closeable={true}
            />
          </Box>
        </Box>

        <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
          <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoChest}>
            <ChestIcon />
          </Box>
          <Box p={1} alignSelf={"start"} onMouseEnter={openInfoChest} minWidth={210}>
            <Typography
              variant="mov_overline2"
              style={{
                textTransform: "uppercase",
                marginBottom: "14px",
                color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
              }}
            >
              Chest measurement
            </Typography>
            <InputNumber disabled={props.isDone} name="currentChest" value={form.currentChest} onChange={formOnChange} endAdornment={"In"} />
          </Box>
          <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoChest}>
            <QuestionCircleIcon />
          </Box>
        </Box>
        <Box display={"flex"} mb={1}>
          <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
            <InfoAlert
              onClose={() => setInfoChest(false)}
              isOpen={infoChest}
              text="Wrap the tape around your bust, under armpits, around shoulder blades and back to the front."
              closeable={true}
            />
          </Box>
        </Box>

        <Box display={"flex"} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: "flex-start" }}>
          <Box display={"flex"} alignItems={"center"} p={1} onMouseEnter={openInfoHip}>
            <HipIcon />
          </Box>
          <Box p={1} alignSelf={"start"} onMouseEnter={openInfoHip} minWidth={210}>
            <Typography
              variant="mov_overline2"
              style={{
                textTransform: "uppercase",
                marginBottom: "14px",
                color: `${props.isDone ? "#9A9A9A" : "#414141"}`,
              }}
            >
              Hip measurement&nbsp;&nbsp;&nbsp;&nbsp;
            </Typography>
            <InputNumber disabled={props.isDone} name="currentHip" value={form.currentHip} onChange={formOnChange} endAdornment={"In"} />
          </Box>
          <Box display={"flex"} className={classes.weeklyInputRes} p={1} alignSelf={"center"} onMouseEnter={openInfoHip}>
            <QuestionCircleIcon />
          </Box>
        </Box>
        <Box display={"flex"} mb={1}>
          <Box display={"flex"} alignItems={"center"} p={1} flexGrow={1}>
            <InfoAlert
              onClose={() => setInfoHip(false)}
              isOpen={infoHip}
              text="Make sure to measure the largest part of your buttocks. Begin from one side of the hip, wrap the tape measure around your rear, other hip and back to where it started."
              closeable={true}
            />
          </Box>
        </Box>
        {!props.isDone && (
          <Box width="100%" textAlign="center">
            <ButtonMui disabled={loading} variant="contained" color="primary" onClick={onSubmit}>
              {loading ? "Submitting..." : "Submit"}
            </ButtonMui>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Weekly;
