import React from "react";
import { SignUpLayout } from "components/layout/containers";
import StepPayment from "./component/StepPayment";
import Feedback from "components/layout/Feedback";
import PaymentStore from "./component/PaymentStore";

const Payment = () => {
  return (
    <>
      <PaymentStore>
        <SignUpLayout>
          <StepPayment />
        </SignUpLayout>
        <Feedback />
      </PaymentStore>
    </>
  );
};

export default Payment;
