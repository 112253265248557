import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DoubleCheckIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            {...props}
            style={{
                color: '#FFA11E',
                width: props.width || '16px',
                height: props.height || '16px',
            }}
        >
            <path d="M4.13867 8.21341L7.81714 11.6332L14.6665 4.96655" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.33325 8.21341L5.01172 11.6332M11.8611 4.96655L7.68246 9.14521" stroke="#6D6D6D" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
}
