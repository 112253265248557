import { useContext, useEffect } from 'react';
import { PaginationContext } from '../context/PaginationContext';

export const usePagination = () => {
    const pagination = useContext(PaginationContext);

    useEffect(() => {
        return () => {
            pagination.reset();
        };
        // eslint-disable-next-line
    }, []);

    return pagination;
}
