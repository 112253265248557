import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabHeader: {
            borderBottom: '1px solid #DDDDDD',
        },
        paper: {
            padding: theme.spacing(1),
        },
        filterAccordion: {
            marginBottom: theme.spacing(2),
        },
        dayAssessmentModal: {
            backgroundColor: '#F8F8F8',
        },
        weeksTableIcon: {
            '& th': {
                textAlign: 'center',
            },
            '& tbody': {
                '& td': {
                    textAlign: 'center',
                },
            },
        },
        modalRoot: {
            margin: 0,
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        modalNextButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(2),
            color: theme.palette.grey[500],
        },
        modalPrevButton: {
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(2),
            color: theme.palette.grey[500],
        },
        buttonUsersDaily: {
            padding: 0
        },
        buttonUsersDailyHead: {
            padding: 0,
            fontSize: '9px',
            lineHeight: '100%',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.05em',
            color: '#B3B3B3',
        },
        popperStyle: {
            width: '300px !important',
            marginTop: '-10px !important',
            '& .MuiAutocomplete-paper': {
                borderRadius: '0px 0px 8px 8px',
                borderTop: '1px solid transparent',
                boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
                backgroundColor: '#f9f9f9',
                padding:'0px 10px',
                '& .MuiAutocomplete-listbox': {
                    '& .MuiAutocomplete-option': {
                        paddingLeft: '5px'
                    },
                    '& .MuiAutocomplete-option[data-focus="true"]': {
                        backgroundColor: '#FFEACC',
                        borderRadius: '7px',
                    }
                }
            }
        },
        buttonSort: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 8px',
    
            background: '#FFFFFF',
    
            border: '1px solid #FFA21E',
    
            boxShadow: '4px 4px 12px rgba(204, 204, 204, 0.5), -4px -4px 12px rgba(255, 255, 255, 0.7)',
            borderRadius: '10px',
            cursor: 'pointer'
        },
        buttonApply: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 8px',
    
            background: '#FFA21E',
    
            border: '1px solid #FFA21E',
    
            boxShadow: '4px 4px 12px rgba(204, 204, 204, 0.5), -4px -4px 12px rgba(255, 255, 255, 0.7)',
            borderRadius: '10px',
            cursor: 'pointer',
            color: '#FFFFFF'
        },
        buttonFont: {
            fontFamily: 'Montserrat',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '22px',
            /* identical to box height */
    
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.025em',
    
            /* Primary/Primary */
    
            color: '#FFA21E',
        },
        buttonFontApply: {
            fontFamily: 'Montserrat',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '22px',
            /* identical to box height */
    
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            letterSpacing: '0.025em',
    
            /* Primary/Primary */
    
            color: '#FFFFFF',
        },
        buttonFixed: {
            position: 'fixed',
            top: 68,
            right: 0
        }
    }),
);

export default useStyles;
