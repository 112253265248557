import React, { FC } from 'react';
import { Box, createStyles, Theme, Typography } from '@material-ui/core';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStylesFacebook = makeStyles((theme: Theme) =>
    createStyles({
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bottomSelected: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            backgroundColor: '#FFDAA5',
            borderRadius: '50%',
        },
        top: {
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }),
);

const CircularProgressWithLabel: FC<any> = (props: CircularProgressProps & { value: number, children: any, selected: any }) => {
    const classes = useStylesFacebook();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                size={40}
                thickness={4}
                {...props}
                className={props.selected ? classes.bottomSelected : classes.bottom}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                thickness={4}
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                {...props}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {props.children ? (
                    <>
                        {props.children}
                    </>
                ) : (
                    <>
                        <Typography variant="caption" component="div" color="textSecondary">
                            {`${Math.round(props.value)}%`}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};
export default CircularProgressWithLabel;
