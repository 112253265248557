import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmationServiceProvider } from "./components/dialog/ConfirmationService";
import MomentUtils from "@date-io/moment";
import {
  Router,
  Switch,
  Route,
  // Link,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

/**
 * config
 */
import { theme } from "./config";
import routes from "pages/routes";
/**
 * router history
 */
import history from "services/data/history";

import PrivateRoute from "./components/core/PrivateRoute";

/**
 * layout containers
 */
import { DashboardLayout } from "components/layout/containers";

/**
 * pages / modules
 */
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EditPassword from "./pages/SetNewPassword";
import Account from "./pages/Account";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Programs from "./pages/Programs";
import Users from "./pages/Users";
import Bookings from "./pages/Bookings";
import Videos from "./pages/Vidoes";
import ReportsAssessment from "./pages/Reports/Assessment/Assessment";
import ReportsGuest from "./pages/Reports/Guest/Guest";
import ReportsVideo from "./pages/Reports/Video/Video";
import WeeklyProgram from "./pages/Reports/WeeklyProgram/WeeklyProgram";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/404";
import Activate from "./pages/Activate";
import OnBoarding from "./pages/OnBoarding";
import Payment from "./pages/Payment";
import BookingsCoach from "./pages/BookingsCoach";
import ProfileSubmitted from "./pages/OnBoarding/ProfileSubmitted";
import PromoCode from "./pages/PromoCode";
import LinkExpired from "pages/LinkExpired";
import LayoutProgress from "components/layout/LayoutProgress";
import { TabMenuProvider } from "./context/tabMenu.context";
import JournalEntries from "pages/Accounting";
import UnearnedRevenue from "pages/Accounting/UnearnedRevenue";
import CoachReporting from "pages/Accounting/CoachReporting";
import CoachReports from "pages/Accounting/CoachReports";

function App() {
  return (
    <TabMenuProvider value="daily">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ConfirmationServiceProvider>
              <Router history={history}>
                <Suspense fallback={<LayoutProgress />}>
                  <Switch>
                    <PrivateRoute path="/dashboard" component={Dashboard} layout={DashboardLayout} />
                    {routes.map((route) => (
                      <PrivateRoute key={route.path} {...route} />
                    ))}
                    <PrivateRoute path="/plan-program" component={Programs} layout={DashboardLayout} />
                    <PrivateRoute path="/users/:action?/:id?" component={Users} layout={DashboardLayout} />
                    <PrivateRoute path="/bookings" component={Bookings} layout={DashboardLayout} />
                    <PrivateRoute path="/bookings-coach" component={BookingsCoach} layout={DashboardLayout} />
                    <PrivateRoute path="/videos" component={Videos} layout={DashboardLayout} />
                    <PrivateRoute path="/account" component={Account} layout="single" />
                    <PrivateRoute path="/profile/:action?" component={Profile} layout="single" />
                    <PrivateRoute path="/user-profile/:id" component={Profile} layout="single" />
                    <PrivateRoute path="/reports/assessment" component={ReportsAssessment} layout={DashboardLayout} />
                    <PrivateRoute path="/reports/guest" component={ReportsGuest} layout={DashboardLayout} />
                    <PrivateRoute path="/reports/video" component={ReportsVideo} layout={DashboardLayout} />
                    <PrivateRoute path="/reports/weekly-program" component={WeeklyProgram} layout={DashboardLayout} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/set-new-password" component={EditPassword} />
                    <Route exact path="/reset-password/:token/:email" component={ResetPassword} />
                    <Route exact path="/auth/activate/:token" component={Activate} />
                    <Route exact path="/onboarding" component={OnBoarding} />
                    <Route exact path="/profile-submitted" component={ProfileSubmitted} />
                    <Route exact path="/payment" component={Payment} />
                    <Route exact path="/add-booking" component={Payment} />
                    <Route exact path="/sign-up" component={Payment} />
                    <PrivateRoute path="/promo-code" component={PromoCode} layout={DashboardLayout} />
                    <PrivateRoute exact path="/accounting/journal-entries" component={JournalEntries} layout={DashboardLayout} />
                    <PrivateRoute exact path="/accounting/unearned-revenue" component={UnearnedRevenue} layout={DashboardLayout} />
                    <PrivateRoute exact path="/accounting/coach-reports" component={CoachReporting} layout={DashboardLayout} />
                    <PrivateRoute path="/accounting/coach-reports/:id" component={CoachReports} layout={DashboardLayout} />
                    <Route exact path="/token-expired/:email" component={LinkExpired} />
                    <Route path="/forbidden" component={Forbidden} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Suspense>
              </Router>
            </ConfirmationServiceProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </TabMenuProvider>
  );
}

export default App;
