import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    weightpanel_value: {
        fontSize: 36,
    },
    coachAvatar: {
        width: '96px',
        height: '96px'
    },
    popUpTitleModal: {
        padding: '16px',
        borderBottom: '2px',
        borderBottomColor: '#E6E6E6',
        borderBottomStyle: 'solid',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight:' 150%',
        letterSpacing: '0.15px',
    },
    popUpSubTitleModal: {
        padding: '10px 0px 15px 24px',
        fontSize: '16px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight:' 150%',
        letterSpacing: '0.15px',
    },
    footerSpace: {
        height: '30px'
    },
    closeArrow: {
        cursor: 'pointer',
        fontSize: '32px',
        alignSelf: 'center'
    },
    previewCloseArrow: {
        cursor: 'pointer',
        marginTop: '2vh',
        marginRight: '2vh',
    },
    previewBackArrow: {
        cursor: 'pointer',
        marginTop: '2vh',
        marginLeft: '2vh',
    },
});