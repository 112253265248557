import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { InjectedLoaderProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    },
));

const withBackdropLoader = (Component: React.ComponentType<InjectedLoaderProps>) => {
    return () => {
        const [open, setOpen] = useState(false);
        const styles = useStyles();
        
        const show = () => setOpen(true);
    
        const hide = () => setOpen(false);

        return (
            <div>
                <Backdrop className={styles.backdrop} open={open}>
                    <CircularProgress />
                </Backdrop>
                <Component showLoader={show} hideLoader={hide} />
            </div>
        );
    };
};

export default withBackdropLoader;
