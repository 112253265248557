import React, { FC, useState } from 'react';
import { DateRangePicker, DateRange, DefinedRange } from 'materialui-daterange-picker';
import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';

interface DateRangePickerWrapperProps {
    label?: string;
    margin?: 'normal';
    value: DateRange;
    minDate?: Date | string;
    maxDate?: Date | string;
    onChange: (dateRange: DateRange) => void;
    closeOnClickOutside?: boolean;
    closeOnSelection?: boolean;
    wrapperClassName?: string;
    definedRanges?: DefinedRange[];
}

const useStyles = makeStyles(() => ({
    dateRangePicker: {
        position: 'absolute',
    },
}));

const DateRangePickerInput: FC<DateRangePickerWrapperProps> = ({
    onChange, value, label, margin, closeOnSelection, ...otherDateProps
}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initVal, setInitVal] = useState<DateRange>();
    const classes = useStyles();
    const handleToggle = () => {
        setShow(!show)
    }

    const { startDate, endDate } = value;
    const formattedValue = `${moment(startDate).format('DD-MMM-YYYY')} to ${moment(endDate).format('DD-MMM-YYYY')}`;

    const onChangeHandler = (dateRange: DateRange) => {
        const sundayOne = moment(dateRange.startDate).startOf('week').toISOString();
        const sundayTwo = moment(dateRange.endDate).startOf('week').toISOString();
        onChange({startDate: new Date(sundayOne), endDate: new Date(sundayTwo)});
        // if (closeOnSelection) {
        //     handleToggle();
        // }
    }

    React.useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setInitVal(value)
            setLoading(false)
        }, 1);
        // eslint-disable-next-line
    }, [value.endDate, value.startDate])

    return (
        <>
            <TextField
                label={label}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleToggle}
                            >
                                <EventIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={formattedValue}
                fullWidth
                type="text"
                margin={margin}
            />
            {!loading && (
                <DateRangePicker
                    definedRanges={[]}
                    open={show}
                    toggle={handleToggle}
                    onChange={onChangeHandler}
                    wrapperClassName={classes.dateRangePicker}
                    initialDateRange={initVal}
                    {...otherDateProps}
                />
            )}
        </>
    );
};
export default DateRangePickerInput