import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Dashboard = lazy(() => import('./Dashboard'));

export default {
    path: routeConstants.DASHBOARD.path,
    component: Dashboard,
    layout: LayoutOptions.dashboard,
};
