import { FC } from 'react';
import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    createStyles
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { CoachProfil } from 'interfaces/user';
import Typography from 'components/_newDesign/typography/Typography'
import { VerifiedUserIcon, UserGroupIcon } from 'components/_newIcon'
import Button from 'components/_newDesign/button/Button'
import { getListCoachByid } from 'services/domain/users';
import moment from 'moment'
interface Props {
    onClose: () => void;
    open: boolean;
    data?: CoachProfil;
    onSelect: (data?: CoachProfil) => void;
}
const useStylesDialog = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 0, 2),
            width: '623px',
            maxWidth: '623px',
            maxHeight: '95%',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: '10px',
            },
            borderRadius: '20px',
        },
        titleModal: {
            padding: '16px',
        },
        contentModal: {
            padding: '0px',
        },
        header: {
            // width: '805px',
            // maxWidth: '805px',
        },
        'header-item': {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '36px',
            lineHeight: '100%',
            color: '#000000',
        },
        content: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#000000',
        },
        'upload-text': {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '150%',
            /* identical to box height, or 36px */

            letterSpacing: ' 0.15px',

            color: '#000000',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
        },
        modalContent: {
            padding: '0px 24px 16px 24px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 16px 0px',
            },
        }
    })
);

const DetailModal: FC<Props> = ({ onClose, open, data, onSelect }) => {
    const classes = useStyles();
    const dialogClass = useStylesDialog();
    const [coach, setCoach] = React.useState<CoachProfil>()
    const getCoach = async (id) => {
        try {
            const respn = await getListCoachByid(id, moment().format('YYYY-MM-DD'))
            setCoach(respn.data[0])
        } catch (error) {
            
        }
    }
    React.useEffect(() => {
        if (data) getCoach(data._id)
    }, [data])
    return (
        <Dialog  onClose={onClose} open={open} classes={{ paper: dialogClass.paper }}>
            <DialogTitle classes={{ root: dialogClass.titleModal }}>
                <Box display="flex" flex={1} justifyContent={'space-between'}>
                    <ArrowBack onClick={onClose} style={{ cursor: 'pointer' }}/>
                    <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }}/>
                </Box>
            </DialogTitle>
            <DialogContent classes={{root: dialogClass.contentModal}}>
                <Box px={5}>
                    <Box display={'flex'} justifyContent={'center'} pb={1}>
                        <Avatar alt={data?.name} src={data?.avatar}
                                className={classes.coachAvatar}/>
                    </Box>
                    <Box justifyContent={'center'} display="flex">
                        <Typography variant="mov_subtitle1_bold" style={{display: 'flex', alignItems: 'center' }}>
                            {data?.name}
                            <VerifiedUserIcon/>
                        </Typography>
                    </Box>
                    <Box justifyContent={'center'} display="flex">
                        <Typography variant="mov_subtitle1_reguler" style={{display: 'flex', alignItems: 'center' }}>
                            Coach
                        </Typography>
                    </Box>
                    <Box justifyContent={'center'} display="flex" pb={3}>
                        <Typography variant="mov_body2" style={{display: 'flex', alignItems: 'center', color: '#9A9A9A' }}>
                            <UserGroupIcon />&nbsp; Currently coaching {coach?.schedule.length} members
                        </Typography>
                    </Box>
                    <Box pb={3}>
                        <Typography gutterBottom>
                            {data?.bio}
                        </Typography>
                    </Box>
                </Box>
                <hr
                    style={{
                        backgroundColor: '#E6E6E6',
                        height: '1px',
                        border: 'none',
                    }}
                />
                <div style={{ padding: '20px 20px', textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes['upload-button']}
                        onClick={() => onSelect(data)}
                    >
                        Select Coach
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default DetailModal;