import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { PopUpIcon } from 'components/_newIcon';
import NutritionModal from './NutritionModal'
interface ButtonModal {
    type: 'amount_of_calories' | 'balanced_meals' | 'eliminate' | 'intermittent_fasting' | 'no_snacking' | 'other' | 'oz_of_water' | 'track_macros';
    title: string;
    preview: boolean;
}

const ButtonPopUp: FC<ButtonModal> = (props) => {
    const [show, setShow] = React.useState<boolean>(false);
    return (
        <>
            <Button
                variant="contained"
                style={{
                    margin: 'auto',
                    boxShadow: 'none',
                    border: '1px solid #E6E6E6',
                    borderRadius: '10px',
                    textTransform: 'none',
                }}
                onClick={() => {
                    setShow(true);
                }}
            >
                <PopUpIcon /> &nbsp;<u>See how it’ll look</u>
            </Button>
            <NutritionModal
                show={show}
                onClose={() => setShow(false)}
                title={props.title}
                type={props.type}
                preview={props.preview}
            />
        </>
    );
};

export default ButtonPopUp;
