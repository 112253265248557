import React from 'react';
import {
    Box,
    Card,
    CardContent,
    withStyles,
    createStyles,
    Theme,
    LinearProgress,
} from '@material-ui/core';
import Button from 'components/_newDesign/button/Button';

import { useStyles } from './Form/styles';
import Auth from 'services/domain/auth';
import movaraLogo from 'assets/images/movWel.png';
import { useHistory } from 'react-router-dom';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 17,
            borderRadius: 10,
            boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
        },
        colorPrimary: {
            backgroundColor: '#F8F8F8',
        },
        bar: {
            borderRadius: 5,
        },
    })
)(LinearProgress);

const Finished: React.FC<any> = (props) => {
    const classes = useStyles();
    const history = useHistory()
    const backDashboard = () => {
        history.goBack();
    }
    const backlogin = () => {
        Auth.logout()
    }
    return (
        <Box mt={5} mb={15} display="flex" justifyContent="center">
            <Card className={classes.rootCheckout} classes={{ root: classes.cardRoot}}>
                <CardContent>
                    <Box className={classes.cardContentWrapper}>
                        <Box mb={5} maxWidth="700px" margin="auto">
                            <BorderLinearProgress
                                variant="determinate"
                                value={100}
                            />
                        </Box>
                        <Box
                            my={5}
                            display="flex"
                            justifyContent="center"
                            maxWidth="700px"
                            margin="auto"
                        >
                            <Box textAlign="center">
                                <img
                                    src={movaraLogo}
                                    alt="movara's logo"
                                    width="60%"
                                />
                            </Box>
                        </Box>
                        <Box
                            my={5}
                            display="flex"
                            justifyContent="center"
                            maxWidth="700px"
                            margin="auto"
                        >
                            <Box mb={2} textAlign="center">
                                <div
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        fontSize: '36px',
                                        lineHeight: '100%',
                                        textAlign: 'center',
                                        letterSpacing: '0.05em',
                                        color: '#000000',
                                    }}
                                >
                                    Thank you for your booking,{' '}
                                    {props.profile.firstName}{' '}
                                    {props.profile.lastName}!
                                </div>
                                <br />
                                <br />
                                <br />
                                <div
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        fontSize: '16px',
                                        lineHeight: '150%',
                                        textAlign: 'center',
                                        letterSpacing: '0.05em',
                                        color: '#000000',
                                    }}
                                >
                                    {Auth.userProperties === null ? (
                                        <span>
                                            We’ve sent you an onboarding email,
                                            have you received it?
                                            <br />
                                            You need to click the activation
                                            button in order to hop on our
                                            <br /> Movara Home platform.
                                        </span>
                                    ) : (
                                        <span>
                                            Welcome back! You’ll receive a
                                            reminder email the day before the
                                            start date (falls on a Sunday) that
                                            you selected. We’re excited to have
                                            you back!
                                        </span>
                                    )}

                                    <br />
                                    <br />
                                    <br />
                                    <span
                                        style={{
                                            fontWeight: 500,
                                        }}
                                    >
                                        Congratulations for prioritizing your
                                        health.{' '}
                                    </span>
                                    <br />
                                    <br />
                                </div>
                                <div
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        fontSize: '18px',
                                        lineHeight: '150%',
                                        textAlign: 'center',
                                        letterSpacing: '0.05em',
                                        color: '#000000',
                                    }}
                                >
                                    "You don't have to see the whole staircase,
                                    just take the first step."
                                    <br />
                                    <br /> <i>— Martin Luther King, Jr.</i>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        fontSize: '16px',
                                        lineHeight: '150%',
                                        textAlign: 'center',
                                        letterSpacing: '0.05em',
                                        color: '#000000',
                                    }}
                                >
                                    Need help?{' '}
                                    <span style={{ color: '#FFA21E' }}>
                                        call
                                    </span>{' '}
                                    or{' '}
                                    <span style={{ color: '#FFA21E' }}>
                                        email
                                    </span>{' '}
                                    support
                                </div>
                            </Box>
                        </Box>
                        <Box my={5}
                            display="flex"
                            justifyContent="center"
                            maxWidth="700px"
                            margin="auto">
                            {Auth.userProperties !== null ? (
                                <Box pt={1} width="100%" textAlign="center">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        onClick={backDashboard}
                                    >
                                        Back to Booking
                                    </Button>
                                </Box>
                            ) : (
                                <Box pt={1} width="100%" textAlign="center">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        onClick={backlogin}
                                    >
                                        Back to Login
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Finished;
