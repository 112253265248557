import React, { FC, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import ChipDate from '../../components/ChipDate';
import { Message, DateSeparator } from 'interfaces/chat';
import auth from 'services/domain/auth';
import { useInfiniteScroll, useAdjustedScroll } from '../../hooks';
import { usePrevious } from 'services/util';

const Container = styled.div`
    flex: 2;
    overflow-y: overlay;
    padding: 0 24px;
    position: relative;
`;

const LoadingMore = styled.div`
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
`;

type StyledProp = {
    isMine: any;
};

const MessageItem = styled.div`
    display: inline-block;
    position: relative;
    max-width: 100%;
    border-radius: 7px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
    margin-bottom: 10px;
    clear: both;

    &::after {
        content: '';
        display: table;
        clear: both;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 3px;
        width: 12px;
        height: 19px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    ${(props: StyledProp) =>
        props.isMine
            ? css`
                float: right;
                background-color: #fff;
                border: 0.5px solid #FFA21E;
            `
            : css`
                float: left;
                background-color: #E5E5E5;
            `
        }
`;

const Contents = styled.div`
    padding: 8px;
    word-wrap: break-word;

    &::after {
        content: ' \\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0';
        display: inline;
    }
`;

const Timestamp = styled.div`
    position: absolute;
    bottom: 2px;
    right: 7px;
    color: gray;
    font-size: 12px;
`;

const DateSeparatorContainer = styled.div`
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
`;

const CoachInfoApproveContainer = styled.div`
    width: 100%;
    display: inline-block;
    text-align: center;
`;

const CoachInfoApprove = styled.div`
    width: auto;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #FFDAA5;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 2px 13px;
    color: #414141;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;

`;

const isMessageArray = (item: any): item is Message => {
    return typeof item?.message === 'string';
}

interface MessageListProps {
    messages: Message[] | DateSeparator[];
    loadMore: () => void;
    hasMore: boolean;
}

const MessageList: FC<MessageListProps> = ({ messages, loadMore, hasMore }) => {
    const prevMessagesLength = usePrevious(messages.length);
    const loggedUserId = auth.userProperties?.userData?._id;
    const selfRef = useRef(null);
    const [isFetching, stopFetching] = useInfiniteScroll({
        onLoadMore: loadMore,
        hasMore,
        ref: selfRef!,
    });

    const adjustScroll = useAdjustedScroll(selfRef);

    useEffect(() => {
        if (!selfRef.current) return;
        
        if (prevMessagesLength !== messages.length) {
            if (isFetching) {
                stopFetching();
                adjustScroll();
            } else {
                adjustScroll(true);
            }
        }
        // eslint-disable-next-line
    }, [messages.length, selfRef, isFetching, stopFetching, adjustScroll])

    return (
        <Container ref={selfRef}>
            {isFetching && <LoadingMore><CircularProgress color="primary" /></LoadingMore>}
            {
                // eslint-disable-next-line
                (messages as Array<Message | DateSeparator>).map((message) => {
                    if (isMessageArray(message) && (message.messageType === 'chat' || !message.messageType)) {
                        return (
                            
                            <MessageItem
                                isMine={message.from === loggedUserId}
                                key={message.message_id}
                            >
                                <Contents>{message.message}</Contents>
                                <Timestamp>{moment(message.timeStamp).format('hh:mm A')}</Timestamp>
                            </MessageItem>
                        )
                    }
                    if (isMessageArray(message) && message.to === loggedUserId && message.messageType === 'info') {
                        return (
                            <CoachInfoApproveContainer key={message.message_id}>
                                <CoachInfoApprove> {message.message} </CoachInfoApprove>
                            </CoachInfoApproveContainer>
                        )
                    }
                    if (message.messageType === 'dateSeparator') {
                        return <DateSeparatorContainer key={message.dateSeparator}><ChipDate label={message.dateSeparator} /></DateSeparatorContainer>
                    }
                })
            }
        </Container>
    );
}

export default React.memo(MessageList);
