import React, { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgressWithLabel from "../../../../../components/progress/CircularProgressWithLabel";
import { Rating } from "@material-ui/lab";
import { HeadIcon, StretchIcon, CookIcon, GymIcon } from "components/icons";

const OverviewOptional: FC<any> = (props) => {
  const classes = useStyles();

  const renderSwitch = (param: string) => {
    switch (param) {
      case "stretch":
        return (
          <React.Fragment>
            <Box>
              <StretchIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Daily Stretch</Typography>
            </Box>
          </React.Fragment>
        );
      case "fitness":
        return (
          <React.Fragment>
            <Box>
              <GymIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Daily Fitness</Typography>
            </Box>
          </React.Fragment>
        );
      case "personalDev":
        return (
          <React.Fragment>
            <Box>
              <HeadIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Daily Personal Dev</Typography>
            </Box>
          </React.Fragment>
        );
      case "breakfast":
        return (
          <React.Fragment>
            <Box>
              <CookIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Breakfast</Typography>
            </Box>
          </React.Fragment>
        );
      case "lunch":
        return (
          <React.Fragment>
            <Box>
              <CookIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Lunch </Typography>
            </Box>
          </React.Fragment>
        );
      case "soup":
        return (
          <React.Fragment>
            <Box>
              <CookIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Soup </Typography>
            </Box>
          </React.Fragment>
        );
      case "dinner":
        return (
          <React.Fragment>
            <Box>
              <CookIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Dinner </Typography>
            </Box>
          </React.Fragment>
        );
      case "dessert":
        return (
          <React.Fragment>
            <Box>
              <CookIcon style={{ color: "transparent" }} />
            </Box>
            <Box>
              <Typography> Dessert </Typography>
            </Box>
          </React.Fragment>
        );
      default:
        return "";
    }
  };
  return (
    <Grid item md={12}>
      <Accordion defaultExpanded>
        <AccordionSummary className={classes.boxHeader} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>OVERVIEW OPTIONAL TASK</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {props.data?.map((item: any, index: number) => (
              <Grid key={index} item md={12} className={classes.boxHeader}>
                <Box display="flex" alignItems="center" padding={2}>
                  <Box flexBasis={"33%"} display="flex">
                    {renderSwitch(item.name)}
                  </Box>
                  <Box margin="auto">
                    <Rating name="read-only" value={item.rating || 0} max={4} readOnly />
                  </Box>
                  <Box flexBasis={"33%"} flexDirection="row-reverse" display="flex">
                    <CircularProgressWithLabel value={item.precentage || 0} size={50} />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
export default OverviewOptional;
