import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            paddingBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        tableSortLabel : {
            display: 'block'
        }
    },
));
