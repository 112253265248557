import React, { FC } from 'react';
import { Button as ButtonMui, makeStyles, Theme } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";

interface Props extends MuiButtonProps {
    buttonText?: string
}

const useStyles = makeStyles((_theme: Theme) => ({
    newDesign: {
        padding: '16px 16px',
        borderRadius: '12px',
        boxShadow: 'none',
        color: 'black',
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '100%',
        letterSpacing: '0.25px',
    }
}))

const Button: FC<Props> = props => {
    const classess = useStyles()
    return (
        <>
            <ButtonMui {...props} className={classess.newDesign} >
                {props.children}
            </ButtonMui>
        </>
    );
};
export default Button;