import React from "react";
import clsx from "clsx";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { useStyles } from "../_config/styles";

export default function PlayCircleIcon({ className, ...props }: SvgIconProps) {
  const classes = useStyles();

  return (
    <SvgIcon {...props} viewBox="0 0 24 25" className={clsx(classes.root, className ?? className)}>
      <circle cx="12" cy="12.1749" r="11.75" stroke="#414141" />
      <path d="M18 12.1749L9 17.3711L9 6.97877L18 12.1749Z" stroke="#414141" strokeLinejoin="round" />
    </SvgIcon>
  );
}
