import { http } from "services/data";
import { UserIdInterface, UserRequestDefault, UserGuestRequest, Name, UpdateRoleRequest, UpdateCoachRequest, UpdateCoachVisiblity } from "interfaces/user";
import { PaginationInterface } from "interfaces/common";
import qs from "qs";

const endPoints = {
  users: "admin/allUser",
  guestUsers: "admin/allUser/role/guest",
  employeeUsers: "admin/allUser/role/guest/employee",
  coachUsers: "admin/allUser/role/coach",
  coachUserAsReference: "admin/allUser/role/coach/nameList",
  userById: "admin/user",
  createUser: "admin/user/add",
  updateUser: "admin/user/edit",
  updateRole: "admin/changeRole",
  updateCoach: "admin/changeCoach",
  deleteUser: "/delete/user",
  userAssessment: "admin/assessments/report/users",
  categoryAssessment: "admin/assessments/report/category",
  updateVisibility: "admin/changeVisibility",
  getZoom: "/zoom/attendance",
  postZoom: "/zoom/attendance/log",
};

interface ZoomPayload {
  date: string;
  coachId?: string;
}

export const getUsers = (payload: any | undefined | null) => http.get(endPoints.users, { params: payload ?? undefined });
export const getGuestUsers = (payload: PaginationInterface) => http.get(endPoints.guestUsers, { params: payload });
export const getEmployeeUsers = (payload: PaginationInterface) => http.get(endPoints.employeeUsers, { params: payload });
export const getCoachUsers = (payload: PaginationInterface) => http.get(endPoints.coachUsers, { params: payload });
export const getCoachUserAsReference = () => http.get(endPoints.coachUserAsReference);
export const getUserById = (payload: UserIdInterface) => http.get(endPoints.userById, { params: payload });
export const createUser = (payload: UserGuestRequest) => http.post(endPoints.createUser, payload);
export const updateUser = (payload: UserRequestDefault) => http.post(endPoints.updateUser, payload);
export const updateRole = (payload: UpdateRoleRequest) => http.post(endPoints.updateRole, payload);
export const updateCoach = (payload: UpdateCoachRequest) => http.post(endPoints.updateCoach, payload);
export const deleteUser = (idUser: string) => http.post(endPoints.deleteUser, { id: idUser });
export const getUserAssessment = (payload: any) =>
  http.get(endPoints.userAssessment, {
    params: payload ?? undefined,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
export const getCategoryAssessment = (payload: any) => http.get(endPoints.categoryAssessment, { params: payload ?? undefined });
export const updateCoachVisiblity = (payload: UpdateCoachVisiblity) => http.post(endPoints.updateVisibility, payload);
export const getZoom = (payload: ZoomPayload) => http.get(endPoints.getZoom, { params: payload });
export const postZoom = (payload: any) => http.post(endPoints.postZoom, payload);

/**
 * selectors
 */

export const coachOptions = (users: { _id: string; name: Name }[]): [string, string][] => {
  if (!users) return [];

  return users.map((user) => [user._id, `${user.name.firstName} ${user.name.lastName}`]);
};
