import React, { useEffect } from 'react';
import {
    Box,
    Card,
    CardMedia,
    Typography,
    CardContent,
    CircularProgress
} from '@material-ui/core';

import BigAvatar from 'components/layout/Avatar/BigAvatar';
import ProfileForm from './Form';
import { useStyles } from './Form/styles';
import profileCover from 'assets/images/header.png';

import { uploadAvatar, uploadHeader } from 'services/domain/users';
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add';
import { InfoIcon } from 'components/icons'
import MImageUploader from 'components/MImageUploader';

const ProfileContainer: React.FC<any> = (props) => {
    // eslint-disable-next-line
    const [loadingAvatar, setLoadingAvatar] = React.useState<boolean>(false);
    const [loadingHeader, setLoadingHeader] = React.useState<boolean>(false);
    const [imgAvatar, setImgAvatar] = React.useState<any>(undefined);
    const [imgHeader, setImgHeader] = React.useState<any>(undefined);
    const { enqueueSnackbar } = useSnackbar()
    const uploadAvatarRef = React.useRef(null);
    const uploadCoverRef = React.useRef(null);
    // eslint-disable-next-line
    const [profile, setProfile] = React.useState({
        displayName: '',
        about: {
            age: 0,
            beenGuest: {
                isCheck: false,
                desc: '',
            },
            bio: '',
            isShowAbout: false,
            location: '',
            occupation: '',
        },
        avatar: {
            path: '',
        },
    });
    const classes = useStyles();

    const postAvatar = async (image: any, doneCallback: () => void) => {
        try {
            const formData = new FormData();
            formData.append('avatar', image)
            const rspn = await uploadAvatar(formData)
            setImgAvatar(rspn.data.path)
            doneCallback();
            setTimeout(() => {
                enqueueSnackbar('Upload Avatar Success', {variant: 'success'});
                (uploadAvatarRef as any)?.current.close();
            }, 300);
        } catch (error) {
            enqueueSnackbar('Upload Avatar Failed', {variant: 'error'})
        } finally {
        }
    }

    const postHeader = async (image: any, doneCallback: () => void) => {
        try {
            setLoadingHeader(true)
            const formData = new FormData();
            formData.append('header', image)
            const rspn = await uploadHeader(formData)
            setImgHeader(rspn.data.path)
            doneCallback();
            setTimeout(() => {
                enqueueSnackbar('Upload Header Success', {variant: 'success'});
                (uploadCoverRef as any)?.current.close();
            }, 300);
        } catch (error) {
            enqueueSnackbar('Upload Header Failed', {variant: 'error'})
        } finally {
            setLoadingHeader(false)
        }
    }

    const avatarClicHandler = () => {
        (uploadAvatarRef as any)?.current.open();
    }

    const headerClicHandler = () => {
        (uploadCoverRef as any)?.current.open();
    }

    const changeStatusStep = (key: string) => {
        props.changeStatusStep(key)
    }

    // const checkAvatar = () => {
    //     if (imgAvatar === undefined) {
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    useEffect(() => {
        props.profile.avatar && setImgAvatar(props.profile.avatar.path)
        props.profile.headerProfile && setImgHeader(props.profile.headerProfile.path)
    }, [props.profile])

    return (
        <Box my={15} display="flex" justifyContent="center">
            <MImageUploader ref={uploadAvatarRef} title="Upload Photo" defaultImage={imgAvatar ? imgAvatar : profile?.avatar?.path} onUpload={postAvatar}/>
            <MImageUploader ref={uploadCoverRef} title="Upload Photo" defaultImage={imgHeader } onUpload={postHeader}/>
            <Card className={classes.root}>

                <label htmlFor="header-button-file">
                    <div className={classes.container} onClick={headerClicHandler}>
                        <CardMedia
                            className={classes.image}
                            height={'300px'}
                            component='img'
                            image={`${imgHeader ? imgHeader : profileCover}`}
                            title="Click to update Image Header"
                        />
                        {loadingHeader ? (
                            <div className={classes.middleLoading}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <div className={classes.middle}>
                                <AddIcon fontSize="large"/>
                                <Typography  className={classes.coverInfo}> <InfoIcon style={{marginRight: 5}}/> Set up a cover image that makes you happy!</Typography>
                            </div>
                        )}

                    </div>
                </label>
                <CardContent>
                    <Box className={classes.cardContentWrapper}>
                        <Box display="flex">
                            <Box>

                            <label htmlFor="avatar-button-file">
                                <div className={classes.containerAvatar} onClick={avatarClicHandler}>
                                    <BigAvatar src={`${imgAvatar ? imgAvatar: BigAvatar}`} classStyle={`${classes.avatar}`} />
                                    {loadingAvatar ? (
                                        <div className={classes.middleLoadingAvatar}>
                                            <CircularProgress color="primary" />
                                        </div>
                                    ) : (
                                        <div className={classes.middleAvatar}>
                                            <AddIcon fontSize="large"/>
                                            <Typography  className={classes.coverInfo}> <InfoIcon style={{marginRight: 5}}/> Set up a profile picture</Typography>
                                        </div>
                                    )}

                                </div>
                            </label>
                            </Box>
                        </Box>
                        <ProfileForm profile={props.profile} changeStatusStep={(key: string) => changeStatusStep(key)} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default ProfileContainer;