import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const SingleVideo = lazy(() => import('./SingleVideo'));

export default {
    path: routeConstants.SINGLE_VIDEO.path,
    component: SingleVideo,
    layout: LayoutOptions.single,
    roles: ['user', 'admin']
};
