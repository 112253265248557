import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Paper, Box, InputLabel, Button, Grid, Typography, Avatar, IconButton, MenuItem, Select, Menu } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useStyles } from "./styles";
import history from "services/data/history";
import { getBookingById, saveBooking, pauseBooking } from "services/domain/admin/bookings";
import { fUTCToLocalDate } from "services/util/common";
import { BookingInterface } from "interfaces/booking";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { Toast } from "services/util/toast";
import { ArrowBack, CreditCard } from "@material-ui/icons";
import StatusBooking from "components/_newDesign/status/StatusBooking";
import { getUserByEmail } from "services/domain/users";
import CustomText from "components/_modules/BookingSuperUser/CustomInputs/CustomText";
import BookingController from "components/_modules/BookingSuperUser/BookingInfo/BookingController";
import Loading from "components/progress/Loading";
import { CalendarIcon } from "components/_newIcon";

interface BookingWithName extends BookingInterface {
  firstName: string;
  lastName: string;
}
interface AdminFormState {
  formState: BookingWithName;
}

type DateRange = {
  startDate: Date;
  endDate: Date;
};

type UserBooking = {
  id: string;
  pauseDateRange: DateRange;
  startDate: Date;
  endDate: Date;
  paymentStatus: string;
};

type UserProfile = {
  avatar?: string;
  statusBooking?: string;
  email?: string;
};
interface FormikState {
  email: string;
  phoneNumber: string;
  address: string;
  bookingData: UserBooking[];
  firstName: string;
  lastName: string;
}
interface BookingDetails {
  id?: string;
  email?: string;
  startDate: Date;
  endDate: Date;
  originalStartDate?: Date;
  originalEndDate?: Date;
  pauseStartDate?: Date;
  pauseEndDate?: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
  refunds?: { date: Date; amount: number }[];
  createdAt?: Date;
}

const l_startDate = moment()
  .add(7 - moment().isoWeekday(), "days")
  .toDate();
const l_endDate = moment(l_startDate).add(1, "week").toDate();
const initialBookingDetails: BookingDetails = {
  startDate: l_startDate,
  endDate: l_endDate,
  coach_id: "",
  coach_name: "",
  totalWeeks: 1,
};

const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  // phoneNumber: Yup.string()
  //     .matches(phoneRegExp, 'Phone number is not valid')
  //     .required('Phone number is required'),
  // address: Yup.string(),
  // no need to send payload below
  firstName: Yup.string(),
  lastName: Yup.string(),
});

const initialValues = {
  email: "",
  phoneNumber: "",
  address: "",
  bookingData: [
    {
      id: "",
      pauseDateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      startDate: new Date(),
      endDate: new Date(),

      paymentStatus: "",
    },
  ],
  firstName: "",
  lastName: "",
};

const BookingEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [bookingDetails, setBookingDetails] = React.useState<BookingDetails>(initialBookingDetails);
  const [isPaused, setIsPaused] = useState(false);
  const [isLastWeek, setIsLastWeek] = useState(false);
  const [currentBookingDetails, setCurrentBookingDetails] = useState<BookingDetails>(initialBookingDetails);
  const [paymentStatus, setPaymentStatus] = React.useState<string>("default");
  const [bookingStatus, setBookingStatus] = React.useState<string>("Active");
  const [bookingData, setBookingData] = useState<any>();
  const [_formState, set_formState] = React.useState<BookingWithName>();
  const [isBookingLoading, setIsBookingLoading] = React.useState(true);

  const booking = useSelector(({ admin: { formState } }: { admin: AdminFormState }) => {
    const obj = Object.assign(initialValues, {
      email: formState?.email ?? "",
      phoneNumber: formState?.phoneNumber ?? "",
      address: formState?.address ?? "",
      bookingData: [
        {
          id,
          pauseDateRange:
            formState?.startDate && formState.endDate
              ? {
                  startDate: fUTCToLocalDate(formState.startDate as string),
                  endDate: fUTCToLocalDate(formState.endDate as string),
                }
              : {
                  startDate: initialValues.bookingData[0].pauseDateRange.startDate,
                  endDate: initialValues.bookingData[0].pauseDateRange.endDate,
                },
          startDate: formState?.startDate || initialValues.bookingData[0].startDate,
          endDate: formState?.endDate || initialValues.bookingData[0].endDate,
          paymentStatus: formState?.paymentStatus ?? "",
        },
      ],
      firstName: formState?.firstName ?? "",
      lastName: formState?.lastName ?? "",
    });
    return obj;
  });
  const [displayPauseTooltip, setDisplayPauseTooltip] = useState(false);
  const pauseMenuItem = useRef<HTMLLIElement | null>(null);

  const [userProfile, setUserProfile] = React.useState<UserProfile>({});
  const classes = useStyles();

  const handleStatusIsPause = () => {
    // set initial start and end to the pause start and end - if they don't exist - select this coming sunday
    const nextSunday = moment().day(7);
    const followingSunday = moment().day(14);

    const sundayInUTC = new Date(Date.UTC(nextSunday.year(), nextSunday.month(), nextSunday.date(), 0, 0, 0)).toISOString();
    const followingSundayInUTC = new Date(Date.UTC(followingSunday.year(), followingSunday.month(), followingSunday.date(), 0, 0, 0)).toISOString();

    const pauseStartDate = moment(bookingData.pauseStartDate, "YYYY-MM-DD");
    const pauseEndDate = moment(bookingData.pauseEndDate, "YYYY-MM-DD");
    const pauseStartValue = bookingData.pauseStartDate
      ? new Date(Date.UTC(pauseStartDate.year(), pauseStartDate.month(), pauseStartDate.date(), 0, 0, 0)).toISOString()
      : sundayInUTC;
    const pauseEndValue = bookingData.pauseEndDate
      ? new Date(Date.UTC(pauseEndDate.year(), pauseEndDate.month(), pauseEndDate.date(), 0, 0, 0)).toISOString()
      : followingSundayInUTC;

    setIsPaused(true);
    setBookingDetails({
      ...bookingDetails,
      // @ts-ignore
      pauseStartDate: pauseStartValue,
      // @ts-ignore
      pauseEndDate: pauseEndValue,
    });
  };

  const fetch = async () => {
    try {
      const user = await getUserByEmail({ email: booking.email });
      const { avatar, statusBooking, email } = user.data;
      setUserProfile({ avatar, statusBooking, email });

      const bookData = await getBookingById(id);

      setBookingData(bookData.data);

      const bookingDetail: BookingDetails = {
        id: bookData.data._id,
        email: bookData.data.email,
        startDate: bookData.data.startDate,
        endDate: bookData.data.endDate,
        originalStartDate: bookData.data?.originalStartDate ?? "",
        originalEndDate: bookData.data?.originalEndDate ?? "",
        pauseStartDate: bookData.data?.pauseStartDate ?? "",
        pauseEndDate: bookData.data?.pauseEndDate ?? "",
        coach_id: bookData.data.coach_id,
        coach_name: bookData.data.coachName,
        totalWeeks: bookData.data.totalWeeks,
        refunds: bookData.data?.refunds ?? [],
        createdAt: bookData.data.createdAt,
      };

      setCurrentBookingDetails({
        ...bookingDetail,
      });

      bookData.data?.paymentStatus?.toLowerCase() === "paid" ? setPaymentStatus("Paid") : setPaymentStatus(bookData.data.paymentStatus);
      setBookingStatus(bookData.data.statusBooking);

      // TODO not sure we need this
      if (bookData.data.statusBooking === "Pause") {
        // set initial start and end to the pause start and end - if they don't exist - select this coming sunday
        const nextSunday = moment().day(7);

        const sundayInUTC = new Date(Date.UTC(nextSunday.year(), nextSunday.month(), nextSunday.date(), 0, 0, 0)).toISOString();

        const pauseStartDate = moment(bookData.data.pauseStartDate, "YYYY-MM-DD");
        const pauseEndDate = moment(bookData.data.pauseEndDate, "YYYY-MM-DD");
        const pauseStartValue = bookData.data.pauseStartDate
          ? new Date(Date.UTC(pauseStartDate.year(), pauseStartDate.month(), pauseStartDate.date(), 0, 0, 0)).toISOString()
          : sundayInUTC;
        const pauseEndValue = bookData.data.pauseEndDate
          ? new Date(Date.UTC(pauseEndDate.year(), pauseEndDate.month(), pauseEndDate.date(), 0, 0, 0)).toISOString()
          : sundayInUTC;

        setIsPaused(true);
        // TODO change this to the pause start and end
        // @ts-ignore
        bookingDetail.pauseStartDate = pauseStartValue;
        // @ts-ignore
        bookingDetail.pauseEndDate = pauseEndValue;
      }

      setBookingDetails(bookingDetail);
      setIsBookingLoading(false);
    } catch (e) {}
  };

  // refresh event should not work, since data is provided from the redux while click the edit button
  React.useEffect(() => {
    if (!booking.email) {
      goBack();
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    try {
      setSubmitting(true);
      const { firstName, lastName, bookingData: bookingDataValue, ...payload } = values;

      const toast = Toast({ onClose: goBack });

      let bookingStatusChangeText = "";

      if (!bookingDetails) {
        console.error("No Booking Details!");
        throw new Error("No Booking Details!");
      }

      const pause = bookingStatus === "Pause";

      if (pause) {
        const l_start = moment(currentBookingDetails.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        const l_end = moment(currentBookingDetails.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let l_totalWeeks = moment(moment(l_end, "YYYY-MM-DD")).diff(moment(l_start, "YYYY-MM-DD"), "weeks");

        const pauseStart = moment(bookingDetails.pauseStartDate, "YYYY-MM-DD").format("MM/DD/YYYY");

        const pauseEnd = moment(bookingDetails.pauseEndDate, "YYYY-MM-DD").format("MM/DD/YYYY");

        if (pauseStart === pauseEnd) {
          const errorToast = Toast({});
          errorToast.fire({
            icon: "error",
            title: "Start and end date cannot be the same",
          });
          setSubmitting(false);
          return;
        }

        if (bookingData.weeksPaused) {
          l_totalWeeks = l_totalWeeks - bookingData.weeksPaused;
        }

        const bookingPayload = {
          bookId: id,
          email: payload.email,
          address: payload.address,
          phoneNumber: payload.phoneNumber,
          coach_id: bookingDetails.coach_id,
          coach_name: bookingDetails.coach_name,
          startDate: moment(bookingDetails.startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
          endDate: moment(bookingDetails.endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
          totalWeeks: l_totalWeeks,
          paymentStatus,
          bookingStatus,
        };

        await saveBooking(bookingPayload);

        const pausePayload = {
          bookingId: id,
          startDate: pauseStart,
          endDate: pauseEnd,
          coachId: bookingDetails.coach_id,
          reasonToPause: "admin/coach pause",
        };

        const pauseRes = await pauseBooking(pausePayload);

        const { startDate, endDate } = pauseRes.data;

        bookingStatusChangeText = `<p>Booking will be paused from <b>${moment(startDate, "YYYY/MM/DD").format("MMM/DD/YYYY")}</b> to <b>${moment(endDate, "YYYY/MM/DD").format(
          "MMM/DD/YYYY",
        )}</b><p>`;
      } else {
        const l_start = moment(bookingDetails.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        const l_end = moment(bookingDetails.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let l_totalWeeks = moment(moment(l_end, "YYYY-MM-DD")).diff(moment(l_start, "YYYY-MM-DD"), "weeks");
        if (bookingData.weeksPaused) {
          l_totalWeeks = l_totalWeeks - bookingData.weeksPaused;
        }

        const bookingPayload = {
          bookId: id,
          email: payload.email,
          address: payload.address,
          phoneNumber: payload.phoneNumber,
          startDate: moment(bookingDetails.startDate, "YYYY-MM-DD"),
          endDate: moment(bookingDetails.endDate, "YYYY-MM-DD"),
          totalWeeks: l_totalWeeks,
          coach_id: bookingDetails.coach_id,
          coach_name: bookingDetails.coach_name,
          paymentStatus,
          bookingStatus,
        };

        const bookingSaveRes = await saveBooking(bookingPayload);

        const { data: dataBeforeStatusCheck, bookingAfterStatusCheck } = bookingSaveRes;

        if (bookingAfterStatusCheck.statusBooking !== dataBeforeStatusCheck.statusBooking) {
          bookingStatusChangeText = `<p>Booking status has been changed from <b>${dataBeforeStatusCheck.statusBooking}</b> to <b>${bookingAfterStatusCheck.statusBooking}</b><p>`;
        }
      }

      if (bookingStatusChangeText) {
        // if status changed, show this toast
        toast.fire({
          icon: "success",
          title: "Booking record has been updated",
          html: bookingStatusChangeText,
        });
      } else {
        // if status not changed, show this toast
        toast.fire({
          icon: "success",
          title: "Booking record has been updated",
        });
      }
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Update booking record failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    // if they change the status to Pause - we need to set the dates in the future as those are the only valid pause dates
    // if it is anything else, it needs to be the original dates
    if (isBookingLoading || !bookingDetails) return;

    if (bookingStatus === "Pause") {
      handleStatusIsPause();
      return;
    }

    // if the previous bookingStatus was "Pause" and no longer is "Pause" then we need to put the dates back to the original dates
    if (isPaused) {
      setBookingDetails({
        ...bookingDetails,
        startDate: currentBookingDetails.startDate,
        endDate: currentBookingDetails.endDate,
      });
      setIsPaused(false);
    }
  }, [bookingStatus]);

  useEffect(() => {
    if (!currentBookingDetails) return;

    const thisSaturdayDate = moment().day(6).format("MM/DD/YYYY");
    const thisSundayDate = moment().day(7).format("MM/DD/YYYY");
    const bookingEndDate = moment(currentBookingDetails.endDate).format("MM/DD/YYYY");
    if (thisSaturdayDate === bookingEndDate || thisSundayDate === bookingEndDate) {
      // is last week of booking - can't pause
      setIsLastWeek(true);
    }
  }, [currentBookingDetails]);

  const pauseEndDate = bookingData && bookingData.statusBooking === "Pause" ? moment(bookingData.pauseEndDate, "YYYY-MM-DD").format("MMM/DD/YY") : "";

  return (
    <>
      <Box
        maxWidth="100%"
        height="80px"
        style={{
          background: "rgba(0, 0, 0, 0.05)",
          backgroundBlendMode: "multiply",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Grid container direction="row" alignItems="center" style={{ height: "100%", justifyContent: "flex-start" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"} width="40px" height="40px" padding={"10px"}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box display="flex" ml={1} justifyContent="center" textAlign={"left"}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "150%",
                color: "#414141",
              }}
            >
              Edit Booking
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Paper className={classes.formContainer}>
        <Box maxWidth="756px" marginLeft="auto" marginRight="auto">
          <Formik enableReinitialize initialValues={booking} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <Form>
                  <Box mb={"20px"}>
                    <InputLabel>ID</InputLabel>
                    <Typography>{}</Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} mb={"42px"}>
                    <Box mx={1}>
                      <Avatar src={userProfile.avatar ?? dummyAvatar} />
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "150%",
                          marginLeft: "8px",
                          paddingBottom: "5px",
                        }}
                      >
                        {`${values.firstName} ${values.lastName}`}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "150%",
                          color: "#9A9A9A",
                          marginLeft: "8px",
                          paddingBottom: "5px",
                        }}
                      >
                        {values?.email || ""}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "150%",
                            color: "#9A9A9A",
                            marginLeft: "8px",
                            paddingBottom: "0px",
                          }}
                        >
                          User Status:
                        </Typography>
                        <StatusBooking status={userProfile?.statusBooking || ""} endDate={pauseEndDate} />
                      </Box>
                    </Box>
                  </Box>
                  <Box my={3} width="100%" height="516px" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px">
                    <Box marginX={"20px"}>
                      <Box paddingTop={"20px"}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "150%",
                          }}
                        >
                          Personal Info
                        </Typography>
                      </Box>
                      <Box width={"400px"}>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="First Name (*)" name="firstName" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Last Name (*)" name="lastName" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Email Address (*)" name="email" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Phone Number (*)" name="phoneNumber" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Address (*)" name="address" margin="normal" fullWidth />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      my={3}
                      width="100%"
                      style={{
                        backgroundColor: "#FAFAFA",
                        paddingBottom: "25px",
                      }}
                      borderRadius="20px"
                    >
                      {isBookingLoading && bookingDetails ? (
                        <Box height={400} display={"flex"} alignItems={"center"}>
                          <Loading />
                        </Box>
                      ) : (
                        <BookingController
                          mode={"edit"}
                          booking={bookingDetails}
                          bookingStatus={bookingStatus}
                          pause={bookingStatus === "Pause"}
                          onBookingInfoChange={(data) => {
                            setBookingDetails(data);
                          }}
                          paymentStatus={paymentStatus}
                        >
                          <Box width={"363px"} mt={"30px"} mx={"20px"}>
                            <Grid container direction="column">
                              <Grid item>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                  <CreditCard />
                                  <Typography
                                    style={{
                                      marginLeft: "10px",
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontSize: "10px",
                                      fontWeight: 400,
                                      lineHeight: "120%",
                                      letterSpacing: "1.5px",
                                      color: "#414141",
                                    }}
                                  >
                                    PAYMENT STATUS
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  mt={"10px"}
                                  width={"100%"}
                                  height={"40px"}
                                  borderRadius={"12px"}
                                  display="flex"
                                  alignItems={"center"}
                                  style={{
                                    backgroundColor: "#F2F2F2",
                                  }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentStatus}
                                    onChange={(event) => setPaymentStatus(event.target.value as string)}
                                    style={{
                                      width: "100%",
                                    }}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {/* Paid value will be chosen for any variation of paid: Paid, paid, PAID */}
                                    <MenuItem selected value={"Paid"}>
                                      Stripe Checkout
                                    </MenuItem>
                                    <MenuItem value="comp">Comp</MenuItem>
                                    <MenuItem value="movara_challenge">Movara Challenge</MenuItem>
                                  </Select>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box width={"363px"} mt={"40px"} mx={"20px"}>
                            <Grid container direction="column">
                              <Grid item>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                  <CalendarIcon />
                                  <Typography
                                    style={{
                                      marginLeft: "10px",
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontSize: "10px",
                                      fontWeight: 400,
                                      lineHeight: "120%",
                                      letterSpacing: "1.5px",
                                      color: "#414141",
                                    }}
                                  >
                                    BOOKING STATUS
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  mt={"10px"}
                                  mb={"40px"}
                                  width={"100%"}
                                  height={"40px"}
                                  borderRadius={"12px"}
                                  display="flex"
                                  alignItems={"center"}
                                  style={{
                                    backgroundColor: "#F2F2F2",
                                  }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={bookingStatus}
                                    // disabled={true}
                                    style={{
                                      width: "100%",
                                    }}
                                    onChange={(event: any) => {
                                      // if the user is on their last week - they cannot pause so prevent event and hide pause tooltip
                                      if (bookingStatus !== "Active" || (isLastWeek && event.target.value === "Pause")) {
                                        event.preventDefault();
                                        setDisplayPauseTooltip(false);
                                        return;
                                      }
                                      setBookingStatus(event.target.value);
                                    }}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {bookingData.statusBooking !== "Pause" && (
                                      <MenuItem value="Active" disabled={bookingStatus !== "Active"}>
                                        Active
                                      </MenuItem>
                                    )}
                                    <MenuItem value="Waiting" disabled={bookingStatus !== "Waiting"}>
                                      Waiting
                                    </MenuItem>
                                    <MenuItem
                                      value="Pause"
                                      ref={pauseMenuItem}
                                      disabled={bookingStatus !== "Active"}
                                      style={{
                                        opacity: isLastWeek || bookingStatus !== "Active" ? "0.5" : "1",
                                        backgroundColor: isLastWeek ? "rgba(0, 0, 0, 0.08)" : "none",
                                      }}
                                      onMouseEnter={() => {
                                        if (isLastWeek || bookingStatus !== "Active") {
                                          setDisplayPauseTooltip(true);
                                        }
                                      }}
                                      onMouseLeave={() => {
                                        if (isLastWeek || bookingStatus !== "Active") {
                                          setDisplayPauseTooltip(false);
                                        }
                                      }}
                                    >
                                      Pause
                                    </MenuItem>

                                    <MenuItem value="Inactive" disabled={bookingStatus !== "Inactive"}>
                                      Inactive
                                    </MenuItem>
                                    <MenuItem value="Indefinite" disabled={bookingStatus !== "Indefinite"}>
                                      Indefinite
                                    </MenuItem>
                                  </Select>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Menu
                            open={displayPauseTooltip}
                            anchorEl={pauseMenuItem.current}
                            style={{
                              transform: "translateY(50px)",
                              zIndex: "1400",
                            }}
                          >
                            <MenuItem disabled>{bookingStatus !== "Active" ? "Status must be Active in order to pause" : "Unable to pause - last week of booking"}</MenuItem>
                          </Menu>
                        </BookingController>
                      )}
                    </Box>
                  </Box>

                  <Box my={"30px"} display={"flex"} alignItems={"center"} justifyContent="center">
                    <Button
                      variant="outlined"
                      onClick={goBack}
                      className={classes.buttonCancel}
                      disabled={isSubmitting}
                      style={{
                        width: "104px",
                        height: "48px",
                        borderRadius: "12px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "10%",
                          color: "#000000",
                          letterSpacing: "0.25px",
                        }}
                      >
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        width: "104px",
                        height: "48px",
                        borderRadius: "12px",
                        marginLeft: "20px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "10%",
                          color: "#000000",
                          letterSpacing: "0.25px",
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};

export default BookingEdit;
