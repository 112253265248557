import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Hidden, Drawer, Grid, Typography, Link } from "@material-ui/core";
import { BigAvatar } from "components/layout/Avatar";
import Navigation from "./Navigation";
import { useStyles } from "./styles";
import movaraLogo from "../../../assets/images/movara-home-logo.png";
import { useProfile, startCaseToUpper } from "services/util";
import ImageModal from "components/modal/ImageModal";

/*
const dummyDrawerItems = (
    <List>
        {
            ['a', 'b', 'c'].map((text, index) => (
                <ListItem button key={index}>
                    <ListItemText primary={text} />
                </ListItem>
            ))
        }
    </List>
);
*/

const NavigationMenu = () => {
  const classes = useStyles();
  const profile = useProfile();
  const [show, setShow] = React.useState<boolean>(false);

  const handleClickImage = () => {
    if (profile?.avatar?.path) setShow(true);
  };
  return (
    <>
      <nav className={classes.root}>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant={"permanent"}
            open
          >
            <Grid container justify="center">
              <RouterLink to="/dashboard-user">
                <img src={movaraLogo} alt="movara home" className={classes.brand} />
              </RouterLink>
              <BigAvatar src={profile?.avatar?.path} onClick={handleClickImage} />
              <Typography variant="h4" className={classes.profileName}>
                {startCaseToUpper(`${profile?.name?.firstName ?? ""} ${profile?.name?.lastName ?? ""}`)}
              </Typography>
              <Typography className={classes.profileLink} variant="subtitle2" align="center">
                <Link component={RouterLink} to={`/profile`}>
                  View Profile
                </Link>
              </Typography>
            </Grid>
            <Navigation />
          </Drawer>
        </Hidden>
      </nav>
      <ImageModal show={show} image={profile?.avatar?.path} onClose={() => setShow(false)} />
    </>
  );
};

export default React.memo(NavigationMenu);
