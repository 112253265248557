import React, { FC, Suspense, useEffect } from "react";
import Statistic from "./components/statisticts";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import Loading from "../../components/progress/Loading";
import auth from "../../services/domain/auth/auth";
import { getUser, getProfileMetrics } from "services/domain/users";

const Chart = React.lazy(() => import("./components/chart"));
const Tasks = React.lazy(() => import("./components/tasks"));
const Appointment = React.lazy(() => import("./components/appointment"));
const Recents = React.lazy(() => import("./components/recents"));
const Posts = React.lazy(() => import("./components/posts"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridHeader: {
      marginBottom: theme.spacing(5),
    },
    grid: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const Dashboard: FC = () => {
  const today = moment().format("dddd, DD MMMM YYYY");
  const [profile, setProfile] = React.useState({
    displayName: "",
    about: {
      age: {
        age: 0,
        isAgeShow: false,
      },
      beenGuest: {
        isCheck: false,
        desc: "",
      },
      bio: "",
      isShowAbout: false,
      location: {
        location: "",
        isLocationShow: false,
      },
      occupation: {
        occupation: "",
        isOccupationShow: false,
      },
    },
    avatar: {
      path: "",
    },
    headerProfile: {
      path: "",
    },
    email: "",
    _id: "",
    totalPoints: 0,
  });
  // console.log("auth.userProperties", auth.userProperties);
  const classes = useStyles();
  const [fetchingUser, setFetchingUser] = React.useState(true);
  const displayName = auth.userProperties?.userData?.displayName;
  const userRole = auth?.userProperties?.userData?.role;
  const fetchData = async () => {
    try {
      const userId: string = auth.userProperties?.userData?._id;
      const asynUser = getUser({ user_id: userId });
      const resultUser = await asynUser;
      if ("data" in resultUser) {
        setProfile(resultUser.data);
      }
      setFetchingUser(false);
    } catch (e) {
      console.error("Profile not found", e);
      setTimeout(() => {
        // history.goBack()
      }, 2000);
      /**
       * TODO: handling error here
       */
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={8}>
        <Grid container className={classes.gridHeader}>
          <Grid item md={6}>
            <Typography variant="h5">Overview</Typography>
          </Grid>
          <Grid item md={6}>
            <Typography align="right">{today}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h4">Hi, {displayName}</Typography>
        </Grid>

        <Suspense fallback={<Loading />}>
          <Statistic />
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Chart />
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Tasks />
        </Suspense>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {userRole === "coach" ? !fetchingUser ? (
          <Suspense fallback={<Loading />}>
            <Appointment profile={profile} fetchProfile={fetchData} />
          </Suspense>
        ) : (
          <Loading></Loading>
        ) : <></>}
        <Suspense fallback={<Loading />}>
          <Recents />
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Posts />
        </Suspense>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
