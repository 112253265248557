/**
 * this component has 2 purpose: as image uploader and as image picker
 */
import React from 'react';
import {
    Modal,
    Grid,
    Typography,
    IconButton,
    Button,
    LinearProgress,
} from '@material-ui/core';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ImageUploading from 'react-images-uploading';
import MImageCropper from 'components/MImageCropper';
import clsx from 'clsx';

import { useStyles } from './styles';
import { CloseIcon } from 'components/icons';
import dropImagePlaceholder from 'assets/images/drop-image-placeholder.png';
import ButtonMui from 'components/_newDesign/button/Button'

type typeFile = {
    type: string;
    name: string;
};
interface MImageUploaderProps {
    title?: string;
    defaultImage?: string;
    onUpload?: (images: any, doneCallback: () => void) => void;
    onPick?: (imageFile: typeFile) => void;
}

const MImageUploader = React.forwardRef(
    ({ title, defaultImage, onUpload, onPick }: MImageUploaderProps, ref) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const [images, setImages] = React.useState(
            defaultImage ? [{ data_url: defaultImage }] : []
        );
        const [imageToCrop, setImageToCrop] = React.useState([]);
        const [progress, setProgress] = React.useState(0);
        const [isUploading, setIsUploading] = React.useState(false);
        const classes = useStyles();
        const cropperRef = React.useRef(null);

        React.useEffect(() => {
            if (defaultImage) {
                setImages([{ data_url: defaultImage }]);
            }
        }, [defaultImage]);

        React.useEffect(() => {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) return oldProgress;

                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            if (!isUploading) {
                clearInterval(timer);
            }

            return () => {
                if (timer) clearInterval(timer);
            };
        }, [isUploading]);

        React.useImperativeHandle(ref, () => ({
            open: handleOpen,
            close: handleClose,
        }));

        const handleOpen = () => {
            setIsOpen(true);
        };

        const handleClose = () => {
            setIsOpen(false);
            setProgress(0);
            if (defaultImage === undefined) {
                setImages([]);
            }
        };

        const handleChange = (imageList: any, addUpdateIndex: any) => {
            // setImages(imageList);
            setImageToCrop(imageList);
            (cropperRef as any)?.current.open();
        };

        const handleRemoveImage = () => {
            setImages([]);
            setProgress(0);
        };

        const handleUploadDone = () => {
            setProgress(100);
            setTimeout(() => {
                setIsUploading(false);
            }, 500);
        };

        const getImageFile = async () => {
            const imageObject = images[0] as {
                data_url: string;
                file: typeFile;
            };
            const base64Response = await fetch(imageObject.data_url);
            const arrBuffer = await base64Response.arrayBuffer();
            const imageFile = new File([arrBuffer], imageObject?.file?.name, {
                type: imageObject?.file?.type,
            });
            return imageFile;
        };

        const handleUpload = async () => {
            setProgress(0);
            setIsUploading(true);
            const imageFile = await getImageFile();
            onUpload!(imageFile, handleUploadDone);
        };

        const handleSave = async () => {
            const imageFile = await getImageFile();
            onPick!(imageFile);
            handleClose();
        };

        const handleProcess = () => {
            if (onPick && !onUpload) {
                handleSave();
            }

            if (onUpload && !onPick) {
                handleUpload();
            }
        };

        const handleOnCrop = (imageURL: any) => {
            const imageObject = imageToCrop[0] as {
                data_url: string;
                file: typeFile;
            };
            setImages([
                Object.assign({}, imageObject, {
                    data_url: imageURL,
                }),
            ]);
            setImageToCrop([]);
        };

        const imgSrc =
            images[0] && images[0].data_url
                ? images[0].data_url
                : dropImagePlaceholder;
        const isImageExist =
            images[0] && images[0].data_url && images[0].data_url.length > 0;
        // const isUpload = onUpload !== undefined;

        return (
            <>
                <Modal open={isOpen} className={classes.modal}>
                    <div className={classes.paper}>
                        <Grid container className={classes.header} style={{padding: '0px 20px'}}>
                            <Grid
                                item
                                xs={9}
                                className={classes['header-item']}
                            >
                                <Typography className={classes['upload-text']}>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                className={classes['header-item']}
                                style={{ justifyContent: 'flex-end' }}
                            >
                                {!isUploading && (
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        <hr style={{backgroundColor: '#E6E6E6', height: '1px', border: 'none'}}/>
                        <ImageUploading
                            value={images}
                            onChange={handleChange}
                            dataURLKey="data_url"
                            acceptType={['jpeg', 'jpg', 'png']}
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                                <div className={classes.body} style={{padding: '0px 20px', textAlign: 'left'}}>
                                    <div
                                        className={classes['image-holder']}
                                        style={
                                            isDragging
                                                ? {
                                                      backgroundColor:
                                                          '#F2F2FE',
                                                      boxShadow:
                                                          '5px 5px 10px rgba(0, 0, 0, 0.1)',
                                                      border: '1px solid #FFA21E',
                                                  }
                                                : undefined
                                        }
                                        {...dragProps}
                                    >
                                        {isImageExist && (
                                            <img alt='' src={imgSrc} className={clsx(classes['image-content'], imgSrc === dropImagePlaceholder && classes['image-placeholder'])} />
                                        )}

                                        {!isImageExist && (
                                            <div
                                                className={
                                                    classes['browse-container']
                                                }
                                            >
                                                <Typography>
                                                    Drop files here to upload…
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        margin: 'auto',
                                                        boxShadow: 'none',
                                                        border: '1px solid #E6E6E6',
                                                        borderRadius: '10px',
                                                        textTransform: 'none'
                                                    }}
                                                    onClick={onImageUpload}
                                                >
                                                    Browse files
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    {isImageExist && !isUploading && (
                                        <ButtonMui
                                            variant="outlined"
                                            color="default"
                                            onClick={handleRemoveImage}
                                            style={{marginTop: '10px'}}
                                        >
                                            Delete
                                        </ButtonMui>
                                    )}
                                    {!isImageExist && (
                                        <Typography
                                            align="center"
                                            className={classes['image-info']}
                                        >
                                            Recommended file dimension 900 x 300
                                            pixels Maximum size 1 MB <br />
                                            Format .jpeg, .png
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </ImageUploading>
                        <hr style={{backgroundColor: '#E6E6E6', height: '1px', border: 'none'}}/>
                        <div style={{padding: '20px 20px', textAlign: 'right'}}>
                            {isUploading && (
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    className={
                                        classes['upload-progress']
                                    }
                                />
                            )}

                            <ButtonMui
                                variant="outlined"
                                color="default"
                                onClick={handleClose}
                                style={{marginRight: '10px'}}
                            >
                                Cancel
                            </ButtonMui>
                            <ButtonMui
                                variant="contained"
                                color="primary"
                                className={classes['upload-button']}
                                onClick={handleProcess}
                                disabled={
                                    !isImageExist ||
                                    !('file' in images[0]) ||
                                    isUploading
                                }
                            >
                                Upload
                            </ButtonMui>
                        </div>
                    </div>
                </Modal>
                <MImageCropper
                    ref={cropperRef}
                    src={imageToCrop}
                    onCrop={handleOnCrop}
                />
            </>
        );
    }
);

MImageUploader.defaultProps = {
    title: 'Upload Image',
};

export default MImageUploader;
