import React, { FC, lazy, useEffect, useRef, useState } from 'react';
import MTable from 'components/_newDesign/table/Table';
import { Box, CircularProgress, Grid } from '@material-ui/core';
// import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';
import ModalConfrimation from 'components/_newDesign/alert/ModalConfirmation';
import { getZoom, postZoom } from 'services/domain/admin/users';
import { useSnackbar } from 'notistack';
import Typography from 'components/_newDesign/typography/Typography';
import Button from 'components/_newDesign/button/Button'
import useStyles from '../styles';

const ZoomNameCell = lazy(() => import('./tableCell/ZoomNameCell'))
const ZoomCallCell = lazy(() => import('./tableCell/ZoomCallCell'))
const ZoomNotesCell = lazy(() => import('./tableCell/ZoomNotesCell'))
interface Props {
    index: number,
    coachId: string
}

const TableZoom: FC<Props> = ({ index, coachId }) => {
    const classes = useStyles()
    const [data, setData] = useState<any>([]);
    const [origin, setOrigin] = useState<any>({
        val: '',
        index: '',
        type:''
    });
    const tableComp = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        if (tableComp && tableComp.current) tableComp.current.forceFetch();
    }, [index, coachId]);

    const fetchData = async () => {
        try {
            const params = {
                date: moment().add(index, 'weeks').endOf('weeks').format('YYYY-MM-DD'),
                coachId: coachId === 'All Coach' ? '' : coachId,
            };

            const res = await getZoom(params)
            const filtered = res.zoomAttendance.filter(item => item.guest !== undefined)
            setData(filtered);
            // setPageCount(res.zoomAttendance.length)
        } catch (error) {
            
        }
    }

    const valueChange = (val, row, index, type) => {
        setOrigin({
            val: val,
            index: index,
            type: type
        })
    }
    const columns = React.useMemo( () => [
        {
            Header: 'User Info',
            accessor: 'name',
            Cell: props => <ZoomNameCell value={props.value} email={props.row.original.email} avatar={props.row.original.avatar}/>
        },
        {
            Header: 'Sun Call',
            Cell: props => <ZoomCallCell index={props.row.index} row={props.row.original.bookingId} value={props.row.original.call1} onValueChange={(val, row, index) => valueChange(val, row, index, 'call1')}/>
        },
        {
            Header: 'Support Call 1',
            Cell: props => <ZoomCallCell index={props.row.index} row={props.row.original.bookingId} value={props.row.original.call2} onValueChange={(val, row, index) => valueChange(val, row, index, 'call2')}/>
        },
        {
            Header: 'Support Call 2',
            Cell: props => <ZoomCallCell index={props.row.index} row={props.row.original.bookingId} value={props.row.original.call3} onValueChange={(val, row, index) => valueChange(val, row, index, 'call3')}/>
        },
        {
            Header: 'Notes',
            Cell: props => <ZoomNotesCell index={props.row.index} row={props.row.original.bookingId} value={props.row.original.notes} onValueChange={(val, row, index) => valueChange(val, row, index, 'notes')}/>
        },
    ],[]);

    const onOk = async () => {
        setShowModal(false)
        setLoading(true)
        const newData = data.map(item => {
            let zoom = {
                _id: item._id,
                userId: item.guest,
                call1: item.call1,
                call2: item.call2,
                call3: item.call3,
                notes: item.notes,
            }
            return zoom
        })
        setTimeout( async() => {
            try {
                const payload = {
                    data: newData
                }
                await postZoom(payload)
                tableComp.current.forceFetch()
                enqueueSnackbar('Save changes success.', { variant: 'success' });
            } catch (error) {
                enqueueSnackbar('Save changes failed. Please try again.', { variant: 'error' });
            } finally {
                setLoading(false)
            }
        }, 1500);
    }

    const ModalContent = () => {
        return (
            <>
                <Typography variant="mov_subtitle1_bold">Are you Sure?</Typography>
                <br/>
                <br/>
                <Typography variant="mov_body1">
                 Are you sure to save this data?
                </Typography>
            </>
        )
    }

    React.useEffect(() => {
        fetchData()
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    React.useEffect(() => {
        if (origin.val !== '') {
            const currentData: any = data
            currentData[origin.index][origin.type] = origin.val
            setData(currentData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [origin])

    return (
        <>
            <ModalConfrimation show={showModal} onClose={() => setShowModal(false)} onOk={onOk} content={<ModalContent />}/>
            <Grid container spacing={3} direction="row" justify="flex-end">
                <Grid item md={12}>
                    <Box textAlign="right">
                        <Box component="div" display="inline" className={scrollPosition > 276 ? classes.buttonFixed : ''}>
                            <Button style={{border: '1px solid #E6E6E6'}} disabled={loading} variant="contained" onClick={onOk} endIcon={loading && <CircularProgress size={18} />}>
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <MTable
                columns={columns}
                data={data}
                onFetchData={fetchData}
                hidePagination={true}
                ref={tableComp}
                handleMessageEmpty="No guest data to show. There's currently 0 clients assigned under coach group within the selected duration."
            />
        </>
    );
};
export default TableZoom;
