import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ClockIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            fill="none"
            viewBox="0 0 24 24"
            {...props}
            style={{
                color: 'white',
                width: props.width || '24px',
                height: props.height || '24px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 13.07L16.49 16.36L17.51 14.64L13 11.93V6.42H11V13.07Z"
                fill="#515151"
            />
        </SvgIcon>
    );
}
