import React, { FC, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "pages/DashboardUser/components/main/daily/styles";
import InputNumber from "components/_newDesign/input/InputNumber";
import ButtonPopUp from "./ButtonPopUp";
import ErrorMessage from "components/_newDesign/alert/ErrorMessage";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview: boolean;
  isDone?: boolean;
  defaultValue?: number;
}
const OzWaterLabel: FC<Props> = (props) => {
  const isDone = props.isDone || false;
  const classes = useStyles();
  const [water, setWater] = React.useState(props.defaultValue);
  const onChange = (e: any) => {
    if (e.target.value > 500) {
      setWater(water);
    } else {
      setWater(e.target.value);
      props.onChange && props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setWater(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box display={"flex"} flexDirection="column">
      <Box display={"flex"}>
        <Box flexGrow={0}>
          <InputNumber value={water} name="ozWatter" onChange={onChange} disabled={isDone} />
        </Box>
        <Box flexGrow={1} display="flex" alignItems={"center"} marginLeft={1}>
          <Typography gutterBottom className={classes.nutritionGoalTypo}>
            <span style={{ color: "black" }}>oz of water</span>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography gutterBottom className={classes.nutritionGoalTypo}>
          (Maximum input 500)
        </Typography>
      </Box>
      {props.active && !water && <ErrorMessage message={"Amount is Required"} />}
      {props.active && (
        <Box marginTop={"10px"}>
          <ButtonPopUp title="Oz of water" type="oz_of_water" preview={props.preview} />
        </Box>
      )}
    </Box>
  );
};

export default OzWaterLabel;
