import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function AfternoonIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 25 25"
            {...props}
            style={{
                color: 'white',
                width: props.width || '25px',
                height: props.height || '25px',
            }}
        >
            <g clipPath="url(#clip0_1097_28308)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 8.31317C10.2909 8.31317 8.5 10.104 8.5 12.3132C8.5 14.5223 10.2909 16.3132 12.5 16.3132C14.7091 16.3132 16.5 14.5223 16.5 12.3132C16.5 10.104 14.7091 8.31317 12.5 8.31317ZM6.5 12.3132C6.5 8.99946 9.18629 6.31317 12.5 6.31317C15.8137 6.31317 18.5 8.99946 18.5 12.3132C18.5 15.6269 15.8137 18.3132 12.5 18.3132C9.18629 18.3132 6.5 15.6269 6.5 12.3132Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 0.313171C13.0523 0.313171 13.5 0.760887 13.5 1.31317V3.31317C13.5 3.86546 13.0523 4.31317 12.5 4.31317C11.9477 4.31317 11.5 3.86546 11.5 3.31317V1.31317C11.5 0.760887 11.9477 0.313171 12.5 0.313171Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 20.3132C13.0523 20.3132 13.5 20.7609 13.5 21.3132V23.3132C13.5 23.8655 13.0523 24.3132 12.5 24.3132C11.9477 24.3132 11.5 23.8655 11.5 23.3132V21.3132C11.5 20.7609 11.9477 20.3132 12.5 20.3132Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.01262 3.82604C4.40314 3.43551 5.03631 3.43551 5.42683 3.82604L6.84683 5.24604C7.23736 5.63656 7.23736 6.26972 6.84683 6.66025C6.45631 7.05077 5.82314 7.05077 5.43262 6.66025L4.01262 5.24025C3.6221 4.84972 3.6221 4.21656 4.01262 3.82604Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.1532 17.966C18.5438 17.5755 19.1769 17.5755 19.5675 17.966L20.9875 19.3861C21.378 19.7766 21.378 20.4097 20.9875 20.8003C20.5969 21.1908 19.9638 21.1908 19.5732 20.8003L18.1532 19.3803C17.7627 18.9897 17.7627 18.3566 18.1532 17.966Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 12.3132C0.5 11.7609 0.947715 11.3132 1.5 11.3132H3.5C4.05228 11.3132 4.5 11.7609 4.5 12.3132C4.5 12.8655 4.05228 13.3132 3.5 13.3132H1.5C0.947715 13.3132 0.5 12.8655 0.5 12.3132Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5 12.3132C20.5 11.7609 20.9477 11.3132 21.5 11.3132H23.5C24.0523 11.3132 24.5 11.7609 24.5 12.3132C24.5 12.8655 24.0523 13.3132 23.5 13.3132H21.5C20.9477 13.3132 20.5 12.8655 20.5 12.3132Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.84683 17.966C7.23736 18.3566 7.23736 18.9897 6.84683 19.3803L5.42683 20.8003C5.03631 21.1908 4.40314 21.1908 4.01262 20.8003C3.6221 20.4097 3.6221 19.7766 4.01262 19.3861L5.43262 17.966C5.82314 17.5755 6.45631 17.5755 6.84683 17.966Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.9875 3.82604C21.378 4.21656 21.378 4.84972 20.9875 5.24025L19.5675 6.66025C19.1769 7.05077 18.5438 7.05077 18.1532 6.66025C17.7627 6.26972 17.7627 5.63656 18.1532 5.24604L19.5732 3.82604C19.9638 3.43551 20.5969 3.43551 20.9875 3.82604Z"
                    fill="#6D6D6D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1097_28308">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5 0.313171)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
