import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

interface Props extends SvgIconProps {
    
}

const BagIcon: React.FC<Props> = ({ className, ...props }: SvgIconProps) => {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 20" className={clsx(classes.root, className ?? className)}>
            <g clipPath="url(#clip0)">
                <path d="M1.20146 18.9045C7.04873 19.7231 12.9901 19.7231 18.8374 18.9045C19.7929 12.8936 19.7929 7.18639 18.8374 1.17509C16.937 0.90947 15.0269 0.729674 13.1128 0.636864C10.6031 0.452661 7.9426 0.560113 6.92607 0.636864C5.01201 0.729674 3.10186 0.90947 1.20146 1.17509C0.245996 7.18677 0.245996 12.894 1.20146 18.9045Z" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round" />
                <path d="M0.625 5.9375H19.375" stroke="#FFA21E" />
                <line x1="3.25" y1="2.5" x2="3.25" stroke="#FFA21E" />
                <line x1="15.75" y1="2.5" x2="15.75" stroke="#FFA21E" />
                <path d="M5.625 11.7939L8.68228 15L14.375 8.75" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}

export default BagIcon;
