import { http } from 'services/data';
import { 
    VideoCookingCatResponse,
    VideoCookingResponse,
    RateVideoCookingForm,
    RateVideoCookingResponse,
    AssesmentFitnessPayload,
    AssesmentPersonalDevPayload,
    AssesmentStretchPayload
} from 'interfaces/videoCooking';

const endPoints = {
    videoCooking: 'admin/cooking/video/byid',
    videoCourse: 'admin/course/video/byid',
    videoCookingCat: 'admin/cooking/video/bycategories/list',
    markVideoCooking: 'admin/cooking/video/completed',
    rateVideoCooking: 'admin/cooking/video/rate',
    markVideoCourse: 'admin/course/video/completed',
    rateVideoCourse: 'admin/course/video/rate',
    assesmentStretch: 'admin/assessments/stretch',
    assesmentFitness: 'admin/assessments/fitness',
    assesmentPersonalDev: 'admin/assessments/personalDev',
};

export const getVideoCooking = async (id: string): Promise<VideoCookingResponse> => await http.get(endPoints.videoCooking, {params: {idVideo: id}})
export const getVideoCourse = async (id: string): Promise<VideoCookingResponse> => await http.get(endPoints.videoCourse, {params: {idVideo: id}})
export const getVideoCookingCat = async (cat: string): Promise<VideoCookingCatResponse> => await http.get(endPoints.videoCookingCat, {params: {categories: cat}})
export const markVideoCooking = async (id: string): Promise<RateVideoCookingResponse> => await http.post(endPoints.markVideoCooking, {idVideo: id})
export const rateVideoCooking = async (payload: RateVideoCookingForm): Promise<RateVideoCookingResponse> => await http.post(endPoints.rateVideoCooking, payload)
export const markVideoCourse = async (id: string): Promise<RateVideoCookingResponse> => await http.post(endPoints.markVideoCourse, {idVideo: id})
export const rateVideoCourse = async (payload: RateVideoCookingForm): Promise<RateVideoCookingResponse> => await http.post(endPoints.rateVideoCourse, payload)
export const assesmentStretch = async (payload: AssesmentStretchPayload): Promise<any> => await http.post(endPoints.assesmentStretch, payload)
export const assesmentFitness = async (payload: AssesmentFitnessPayload): Promise<any> => await http.post(endPoints.assesmentFitness, payload)
export const assesmentPersonalDev = async (payload: AssesmentPersonalDevPayload): Promise<any> => await http.post(endPoints.assesmentPersonalDev, payload)