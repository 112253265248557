import React, { FC, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, Modal, Box, Grid, IconButton, Typography, Checkbox, FormControlLabel } from "@material-ui/core";
import { CloseIcon } from "components/_newIcon";
import ButtonMui from "components/_newDesign/button/Button";
import moment from "moment";
import ModalConfirmation from "components/_newDesign/alert/ModalConfirmation";

interface BookingDetails {
  id?: string;
  email?: string;
  startDate: Date;
  endDate: Date;
  originalStartDate?: Date;
  originalEndDate?: Date;
  pauseStartDate?: Date;
  pauseEndDate?: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
  createdAt?: Date;
}

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleSubmit: (data: { bookingId: string; bookingEmail: string; cancelBooking: boolean; originalBookingLength: number; createdAt?: Date }) => void;
  booking: BookingDetails;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      boxShadow: "0px 15px 60px rgba(0, 0, 0, 0.15)",
      padding: theme.spacing(2, 0, 2),
      width: "623px",
      maxWidth: "623px",
      maxHeight: "95%",
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
      borderRadius: "20px",
    },
    header: {
      // width: '805px',
      // maxWidth: '805px',
    },
    "header-item": {
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "100%",
      color: "#000000",
    },
    content: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "150%",
      color: "#000000",
    },
    "upload-text": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "150%",
      /* identical to box height, or 36px */

      letterSpacing: " 0.15px",

      color: "#000000",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "20px",
    },
    modalContent: {
      padding: "0px 24px 16px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 0px 16px 0px",
      },
    },
  }),
);

const RefundFormModal: FC<Props> = ({ visible, handleClose, handleSubmit, booking }) => {
  const classes = useStyles();

  const [originalBookingLength, setOriginalBookingLength] = useState(0);
  const [refundWeekOptions, setRefundWeekOptions] = useState<number[]>([]);
  const [cancelBookingChecked, setCancelBookingChecked] = useState(false);

  const [refundConfirmModalOpen, setRefundConfirmModalOpen] = React.useState<boolean>(false);

  const handleCancelBookingChange = (event) => {
    setCancelBookingChecked(event.target.checked);
  };

  const handleSaveClick = () => {
    setRefundConfirmModalOpen(true);
  };

  const handleConfirmOkay = () => {
    handleSubmit({
      bookingId: booking.id || "",
      bookingEmail: booking.email || "",
      cancelBooking: cancelBookingChecked,
      originalBookingLength,
      createdAt: booking.createdAt,
    });

    setRefundConfirmModalOpen(false);
  };

  useEffect(() => {
    if (booking.originalStartDate && booking.originalEndDate) {
      // how many weeks between the original start and end date of the booking
      const diffInWeeks = moment(booking.originalEndDate).diff(moment(booking.originalStartDate), "weeks");

      setOriginalBookingLength(diffInWeeks);

      // create an array of options for the user to select from
      const options = Array.from({ length: diffInWeeks }, (_, i) => i + 1);

      setRefundWeekOptions(options);
    }
  }, [booking]);

  return (
    <>
      <Modal
        open={visible}
        onClose={handleClose}
        style={{ maxHeight: "100vh" }}
        className={classes.modal}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0.7)",
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container className={classes.header} style={{ padding: "0px 20px" }}>
            <Grid item xs={6} className={classes["header-item"]} style={{ justifyContent: "flex-start" }}>
              <Typography className={classes.title}>Refund Booking</Typography>
            </Grid>
            <Grid item xs={6} className={classes["header-item"]} style={{ justifyContent: "flex-end" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <hr
            style={{
              backgroundColor: "#E6E6E6",
              height: "1px",
              border: "none",
            }}
          />

          <Box width={"100%"} className={classes.modalContent} mt="32px">
            <Box mt={2} mb={2}>
              <Typography className={classes.content}>
                This booking will be marked as fully refunded. You should make sure you've fully refunded the user's payment in Stripe before continuing.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Typography className={classes.content}>Cancel this booking right now?</Typography>

              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                checked={cancelBookingChecked}
                onChange={handleCancelBookingChange}
                control={<Checkbox name="cancelBooking" color="primary" />}
                label={<Typography className={classes.content}>Cancel booking</Typography>}
              />
            </Box>
          </Box>
          <hr
            style={{
              backgroundColor: "#E6E6E6",
              height: "1px",
              border: "none",
            }}
          />
          <div style={{ padding: "20px 20px", textAlign: "center" }}>
            <ButtonMui variant="outlined" color="default" onClick={handleClose} style={{ marginRight: "10px" }}>
              Cancel
            </ButtonMui>
            <ButtonMui variant="contained" color="primary" className={classes["upload-button"]} onClick={handleSaveClick}>
              SAVE
            </ButtonMui>
          </div>
        </div>
      </Modal>
      <ModalConfirmation
        show={refundConfirmModalOpen}
        onClose={() => setRefundConfirmModalOpen(false)}
        onOk={() => handleConfirmOkay()}
        title="Refund Booking"
        content="Are you sure you want to refund this booking? Please ensure you've completed the Stripe refund process before proceeding."
        showImage={false}
      />
    </>
  );
};

export default RefundFormModal;
