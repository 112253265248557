import React, { FC, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useLocation, useParams, useHistory } from "react-router-dom";
import { Paper, Box, Typography, Button, TextField, withStyles, Popper } from "@material-ui/core";
import AddIcon from "components/icons/AddIcon";
import { Column } from "react-table";

import MTable from "components/_newDesign/table";
import BookingAddNew from "./BookingAddNew";
import BookingEdit from "./BookingEdit";
import { ActionCell, DurationCell, GuestProfile, BookingId } from "./components/cells";
import { useStyles } from "./styles";
import { getBookingAll, getBookingByEmail, getBookingPeriod } from "services/domain/admin/bookings";
import { startCaseToUpper } from "services/util/common";
import { Creators } from "services/domain/admin/reducer";
import { Autocomplete } from "@material-ui/lab";
import { SearchIcon } from "components/icons";
import { getUserByFirstNameBooking } from "services/domain/users/users";
import DateRangePickerInput from "components/controls/DateRangePicker";
import { DateRange } from "materialui-daterange-picker";
import { getThisWeekRange } from "helper/Date";
import moment from "moment";
import TypographyMui from "components/_newDesign/typography/Typography";
// import BookingPause from 'pages/Bookings/BookingPause';
import BookingReschedule from "pages/Bookings/BookingReschedule";
import auth from "services/domain/auth";

type Booking = {
  _id: string;
  email: string;
  createdAt: string;
  startDate: string;
  paymentStatus: string;
};

const PopperMy = (props: any) => {
  const classes = useStyles();
  return <Popper {...props} className={classes.popperStyle} placement="bottom-start" />;
};

const CssTextField = withStyles({
  root: {
    background: "#F8F8F8",
    boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)",
    borderRadius: "7px",
    paddingRight: "20px",
    "&:focus": {
      backgroundColor: "white",
      boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
})(TextField);

const FocusStyle = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 6.25px 20px rgba(0, 0, 0, 0.125)",
};

const Bookings: FC<{}> = () => {
  const [bookings, setBookings] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const classes = useStyles();
  const location = useLocation();
  const { action } = useParams<{ action: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [pageIndexMem, setPageIndexMem] = useState<any>(0);
  const [pageSizeMem, setPageSizeMem] = useState<any>(0);
  const [sortByMem, setSortByMem] = useState<any>("asc");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>(getThisWeekRange());
  const [loading, setLoading] = useState<boolean>(false);
  const tableComp = React.useRef<any>(null);

  const fetchBookings = useCallback(
    async ({ pageIndex, pageSize, sortBy, startDate, endDate }: { pageIndex: any; pageSize: any; sortBy: any; startDate: any; endDate: any }) => {
      try {
        setLoading(true);
        let result;
        const coach_id = auth?.userProperties?.userData?.role === "coach" ? auth?.userProperties?.userData?._id : undefined;
        if (startDate !== undefined && startDate !== undefined) {
          result = await getBookingPeriod({
            existData: pageIndex * pageSize,
            limitData: pageSize,
            startDate: startDate,
            endDate: endDate,
            coachId: coach_id,
          });
        } else {
          result = await getBookingAll({
            existData: pageIndex * pageSize,
            limitData: pageSize,
            coachId: coach_id,
          });
        }
        result.data.map((elm) => {
          if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
          if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
          return elm;
        });
        setBookings(result.data);
        setPageCount(result.meta.pagination.totalPage);
        setPageIndexMem(pageIndex);
        setPageSizeMem(pageSize);
        setSortByMem(sortBy);
      } catch (e) {
        console.error(e);
        /**
         * TODO: error handle here
         */
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const fetchBookingsEmail = async ({ pageIndex, pageSize, sortBy, email }: { pageIndex: any; pageSize: any; sortBy: any; email: any }) => {
    try {
      const result = await getBookingByEmail({
        existData: pageIndex * pageSize,
        limitData: pageSize,
        email: searchValue?.email ? searchValue?.email : email,
      });
      result.data.map((elm) => {
        if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
        if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
        return elm;
      });
      setBookings(result.data);
      setPageCount(result.meta.pagination.totalPage);
      setRefresh(false);
    } catch (e) {
      /**
       * TODO: error handle here
       */
    } finally {
      /**
       * TODO: final action here
       */
    }
  };

  const columns: Column<Booking>[] = useMemo(
    () => [
      {
        Header: "Booking Id",
        Cell: BookingId,
      },
      {
        Header: "Guest Name",
        Cell: GuestProfile,
      },
      {
        Header: "Book Date",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{moment.utc(props.row.original.createdAt).format("MMM/DD/YYYY")}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Duration",
        Cell: DurationCell,
      },
      {
        Header: "Payment Status",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{startCaseToUpper(props.row.original.paymentStatus)}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Action",
        Cell: (props: any) => <ActionCell actionEdit={handleEditAction} /* actionPause={handlePauseAction} */ actionReschedule={handleRescheduleAction} {...props} />,
      },
      // eslint-disable-next-line
    ],
    [],
  );

  const handleEditAction = useCallback((rowData) => {
    dispatch(Creators.updateFormState(rowData.original));
    history.push(`${location.pathname}/edit/${rowData.original._id}`);
    // eslint-disable-next-line
  }, []);
  // ! pausing is now done on the edit page
  // const handlePauseAction = useCallback((rowData) => {
  //     dispatch(Creators.updateFormState(rowData.original));
  //     history.push(`${location.pathname}/pause/${rowData.original._id}`);
  //     // eslint-disable-next-line
  // }, [])
  const handleRescheduleAction = useCallback((rowData) => {
    dispatch(Creators.updateFormState(rowData.original));
    history.push(`${location.pathname}/reschedule/${rowData.original._id}`);
    // eslint-disable-next-line
  }, []);

  if (action === "add") {
    return <BookingAddNew />;
  }

  if (action === "edit") {
    return <BookingEdit />;
  }

  // if (action === 'pause') {
  //     return (
  //         <BookingPause />
  //     );
  // }

  if (action === "reschedule") {
    return <BookingReschedule />;
  }

  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const payload = {
        firstName: value,
      };
      const rspn = await getUserByFirstNameBooking(payload);
      setOptions(rspn.data);
    } catch (error) {
    } finally {
      setLoadingSearch(false);
    }
  };

  const onChangeValue = (event: any, value: any) => {
    setSearchValue(value);
    if (value !== null) {
      setRefresh(true);
      fetchBookingsEmail({
        pageIndex: 0,
        pageSize: 10,
        sortBy: sortByMem,
        email: value?.email,
      });
    } else {
      if (tableComp && tableComp.current) tableComp.current.forceFetch();
    }
  };

  const onChangeDateFilter = (startDate: string, endDate: string) => {
    fetchBookings({
      pageIndex: pageIndexMem,
      pageSize: pageSizeMem,
      sortBy: sortByMem,
      startDate: startDate,
      endDate: endDate,
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Bookings</Typography>
        {auth?.userProperties?.userData?.role === "admin" && (
          <Button color="primary" component={RouterLink} to={`${location.pathname}/add`}>
            ADD NEW BOOKING
            <AddIcon className={classes.addIcon} />
          </Button>
        )}
      </Box>

      <Box mt={3}>
        <Paper>
          <Box display="flex" alignItems="center" p={3}>
            <Box mr={2} alignItems="center">
              <Button
                onClick={() => {
                  setDateRangeFilter(getThisWeekRange);
                  onChangeDateFilter(moment(getThisWeekRange().startDate).format("YYYY-MM-DD"), moment(getThisWeekRange().endDate).format("YYYY-MM-DD"));
                }}
                variant="contained"
                color="primary"
              >
                BY THIS WEEK
              </Button>
            </Box>
            <Box boxShadow={2} px={2} py={"2px"} display="flex" alignItems="center" width="400px">
              <Box mr={3} width="150px">
                <Typography variant={"button"}>BY DURATION</Typography>
              </Box>
              <Box width="100%">
                <DateRangePickerInput
                  onChange={(date) => {
                    onChangeDateFilter(moment(date.startDate).format("YYYY-MM-DD"), moment(date.endDate).format("YYYY-MM-DD"));
                    setDateRangeFilter(date);
                  }}
                  value={dateRangeFilter}
                  closeOnSelection={true}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Box mt={3} display="flex" flexDirection="row-reverse">
        <Autocomplete
          value={searchValue}
          size="small"
          freeSolo
          id="autocomplete-search"
          style={{ width: 300 }}
          // open={true}
          onOpen={() => {
            inputChange(null, null);
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={onChangeValue}
          onInputChange={inputChange}
          // getOptionSelected={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          options={options}
          loading={loadingSearch}
          PopperComponent={PopperMy}
          renderInput={(params) => (
            <CssTextField
              placeholder="Search for ‘something’"
              // size="small"
              {...params}
              variant="outlined"
              style={open ? FocusStyle : {}}
              // InputProps={{ disableUnderline: true }}
              // InputProps={{
              //     ...params.InputProps,
              //     endAdornment: (
              //     <React.Fragment>
              //         <SearchIcon style={{marginRight: '-28px'}}/>
              //     </React.Fragment>
              //     ),
              // }}
            />
          )}
        />
        <SearchIcon
          style={{
            position: "absolute",
            marginTop: "9px",
            right: "48px",
          }}
        />
      </Box>

      <Box mt={3}>
        <Paper>
          <MTable
            columns={columns}
            data={bookings}
            onFetchData={searchValue ? fetchBookingsEmail : fetchBookings}
            pageCount={pageCount}
            refreshPage={refresh}
            loading={loading}
            ref={tableComp}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Bookings;
