import React, { useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import Typography from "components/_newDesign/typography/Typography";
import { CoachProfil } from "interfaces/user";
import SelectCoach from "pages/BookingsGuest/SelectCoach";
import { ClockIcon } from "components/_newIcon";
import TotalWeekIndicator from "components/_modules/booking/TotalWeekIndicator";
import moment from "moment";
import Swal from "sweetalert2";
import CoachCalendarDateRange from "../CoachCalendar/Calendar";
import "react-datepicker/dist/react-datepicker.css";
import RefundFormModal from "../BookingRefund/RefundFormModal";
import bookingValueMap from "../BookingRefund/bookingValueMap";
import { postBookingRefund } from "services/domain/admin/bookings";
import { Toast } from "services/util/toast";
import { formatCentsToDollars } from "services/util";
import history from "services/data/history";
import bookingValueMapOld from "../BookingRefund/bookingValueMapOld";

interface Props {
  booking: BookingDetails;
  onBookingInfoChange: (BookingDetails) => void;
  mode: string;
  pause?: boolean;
  children?: JSX.Element | JSX.Element[];
  bookingStatus?: string;
  paymentStatus?: string;
}

interface BookingDetails {
  id?: string;
  email?: string;
  startDate: Date;
  endDate: Date;
  originalStartDate?: Date;
  originalEndDate?: Date;
  pauseStartDate?: Date;
  pauseEndDate?: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
  refunds?: { date: Date; amount: number }[];
  createdAt?: Date;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface coachMinimal {
  _id: string;
  coachName: string;
}

const l_startDate = moment().add(7 - moment().isoWeekday(), "days");
const l_endDate = moment(l_startDate).add(1, "week");

const initialState: BookingDetails = {
  startDate: l_startDate.toDate(),
  endDate: l_endDate.toDate(),
  coach_id: "",
  coach_name: "",
  totalWeeks: 1,
};

const BookingController: React.FC<Props> = ({ booking, onBookingInfoChange, mode, bookingStatus, pause, children, paymentStatus }) => {
  const [coachInfo, setCoachInfo] = React.useState<CoachProfil>();
  const [isFirst, setIsFirst] = React.useState<boolean>(true);

  const [_pauseDateRange, setPauseDateRange] = React.useState<DateRange>({
    startDate: booking.pauseStartDate ?? new Date(),
    endDate: booking.pauseEndDate ?? new Date(new Date().getDate() + 7),
  });

  const [bookingStartDate, setBookingStartDate] = React.useState<Date>(booking.startDate ?? initialState.startDate);

  const [bookingEndDate, setBookingEndDate] = React.useState<Date>(booking.endDate ?? initialState.endDate);

  const [totalWeek, setTotalWeek] = React.useState<number>(1);
  const [coachSummary, setCoachSummary] = React.useState<coachMinimal>({ _id: "", coachName: "" });
  const [datesDifferentFromOriginal, setDatesDifferentFromOriginal] = React.useState<boolean>(false);

  const [refundModalOpen, setRefundModalOpen] = React.useState<boolean>(false);

  // can only refund a booking if in edit mode, booking has original start/end dates, normal start/end dates weren't changed, and payment status is paid
  const showRefundSection = mode === "edit" && booking.originalStartDate && booking.originalEndDate && !datesDifferentFromOriginal && paymentStatus?.toLowerCase() === "paid";

  React.useEffect(() => {
    if (isFirst) {
      // * WORKS
      const obj = booking ?? initialState;

      setPauseDateRange({
        startDate: obj.pauseStartDate ?? initialState.startDate,
        endDate: obj.pauseEndDate ?? initialState.endDate,
      });

      setIsFirst(false);
      setCoachSummary({
        _id: obj.coach_id,
        coachName: obj.coach_name,
      });
      setTotalWeek(obj.totalWeeks ?? 1);
    } else {
      setCoachSummary({
        _id: coachInfo?._id ?? coachSummary?._id ?? "",
        coachName: coachInfo?.name ?? coachSummary?.coachName ?? "",
      });

      onBookingInfoChange({
        id: booking?.id,
        email: booking?.email,
        startDate: bookingStartDate,
        endDate: bookingEndDate,
        pauseStartDate: pause ? _pauseDateRange.startDate : null,
        pauseEndDate: pause ? _pauseDateRange.endDate : null,
        originalStartDate: booking?.originalStartDate,
        originalEndDate: booking?.originalEndDate,
        coach_id: coachInfo?._id ?? coachSummary?._id ?? "",
        coach_name: coachInfo?.name ?? coachSummary?.coachName ?? "",
        totalWeeks: totalWeek,
        refunds: booking?.refunds ?? [],
        createdAt: booking?.createdAt,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_pauseDateRange, coachInfo, totalWeek, bookingStartDate, bookingEndDate]);

  const onWeekItemClick = (weekNumber: number) => {
    const nextMondayDayCount = 7 - moment().isoWeekday();
    const startDate = moment().add(nextMondayDayCount, "days");
    const endDate = moment(startDate).add(weekNumber, "weeks");
    const metric = weekNumber === 1 ? "week" : "weeks";

    Swal.fire({
      confirmButtonText: "Yes",
      showCancelButton: true,
      html: `<div style="font-family: Montserrat; font-size: 16px;">This action will update booking's date range to <br/><b>${
        startDate.format("dddd, DD-MMM-YYYY") + "</b> until <b>" + endDate.format("dddd, DD-MMM-YYYY")
      }</b>,<br/>with total <b>${weekNumber}</b> ${metric},<br/>are you sure ?</div>`,
      confirmButtonColor: "#FFA21E",
    }).then((result) => {
      if (result.isConfirmed) {
        setTotalWeek(weekNumber);

        setBookingStartDate(startDate.toDate());
        setBookingEndDate(endDate.toDate());
      }
    });
  };

  const calendarRangeHandler = (dateRange) => {
    setPauseDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  };

  // submit the booking refund to the API
  const handleRefundSubmit = (data: { bookingId: string; bookingEmail: string; cancelBooking: boolean; originalBookingLength: number; createdAt?: Date }) => {
    try {
      const { bookingId, bookingEmail, cancelBooking, originalBookingLength, createdAt } = data;

      const useOldPricing = moment(createdAt).isBefore(moment("2023-06-01"));

      // value of the booking (will use old price table if purchased before 1st June 2023)
      const refundAmount = useOldPricing ? bookingValueMapOld[originalBookingLength].bookingValue : bookingValueMap[originalBookingLength].bookingValue;

      const toast = Toast({ onClose: () => history.goBack() });

      postBookingRefund({
        bookingId,
        email: bookingEmail,
        cancelBooking,
        refundAmount,
      });

      setRefundModalOpen(false);

      toast.fire({
        icon: "success",
        title: "Booking refund has been saved",
      });
    } catch (e) {
      console.error(e);

      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Booking refund failed",
      });
    } finally {
      setRefundModalOpen(false);
    }
  };

  useEffect(() => {
    // original start and end dates don't change, so if the start/end dates differ from the original ones, we know they were changed and to display the originals
    const hasOriginalStartDate = booking?.originalStartDate;
    const hasOriginalEndDate = booking?.originalEndDate;

    const startDateWasChanged = hasOriginalStartDate && booking.startDate !== booking.originalStartDate;
    const endDateWasChanged = hasOriginalEndDate && booking.endDate !== booking.originalEndDate;

    if (startDateWasChanged || endDateWasChanged) {
      setDatesDifferentFromOriginal(true);
    }

    if (mode === "edit" && booking.startDate > _pauseDateRange.startDate) {
      setPauseDateRange({
        ..._pauseDateRange,
        startDate: booking.startDate,
      });
    }

    if (mode === "edit" && booking.endDate < _pauseDateRange.endDate) {
      setPauseDateRange({
        ..._pauseDateRange,
        endDate: booking.endDate,
      });
    }
  }, [booking]);

  return (
    <Box my={3} width="100%" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px">
      <Box marginX={"20px"}>
        <Box paddingTop={"20px"}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "150%",
            }}
          >
            Booking
          </Typography>
        </Box>
        {datesDifferentFromOriginal && (
          <Box>
            <Typography
              variant="mov_overline2"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                fontWeight: "700",
              }}
            >
              BOOKING DETAILS
            </Typography>
            <Box>
              <Typography
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                Original Start Date
              </Typography>
              <Typography>{booking?.originalStartDate ? moment(booking?.originalStartDate, "YYYY-MM-DD").format("MMM/DD/YYYY") : ""}</Typography>
            </Box>

            <Box>
              <Typography
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                Original End Date
              </Typography>
              <Typography>{booking?.originalEndDate ? moment(booking?.originalEndDate, "YYYY-MM-DD").format("MMM/DD/YYYY") : ""}</Typography>
            </Box>
          </Box>
        )}

        {mode === "edit" && _pauseDateRange.startDate && _pauseDateRange.endDate && (
          <Box>
            <Typography
              variant="mov_overline2"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                fontWeight: "700",
              }}
            >
              BOOKING PAUSE DATA
            </Typography>
            <Box>
              <Typography
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                Pause Start Date
              </Typography>
              <Typography>{moment(_pauseDateRange.startDate, "YYYY-MM-DD").format("MMM/DD/YYYY")}</Typography>
            </Box>
            <Box>
              <Typography
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                Pause End Date
              </Typography>
              <Typography>{moment(_pauseDateRange.endDate, "YYYY-MM-DD").format("MMM/DD/YYYY")}</Typography>
            </Box>
          </Box>
        )}

        <Box>
          <>
            {mode === "edit" ? (
              <SelectCoach
                edit={true}
                defaultValue={coachSummary?._id}
                selectCoach={(coachData) => {
                  setCoachInfo(coachData);
                }}
              />
            ) : (
              <SelectCoach
                selectCoach={(coachData) => {
                  setCoachInfo(coachData);
                }}
              />
            )}
            {children}
          </>
        </Box>
        <Box mt={5} sx={{ display: "block" }}>
          {mode !== "edit" && (
            <>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <ClockIcon /> SELECT DURATION
              </Typography>
              <Grid container style={{ justifyContent: "center" }}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                  <Grid container style={{ justifyContent: "center" }}>
                    <Grid item md={12} xs={12} sm={12} xl={12}>
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                          <TotalWeekIndicator
                            startDate={bookingStartDate ?? null}
                            endDate={bookingEndDate === bookingStartDate ? moment(bookingStartDate).add(totalWeek, "weeks").toDate() : bookingEndDate}
                            showRecurringOption={false}
                            recurring={false}
                            onItemClick={(weekNumber) => onWeekItemClick(weekNumber)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {showRefundSection && (
            <Box mt={8}>
              <Typography
                variant="mov_overline2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  fontWeight: "700",
                }}
              >
                BOOKING REFUND INFORMATION
              </Typography>

              <Box mt={2}>
                {booking?.refunds && booking.refunds?.length > 0 ? (
                  <>
                    <Typography variant="mov_overline2">Past Refunds</Typography>
                    <Box mt={1} mb={2}>
                      {booking.refunds?.map((refund) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>
                              Booking was refunded for {formatCentsToDollars(refund.amount)} on {moment(refund.date).format("MM/DD/YYYY")}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  <Box mt={1} mb={2}>
                    <Typography variant="mov_overline2">No past refunds</Typography>
                  </Box>
                )}
              </Box>
              {booking?.refunds && booking?.refunds?.length === 0 && (
                <Box mt={1} mb={2}>
                  <Button
                    onClick={() => {
                      setRefundModalOpen(true);
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      color: "black",
                    }}
                  >
                    Refund Booking
                  </Button>
                </Box>
              )}

              <RefundFormModal
                visible={refundModalOpen}
                handleClose={() => {
                  setRefundModalOpen(false);
                }}
                handleSubmit={(data) => {
                  handleRefundSubmit(data);
                }}
                booking={booking}
              />
            </Box>
          )}

          {pause && (
            <CoachCalendarDateRange
              onChange={(newDateRange) => calendarRangeHandler(newDateRange)}
              dateRange={{
                startDate: _pauseDateRange.startDate,
                endDate: _pauseDateRange.endDate,
              }}
              coachId={coachInfo?._id || ""}
              coachName={coachInfo?.name || ""}
              totalWeek={moment(_pauseDateRange.endDate).diff(moment(_pauseDateRange.startDate), "weeks")}
              pause={pause}
              mode={mode}
              booking={booking}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingController;
