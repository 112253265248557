import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
        },
        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            height: 80,
        },
        bookingTable: {
            maxHeight: 150,
        },
    }),
);

export default useStyles;
