export default (weeks) => {
  let data = {
    index: 0,
    amount: 0,
    perweek: 0,
  };
  switch (true) {
    case weeks <= 3:
      data = {
        index: weeks,
        perweek: 150,
        amount: 150 * weeks,
      };
      break;
    case weeks > 3 && weeks <= 7:
      data = {
        index: weeks,
        perweek: 142.5,
        amount: 142.5 * weeks,
      };
      break;
    case weeks > 7 && weeks <= 11:
      data = {
        index: weeks,
        perweek: 142.5,
        amount: 142.5 * weeks,
      };
      break;
    case weeks > 11 && weeks <= 23:
      data = {
        index: weeks,
        perweek: 135,
        amount: 135 * weeks,
      };
      break;
    case weeks > 23 && weeks <= 47:
      data = {
        index: weeks,
        perweek: 124.5,
        amount: 124.5 * weeks,
      };
      break;
    case weeks > 47:
      data = {
        index: weeks,
        perweek: 90,
        amount: 90 * weeks,
      };
      break;
    default:
      break;
  }

  return data;
};
