import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function ThumbIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path d="M6.40359 9.56195V22.0682C6.40359 22.7938 5.81531 23.3821 5.08969 23.3821H1.82953C1.10391 23.3821 0.515625 22.7938 0.515625 22.0682V9.56195C0.515625 8.83586 1.10391 8.24805 1.82953 8.24805H5.09016C5.81531 8.24805 6.40359 8.83586 6.40359 9.56195Z" fill="#FFA21E" stroke="#414141" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M20.9415 13.6002C21.7628 13.9405 22.6876 14.6225 22.5845 15.6931C22.4823 16.7638 20.9415 17.7369 19.7092 17.6881C19.3496 17.69 20.8904 18.0285 20.9415 18.8075C20.9931 19.5856 20.2224 20.9975 17.7577 20.7542C17.7577 20.7542 19.5549 21.3383 19.1438 22.2144C18.7327 23.09 18.1163 23.4791 15.0873 23.4791C12.1567 23.4791 7.01681 24.1621 6.40369 21.1246V9.66971C7.53947 8.77534 8.22666 8.73596 9.13041 8.49127C10.2085 8.19924 10.4138 6.20424 10.4138 4.98737C10.4138 3.77049 10.0388 0.557212 11.9026 0.461118C13.801 0.364087 14.8309 2.01081 14.8309 3.71377C14.8309 5.41721 15.0877 7.66299 14.8815 8.73409C16.3707 8.78237 18.7843 8.73409 20.4277 8.73409C22.0707 8.73409 23.252 9.65846 23.4568 10.8261C23.6631 11.9942 22.6871 13.4056 20.9415 13.6002Z" stroke="#414141" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M2.18616 16.1777V19.1759" stroke="#414141" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M3.45996 21.7921C2.00823 21.7921 2.18636 21.6463 2.18636 20.916C2.18636 20.758 2.18636 20.2832 2.18636 20.2832" stroke="#414141" strokeWidth="0.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>

);
};
