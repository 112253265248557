import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    formContainer: {
        padding: theme.spacing(10),
    },
    titleContainer: {
        marginBottom: theme.spacing(50),
        display: 'inline',
    },
    buttonCancel: {
        marginRight: '30px',
        boxZizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 32px',
        gap: '10px',
        width: '122px',
        height: '48px',
        background: '#FFFFFF',
        border: '1px solid #E6E6E6',
        borderRadius: '12px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        fontSize: '16px',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontWeight: 600,
        lineHeight: '100%',
    },
    buttonCreate: {
        marginRight: '30px',
        boxZizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 32px',
        gap: '10px',
        width: '122px',
        height: '48px',
        background: '#FFA11E',
        borderRadius: '12px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        fontSize: '16px',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontWeight: 600,
        lineHeight: '100%',
    },
    userId: {
        marginTop: theme.spacing(6),
    },
    popperStyle: {
        width: '300px !important',
        marginTop: '-10px !important',
        '& .MuiAutocomplete-paper': {
            borderRadius: '0px 0px 8px 8px',
            borderTop: '1px solid transparent',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
            backgroundColor: '#f9f9f9',
            padding:'0px 10px',
            '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option': {
                    paddingLeft: '5px'
                },
                '& .MuiAutocomplete-option[data-focus="true"]': {
                    backgroundColor: '#FFEACC',
                    borderRadius: '7px',
                }
            }
        }
    },
    promoCodeHeader: {
        marginTop: '5px',
        display: 'flex',
        minWidth: '20vh !important', 
    },
    reedemablePromo: {
        marginTop: '5px',
        width: '20vh',
    },
    reedemableTest:{
        display: 'inline-flex',
        height: '8vh',
        alignItems: 'end',
        marginLeft: '10px',
        fontSize: '18px',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontWeight: 400,
        lineHeight: 1,
    },
    promoHeaderField: {
        fontSize: '18px',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        fontWeight: 400,
        lineHeight: 1,
    },
    required: {
        color: 'red',
    },
    datePicker: {
        "& .MuiInputBase-root": {
            background:' #FAFAFA',
            boxShadow: 'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
            borderRadius: '7px',
            padding: '5px 0px',
            width: '100%'
        },
        "& .MuiInput-underline:before": {
            content: 'none !important'
        },
        "& .MuiInput-underline:after": {
            content: 'none !important'
        }
    },
    keyTitle: {
        fontStyle: 'normal',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        textAlign: 'left',
        letterSpacing: '0.05em',
        color: '#000000',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        MozFontFeatureSettings: "'pnum' on, 'lnum' on",
        WebkitFontFeatureSettings: "'pnum' on, 'lnum' on",
    },
    inputCustomWidth: {
        height: '45px',
    },
    formSwitchContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    switchTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
        color: '#000000',
        display: 'flex',
        marginBottom: '10px',
    },
    discountLabel: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
        color: '#000000',
        display: 'flex',
        alignSelf: 'center',
        marginLeft: '10px',
    },
    discountRadio: {
        marginRight: '25px',
    },
    boxOne: {
        backgroundColor: '#FAFAFA',
        padding: '23px',
        marginBottom: '5vh',
        borderRadius: '25px',
    },
    actionLabel: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
        textAlign: 'start',
        marginBottom: '3vh',
    },
    alphanumericLabel: {
        opacity: 0.5,
    }
}));