import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 10, 6),
        maxWidth: '740px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    buttonContainer: {
        '& .MuiButtonBase-root': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
}));