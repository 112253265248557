import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { AlertIcon } from 'components/_newIcon/';
import Typography from '../typography/Typography';
interface Props {
    message: string;
    style?: React.CSSProperties
}

const ErrorMessage: FC<Props> = ({ message, style }) => {

    return (
        <Box display={'flex'} alignItems="center" marginBottom={'10px'} marginTop="8px" style={style}>
            <AlertIcon />
            <Typography style={{marginLeft: '8px', color: '#D72C0D'}}>{message}</Typography>
        </Box>
    );
};
export default ErrorMessage;