import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function DashboardIcon({ className, ...props}: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 11" className={clsx(classes.root, className ?? className)}>
            <path d="M18.8612 9.25H1.13885C1.39814 4.58108 5.26627 0.875 10 0.875C14.7337 0.875 18.6019 4.58108 18.8612 9.25Z" stroke="#414141"/>
            <line x1="10.5" y1="0.375" x2="10.5" y2="2.875" stroke="#414141"/>
            <line x1="16.7979" y1="4.00248" x2="15.4721" y2="5.3283" stroke="#414141"/>
            <line y1="-0.5" x2="1.875" y2="-0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 4.45068 5.45068)" stroke="#414141"/>
            <circle cx="10.3125" cy="9.4375" r="1.0625" fill="white" stroke="black"/>
            <line x1="9.56588" y1="8.74807" x2="7.06588" y2="4.37307" stroke="black"/>
        </SvgIcon>
    );
};
