import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function TrashIcon({ className, ...props}: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 32" fill="none" className={clsx(classes.root, className ?? className)}>
            <path d="M26.054 11.2973V18.0372M6.27026 11.2973V29.3784C6.27026 30.274 6.95161 31 7.79209 31H24.5322C25.3727 31 26.054 30.274 26.054 29.3784V21.4324" stroke="red" strokeWidth="0.5"/>
            <path d="M11.1351 12.3513V27.1081" stroke="red" strokeWidth="0.5"/>
            <path d="M16.1621 12.3513V27.1081" stroke="red" strokeWidth="0.5"/>
            <path d="M21.1892 12.3513V27.1081" stroke="red" strokeWidth="0.5"/>
            <path d="M19.8477 4.52526V3.64395C19.8477 2.18374 18.6101 1 17.0835 1H15.2408C13.7142 1 12.4767 2.18374 12.4767 3.64395V4.52526M5.84275 9.10811H26.4816C27.4993 9.10811 28.3243 8.31895 28.3243 7.34547V6.2879C28.3243 5.31442 27.4993 4.52526 26.4816 4.52526H5.84275C4.82503 4.52526 4 5.31442 4 6.2879V7.34547C4 8.31895 4.82503 9.10811 5.84275 9.10811Z" stroke="red" strokeWidth="0.5"/>
        </SvgIcon>
    );
};
