// TODO: still need to optimize
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import { TableInstance } from 'react-table';
import { Box, Select, MenuItem, InputLabel } from '@material-ui/core';
import _MuiTablePagination from '@material-ui/lab/Pagination';

import {
    useStyles,
    SelectWrapper,
} from './styles';

// avoid all of the redraws caused by the internal withStyles
// const interestingPropsEqual = (prevProps: any, nextProps: any) =>
//     prevProps.count === nextProps.count &&
//     prevProps.page === nextProps.page;

// a bit of a type hack to keep OverridableComponent working as desired
type T = typeof _MuiTablePagination
// const MuiTablePagination: T = React.memo(_MuiTablePagination, interestingPropsEqual) as T
const MuiTablePagination: T = React.memo(_MuiTablePagination) as T

const TablePagination = <T extends object>({ instance }: PropsWithChildren<{ instance: TableInstance<T> }>): ReactElement | null => {
    const classes = useStyles();
    const {
        state: { pageIndex, pageSize },
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
    } = instance;

    const handleChangePage = useCallback(
        (event: React.ChangeEvent<unknown> | null, newPage: number) => {
            if (newPage === pageIndex + 2) {
                nextPage()
            } else if (newPage === pageIndex - 2) {
                previousPage()
            } else {
                gotoPage(newPage - 1)
            }
        },
        [gotoPage, nextPage, pageIndex, previousPage]
    )

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
        >
            <SelectWrapper>
                <InputLabel className={classes.select_label}>Rows per page: </InputLabel>
                <Select
                    value={pageSize}
                    onChange={e => { setPageSize(Number(e.target.value)); }}
                    disableUnderline
                >
                    {[10, 25, 50, 100].map(pageTotal => (
                        <MenuItem key={pageTotal} value={pageTotal}>{pageTotal}</MenuItem>
                    ))}
                </Select>
            </SelectWrapper>
            <MuiTablePagination
                count={pageCount}
                page={pageIndex + 1}
                onChange={handleChangePage}
            />
        </Box>
    );
}

export default TablePagination;
