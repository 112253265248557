import React, { FC } from 'react';
import {
    Box
} from '@material-ui/core';
import useStyles from './styles';


const WeeklyLoading: FC = () => {
    const classes = useStyles();

    return (
        <>
            <Box margin={'42px 50px 42px 50px'} className={classes.weeklyContainer} >
                <Box display={'flex'} mb={2} className={classes.weeklyContentRes2} justifyContent="center !important">
                    <Box display={'flex'} justifyContent="center !important">
                        <h1>No Data</h1>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export default WeeklyLoading;