import React, { FC, useCallback, useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";
import CreateOutlined from "@material-ui/icons/CreateOutlined";
import { Column } from "react-table";
import MTable from "components/MTable";
import EllipsisCell from "components/MTable/Cells/EllipsisCell";
import UserNameCell from "../_components/UserNameCell";
import ButtonIcon from "components/button/ButtonIcon";
import { getEmployeeUsers, updateCoachVisiblity } from "services/domain/admin/users";
import history from "services/data/history";
import { Toast } from "services/util/toast";
import DateCell from "components/MTable/Cells/DateCell";

type Name = {
  firstName: string;
  lastName: string;
};

type Booking = {
  _id: string;
  startDate: string;
  endDate: string;
};

type User = {
  _id: string;
  name: Name;
  role: string;
  createdDate: string;
  lastLogin: string;
  dataBookings: Booking[];
  coachName: string;
};

const ActionCell = ({ actionEdit, row }: { actionEdit: (_id: string) => void; row: any }) => (
  <>
    <ButtonIcon color="primary" variant="contained" onClick={() => actionEdit(row?.original?._id)} startIcon={<CreateOutlined />} />
  </>
);

const Employee: FC<any> = (props) => {
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndexMem, setPageIndexMem] = useState<any>(0);
  const [pageSizeMem, setPageSizeMem] = useState<any>(0);
  const [sortByMem, setSortByMem] = useState<any>("asc");
  const [dataSearch, setDataSearch] = useState<any>();
  const location = useLocation();

  const fetchUsers = useCallback(async ({ pageIndex, pageSize, sortBy, search }: { pageIndex: any; pageSize: any; sortBy: any; search?: any }) => {
    try {
      if (pageSize === 0) return;
      const result = await getEmployeeUsers({
        existData: pageIndex * pageSize,
        limitData: pageSize,
        userId: search,
      });
      setUsers(result.data);
      setPageCount(result.meta.pagination.totalPage);
      setPageIndexMem(pageIndex);
      setPageSizeMem(pageSize);
      setSortByMem(sortBy);
    } catch (e) {
      /**
       * TODO: error handle here
       */
    } finally {
      /**
       * TODO: final action here
       */
    }
  }, []);

  const columns: Column<User>[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
        Cell: EllipsisCell,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: UserNameCell,
      },
      {
        Header: "Coach",
        accessor: "coachName",
      },
      {
        Header: "Last Login",
        accessor: "lastLogin",
        Cell: DateCell,
      },
      {
        Header: "ACTION",
        // accessor: '',
        Cell: (props: any) => <ActionCell actionEdit={handleEditAction} {...props} />,
      },
      // eslint-disable-next-line
    ],
    [],
  );

  const handleEditAction = useCallback((_id: string) => {
    history.push(`${location.pathname}/edit/${_id}`);
    // eslint-disable-next-line
  }, []);

  const handleActivateAction = useCallback(async (userId, visibility) => {
    const action = visibility ? "Activate" : "Deactivate";
    const toast = Toast({});

    try {
      await updateCoachVisiblity({ userId, visibility });
      toast.fire({
        icon: "success",
        title: `${action} coach succeed`,
      });
      fetchUsers({
        pageIndex: pageIndexMem,
        pageSize: 50,
        sortBy: sortByMem,
      });
    } catch (e) {
      toast.fire({
        icon: "error",
        title: `${action} coach failed`,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDataSearch(props.searchValue);
  }, [props.searchValue]);

  useEffect(() => {
    if (dataSearch !== null) {
      fetchUsers({
        pageIndex: pageIndexMem,
        pageSize: pageSizeMem,
        sortBy: sortByMem,
        search: dataSearch?._id,
      });
    } else {
      fetchUsers({
        pageIndex: pageIndexMem,
        pageSize: pageSizeMem,
        sortBy: sortByMem,
      });
    }
    // eslint-disable-next-line
  }, [dataSearch]);

  return (
    <>
      <Box>
        <Paper>
          <MTable columns={columns} data={users} onFetchData={fetchUsers} pageCount={pageCount} />
        </Paper>
      </Box>
    </>
  );
};

export default Employee;
