import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import { useStyles } from './styles';

export default function SearchIcon(props: SvgIconProps) {
    const classes = useStyles();
    const { className } = props;

    return (
        <SvgIcon {...props} viewBox="0 0 24 24" className={clsx(classes.root, className)}>
            <path d="M9.57414 18.8954C14.5004 18.8954 18.494 14.9018 18.494 9.97551C18.494 5.04921 14.5004 1.05566 9.57414 1.05566C4.64785 1.05566 0.654297 5.04921 0.654297 9.97551C0.654297 14.9018 4.64785 18.8954 9.57414 18.8954Z" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M16.2544 15.8533L18.8166 18.4155" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M19.4226 17.7916L18.1926 19.0216L22.1154 22.9444L23.3454 21.7143L19.4226 17.7916Z" stroke="#C4C4C4" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
