import React from "react";
import moment from "moment";
import { Paper, Box, Button, Grid, Typography, RadioGroup, FormControlLabel, Radio, IconButton, Checkbox } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
// import { TextField } from 'formik-material-ui';
import * as Yup from "yup";

// import BookingInfo from 'components/_modules/booking/BookingInfo';
// import { Autocomplete as AutocompleteMui } from '@material-ui/lab';
// import PromoCodeIcon from 'components/icons/PromotionCodeIcon';
import { useStyles } from "./styles";
// import { phoneRegExp } from 'services/util/regex';
import history from "services/data/history";
// import { getUserByFirstName } from 'services/domain/users/users';
import { savePromoCode } from "services/domain/admin/promocodes";
// import { getTimeDiff } from 'services/util/common';
// import dummyAvatar from 'assets/images/dummy-avatar-256.png';
import { Toast } from "services/util/toast";

import { KeyboardDatePicker } from "@material-ui/pickers";
import MtextArea from "components/controls/CustomInput/MtextArea";
import CustomInput from "components/controls/CustomInput/CustomInput";

import { http } from "services/data/http";
import { ArrowBack } from "@material-ui/icons";

interface FormikState {
  code: string;
  redeemLimit: string;
  // percent: string;
  // cash: string;
  rate: string;
  description: string;
  // discountType: string;
}

const validationSchema = Yup.object({
  code: Yup.string()
    .required("Promo code is required")
    .test("Unique Promo Code", "Promo code already in use", (value) => {
      return new Promise((resolve, reject) => {
        http
          .get("search/promocode", {
            params: {
              code: value,
            },
          })
          .then((res) => {
            // exists
            res.data.length === 0 ? resolve(true) : resolve(false);
          })
          .catch((err) => {
            // note exists
            resolve(false);
          });
      });
    })
    .matches(/^\S*$/, "No Spaces in the promocode")
    .matches(/^[A-Z0-9]{1,15}$/, "Only Uppercase Character & 15 Characters limit"),
  // code: Yup.string().required('Promo code is required').matches(/^\S*$/, 'No spaces in the promocode'),
  redeemLimit: Yup.string().required("Usage limit is required").matches(/^\d+$/, "Numbers only"),
  // rate: Yup.string().required('Discount amount is required').matches(/^\d+$/, 'Numbers only').matches(/[^0]+/, 'Must not be 0'),
  description: Yup.string()
    .required("Description is required")
    .matches(/^.{1,200}$/, "Must not exceed 200 characters)"),
});

const initialValues = {
  code: "",
  redeemLimit: "",
  rate: "",
  description: "",
};

const PromocodeAdd = () => {
  const classes = useStyles();
  const [discountType, setDiscountType] = React.useState<string>("percent");
  const [startDate, setStartDateChange] = React.useState<Date | moment.Moment>(moment().add(1, "days"));
  const [expiryDate, setExpiryDateChange] = React.useState<Date | moment.Moment>(moment().add(2, "days"));
  const [noExpiry, setNoExpiry] = React.useState<boolean>(false);

  const goBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    setSubmitting(true);
    try {
      const { code, redeemLimit, rate, description } = values;

      const toast = Toast({ onClose: goBack });

      await savePromoCode({
        codeId: "",
        code: code,
        description: description,
        redeemLimit: redeemLimit,
        type: discountType,
        rate: rate,
        startDate: startDate,
        expiryDate: expiryDate,
        noExpiry: noExpiry,
      });

      toast.fire({
        icon: "success",
        title: "New promo code has been added",
      });
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Create new promo code failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onDiscountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountType((event.target as HTMLInputElement).value);
  };

  const handleStartDateChange = (startDate) => {
    setStartDateChange(moment(startDate));
  };

  const handleExpiryDateChange = (expiryDate) => {
    setExpiryDateChange(moment(expiryDate));
  };

  const handleNoExpiryChange = () => {
    setNoExpiry(!noExpiry);

    // set expiry date to start date but 2099
    if (!noExpiry) {
      setExpiryDateChange(moment(startDate).set("year", 2099));
    } else {
      setExpiryDateChange(moment(startDate).add(1, "days"));
    }
  };

  function validateRate(value) {
    let error;
    if (!value) {
      error = discountType === "percent" ? "Discount percent is required" : "Discount cash amount is required";
    } else if (!/^\d+$/.test(value)) {
      error = "Numbers only";
    } else if (!/[^0]+/.test(value) && discountType === "cash") {
      error = "Must not be 0";
    } else if (!/^[1-9][0-9]?$|^100$/.test(value) && discountType === "percent") {
      error = "Must be between 1 to 100";
    }
    return error;
  }

  return (
    <>
      <Box
        maxWidth="100%"
        height="80px"
        style={{
          background: "rgba(0, 0, 0, 0.05)",
          backgroundBlendMode: "multiply",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Grid container direction="row" alignItems="center" style={{ height: "100%", justifyContent: "flex-start" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"} width="40px" height="40px" padding={"10px"}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box display="flex" ml={1} justifyContent="center" textAlign={"left"}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "150%",
                color: "#414141",
              }}
            >
              Add New Promotion Code
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Paper className={classes.formContainer}>
        <Box display="flex" justifyContent="center" marginLeft="auto" marginRight="auto">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, getFieldProps, getFieldMeta, errors }) => {
              return (
                <Form>
                  <Box pt={7} width="75vh" textAlign="center" className={classes.boxOne}>
                    <Grid container alignItems="center" className={classes.titleContainer} style={{ justifyContent: "center" }}>
                      <div className={classes.actionLabel}>Promotion Code Information</div>
                    </Grid>

                    <Typography className={classes.label} style={{ marginTop: "10px" }}>
                      PROMOTION CODE <span className={classes.required}>*</span>
                    </Typography>

                    <div style={{ width: "50vh", textAlign: "start" }}>
                      <Field
                        as={CustomInput}
                        placeholder="Name of promotion code"
                        id="code"
                        autoFocus
                        className={classes.promoCodeHeader}
                        {...getFieldProps("code")}
                        meta={getFieldMeta("code")}
                      />
                    </div>
                    <Typography style={{ margin: "10px 0px 15px", opacity: 0.5, display: "flex" }}>Use alphanumeric characters with no spacing</Typography>

                    <Typography className={classes.label} style={{ marginTop: "10px" }}>
                      USAGE LIMIT <span className={classes.required}>*</span>
                    </Typography>
                    <div style={{ width: "50vh", textAlign: "start" }}>
                      <Field
                        component={CustomInput}
                        type="text"
                        placeholder="Maximum allowable redemption"
                        id="redeemLimit"
                        margin="normal"
                        className={classes.promoCodeHeader}
                        {...getFieldProps("redeemLimit")}
                        meta={getFieldMeta("redeemLimit")}
                        fullWidth
                      />
                    </div>

                    <Typography className={classes.label} style={{ marginTop: "10px" }}>
                      REDEEMABLE PROMO <span className={classes.required}>*</span>
                    </Typography>
                    <div style={{ width: "100%", textAlign: "start" }}>
                      <RadioGroup
                        onChange={onDiscountTypeChange}
                        aria-label="promo-type"
                        name="discount-type"
                        // defaultValue="percent"
                        value={discountType}
                      >
                        <div style={{ width: "60vh", textAlign: "start" }}>
                          <FormControlLabel
                            value="percent"
                            control={<Radio color="primary" />}
                            label="Percent Discount"
                            labelPlacement="end"
                            className={classes.discountRadio}
                          />

                          <FormControlLabel value="cash" control={<Radio color="primary" />} label="Cash Discount" labelPlacement="end" className={classes.discountRadio} />
                        </div>

                        <div style={{ width: "50%", textAlign: "start", display: "flex" }}>
                          <div style={{ width: "80%", textAlign: "start", display: "inline-block" }}>
                            <Field
                              component={CustomInput}
                              type="text"
                              placeholder={discountType === "percent" ? "Percent discount" : "Cash discount"}
                              id="rate"
                              margin="normal"
                              className={classes.reedemablePromo}
                              validate={validateRate}
                              {...getFieldProps("rate")}
                              meta={getFieldMeta("rate")}
                              maxWidth="md"
                            />
                          </div>
                          <div style={{ width: "21%", textAlign: "start", display: "flex" }}>
                            <span className={classes.discountLabel}>{discountType === "percent" ? "%" : "$"} Off</span>
                          </div>
                        </div>
                      </RadioGroup>
                    </div>
                    <div style={{ width: "100%", textAlign: "start", display: "flex" }}>
                      <div style={{ width: "35%", textAlign: "start", marginRight: "40px" }}>
                        <Typography className={classes.label} style={{ marginTop: "20px" }}>
                          START DATE<span className={classes.required}>*</span>
                        </Typography>
                        <KeyboardDatePicker
                          style={{ marginTop: "10px" }}
                          className={classes.datePicker}
                          variant="inline"
                          format="DD-MMM-yyyy"
                          value={startDate}
                          InputAdornmentProps={{ position: "start" }}
                          autoOk={true}
                          onChange={(date) => handleStartDateChange(date)}
                          minDate={moment(new Date())}
                        />
                      </div>
                      {noExpiry ? (
                        <></>
                      ) : (
                        <div style={{ width: "35%", textAlign: "start" }}>
                          <Typography className={classes.label} style={{ marginTop: "20px" }}>
                            EXPIRATION DATE <span className={classes.required}>*</span>
                          </Typography>
                          <KeyboardDatePicker
                            style={{ marginTop: "10px" }}
                            className={classes.datePicker}
                            variant="inline"
                            format="DD-MMM-yyyy"
                            value={expiryDate}
                            InputAdornmentProps={{ position: "start" }}
                            autoOk={true}
                            onChange={(date) => handleExpiryDateChange(date)}
                            minDate={moment(startDate).add(1, "days")}
                            minDateMessage="Expiry date should not be before Start Date"
                          />
                        </div>
                      )}
                    </div>

                    <div style={{ width: "35%", textAlign: "start" }}>
                      <Typography className={classes.label} style={{ marginTop: "20px" }}>
                        This promo code doesn't expire
                      </Typography>
                      <Checkbox checked={noExpiry} color={"primary"} onChange={handleNoExpiryChange} />
                    </div>

                    <Grid container className={classes.formSwitchContainer}>
                      <Grid item md={12} className={classes.switchTitleContainer}>
                        <Typography className={classes.label}>
                          DESCRIPTION <span className={classes.required}>*</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Field
                      as={MtextArea}
                      placeholder="Short description of the promotion code"
                      id="description"
                      rows={1}
                      className={classes.inputCustomWidth}
                      {...getFieldProps("description")}
                      meta={getFieldMeta("description")}
                    />
                  </Box>
                  <Box mt={8} display="flex" justifyContent="center" alignItems={"center"}>
                    <Button onClick={goBack} className={classes.buttonCancel} disabled={isSubmitting}>
                      Cancel
                    </Button>

                    <Button variant="contained" className={classes.buttonCreate} type="submit" disabled={isSubmitting}>
                      Create
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};

export default PromocodeAdd;
