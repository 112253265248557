import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    formContainer: {
        padding: theme.spacing(10),
    },
    titleContainer: {
        marginBottom: theme.spacing(10),
    },
    buttonCancel: {
        marginLeft: '16px',
    },
    userId: {
        marginTop: theme.spacing(6),
    },
    popperStyle: {
        width: '300px !important',
        marginTop: '-10px !important',
        '& .MuiAutocomplete-paper': {
            borderRadius: '0px 0px 8px 8px',
            borderTop: '1px solid transparent',
            boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
            backgroundColor: '#f9f9f9',
            padding:'0px 10px',
            '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option': {
                    paddingLeft: '5px'
                },
                '& .MuiAutocomplete-option[data-focus="true"]': {
                    backgroundColor: '#FFEACC',
                    borderRadius: '7px',
                }
            }
        }
    },
    buttonSort: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 8px',

        background: '#FFFFFF',

        border: '1px solid #FFA21E',

        boxShadow: '4px 4px 12px rgba(204, 204, 204, 0.5), -4px -4px 12px rgba(255, 255, 255, 0.7)',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    buttonFont: {
        fontFamily: 'Montserrat',
        textTransform: 'capitalize',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
        /* identical to box height */

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.025em',

        /* Primary/Primary */

        color: '#FFA21E',
    }
}));