import React, { FC } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Grid,
    Typography,
    Box,
} from '@material-ui/core';
import useStyles from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgressWithLabel from '../../../../../components/progress/CircularProgressWithLabel';
import {
    SpoonForkIcon,
    ImageIcon,
    GymIcon,
    HeadIcon,
    StretchIcon,
    AssesmentIcon
} from 'components/icons';

const OverviewMain: FC<any> = props => {
    const classes = useStyles();

    return (
        <Grid item md={12}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    className={classes.boxHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>OVERVIEW MAIN TASK</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><SpoonForkIcon/></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.dailyFoodLogData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Daily Food Log</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><ImageIcon /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.dailyPhotoData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Post a Daily Photo</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><GymIcon style={{color: "transparent"}} /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.dailyFitnessData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Daily Fitness</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><HeadIcon style={{color: "transparent"}} /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.dailyPersonalDev || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Daily Personal Development</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><StretchIcon style={{color: "transparent"}} /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.dailyStretchData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Daily Stretch</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><AssesmentIcon style={{color: "transparent"}} /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.weekInData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Week In Assessment</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box textAlign="center" m={2}>
                                <Box mb={1}><AssesmentIcon style={{color: "transparent"}} /></Box>
                                <Box mb={1}>
                                    <CircularProgressWithLabel value={props.data?.weekOutData || 0} size={50} />
                                </Box>
                                <Box>
                                    <Typography>Week Out Assessment</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
export default OverviewMain;
