import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import { useStyles } from '../_config/styles';

export default function AddIcon(props: SvgIconProps) {
    const classes = useStyles();
    const { className, ...otherProps } = props;

    return (
        <SvgIcon {...otherProps} viewBox="0 0 13 13" className={clsx(classes.root, className)}>
            <line x1="6.34998" y1="1.42062e-08" x2="6.34997" y2="13" stroke="#FFA21E"/>
            <line x1="13" y1="6.3501" x2="-2.84124e-08" y2="6.3501" stroke="#FFA21E"/>
        </SvgIcon>
    );
}
