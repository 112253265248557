import { BehaviorSubject } from 'rxjs';
import { http } from 'services/data';
import { NewMessageParam, MessageListParam, ChatListParam } from 'interfaces/chat';

const endPoints = {
    createMessage: 'chats/new',
    getMessages: 'chats/get',
    getAvailableContacts: 'chats/get/displayName',
    getChatList: 'chats/get/list',
};

export const createMessage = (payload: NewMessageParam) => http.post(endPoints.createMessage, payload);
export const getMessages = (payload: MessageListParam) => http.get(endPoints.getMessages, { params: payload });
export const getAvailableContacts = () => http.get(endPoints.getAvailableContacts);
export const getChatList = (payload: ChatListParam) => http.get(endPoints.getChatList, { params: payload });

export const chatSubject = new BehaviorSubject(null);
export const chatObservable = chatSubject.asObservable();
