import React from 'react';
import { SignUpLayout } from 'components/layout/containers';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import Feedback from 'components/layout/Feedback';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import movaraLogoWhite from 'assets/images/movara-home-logo-white.png';
import illustration from 'assets/images/illustration.png';
import auth from 'services/domain/auth';
import Button from 'components/_newDesign/button/Button';
import Timeline from './component/Timeline'
import { format } from 'date-fns'

const ProfileSubmitted = () => {
    const classes = useStyles();
    const history = useHistory();
    const user = auth.userProperties.userData;
    const dateString =( user.nextStart !== '' && user.nextStart !== undefined && user.nextStart !== null) ? format(new Date(user.nextStart), 'MMM dd, yyyy') : ''

    return (
        <>
            <SignUpLayout>
                <Container maxWidth="md">
                    <Box margin="auto" mb={10} mt={10} textAlign="center" maxWidth="800px">
                        <img src={movaraLogoWhite} alt="movara's logo" width="50%"/>
                    </Box>
                    <Box my={10}>
                        <Paper>
                            <Box
                                py={7}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Box mt={2}>
                                        <Typography className={classes.profileTitle}>
                                            You've completed your account set up!
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <img src={illustration} alt="movara's logo" width="300px"/>
                                    </Box>
                                    <Box>
                                        <Timeline />
                                    </Box>
                                    <Box mt={8}>
                                        <Typography className={classes.profileTitle}>
                                            While you're waiting...
                                        </Typography>
                                    </Box>
                                    <Box mt={2} px={22}>
                                        <Typography className={classes.profileDesc}>
                                        Your program will begin on
                                        <br />
                                        {dateString}
                                        <br />
                                        <br />
                                        On Friday Before Start  we'll send you a reminder email to  <b>Submit your Weekly Assessment</b> and introduce yourself to the group with a <b>Post on the Community Feed...</b>
                                        <br />
                                        <br />
                                        When your program starts you will get full access to the platform, but while you're waiting we've designed some reasources to help you get a head start, click the orange button below to see...
                                        </Typography>
                                    </Box>
                                    <Box mt={3}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            onClick={() => history.push('/waiting')}
                                        >
                                            Go to Welcome Page
                                        </Button>
                                    </Box>


                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </SignUpLayout>
            <Feedback />
        </>
    );
};

export default ProfileSubmitted;
