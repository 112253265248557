import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props extends SvgIconProps {
    isActive?: boolean;
    variant?: 'default' | 'white';
}

const useCustomStyles = makeStyles((theme: Theme) => ({
    root: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        color: 'transparent',
        '&.MuiSvgIcon-colorPrimary': {
            color: theme.palette.primary.main,
        },
        '&.variant-white': {
            color: '#FFF',
        },
    },
    active: {
        color: theme.palette.primary.main,
    },
}));

const CoachBadgeIcon: React.FC<Props> = ({ variant, ...props}) => {
    const customClasses = useCustomStyles();
    let letterStroke = '#FFA21E';
    let surroundedStroke = '#FFA21E';

    if (props.isActive) letterStroke = 'white';
    if (variant === 'white') surroundedStroke = 'white';

    return (
        <SvgIcon
            {...props}
            viewBox="0 0 16 16"
            className={clsx(customClasses.root, props.className && props.className, props.isActive && customClasses.active, variant === 'white' && 'variant-white')}
        >
            <path d="M1.99824 8L1 10.3182L3.16942 11.6065L3.72572 14.0679L6.2376 13.8376L8.13461 15.5L10.0316 13.8376L12.5435 14.0679L13.0998 11.6065L15.2692 10.3182L14.271 8L15.2692 5.68182L13.0998 4.39349L12.5435 1.93213L10.0316 2.1624L8.13461 0.5L6.2376 2.1624L3.72572 1.93213L3.16942 4.39349L1 5.68182L1.99824 8Z" stroke={surroundedStroke} strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M10.836 6.01317C10.4722 5.47422 9.90658 4.55811 8.23581 4.55811C6.56505 4.55811 5.2854 6.08042 5.2854 7.99354C5.2854 9.90666 6.76742 11.4423 8.3167 11.4423C9.86598 11.4423 10.8224 10.4859 10.9842 9.89302" stroke={letterStroke} strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
}

export default CoachBadgeIcon;
