import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function AlertIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 20 21"
            {...props}
            style={{
                color: 'white',
                width: props.width || '20px',
                height: props.height || '21px',
            }}
        >
            <path
                d="M8.57502 3.71635L1.51668 15.4997C1.37116 15.7517 1.29416 16.0374 1.29334 16.3284C1.29253 16.6195 1.36793 16.9056 1.51204 17.1585C1.65615 17.4113 1.86396 17.622 2.11477 17.7696C2.36559 17.9171 2.65068 17.9965 2.94168 17.9997H17.0583C17.3494 17.9965 17.6344 17.9171 17.8853 17.7696C18.1361 17.622 18.3439 17.4113 18.488 17.1585C18.6321 16.9056 18.7075 16.6195 18.7067 16.3284C18.7059 16.0374 18.6289 15.7517 18.4834 15.4997L11.425 3.71635C11.2765 3.47144 11.0673 3.26895 10.8177 3.12842C10.5681 2.98789 10.2865 2.91406 10 2.91406C9.71357 2.91406 9.43196 2.98789 9.18235 3.12842C8.93275 3.26895 8.72358 3.47144 8.57502 3.71635V3.71635Z"
                stroke="#EF5136"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 8V11.3333"
                stroke="#EF5136"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 14.667H10.0083"
                stroke="#EF5136"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
