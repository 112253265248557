import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Messages = lazy(() => import('./Messages'));

export default {
    path: routeConstants.MESSAGES.path,
    component: Messages,
    layout: LayoutOptions.main,
};
