import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function QuestionCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 20 21"
            {...props}
            style={{
                color: 'white',
                width: props.width || '20px',
                height: props.height || '20px',
            }}
        >
            <path
                d="M9.99996 18.8327C14.6023 18.8327 18.3333 15.1017 18.3333 10.4993C18.3333 5.89698 14.6023 2.16602 9.99996 2.16602C5.39759 2.16602 1.66663 5.89698 1.66663 10.4993C1.66663 15.1017 5.39759 18.8327 9.99996 18.8327Z"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.57495 8.00047C7.77087 7.44353 8.15758 6.9739 8.66658 6.67475C9.17558 6.37561 9.77403 6.26626 10.3559 6.36607C10.9378 6.46588 11.4656 6.76841 11.8458 7.22008C12.2261 7.67175 12.4342 8.24341 12.4333 8.83381C12.4333 10.5005 9.93328 11.3338 9.93328 11.3338"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 14.666H10.0083"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
