import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function DashboardIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 20" className={classes.root}>
            <path d="M17.4531 8.57041V18.6372C17.4531 19.0997 17.0781 19.4747 16.6156 19.4747H3.38438C2.92188 19.4747 2.54688 19.0997 2.54688 18.6372V8.57041C2.54688 8.10791 2.92188 7.73291 3.38438 7.73291H16.6156C17.0781 7.73291 17.4531 8.10791 17.4531 8.57041Z" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M4.34961 7.7332V2.83477C4.34961 1.55937 5.38398 0.525391 6.65937 0.525391H13.341C14.6168 0.525391 15.6504 1.55977 15.6504 2.83477V7.7332" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M10 13.691C10.7868 13.691 11.4247 13.0532 11.4247 12.2664C11.4247 11.4796 10.7868 10.8418 10 10.8418C9.21326 10.8418 8.57544 11.4796 8.57544 12.2664C8.57544 13.0532 9.21326 13.691 10 13.691Z" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M10 16.3664V13.6348" stroke="#FFA21E" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
}
