import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styleTimeLine';
import { DoubleCheckIcon, ComunityIcon, ZoomCallIcon } from 'components/_newIcon'
import auth from 'services/domain/auth';

interface TimelineProps {
    type?: string
    done?: boolean
}
const Timeline: React.FC<TimelineProps> = (props) => {
    const user = auth.userProperties.userData;
    const date = new Date(user.nextStart);
    const sunday = date.getDate();
    const friday = date.setDate(date.getDate() - 2);
    const fridayDate = new Date(friday).getDate()
    const classes = useStyles();
    if (props.type === 'first' || !props.type) {
        return (
            <Box className={classes.timeline}>
                <div className={classes.registerNow}>Sign Up</div>
                <svg height="5" width="120">
                    <line
                        x1="0"
                        y1="0"
                        x2="200"
                        y2="0"
                        style={{stroke: '#FFCA36', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
    
                <div className={classes.event1}>
                    <div className={classes.youHere}>You're here!</div>
                    <svg height="22" width="4" className={classes.lineVer}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="22"
                            style={{stroke: '#FFA11E', strokeWidth: 5}}
                        />
                    </svg>
                    <svg height="16" width="16">
                        <circle
                            cx="8"
                            cy="8"
                            r="5"
                            fill="#FFFFFF"
                            stroke="#FFA11E"
                            stroke-width="6"
                        />
                    </svg>
                    <div className={classes.accountSetDown}> <DoubleCheckIcon /> &nbsp; Account Set Up</div>
                </div>
                <svg height="5" width="120">
                    <line
                        x1="0"
                        y1="0"
                        x2="200"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.event1}>
                    <div className={classes.circle}>{fridayDate}</div>
                    <svg height="45" width="4" className={classes.lineDashEarly}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="45"
                            stroke-dasharray="4"
                            style={{stroke: '#E6E6E6', strokeWidth: 5}}
                        />
                    </svg>
                    <div className={classes.earlyAccess}> <ComunityIcon /> &nbsp; Early Access</div>
                </div>
                <svg height="5" width="25">
                    <line
                        x1="0"
                        y1="0"
                        x2="25"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5" fill="rgba(162, 164, 163, 0.37)" />
                </svg>
                <svg height="5" width="25">
                    <line
                        x1="0"
                        y1="0"
                        x2="25"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.event1}>
                    <div className={classes.circle}>{sunday || 0}</div>
                    <svg height="85" width="4" className={classes.lineDashEarly}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="85"
                            stroke-dasharray="4"
                            style={{stroke: '#E6E6E6', strokeWidth: 5}}
                        />
                    </svg>
                    <div className={classes.sundayCall}><ZoomCallIcon /> &nbsp; Sunday Call</div>
                </div>
            </Box>
        );
    }
    if (props.type === 'second') {
        return (
            <Box className={classes.timeline}>
                <div className={classes.registerNow}>Sign Up</div>
                <svg height="5" width="72">
                    <line
                        x1="0"
                        y1="0"
                        x2="72"
                        y2="0"
                        style={{stroke: '#FFCA36', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.registerNow}>Account Set Up</div>
                <svg height="5" width="72">
                    <line
                        x1="0"
                        y1="0"
                        x2="72"
                        y2="0"
                        style={{stroke: '#FFCA36', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.event1}>
                    <div className={classes.youHere}>You're here!</div>
                    <svg height="22" width="4" className={classes.lineVer}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="22"
                            style={{stroke: '#FFA11E', strokeWidth: 5}}
                        />
                    </svg>
                    <svg height="16" width="16">
                        <circle
                            cx="8"
                            cy="8"
                            r="5"
                            fill="#FFFFFF"
                            stroke="#FFA11E"
                            stroke-width="6"
                        />
                    </svg>
                    <div className={classes.earlyAccess} style={{top: '39px', border: '2px solid #FFA11E', left: '-56px', backgroundColor: props.done ? '#FFA11E' : '#F2F2F2'}}> 
                        {props.done ? <DoubleCheckIcon /> : <ComunityIcon />} &nbsp; Early Access
                    </div>
                </div>
                <svg height="5" width="25">
                    <line
                        x1="0"
                        y1="0"
                        x2="25"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5" fill="rgba(162, 164, 163, 0.37)" />
                </svg>
                <svg height="5" width="25">
                    <line
                        x1="0"
                        y1="0"
                        x2="25"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.event1}>
                    <div className={classes.circle}>{sunday || 0}</div>
                    <svg height="65" width="4" className={classes.lineDashEarly}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="65"
                            stroke-dasharray="4"
                            style={{stroke: '#E6E6E6', strokeWidth: 5}}
                        />
                    </svg>
                    <div className={classes.sundayCall} style={{top: '96px'}}><ZoomCallIcon /> &nbsp; Sunday Call</div>
                </div>
            </Box>
        );
    }
    if (props.type === 'third') {
        return (
            <Box className={classes.timeline}>
                <div className={classes.registerNow}>Sign Up</div>
                <svg height="5" width="120">
                    <line
                        x1="0"
                        y1="0"
                        x2="200"
                        y2="0"
                        style={{stroke: '#FFCA36', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
    
                <div className={classes.event1}>
                    <div className={classes.youHere}>You're here!</div>
                    <svg height="22" width="4" className={classes.lineVer}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="22"
                            style={{stroke: '#FFA11E', strokeWidth: 5}}
                        />
                    </svg>
                    <svg height="16" width="16">
                        <circle
                            cx="8"
                            cy="8"
                            r="5"
                            fill="#FFFFFF"
                            stroke="#FFA11E"
                            stroke-width="6"
                        />
                    </svg>
                    <div className={classes.accountSetDown} style={{left: '-54px'}}> <DoubleCheckIcon /> &nbsp; Onbarding</div>
                </div>
                <svg height="5" width="120">
                    <line
                        x1="0"
                        y1="0"
                        x2="200"
                        y2="0"
                        style={{stroke: '#E2E2E2', strokeWidth: 3}}
                    />
                    Sorry, your browser does not support inline SVG.
                </svg>
                <div className={classes.event1}>
                    <div className={classes.circle}>{sunday || 0}</div>
                    <svg height="85" width="4" className={classes.lineDashEarly}>
                        <line
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="85"
                            stroke-dasharray="4"
                            style={{stroke: '#E6E6E6', strokeWidth: 5}}
                        />
                    </svg>
                    <div className={classes.sundayCall}><ZoomCallIcon /> &nbsp; Sunday Call</div>
                </div>
            </Box>
        );
    }
    return null
};

export default Timeline;
