import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    timeline: {
        display: 'flex',
        alignItems: 'center',
        height: '250px',
    },

    event1: {
        position: 'relative',
    },

    registerNow: {
        background: '#FFA11E',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.25px',
        color: '#000000',
        padding: '4px 8px',
        width: 'max-content'
    },

    youHere: {
        position: 'absolute',
        background: '#FFA11E',
        borderRadius: '48px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '150%',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.1px',
        padding: '10px 16px',
        color: '#000000',
        width: 'max-content',
        top: '-69px',
        left: '-66px',
    },
    lineVer: {
        position: 'absolute',
        top: '-22px',
        left: '7px',
    },
    accountSetDown: {
        background: '#FFA11E',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.25px',
        color: '#000000',
        padding: '4px 8px',
        position: 'absolute',
        width: 'max-content',
        left: '-68px',
        top: '39px',
    },

    circle: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '180px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0.02em',
        textAlign: 'center',
        color: '#000000',
    },

    lineDashEarly: {
        position: 'absolute',
        top: '40px',
        left: '18px',
    },
    earlyAccess: {
        background: '#F2F2F2',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.25px',
        color: '#000000',
        padding: '4px 8px',
        position: 'absolute',
        width: 'max-content',
        left: '-47px',
        top: '85px',
    },
    sundayCall: {
        background: '#F2F2F2',
        borderRadius: '20px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.25px',
        color: '#000000',
        padding: '4px 8px',
        position: 'absolute',
        width: 'max-content',
        left: '-83px',
        top: '125px',
    },
}));
