// @ts-nocheck
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
    chatList: [],
    chatActiveUser: null,
    chatAssessmentUser: null,
};

export const { Types, Creators } = createActions({
    updateChatList: ['chatList'],
    updateChatActiveUser: ['userId', 'name', 'displayName', 'role', 'avatar'],
    updateChatAssessmentUser: ['userId', 'name', 'displayName', 'avatar'],
    // updateChatListItem: ['chatItem'],
});

const updateChatList = (state = INITIAL_STATE, { chatList }) => {
    return { ...state, chatList };
}

const updateChatActiveUser = (state = INITIAL_STATE, { userId, name, displayName, role, avatar }) => {
    let chatList;
    const foundChatListIndex = state.chatList.findIndex(chatListItem => chatListItem.from === userId && chatListItem.unread > 0);

    if (foundChatListIndex > -1) {
        const updatedChatList = state.chatList.map((chatListItem, index) => {
            if (index === foundChatListIndex) {
                return Object.assign({}, chatListItem, { unread: 0 });
            }

            return chatListItem;
        });

        chatList = {
            chatList: updatedChatList,
        };
    }
    

    return { ...state, chatActiveUser: { id: userId, name, displayName, role, avatar }, ...chatList};
}

const updateChatAssessmentUser = (state = INITIAL_STATE, { userId, name, displayName, avatar } ) => {
    return { ...state, chatAssessmentUser: { userId, name, displayName, avatar } };
};

/*
const updateChatListItem = (state = INITIAL_STATE, { chatItem }) => {
    const isItemExist = state.chatList.find(chat => chat._id === chatItem._id);

    const excludeItem = state.chatList.filter(chat => chat)
};
*/

const HANDLERS = {
    [Types.UPDATE_CHAT_LIST]: updateChatList,
    [Types.UPDATE_CHAT_ACTIVE_USER]: updateChatActiveUser,
    [Types.UPDATE_CHAT_ASSESSMENT_USER]: updateChatAssessmentUser, 
    // [Types.UPDATE_CHAT_LIST_ITEM]: updateChatListItem,
};

export default createReducer(INITIAL_STATE, HANDLERS);
