import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function SpoonForkIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props}
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path d="M12.5635 7.43701L6.28455 13.7159" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M6.87594 14.3071L5.69238 13.1235L0.700983 18.115L1.88455 19.2985L6.87594 14.3071Z"
                  strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M15.4567 7.77128C14.7289 8.49901 13.4164 8.36698 12.525 7.47558L12.5246 7.47519C11.6332 6.58378 11.5012 5.27129 12.2289 4.54355L13.5891 3.42285L16.5774 6.41113L15.4567 7.77128Z"
                strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M19.15 3.2876L16.5769 6.41104" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M13.5885 3.42314L16.7119 0.850098" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M17.8397 2.12646L15.0663 4.90029" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M6.92468 7.07373L13.1653 13.3144" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M13.7566 12.7222L12.573 13.9058L17.5644 18.8972L18.748 17.7136L13.7566 12.7222Z"
                  strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M2.94034 6.71876C4.42277 8.20118 5.88409 8.0379 6.88605 7.03594C7.888 6.03399 8.05128 4.57266 6.56886 3.09024C5.08644 1.60782 2.52003 0.665241 1.51808 1.66758C0.516125 2.66993 1.45792 5.23633 2.94034 6.71876Z"
                strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
