import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0, 2),
        width: '740px',
        maxWidth: '740px',
        maxHeight: '95%',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
        borderRadius: '20px',
    },
    header: {
        // width: '805px',
        // maxWidth: '805px',
    },
    'header-item': {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        // maxWidth: '586px',
        textAlign: 'center',
    },
    'image-holder': {
        marginTop: theme.spacing(2),
        backgroundColor: '#FAFAFA',
        border: '2px dashed rgba(0, 0, 0, 0.1)',
        // height: '461px',
        paddingBottom: '33.48%', // 461/580*100%
        position: 'relative',
    },
    'remove-image': {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        // '&:hover': {
        //     backgroundColor: 'transparent',
        // }
    },
    'image-content': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    'image-placeholder': {
        top: theme.spacing(11),
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
            width: '30%',
        },
    },
    'browse-container': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(1),
        },
        textAlign: 'center',
    },
    'image-info': {
        maxWidth: '319px',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    'upload-progress': {
        width: '100%',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(3),
    },
    'upload-button': {
        marginTop: theme.spacing(4),
    },
    'upload-text': {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '150%',
        /* identical to box height, or 36px */

        letterSpacing:' 0.15px',

        color: '#000000',
    }
}));