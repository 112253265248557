import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const BookingsGuest = lazy(() => import('./BookingsGuest'));

export default {
    path: routeConstants.BOOKINGSGUEST.path,
    component: BookingsGuest,
    layout: LayoutOptions.single,
    roles: ['user', 'admin']
};
