import React, { FC } from 'react';
import { makeStyles, TextareaAutosize, Theme, Typography } from '@material-ui/core';
import { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    textAreaMov: {
        padding: '12px 16px',
        minHeight: 61,
        background: '#FAFAFA',
        boxShadow: 'inset 2px 2px 6px rgba(128, 128, 128, 0.2), inset -2px -2px 6px rgba(255, 255, 255, 0.5)',
        borderRadius: '7px',
        minWidth:'100%',
        border: 'none',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
    },
    inputClass: {
        '&::placeholder': {
            color: '#282828',
            opacity: 1
        }
    },
}));

const MTextArea: FC<Props & TextareaAutosizeProps> = ({ meta, isSubmitted, placeholder, ...props }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <TextareaAutosize
                {...props}
                aria-label="minimum height" 
                placeholder={placeholder} 
                className={classes.textAreaMov}/>
            {meta?.touched && meta?.error && (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            )}
        </React.Fragment>
    );
};

export default MTextArea;
