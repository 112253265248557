import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function PasswordIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 32" className={classes.root}>
            <path d="M6.88892 12.7686V19.231" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M4.60376 13.7148L9.17376 18.2848" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M3.65698 16H10.1201" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M4.60376 18.2848L9.17376 13.7148" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M16 12.7686V19.231" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M13.7151 13.7148L18.2851 18.2848" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M12.7688 16H19.2313" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M13.7151 18.2848L18.2851 13.7148" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M25.1113 12.7686V19.231" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M22.8264 13.7148L27.3964 18.2848" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M21.8801 16H28.3433" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M22.8264 18.2848L27.3964 13.7148" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
            <path d="M30.0326 21.3411H1.96761C1.30386 21.3411 0.766357 20.8029 0.766357 20.1398V11.8604C0.766357 11.1967 1.30448 10.6592 1.96761 10.6592H30.0332C30.697 10.6592 31.2345 11.1973 31.2345 11.8604V20.1398C31.2345 20.8029 30.6964 21.3411 30.0326 21.3411Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round" />
        </SvgIcon>
    );
}
