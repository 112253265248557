import {
    Box, createStyles, Dialog,
    DialogContent,
    IconButton, Theme, Typography, WithStyles, withStyles,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { http } from '../../../services/data';
import Loading from '../../../components/progress/Loading';
import moment from 'moment';


interface Props {
    show: boolean;
    onClose: () => void;
    data: any;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Box textAlign="center">
                <Typography variant="h6">{children}</Typography>
            </Box>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});



const UserChartModal: FC<Props> = ({ show, onClose, data }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [chart, setChart] = useState([]);

    useEffect(() => {
        if (show) {
            setLoading(true);
            const { name: { email = '' } } = data;
            const params = {
                email,
            };
            http.get('admin/assessments/data/weight/email', {
                params,
            }).then((res) => {
                const { data: { data } } = res;
                const reformatChart = data.map((item: any) => ({
                    longTerm: item.goalWeight.data,
                    current: item.currentWeight.data,
                    longTermUnit: item.goalWeight.typeData,
                    currentUnit: item.currentWeight.typeData,
                    met: 0,
                    missed: 0,
                    date: moment(item.submissionDate).format('MMM  D'),
                })).sort((a:any, b:any) => b.date - a.date);
                setChart(reformatChart);
            }).catch(() => {

            }).finally(() => {
                setLoading(false);
            });
        }
    }, [show, data]);
    return (<Dialog onClose={() => onClose()}
                    aria-labelledby="customized-dialog-title" open={show} fullWidth
                    maxWidth={'lg'}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
            WEIGHT PROGRESS MONTH VIEW
        </DialogTitle>
        <DialogContent>
            {!loading ? (
                <Box>
                    <ResponsiveContainer height={300}>
                        <LineChart data={chart} margin={{ top: 5, bottom: 5 }}>
                            <CartesianGrid/>
                            <XAxis dataKey="date"/>
                            <YAxis/>
                            <Tooltip formatter={(value, name, props:any) => {
                                const units = props.payload[`${props.dataKey}Unit`] || ''
                                return [`${value} ${units}`, name];
                            }}/>
                            <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{
                                paddingLeft: '20px',
                            }}/>
                            <Line name="LONG TERM" dataKey="longTerm" strokeWidth={2} stroke="#FFDAA5"/>
                            <Line name="CURRENT" dataKey="current" strokeWidth={2} stroke="#CA822A"/>
                            <Line name="MET" dataKey="met" strokeWidth={2} stroke="#FFA21E"/>
                            <Line name="MISSED" dataKey="missed" strokeWidth={2} stroke="#FFA21E"/>
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            ) : (
                <Box height={'100px'} display={'flex'} alignItems={'center'}
                     justifyContent={'center'}>
                    <Loading/>
                </Box>
            )}
        </DialogContent>
    </Dialog>);
};
export default UserChartModal;