import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ImageIcon(props: SvgIconProps) {

    return (
        <SvgIcon {...props}>
            <path d="M19.375 9.0625L4.84375 14.7458L4.84375 3.37921L19.375 9.0625Z" stroke="#414141" strokeLinejoin="round"/>
            <line x1="4.875" y1="2.5" x2="4.875" y2="19.375" stroke="#414141" strokeLinejoin="round"/>
            <circle cx="4.6875" cy="1.5625" r="0.9375" stroke="#414141"/>
        </SvgIcon>
    );
};
