import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 120,
            height: 48,
            padding: '8px 1px 8px 23px',
        },
        switchBase: {
            padding: 11,
            color: '#ff6a00',
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        thumb: {
            width: 52,
            height: 30,
            borderRadius: 7,
            backgroundColor: '#fff',
            marginTop: -3,
            marginLeft: 10,
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        track: {
            background: 'grey',
            opacity: '1 !important',
            borderRadius: 10,
            height: 30,
            width: 100,
            position: 'relative',
            '&:before, &:after': {
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                width: '50%',
                transform: 'translateY(-50%)',
                color: '#fff',
                textAlign: 'center',
                fontSize: '11px',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '20px',
                letterSpacing: '0.05em',

            },
            '&:before': {
                content: '"SHOW"',
                left: 0,
                top: 16,
                opacity: 0,
            },
            '&:after': {
                content: '"HIDE"',
                right: 2,
                top: 16,
            },
        },
        checked: {
            '&$switchBase': {
                color: '#185a9d',
                transform: 'translateX(47px)',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& $thumb': {
                backgroundColor: '#fff',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& + $track': {
                background: 'linear-gradient(to right, #FFA21E, #FFA21E)',
                '&:before': {
                    opacity: 1,
                },
                '&:after': {
                    opacity: 0,
                },
            },
        },
        unchecked: {
            '&$switchBase': {
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& $thumb': {
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
        },
    })
);

export const useStylesSecond = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 120,
            height: 48,
            padding: '8px 1px 8px 23px',
        },
        switchBase: {
            padding: 11,
            color: '#ff6a00',
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        thumb: {
            width: 52,
            height: 30,
            borderRadius: 7,
            backgroundColor: '#fff',
            marginTop: -3,
            marginLeft: 10,
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        track: {
            background: 'grey',
            opacity: '1 !important',
            borderRadius: 10,
            height: 30,
            width: 100,
            position: 'relative',
            '&:before, &:after': {
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                width: '50%',
                transform: 'translateY(-50%)',
                color: '#fff',
                textAlign: 'center',
                fontSize: '11px',
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '20px',
                letterSpacing: '0.05em',

            },
            '&:before': {
                content: '"YES"',
                left: 0,
                top: 16,
                opacity: 0,
            },
            '&:after': {
                content: '"NO"',
                right: 2,
                top: 16,
            },
        },
        checked: {
            '&$switchBase': {
                color: '#185a9d',
                transform: 'translateX(47px)',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& $thumb': {
                backgroundColor: '#fff',
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& + $track': {
                background: 'linear-gradient(to right, #FFA21E, #FFA21E)',
                '&:before': {
                    opacity: 1,
                },
                '&:after': {
                    opacity: 0,
                },
            },
        },
        unchecked: {
            '&$switchBase': {
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
            '& $thumb': {
                '&:hover': {
                    backgroundColor: 'unset',
                },
            },
        },
    })
);
