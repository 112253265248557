import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import {
    DateRange,
    DateRangePickerComponent,
} from 'materialui-daterange-picker';

interface CalendarProps {
    dateRangeValues: DateRange | undefined;
    onCalendarChange: (dateRange: DateRange) => void;
}
const Calendar: FC<CalendarProps> = (props) => {
    const classes = useStyles();

    const onChange = (range: DateRange) => {
        props.onCalendarChange(range);
    };

    return (
        <React.Fragment>
            <Box pt={1} className={classes.dateRangeClass}>
                <DateRangePickerComponent
                    open={true}
                    onChange={(range) => onChange(range)}
                    definedRanges={[]}
                    initialDateRange={props.dateRangeValues}
                />
            </Box>
        </React.Fragment>
    );
};

export default Calendar;
