import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowLeftCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 20 20"
            style={{
                color: props.color || 'black',
                width: props.width || '20px',
                height: props.height || '20px',
            }}
        >
            <path
                d="M7.66149 6.07041L7.48908 5.88938L7.30804 6.06179L4.50804 8.72846C4.43372 8.79924 4.39166 8.89738 4.39166 9.00001C4.39166 9.10264 4.43372 9.20078 4.50804 9.27156L7.30804 11.9382L7.48908 12.1106L7.66149 11.9296L7.8339 11.7486L8.00632 11.5675L7.82528 11.3951L5.70416 9.37501H12.2333H12.4833V9.12501V8.87501V8.62501H12.2333H5.70416L7.82528 6.6049L8.00632 6.43248L7.8339 6.25145L7.66149 6.07041ZM16 9C16 13.1421 12.6421 16.5 8.5 16.5C4.35786 16.5 1 13.1421 1 9C1 4.85786 4.35786 1.5 8.5 1.5C12.6421 1.5 16 4.85786 16 9ZM8.5 17.25C13.0563 17.25 16.75 13.5563 16.75 9C16.75 4.44365 13.0563 0.75 8.5 0.75C3.94365 0.75 0.25 4.44365 0.25 9C0.25 13.5563 3.94365 17.25 8.5 17.25Z"
                fill={props.fill || "#B3B3B3"}
                stroke={props.stroke || "#B3B3B3"}
                strokeWidth="0.5"
            />
        </SvgIcon>
    );
}
