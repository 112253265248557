import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function AssesmentIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 33 33" className={classes.root}>
            <path
                d="M30.2161 30.3773C20.8605 31.6927 11.3542 31.6927 1.99861 30.3773C0.469863 20.7188 0.469863 11.5477 1.99861 1.88734C5.03924 1.46052 8.09549 1.1716 11.158 1.02246V5.56153C11.158 6.72719 12.1049 7.67257 13.2724 7.67257H18.9424C20.1099 7.67257 21.0567 6.72719 21.0567 5.56153V1.02246C24.1192 1.1716 27.1755 1.46052 30.2161 1.88734C31.7449 11.5471 31.7449 20.7182 30.2161 30.3773Z"
                stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M21.0567 1.02258V5.56165C21.0567 6.72731 20.1098 7.67269 18.9423 7.67269H13.2723C12.1048 7.67269 11.158 6.72731 11.158 5.56165V1.02258C14.4555 0.860962 17.7592 0.860962 21.0567 1.02258Z"
                stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M27.5488 4.70898V27.556H4.66626V4.70898H11.1581V5.56139C11.1581 6.72704 12.105 7.67242 13.2725 7.67242H18.9425C20.11 7.67242 21.0569 6.72704 21.0569 5.56139V4.70898H27.5488Z"
                stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path
                d="M21.0567 0.624023V5.56184C21.0567 6.7275 20.1098 7.67288 18.9423 7.67288H13.2723C12.1048 7.67288 11.158 6.7275 11.158 5.56184V0.624023H21.0567Z"
                stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M8.4895 11.9678H23.5689" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M8.4895 17.0664H23.5689" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M8.4895 22.1641H23.5689" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
