import React, { FC, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "pages/DashboardUser/components/main/daily/styles";
import InputText from "components/_newDesign/input/InputText";
import ButtonPopUp from "./ButtonPopUp";
import ErrorMessage from "components/_newDesign/alert/ErrorMessage";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview: boolean;
  isDone?: boolean;
  defaultValue: string;
}
const EliminateLabel: FC<Props> = (props) => {
  const isDone = props.isDone || false;
  const classes = useStyles();
  const [eliminate, setEliminate] = React.useState<string>(props.defaultValue);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const onChange = (e: any) => {
    if (e.target.value.length > 50) {
      setEliminate(eliminate);
    } else {
      setEliminate(e.target.value);
      props.onChange && props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setEliminate(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box display={"flex"} flexDirection="column">
      <Box display={"flex"} flexDirection={matches ? "column" : "row"} width={matches ? "100%" : "360px"}>
        <Box flexGrow={1} display="flex" alignItems={"center"} marginLeft={1}>
          <Typography gutterBottom className={classes.nutritionGoalTypo}>
            <span style={{ color: "black" }}>Eliminate</span>
          </Typography>
        </Box>
        <Box flexGrow={1} marginLeft="10px">
          <InputText
            disabled={isDone}
            multiline
            onChange={onChange}
            placeholder="ex. Refined sugar"
            value={eliminate}
            endAdornment={<Typography variant="caption">{`${eliminate.length}/50`}</Typography>}
          />
        </Box>
      </Box>
      {props.active && eliminate === "" && <ErrorMessage message={"Field is Required"} />}
      {props.active && (
        <Box marginTop={"10px"}>
          <ButtonPopUp title="Eliminate" type="eliminate" preview={props.preview} />
        </Box>
      )}
    </Box>
  );
};

export default EliminateLabel;
