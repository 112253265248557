import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function StretchedIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7592 1.40207C18.4578 1.1692 19.2129 1.54675 19.4458 2.24534L21.2639 7.69989C21.4236 8.179 21.2989 8.70722 20.9418 9.06433L17.1846 12.8216L18.2304 19.0964C19.9468 21.3792 21.0292 22.7409 21.5381 23.2533C22.2613 23.9445 23.1102 24.701 23.8488 25.3545C23.9432 25.4381 24.037 25.521 24.1294 25.6026C24.7281 26.1315 25.2649 26.6058 25.5009 26.8481C25.5061 26.8534 25.5128 26.8602 25.5209 26.8683C25.6174 26.9649 25.9065 27.2546 26.0754 27.6386C26.3189 28.1922 26.2854 28.8086 25.9662 29.3735C25.9198 29.4557 25.8648 29.5327 25.8021 29.6032L25.3981 30.058C25.3811 30.0771 25.3635 30.0957 25.3454 30.1139C24.8531 30.6076 24.2347 30.6491 23.8238 30.5855C23.4326 30.525 23.087 30.3577 22.8278 30.2034C22.7399 30.1511 22.6583 30.0888 22.5846 30.0179L18.4679 26.0514L18.4502 26.034C17.8779 25.4618 17.3138 24.8913 15.9586 23.1282L11.6473 25.1996L9.72013 29.3931C9.38166 30.232 8.43358 30.876 7.37451 30.607L6.81501 30.4644C5.73359 30.188 5.14681 29.0924 5.38602 28.0762C5.40307 28.0038 5.42615 27.9329 5.45502 27.8643L7.86108 22.1496C7.99269 21.837 8.23848 21.5863 8.54842 21.4486L11.2967 20.2271C11.2015 20.1952 11.1083 20.1519 11.0191 20.0969L7.29894 17.8017C6.9161 17.5655 6.67816 17.1522 6.66617 16.7025C6.65417 16.2529 6.86973 15.8274 7.23943 15.5712L12.5728 11.8742C12.705 11.7825 12.8528 11.7155 13.009 11.6765L15.0749 11.16L18.4735 7.7614L16.9159 3.08861C16.6831 2.39002 17.0606 1.63493 17.7592 1.40207ZM12.363 15.2643L10.4331 16.602L12.4193 17.8274C12.6092 17.9446 12.7594 18.1017 12.8658 18.2812L12.363 15.2643ZM13.0247 19.2346C13.0062 19.3233 12.9784 19.411 12.941 19.4963L13.0596 19.4436L13.0247 19.2346Z"
                fill="#6D6D6D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5752 6.90907C9.5752 4.83381 11.2575 3.15149 13.3328 3.15149C15.408 3.15149 17.0903 4.83381 17.0903 6.90907C17.0903 8.98432 15.408 10.6666 13.3328 10.6666C11.2575 10.6666 9.5752 8.98432 9.5752 6.90907ZM13.3328 5.81816C12.7303 5.81816 12.2419 6.30657 12.2419 6.90907C12.2419 7.51156 12.7303 7.99998 13.3328 7.99998C13.9353 7.99998 14.4237 7.51156 14.4237 6.90907C14.4237 6.30657 13.9353 5.81816 13.3328 5.81816Z"
                fill="#6D6D6D"
            />
        </SvgIcon>
    );
}
