import React, { useEffect } from "react";
import * as reactRouterDom from "react-router-dom";
import { Paper, Box, Card, CardMedia, Typography, CardContent, Tabs, Tab, Grid, IconButton } from "@material-ui/core";
import clsx from "clsx";

import MImageUploader from "components/MImageUploader";
import BigAvatar from "components/layout/Avatar/BigAvatar";
import LocationIcon from "components/icons/LocationIcon";
import GearIcon from "components/icons/GearIcon";
import withBackdropLoader, { InjectedLoaderProps } from "components/core/withBackdropLoader";
import { TabPanel } from "./components";
import ProfileForm from "./Form";
import { useStyles } from "./styles";
import dummyProfileCover from "assets/images/profile-cover.jpg";

import auth from "services/domain/auth";
import { getUser, getProfileMetrics } from "services/domain/users";
import AddIcon from "components/icons/AddIcon";
import { useSnackbar } from "notistack";
import { uploadAvatar, uploadHeader } from "services/domain/users";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { InfoIcon } from "components/icons";
import ImageModal from "components/modal/ImageModal";
import PointsLogCard from "./components/PointsLogCard";

const Progress = React.lazy(() => import("./Progress"));
const Post = React.lazy(() => import("./Post"));
const Activity = React.lazy(() => import("./Activity"));

function a11yProps(index: any) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

const ProfileContainer: React.FC<InjectedLoaderProps> = (props) => {
  const [tabValue, setTabValue] = React.useState("progress");
  const [profile, setProfile] = React.useState({
    displayName: "",
    about: {
      age: {
        age: 0,
        isAgeShow: false,
      },
      beenGuest: {
        isCheck: false,
        desc: "",
      },
      bio: "",
      isShowAbout: false,
      location: {
        location: "",
        isLocationShow: false,
      },
      occupation: {
        occupation: "",
        isOccupationShow: false,
      },
    },
    avatar: {
      path: "",
    },
    headerProfile: {
      path: "",
    },
    email: "",
    _id: "",
    totalPoints: 0,
  });

  // eslint-disable-next-line
  const [profileMetrics, setProfileMotrics] = React.useState({
    bodyFat: 0,
    date: new Date(),
    goal: {
      data: 0,
      typeData: "lbs",
    },
    hip: 10,
    in: {
      data: 0,
      typeData: "lbs",
    },
    lbsLost: 0,
    maxBar: 10,
    thisWeekGoal: "",
    waist: 0,
    isAge: false,
    isLocation: false,
    isOccupation: false,
    isProgress: false,
  });
  const classes = useStyles();
  const { action } = reactRouterDom.useParams<{ action: string }>();
  const { id }: { id: string } = reactRouterDom.useParams();
  const location = reactRouterDom.useLocation();
  const isEdit = action === "edit";
  const [imgAvatar, setImgAvatar] = React.useState<any>(undefined);
  const [imgHeader, setImgHeader] = React.useState<any>(undefined);
  const uploadAvatarRef = React.useRef(null);
  const uploadHeaderRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const avatar = profile?.avatar?.path ?? dummyAvatar;
  const header = profile?.headerProfile?.path || dummyProfileCover;
  const [image, setImage] = React.useState<string>("");
  const [show, setShow] = React.useState<boolean>(false);

  const fetchData = async () => {
    props.showLoader();
    try {
      const userId: string = id ? id : auth.userProperties?.userData?._id;
      const asynUser = getUser({ user_id: userId });
      const asyncProfileMetrics = getProfileMetrics({ userId });

      const resultUser = await asynUser;

      const resultProfileMetrics = await asyncProfileMetrics;

      if (!resultProfileMetrics.data.isProgress) {
        setTabValue("post");
      }

      if ("data" in resultUser) {
        setProfile(resultUser.data);
      }

      if ("data" in resultProfileMetrics) {
      }
    } catch (e) {
      enqueueSnackbar("Profile not found.", { variant: "error" });
      setTimeout(() => {
        // history.goBack()
      }, 2000);
      /**
       * TODO: handling error here
       */
    } finally {
      props.hideLoader();
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const postAvatar = async (image: any, doneCallback: () => void) => {
    try {
      const formData = new FormData();
      formData.append("avatar", image);
      const rspn = await uploadAvatar(formData);
      setImgAvatar(rspn.data.path);
      setTimeout(() => {
        enqueueSnackbar("Upload Avatar Success", { variant: "success" });
        fetchData();
        (uploadAvatarRef as any)?.current.close();
      }, 300);
    } catch (error) {
      setTimeout(() => {
        let message = "";

        // TODO figure out how to handle this
        if (error.messages?.length === 1) {
          message = `: ${error?.messages[0]?.message}`;
        }
        enqueueSnackbar(`Upload Avatar Failed${message}`, { variant: "error" });
      }, 500);
    } finally {
      doneCallback();
    }
  };

  const postHeader = async (image: any, doneCallback: () => void) => {
    try {
      const formData = new FormData();
      formData.append("header", image);
      const rspn = await uploadHeader(formData);
      setImgHeader(rspn.data.path);
      setTimeout(() => {
        enqueueSnackbar("Upload Header Success", { variant: "success" });
        fetchData();
        (uploadHeaderRef as any)?.current.close();
      }, 300);
    } catch (error) {
      setTimeout(() => {
        let message = "";

        if (error.messages?.length === 1) {
          message = `: ${error.messages[0]?.message}`;
        }
        enqueueSnackbar(`Upload Header Failed${message}`, { variant: "error" });
      });
    } finally {
      doneCallback();
    }
  };

  const avatarClicHandler = () => {
    (uploadAvatarRef as any)?.current.open();
  };

  const headerClickHandler = () => {
    (uploadHeaderRef as any)?.current.open();
  };

  const handleClickImage = (image: string) => {
    if (image && image !== "") {
      setImage(image);
      setTimeout(() => {
        setShow(true);
      }, 100);
    }
  };

  return (
    <Box my={15} display="flex" justifyContent="center">
      <ImageModal image={image} show={show} onClose={() => setShow(false)} />
      <MImageUploader ref={uploadAvatarRef} title="Upload Photo" defaultImage={imgAvatar ? imgAvatar : profile?.avatar?.path} onUpload={postAvatar} />
      <MImageUploader ref={uploadHeaderRef} title="Upload Photo" defaultImage={imgHeader ? imgHeader : profile?.headerProfile?.path} onUpload={postHeader} />
      <Card className={classes.root}>
        {isEdit ? (
          <React.Fragment>
            <label htmlFor="header-button-file" onClick={headerClickHandler}>
              <div className={classes.container}>
                <CardMedia className={classes.image} height={"300px"} component="img" image={`${imgHeader ? imgHeader : header}`} title="Click to update Image Header" />
                <div className={classes.middle}>
                  <AddIcon fontSize="large" />
                  <Typography className={classes.coverInfo}>
                    {" "}
                    <InfoIcon style={{ marginRight: 5 }} /> Set up a cover image that makes you happy!
                  </Typography>
                </div>
              </div>
            </label>
          </React.Fragment>
        ) : (
          <CardMedia onClick={() => handleClickImage(header)} className={classes.image} height={"300px"} component="img" image={header} />
        )}

        <CardContent>
          <Box className={classes.cardContentWrapper}>
            <Box className={classes.profilInfo}>
              <Box className={classes.avatarCointainer}>
                {isEdit ? (
                  <React.Fragment>
                    <label htmlFor="avatar-button-file">
                      <div className={classes.containerAvatar} onClick={avatarClicHandler}>
                        <BigAvatar src={`${imgAvatar ? imgAvatar : avatar}`} />
                        <div className={classes.middleAvatar}>
                          <AddIcon fontSize="large" />
                          <Typography className={classes.coverInfo}>
                            {" "}
                            <InfoIcon style={{ marginRight: 5 }} /> Set up a profile picture
                          </Typography>
                        </div>
                      </div>
                    </label>
                  </React.Fragment>
                ) : (
                  <Box>
                    <BigAvatar
                      src={avatar}
                      classStyle={classes.avatar}
                      onClick={() => {
                        handleClickImage(avatar);
                      }}
                    />
                    <Box className={classes.points}>
                      <Grid container className={classes.pointsContainer}>
                        <Grid item>
                          <Typography align={"center"} className={classes.pointsValue}>
                            {profile.totalPoints}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography align="center" className={classes.pointsLabel}>
                            POINTS
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>

              <Box pl={3} className={clsx(classes.profileInfo, isEdit && "is-edit")}>
                <Box display="flex" justifyContent="space-between">
                  <Typography gutterBottom variant="h5">
                    {profile.displayName}
                  </Typography>
                  {id === undefined && (
                    <IconButton className="icon-setting" component={reactRouterDom.Link} to={`${location.pathname}/edit`}>
                      <GearIcon />
                    </IconButton>
                  )}
                </Box>
                <Typography variant="body2" className="profile__items">
                  {profile.about?.bio}
                </Typography>

                <Box mt={3} className="profile__items">
                  <Grid container>
                    <Grid item md={6}>
                      <Typography>
                        {profileMetrics.isOccupation ? profile.about?.occupation?.occupation || "-" : "-"}, {profileMetrics.isAge ? profile.about?.age?.age || "-" : "-"}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Box display="flex">
                        <LocationIcon />
                        <Typography>{profileMetrics.isLocation ? profile.about?.location?.location || "-" : "-"}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3} className={clsx("profile__items")}>
                  <Typography className="profile__guest-desc">
                    <span style={{ color: "#282828" }}>Guest to Movara</span>
                    {` ${profile.about?.beenGuest?.desc}`}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {!isEdit && (
              <>
                <Box mt={3}>
                  <Paper elevation={3}>
                    <Box display="flex" justifyContent="center">
                      <div style={{ flexGrow: 1 }} />
                      <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
                        {profile.email !== "" && profileMetrics.isProgress && <Tab value="progress" label="Progress" {...a11yProps("progress")} />}
                        <Tab value="post" label="Post" {...a11yProps("post")} />
                        <Tab value="activity" label="Activity" {...a11yProps("activity")} />
                        <Tab value="points" label="Points" {...a11yProps("points")} />
                      </Tabs>
                      <div style={{ flexGrow: 1 }} />
                    </Box>
                  </Paper>
                </Box>

                <React.Suspense fallback={<h1>Loading ...</h1>}>
                  <TabPanel value={tabValue} index="progress">
                    {profile.email !== "" && profileMetrics.isProgress && <Progress profile={profile} data={profileMetrics} avatar={avatar} />}
                  </TabPanel>
                  <TabPanel value={tabValue} index="post">
                    <Post profile={profile} />
                  </TabPanel>
                  <TabPanel value={tabValue} index="activity">
                    <Activity profile={profile} />
                  </TabPanel>
                  <TabPanel value={tabValue} index="points">
                    <PointsLogCard profile={profile} getProfile={fetchData} />
                  </TabPanel>
                </React.Suspense>
              </>
            )}

            {isEdit && (
              //
              <ProfileForm profile={profile} fetchProfile={fetchData} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withBackdropLoader(ProfileContainer);
