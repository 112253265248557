import { makeStyles, Theme, fade } from '@material-ui/core/styles';
import { drawerWidth } from 'config/constants';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#FFF',
    },
    rootDashboard: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    logo: {
        padding: theme.spacing(2, 0),
        width: '50%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    search: {
        flex: 1,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('md')]: {
        //   width: '20ch',
        // },
    },
    grow: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
    },
    navBarStyle: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        }
    }
}));