import { http } from 'services/data';
import { PaginationInterface } from 'interfaces/common';
import { PromoCodeData, PromoCodeStatus, PromoCodeIdInterface, PromoCodeDetails } from 'interfaces/promocode';

const endPoints = {
    promoCodes: 'admin/promocodes/getPromoCodes',
    promoCodeById: 'admin/promocodes/getPromoCodeById',
    updatePromoCodeStatus: 'admin/promocodes/updatePromoCodeStatus',
    savePromoCode: 'admin/promocodes/save',
    getPromoCodeDetails: 'admin/promocodes/getPromoCodeDetails',
};

export const getPromoCodes = (payload: PaginationInterface) => http.get(endPoints.promoCodes, { params: payload });
export const getPromoCodeById = (payload: PromoCodeIdInterface) => http.get(endPoints.promoCodeById, { params: payload });
export const updatePromoCodeStatus = (payload: PromoCodeStatus) => http.post(endPoints.updatePromoCodeStatus, payload);
export const savePromoCode = (payload: PromoCodeData) => http.post(endPoints.savePromoCode, payload);
export const getPromoCodeDetails = (payload: PromoCodeDetails) => http.get(endPoints.getPromoCodeDetails, { params: payload });