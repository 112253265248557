import React, { createContext, useState } from 'react';
import { chatLimitPerRequest } from '../config';

export const PaginationContext = createContext({
    after: 0,
    limit: 20,
    setAfter: (after: number) => {},
    reset: () => {},
});

export const ChatPaginationProvider = ({ children }: { children: any }) => {
    const [after, setAfter] = useState<number | null>(null);

    return (
        <PaginationContext.Provider
            value={{
                limit: chatLimitPerRequest,
                after: after!,
                setAfter,
                reset: () => setAfter(null),
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
}
