/**
 *   @description - convert into an array of objects where the header is the key and the cell is the value
 *  @param headers - array of strings for the header labels
 *  @param rows - array of string arrays - each array containing all of the cell values
 *   @returns - array of objects
 */

export default (headers: string[], rows: string[][]) => {
  const formattedArrayOfObjects = rows.map((row) => {
    const rowObject = {};

    row.forEach((cell, i) => {
      const key = headers[i];
      const value = cell;

      rowObject[key] = value;
    });

    return rowObject;
  });

  return formattedArrayOfObjects;
};
