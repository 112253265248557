import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function AlertCircleIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 150 150"
            style={{
                color: props.color || 'black',
                width: props.width || '150px',
                height: props.height || '150px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.5 18.6853C46.1913 18.6853 20 43.7822 20 74.7407C20 105.699 46.1913 130.796 78.5 130.796C110.809 130.796 137 105.699 137 74.7407C137 43.7822 110.809 18.6853 78.5 18.6853ZM7 74.7407C7 36.9025 39.0116 6.22852 78.5 6.22852C117.988 6.22852 150 36.9025 150 74.7407C150 112.579 117.988 143.253 78.5 143.253C39.0116 143.253 7 112.579 7 74.7407Z"
                fill={props.fill || "#FFD557"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.5 43.5986C82.0899 43.5986 85 46.3872 85 49.827V74.7406C85 78.1804 82.0899 80.9689 78.5 80.9689C74.9101 80.9689 72 78.1804 72 74.7406V49.827C72 46.3872 74.9101 43.5986 78.5 43.5986Z"
                fill={props.fill || "#FFD557"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 99.6542C72 96.2143 74.9101 93.4258 78.5 93.4258H78.565C82.1549 93.4258 85.065 96.2143 85.065 99.6542C85.065 103.094 82.1549 105.883 78.565 105.883H78.5C74.9101 105.883 72 103.094 72 99.6542Z"
                fill={props.fill || "#FFD557"}
            />
        </SvgIcon>
    );
}
