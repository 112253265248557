import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CalendarDotIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 17 17"
            {...props}
            style={{
                color: props.color || 'white',
                width: props.width || '17px',
                height: props.height || '17px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.8335 3.33333C3.46531 3.33333 3.16683 3.63181 3.16683 4V13.3333C3.16683 13.7015 3.46531 14 3.8335 14H13.1668C13.535 14 13.8335 13.7015 13.8335 13.3333V4C13.8335 3.63181 13.535 3.33333 13.1668 3.33333H3.8335ZM1.8335 4C1.8335 2.89543 2.72893 2 3.8335 2H13.1668C14.2714 2 15.1668 2.89543 15.1668 4V13.3333C15.1668 14.4379 14.2714 15.3333 13.1668 15.3333H3.8335C2.72893 15.3333 1.8335 14.4379 1.8335 13.3333V4Z"
                fill={props.fill || "#9A9A9A"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1667 0.666504C11.5349 0.666504 11.8333 0.964981 11.8333 1.33317V3.99984C11.8333 4.36803 11.5349 4.6665 11.1667 4.6665C10.7985 4.6665 10.5 4.36803 10.5 3.99984V1.33317C10.5 0.964981 10.7985 0.666504 11.1667 0.666504Z"
                fill={props.fill || "#9A9A9A"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83366 0.666504C6.20185 0.666504 6.50033 0.964981 6.50033 1.33317V3.99984C6.50033 4.36803 6.20185 4.6665 5.83366 4.6665C5.46547 4.6665 5.16699 4.36803 5.16699 3.99984V1.33317C5.16699 0.964981 5.46547 0.666504 5.83366 0.666504Z"
                fill={props.fill || "#9A9A9A"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.8335 6.66667C1.8335 6.29848 2.13197 6 2.50016 6H14.5002C14.8684 6 15.1668 6.29848 15.1668 6.66667C15.1668 7.03486 14.8684 7.33333 14.5002 7.33333H2.50016C2.13197 7.33333 1.8335 7.03486 1.8335 6.66667Z"
                fill={props.fill || "#9A9A9A"}
            />
            <path
                d="M9.16699 9.99967C9.16699 9.63148 9.46547 9.33301 9.83366 9.33301H11.8337C12.2018 9.33301 12.5003 9.63148 12.5003 9.99967V11.9997C12.5003 12.3679 12.2018 12.6663 11.8337 12.6663H9.83366C9.46547 12.6663 9.16699 12.3679 9.16699 11.9997V9.99967Z"
                fill={props.fill || "#9A9A9A"}
            />
        </SvgIcon>
    );
}
