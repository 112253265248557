import React, { FC, useEffect, useState, Suspense } from 'react';
import {
    Avatar,
    Box, Button,
    Grid,
    GridList,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    Paper,
    Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { http } from '../../../services/data';
import Loading from '../../../components/progress/Loading';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import MChip from '../../../components/MChip';

export interface userListInterface {
    createdDate: Date
    email: string
    lastLogin: Date
    name: { firstName: string, lastName: string }
    phoneNumber: number
    statusBooking: null
}

const Guest: FC = () => {
    const classes = useStyles();
    const Details = React.lazy(() => import('./Details'));
    const [userList, setUserList] = useState<any[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };
    const fetchData = () => {
        setLoading(true);
        const params = {
            existData: 0,
            limitData: 10,
        };
        http.get('admin/report/guest', { params }).then(res => {
            setUserList(res.data.usersData);
        }).finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Box my={2}><Typography gutterBottom variant="h4">Guest</Typography></Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" justifyContent="flex-end" flex={1}>
                        <Button variant="contained" startIcon={<DownloadIcon/>}>
                            Download
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper elevation={3}>
                        <GridList cellHeight={800}>
                            <List className={classes.list}
                                  component="nav"
                                  aria-labelledby="user"
                            >
                                {loading ? (<Loading/>) : (
                                    <>
                                        {userList.map((item, index) => (
                                                <ListItem key={index} button selected={selectedIndex === index}
                                                          className={classes.listItem}
                                                          onClick={(event) => handleListItemClick(index)}>
                                                    <ListItemIcon>
                                                        <Avatar alt={item?.name.firstName}
                                                                src={item?.avatar?.path}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={
                                                        <>
                                                            <Typography
                                                                gutterBottom>{item?.name.firstName} {item?.name.lastName}</Typography>
                                                        </>
                                                    }
                                                                  secondary={
                                                                      <>
                                                                          {item?.statusBooking && (
                                                                              <MChip label={item?.statusBooking}
                                                                                     type={'block'}/>
                                                                          )}
                                                                      </>
                                                                  }/>
                                                </ListItem>
                                            ),
                                        )}
                                    </>
                                )}
                            </List>
                        </GridList>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Suspense fallback={<Loading/>}>
                        <Details user={userList[selectedIndex]}/>
                    </Suspense>
                </Grid>
            </Grid>
        </>
    );
};
export default Guest;
