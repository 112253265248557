import { Box, Paper, Tooltip } from '@material-ui/core';
import MTable from 'components/_newDesign/table/Table';
import Typography from 'components/_newDesign/typography/Typography';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { getCoachReports } from 'services/domain/admin/accounting';
import { Toast } from 'services/util/toast';
import TypographyMui from 'components/_newDesign/typography/Typography';
import moment from 'moment';
import { formatCentsToDollars } from 'services/util';
import { getUserById } from 'services/domain/admin/users';
import ButtonIcon from 'components/button/ButtonIcon';
import GetAppIcon from '@material-ui/icons/GetApp';
import coachReport from 'services/util/generatePDF/coachReport';

const CoachReports: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [coach, setCoach] = useState<any>({});
    const [coachReports, setCoachReports] = useState<any[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [refresh, setRefresh] = useState<boolean>(false);

    const tableComp = React.useRef<any>(null);

    const handleGeneratePDFClick = async (report: any) => {
        try {
            coachReport(report, coach);
        } catch (e) {
            console.error(e);
        }
    };

    const columns: Column<any>[] = useMemo(
        () => [
            {
                Header: 'Start Date',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            {moment(props.row.original.startDate).format(
                                'MM/DD/YYYY'
                            )}
                        </TypographyMui>
                    </Box>
                ),
            },
            {
                Header: 'End Date',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            {moment(props.row.original.endDate).format(
                                'MM/DD/YYYY'
                            )}
                        </TypographyMui>
                    </Box>
                ),
            },
            {
                Header: 'Payment Rate',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            {formatCentsToDollars(
                                props.row.original.paymentRate
                            )}
                        </TypographyMui>
                    </Box>
                ),
            },
            {
                Header: 'Number of Bookings',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            {props.row.original.bookings.length}
                        </TypographyMui>
                    </Box>
                ),
            },
            {
                Header: 'Total Payment',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            {formatCentsToDollars(
                                props.row.original.totalPayment
                            )}
                        </TypographyMui>
                    </Box>
                ),
            },
            {
                Header: 'Download as PDF',
                Cell: (props) => (
                    <Box textAlign={'center'} margin="auto">
                        <TypographyMui variant="mov_body1">
                            <Tooltip title="Download as PDF">
                                <ButtonIcon
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        handleGeneratePDFClick(
                                            props.row.original
                                        );
                                    }}
                                    startIcon={<GetAppIcon />}
                                />
                            </Tooltip>
                        </TypographyMui>
                    </Box>
                ),
            },
        ],
        [coach]
    );

    // fetch the coach's reports
    const fetchCoachReports = useCallback(
        async ({
            pageIndex,
            pageSize,
            sortBy,
        }: {
            pageIndex: any;
            pageSize: any;
            sortBy: any;
        }) => {
            try {
                setLoading(true);

                const result = await getCoachReports({
                    existData: pageIndex * pageSize,
                    limitData: pageSize,
                    coachId: id,
                });

                result.data.map((elm) => {
                    if (elm.startDate)
                        elm.startDate = elm.startDate.slice(0, 10);
                    if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
                    return elm;
                });

                const { data } = result;

                setCoachReports(data);
                setPageCount(result.meta.pagination.totalPage);
            } catch (e) {
                console.error(e);

                const errorToast = Toast({});
                errorToast.fire({
                    icon: 'error',
                    title: 'Failed to load Coach Reports',
                    text: 'Please refresh the page to try again.',
                });
            } finally {
                setLoading(false);
            }
        },
        []
    );

    // fetch the coach data so we can display the coach name
    const fetchCoach = async () => {
        try {
            setLoading(true);

            const res = await getUserById({
                id,
            });

            const { data } = res;

            setCoach(data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCoach();
    }, []);

    return (
        <>
            <Typography variant="h4">Weekly Coach Reports</Typography>
            {coach.name && (
                <Box mt={2}>
                    <Typography variant="h5">
                        {coach.name.firstName} {coach.name.lastName}
                    </Typography>
                </Box>
            )}

            <Box mt={3}>
                <Paper>
                    <MTable
                        columns={columns}
                        data={coachReports}
                        onFetchData={fetchCoachReports}
                        pageCount={pageCount}
                        refreshPage={refresh}
                        loading={loading}
                        ref={tableComp}
                    />
                </Paper>
            </Box>
        </>
    );
};

export default CoachReports;
