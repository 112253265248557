import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import { useStyles } from '../_config/styles';

export default function AddIcon(props: SvgIconProps) {
    const classes = useStyles();
    const { className, ...otherProps } = props;

    return (
        <SvgIcon {...otherProps} viewBox="0 0 32 32" className={clsx(classes.root, className)}>
            <g clipPath="url(#clip0)">
                <path d="M29.1932 5.73792L15.8175 15.3705C15.4856 15.6098 15.0373 15.6098 14.7054 15.3705L1.33032 5.73792" stroke="#414141" strokeMiterlimit="10" />
                <path d="M28.1174 4.55872H22.2754H15.2619H8.24838H2.40636C1.75916 4.55872 1.23486 5.08301 1.23486 5.73021V12.2099V14.7601V17.3103V23.7901C1.23486 24.4373 1.75916 24.9616 2.40636 24.9616H8.24838H15.2619C15.2619 24.9616 15.2619 26.4299 15.2619 29.9854C18.8684 29.2058 22.1163 27.2226 24.5768 24.9616H28.1174C28.7646 24.9616 29.2889 24.4373 29.2889 23.7901V17.3103V14.7601V12.2099V5.73021C29.2889 5.08301 28.7646 4.55872 28.1174 4.55872Z" stroke="#414141" strokeMiterlimit="10" />
                <circle cx="23.5" cy="7.5" r="7.5" fill="#FFA21E" />
                <path d="M23.5 15.5C27.9183 15.5 31.5 11.9183 31.5 7.5C31.5 3.08172 27.9183 -0.5 23.5 -0.5C19.0817 -0.5 15.5 3.08172 15.5 7.5C15.5 11.9183 19.0817 15.5 23.5 15.5Z" fill="white" stroke="white" strokeMiterlimit="10" />
                <path d="M30.5 7.5C30.5 11.366 27.366 14.5 23.5 14.5C19.634 14.5 16.5 11.366 16.5 7.5C16.5 3.63401 19.634 0.5 23.5 0.5C27.366 0.5 30.5 3.63401 30.5 7.5Z" fill="white" stroke="#414141" strokeMiterlimit="10" />
                <path d="M23.4999 2.03662V11.988" stroke="#414141" strokeMiterlimit="10" />
                <path d="M18.5243 7.01233H28.4757" stroke="#414141" strokeMiterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
