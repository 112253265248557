import React from 'react';
import { Box } from '@material-ui/core';

import ProfileForm from './Form';
import { useStyles } from './Form/styles';

const ProfileContainer: React.FC<any> = (props) => {
    // eslint-disable-next-line
    const [profile, setProfile] = React.useState({
        displayName: '',
        about: {
            age: 0,
            beenGuest: {
                isCheck: false,
                desc: '',
            },
            bio: '',
            isShowAbout: false,
            location: '',
            occupation: '',
        },
        avatar: {
            path: '',
        },
    });
    const classes = useStyles();

    const changeStatusStep = (key: number) => {
        props.changeStatusStep(key);
    };

    const onProfileSubmit = (values) => {
        props.onProfileSubmit(values);
    };

    return (
        <Box display="flex" justifyContent="center" mt="20px">
            <Box className={classes.root}>
                <ProfileForm
                    profile={profile}
                    changeStatusStep={(key: number) => changeStatusStep(key)}
                    onProfileSubmit={(values) => onProfileSubmit(values)}
                    hasAddWeeksQuery={props.hasAddWeeksQuery || false}
                />
            </Box>
        </Box>
    );
};

export default ProfileContainer;
