import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SmileIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            fill="none"
            viewBox="0 0 36 36"
            {...props}
            style={{
                color: 'white',
                width: props.width || '36px',
                height: props.height || '36px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5ZM1.5 18C1.5 8.8873 8.8873 1.5 18 1.5C27.1127 1.5 34.5 8.8873 34.5 18C34.5 27.1127 27.1127 34.5 18 34.5C8.8873 34.5 1.5 27.1127 1.5 18Z"
                fill="#6D6D6D"
            />
            <path
                d="M18 25.5C20.7089 25.5 23.1024 24.1843 24.6008 22.1677C25.2311 21.3194 24.5224 20.25 23.4655 20.25H12.5344C11.4776 20.25 10.7688 21.3194 11.3991 22.1677C12.8975 24.1843 15.2911 25.5 18 25.5Z"
                fill="#6D6D6D"
            />
            <path
                d="M23.25 15.75C24.495 15.75 25.5 14.745 25.5 13.5C25.5 12.255 24.495 11.25 23.25 11.25C22.005 11.25 21 12.255 21 13.5C21 14.745 22.005 15.75 23.25 15.75Z"
                fill="#6D6D6D"
            />
            <path
                d="M12.75 15.75C13.995 15.75 15 14.745 15 13.5C15 12.255 13.995 11.25 12.75 11.25C11.505 11.25 10.5 12.255 10.5 13.5C10.5 14.745 11.505 15.75 12.75 15.75Z"
                fill="#6D6D6D"
            />
        </SvgIcon>
    );
}
