import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, FormControl, IconButton, InputBase, Modal, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { CommentIcon, BugIcon } from 'components/icons'
import { useSnackbar } from 'notistack'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { sendFeedback, Feedback as IFeedback} from 'services/domain/users'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface Props {
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonStyle: {
        position: 'fixed',
        bottom: 70,
        left:  -122,
        textTransform: 'none',
        fontSize: '14px',
        boxShadow: '0px 15px 60px rgba(0, 0, 0, 0.12)',
        borderRadius: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.05em',
        backgroundColor: '#FFA21E',
        color: 'white',
        transition: 'left 1s',
        msTransition: 'left 1s',
        WebkitTransition: 'left 1s',
        [theme.breakpoints.down('xs')]: {
            bottom: 120,
        },
    },
    buttonStyleOpen: {
        left:  0,
    },
    buttonSubmit: {
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '100%',
        boxShadow: '0px 15px 60px rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
    },
    iconStyle: {
        marginRight: 5,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputPost: {
        boxShadow: 'none',
        padding: '20px',
        width: '100%',
    },
    taskInputText: {
        background: '#F8F8F8',
        boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
        borderRadius: '7px',
        padding: '4px 10px',
    },
    iconArrow: {
        transition: 'transform .8s ease-in-out',
        msTransition: 'transform .8s ease-in-out',
        WebkitTransition: 'transform .8s ease-in-out',
    },
    iconArrowOpen: {
        transform: 'rotate(180deg)',
        msTransform: 'rotate(180deg)',
        WebkitTransform: 'rotate(180deg)',
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box py={3} textAlign="center">
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Feedback: FC<{}> = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [openButton, setOpenButton] = React.useState<boolean>(false);
    const classes = useStyles();
    const [value, setValue] = React.useState<number>(0);
    const [url, setUrl] = React.useState<string>('');
    const [problem, setProblem] = React.useState<string>('');
    const [improvement, setImprovement] = React.useState<string>('');
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const onChangeButtonToggle = () => {
        const status = !openButton
        setOpenButton(status)
    }

    const sendImprovement = async () => {
        try {
            if (improvement === '') return
            setLoading(true)
            const payload: IFeedback = {
                url: "",
                message: improvement
            }
            await sendFeedback(payload)
            enqueueSnackbar('Submit Improvement Success', {variant: 'success'})
            setImprovement('')
            setOpen(false)
        } catch (error) {
            enqueueSnackbar('Submit Improvement Failed', {variant: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const onSendFeedback = async () => {
        try {
            if (url === '' || problem === '') return
            setLoading(true)
            const payload: IFeedback = {
                url: url,
                message: problem
            }
            await sendFeedback(payload)
            enqueueSnackbar('Submit Improvement Success', {variant: 'success'})
            setUrl('')
            setProblem('')
            setOpen(false)
        } catch (error) {
            enqueueSnackbar('Submit Improvement Failed', {variant: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Box className={`${classes.buttonStyle} ${openButton && classes.buttonStyleOpen}`} display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                <Box p={1} display="flex" justifyContent="center" alignContent="center" alignItems="center">
                    <CommentIcon className="iconStyle" stroke="white"/>
                </Box>
                <Box onClick={() => setOpen(true)} p={1} display="flex" justifyContent="center" alignContent="center" alignItems="center">
                    Feedback
                </Box>
                <Box
                    onClick={onChangeButtonToggle}
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    borderLeft="0.5px solid">
                    <ArrowForwardIosIcon className={`${classes.iconArrow} ${openButton && classes.iconArrowOpen}`}/>
                </Box>
            </Box>

            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                className={classes.modal}
                onClose={() => setOpen(false)}
                closeAfterTransition
                disableBackdropClick={loading}
                disableEscapeKeyDown={loading}

            >
                <Paper style={{outline: 'none'}}>
                    <Box width="100%" textAlign="right">
                        <IconButton disabled={loading} aria-label="delete" size="small" onClick={() => setOpen(false)}>
                            <HighlightOffIcon fontSize="small" color="disabled"/>
                        </IconButton>
                    </Box>
                    <Divider light/>
                    <Box p="20px" paddingTop={0} width="auto">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab icon={<CommentIcon />} label="SUGGEST IMPROVEMENT" {...a11yProps(0)} />
                            <Tab icon={<BugIcon />} label="REPORT BUG" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0} >
                            <Box paddingBottom={3}>
                                <Typography>Please leave your feedback below:</Typography>
                            </Box>
                            <Box paddingBottom={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputBase
                                        className={classes.taskInputText}
                                        id="outlined-adornment-post-feed"
                                        type="text"
                                        name="problem"
                                        placeholder="Type in text"
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        value={improvement}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImprovement(e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained" color="primary"
                                    onClick={() => sendImprovement()}
                                    disabled={loading}
                                    >
                                    Submit
                                </Button>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1} >
                            <Box paddingBottom={3}>
                                <Typography>Please enter the URL of the reported page:</Typography>
                            </Box>
                            <Box paddingBottom={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputBase
                                        className={classes.taskInputText}
                                        id="outlined-adornment-post-feed"
                                        type="text"
                                        name="feedbackUrl"
                                        placeholder="Paste URL here"
                                        value={url}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box paddingBottom={3}>
                                <Typography>Please describe the problem with the page:</Typography>
                            </Box>
                            <Box paddingBottom={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputBase
                                        className={classes.taskInputText}
                                        id="outlined-adornment-post-feed"
                                        type="text"
                                        name="problem"
                                        placeholder="Type in text"
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        value={problem}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProblem(e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained" color="primary"
                                    onClick={() => onSendFeedback()}
                                    disabled={loading}
                                    >
                                    Submit
                                </Button>
                            </Box>
                        </TabPanel>
                    </Box>
                </Paper>
            </Modal>
        </React.Fragment>
    );
};

export default Feedback;
