
import { http } from 'services/data';
import { 
    ComunityFeedsResponse, 
    CommunityFeedsFilter,  
    CommunityFeedsPayload,
    CommunityFeedsLikesResponse,
    ComunityFeedsResponseSingle,
    ComunityFeedsResponseComments,
    PostingResponse,
    PropmtResponse
} from 'interfaces/comunityFeeds';
import { logPoint } from '../users';
import auth from '../auth';


const endPoints = {
    posting: 'comfeed/posting',
    getData: 'comfeed/getData',
    getDataLogin: 'comfeed/getData/login',
    getDataByFilter: 'comfeed/getDataByFilter',
    getDataSinglePost: 'comfeed/getDataSinglePost',
    reportPost: 'comfeed/reportPost',
    topPost: 'comfeed/topPost',
    hidePost: 'comfeed/hidePost',
    pinPost: 'comfeed/pinPost',
    editPost: 'comfeed/posting/edit',
    deletePost: 'comfeed/posting/delete',
    postComment: 'comfeed/postComment',
    postCommentDell: 'comfeed/postCommentDell',
    editComment: 'comfeed/editComment',
    reportComment: 'comfeed/reportComment',
    reportCommentSub: 'comfeed/reportComment/sub',
    postLikes: 'comfeed/postLikes',
    postLikesDell: 'comfeed/postLikesDell',
    propmt: 'comfeed/prom/comfeed',
};

export const posting = async (payload:any): Promise<PostingResponse> => new Promise((resolve, reject) => {
    http.post(endPoints.posting, payload).then( async (res) => {
        try {
            await logPoint({
                userId: auth.userProperties.userData._id,
                points: 1,
                activity: `${auth.userProperties.userData.displayName} posted to the community page`,
                userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
            resolve(res)
        } catch (err) {
            reject(err)
        }
    }).catch((err) => reject(err))
})
export const getData = async (payload: CommunityFeedsFilter): Promise<ComunityFeedsResponse> => await http.get(endPoints.getData, {params: payload});
export const getDataLogin = async (payload: CommunityFeedsFilter): Promise<ComunityFeedsResponse> => await http.get(endPoints.getDataLogin, {params: payload});
export const getDataByFilter = async (payload: CommunityFeedsFilter): Promise<ComunityFeedsResponse> => await http.get(endPoints.getDataByFilter, {params: payload});
export const topPost = async (): Promise<ComunityFeedsResponse> => await http.get(endPoints.topPost);
export const getDataSinglePost = async (payload: {idPost:string}): Promise<ComunityFeedsResponseSingle> => await http.get(endPoints.getDataSinglePost, {params: payload});
export const reportPost = async (payload: CommunityFeedsPayload): Promise<ComunityFeedsResponseSingle> => await http.post(endPoints.reportPost, payload);
export const hidePost = async (payload: CommunityFeedsPayload): Promise<ComunityFeedsResponseSingle> => await http.post(endPoints.hidePost, payload);
export const pinPost = async (payload: CommunityFeedsPayload): Promise<ComunityFeedsResponseSingle> => await http.post(endPoints.pinPost, payload);
export const postComment = async (payload: CommunityFeedsPayload): Promise<ComunityFeedsResponseComments> => await http.post(endPoints.postComment, payload);
export const postCommentDell = async (payload: CommunityFeedsPayload): Promise<ComunityFeedsResponseComments> => await http.post(endPoints.postCommentDell, payload);
export const editComment = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.editComment, payload);
export const reportComment = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.reportComment, payload);
export const reportCommentSub = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.reportCommentSub, payload);
export const postLikes = async (payload: CommunityFeedsPayload): Promise<CommunityFeedsLikesResponse> => await http.post(endPoints.postLikes, payload);
export const postLikesDell = async (payload: CommunityFeedsPayload): Promise<CommunityFeedsLikesResponse> => await http.post(endPoints.postLikesDell, payload);
export const commentLikes = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postLikes+'/comment', payload);
export const commentLikesDell = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postLikesDell+'/comment', payload);
export const commentSubLikes = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postLikes+'/comment/sub', payload);
export const commentSubLikesDell = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postLikesDell+'/comment/sub', payload);
export const postCommentSub = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postComment+'/sub', payload);
export const postCommentDellSub = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.postCommentDell+'/sub', payload);
export const editCommentSub = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.editComment+'/sub', payload);
export const editPost = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.editPost, payload);
export const deletePost = async (payload: CommunityFeedsPayload): Promise<any> => await http.post(endPoints.deletePost, payload);
export const getPrompt = async (): Promise<PropmtResponse> => await http.get(endPoints.propmt);
export const postPrompt = async (data: string): Promise<PropmtResponse> => await http.post(endPoints.propmt, { message: data });
