import React, { FC, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useLocation, useParams, useHistory } from "react-router-dom";
import { Paper, Box, Typography, Button } from "@material-ui/core";
import AddIcon from "components/icons/AddIcon";
import { Column } from "react-table";

import MTable from "components/MTable";
// import DateCell from 'components/MTable/Cells/DateCell'
// import EllipsisCell from 'components/MTable/Cells/EllipsisCell';
import PromocodeAdd from "./PromocodeAdd";
import PromocodeEdit from "./PromocodeEdit";
import { ActionCell, PromotionTitleCell, RateCell, AvailableRedemptionCell, DurationCellStart, DurationCellExpire, UsageLimitCell } from "./components/cells";
import { useStyles } from "./styles";
// import { getBookingAll, getBookingByEmail, getBookingPeriod } from 'services/domain/admin/bookings';
import { getPromoCodes, updatePromoCodeStatus } from "services/domain/admin/promocodes";
// import { startCaseToUpper } from 'services/util/common';
import { Creators } from "services/domain/admin/reducer";
// import { Autocomplete } from '@material-ui/lab';
// import { SearchIcon } from 'components/icons';
// import { getUserByFirstNameBooking } from 'services/domain/users/users'
// import DateRangePickerInput from 'components/controls/DateRangePicker';
// import { DateRange } from 'materialui-daterange-picker';
// import { getThisWeekRange } from 'helper/Date';
// import moment from 'moment'
import SwitchIos from "components/_newDesign/switch/SwitchIos";
import { Toast } from "services/util/toast";

type PromoCode = {
  _id: string;
  code: string;
  title: string;
  description: string;
  startDate: Date;
  expiryDate: Date;
  redeems: number;
  redeemLimit: number;
  type: string;
  isActive: boolean;
  rate: number;
};

// const PopperMy = (props: any) => {
//     const classes = useStyles();
//     return (<Popper {...props} className={classes.popperStyle} placement='bottom-start' />)
// }

// const CssTextField = withStyles({
//     root: {
//         background: '#F8F8F8',
//         boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.15), inset 0px 1px 2px rgba(65, 65, 65, 0.2)',
//         borderRadius: '7px',
//         paddingRight: '20px',
//         '&:focus': {
//             backgroundColor: 'white',
//             boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
//         },
//       '& label.Mui-focused': {
//         color: 'transparent',
//       },
//       '& .MuiInput-underline:after': {
//         borderBottomColor: 'transparent',
//       },
//       '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//             borderColor: 'transparent',

//         },
//         '&:hover fieldset': {
//           borderColor: 'transparent',
//         },
//         '&.Mui-focused fieldset': {
//           borderColor: 'transparent',
//         },
//       },
//     },
// })(TextField);

// const FocusStyle = {
//     backgroundColor: '#ffffff',
//     boxShadow: '0px 6.25px 20px rgba(0, 0, 0, 0.125)',
// }

const ActiveCell = ({ updateStatus, row }: { updateStatus: (_id: string, visibility: boolean) => void; row: any }) => (
  <Box width={"85px"} display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"} textAlign="center" margin="auto">
    <SwitchIos
      color="primary"
      disableRipple={false}
      onClick={() => updateStatus(row?.original._id, !row?.original.isActive)}
      checked={row?.original?.isActive}
      style={{ color: row?.original?.isActive ? "#ffffff" : "#afafaf" }}
    />
    {row?.original?.isActive ? "Active" : "Inactive"}
  </Box>
);

const Promocode: FC<{}> = () => {
  const [promocodes, setPromoCode] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const classes = useStyles();
  const location = useLocation();
  const { action } = useParams<{ action: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [searchValue, setSearchValue] = useState<any>();
  // const [open, setOpen] = React.useState(false);
  // const [loadingSearch, setLoadingSearch] = React.useState(false);
  // const [options, setOptions] = React.useState<any[]>([]);
  const [pageIndexMem, setPageIndexMem] = useState<any>(0);
  const [pageSizeMem, setPageSizeMem] = useState<any>(0);
  const [sortByMem, setSortByMem] = useState<any>("asc");
  // const [refresh, setRefresh] = useState<boolean>(false);
  // const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>(getThisWeekRange());
  const [loading, setLoading] = useState<boolean>(false);
  const tableComp = React.useRef<any>(null);

  const fetchBookings = useCallback(async ({ pageIndex, pageSize, sortBy }: { pageIndex: any; pageSize: any; sortBy: any }) => {
    try {
      setLoading(true);
      let result = await getPromoCodes({ existData: pageIndex * pageSize, limitData: pageSize });
      // if (startDate !== undefined && startDate !== undefined){
      //     result = await getBookingPeriod({ existData: pageIndex * pageSize, limitData: pageSize, startDate: startDate, endDate: endDate });
      // } else {
      //     result = await getBookingAll({ existData: pageIndex * pageSize, limitData: pageSize });
      // }
      result.data.map((elm) => {
        if (elm.startDate) elm.startDate = elm.startDate.slice(0, 10);
        if (elm.endDate) elm.endDate = elm.endDate.slice(0, 10);
        return elm;
      });
      setPromoCode(result.data);
      setPageCount(result.meta.pagination.totalPage);
      setPageIndexMem(pageIndex);
      setPageSizeMem(pageSize);
      setSortByMem(sortBy);
    } catch (e) {
      console.error(e);
      /**
       * TODO: error handle here
       */
    } finally {
      setLoading(false);
    }
  }, []);

  const columns: Column<PromoCode>[] = useMemo(
    () => [
      {
        Header: "Promotion Title",
        accessor: "code",
        Cell: PromotionTitleCell,
      },
      {
        Header: "Usage Limit",
        accessor: "redeemLimit",
        Cell: UsageLimitCell,
      },
      {
        Header: "Available Redemption",
        accessor: "redeems",
        Cell: AvailableRedemptionCell,
      },
      {
        Header: "Redeemable Promo",
        accessor: "rate",
        Cell: RateCell,
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: DurationCellStart,
      },
      {
        Header: "Expiration Date",
        accessor: "expiryDate",
        Cell: DurationCellExpire,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: (props: any) => <ActiveCell {...props} updateStatus={handleActivateAction} />,
      },
      {
        Header: "ACTION",
        Cell: (props: any) => <ActionCell actionEdit={handleEditAction} {...props} />,
      },
      // eslint-disable-next-line
    ],
    [],
  );

  const handleEditAction = useCallback((rowData) => {
    dispatch(Creators.updateFormState(rowData.original));
    history.push(`${location.pathname}/edit/${rowData.original._id}`);
    // eslint-disable-next-line
  }, []);

  const handleActivateAction = useCallback(async (codeId, isActive) => {
    const actionDone = isActive ? "Activate" : "Deactivate";
    const toast = Toast({});

    try {
      await updatePromoCodeStatus({ codeId, isActive });
      toast.fire({
        icon: "success",
        title: `${actionDone} status succeed`,
      });
      fetchBookings({ pageIndex: pageIndexMem, pageSize: pageSizeMem, sortBy: sortByMem });
      // fetchBookings({pageIndex: pageIndexMem, pageSize: 50, sortBy: sortByMem})
    } catch (e) {
      toast.fire({
        icon: "error",
        title: `${actionDone} status failed`,
      });
    }
    // eslint-disable-next-line
  }, []);

  if (action === "add") {
    return <PromocodeAdd />;
  }

  if (action === "edit") {
    return <PromocodeEdit />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Promotion Code</Typography>

        <Button color="default" component={RouterLink} to={`${location.pathname}/add`}>
          Create Promotion Code
          <AddIcon className={classes.addIcon} />
        </Button>
      </Box>

      <Box mt={3}>
        <Paper>
          <MTable
            columns={columns}
            data={promocodes}
            onFetchData={fetchBookings}
            pageCount={pageCount}
            // refreshPage={refresh}
            loading={loading}
            ref={tableComp}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Promocode;
