import { Toast } from "../toast";
import * as XLSX from "xlsx";
import convertDataIntoObject from "./convertDataIntoObject";
import convertDataIntoWorksheetData from "./convertDataIntoWorksheetData";

/**
 * @description Generates a Excel file and formats specifically for journal entries.
 *
 * @param row the row data for this journal entry we are creating an excel file for
 */

export default (row: any) => {
  try {
    const headers = ["GL CODE", "ACCOUNT NAME", "AMOUNT", "DATE", "REF", "MEMO"];

    const convertToNumber = (str: string) => {
      const numString = str.replace(/^\D/g, "");

      return parseFloat(numString);
    };

    const rows = [
      ["2002-20", "Checking Account", convertToNumber(row.checkingAccountDailyChange), row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
      ["7012-00", "Movara Home Expenses", convertToNumber(row.movaraHomeExpensesDailyChange), row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
      [
        "3500-15",
        "Movara Home Unearned Revenue",
        convertToNumber(row.movaraHomeUnearnedRevenueDailyChange),
        row.dateCreated,
        row.dateCreated,
        "Movara Home Daily Journal Entry",
      ],
      ["6030-99", "Movara Home Revenue", convertToNumber(row.movaraHomeRevenueDailyChange), row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
      ["6030-98", "Movara Home Refunds", convertToNumber(row.movaraHomeRefundsDailyChange), row.dateCreated, row.dateCreated, "Movara Home Daily Journal Entry"],
    ];

    // filter out rows where the amount is 0
    // eslint-disable-next-line
    const filteredOutEmptyAmountRows = rows.filter((row: any[]) => {
      const amountIndex = 2;

      if (row[amountIndex] !== 0) return row;
    });

    if (!filteredOutEmptyAmountRows.length) {
      // if there are no amounts greater than 0
      const errorToast = Toast({});
      errorToast.fire({
        icon: "warning",
        title: "There are no amounts higher than 0 for this file",
        text: "Please choose another Journal Entry",
      });
      return;
    }

    const formattedData = convertDataIntoObject(headers, filteredOutEmptyAmountRows);

    const worksheetData = convertDataIntoWorksheetData(formattedData);

    const sheetName: string = "Daily Journal Entries";

    const workbook = XLSX.utils.book_new();

    workbook.SheetNames = [sheetName];
    workbook.Sheets = {
      [sheetName]: worksheetData,
    };

    XLSX.writeFile(workbook, "DailyJournalEntries.xlsx", { compression: true, cellStyles: true });
  } catch (error) {
    console.error("error", error);

    const errorToast = Toast({});
    errorToast.fire({
      icon: "error",
      title: "Failed to generate Excel document",
      text: "Please refresh the page and try again.",
    });
  }
};
