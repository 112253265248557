import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SuccessCircleCheckIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 20 21"
            {...props}
            style={{
                color: 'g',
                width: props.width || '20px',
                height: props.height || '21px',
                stroke: 'none',
            }}
        >
            <path d="M 10 0 C 4.484375 0 0 4.484375 0 10 C 0 15.515625 4.484375 20 10 20 C 15.515625 20 20 15.515625 20 10 C 20 4.484375 15.515625 0 10 0 Z M 10 18.359375 C 5.390625 18.359375 1.640625 14.609375 1.640625 10 C 1.640625 5.390625 5.390625 1.640625 10 1.640625 C 14.609375 1.640625 18.359375 5.390625 18.359375 10 C 18.359375 14.609375 14.609375 18.359375 10 18.359375 Z M 10 18.359375 "/>
            <path d="M 14.324219 6.160156 L 8.386719 12.097656 L 5.675781 9.386719 C 5.355469 9.066406 4.835938 9.066406 4.515625 9.386719 C 4.195312 9.703125 4.195312 10.222656 4.515625 10.542969 L 7.808594 13.839844 C 7.96875 14 8.179688 14.078125 8.386719 14.078125 C 8.597656 14.078125 8.808594 14 8.96875 13.839844 L 15.484375 7.320312 C 15.804688 7 15.804688 6.480469 15.484375 6.160156 C 15.164062 5.84375 14.644531 5.84375 14.324219 6.160156 Z M 14.324219 6.160156 "/>
        </SvgIcon>
    );
}
