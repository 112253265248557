import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    content: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    button: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        textTransform: 'none',
        boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.5), 1px 1px 6px rgba(0, 0, 0, 0.25), inset 1px 1px 3px rgba(255, 218, 165, 0.6)',
        borderRadius: '10px',
    },
    loadingPost: {
        width: '100% !important'
    },
}));