import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function GymIcon(props: SvgIconProps & Status) {
    const { isActive } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();
    return (
        <SvgIcon {...props} className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active}`}>
            <path
                d="M10.599 6.48416C11.4569 7.27205 12.3919 7.97881 13.3922 8.59158L16 5.98386C15.3872 4.98358 14.6808 4.0486 13.8925 3.1908M10.599 6.48416C10.411 6.31135 10.2267 6.13491 10.0459 5.95418C9.8652 5.77345 9.68842 5.58876 9.5156 5.40078M10.599 6.48416L6.48473 10.5989M9.5156 5.40078C8.72702 4.54264 8.02057 3.60766 7.40811 2.60771L10.0159 0C11.0159 0.612435 11.9509 1.31919 12.8091 2.10741M9.5156 5.40078L5.4013 9.51552M12.8091 2.10741C12.9971 2.28023 13.1818 2.457 13.3625 2.63773C13.5432 2.81846 13.7197 3.00281 13.8925 3.1908M12.8091 2.10741L13.3548 1.5623C13.5985 1.31858 13.9933 1.31858 14.2367 1.56197L14.4382 1.76348C14.6819 2.0072 14.6819 2.40197 14.4382 2.64569L13.8925 3.1908M3.19125 13.8923C4.04908 14.6802 4.9841 15.3869 5.98408 16L8.59222 13.392C7.97943 12.3917 7.27298 11.4567 6.48473 10.5989M3.19125 13.8923C3.00325 13.7195 2.81889 13.543 2.63815 13.3623C2.45742 13.1816 2.28064 12.9969 2.10782 12.8089C1.31924 11.9507 0.612788 11.0158 0 10.0162L2.60814 7.4081C3.60813 8.02054 4.54314 8.7273 5.4013 9.51552M3.19125 13.8923L2.6457 14.4379C2.4023 14.6813 2.00751 14.6813 1.76411 14.4379L1.5626 14.2364C1.3192 13.993 1.3192 13.5983 1.5626 13.3549L2.10844 12.8091C2.28126 12.997 2.45804 13.1817 2.63877 13.3625C2.81918 13.5429 3.00325 13.7195 3.19125 13.8923ZM5.4013 9.51552C5.5893 9.68833 5.77399 9.8651 5.95473 10.0458C6.13546 10.2266 6.31191 10.4109 6.48473 10.5989"
                strokeWidth="0.5" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
