import React from 'react';
import Duration from 'components/_newDesign/status/StatusDuration'
import { Box } from '@material-ui/core';

const DurationCell = ({ value, row }: { value: any, row: any }) => {
    return (
        <Box width={'120px'} display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'} textAlign="center" margin="auto">
            <Duration status={row.original.statusBooking || ''} startDate={row.original.startDate || new Date()} endDate={row.original.endDate || new Date()} totalWeeks={row.original.totalWeeks || 0} weekOngoing={row.original.weekOngoing || 0} />
        </Box>
    );
};

export default DurationCell;
