import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Bookings = lazy(() => import('./Bookings'));

export default {
    path: routeConstants.BOOKINGS.path,
    component: Bookings,
    layout: LayoutOptions.dashboard,
};
