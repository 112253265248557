import React, { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import TypographyMUI from "components/_newDesign/typography/Typography";
import { Box } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px -5px;
  justify-content: center;
`;

const WeekItemWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const WeekItem = styled.div`
  min-width: 64px;
  padding: 10px 14px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  ${(props: { isActive: boolean }) => {
    if (props.isActive) {
      return css`
        background-color: #ffa21e;
        color: white;
      `;
    }
  }}
`;

interface Props {
  startDate: Date;
  endDate: Date;
  showRecurringOption: boolean;
  recurring: boolean;
  onItemClick: (weekNumber: number, recurring: boolean) => void;
  hasAddWeeksQuery?: boolean;
}
const weeks = [
  {
    text: "1 W",
    index: 1,
  },
  {
    text: "2 W",
    index: 2,
  },
  {
    text: "3 W",
    index: 3,
  },
  {
    text: "4 W",
    index: 4,
  },
  {
    text: "8 W",
    index: 8,
  },
  {
    text: "12 W",
    index: 12,
  },
  {
    text: "24 W",
    index: 24,
  },
  {
    text: "48 W",
    index: 48,
  },
];

const recurringWeeks = [
  {
    text: "4W Recurring",
    index: 4,
  },
];

const TotalWeekIndicator: FC<Props> = ({ startDate, endDate, showRecurringOption, recurring, onItemClick, hasAddWeeksQuery = false }) => {
  const [customWeek, setCustomWeek] = React.useState(0);
  const [showCustomWeek, setShowCustomWeek] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number>();

  useEffect(() => {
    if (!startDate || !endDate) return;

    const diffRange = Math.round(moment(endDate).diff(startDate, "days") / 7);
    // const diffRange = 4;
    const findWeek = weeks.find((item) => item.index === diffRange);
    setCustomWeek(diffRange);
    if (diffRange > 0 && findWeek === undefined) {
      setShowCustomWeek(true);
    } else {
      setShowCustomWeek(false);
      findWeek && setActiveIndex(findWeek.index);
    }
  }, [startDate, endDate]);

  return (
    <>
      <Container>
        {weeks.map((week, index) => (
          <WeekItemWrapper key={week.text} onClick={() => onItemClick(week.index, false)}>
            <WeekItem isActive={!recurring && week.index === activeIndex /* Math.round(moment(endDate).diff(startDate, 'days')/7) */}>{week.text}</WeekItem>
          </WeekItemWrapper>
        ))}

        {showCustomWeek && (
          <WeekItemWrapper>
            <WeekItem isActive={true}>{customWeek} W</WeekItem>
          </WeekItemWrapper>
        )}
      </Container>
      {showRecurringOption && (
        <Container>
          <Box display={"flex"} mt={"10px"} ml={1} width={"100%"}>
            <TypographyMUI style={{ color: "#9A9A9A" }} variant="mov_body2">
              Recurring Subscription
            </TypographyMUI>
          </Box>
          <Box display={"flex"} ml={1} width={"100%"}>
            {recurringWeeks.map((week, index) => (
              <WeekItemWrapper key={week.text} onClick={() => onItemClick(week.index, true)}>
                <WeekItem isActive={recurring && week.index === activeIndex /* Math.round(moment(endDate).diff(startDate, 'days')/7) */}>{week.text}</WeekItem>
              </WeekItemWrapper>
            ))}
          </Box>
        </Container>
      )}
    </>
  );
};

export default TotalWeekIndicator;
