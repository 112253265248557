import React, { FC } from 'react';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import { useStyles } from '../styles'
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

interface Props {
    data: {
        text: string,
        achieved: boolean
        startFasting: string;
        endFasting: string;
        fastedDuration: number;
    }
}
const Intermittan: FC<Props> = (props) => {
    const classes = useStyles();
    const startTime = moment(props.data.startFasting);
    const endTime = moment(props.data.endFasting);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asMinutes()/60);
    const minutes = duration.asMinutes()%60;
    return (
        <Box>
            <Box>
                <FormControlLabel
                    aria-label="Acknowledge"
                    checked={true}
                    control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                    label={
                        <Box display={'flex'} alignItems="center">
                            <SpoonForkIcon />
                            <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                I intermittent fasted today
                            </Typography>
                        </Box>
                    }
                />
            </Box>
            <Box>
                <Box width={'100%'}>
                    <Box className={classes.itemDetailNoBGBox} paddingRight="0px !important" marginTop="32px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                START FASTING
                            </Typography>
                            <DateTimePicker
                                minutesStep={5}
                                margin="normal"
                                id="time-picker"
                                value={props.data.startFasting}
                                onChange={() => {}}
                                disabled={true}
                                format="ddd, hh:mm a"
                                InputProps={{ className: classes.inputDateTimeMov, disableUnderline: true }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                            marginTop={'13px'}
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                END FASTING
                            </Typography>
                            <DateTimePicker
                                minutesStep={5}
                                margin="normal"
                                id="time-picker"
                                value={props.data.endFasting}
                                onChange={() => {}}
                                disabled={true}
                                format="ddd, hh:mm a"
                                InputProps={{ className: classes.inputDateTimeMov, disableUnderline: true }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                            marginTop={'13px'}
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                FASTED {hours} HOUR {minutes !== 0 ? minutes + ' MINUTES' : ''}
                            </Typography>
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                multiline
                                name="text"
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                value={props.data.text}
                                disabled={true}
                                endAdornment={<Typography variant='caption'>{`${props.data.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Intermittan;
