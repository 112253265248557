import React from "react";
import moment from "moment";
import { Paper, Box, InputLabel, Button, Grid, Typography, Avatar, CircularProgress, TextField as TextFieldMui, IconButton, MenuItem, Select } from "@material-ui/core";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";

import { Autocomplete as AutocompleteMui } from "@material-ui/lab";
import { useStyles } from "./styles";
import { phoneRegExp } from "services/util/regex";
import history from "services/data/history";
import { searchUserLikeEmail } from "services/domain/users/users";
import { saveBooking } from "services/domain/admin/bookings";
import dummyAvatar from "assets/images/dummy-avatar-256.png";
import { Toast } from "services/util/toast";
import { ArrowBack, CreditCard } from "@material-ui/icons";
import { UserSearchProfile } from "interfaces/user";
import StatusBooking from "components/_newDesign/status/StatusBooking";
import BookingController from "components/_modules/BookingSuperUser/BookingInfo/BookingController";
import CustomText from "components/_modules/BookingSuperUser/CustomInputs/CustomText";

type DateRange = {
  startDate: Date;
  endDate: Date;
};

interface FormikState {
  email: string;
  phoneNumber: string;
  address: string;
  firstName: string;
  lastName: string;
}

interface BookingDetails {
  startDate: Date;
  endDate: Date;
  coach_id: string;
  coach_name: string;
  totalWeeks: number;
}

const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  address: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
});

const initialValues = {
  email: "",
  phoneNumber: "",
  address: "",
  firstName: "",
  lastName: "",
};

const l_startDate = moment()
  .add(7 - moment().isoWeekday(), "days")
  .toDate();
const l_endDate = moment(l_startDate).add(1, "week").toDate();
const initialBookingDetails: BookingDetails = {
  startDate: l_startDate,
  endDate: l_endDate,
  coach_id: "",
  coach_name: "",
  totalWeeks: 1,
};

const BookingAddNew = () => {
  const classes = useStyles();
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = React.useState<any>();
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [userProfile, setUserProfile] = React.useState<UserSearchProfile>();
  // const [dateRange, setDateRange] = React.useState<DateRange>({startDate: new Date(), endDate: new Date()});
  const [bookingDetails, setBookingDetails] = React.useState<BookingDetails>(initialBookingDetails);
  const [paymentStatus, setPaymentStatus] = React.useState<string>("default");

  const goBack = () => {
    history.goBack();
  };

  const handleEmailChange = (user: any, setFieldValue: any) => {
    setFieldValue("email", user?.email ?? "");
    setFieldValue("phoneNumber", user?.phoneNumber ?? "");
    setFieldValue("address", user?.address ?? "");
    setFieldValue("firstName", user?.name?.firstName ?? "");
    setFieldValue("lastName", user?.name?.lastName ?? "");
    setUserProfile(user);
  };

  const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
    setSubmitting(true);
    try {
      const { firstName, lastName, ...payload } = values;
      const toast = Toast({ onClose: goBack });
      if (!paymentStatus || paymentStatus === "default") {
        throw new Error("No payment status!");
      }
      if (!bookingDetails) {
        throw new Error("No Booking Details!");
      }
      const l_start = moment(bookingDetails.startDate).format("YYYY-MM-DD");
      const l_end = moment(bookingDetails.endDate).format("YYYY-MM-DD");
      const l_totalWeeks = moment(l_end).diff(l_start, "weeks");
      const bookingPayload = {
        bookId: "",
        bookingData: [
          {
            startDate: moment(bookingDetails.startDate).utc(true),
            endDate: moment(bookingDetails.endDate).utc(true),
            totalWeeks: l_totalWeeks,
            email: payload.email,
            firstName,
            lastName,
            phoneNumber: payload.phoneNumber,
            address: payload.address,
            paymentStatus,
            coach_id: bookingDetails.coach_id,
            coach_name: bookingDetails.coach_name,
          },
        ],
      };

      await saveBooking(bookingPayload);

      toast.fire({
        icon: "success",
        title: "New booking has been added",
      });
    } catch (e) {
      const toast = Toast({});

      toast.fire({
        icon: "error",
        title: "Create new booking failed",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const inputChange = async (event: any, value: any) => {
    try {
      setLoadingSearch(true);
      const rspn = await searchUserLikeEmail(value);
      setOptions(rspn.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <>
      <Box
        maxWidth="100%"
        height="80px"
        style={{
          background: "rgba(0, 0, 0, 0.05)",
          backgroundBlendMode: "multiply",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Grid container direction="row" alignItems="center" style={{ height: "100%", justifyContent: "flex-start" }}>
          <Box display="flex" justifyContent="center" alignItems={"center"} width="40px" height="40px" padding={"10px"}>
            <IconButton onClick={() => goBack()}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box display="flex" ml={1} justifyContent="center" textAlign={"left"}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "150%",
                color: "#414141",
              }}
            >
              Add New Booking
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Paper className={classes.formContainer}>
        <Box maxWidth="756px" marginLeft="auto" marginRight="auto">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, values, setFieldValue, errors, getFieldMeta }) => {
              return (
                <Form>
                  <AutocompleteMui
                    value={searchValue}
                    id="autocomplete-search-booking"
                    // open={true}
                    onOpen={() => {
                      inputChange(null, null);
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onChange={(e, obj) => {
                      handleEmailChange(obj, setFieldValue);
                    }}
                    onInputChange={inputChange}
                    getOptionSelected={(option, value) => option.email === value.email}
                    getOptionLabel={(option) => option.email}
                    options={options}
                    loading={loadingSearch}
                    // PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <TextFieldMui
                        {...params}
                        label={"SEARCH BY EMAIL"}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSearch ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Box className={classes.userId} mb={"24px"}>
                    <InputLabel>ID</InputLabel>
                    <Typography>{}</Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} mb={"42px"}>
                    <Box mx={1}>
                      <Avatar src={userProfile?.avatar ?? dummyAvatar} />
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "150%",
                          marginLeft: "8px",
                          paddingBottom: "5px",
                        }}
                      >
                        {userProfile?.name ? `${userProfile?.name.firstName} ${userProfile?.name.lastName}` : ""}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "150%",
                          color: "#9A9A9A",
                          marginLeft: "8px",
                          paddingBottom: "5px",
                        }}
                      >
                        {userProfile?.email || ""}
                      </Typography>
                      <Box>
                        <StatusBooking status={userProfile?.statusBooking || ""} />
                      </Box>
                    </Box>
                  </Box>

                  <Box my={3} width="100%" height="516px" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px">
                    <Box marginX={"20px"}>
                      <Box paddingTop={"20px"}>
                        <Typography
                          style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "150%",
                          }}
                        >
                          Personal Info
                        </Typography>
                      </Box>
                      <Box width={"400px"}>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="First Name (*)" name="firstName" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Last Name (*)" name="lastName" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Email Address (*)" name="email" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Phone Number (*)" name="phoneNumber" margin="normal" fullWidth />
                        </Box>
                        <Box paddingTop={"16px"} paddingBottom={"6px"}>
                          <Field as={CustomText} type="text" label="Address (*)" name="address" margin="normal" fullWidth />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box my={3} width="100%" style={{ backgroundColor: "#FAFAFA" }} borderRadius="20px">
                    <BookingController mode={"add"} booking={bookingDetails} onBookingInfoChange={(data) => setBookingDetails(data)} />
                    <Box width={"363px"} mt={"40px"} mx={"20px"}>
                      <Grid container direction="column">
                        <Grid item>
                          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <CreditCard />
                            <Typography
                              style={{
                                marginLeft: "10px",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontSize: "10px",
                                fontWeight: 400,
                                lineHeight: "120%",
                                letterSpacing: "1.5px",
                                color: "#414141",
                              }}
                            >
                              PAYMENT STATUS
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            mt={"10px"}
                            mb={"40px"}
                            width={"100%"}
                            height={"40px"}
                            borderRadius={"12px"}
                            display="flex"
                            alignItems={"center"}
                            style={{
                              backgroundColor: "#F2F2F2",
                            }}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={paymentStatus}
                              onChange={(event) => setPaymentStatus(event.target.value as string)}
                              style={{
                                width: "100%",
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <option hidden value="default">
                                Select payment method
                              </option>
                              <MenuItem value="comp">Comp</MenuItem>
                              <MenuItem value="movara_challenge">Movara Challenge</MenuItem>
                            </Select>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box my={"30px"} display={"flex"} alignItems={"center"} justifyContent="center">
                    <Button
                      variant="outlined"
                      onClick={goBack}
                      className={classes.buttonCancel}
                      disabled={isSubmitting}
                      style={{
                        width: "104px",
                        height: "48px",
                        borderRadius: "12px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "10%",
                          color: "#000000",
                          letterSpacing: "0.25px",
                        }}
                      >
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        width: "104px",
                        height: "48px",
                        borderRadius: "12px",
                        marginLeft: "20px",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontSize: "16px",
                          fontWeight: 600,
                          lineHeight: "10%",
                          color: "#000000",
                          letterSpacing: "0.25px",
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};

export default BookingAddNew;
