import React, { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';


const Loading: FC = () => {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={1}>
            <CircularProgress/>
        </Box>
    );
};
export default Loading;