import React from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress } from '@material-ui/core';

interface Props<T> {
    selector: string;
    onChange: (e: any, item: any) => void;
    fetcher: () => Promise<any>;
    label?: string
}

const Autocomplete = <T extends object>({ selector, onChange, fetcher, label }: Props<T>) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            try {
                const response = await fetcher();

                if (active) {
                    setData(response.data);
                    setOptions(response.data.map((item: any) => item[selector]));
                }
            } catch (e) {

            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setData([]);
            setOptions([]);
        }
    }, [open]);

    const changeHandler = (e: any, val: any) => {
        const foundItem = data.find(item => item[selector] === val);
        onChange(e, foundItem);
    }

    return (
        <MuiAutocomplete
            open={open}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            onChange={changeHandler}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} />: null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default Autocomplete;
