import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function VideoIcon(props: SvgIconProps) {

    return (
        <SvgIcon {...props}>
            <path d="M23.4844 1.82422H0.515625V22.175H23.4844V1.82422Z" stroke="#FFA21E" stroke-miterlimit="10" strokeLinejoin="round"/>
            <line x1="0.75" y1="4" x2="23.25" y2="4" stroke="#FFA21E"/>
            <path d="M9.6875 9.01915L15.5 12.375L9.6875 15.7308L9.6875 9.01915Z" stroke="#FFA21E"/>
            <path d="M3.47417 2.05105L2.52549 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M6.67302 2.05105L5.72434 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M9.67302 2.05105L8.72434 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M12.673 2.05105L11.7243 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M15.673 2.05105L14.7243 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M18.673 2.05105L17.7243 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
            <path d="M21.673 2.05105L20.7243 3.94842" stroke="#FFA21E" strokeLinecap="square" strokeLinejoin="bevel"/>
        </SvgIcon>
    );
};
