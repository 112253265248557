import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ImageIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 32 33" {...props} style={{color: 'white', width: props.width || '32px', height: props.height || '32px' }}>
            <path
                d="M25.3333 4.5H6.66667C5.19391 4.5 4 5.69391 4 7.16667V25.8333C4 27.3061 5.19391 28.5 6.66667 28.5H25.3333C26.8061 28.5 28 27.3061 28 25.8333V7.16667C28 5.69391 26.8061 4.5 25.3333 4.5Z"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3334 13.833C12.4379 13.833 13.3334 12.9376 13.3334 11.833C13.3334 10.7284 12.4379 9.83301 11.3334 9.83301C10.2288 9.83301 9.33337 10.7284 9.33337 11.833C9.33337 12.9376 10.2288 13.833 11.3334 13.833Z"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28 20.4997L21.3333 13.833L6.66663 28.4997"
                stroke={props.stroke || '#6D6D6D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
