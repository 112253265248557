import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function NightIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 25 25"
            {...props}
            style={{
                color: 'white',
                width: props.width || '25px',
                height: props.height || '25px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5811 3.13538C12.7746 3.47877 12.7484 3.9038 12.5141 4.22077C11.6598 5.37642 11.2488 6.80029 11.3557 8.23341C11.4625 9.66652 12.0802 11.0137 13.0964 12.0299C14.1126 13.046 15.4597 13.6637 16.8929 13.7706C18.326 13.8775 19.7498 13.4664 20.9055 12.6122C21.2225 12.3779 21.6475 12.3517 21.9909 12.5452C22.3343 12.7387 22.5319 13.1159 22.4957 13.5084C22.3209 15.3997 21.6111 17.2022 20.4493 18.7048C19.2875 20.2075 17.7218 21.3482 15.9353 21.9934C14.1489 22.6387 12.2156 22.7619 10.3618 22.3485C8.5079 21.9351 6.8101 21.0023 5.46702 19.6592C4.12394 18.3162 3.19115 16.6184 2.77778 14.7645C2.36441 12.9106 2.48757 10.9774 3.13284 9.19093C3.77811 7.4045 4.9188 5.83879 6.42145 4.67699C7.9241 3.5152 9.72654 2.80539 11.6179 2.6306C12.0104 2.59433 12.3876 2.792 12.5811 3.13538ZM9.81629 5.06542C9.03868 5.34677 8.3055 5.74838 7.64478 6.25923C6.44266 7.18866 5.5301 8.44124 5.01389 9.87038C4.49767 11.2995 4.39914 12.8461 4.72984 14.3292C5.06054 15.8123 5.80677 17.1706 6.88123 18.245C7.95569 19.3195 9.31394 20.0657 10.797 20.3964C12.2801 20.7271 13.8267 20.6286 15.2559 20.1124C16.685 19.5962 17.9376 18.6836 18.867 17.4815C19.3779 16.8208 19.7795 16.0876 20.0608 15.31C19.0077 15.691 17.8781 15.8496 16.7441 15.7651C14.8333 15.6226 13.0371 14.799 11.6822 13.4441C10.3273 12.0892 9.5037 10.293 9.3612 8.38214C9.27664 7.24817 9.43528 6.11854 9.81629 5.06542Z"
                fill="#6D6D6D"
            />
        </SvgIcon>
    );
}
