/**
 *   @description - convert array of objects data into journal entry worksheet data where it will format amount, date, and ref fields into number and date formats
 *  @param data - array of objects where keys are the headers and values are the cell values for that header - each array is a row
 *   @returns - object containing cell labels and cell values - example: A1: { v: "Amount" }
 */

import numberToCellLetter from "./numberToCellLetter";
import * as XLSX from "xlsx";

interface Data {
  [key: string]: string;
}

const xlsxFormatter = {
  type: "t",
  format: "z",
  value: "v",
  numberType: "n",
  numberFormat: "0.00",
  dateType: "d",
  dateFormat: "mm/dd/yyyy",
};

function convertDataIntoWorksheetData(data: Data[]): XLSX.WorkSheet {
  const worksheetData = {};

  const startingCell = "A1";
  let lastCell = "";

  let amountIndex = 0;
  let dateIndex = 0;
  let refIndex = 0;

  // add headers to worksheetData
  Object.keys(data[0]).forEach((header: string, i: number) => {
    // generate column letter - 0 = A, 1 = B, etc.
    const excelColumnLetter = numberToCellLetter(i);

    const headerColumnLetter = `${excelColumnLetter}1`;

    const lowercaseHeader = header.toLowerCase();

    if (lowercaseHeader === "amount") amountIndex = i;
    if (lowercaseHeader === "date") dateIndex = i;
    if (lowercaseHeader === "ref") refIndex = i;

    worksheetData[headerColumnLetter] = {
      v: header,
    };
  });

  // add rows to worksheetData
  data.forEach((row: { [key: string]: string }, rowIndex: number) => {
    const headers = Object.keys(row);

    headers.forEach((header: string, headerIndex: number) => {
      const cellValue = row[header];

      const columnLetter = numberToCellLetter(headerIndex);
      const cellLabel = `${columnLetter}${rowIndex + 2}`;

      const cellObject = {
        [xlsxFormatter.value]: cellValue,
      };

      // set cellObject to have number type and number formatter if we are on the amount header
      if (headerIndex === amountIndex) {
        cellObject[xlsxFormatter.type] = xlsxFormatter.numberType;
        cellObject[xlsxFormatter.format] = xlsxFormatter.numberFormat;
      }

      // set cellObject to have number type and number formatter if we are on the amount header
      if (headerIndex === dateIndex || headerIndex === refIndex) {
        cellObject[xlsxFormatter.type] = xlsxFormatter.dateType;
        cellObject[xlsxFormatter.format] = xlsxFormatter.dateFormat;
      }

      worksheetData[cellLabel] = cellObject;

      // if we are at the last headerIndex on the last rowIndex - set the cellLabel as the lastCellLabel
      if (rowIndex === data.length - 1 && headerIndex === headers.length - 1) lastCell = cellLabel;
    });
  });

  // add cellRanges to worksheetData
  worksheetData["!ref"] = `${startingCell}:${lastCell}`;

  console.log(worksheetData);
  return worksheetData;
}

export default convertDataIntoWorksheetData;
