import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import auth from 'services/domain/auth';

declare module 'axios' {
    export interface AxiosInstance {
        request<T = any>(config: AxiosRequestConfig): Promise<T>;
        get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
        delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
        head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
        post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
        put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
        patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    }
}

function parseError (messages: string | string[]) {
    // error
    if (messages) {
        if (messages instanceof Array) {
            return Promise.reject({ messages: messages });
        } else {
            return Promise.reject({ messages: [messages] });
        }
    } else {
        return Promise.reject({ messages: 'Something went wrong' });
    }
}

type response = {
    result: string[];
    messages: string;
};

interface ParseBodyParam {
    status: number;
    data: response;
};

function parseBody(response: AxiosResponse<any>) {
    //  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
    if (response.status === 200) {
        return response.data;
    } else {
        return parseError(response.data.messages);
    }
}

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    paramsSerializer: function(params) {
        return qs.stringify(params, { indices: false });
    },
});

instance.interceptors.request.use(
    config => {
        const token = auth.userProperties?.token;
        if (token) {
            config.headers.token = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        return parseBody(response);
    },
    error => {
        // return Promise.reject(error)
        if (error.response) {
            if (error.response.status === 401) auth.logout();
            return parseError(error.response.data);
        } else {
            return Promise.reject(error);
        }
    });


export const http = instance;
