import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function InfoIcon({ className, ...props }: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 22 22" className={clsx(classes.root, className ?? className)}>
            <path d="M10.9999 1.3875C5.69112 1.3875 1.3875 5.69112 1.3875 10.9999C1.3875 16.3087 5.69112 20.6123 10.9999 20.6123C16.3087 20.6123 20.6123 16.3087 20.6123 10.9999C20.6123 5.69112 16.3087 1.3875 10.9999 1.3875Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M11 13.6475L11 5.99953" stroke="#414141" strokeMiterlimit="10"/>
            <path d="M11 16.6248L11 15.3611" stroke="#414141" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
