import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ComunityIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 16 16"
            {...props}
            style={{
                color: 'transparent',
                width: props.width || '16px',
                height: props.height || '16px',
            }}
        >
            <path d="M4.90181 4.57257C3.99689 5.20965 3.30015 6.12255 2.93384 7.18904M12.9224 7.05643C12.5261 5.99599 11.8002 5.09635 10.8694 4.48218M10.5302 13.5498C10.3004 13.6779 10.0601 13.7893 9.81093 13.8826C9.23241 14.0991 8.60597 14.2175 7.95189 14.2175C7.2978 14.2175 6.67137 14.0991 6.09284 13.8826C5.84366 13.7893 5.60336 13.6779 5.37357 13.5498" stroke="#FFA11E" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.11622 8.47662C9.06019 8.42056 8.99367 8.3761 8.92045 8.34576C8.84723 8.31542 8.76875 8.2998 8.6895 8.2998C8.61024 8.2998 8.53176 8.31542 8.45854 8.34576C8.38532 8.3761 8.3188 8.42056 8.26277 8.47662L8.14649 8.5929L8.03021 8.47662C7.91703 8.36344 7.76354 8.29986 7.60348 8.29986C7.44343 8.29986 7.28993 8.36344 7.17676 8.47662C7.06358 8.58979 7 8.74329 7 8.90335C7 9.0634 7.06358 9.2169 7.17676 9.33007L7.29304 9.44635L8.14649 10.2998L8.99994 9.44635L9.11622 9.33007C9.17228 9.27404 9.21674 9.20752 9.24708 9.1343C9.27742 9.06108 9.29304 8.9826 9.29304 8.90335C9.29304 8.82409 9.27742 8.74561 9.24708 8.67239C9.21674 8.59917 9.17228 8.53265 9.11622 8.47662V8.47662Z" stroke="#6D6D6D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.55556 6.29989V5.85544C9.55556 5.61969 9.46191 5.3936 9.29521 5.2269C9.12851 5.0602 8.90242 4.96655 8.66667 4.96655H6.88889C6.65314 4.96655 6.42705 5.0602 6.26035 5.2269C6.09365 5.3936 6 5.61969 6 5.85544V6.29989" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.77781 4.07758C8.26873 4.07758 8.66669 3.67961 8.66669 3.18869C8.66669 2.69777 8.26873 2.2998 7.77781 2.2998C7.28689 2.2998 6.88892 2.69777 6.88892 3.18869C6.88892 3.67961 7.28689 4.07758 7.77781 4.07758Z" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.55556 12.2999V11.8554C4.55556 11.6197 4.46191 11.3936 4.29521 11.2269C4.12851 11.0602 3.90241 10.9666 3.66667 10.9666H1.88889C1.65314 10.9666 1.42705 11.0602 1.26035 11.2269C1.09365 11.3936 1 11.6197 1 11.8554V12.2999" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.7778 10.0776C3.26872 10.0776 3.66669 9.67961 3.66669 9.18869C3.66669 8.69777 3.26872 8.2998 2.7778 8.2998C2.28689 8.2998 1.88892 8.69777 1.88892 9.18869C1.88892 9.67961 2.28689 10.0776 2.7778 10.0776Z" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0556 12.2999V11.8554C15.0556 11.6197 14.9619 11.3936 14.7952 11.2269C14.6285 11.0602 14.4024 10.9666 14.1667 10.9666H12.3889C12.1531 10.9666 11.927 11.0602 11.7603 11.2269C11.5937 11.3936 11.5 11.6197 11.5 11.8554V12.2999" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.2778 10.0776C13.7687 10.0776 14.1667 9.67961 14.1667 9.18869C14.1667 8.69777 13.7687 8.2998 13.2778 8.2998C12.7869 8.2998 12.3889 8.69777 12.3889 9.18869C12.3889 9.67961 12.7869 10.0776 13.2778 10.0776Z" stroke="#6D6D6D" strokeLinecap="round" strokeLinejoin="round"/>

        </SvgIcon>
    );
}
