import React, { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon, MorningIcon, NightIcon, AfternoonIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import InputNumber from 'components/_newDesign/input/InputNumber';
import { useStyles } from './styles'
import ButtonMui from 'components/_newDesign/button/Button';
import { CaloriesAmountRequest } from 'interfaces/nutritionGoals';
import moment from 'moment';
import ErrorMessage from 'components/_newDesign/alert/ErrorMessage';
import { postCaloriesAmount } from 'services/domain/users/nutritionGoal'
import { useSnackbar } from 'notistack';
import { AvatarGroupComponent } from '../AvatarGroup/AvatarGroup';
import { UserSubmissionListItem } from 'interfaces/user';

interface Props {
    onChange?: (value: any) => void;
    active: boolean;
    preview?: boolean;
    amountCalories?: number;
    weekly?: boolean;
    defaultExpanded?: boolean;
    isDone?: boolean;
    submitSucces?: () => void;
    data?: {
        balanceBreakfast: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        balanceLunch: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        balanceDinner: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        achieved: boolean,
        text: string
      };
    userList?: Array<UserSubmissionListItem>;
    submissionDate?: string;
}
const defaultValue: CaloriesAmountRequest = {
    achieved: true,
    text: '',
    balanceBreakfastIsCheck: true,
    balanceBreakfastTotalCalories: undefined,
    balanceBreakfastText: '',
    balanceLunchIsCheck: true,
    balanceLunchTotalCalories: undefined,
    balanceLunchText: '',
    balanceDinnerIsCheck: true,
    balanceDinnerTotalCalories: undefined,
    balanceDinnerText: '',
    submissionDate: moment().utc(true).toString(),
}
interface BalanceCheck {
    breackfast: boolean,
    lunch: boolean,
    dinner: boolean
}
const AmountCalories: FC<Props> = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState<boolean>(props.preview ? true : false);
    const [expand, setExpand] = React.useState<boolean>(false)
    const [submit, setSubmit] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [balenceChecked, setBalnceCheck] = React.useState<BalanceCheck>({
        breackfast: false,
        lunch: false,
        dinner: false
    })
    const [form, setForm] = React.useState<CaloriesAmountRequest>(defaultValue)
    const { enqueueSnackbar } = useSnackbar();
    const onChange = (event) => {
        if (!props.isDone && !props.preview) {
            setChecked(event.target.checked);
            setExpand(event.target.checked)
        }
    }
    const onChangeExpand = (value: boolean) => {
        if (!props.isDone && !props.preview) {
            setChecked(value);
            setExpand(value)
        } else if (props.isDone) {
            setExpand(value)
        }
    }
    const onChangeBalancedChecked = (event) => {
        if (!props.isDone && !props.preview) {
            setBalnceCheck({...balenceChecked, [event.target.name]: event.target.checked})
        }
    }
    const formOnChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target
        let newValue: any = value
        if((name === 'balanceBreakfastTotalCalories' 
            || name === 'balanceLunchTotalCalories' 
            || name === 'balanceDinnerTotalCalories') && Number(value) > 9999) {
             const valueString = String(form[name]).substring(0, 4)
             newValue = Number(valueString)
             setForm({ ...form, [name]: newValue })
        } else if((name === 'balanceBreakfastText' 
            || name === 'balanceLunchText' 
            || name === 'text' 
            || name === 'balanceDinnerText') && form[name].length > 254) {
            newValue = form[name].substring(0, 254)
            setForm({ ...form, [name]: newValue })
        } else {
            setForm({ ...form, [name]: newValue })
        }
        
    }

    const onSubmit = async() => {
        setSubmit(true)
        try {
            if  (!form.balanceBreakfastTotalCalories && !form.balanceDinnerTotalCalories && !form.balanceLunchTotalCalories) return
            form.balanceBreakfastIsCheck = balenceChecked.breackfast
            form.balanceLunchIsCheck = balenceChecked.lunch
            form.balanceDinnerIsCheck = balenceChecked.dinner
            const payload: CaloriesAmountRequest = {
                ...form,
                submissionDate: props.submissionDate ? props.submissionDate : moment().utc(true).toString()
            }
            setLoading(true)
            await postCaloriesAmount(payload)
            enqueueSnackbar('Add Calories Amount Success', { variant: 'success' })
            if (props.submitSucces) props.submitSucces()
        } catch (error) {
            enqueueSnackbar('Add Calories Amount Failed', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (props.data) {
            const newForm = {
                achieved: true,
                text: props.data.text,
                balanceBreakfastIsCheck: props.data.balanceBreakfast.isCheck,
                balanceBreakfastTotalCalories: props.data.balanceBreakfast.totalCalories,
                balanceBreakfastText: props.data.balanceBreakfast.text,
                balanceLunchIsCheck: props.data.balanceLunch.isCheck,
                balanceLunchTotalCalories: props.data.balanceLunch.totalCalories,
                balanceLunchText: props.data.balanceLunch.text,
                balanceDinnerIsCheck: props.data.balanceDinner.isCheck,
                balanceDinnerTotalCalories: props.data.balanceDinner.totalCalories,
                balanceDinnerText: props.data.balanceDinner.text,
                submissionDate: moment().utc(true).toString(),
            }
            setForm(newForm)
            setBalnceCheck({
                breackfast: props.data.balanceBreakfast.isCheck,
                lunch: props.data.balanceLunch.isCheck,
                dinner: props.data.balanceDinner.isCheck
            })
            // if (props.data.achieved)
        }
    }, [props.data])
    React.useEffect(() => {
        if (props.isDone) setChecked(true)
    }, [props.isDone])
    React.useEffect(() => {
        if (props.preview) {
            setChecked(true)
            setBalnceCheck({
                breackfast: true,
                lunch: true,
                dinner: true
            })
        }
    }, [props.preview])
    return (
        <div className={`${props.weekly ? classes.rootWeekly : classes.root}`}>
            <Accordion
                defaultExpanded={props.defaultExpanded}
                classes={{ root: classes.accordionRoot }}
                expanded={props.preview || expand}
                onChange={(_e, expanded) => onChangeExpand(expanded)}
            >
                <AccordionSummary
                    expandIcon={undefined}
                    aria-controls="action-amount-calories-content"
                    id="action-amount-calories-header"
                    classes={{ content: classes.summaryContent }}
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        checked={checked}
                        onChange={onChange}
                        control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                        label={
                            <Box display={'flex'} alignItems="center">
                                <SpoonForkIcon />
                                <Typography variant="mov_body1" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    {' '}
                                    I stayed under {props.amountCalories || 1500} calories today{' '}
                                </Typography>
                            </Box>
                        }
                    />
                    <Box 
                        display={'flex'} 
                        alignItems="center" 
                        justifyContent={'space-between'}
                        padding="2.5px 20px"
                        borderRadius={'10px'}
                        style={{ background: 'rgba(0, 0, 0, 0.05)' }}
                        mb={2}
                        >
                        <Typography
                            variant="mov_body2"
                        >
                            Click to expand/shrink
                        </Typography>
                        {!expand ? (
                            <ExpandMoreIcon fontSize='large'/>
                        ) : (
                            <ExpandLessIcon fontSize='large'/>
                        )}
                    </Box>
                    {!expand && (props.userList !== undefined) && props.userList.length > 0 ? (
                        <Box mt={1} mb={3}>
                            <AvatarGroupComponent userList={props.userList}/>
                        </Box>
                    ):(<></>)}
                </AccordionSummary>
                <AccordionDetails>
                    <Box width={'100%'}>
                        <InputText
                            multiline
                            name="text"
                            placeholder="Write notes to your coach..."
                            style={{ width: '100%' }}
                            disabled={props.preview || props.isDone}
                            value={props.preview ? 'I did it coach!' : form.text}
                            onChange={formOnChange}
                            endAdornment={<Typography variant='caption'>{`${form.text.length}/255`}</Typography>}
                        />
                        {submit && !form.balanceBreakfastTotalCalories && !form.balanceDinnerTotalCalories && !form.balanceLunchTotalCalories && (
                            <Box display={'flex'} justifyContent="center">
                                <ErrorMessage message={"Please fill minimum 1 task balance"} />
                            </Box>
                        )}
                        <Box className={classes.itemDetailBox} marginTop="32px">
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    checked={balenceChecked.breackfast}
                                    onChange={onChangeBalancedChecked}
                                    control={<Checkbox name="breackfast" color="primary" />}
                                    label={
                                        <Typography variant="mov_body1" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                            Ate a Balanced Breakfast
                                        </Typography>
                                    }
                                />
                                <MorningIcon />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    TOTAL CALORIES
                                </Typography>
                                <InputNumber
                                    name="balanceBreakfastTotalCalories"
                                    endAdornment={'Cal'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.breackfast} 
                                    value={props.preview ? 256 : form.balanceBreakfastTotalCalories}
                                    onChange={formOnChange}
                                />
                            </Box>
                            <Box marginTop={'8px'}>
                                <InputText
                                    multiline
                                    placeholder="Write notes to your coach..."
                                    style={{ width: '100%' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.breackfast}
                                    name="balanceBreakfastText"
                                    value={props.preview ? '-' : form.balanceBreakfastText}
                                    onChange={formOnChange}
                                    endAdornment={<Typography variant='caption'>{`${form.balanceBreakfastText.length}/255`}</Typography>}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.itemDetailBox} marginTop="12px">
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    checked={balenceChecked.lunch}
                                    onChange={onChangeBalancedChecked}
                                    control={<Checkbox color="primary" name='lunch' />}
                                    label={
                                        <Typography variant="mov_body1" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                            Ate a Balanced Lunch
                                        </Typography>
                                    }
                                />
                                <AfternoonIcon />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    TOTAL CALORIES
                                </Typography>
                                <InputNumber
                                    name="balanceLunchTotalCalories"
                                    endAdornment={'Cal'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.lunch}
                                    value={props.preview ? 421 : form.balanceLunchTotalCalories}
                                    onChange={formOnChange}
                                />
                            </Box>
                            <Box marginTop={'8px'}>
                                <InputText
                                    name="balanceLunchText"
                                    multiline
                                    placeholder="Write notes to your coach..."
                                    style={{ width: '100%' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.lunch}
                                    value={props.preview ? 'Healthy meals, lots of veggies' : form.balanceLunchText}
                                    onChange={formOnChange}
                                    endAdornment={<Typography variant='caption'>{`${form.balanceLunchText.length}/255`}</Typography>}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.itemDetailBox} marginTop="12px">
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    checked={balenceChecked.dinner}
                                    onChange={onChangeBalancedChecked}
                                    control={<Checkbox color="primary" name='dinner' />}
                                    label={
                                        <Typography variant="mov_body1" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                            Ate a Balanced Dinner
                                        </Typography>
                                    }
                                />
                                <NightIcon />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems="center"
                            >
                                <Typography variant="mov_overline2" style={{color: `${(props.preview || props.isDone) ? '#9A9A9A' : '#414141'}`}}>
                                    TOTAL CALORIES
                                </Typography>
                                <InputNumber
                                    name="balanceDinnerTotalCalories"
                                    endAdornment={'Cal'}
                                    style={{ width: '120px', minWidth: '120px' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.dinner}
                                    value={props.preview ? 578 : form.balanceDinnerTotalCalories}
                                    onChange={formOnChange}
                                />
                            </Box>
                            <Box marginTop={'8px'}>
                                <InputText
                                    name="balanceDinnerText"
                                    multiline
                                    placeholder="Write notes to your coach..."
                                    style={{ width: '100%' }}
                                    disabled={props.preview || props.isDone || !balenceChecked.dinner}
                                    value={props.preview ? 'Tried a new restaurant in town' : form.balanceDinnerText}
                                    onChange={formOnChange}
                                    endAdornment={<Typography variant='caption'>{`${form.balanceDinnerText.length}/255`}</Typography>}
                                />
                            </Box>
                            {(!props.preview && !props.isDone) && (
                                <Box width="100%" textAlign="center" mt="20px">
                                    <ButtonMui
                                        disabled={loading}
                                        variant="contained"
                                        color="primary"
                                        onClick={onSubmit}
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </ButtonMui>
                                </Box>
                            )}
                            {expand && (props.userList !== undefined) && props.userList.length > 0 ? (
                                <Box mt={3}>
                                    <AvatarGroupComponent userList={props.userList}/>
                                </Box>
                            ):(<></>)}
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default AmountCalories;
