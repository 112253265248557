import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { 
    makeStyles, 
    createStyles, 
    Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            opacity: 0.6,
            marginTop: '5px',
        },
    },
));

export default function PromoCodeIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none"  className={classes.root}>
            <path d="M7 5.24609C6.73478 5.24609 6.48043 5.35145 6.29289 5.53899C6.10536 5.72652 6 5.98088 6 6.24609V8.24609C6 8.51131 
                6.10536 8.76566 6.29289 8.9532C6.48043 9.14074 6.73478 9.24609 7 9.24609C7.26522 9.24609 7.51957 9.14074 7.70711 8.9532C7.89464 
                8.76566 8 8.51131 8 8.24609V6.24609C8 5.98088 7.89464 5.72652 7.70711 5.53899C7.51957 5.35145 7.26522 5.24609 7 5.24609ZM19 
                6.24609C19.2652 6.24609 19.5196 6.14074 19.7071 5.9532C19.8946 5.76566 20 5.51131 20 5.24609V1.24609C20 0.980877 19.8946 
                0.726524 19.7071 0.538987C19.5196 0.351451 19.2652 0.246094 19 0.246094H1C0.734784 0.246094 0.48043 0.351451 0.292893 
                0.538987C0.105357 0.726524 0 0.980877 0 1.24609V5.24609C0 5.51131 0.105357 5.76566 0.292893 5.9532C0.48043 6.14074 0.734784 
                6.24609 1 6.24609C1.26522 6.24609 1.51957 6.35145 1.70711 6.53899C1.89464 6.72652 2 6.98088 2 7.24609C2 7.51131 1.89464 
                .76566 1.70711 7.9532C1.51957 8.14074 1.26522 8.24609 1 8.24609C0.734784 8.24609 0.48043 8.35145 0.292893 8.53899C0.105357 
                .72652 0 8.98088 0 9.24609V13.2461C0 13.5113 0.105357 13.7657 0.292893 13.9532C0.48043 14.1407 0.734784 14.2461 1 
                14.2461H19C19.2652 14.2461 19.5196 14.1407 19.7071 13.9532C19.8946 13.7657 20 13.5113 20 13.2461V9.24609C20 8.98088 
                19.8946 8.72652 19.7071 8.53899C19.5196 8.35145 19.2652 8.24609 19 8.24609C18.7348 8.24609 18.4804 8.14074 18.2929 7.9532C18.1054 
                7.76566 18 7.51131 18 7.24609C18 6.98088 18.1054 6.72652 18.2929 6.53899C18.4804 6.35145 18.7348 6.24609 19 6.24609ZM18 
                4.42609C17.4208 4.6363 16.9205 5.01972 16.5668 5.52426C16.2132 6.0288 16.0235 6.62997 16.0235 7.24609C16.0235 7.86221 16.2132 
                8.46339 16.5668 8.96793C16.9205 9.47247 17.4208 9.85589 18 10.0661V12.2461H8C8 11.9809 7.89464 11.7265 7.70711 11.539C7.51957 
                11.3514 7.26522 11.2461 7 11.2461C6.73478 11.2461 6.48043 11.3514 6.29289 11.539C6.10536 11.7265 6 11.9809 6 12.2461H2V10.0661C2.57915 
                9.85589 3.07954 9.47247 3.43316 8.96793C3.78678 8.46339 3.97648 7.86221 3.97648 7.24609C3.97648 6.62997 3.78678 6.0288 3.43316 
                5.52426C3.07954 5.01972 2.57915 4.6363 2 4.42609V2.24609H6C6 2.51131 6.10536 2.76566 6.29289 2.9532C6.48043 3.14074 6.73478 3.24609 
                7 3.24609C7.26522 3.24609 7.51957 3.14074 7.70711 2.9532C7.89464 2.76566 8 2.51131 8 2.24609H18V4.42609Z" fill="#515151"/>
        </SvgIcon>
    );
};
