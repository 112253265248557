import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function UserGroupIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            {...props}
            style={{
                color: 'white',
                width: props.width || '24px',
                height: props.height || '24px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.64396 13.6104C8.24825 13.0061 9.06783 12.6666 9.92242 12.6666H14.3669C15.2214 12.6666 16.041 13.0061 16.6453 13.6104C17.2496 14.2147 17.5891 15.0343 17.5891 15.8889V17C17.5891 17.5523 17.1414 18 16.5891 18C16.0368 18 15.5891 17.5523 15.5891 17V15.8889C15.5891 15.5647 15.4603 15.2538 15.2311 15.0246C15.0019 14.7954 14.691 14.6666 14.3669 14.6666H9.92242C9.59826 14.6666 9.28739 14.7954 9.05818 15.0246C8.82896 15.2538 8.7002 15.5647 8.7002 15.8889V17C8.7002 17.5523 8.25248 18 7.7002 18C7.14791 18 6.7002 17.5523 6.7002 17V15.8889C6.7002 15.0343 7.03968 14.2147 7.64396 13.6104Z"
                fill="#515151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1446 8C11.4696 8 10.9224 8.54721 10.9224 9.22222C10.9224 9.89724 11.4696 10.4444 12.1446 10.4444C12.8196 10.4444 13.3668 9.89724 13.3668 9.22222C13.3668 8.54721 12.8196 8 12.1446 8ZM8.92236 9.22222C8.92236 7.44264 10.365 6 12.1446 6C13.9242 6 15.3668 7.44264 15.3668 9.22222C15.3668 11.0018 13.9242 12.4444 12.1446 12.4444C10.365 12.4444 8.92236 11.0018 8.92236 9.22222Z"
                fill="#515151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.72878 13.8787C2.29139 13.3161 3.05445 13 3.8501 13H5.90011C6.45239 13 6.90011 13.4477 6.90011 14C6.90011 14.5523 6.45239 15 5.90011 15H3.8501C3.58489 15 3.33053 15.1053 3.14299 15.2929C2.95545 15.4804 2.8501 15.7348 2.8501 16V17C2.8501 17.5523 2.40238 18 1.8501 18C1.29781 18 0.850098 17.5523 0.850098 17V16C0.850098 15.2043 1.16617 14.4413 1.72878 13.8787Z"
                fill="#515151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.8501 9C5.29782 9 4.8501 9.44772 4.8501 10C4.8501 10.5523 5.29782 11 5.8501 11C6.40239 11 6.85011 10.5523 6.85011 10C6.85011 9.44772 6.40239 9 5.8501 9ZM2.8501 10C2.8501 8.34315 4.19325 7 5.8501 7C7.50696 7 8.85011 8.34315 8.85011 10C8.85011 11.6569 7.50696 13 5.8501 13C4.19325 13 2.8501 11.6569 2.8501 10Z"
                fill="#515151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.6711 13.8787C22.1085 13.3161 21.3454 13 20.5498 13H18.4998C17.9475 13 17.4998 13.4477 17.4998 14C17.4998 14.5523 17.9475 15 18.4998 15H20.5498C20.815 15 21.0694 15.1053 21.2569 15.2929C21.4444 15.4804 21.5498 15.7348 21.5498 16V17C21.5498 17.5523 21.9975 18 22.5498 18C23.1021 18 23.5498 17.5523 23.5498 17V16C23.5498 15.2043 23.2337 14.4413 22.6711 13.8787Z"
                fill="#515151"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5498 9C19.1021 9 19.5498 9.44772 19.5498 10C19.5498 10.5523 19.1021 11 18.5498 11C17.9975 11 17.5498 10.5523 17.5498 10C17.5498 9.44772 17.9975 9 18.5498 9ZM21.5498 10C21.5498 8.34315 20.2067 7 18.5498 7C16.8929 7 15.5498 8.34315 15.5498 10C15.5498 11.6569 16.8929 13 18.5498 13C20.2067 13 21.5498 11.6569 21.5498 10Z"
                fill="#515151"
            />
        </SvgIcon>
    );
}
