import React, { lazy, useState, Suspense, useCallback } from "react";
import { useParams, useLocation, Link as RouterLink } from "react-router-dom";
import { Paper, Box, Typography, Button, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FormikHelpers } from "formik";

import AddIcon from "components/icons/AddIcon";
import VideoTab from "./components/VideoTab";
import VideoTabPanel from "./components/VideoTabPanel";
import VideoAddNew from "./VideoAddNew";
import VideoEdit from "./VideoEdit";
import { VideoVariant, DefaultVideoRequest } from "interfaces/video";
import history from "services/data/history";

import { useStyles } from "./styles";
import { postVideoCourse, postVideoCooking } from "services/domain/admin/videos";

const VideoCourse = lazy(() => import("./Course"));
const VideoCooking = lazy(() => import("./Cooking"));

/**
 * these to keep component update event after change route
 */
let videoType = "course" as VideoVariant;

const Videos = () => {
  const [tab, setTab] = useState<VideoVariant>(videoType);
  const { action, id } = useParams<{ action: string; id: string }>();

  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  // eslint-disable-next-line
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");

  const location = useLocation();
  const classes = useStyles();

  // TODO fix eslint warning due to dependency array
  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, val: VideoVariant) => {
    setTab(val);
    videoType = val;
  }, []);

  const onSubmit = useCallback(async (values: DefaultVideoRequest, { setSubmitting }: FormikHelpers<DefaultVideoRequest>, videoType: string) => {
    setSubmitting(true);
    try {
      const payload = values;
      if (action === "edit") {
        Object.assign(payload, { idVideo: id });
      }

      if (videoType === "course") {
        await postVideoCourse(values);
      }
      if (videoType === "cooking") {
        await postVideoCooking(values);
      }

      // if ('message' in result) {
      //     setSeverity('success');
      //     setMessage(result.message);
      //     setShowAlert(true);
      history.goBack();
      // }
    } catch (e) {
      // TODO: handle error here
    } finally {
      setSubmitting(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
    setMessage("");
  };

  if (action === "add") {
    return <VideoAddNew variant={tab} onSubmit={onSubmit} />;
  }

  if (action === "edit") {
    return <VideoEdit variant={tab} onSubmit={onSubmit} />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Videos</Typography>

        <Button color="primary" component={RouterLink} to={`${location.pathname}/add`}>
          ADD NEW {tab.toUpperCase()} VIDEO
          <AddIcon className={classes.addIcon} />
        </Button>
      </Box>
      <Box mt={3}>
        <Paper elevation={3}>
          <Box display="flex" justifyContent="center">
            <div style={{ flexGrow: 1 }} />
            <VideoTab value={tab} handleChange={handleTabChange} />
            <div style={{ flexGrow: 1 }} />
          </Box>
        </Paper>
      </Box>

      <Suspense fallback={<h1>Loading ...</h1>}>
        <VideoTabPanel value={tab} index="course">
          <VideoCourse />
        </VideoTabPanel>
        <VideoTabPanel value={tab} index="cooking">
          <VideoCooking />
        </VideoTabPanel>
      </Suspense>
      <Snackbar open={isShowAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Videos;
