import React, { FC } from 'react';
import {
    Box
} from '@material-ui/core';
import useStyles from './styles';
import { Skeleton } from '@material-ui/lab';


const WeeklyLoading: FC = () => {
    const classes = useStyles();

    return (
        <>
            <Box margin={'42px 50px 42px 50px'} className={classes.weeklyContainer} >
                <Box display={'flex'} mb={2} className={classes.weeklyContentRes2}>
                    <Box display={'flex'}>
                        <Box display={'flex'} alignItems={'start'} p={1}>
                            <Skeleton height={'52px'} width={'32px'} />
                        </Box>
                        <Box flex="1" p={1} alignSelf={'center'}>
                            <Skeleton height={20} width={200} />
                        </Box>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <Skeleton height={100} width={200} />
                    </Box>
                </Box>
                

                <Box display={'flex'} mt={2} mb={2} className={classes.weeklyContentRes2}>
                    <Box display={'flex'}>
                        <Box display={'flex'} alignItems={'start'} p={1}>
                            <Skeleton height={'52px'} width={'32px'} />
                        </Box>
                        <Box flex="1" p={1} alignSelf={'center'}>
                            <Skeleton height={20} width={200} />
                            <Skeleton height={20} width={200} />
                        </Box>
                    </Box>
                </Box>

                <Box display={'flex'} mt={2} mb={2} className={classes.weeklyContentRes2}>
                    <Box display={'flex'}>
                        <Box display={'flex'} alignItems={'start'} p={1}>
                            <Skeleton height={'52px'} width={'32px'} />
                        </Box>
                        <Box flex="1" p={1} alignSelf={'center'}>
                            <Skeleton height={20} width={200} />
                            <Skeleton height={20} width={200} />
                        </Box>
                    </Box>
                </Box>
                <Box display={'flex'} mt={2} mb={2} className={classes.weeklyContentRes2}>
                    <Box display={'flex'}>
                        <Box display={'flex'}>
                        <Box display={'flex'} alignItems={'start'} p={1}>
                            <Skeleton height={'52px'} width={'32px'} />
                        </Box>
                        <Box flex="1" p={1} alignSelf={'center'}>
                            <Skeleton height={20} width={200} />
                            <Skeleton height={40} width={200} />
                        </Box>
                    </Box>
                    </Box>
                </Box>

                <Box display={'flex'} mt={2} mb={2} className={classes.weeklyContentRes2}>
                    <Box display={'flex'}>
                        <Box display={'flex'}>
                        <Box display={'flex'} alignItems={'start'} p={1}>
                            <Skeleton height={'52px'} width={'32px'} />
                        </Box>
                        <Box flex="1" p={1} alignSelf={'center'}>
                            <Skeleton height={20} width={200} />
                            <Skeleton height={80} width={200} />
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Box>
            <Box margin={'42px 50px 42px 50px'} className={classes.weeklyContainer} marginTop={'16px'}>
                <Box display={'flex'} mb={2}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <Box width="17.58px" ></Box>
                    </Box>
                    <Box flex="1" p={1} alignSelf={'center'}>
                        <Skeleton height={20} width={100} />
                        <Skeleton height={20} width={200} />
                    </Box>
                </Box>

                <Box display={'flex'} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: 'flex-start' }}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <Skeleton height={'52px'} width={'32px'} />
                    </Box>
                    <Box p={1} alignSelf={'start'} minWidth={210}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={40} width={200} />
                    </Box>
                </Box>

                <Box display={'flex'} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: 'flex-start' }}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <Skeleton height={'52px'} width={'32px'} />
                    </Box>
                    <Box p={1} alignSelf={'start'} minWidth={210}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={40} width={200} />
                    </Box>
                </Box>

                <Box display={'flex'} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: 'flex-start' }}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <Skeleton height={'52px'} width={'32px'} />
                    </Box>
                    <Box p={1} alignSelf={'start'} minWidth={210}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={40} width={200} />
                    </Box>
                </Box>

                <Box display={'flex'} mb={0} className={classes.weeklyContentOpt} style={{ justifyContent: 'flex-start' }}>
                    <Box display={'flex'} alignItems={'center'} p={1}>
                        <Skeleton height={'52px'} width={'32px'} />
                    </Box>
                    <Box p={1} alignSelf={'start'} minWidth={210}>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={40} width={200} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export default WeeklyLoading;