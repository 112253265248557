/**
 * @description Converts a time from Mountain Time to the user's timezone. Returns an object with the converted time and day. If the time or day is undefined, returns an empty string for both.
 *
 * @param time - The time to convert. Must be in the format "HH:mm". example "17:55". Assumed to be in Mountain Time.
 * @param day - The day to convert. Must be in the format "ddd". example "Mon". Assumed to be in Mountain Time.
 *
 * @returns An object with the converted time and day. If the time or day is undefined, returns an empty string for both.
 */

import moment from "moment-timezone";

const convertTimeToUserTZ = (time: string | undefined, day: string | undefined): { convertedTime: string; convertedDay: string } => {
  try {
    if (!time || !day)
      return {
        convertedTime: "",
        convertedDay: "",
      };

    const userTZName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const momentOriginalTime = moment.tz(`${day} ${time}`, "ddd HH:mm", "America/Denver"); // assumed to be in Mountain Time
    const momentUserTime = momentOriginalTime.clone().tz(userTZName);

    const userTime = momentUserTime.format("h:mm a");
    const userDay = momentUserTime.format("ddd");

    return {
      convertedTime: userTime,
      convertedDay: userDay,
    };
  } catch (error) {
    console.error("Error in convertTimeToUserTZ: ", error);

    return {
      convertedTime: "",
      convertedDay: "",
    };
  }
};

export default convertTimeToUserTZ;
