import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles, Theme, fade } from '@material-ui/core/styles';

export const Styled = (input: any) => withStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiInputLabel-shrink': {
                transform: 'translate(0, 1.5px) scale(0.9)',
                fontWeight: 600,
                color: '#282828',
            },
            'label + &': {
                fontWeight: 500,
                color: '#282828',
            },
            // marginTop: theme.spacing(2),
            '& label + .MuiInput-formControl' : {
                marginTop: theme.spacing(2.5),
                fontWeight: 500,
                color: '#282828',
            },
            '& .MuiInput-underline::before, & .MuiInput-underline:hover:not(.Mui-disabled)::before, & .MuiInput-underline::after': {
                borderBottom: 'none',
            },
            '& .MuiInput-underline.Mui-error::after': {
                borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiInput-input': {
                borderRadius: 12,
                position: 'relative',
                backgroundColor: '#FFFFFF',
                border: '1px solid #ced4da',
                boxShadow: 'inset 1px 1px 8px rgba(0, 0, 0, 0.1)',
                fontSize: 16,
                fontWeight: 600,
                // width: 'auto',
                padding: '10px 12px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                color: '#282828',
                // Use the system font instead of the default Roboto font.
                // fontFamily: [
                //     '-apple-system',
                //     'BlinkMacSystemFont',
                //     '"Segoe UI"',
                //     'Roboto',
                //     '"Helvetica Neue"',
                //     'Arial',
                //     'sans-serif',
                //     '"Apple Color Emoji"',
                //     '"Segoe UI Emoji"',
                //     '"Segoe UI Symbol"',
                // ].join(','),
                height: 20,
                '&:focus': {
                    boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderColor: theme.palette.primary.main,
                },
                '&::placeholder': {
                    color: '#282828',
                    opacity: 1,
                    fontWeight: 400,
                }
            },
            // TODO:
            // '.Mui-error + &': {
                //     boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                //     borderColor: theme.palette.primary.main,
                // },
            '& .MuiInputAdornment-root': {
                position: 'absolute',
                right: 0,
            },
        },
    }),
)(input); 

const StyledInput = Styled(TextField);

export default StyledInput;
