import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';

const DurationCell = ({ value, row }: { value: any, row: any }) => {
    let dateValue = '-';
    if (value) dateValue = moment.utc(value).format('MMM/DD/YYYY');
    return (
        <Box width={'165px'} display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'} textAlign="center" margin="auto">
            <span style={{fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '150%'}}>{dateValue}</span>
        </Box>
    );
};

export default DurationCell;
