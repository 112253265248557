import _ from "lodash";
import moment from "moment";
import jwt from "jwt-simple";

import Auth from "services/domain/auth";

export const startCaseToUpper = (text: string) => _.startCase(_.toLower(text));
export const getTimeDiff = ({ startDate, endDate, unit = "weeks" }: { startDate: Date | moment.Moment; endDate: Date | moment.Moment; unit?: "weeks" }) => {
  // const diff = moment.duration(moment(endDate).diff(moment(startDate)));
  return moment(endDate).diff(moment(startDate), unit);

  // if (unit === 'week') {
  //     return diff.weeks();
  // }

  // return 0;
};

export const fUTCToLocalDate = (date: string) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
};

export const getTokenPayload = (key: "_id" | "role") => {
  if (Auth.userProperties?.token) {
    try {
      const decoded = jwt.decode(Auth.userProperties?.token, "", true);

      return decoded[key];
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

// formats cents to dollars and ensures there are two decimal places
export const formatCentsToDollars = (cents: number) => {
  return `$${(cents / 100).toFixed(2)}`;
};
