import React, { useEffect, useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { ClockIcon } from 'components/_newIcon';
import TotalWeekIndicator from 'components/_modules/booking/TotalWeekIndicator';
import moment from 'moment';
import Swal from 'sweetalert2';
import DateRangePicker from './DateRangeFilter';
import Typography from 'components/_newDesign/typography/Typography';
import ErrorMessage from 'components/_newDesign/alert/ErrorMessage';
import { useLocation } from 'react-router-dom';
import { ProfileContext, ProfileContextType } from '../PaymentStore';

const BookingInfo: React.FC<any> = (props) => {
    const bookingData = props.bookingData;

    const { profile, setProfile } = useContext(
        ProfileContext
    ) as ProfileContextType;

    const { search } = useLocation();

    const urlQueries = new URLSearchParams(search);
    const hasAddWeeksQuery = urlQueries.has('add-weeks');

    //* I believe this is the toggle to whether an option has already been selected?
    const [first, setFirst] = React.useState(true);

    //* The total number of weeks selected by the user - default to 1
    const [totalWeek, setTotalWeek] = React.useState<number>(1);
    const [recurring, setRecurring] = React.useState<boolean>(false);

    const onWeekItemClick = (
        weekNumber: number,
        bookingInfoIndex: number,
        recurring: boolean = false
    ) => {
        // TODO how do we handle recurring bookings?

        setTotalWeek(weekNumber);
        setRecurring(recurring);

        const nextMondayDayCount = 7 - moment().isoWeekday();
        const startDate = bookingData.startDate
            ? moment(bookingData.startDate)
            : moment().add(nextMondayDayCount, 'days');
        const endDate = weekNumber
            ? moment(startDate).add(weekNumber, 'weeks')
            : moment().add(nextMondayDayCount, 'days');

        const metric = weekNumber === 1 ? 'week' : 'weeks';
        if (!first) {
            Swal.fire({
                confirmButtonText: 'Yes',
                showCancelButton: true,
                html: `<div style="font-family: Montserrat; font-size: 16px;">This action will update booking's date range to <br/><b>${
                    startDate.format('dddd, DD-MMM-YYYY') +
                    '</b> until <b>' +
                    endDate.format('dddd, DD-MMM-YYYY')
                }</b>,<br/>with total <b>${weekNumber}</b> ${metric},<br/>are you sure ?</div>`,
                confirmButtonColor: '#FFA21E',
            }).then((result) => {
                const startDateFromMoment = startDate.toDate();
                const endDateFromMoment = endDate.toDate();
                props.onDateRangeChange({
                    ...profile,
                    startDate: startDateFromMoment,
                    endDate: endDateFromMoment,
                    recurring,
                });
            });
        }
        if (first) {
            const startDateFromMoment = startDate.toDate();
            const endDateFromMoment = endDate.toDate();
            props.onDateRangeChange({
                ...profile,
                startDate: startDateFromMoment,
                endDate: endDateFromMoment,
                recurring,
            });
            setFirst(false);
        }
    };

    const dateRangeChangeHandle = (val: any, index: number) => {
        props.onDateRangeChange({
            ...profile,
            startDate: val.startDate,
            endDate: val.endDate,
            recurring,
        });
    };

    useEffect(() => {
        if (!hasAddWeeksQuery) return;

        const addWeeksDefault = 4;

        setTotalWeek(addWeeksDefault);
    }, []);

    return (
        <Box mt={5}>
            <Typography
                variant="mov_overline2"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px',
                }}
            >
                <ClockIcon /> SELECT DURATION
            </Typography>
            {props.showSelectDurationErr && (
                <ErrorMessage message="Please select duration." />
            )}
            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item md={12} xs={12} sm={12} xl={12}>
                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item md={12} xs={12} sm={12} xl={12}>
                            <Grid
                                container
                                style={{ justifyContent: 'center' }}
                            >
                                <Grid item md={12} xs={12} sm={12} xl={12}>
                                    <TotalWeekIndicator
                                        startDate={props.bookingData.startDate}
                                        endDate={props.bookingData.endDate}
                                        showRecurringOption={true}
                                        recurring={recurring}
                                        onItemClick={(weekNumber, recurring) =>
                                            onWeekItemClick(
                                                weekNumber,
                                                0,
                                                recurring
                                            )
                                        }
                                        hasAddWeeksQuery={hasAddWeeksQuery}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!hasAddWeeksQuery && (
                <>
                    <Typography
                        variant="mov_overline2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '20px',
                        }}
                    >
                        {' '}
                        SELECT YOUR START DAY
                    </Typography>
                    {props.showSelectStartDateErr && (
                        <ErrorMessage message="Please select start date." />
                    )}
                    <DateRangePicker
                        onChange={(val) => {
                            dateRangeChangeHandle(val, 0);
                        }}
                        label="Duration Date"
                        margin="normal"
                        definedRanges={[]}
                        totalWeek={totalWeek}
                        closeOnSelection
                    />
                </>
            )}
        </Box>
    );
};

export default BookingInfo;
