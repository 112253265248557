import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    content: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    subtitle: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',

    },
    button: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        textTransform: 'none',
        boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.5), 1px 1px 6px rgba(0, 0, 0, 0.25), inset 1px 1px 3px rgba(255, 218, 165, 0.6)',
        borderRadius: '7px',
    },
    loadingPost: {
        width: '100% !important'
    },
    listDay: {
        display: 'inline-flex',
        paddingLeft: 0,
        '& li': {
            display: 'inline',
            position: 'relative',
            paddingLeft: '70px',
            paddingRight: '70px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '182px',
                minWidth: '140px',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '18px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    listDayStep2: {
        display: 'inline-flex',
        paddingLeft: 0,
        '& li': {
            display: 'inline',
            position: 'relative',
            paddingLeft: '70px',
            paddingRight: '70px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '182px',
                minWidth: '140px',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '18px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    listDayStep: {
        display: 'flex',
        paddingLeft: 0,
        width: '100%',
        justifyContent:'space-between',
        '& li': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:last-child)::before': {
                content: '\'\'',
                left: '100%',
                width: '100%',
                height: 1,
                background: '#C4C4C4',
                position: 'absolute',
                top: '18px',
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    listDayVertical: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 0,
        '& li': {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '48px',
            '&::before': {
                content: '\'\'',
            },
            '&:not(:first-child)::before': {
                content: '\'\'',
                minWidth: 1,
                height: 50,
                background: '#C4C4C4',
                position: 'absolute',
                top: -49,
            },
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    listToday: {
        '&:not(:last-child)::before': {
            background: `${theme.palette.primary.main} !important`,
        },
    },
    circle: {
        borderRadius: '7px',
        width: '112px',
        height: '36px',
        background: '#FFFFFF',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box', 

    },
    circle2: {
        borderRadius: '7px',
        width: '112px',
        height: '36px',
        textAlign: 'center',
        background: '#FFFFFF',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box', 
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        fontFamily: 'Montserrat,Roboto,Helvetica,Arial,sans-serif',
        [theme.breakpoints.down('sm')]: {
            borderRadius: '7px',
            width: '80px',
            height: '41px',
            textAlign: 'center',
            fontSize: '14px',
        },

    },
    circleEnd: {
        width: '62px',
        height: '36px',
        background: '#FFFFFF',
    },
    circleActive: {
        borderColor: theme.palette.primary.main,
    },
    circleToday: {
        // borderColor: '#E5E5E5',
        // filter: 'drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.1))',
    },
    whenStepOn: {
        marginBottom: 150,
    },
    whenStepComplete: {
        marginBottom: 300,
    },
    weeklyPadding: {
        padding: '24px 48px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px 24px',
        },
    },
    titleHeader: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '48px',
        lineHeight: '150%',
        textAlign: 'center',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    logoutButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
        },
    },
    circleStep: {
        border: '1px solid #B3B3B3',
        display: 'flex',
        justifyContent: 'center',
        width: 28,
        height: 28,
        borderRadius: 28,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '100%',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing:' 0.02em',
        color: '#000000',
    },
    circleStepActive: {
        border: 'none',
        background: '#FFA11E',
        color: '#000000',
    },
    circleStepDone: {
        backgroundColor: '#CDCDCD',
        color: '#FFFFFF',
        border: 'none',
    },
    stepTitle: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0.05em',
        color: '#414141',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginLeft: 0,
        },
    },
    stepTitleActive: {
        color: '#000000',
        fontWeight: 600,
    },
    stepTitleDone: {
        color: '#CDCDCD',
        fontWeight: 600,
    },
    stepStyle: {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        padding: '30px',
        maxWidth: '900px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            flexDirection: 'column',
            alignItems: 'start'
        },
    },
    stepFixStyle: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 20px 20px',
        padding: '30px',
        width: '100%',
        position: 'fixed',
        zIndex: 1000,
        top: 0,
        left: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '0px',
            paddingTop: '10px'
        },
    },
    subStepStyle: {
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            flexDirection: 'column',
            width: '50%'
        },
    },
    stepContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
        },
    }
}));