import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Support = lazy(() => import('./Support'));

export default {
    path: routeConstants.SUPPORTS.path,
    component: Support,
    layout: LayoutOptions.dashboard,
};
