import React from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import movaraLogo from "assets/images/movara-home-logo.png";
import NavBar from "./components/NavBar";
import { TopBarProps } from "./types";
import { useStyles } from "./styles";
import { Box, IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory } from "react-router-dom";

const TopBar = ({ layoutType = "main", disableBackButton = true }: TopBarProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar className={`${classes.root} ${layoutType === "dashboard" ? classes.rootDashboard : undefined}`} color="default" position="fixed">
      <Toolbar style={{ paddingLeft: "0px" }}>
        {!disableBackButton && (
          <IconButton id={`back-button-navbar`} onClick={() => history.goBack()}>
            <KeyboardArrowLeftIcon color="secondary" fontSize="large" />
          </IconButton>
        )}
        {layoutType === "main" && (
          <>
            <div className={classes.sectionDesktop} style={{ width: "150px" }} />
            <div className={classes.grow} />
            <Box textAlign="center">
              <RouterLink to="/dashboard-user">
                <img src={movaraLogo} alt="movara home" className={classes.logo} />
              </RouterLink>
            </Box>
          </>
        )}

        <div className={classes.grow} />
        <div className={classes.navBarStyle}>
          <NavBar />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
