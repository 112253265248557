import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            flexGrow: 1,
        },
        listItem: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            height: 106,
            '&.Mui-selected': {
                background: 'none',
                '&:hover': {
                    background: 'none',
                },
                '&::after': {
                    content: '\'\'',
                    width: 8,
                    height: 90,
                    background: theme.palette.primary.main,
                    position: 'relative',
                    left: 16,
                },
            },
        },
        videoList: {
            width: '50%',
            marginRight: 10,
        },
        videoListDetail: {
            width: '50%',
        },
        tabHeader: {
            borderBottom: '1px solid #DDDDDD',
        },
        paper: {
            padding: theme.spacing(1),
        },
        filterAccordion: {
            marginBottom: theme.spacing(2),
        },
    }),
);

export default useStyles;
