import { makeStyles, Theme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    'forgot-password__container': {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
    },
    'forgot-password__page-title': {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    button: {
        '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.46)',
            backgroundColor: 'rgba(0, 0, 0, 0.42)',
        },
    },
    buttonWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: orange[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));