import React, { FC } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import StyledInput from './styles';

type Meta = {
    error: string;
    touched: boolean;
};

interface Props {
    meta?: Meta;
    isSubmitted?: boolean;
}

const MInput: FC<Props & TextFieldProps> = ({ meta, isSubmitted, ...props }) => {
    let errorText = '';
    if ( meta && meta.error && meta.touched) {
        errorText = meta.error;
    }
    let error = !!errorText;

    if ('isSubmitted' !== undefined) {
        error = error && isSubmitted!;
        
        if (!isSubmitted) {
            errorText = '';
        }
    }

    return (
        <StyledInput
            {...props}
            helperText={errorText}
            error={error}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

MInput.defaultProps = {
    fullWidth: true,
    margin: 'normal',
};

export default MInput;
