import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles({
    chatWindowInnerWrapper: {
        height: '100%',
        backgroundColor: grey[50],
    },
    chatWindowBody: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F8F8',
        height: 'calc(100% - 75px)',
    },
    chatCard: {
        backgroundColor: 'transparent',
        color: 'white',
    }
});
