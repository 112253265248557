import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography, Select, MenuItem, FormControl, Tabs, Tab, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import Daily from './component/daily'
import Duration from './component/duration';
import { getDailyFitness, getDailyStretch, getDailypersonalDev, getDuration } from 'services/domain/admin/programPlan';
import { DailyVideoData} from 'interfaces/programPlan'

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface IDateRange {
    startDate: string
    endDate: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridHeader: {
            marginBottom: theme.spacing(5),
        },
        grid: {
            marginBottom: theme.spacing(2),
        },
        boxHeader: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        boxTitle: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        boxBody: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        formControl: {
            width: '100%',
        },
    }),
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Programs = () => {
    const classes = useStyles();
    const [selectedWeek, setSelectedWeek] = React.useState<number>(1)
    const [value, setValue] = React.useState(0);
    const [fitness, setFitness] = React.useState<DailyVideoData[]>([]);
    const [stretch, setStretch] = React.useState<DailyVideoData[]>([]);
    const [personalDev, setPersonalDev] = React.useState<DailyVideoData[]>([]);
    const [dateRange, setDateRange] = React.useState<IDateRange>({startDate: '', endDate: ''});
    const [loading, setLoading] = React.useState<boolean>(true)

    const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedWeek(event.target.value as number)
        fetchDuration(event.target.value as number)
    }
    const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const initData = async () => {
        setLoading(true)
        try {
            const fitness = await getDailyFitness()
            const stretch = await getDailyStretch()
            const personalDev = await getDailypersonalDev()
            setFitness(fitness.data)
            setStretch(stretch.data)
            setPersonalDev(personalDev.data)

        } catch (error) {

        } finally {
            fetchDuration(1)
        }
    }
    const fetchDuration = async (week: number) => {
        try {
            const duration = await getDuration({week: week})
            const start = moment.utc(duration.data[0].startDate);
            const end = moment.utc(duration.data[0].endDate);

            setDateRange({
                startDate: start.format('DD-MMM-YYYY'),
                endDate: end.format('DD-MMM-YYYY'),
            })
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        initData()
        // eslint-disable-next-line
    }, []);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.gridHeader}>
                    <Grid item md={12}>
                        <Typography variant="h5">Plan Program {value}</Typography>
                    </Grid>
                </Grid>
                <Paper elevation={3}>
                    <Grid container>
                    {value !== 1 && (
                        <React.Fragment>
                            <Grid item md={12} className={classes.boxHeader}>
                                <Box height={80} className={classes.boxTitle} display="flex" alignItems="center">
                                    <Box flexGrow={1} >
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={selectedWeek}
                                                onChange={handleChangeSelect}
                                                >
                                                    <MenuItem value={1}>Week 1</MenuItem>
                                                    <MenuItem value={2}>Week 2</MenuItem>
                                                    <MenuItem value={3}>Week 3</MenuItem>
                                                    <MenuItem value={4}>Week 4</MenuItem>
                                                    <MenuItem value={5}>Week 5</MenuItem>
                                                    <MenuItem value={6}>Week 6</MenuItem>
                                                    <MenuItem value={7}>Week 7</MenuItem>
                                                    <MenuItem value={8}>Week 8</MenuItem>
                                                    <MenuItem value={9}>Week 9</MenuItem>
                                                    <MenuItem value={10}>Week 10</MenuItem>
                                                    <MenuItem value={11}>Week 11</MenuItem>
                                                    <MenuItem value={12}>Week 12</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box flexGrow={1} ml={5}>
                                        <Typography>{dateRange.startDate} to {dateRange.endDate}</Typography>
                                    </Box>

                                </Box>
                            </Grid>
                        </React.Fragment>
                    )}
                        <hr/>
                        <Grid item md={12} className={classes.boxBody}>
                            <Tabs
                                value={value}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTabs}
                                aria-label="disabled tabs example"
                                centered
                            >
                                <Tab label="Daily" />

                                <Tab label="Duration" />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {loading ? (
                    <Box display="flex"
                        pb={1}
                        alignItems="center"
                        justifyContent="center">
                        <CircularProgress color="primary" />
                    </Box>
                ) :  (
                    <React.Fragment>
                        <TabPanel value={value} index={0}>
                            <Daily week={selectedWeek} stretch={stretch} fitness={fitness} personalDev={personalDev}/>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Duration />
                        </TabPanel>
                    </React.Fragment>
                )}

            </Grid>
        </Grid>
    )
};

export default Programs;
