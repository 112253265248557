import React from 'react';
import { CardHeader, Typography } from '@material-ui/core';

interface SubHeaderProps {
    description: string;
}

const SubHeader = ({ description }: SubHeaderProps) => {
    return (
        <>
            <Typography>{description}</Typography>
        </>
    );
};

const PromotionTitleCell= ({ value, row }: { value: any, row: any }) => {
    const { original: { ...otherRowProps }} = row;
    return (
        <CardHeader
            title={`${value}`}
            subheader={<SubHeader { ...otherRowProps } />}
        />
    );
};

export default PromotionTitleCell;
