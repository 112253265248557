import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function WaistMeasurementInOutIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 34 32"
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path
                d="M28.5334 5.19607C28.1143 3.8993 27.6165 2.9562 27.6165 2.9562M10.5228 13.0553H25.0492C29.5813 12.4658 29.4896 8.7589 28.7692 6.00819M5.66318 2.9562C5.66318 2.9562 0.88217 12.0991 8.23051 13.0553H9.73686M7.77206 12.9767C7.96096 13.801 8.17988 14.8704 8.33261 15.9784M8.3877 20.0106C7.83755 22.6566 4.27472 24.6345 3.60669 30.2538M28.4024 25.9836C28.9788 27.1494 29.4634 28.5248 29.6599 30.2538M25.5076 12.9767C25.3187 13.801 25.0998 14.8704 24.9471 15.9784M24.892 20.0106C25.2326 21.648 26.7258 23.0364 27.9702 25.1846M11.479 2C11.479 2 12.3304 5.99509 16.6136 5.99509C20.8969 5.99509 21.6697 2.18338 21.6697 2.18338M5.24402 28.7212H29.3979M17.0197 25.2763C17.0197 25.4861 16.8496 25.6562 16.6398 25.6562C16.4301 25.6562 16.26 25.4861 16.26 25.2763C16.26 25.0665 16.4301 24.8964 16.6398 24.8964C16.8496 24.8964 17.0197 25.0665 17.0197 25.2763Z"
                strokeWidth="0.5" strokeMiterlimit="10"/>
            <path d="M3.06959 18.4916H33V16.6709H3.8948C2.29677 16.6709 1 17.9677 1 19.5657V20.6398" fill="#FFA21E"/>
            <path
                d="M3.06959 22.6308C4.21259 22.6308 5.13917 21.7042 5.13917 20.5612C5.13917 19.4182 4.21259 18.4916 3.06959 18.4916C1.92659 18.4916 1 19.4182 1 20.5612C1 21.7042 1.92659 22.6308 3.06959 22.6308Z"
                fill="#FFA21E"/>
            <path
                d="M3.03029 21.203C3.38477 21.203 3.67212 20.9157 3.67212 20.5612C3.67212 20.2067 3.38477 19.9194 3.03029 19.9194C2.67582 19.9194 2.38846 20.2067 2.38846 20.5612C2.38846 20.9157 2.67582 21.203 3.03029 21.203Z"
                fill="#FFA21E"/>
            <path
                d="M3.06959 18.4916H33V16.6709H3.8948C2.29677 16.6709 1 17.9677 1 19.5657V20.6398M3.06959 18.4916C1.92659 18.4916 1 19.4182 1 20.5612C1 21.7042 1.92659 22.6308 3.06959 22.6308C4.21259 22.6308 5.13917 21.7042 5.13917 20.5612C5.13917 19.4182 4.21259 18.4916 3.06959 18.4916ZM31.3627 17.7319V16.7495M29.9349 17.7319V16.7495M28.5072 17.7319V16.7495M27.0794 17.7319V16.7495M25.6517 17.7319V16.7495M24.2239 17.7319V16.7495M22.7962 17.7319V16.7495M21.3684 17.7319V16.7495M19.9275 17.7319V16.7495M18.4998 17.7319V16.7495M17.072 17.7319V16.7495M15.6443 17.7319V16.7495M14.2165 17.7319V16.7495M12.7888 17.7319V16.7495M11.361 17.7319V16.7495M9.93328 17.7319V16.7495M8.50553 17.7319V16.7495M7.07777 17.7319V16.7495M5.65002 17.7319V16.7495M4.20917 17.7319V16.7495M2.78142 17.7319V16.7495M3.67212 20.5612C3.67212 20.9157 3.38477 21.203 3.03029 21.203C2.67582 21.203 2.38846 20.9157 2.38846 20.5612C2.38846 20.2067 2.67582 19.9194 3.03029 19.9194C3.38477 19.9194 3.67212 20.2067 3.67212 20.5612Z"
                strokeWidth="0.5" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
