import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
    Paper, Box, InputLabel, Button, Grid, Typography, Card, CardHeader, Avatar,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import BookingInfo from 'components/_modules/booking/BookingInfo';
import Autocomplete from 'components/controls/Autocomplete';
import BagIcon from 'components/icons/BagIcon';
import { useStyles } from './styles';
import { phoneRegExp } from 'services/util/regex';
import history from 'services/data/history';
import { getUsers } from 'services/domain/admin/users';
import { saveBooking } from 'services/domain/admin/bookings';
import { getTimeDiff, fUTCToLocalDate } from 'services/util/common';
import { BookingInterface } from 'interfaces/booking';
import dummyAvatar from 'assets/images/dummy-avatar-256.png';
import { Toast } from 'services/util/toast';


interface BookingWithName extends BookingInterface {
    firstName: string;
    lastName: string;
}
interface AdminFormState {
    formState: BookingWithName;
}

type DateRange = {
    startDate: Date;
    endDate: Date;
}

type UserBooking = {
    id: string;
    dateRange: DateRange;
    paymentStatus: string;
}
interface FormikState {
    email: string;
    phoneNumber: string;
    address: string;
    bookingData: UserBooking[];
    firstName: string;
    lastName: string;
}

const validationSchema = Yup.object({
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    address: Yup.string(),
    bookingData: Yup.array().of(
        Yup.object().shape({
            id: Yup.string(),
            dateRange: Yup.object({
                startDate: Yup.date(),
                endDate: Yup.date(),
            }),
            paymentStatus: Yup.string().when('dateRange', {
                is: dateRange => !!dateRange,
                then: Yup.string().required('Payment status is required'),
            }),
        }),
    ),
    // no need to send payload below
    firstName: Yup.string(),
    lastName: Yup.string(),
});

const initialValues = {
    email: '',
    phoneNumber: '',
    address: '',
    bookingData: [
        {
            id: '',
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            paymentStatus: '',
        },
    ],
    firstName: '',
    lastName: '',
};

const BookingEdit = () => {
    const { id } = useParams<{ id: string }>();
    const booking = useSelector(({ admin: { formState } }: { admin: AdminFormState }) => Object.assign(initialValues, {
        email: formState?.email ?? '',
        phoneNumber: formState?.phoneNumber ?? '',
        address: formState?.address ?? '',
        bookingData: [
            {
                id,
                dateRange: formState?.startDate && formState.endDate
                    ? { startDate: fUTCToLocalDate(formState.startDate as string), endDate: fUTCToLocalDate(formState.endDate as string) }
                    : { startDate: initialValues.bookingData[0].dateRange.startDate, endDate: initialValues.bookingData[0].dateRange.endDate, },
                paymentStatus: formState?.paymentStatus ?? '',
            },
        ],
        firstName: formState?.firstName ?? '',
        lastName: formState?.lastName ?? '',
    }));
    // eslint-disable-next-line
    const [user, setUser] = React.useState({ ...booking });
    const classes = useStyles();

    // refresh event should not work, since data is provided from the redux while click the edit button
    React.useEffect(() => {
        if (!booking.email) goBack();
        // eslint-disable-next-line
    }, []);

    const userFetcher = React.useCallback(
        () => {
            return getUsers({ existData: 0, limitData: 50, role: 'guest' });
        }, []
    )

    const goBack = () => {
        history.goBack();
    }

    const handleSubmit = async (values: FormikState, { setSubmitting }: FormikHelpers<FormikState>) => {
        setSubmitting(true);
        try {
            const { firstName, lastName, bookingData, ...payload } = values;
            const toast = Toast({ onClose: goBack });
            await saveBooking({
                bookId: id,
                ...payload,
                startDate: moment(bookingData[0].dateRange.startDate).utc(true),
                endDate: moment(bookingData[0].dateRange.endDate).utc(true),
                totalWeeks: getTimeDiff({
                    startDate: bookingData[0].dateRange.startDate,
                    endDate: bookingData[0].dateRange.endDate,
                }),
                paymentStatus: bookingData[0].paymentStatus,
            });

            toast.fire({
                icon: 'success',
                title: 'Booking record has been updated',
            })
        } catch(e) {
            const toast = Toast({});

            toast.fire({
                icon: 'error',
                title: 'Update booking record failed',
            });
        } finally {
            setSubmitting(false);
        }
    }

    const handleEmailChange = (user: any, setFieldValue: any) => {
        setFieldValue('email', user?.email ?? '');
        setFieldValue('phoneNumber', user?.phoneNumber ?? '');
        setFieldValue('address', user?.address ?? '');
        setFieldValue('firstName', user?.name?.firstName ?? '');
        setFieldValue('lastName', user?.name?.lastName ?? '');
    }

    return (
        <Paper className={classes.formContainer}>
            <Box maxWidth="430px" marginLeft="auto" marginRight="auto">
                <Formik
                    enableReinitialize
                    initialValues={user}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting, values, setFieldValue }) => {
                        return (
                            <Form>
                                <Grid container alignItems="center" justify="center" className={classes.titleContainer}>
                                    <BagIcon />
                                    <Typography component="span">
                                        &nbsp;&nbsp;&nbsp;Edit Booking
                                    </Typography>
                                </Grid>
                                <Autocomplete
                                    onChange={(e, obj) => { handleEmailChange(obj, setFieldValue) }}
                                    fetcher={userFetcher}
                                    selector="email"
                                    label="Search by email"
                                />

                                <Box className={classes.userId}>
                                    <InputLabel>ID</InputLabel>
                                    <Typography>{}</Typography>
                                </Box>
                                <Card elevation={0}>
                                    <CardHeader
                                        avatar={<Avatar src={dummyAvatar} />}
                                        title={`${values.firstName} ${values.lastName}`}
                                    />
                                </Card>

                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Email Address (*)"
                                    name="email"
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Phone Number (*)"
                                    name="phoneNumber"
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Address (*)"
                                    name="address"
                                    margin="normal"
                                    fullWidth
                                />

                                <BookingInfo
                                    bookingData={values.bookingData}
                                    setFieldValue={setFieldValue}
                                    minItemCount={1}
                                    maxItemCount={1}
                                />

                                <Box mt={10}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Save Booking
                                    </Button>
                                    <Button
                                        onClick={goBack}
                                        className={classes.buttonCancel}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Paper>
    );
};

export default BookingEdit;
