import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            borderRadius: 10,
            height: 100,
        },
        header: {
            fontSize: 14,
            marginBottom: 18,
        },
        grid: {
            marginBottom: theme.spacing(2),
        },
    }),
);

export default useStyles;
