import {
    unstable_createMuiStrictModeTheme,
} from '@material-ui/core/styles';

export default unstable_createMuiStrictModeTheme({
    props: {
        MuiTextField: {
            InputLabelProps: {
                shrink: true,
            },
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
        fontWeightBold: 600,
        fontSize: 12,
    },
    palette: {
        primary: {
            main: '#FFA21E',
            dark: '#b27115',
            light: '#ffb44b',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#414141',
            dark: '#2d2d2d',
            light: '#676767',
        },
        action: {
            hover: '#FFECD2',
            // selected: '#FFA21E',
            // active: '#FFA21E',
        },
    },
    overrides: {
        MuiButton: {
            contained:{
                // color: '',
                backgroundColor: '#FFF',
                /*'&:hover': {
                    backgroundColor: CONSTANTS.LIGHT_BLUE,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: CONSTANTS.CONTRAST_TEXT,
                    },
                }*/
            }
        },
    },
});
