import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function GuestIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root} viewBox="0 0 18 18">
            <path d="M15.1875 8.05263H13.8562H11.1936L9.31672 12.9375L7.15609 7.3125H4.63536H3.375" stroke="#32ADBC"
                  strokeWidth="0.5" strokeMiterlimit="10"/>
            <circle cx="9.28125" cy="9.28125" r="8.15625" stroke="#414141" strokeWidth="0.5" strokeMiterlimit="10"
                    strokeLinejoin="round"/>
        </SvgIcon>
    );
};
