import React from 'react';
import { Box } from '@material-ui/core';

import CheckoutContainer from './Checkout/CheckoutContainer';
import { useStyles } from './Form/styles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';

const stripePromise = loadStripe(stripeKey);

const Checkout: React.FC<any> = (props) => {
    const classes = useStyles();

    const changeStatusStep = (key: string) => {
        props.changeStatusStep(key);
    };

    return (
        <Box mt={5} mb={15} display="flex" justifyContent="center">
            <Box className={classes.root} marginBottom={'100px'}>
                <Elements stripe={stripePromise}>
                    <CheckoutContainer
                        profile={props.profile}
                        changeStatusStep={(key: string) =>
                            changeStatusStep(key)
                        }
                        hasAddWeeksQuery={props.hasAddWeeksQuery || false}
                        activeBookingId={props.activeBookingId || ''}
                    />
                </Elements>
            </Box>
        </Box>
    );
};

export default Checkout;
