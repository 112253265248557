import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function PostIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} className={classes.root}>
            <path d="M3.11523 20.7691H22.4999V4.61523" stroke="#414141" strokeWidth="0.5"/>
            <rect x="1.75" y="3.25" width="18.8846" height="15.6538" stroke="#414141" strokeWidth="0.5"/>
            <circle cx="6.34617" cy="7.8469" r="2.17308" stroke="#414141" strokeWidth="0.5"/>
            <line x1="10.3848" y1="8.4043" x2="18.4617" y2="8.4043" stroke="#414141" strokeWidth="0.5"/>
            <line x1="8.76929" y1="11.6348" x2="18.4616" y2="11.6348" stroke="#414141" strokeWidth="0.5"/>
            <line x1="3.9231" y1="14.8652" x2="18.4616" y2="14.8652" stroke="#414141" strokeWidth="0.5"/>
        </SvgIcon>

);
};
