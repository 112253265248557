/**
 * @description This component is used to render the accounting dropdown menu in the left hand navigation menu. It uses the Collapse component from Material UI, along with the ListItem components used for the other navigation items.
 */

import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';

export default function AccountingDropdown() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const location = useLocation();

    useEffect(() => {
        setOpen(location.pathname.includes('/accounting'));
    }, [location]);

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <AccountBalanceOutlinedIcon htmlColor="#c7c7c7" />
                </ListItemIcon>
                <ListItemText primary="ACCOUNTING" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem
                        button
                        component={Link}
                        to="/accounting/journal-entries"
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="JOURNAL ENTRIES" />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/accounting/unearned-revenue"
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="UNEARNED REVENUE REPORT" />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/accounting/coach-reports"
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="COACH REPORTS" />
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
}
