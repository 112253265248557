import React, { FC } from 'react';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon, MorningIcon, NightIcon, AfternoonIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import InputNumber from 'components/_newDesign/input/InputNumber';
import { useStyles } from '../styles'

interface Props {
    amountCalories: number;
    data: {
        balanceBreakfast: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        balanceLunch: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        balanceDinner: {
          isCheck: boolean,
          totalCalories: number,
          text: string
        },
        achieved: boolean,
        text: string
      }
}
const AmountCalories: FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <Box>
            <Box>
                <FormControlLabel
                    aria-label="Acknowledge"
                    checked={props.data.achieved}
                    control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                    label={
                        <Box display={'flex'} alignItems="center">
                            <SpoonForkIcon />
                            <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                I stayed under {props.amountCalories} calories today
                            </Typography>
                        </Box>
                    }
                />
                <InputText
                    multiline
                    name="text"
                    placeholder="Write notes to your coach..."
                    style={{ width: '100%' }}
                    disabled={true}
                    value={props.data.text}
                    endAdornment={<Typography variant='caption'>{`${props.data.text.length}/255`}</Typography>}
                />
            </Box>
            <Box>
                <Box width={'100%'}>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="32px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={props.data.balanceBreakfast.isCheck}
                                control={<Checkbox name="breackfast" color="primary" />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Breakfast
                                    </Typography>
                                }
                            />
                            <MorningIcon />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                TOTAL CALORIES
                            </Typography>
                            <InputNumber
                                name="balanceBreakfastTotalCalories"
                                endAdornment={'Cal'}
                                style={{ width: '120px', minWidth: '120px' }}
                                disabled={true} 
                                value={props.data.balanceBreakfast.totalCalories}
                            />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                name="balanceBreakfastText"
                                value={props.data.balanceBreakfast.text}
                                endAdornment={<Typography variant='caption'>{`${props.data.balanceBreakfast.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="12px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={props.data.balanceLunch.isCheck}
                                control={<Checkbox color="primary" name='lunch' />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Lunch
                                    </Typography>
                                }
                            />
                            <AfternoonIcon />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                TOTAL CALORIES
                            </Typography>
                            <InputNumber
                                name="balanceLunchTotalCalories"
                                endAdornment={'Cal'}
                                style={{ width: '120px', minWidth: '120px' }}
                                disabled={true}
                                value={props.data.balanceLunch.totalCalories}
                            />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                name="balanceLunchText"
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                value={props.data.balanceLunch.text}
                                
                                endAdornment={<Typography variant='caption'>{`${props.data.balanceLunch.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.itemDetailBox} paddingRight="0px !important" marginTop="12px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                checked={props.data.balanceDinner.isCheck}
                                control={<Checkbox color="primary" name='dinner' />}
                                label={
                                    <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                        Ate a Balanced Dinner
                                    </Typography>
                                }
                            />
                            <NightIcon />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                TOTAL CALORIES
                            </Typography>
                            <InputNumber
                                name="balanceDinnerTotalCalories"
                                endAdornment={'Cal'}
                                style={{ width: '120px', minWidth: '120px' }}
                                disabled={true}
                                value={props.data.balanceDinner.totalCalories}
                            />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                name="balanceDinnerText"
                                multiline
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                disabled={true}
                                value={props.data.balanceDinner.text}
                                endAdornment={<Typography variant='caption'>{`${props.data.balanceDinner.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AmountCalories;
