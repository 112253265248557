import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './style';

export default function NotificationIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 24" className={classes.root}>
            <path d="M12.6556 20.1548C12.6556 21.5132 11.579 22.6137 10.2501 22.6137C8.92076 22.6137 7.84375 21.5132 7.84375 20.1548C7.84375 20.09 7.8463 20.0265 7.85056 19.963H12.6488C12.6527 20.0265 12.6556 20.09 12.6556 20.1548Z" stroke="#414141"/>
            <path d="M11.9227 3.14668V3.15408C11.8291 3.14842 11.7354 3.14668 11.6405 3.14668H8.85928C8.76523 3.14668 8.67077 3.14886 8.57715 3.15408V3.14668C8.57715 2.20236 9.32651 1.43726 10.2503 1.43726C11.1733 1.43726 11.9227 2.2028 11.9227 3.14668Z" stroke="#414141"/>
            <path d="M19.4994 18.5158V18.9108C19.4994 19.4915 19.0173 19.963 18.4233 19.963H2.07616C1.48255 19.963 1 19.4915 1 18.9108V18.5158C1 17.9343 1.48255 17.4628 2.07616 17.4628H18.4233C19.0173 17.4632 19.4994 17.9347 19.4994 18.5158Z" stroke="#414141"/>
            <path d="M17.9428 17.4631H2.55566L3.0263 8.78677C3.19268 5.71894 5.60415 3.29834 8.57647 3.15437C8.67009 3.14871 8.76413 3.14697 8.8586 3.14697H11.6399C11.7347 3.14697 11.8284 3.14915 11.922 3.15437C14.8943 3.29834 17.3058 5.71938 17.4722 8.78677L17.9428 17.4631Z" stroke="#414141"/>
        </SvgIcon>
    );
};
