import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function MeasureIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 32 32" fill="none" className={classes.root}>
            <path d="M12.6997 21.8994V24.3988" stroke="#FFA21E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.23291 20.1665V24.214" stroke="#FFA21E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M25.7939 18.3716V20.871" stroke="#FFA21E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4614 19.7231V23.7706" stroke="#FFA21E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.17578 20.2798V22.7792" stroke="#FFA21E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.1991 13.3977C22.1991 15.6833 17.4535 17.5364 11.598 17.5364C5.74377 17.5364 0.997559 15.6839 0.997559 13.3977C0.997559 11.1114 5.74315 9.2583 11.598 9.2583C17.4529 9.2583 22.1991 11.1121 22.1991 13.3977Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M11.598 14.0739C9.30309 14.0739 7.52838 13.7602 6.29248 13.3977C7.52838 13.0352 9.30309 12.7214 11.598 12.7214C13.8929 12.7214 15.6676 13.0352 16.9035 13.3977C15.6676 13.7602 13.8929 14.0739 11.598 14.0739Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M22.1991 13.3977V20.2858C22.1991 22.5715 17.4535 24.4246 11.598 24.4246C5.74377 24.4246 0.997559 22.5721 0.997559 20.2858V13.3977C0.997559 15.6833 5.74315 17.5365 11.598 17.5365C17.4529 17.5365 22.1991 15.6833 22.1991 13.3977Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M28.7215 14.5152V20.7152C26.5408 21.2171 24.2521 20.859 22.2241 19.6396V13.5377C24.2763 14.6483 26.5426 14.974 28.7215 14.5152Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M31.3792 13.5308V19.6308C30.53 20.1432 29.6349 20.5045 28.7212 20.7151V14.5151C29.63 14.3239 30.5238 13.9958 31.3792 13.5308Z" stroke="#414141" strokeMiterlimit="10" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
