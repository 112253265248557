import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    footer: {
        marginTop: 'auto',
        background: 'linear-gradient(0deg, #414141, #414141)',
    },
    inner: {
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    // image: { TODO:
    //     backgroundImage: `url(${FooterBackground})`,
    //     height: '120px',
    // },
});

const Footer: FC<{}> = () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="md" className={classes.inner}>
                <Box
                    color="common.white"
                    textAlign="center"
                >
                    <Typography variant="caption">
                        Copyright © {new Date().getFullYear()} Movara Fitness Resort | All Rights Reserved | Designed by Movara
                    </Typography>
                </Box>
            </Container>
        </footer>
    );
};

export default Footer;
