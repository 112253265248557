import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';
import { useStyles as globalStyles } from '../_config/styles';
import { Status } from '../_config/interface';

export default function ChestMeasurementInOutIcon(props: SvgIconProps & Status) {
    const { isActive, isDisabled } = props;
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 34 32"
                 className={`${classes.root} ${globalClasses.default} ${isActive && globalClasses.active} ${isDisabled && globalClasses.disabled}`}
        >
            <path
                d="M5.6994 7.74965C3.97259 8.60073 2.49246 10.1302 2.31978 12.8684M12.767 4L11.6446 6.7629M11.6446 6.7629C11.6446 6.7629 8.77066 6.47921 6.20511 7.52764M11.6446 6.7629C11.6446 6.7629 15.9369 8.68707 20.5623 7.41663M2.2211 18.3942C1.44404 20.4911 0.370946 24.5368 1.48104 28.8168M7.05618 15.434C7.05618 15.434 7.05618 18.6533 9.5354 20.01M9.33805 27.0406L9.41205 27.8177M8.56098 19.2576L9.27637 26.4609M8.05527 18.8136C8.05527 18.8136 7.68524 22.8716 6.43947 27.9534M31.6263 14.5336C31.5029 15.656 31.1946 16.9388 30.6642 18.3942M21.2407 4L22.3632 6.7629C22.3632 6.7629 30.9602 5.92416 31.6633 12.5231M31.1452 16.9018C31.1452 16.9018 34.1302 22.6126 32.5267 28.8168M26.9392 15.434C26.9392 15.434 26.9392 18.6533 24.46 20.01M25.4468 19.2576L24.5834 27.8177M25.9525 18.8136C25.9525 18.8136 26.3225 22.8716 27.5683 27.9534M1 24.5368H7.11786M26.8159 24.5368H31.0712M31.8113 24.5368H32.9954M21.0681 7.26861C21.4998 7.13293 21.9315 6.96025 22.3508 6.7629"
                strokeWidth="0.5" strokeMiterlimit="10"/>
            <path
                d="M3.04751 14.6329H32.65V12.832H3.86158C2.28278 12.832 1 14.1148 1 15.6936V16.7544M3.04751 14.6329C1.9167 14.6329 1 15.5496 1 16.6804C1 17.8112 1.9167 18.7279 3.04751 18.7279C4.17832 18.7279 5.09502 17.8112 5.09502 16.6804C5.09502 15.5496 4.17832 14.6329 3.04751 14.6329ZM31.0219 13.8805V12.9184M29.6158 13.8805V12.9184M28.1973 13.8805V12.9184M26.7912 13.8805V12.9184M25.3728 13.8805V12.9184M23.9543 13.8805V12.9184M22.5482 13.8805V12.9184M21.1297 13.8805V12.9184M19.7236 13.8805V12.9184M18.3051 13.8805V12.9184M16.899 13.8805V12.9184M15.4806 13.8805V12.9184M14.0745 13.8805V12.9184M12.656 13.8805V12.9184M11.2375 13.8805V12.9184M9.83142 13.8805V12.9184M8.41297 13.8805V12.9184M7.00685 13.8805V12.9184M5.58839 13.8805V12.9184M4.18227 13.8805V12.9184M2.76382 13.8805V12.9184M3.65189 16.6804C3.65189 17.0346 3.36473 17.3217 3.01051 17.3217C2.65628 17.3217 2.36912 17.0346 2.36912 16.6804C2.36912 16.3261 2.65628 16.039 3.01051 16.039C3.36473 16.039 3.65189 16.3261 3.65189 16.6804Z"
                strokeWidth="0.5" strokeMiterlimit="10"/>
        </SvgIcon>
    );
};
