import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const Users = lazy(() => import('./Users'));

export default {
    path: routeConstants.USERS.path,
    component: Users,
    layout: LayoutOptions.dashboard,
};
