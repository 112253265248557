import React, { FC } from 'react';
import Typography from 'components/_newDesign/typography/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { SpoonForkIcon } from 'components/_newIcon/';
import InputText from 'components/_newDesign/input/InputText';
import InputNumber from 'components/_newDesign/input/InputNumber';
import { useStyles } from '../styles'

interface Props {
    amount?: number;
    data?: {
        text: string,
        achieved: boolean
        totalWater: number
    }
}
const OzWater: FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <Box>
            <Box>
                <FormControlLabel
                    aria-label="Acknowledge"
                    checked={true}
                    control={<Checkbox name='caloriesAmountNutrition' color="primary"/>}
                    label={
                        <Box display={'flex'} alignItems="center">
                            <SpoonForkIcon />
                            <Typography variant="mov_body1" style={{color: '#9A9A9A'}}>
                                I drank {props.amount || '75'} oz of water today
                            </Typography>
                        </Box>
                    }
                />
            </Box>
            <Box>
                <Box width={'100%'}>
                    <Box className={classes.itemDetailNoBGBox} paddingRight="0px !important" marginTop="32px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Typography variant="mov_overline2" style={{color: '#9A9A9A'}}>
                                TOTAL WATER
                            </Typography>
                            <InputNumber
                                name="totalWater"
                                endAdornment={'oz'}
                                style={{ width: '130px', minWidth: '130px' }}
                                value={props.data?.totalWater}
                                disabled={true}
                            />
                        </Box>
                        <Box marginTop={'8px'}>
                            <InputText
                                multiline
                                name="text"
                                placeholder="Write notes to your coach..."
                                style={{ width: '100%' }}
                                value={props.data?.text}
                                disabled={true}
                                endAdornment={<Typography variant='caption'>{`${props.data?.text.length}/255`}</Typography>}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default OzWater;
