import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './styles';

export default function FilterIcon(props: SvgIconProps) {
    const classes = useStyles();
    const fill = props.fill;

    return (
        <SvgIcon {...props} className={classes.root}>
            <line x1="2.5" y1="15.75" x2="16.875" y2="15.75" stroke="#414141"/>
            <line x1="2.5" y1="10.125" x2="16.875" y2="10.125" stroke="#414141"/>
            <line x1="2.5" y1="4.5" x2="16.875" y2="4.5" stroke="#414141"/>
            <circle cx="11.9688" cy="4.46875" r="1.96875" fill={fill}/>
            <path d="M13.875 4.46875C13.875 5.15911 13.3154 5.71875 12.625 5.71875C11.9346 5.71875 11.375 5.15911 11.375 4.46875C11.375 3.77839 11.9346 3.21875 12.625 3.21875C13.3154 3.21875 13.875 3.77839 13.875 4.46875Z" stroke="#414141"/>
            <circle cx="10.0938" cy="15.7188" r="1.96875" fill={fill}/>
            <circle cx="10.75" cy="15.7188" r="1.25" stroke="#414141"/>
            <circle cx="6.6425" cy="10.5313" r="1.9687" transform="rotate(-180 6.6425 10.5313)" fill={fill}/>
            <circle cx="5.98613" cy="10.5313" r="1.24996" transform="rotate(-180 5.98613 10.5313)" stroke="#414141"/>
        </SvgIcon>
    );
};
