import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function MorningIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 25 25"
            {...props}
            style={{
                color: 'white',
                width: props.width || '25px',
                height: props.height || '25px',
            }}
        >
            <g clipPath="url(#clip0_1097_27985)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.62602 16H16.374C15.9299 14.2748 14.3638 13 12.5 13C10.6362 13 9.07006 14.2748 8.62602 16ZM6.5 17C6.5 13.6863 9.18629 11 12.5 11C15.8137 11 18.5 13.6863 18.5 17C18.5 17.5523 18.0523 18 17.5 18H7.5C6.94772 18 6.5 17.5523 6.5 17Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 5C13.0523 5 13.5 5.44772 13.5 6V8C13.5 8.55228 13.0523 9 12.5 9C11.9477 9 11.5 8.55228 11.5 8V6C11.5 5.44772 11.9477 5 12.5 5Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.01311 8.51286C4.40363 8.12234 5.0368 8.12234 5.42732 8.51286L6.84732 9.93286C7.23785 10.3234 7.23785 10.9566 6.84732 11.3471C6.4568 11.7376 5.82363 11.7376 5.43311 11.3471L4.01311 9.92708C3.62258 9.53655 3.62258 8.90339 4.01311 8.51286Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 17C0.5 16.4477 0.947715 16 1.5 16H3.5C4.05228 16 4.5 16.4477 4.5 17C4.5 17.5523 4.05228 18 3.5 18H1.5C0.947715 18 0.5 17.5523 0.5 17Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5 17C20.5 16.4477 20.9477 16 21.5 16H23.5C24.0523 16 24.5 16.4477 24.5 17C24.5 17.5523 24.0523 18 23.5 18H21.5C20.9477 18 20.5 17.5523 20.5 17Z"
                    fill="#6D6D6D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.987 8.51286C21.3775 8.90339 21.3775 9.53655 20.987 9.92708L19.567 11.3471C19.1764 11.7376 18.5433 11.7376 18.1528 11.3471C17.7622 10.9566 17.7622 10.3234 18.1528 9.93286L19.5728 8.51286C19.9633 8.12234 20.5964 8.12234 20.987 8.51286Z"
                    fill="#6D6D6D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1097_27985">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
