import React, { FC, useEffect } from "react";
import { Box, Grid, Paper, Typography, Button, Tabs, Tab, List, ListItem, ListItemText, ListItemIcon, CircularProgress, Snackbar } from "@material-ui/core";
import useStyles from "./styles";
import IconGym from "assets/images/icons/gym.png";
import IconBarbel from "assets/images/icons/barbel.png";
import IconHead from "assets/images/icons/head.png";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { applyDrag } from "services/util";
import { DailyVideoData, DailyTaskForm } from "interfaces/programPlan";
import DeleteIcon from "@material-ui/icons/Delete";
import { addDailyTask, getDailyTask } from "services/domain/admin/programPlan";
import { Alert } from "@material-ui/lab";
import { SpoonForkIcon, FlagIcon, ImageIcon } from "components/icons";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface IData {
  id: number;
  data: string;
}
interface IDailyProps {
  week: number;
  stretch: DailyVideoData[];
  fitness: DailyVideoData[];
  personalDev: DailyVideoData[];
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const Daily: FC<IDailyProps> = (props) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  // eslint-disable-next-line
  const [day, setDay] = React.useState([1, 2, 3, 4, 5, 6, 7]);
  const [stretch, setStretch] = React.useState<DailyVideoData[]>(props.stretch);
  const [fitness, setFitness] = React.useState<DailyVideoData[]>(props.fitness);
  const [personal, setPersonal] = React.useState<DailyVideoData[]>(props.personalDev);
  const [optional, setOptional] = React.useState<DailyVideoData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [sending, setSending] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<"success" | "info" | "warning" | "error">("success");
  const [message, setMessage] = React.useState("");

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    fetchDailyTask(newValue + 1);
  };

  const fetchDailyTask = async (value: number) => {
    setLoading(true);
    setOptional([]);
    try {
      const res = await getDailyTask({ week: props.week, day: value - 1 });
      const dailyFitness = props.fitness.filter((opt: DailyVideoData) => {
        return opt._id === res.data.dailyFitness_id;
      });
      const dailyStretch = props.stretch.filter((opt: DailyVideoData) => {
        return opt._id === res.data.dailyStretch_id;
      });
      const dailyPersonalDev = props.personalDev.filter((opt: DailyVideoData) => {
        return opt._id === res.data.dailyPersonalDev_id;
      });
      setOptional([...dailyFitness, ...dailyStretch, ...dailyPersonalDev]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const saveOptionsVideos = async () => {
    const dailyFitness = optional.filter((opt: DailyVideoData) => {
      return opt.categories === "dailyFitness";
    });
    const dailyStretch = optional.filter((opt: DailyVideoData) => {
      return opt.categories === "dailyStretch";
    });
    const dailyPersonalDev = optional.filter((opt: DailyVideoData) => {
      return opt.categories === "dailyPersonalDev";
    });
    setSending(true);
    try {
      const payload: DailyTaskForm = {
        week: props.week,
        day: tab,
        dailyStretch_id: dailyStretch.length !== 0 ? dailyStretch[0]._id : undefined,
        dailyFitness_id: dailyFitness.length !== 0 ? dailyFitness[0]._id : undefined,
        dailyPersonalDev_id: dailyPersonalDev.length !== 0 ? dailyPersonalDev[0]._id : undefined,
      };
      await addDailyTask(payload);
      setSeverity("success");
      setMessage("Update Daily Task Success");
      setOpen(true);
    } catch (error) {
      setSeverity("error");
      setMessage("Update Daily Task Failed");
      setOpen(true);
    } finally {
      setSending(false);
    }
  };

  const arrayMoveMutate = (array: any, from: any, to: any) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = to < 0 ? array.length + to : to;

      const [item] = array.splice(from, 1);
      array.splice(endIndex, 0, item);
    }
  };

  const arrayMove = (array: any, from: any, to: any) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
  };

  const onDropOption = (e: DropResult) => {
    const { removedIndex, addedIndex, payload } = e;
    const checkData = optional.filter((item: DailyVideoData) => item._id === payload._id);
    if (checkData.length > 0) {
      setOptional((optional) => arrayMove(optional, removedIndex, addedIndex));
      return;
    }
    if (removedIndex === null && addedIndex === null) return;

    const data = [...optional];
    let itemToAdd = payload;
    const result = data.filter((opt: DailyVideoData) => {
      return opt.categories !== itemToAdd.categories;
    });

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    setOptional(result);
    // setPersonal(applyDrag(personal, e))
  };

  const deleteOptional = (i: number) => {
    const data = [...optional];
    data.splice(i, 1);
    setOptional(data);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    fetchDailyTask(1);
    // eslint-disable-next-line
  }, [props.week]);

  return (
    <Grid container spacing={3}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid item md={4}>
        <Grid container>
          <Grid item md={12} className={classes.videoOption}>
            <Paper>
              <Box pt={1} paddingX={3} paddingY={2}>
                <Box flexGrow={1}>
                  <Typography variant="subtitle1">VIDEO / DAILY STRETCH</Typography>
                </Box>
                <Box flexGrow={1} className={classes.listBox} height={200}>
                  <List component="nav" aria-label="main mailbox folders">
                    <Container behaviour="copy" groupName="1" getChildPayload={(i) => stretch[i]} onDrop={(e) => setStretch(applyDrag(stretch, e))}>
                      {stretch.map((p, i) => {
                        return (
                          <Draggable key={i}>
                            <ListItem className={classes.listItemBox} button>
                              <ListItemIcon>
                                <img src={IconGym} alt="" height={16} />
                              </ListItemIcon>
                              <ListItemText primary={p.title} />
                            </ListItem>
                          </Draggable>
                        );
                      })}
                    </Container>
                  </List>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={12} className={classes.videoOption}>
            <Paper>
              <Box pt={1} paddingX={3} paddingY={2}>
                <Box flexGrow={1}>
                  <Typography variant="subtitle1">VIDEO / DAILY FITNESS</Typography>
                </Box>
                <Box flexGrow={1} className={classes.listBox} height={200}>
                  <List component="nav" aria-label="main mailbox folders">
                    <Container behaviour="copy" groupName="1" getChildPayload={(i) => fitness[i]} onDrop={(e) => setFitness(applyDrag(fitness, e))}>
                      {fitness.map((p, i) => {
                        return (
                          <Draggable key={i}>
                            <ListItem className={classes.listItemBox} button>
                              <ListItemIcon>
                                <img src={IconBarbel} alt="" height={16} />
                              </ListItemIcon>
                              <ListItemText primary={p.title} />
                            </ListItem>
                          </Draggable>
                        );
                      })}
                    </Container>
                  </List>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={12} className={classes.videoOption}>
            <Paper>
              <Box pt={1} paddingX={3} paddingY={2}>
                <Box flexGrow={1}>
                  <Typography variant="subtitle1">VIDEO / DAILY PERSONAL DEVELOPMENT</Typography>
                </Box>
                <Box flexGrow={1} className={classes.listBox} height={200}>
                  <List component="nav" aria-label="main mailbox folders">
                    <Container behaviour="copy" groupName="1" getChildPayload={(i) => personal[i]} onDrop={(e) => setPersonal(applyDrag(personal, e))}>
                      {personal.map((p, i) => {
                        return (
                          <Draggable key={i}>
                            <ListItem className={classes.listItemBox} button>
                              <ListItemIcon>
                                <img src={IconHead} alt="" height={16} />
                              </ListItemIcon>
                              <ListItemText primary={p.title} />
                            </ListItem>
                          </Draggable>
                        );
                      })}
                    </Container>
                  </List>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8}>
        <Paper>
          <div className={classes.root}>
            <Tabs value={tab} onChange={handleChangeTab} variant="scrollable" indicatorColor="primary" scrollButtons="auto" aria-label="scrollable auto tabs">
              {day.map((item, index) => (
                <Tab classes={{ root: classes.tab }} key={index} label={`Day ${item}`} {...a11yProps(index)} />
              ))}
            </Tabs>
            {!loading ? (
              <Box paddingX={3} paddingBottom={2}>
                <Box my={2}>
                  <Typography>MAIN TASK </Typography>
                </Box>
                <Box>
                  <List component="nav" aria-label="main mailbox folders">
                    {tab === 0 && (
                      <ListItem className={classes.listItemBox} button>
                        <ListItemIcon>
                          <FlagIcon style={{ color: "transparent" }} />
                        </ListItemIcon>
                        <ListItemText primary="Orientation Call" />
                      </ListItem>
                    )}
                    <ListItem className={classes.listItemBox} button>
                      <ListItemIcon>
                        <SpoonForkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Balanced Breakfast" />
                    </ListItem>
                    <ListItem className={classes.listItemBox} button>
                      <ListItemIcon>
                        <SpoonForkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Balanced Lunch" />
                    </ListItem>
                    <ListItem className={classes.listItemBox} button>
                      <ListItemIcon>
                        <SpoonForkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Balanced Dinner" />
                    </ListItem>
                    <ListItem className={classes.listItemBox} button>
                      <ListItemIcon>
                        <SpoonForkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Stayed within 1,200-1,500 calories" />
                    </ListItem>
                    <ListItem className={classes.listItemBox} button>
                      <ListItemIcon>
                        <ImageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Post a daily photo" />
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Container groupName="1" getChildPayload={(i) => optional[i]} onDrop={(e) => onDropOption(e)}>
                    {optional.map((p, i) => {
                      return (
                        <Draggable key={i}>
                          <ListItem className={classes.listItemBox}>
                            <ListItemIcon>
                              <img src={p.categories === "dailyFitness" ? IconBarbel : p.categories === "dailyStretch" ? IconGym : IconHead} alt="" height={16} />
                            </ListItemIcon>
                            <ListItemText primary={p.title} />
                            <ListItemIcon onClick={() => deleteOptional(i)}>
                              <DeleteIcon className={classes.iconListDelete} />
                            </ListItemIcon>
                          </ListItem>
                        </Draggable>
                      );
                    })}
                  </Container>
                </Box>
                <Box textAlign="right">
                  <Button color="secondary" type="submit" variant="contained" size="medium" disabled={sending || loading} onClick={saveOptionsVideos}>
                    {sending ? "Saving..." : "Save"}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box display="flex" pb={1} alignItems="center" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Daily;
