import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PencilIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 25 25"
            {...props}
            style={{
                color: 'white',
                width: props.width || '25px',
                height: props.height || '25px',
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5 3.17139C19.2599 3.17139 19.0222 3.21868 18.8003 3.31057C18.5785 3.40245 18.3769 3.53714 18.2071 3.70692L4.89491 17.0191L3.92524 20.5746L7.4807 19.6049L20.7929 6.29271C20.9627 6.12292 21.0974 5.92136 21.1893 5.69952C21.2812 5.47769 21.3284 5.23993 21.3284 4.99981C21.3284 4.7597 21.2812 4.52194 21.1893 4.30011C21.0974 4.07827 20.9627 3.87671 20.7929 3.70692C20.6231 3.53714 20.4216 3.40245 20.1997 3.31057C19.9779 3.21868 19.7401 3.17139 19.5 3.17139ZM18.0349 1.46281C18.4994 1.27041 18.9973 1.17139 19.5 1.17139C20.0028 1.17139 20.5006 1.27041 20.9651 1.46281C21.4296 1.6552 21.8516 1.9372 22.2071 2.29271C22.5626 2.64821 22.8446 3.07025 23.037 3.53474C23.2294 3.99923 23.3284 4.49706 23.3284 4.99981C23.3284 5.50257 23.2294 6.0004 23.037 6.46489C22.8446 6.92938 22.5626 7.35142 22.2071 7.70692L8.70713 21.2069C8.58407 21.33 8.43104 21.4188 8.26314 21.4646L2.76314 22.9646C2.41693 23.059 2.04667 22.9607 1.79292 22.7069C1.53917 22.4532 1.44084 22.0829 1.53526 21.7367L3.03526 16.2367C3.08105 16.0688 3.16986 15.9158 3.29292 15.7927L16.7929 2.29271C17.1484 1.9372 17.5705 1.6552 18.0349 1.46281Z"
                fill={props.fill || '#515151'}
            />
        </SvgIcon>
    );
}
