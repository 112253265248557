/**
 * This file contains the booking value map for the booking refund component. Booking value is the original amount paid for the whole booking. Weekly value is the amount paid divided by the number of weeks in the booking.(note how longer bookings are cheaper per week)
 */

// prices are in cents for consistency
const bookingValueMap: {
  [key: number]: {
    bookingValue: number;
    weeklyValue: number;
  };
} = {
  1: {
    bookingValue: 15000,
    weeklyValue: 15000,
  },
  2: {
    bookingValue: 30000,
    weeklyValue: 15000,
  },
  3: {
    bookingValue: 45000,
    weeklyValue: 15000,
  },
  4: {
    bookingValue: 57000,
    weeklyValue: 14250,
  },
  8: {
    bookingValue: 114000,
    weeklyValue: 14250,
  },
  12: {
    bookingValue: 162000,
    weeklyValue: 13500,
  },
  24: {
    bookingValue: 298800,
    weeklyValue: 12450,
  },
  48: {
    bookingValue: 432000,
    weeklyValue: 9000,
  },
};

export default bookingValueMap;
