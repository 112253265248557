import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function BugIcon(props: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 24 24" className={classes.root}>
            <path 
                d="M6.18185 7.63054C6.88416 7.705 7.53079 7.78754 8.18317 7.78754C8.71392 7.78754 9.24847 7.71968 9.81991 7.6515C10.8525 7.5283 11.6967 6.67053 11.6967 5.63057C11.6967 4.54561 10.9089 3.61709 9.82981 3.50401M6.18185 7.63054C5.14769 7.52088 4.30328 6.67053 4.30328 5.63057C4.30328 4.54561 5.0911 3.61709 6.17015 3.50401M6.18185 7.63054C6.28389 7.64135 6.38476 7.65235 6.48464 7.66323C7.00781 7.72023 7.50391 7.77428 8 7.78544M6.18185 7.63054C5.24922 7.53165 4.47091 6.83035 4.32708 5.93086C3.81851 6.10782 3.19666 6.61718 3.19666 7.78018C3.19666 10.6154 3.54496 14.6905 8 15.2948M9.82981 3.50401C9.21646 3.43973 8.55119 3.38793 7.99998 3.38793C7.44878 3.38793 6.78351 3.43973 6.17015 3.50401M9.82981 3.50401C9.88864 3.51017 9.9466 3.51876 10.0036 3.52967C9.93793 2.57221 9.06599 1.81421 8 1.81421C6.93402 1.81421 6.06209 2.57221 5.9964 3.52966C6.0534 3.51876 6.11135 3.51017 6.17015 3.50401M8 15.2948V7.78544M8 15.2948C12.455 14.6905 12.8033 10.6154 12.8033 7.78018C12.8033 6.61718 12.1815 6.10781 11.6729 5.93085C11.5291 6.83035 10.7508 7.53164 9.81815 7.63053C9.71611 7.64135 9.61524 7.65234 9.51537 7.66323C8.99219 7.72023 8.49609 7.77428 8 7.78544M0.894958 4.49908C0.987476 5.41866 1.58387 7.25782 3.22931 7.25782M0.894958 14.9505C0.987476 14.0309 2.17068 12.0111 3.81612 12.0111M3.32129 9.80677H0.097229M15.1048 4.49908C15.0123 5.41866 14.4159 7.25782 12.7705 7.25782M15.1048 14.9505C15.0123 14.0309 13.8291 12.0111 12.1837 12.0111M12.6785 9.80677H15.9025" 
                stroke={props.stroke || '#414141'} strokeWidth="0.5" strokeLinejoin="bevel"
                />
        </SvgIcon>
    );
};
