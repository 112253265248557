// @ts-nocheck
// TODO:
import React from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Box, InputLabel, Button, Grid, MenuItem, Typography, Select as MuiSelect } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import history from 'services/data/history';
import { getVideoCookingById, getVideoCourseById } from 'services/domain/admin/videos';
import { VideoVariant, DefaultVideoRequest, CookingVideoRequest } from 'interfaces/video';
import { useStyles } from './styles';

interface VideoEditProps {
    variant: VideoVariant;
    onSubmit: (values: DefaultVideoRequest | CookingVideoRequest, { setSubmitting }: FormikHelpers<DefaultVideoRequest | CookingVideoRequest>, videoType: string) => void;
}

const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    urlLink: Yup.string().required('Url is required'),
    categories: Yup.string().required('Category is required'),
    nameOfDish: Yup.string(),
    serving: Yup.string(),
    caloriesTotal: Yup.string(),
    caloriesDetails: Yup.string(),
    ingredients: Yup.string(),
    direction: Yup.string(),
});

const initialValues = {
    title: '',
    urlLink: '',
    categories: '',

    nameOfDish: '',
    serving: '',
    caloriesTotal: '',
    caloriesDetails: '',
    ingredients: '',
    direction: '',
};

const VideoEdit: React.FC<VideoEditProps> = ({ variant, onSubmit }) => {
    const [videoType, setVideoType] = React.useState<string | null>(null);
    const [videoDetail, setVideoDetail] = React.useState(null);
    const { id } = useParams<{ id: string; }>();
    const defaultInitialValues = initialValues;
    const classes = useStyles();

    React.useEffect(() => {
        setVideoType(variant);

        const fetchVideoDetail = async () => {
            try {
                let result;
                
                if (variant === 'course') {
                    result = await getVideoCourseById({ idVideo: id });
                    setVideoDetail(result.data[0]);
                }

                if (variant === 'cooking') {
                    result = await getVideoCookingById({ idVideo: id });
                    const { recipeDetail, ...details } = result.data[0];
                    Object.assign(details, { ...recipeDetail });
                    setVideoDetail(details);
                }

            } catch (e) {
                // TODO: handle error here
            } finally {

            }
        }

        fetchVideoDetail();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (videoType === 'cooking') {
            // Object.assign(defaultInitialValues, recipeValues); TODO:
        }
    }, [videoType]);

    const handleVideoType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setVideoType(event.target.value as string);
    };

    const goBack = () => {
        history.goBack();
    }
    
    if (!videoType) {
        return null;
    }

    return (
        <Paper>
            <Box p={10}>
                <Formik
                    initialValues={videoDetail ?? defaultInitialValues}
                    onSubmit={(values, helpers) => { onSubmit(values, helpers, videoType) }}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting }) => {
                        return (
                            <Form>
                                <Grid container>
                                    <Grid item md={7}>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Title (*)"
                                            name="title"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={7}>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Url Link"
                                            name="urlLink"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={7}>
                                        <Box mt={5}>
                                            <InputLabel htmlFor="category">
                                                Categories
                                            </InputLabel>
                                            <Grid container spacing={2} className={classes.categorySpacing}>
                                                <Grid item md={6}>
                                                    <MuiSelect
                                                        value={videoType}
                                                        onChange={handleVideoType}
                                                        fullWidth
                                                        disabled
                                                    >
                                                        <MenuItem value="cooking">Cooking Videos</MenuItem>
                                                        <MenuItem value="course">Course Videos</MenuItem>
                                                    </MuiSelect>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Field
                                                        component={TextField}
                                                        name="categories"
                                                        inputProps={{
                                                            id: 'categories',
                                                        }}
                                                        type="select"
                                                        fullWidth
                                                        select
                                                    >
                                                        <MenuItem value="breakfast">Breakfast</MenuItem>
                                                        <MenuItem value="dailyFitness">Daily Fitness</MenuItem>
                                                        <MenuItem value="dailyPersonalDev">Daily Personal Development</MenuItem>
                                                        <MenuItem value="dessert">Dessert</MenuItem>
                                                        <MenuItem value="dinner">Dinner</MenuItem>
                                                        <MenuItem value="lunch">Lunch</MenuItem>
                                                        <MenuItem value="soup">Soup</MenuItem>
                                                        <MenuItem value="stretch">Stretch</MenuItem>
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {
                                        videoType === 'cooking'
                                        && (
                                            <>
                                                <Grid item md={7}>
                                                    <Typography>Recipes</Typography>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Name of Dish"
                                                        name="nameOfDish"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Servings"
                                                        name="serving"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Calories Total"
                                                        name="caloriesTotal"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Calories Details"
                                                        name="caloriesDetails"
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Ingredients"
                                                        name="ingredients"
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        rows={7}
                                                    />
                                                </Grid>
                                                <Grid item md={7}>
                                                    <Field
                                                        component={TextField}
                                                        placeholder="Type in here"
                                                        type="text"
                                                        label="Directions"
                                                        name="direction"
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        rows={7}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>

                                <Box mt={10}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Update Video
                                    </Button>
                                    <Button
                                        onClick={goBack}
                                        className={classes.buttonCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Paper>
    );
};

export default VideoEdit;
