import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from '../_config/styles';

export default function AddCircleIcon({ className, ...props }: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 24 25" className={clsx(classes.root, className ?? className)}>
            <circle cx="12" cy="12.1749" r="11.75" stroke="#414141" />
            <path d="M12 6.17493L12 18.1749M6 11.7999H18" stroke="#414141" />
        </SvgIcon>
    );
};
