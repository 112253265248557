import { makeStyles, Theme } from '@material-ui/core/styles';

export const useBookingInfoStyles = makeStyles((theme: Theme) => ({
    bookingDetailWrapper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    removeButton: {
        color: theme.palette.error.main,
    },
}));
