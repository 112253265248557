export default Object.freeze({
    HOME: {
        path: '/',
    },
    DASHBOARD: {
        name: 'Dashboard',
        path: '/dashboard',
    },
    PLAN_PROGRAM: {
        name: 'Plan Program',
        path: '/plan-program',
    },
    USERS: {
        name: 'Users',
        path: '/users/:action?/:id?',
    },
    BOOKINGS: {
        name: 'Bookings',
        path: '/bookings/:action?/:id?',
    },
    VIDEOS: {
        name: 'Videos',
        path: '/videos/:action?/:id?',
    },
    COMMUNITY_FEEDS: {
        name: 'Community Feeds',
        path: '/community-feeds',
    },
    MESSAGES: {
        name: 'Messages',
        path: '/messages',
    },
    SUPPORTS: {
        name: 'Support',
        path: '/support',
    },
    DASHBOARD_USER: {
        name: 'Dashboard User',
        path: '/dashboard-user',
    },
    SINGLE_POST: {
        name: 'Single Post',
        path: '/single-post/:id',
    },
    SINGLE_VIDEO: {
        name: 'Single Video',
        path: '/single-video/:type/:id',
    },
    BOOKINGS_COACH: {
        name: 'Bookings Coach',
        path: '/bookings-coach/:action?/:id?',
    },
    PAUSE_BOOKINGS: {
        name: 'Pause Booking',
        path: '/pause-bookings/:action?/:id?',
    },
    WAITING: {
        name: 'Waiting',
        path: '/waiting',
    },
    INACTIVE: {
        name: 'Inactive',
        path: '/inactive',
    },
    LASTWEEK: {
        name: 'Last Week',
        path: '/last-Week',
    },
    PROMO_CODE: {
        name: 'Promo Code',
        path: '/promo-code/:action?/:id?',
    },
    BOOKINGSGUEST: {
        name: 'Bookings Guest',
        path: '/bookings-guest',
    },
});
