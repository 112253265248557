import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        button: {
            '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.46)',
                backgroundColor: 'rgba(0, 0, 0, 0.42)',
            },
        },
        commonWhite: {
            '&, & *': {
                color: theme.palette.common.white,
            }
        },
        'forgot-password': {
            fontWeight: 600,
        },
        textBold: {
            // fontWeight: theme.typography.fontWeightBold,
        },
        buttonWrapper: {
            position: 'relative',
        },
        buttonProgress: {
            color: orange[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);
