import React from 'react';
import { Modal, Box, Button, Tooltip, IconButton } from '@material-ui/core';
import RotateRightIcon from '@material-ui/icons/RotateRightOutlined';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { useStyles } from './styles';

type typeFile = {
    type: string;
    name: string;
}

interface MImageCropper {
    src?: Array<{ data_url: string; file: typeFile }>;
    onCrop: (imageURL: any) => void;
}

const MImageCropper = React.forwardRef(({ src, onCrop }: MImageCropper, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [image, setImage] = React.useState('');
    // const [cropData, setCropData] = React.useState('#');
    const [cropper, setCropper] = React.useState<any>();
    const classes = useStyles();
    // eslint-disable-next-line
    const handleImageChange = async (files: any) => {
        const imageObject = (files[0] as { data_url: string; file: typeFile });
        const imagePromise = await fetch(imageObject.data_url);
        const imageBlob = await imagePromise.blob();
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(imageBlob);
    }

    const handleOpen = () => { setIsOpen(true); }
    const handleClose = () => { setIsOpen(false); setImage(''); setCropper(''); }

    const handleRotate = () => {
        cropper.rotate(90);
        setCrop()
    }

    const getCropData = () => {
        if (typeof cropper !== undefined) {
            // setCropData(cropper.getCroppedCanvas().toDataURL());
            onCrop(cropper.getCroppedCanvas().toDataURL());
            handleClose();
        }
    }

    const setCrop = () => {
        if (cropper !== undefined && cropper.imageData !== undefined && cropper.containerData !== undefined) {
            if (cropper.imageData.width > cropper.imageData.height) {
                
                if ((cropper.imageData.rotate === 0 || cropper.imageData.rotate === 180)) {
                    cropper.setCropBoxData({
                        top: (cropper.containerData.height - (cropper.containerData.width / cropper.imageData.aspectRatio))/2,
                        left: 0,
                        width: cropper.containerData.width,
                        height: (cropper.containerData.width / cropper.imageData.aspectRatio),
                    })
                    cropper.setCanvasData({
                        top: (cropper.containerData.height - (cropper.containerData.width / cropper.imageData.aspectRatio))/2,
                        left: 0,
                        width: cropper.containerData.width,
                        height: (cropper.containerData.width / cropper.imageData.aspectRatio),
                    })
                    
                } else {
                    cropper.setCropBoxData({
                        top: 0,
                        left: (cropper.containerData.width-(cropper.containerData.height/cropper.imageData.width*cropper.imageData.height))/2,
                        width: (cropper.containerData.height/cropper.imageData.width*cropper.imageData.height),
                        height: cropper.containerData.height
                    })
                    cropper.setCanvasData({
                        top: 0,
                        left: (cropper.containerData.width-(cropper.containerData.height/cropper.imageData.width*cropper.imageData.height))/2,
                        width: (cropper.containerData.height/cropper.imageData.width*cropper.imageData.height),
                        height: cropper.containerData.height
                    })
                    
                }
            }
            if (cropper.imageData.width < cropper.imageData.height) {
                
                if ((cropper.imageData.rotate === 0 || cropper.imageData.rotate === 180)) {
                    cropper.setCropBoxData({
                        top: 0,
                        left: (cropper.containerData.width - (cropper.containerData.height * cropper.imageData.aspectRatio))/2,
                        width: (cropper.containerData.height * cropper.imageData.aspectRatio),
                        height: cropper.containerData.height,
                    })
                    cropper.setCanvasData({
                        top: 0,
                        left: (cropper.containerData.width - (cropper.containerData.height * cropper.imageData.aspectRatio))/2,
                        width: (cropper.containerData.height * cropper.imageData.aspectRatio),
                        height: cropper.containerData.height,
                    })
                    
                } else {
                    cropper.setCropBoxData({
                        top: (cropper.containerData.height - (cropper.containerData.width/cropper.imageData.height*cropper.imageData.width))/2,
                        left: 0,
                        width: cropper.containerData.width,
                        height: (cropper.containerData.width/cropper.imageData.height*cropper.imageData.width)
                    })
                    cropper.setCanvasData({
                        top: (cropper.containerData.height - (cropper.containerData.width/cropper.imageData.height*cropper.imageData.width))/2,
                        left: 0,
                        width: cropper.containerData.width,
                        height: (cropper.containerData.width/cropper.imageData.height*cropper.imageData.width)
                    })
                    
                }
            }
        }
    }

    React.useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    React.useEffect(() => {
        if (src && src.length > 0) {
            handleImageChange(src);
        }
        // eslint-disable-next-line
    }, [src, handleImageChange])

    React.useEffect(() => {
        setTimeout(() => {
            if (cropper !== undefined && cropper.imageData !== undefined && cropper.containerData !== undefined) {
                setCrop()
            }
        }, 500)
        // eslint-disable-next-line
    }, [cropper])
    return (
        <Modal
            open={isOpen}
            className={classes.modal}
        >
            <div className={classes.paper}>
                <Cropper
                    src={image}
                    style={{ height: 400, width: '100%' }}
                    initialAspectRatio={1}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    rotatable
                    dragMode="none"
                />

                <Box
                    className={classes.buttonContainer}
                    mt={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Tooltip title="Rotate to right">
                        <IconButton
                            color="primary"
                            onClick={handleRotate}
                        >
                            <RotateRightIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Save">
                        <Button
                            color="primary"
                            onClick={getCropData}
                        >
                            Save
                        </Button>
                    </Tooltip>
                </Box>
                <Box
                    className={classes.buttonContainer}
                    mt={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        onClick={setCrop}
                    >
                        Default Crop
                    </Button>
                </Box>
            </div>
        </Modal>
    );
});

export default MImageCropper;
