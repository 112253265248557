import Swal from 'sweetalert2';

export const Toast = ({ onClose }: { onClose?: () => void }) => Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    showCloseButton: true,
    onClose,
});
