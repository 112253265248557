import React from "react";
import { Box } from "@material-ui/core";
import Typography from "components/_newDesign/typography/Typography";
import moment from "moment";
import Status from "components/_newDesign/status/StatusBooking";

const BookingId = ({ value, row }: { value: any; row: any }) => {
  const status = row.original.statusBooking || "";
  const pauseEndDate = status === "Pause" ? moment(row.original.pauseEndDate, "YYYY-MM-DD").format("MMM/DD/YY") : "";
  return (
    <Box>
      <Typography variant="mov_body1">Movara Home Program {moment(row.original.endDate).diff(row.original.startDate, "weeks")} weeks</Typography>
      <Typography variant="mov_caption1" style={{ color: "#9A9A9A", marginTop: "4px" }}>
        #{row.original._id}
      </Typography>
      <Box display={"flex"} mt="11px">
        <Status ml="0px" status={status} endDate={pauseEndDate} />
        <Box
          marginLeft={"8px"}
          p="4px 8px"
          borderRadius="20px"
          style={{ background: "#F8F8F8" }}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          width={"fix-content"}
        >
          <Typography variant="mov_body2">{row.original.coachName}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingId;
