import React from "react";
import { makeStyles } from "@material-ui/core";

const dropdownStyles = makeStyles({
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});
const ActionCell = ({ actionEdit, row }: { actionEdit: () => void; row: any }) => {
  const style = dropdownStyles();

  return <></>;
};
export default ActionCell;
