import React, { FC, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "pages/DashboardUser/components/main/daily/styles";
import InputNumber from "components/_newDesign/input/InputNumber";
import ButtonPopUp from "./ButtonPopUp";
import ErrorMessage from "components/_newDesign/alert/ErrorMessage";

interface Props {
  onChange?: (value: any) => void;
  active: boolean;
  preview: boolean;
  isDone?: boolean;
  defaultValue?: number;
}
const AmountCaloriesLabel: FC<Props> = (props) => {
  const isDone = props.isDone || false;
  const classes = useStyles();
  const [calories, setCalories] = React.useState(props.defaultValue);
  const onChange = (e: any) => {
    if (e.target.value > 9999) {
      setCalories(calories);
    } else {
      setCalories(e.target.value);
      props.onChange && props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setCalories(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Box display={"flex"} flexDirection="column">
      <Box display={"flex"}>
        <Box flexGrow={0}>
          <InputNumber disabled={isDone} value={calories} name="currentWeight" onChange={onChange} />
        </Box>
        <Box flexGrow={1} display="flex" alignItems={"center"} marginLeft={1}>
          <Typography gutterBottom className={classes.nutritionGoalTypo}>
            <span style={{ color: "black" }}>amount of calories</span>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography gutterBottom className={classes.nutritionGoalTypo}>
          (Movara serves 1200-1500)
        </Typography>
      </Box>
      {props.active && !calories && <ErrorMessage message={"Amount is Required"} />}

      {props.active && (
        <Box>
          <ButtonPopUp title="Amount of Calories" type="amount_of_calories" preview={props.preview} />
        </Box>
      )}
    </Box>
  );
};

export default AmountCaloriesLabel;
