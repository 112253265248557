import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            {...props}
            style={{
                color: 'white',
                width: props.width || '32px',
                height: props.height || '32px',
            }}
        >
            <rect width="32" height="32" rx="16" fill="white" />
            <rect
                width="32"
                height="32"
                rx="16"
                fill="url(#paint0_radial_961_5004)"
            />
            <path
                d="M23.6982 22.4191L23.7982 22.1729L25.797 24.1804L25.1995 23.9266L23.6982 22.4191Z"
                fill="#69696A"
            />
            <path
                d="M25.7974 24.1802L25.7824 24.1952H5.80615L5.82115 24.1802H25.7974Z"
                fill="#979596"
            />
            <path
                d="M5.82115 24.1804L5.80615 24.1954L7.80365 22.1879L7.81865 22.1729L5.82115 24.1804Z"
                fill="#979596"
            />
            <path
                d="M7.92635 22.4191L7.81885 22.1729H23.7988L23.6988 22.4191H7.92635Z"
                fill="#707070"
            />
            <path
                d="M23.6988 22.4189L25.2 23.9264H6.42627L7.92627 22.4189H23.6988Z"
                fill="#747373"
            />
            <path
                d="M25.2 23.9268L25.7975 24.1805H5.82129L6.42629 23.9268H25.2Z"
                fill="#858383"
            />
            <path
                d="M6.42629 23.9266L5.82129 24.1804L7.81879 22.1729L7.92629 22.4191L6.42629 23.9266Z"
                fill="#7D7B7C"
            />
            <path
                d="M23.8002 16.1491L23.7852 16.1641L25.7827 14.1566L25.7977 14.1416L23.8002 16.1491Z"
                fill="#8B8989"
            />
            <path
                d="M23.8014 22.1714L23.7864 22.1864L23.7852 16.1639L23.8002 16.1489L23.8014 22.1714Z"
                fill="#8B8989"
            />
            <path
                d="M25.7999 24.1789L25.7849 24.1939L23.7861 22.1864L23.8011 22.1714L25.7999 24.1789Z"
                fill="#8B8989"
            />
            <path
                d="M24.0536 16.2491L23.7998 16.1491L25.7973 14.1416L25.5498 14.7441L24.0536 16.2491Z"
                fill="#6D6B6B"
            />
            <path
                d="M25.5498 14.7441L25.7973 14.1416L25.7986 22.1716L25.5523 22.1679L25.5498 14.7441Z"
                fill="#5C5B5B"
            />
            <path
                d="M24.0548 22.0652L23.8011 22.1714L23.7998 16.1489L24.0536 16.2489L24.0548 22.0652Z"
                fill="#787676"
            />
            <path
                d="M25.55 14.7441L25.5525 22.1679L25.5537 23.5704L24.055 22.0654L24.0537 16.2491L25.55 14.7441Z"
                fill="#636261"
            />
            <path
                d="M25.5543 23.5704L25.8005 24.1792L23.8018 22.1717L24.0555 22.0654L25.5543 23.5704Z"
                fill="#7B7979"
            />
            <path
                d="M25.5527 22.168L25.799 22.1717L25.8002 24.1792L25.554 23.5705L25.5527 22.168Z"
                fill="#5C5B5B"
            />
            <path
                d="M5.82121 22.1715L5.80621 22.1865L5.80371 14.5677L5.81871 14.5527L5.82121 22.1715Z"
                fill="#8B8989"
            />
            <path
                d="M5.82115 24.1789L5.80615 24.1939V22.1864L5.82115 22.1714V24.1789Z"
                fill="#8B8989"
            />
            <path
                d="M6.0726 15.1552L5.81885 14.5527L7.81635 16.5615L7.5701 16.6602L6.0726 15.1552Z"
                fill="#575757"
            />
            <path
                d="M7.56982 16.6603L7.81607 16.5615L7.81857 22.1715L7.57107 22.0653L7.56982 16.6603Z"
                fill="#5C5B5B"
            />
            <path
                d="M6.07385 22.1677L5.82135 22.1715L5.81885 14.5527L6.0726 15.1552L6.07385 22.1677Z"
                fill="#787676"
            />
            <path
                d="M6.07504 23.5705L5.82129 24.1792V22.1717L6.07379 22.168L6.07504 23.5705Z"
                fill="#787676"
            />
            <path
                d="M7.57129 22.0654L7.81879 22.1717L5.82129 24.1792L6.07504 23.5704L7.57129 22.0654Z"
                fill="#6A6868"
            />
            <path
                d="M6.07227 15.1553L7.56977 16.6603L7.57102 22.0653L6.07477 23.5703L6.07352 22.1678L6.07227 15.1553Z"
                fill="#636261"
            />
            <path
                d="M14.726 19.5175L14.711 19.5325L12.9072 15.16L12.9222 15.145L14.726 19.5175Z"
                fill="#EACA87"
            />
            <path
                d="M7.82234 15.1437L7.80734 15.1587L5.80859 13.1512L5.82359 13.1362L7.82234 15.1437Z"
                fill="#EACA87"
            />
            <path
                d="M12.9221 15.1448L12.9071 15.1598L7.80713 15.1586L7.82213 15.1436L12.9221 15.1448Z"
                fill="#EACA87"
            />
            <path
                d="M14.0928 13.3825L14.2565 13.1362L15.0853 15.145L14.8578 15.2375L14.0928 13.3825Z"
                fill="#FEC229"
            />
            <path
                d="M14.8574 15.2375L15.0849 15.145L15.8074 16.895L15.5399 16.8925L14.8574 15.2375Z"
                fill="#FEC229"
            />
            <path
                d="M14.7294 18.8588L14.7256 19.5176L12.9219 15.1451L13.0919 14.8926L14.7294 18.8588Z"
                fill="#EFC771"
            />
            <path
                d="M15.5403 16.8926L15.8078 16.8951L14.7266 19.5176L14.7303 18.8588L15.5403 16.8926Z"
                fill="#F8C343"
            />
            <path
                d="M6.42873 13.3825L5.82373 13.1362H14.2562L14.0925 13.3825H6.42873Z"
                fill="#FAC33B"
            />
            <path
                d="M13.0923 14.8924L12.9223 15.1449L7.82227 15.1436L7.92977 14.8911L13.0923 14.8924Z"
                fill="#F1C66A"
            />
            <path
                d="M14.0925 13.3823L14.8575 15.2373L15.54 16.8923L14.73 18.8586L13.0925 14.8923L7.92996 14.8911L6.42871 13.3823H14.0925Z"
                fill="#F8C345"
            />
            <path
                d="M7.92998 14.8912L7.82248 15.1437L5.82373 13.1362L6.42873 13.3825L7.92998 14.8912Z"
                fill="#EFC772"
            />
            <path
                d="M23.8005 16.1489L23.7855 16.1639H18.2617L18.2767 16.1489H23.8005Z"
                fill="#E8BA80"
            />
            <path
                d="M16.1156 16.1491L16.1006 16.1641L16.9281 14.1566L16.9431 14.1416L16.1156 16.1491Z"
                fill="#E8BA80"
            />
            <path
                d="M15.808 16.8964L15.793 16.9114L16.1005 16.1639L16.1155 16.1489L15.808 16.8964Z"
                fill="#E8BA80"
            />
            <path
                d="M25.1992 14.3879L25.7967 14.1416L23.8005 16.1491L23.6992 15.8954L25.1992 14.3879Z"
                fill="#F2AA4C"
            />
            <path
                d="M14.7279 19.519L14.7129 19.534L15.7929 16.9115L15.8079 16.8965L14.7279 19.519Z"
                fill="#E8BA80"
            />
            <path
                d="M15.8104 22.1428L15.7954 22.1578L14.7129 19.534L14.7279 19.519L15.8104 22.1428Z"
                fill="#E8BA80"
            />
            <path
                d="M17.1134 14.3879L16.9434 14.1416H25.7971L25.1996 14.3879H17.1134Z"
                fill="#F6A535"
            />
            <path
                d="M23.6995 15.8955L23.8008 16.1493H18.277L18.1133 15.8955H23.6995Z"
                fill="#EEB162"
            />
            <path
                d="M16.349 16.2404L16.1152 16.1491L16.9427 14.1416L17.1127 14.3879L16.349 16.2404Z"
                fill="#EFAF5D"
            />
            <path
                d="M16.0426 16.9877L15.8076 16.8964L16.1151 16.1489L16.3489 16.2402L16.0426 16.9877Z"
                fill="#EFAF5D"
            />
            <path
                d="M18.1134 15.8955L18.2771 16.1493L16.8909 19.5193L16.6621 19.4205L18.1134 15.8955Z"
                fill="#F4A73D"
            />
            <path
                d="M15.0013 19.5152L14.7275 19.519L15.8075 16.8965L16.0425 16.9877L15.0013 19.5152Z"
                fill="#EFAF5D"
            />
            <path
                d="M25.1995 14.3877L23.6995 15.8952H18.1132L16.662 19.4202L15.8132 21.4827L15.002 19.5152L16.0432 16.9877L16.3495 16.2402L17.1132 14.3877H25.1995Z"
                fill="#F4A73E"
            />
            <path
                d="M15.8125 21.4826L15.81 22.1426L14.7275 19.5189L15.0013 19.5151L15.8125 21.4826Z"
                fill="#EDB369"
            />
            <path
                d="M16.6618 19.4204L16.8905 19.5192L15.8105 22.1429L15.813 21.4829L16.6618 19.4204Z"
                fill="#F4A73D"
            />
            <path
                d="M15.8301 6.87158L15.8151 6.88658L12.1514 10.1703L12.1664 10.1566L15.8301 6.87158Z"
                fill="#8B8989"
            />
            <path
                d="M14.3338 5.85887L14.3301 5.52637L15.8301 6.87137L15.4588 6.86762L14.3338 5.85887Z"
                fill="#575757"
            />
            <path
                d="M12.1666 10.1562L12.1516 10.17L8.8291 13.1513L8.8441 13.1363L12.1666 10.1562Z"
                fill="#8B8989"
            />
            <path
                d="M8.84389 13.1362L8.82889 13.1512H5.82764L5.84264 13.1362H8.84389Z"
                fill="#8B8989"
            />
            <path
                d="M11.0064 8.84012L10.8364 8.65762L14.3302 5.52637L14.3339 5.85887L11.0064 8.84012Z"
                fill="#6C6A6A"
            />
            <path
                d="M15.4592 6.86768L15.8304 6.87143L12.1667 10.1564L12.0029 9.96643L15.4592 6.86768Z"
                fill="#6B6969"
            />
            <path
                d="M12.0027 9.96631L12.1665 10.1563L8.84396 13.1363L8.75146 12.8826L12.0027 9.96631Z"
                fill="#6B6969"
            />
            <path
                d="M6.50027 12.8827L5.84277 13.1365L10.8365 8.65771L11.0065 8.84021L6.50027 12.8827Z"
                fill="#6C6A6A"
            />
            <path
                d="M8.75152 12.8828L8.84402 13.1366H5.84277L6.50027 12.8828H8.75152Z"
                fill="#767474"
            />
            <path
                d="M14.3337 5.85889L15.4587 6.86764L12.0025 9.96639L8.75125 12.8826H6.5L11.0063 8.84014L14.3337 5.85889Z"
                fill="#636261"
            />
            <path
                d="M25.8197 13.1362L25.8047 13.1512H22.8047L22.8197 13.1362H25.8197Z"
                fill="#979596"
            />
            <path
                d="M22.8201 13.1365L22.8051 13.1515L20.3076 10.9115L20.3226 10.8965L22.8201 13.1365Z"
                fill="#979596"
            />
            <path
                d="M20.3231 10.8962L20.3081 10.9112L16.9844 7.93248L16.9994 7.91748L20.3231 10.8962Z"
                fill="#979596"
            />
            <path
                d="M16.9989 7.91783L16.9839 7.93283L15.8164 6.88658L15.8314 6.87158L16.9989 7.91783Z"
                fill="#979596"
            />
            <path
                d="M15.8314 6.87137L15.8164 6.88637L14.3164 5.54137L14.3314 5.52637L15.8314 6.87137Z"
                fill="#979596"
            />
            <path
                d="M23.1592 11.0802L23.3229 10.8965L25.8204 13.1365L25.1717 12.8827L23.1592 11.0802Z"
                fill="#6A696A"
            />
            <path
                d="M18.165 6.60145L18.3288 6.41895L23.3225 10.8964L23.1588 11.0802L18.165 6.60145Z"
                fill="#6A696A"
            />
            <path
                d="M15.8338 4.51268L15.8301 4.18018L18.3288 6.41893L18.1651 6.60143L15.8338 4.51268Z"
                fill="#6A696A"
            />
            <path
                d="M25.1716 12.8828L25.8203 13.1366H22.8203L22.9191 12.8828H25.1716Z"
                fill="#858383"
            />
            <path
                d="M22.9185 12.8825L22.8198 13.1363L20.3223 10.8963L20.4923 10.7075L22.9185 12.8825Z"
                fill="#898788"
            />
            <path
                d="M20.4928 10.7075L20.3228 10.8963L16.999 7.91754L17.169 7.72754L20.4928 10.7075Z"
                fill="#898788"
            />
            <path
                d="M17.1686 7.72789L16.9986 7.91789L15.8311 6.87164L16.0011 6.68164L17.1686 7.72789Z"
                fill="#898788"
            />
            <path
                d="M14.7086 5.52268L14.3311 5.52643L15.8298 4.18018L15.8336 4.51268L14.7086 5.52268Z"
                fill="#7C7B7B"
            />
            <path
                d="M16.0011 6.68121L15.8311 6.87121L14.3311 5.52621L14.7086 5.52246L16.0011 6.68121Z"
                fill="#898788"
            />
            <path
                d="M15.834 4.5127L18.1652 6.60144L23.159 11.0802L25.1715 12.8827H22.919L20.4927 10.7077L17.169 7.72769L16.0015 6.68144L14.709 5.5227L15.834 4.5127Z"
                fill="#747373"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_961_5004"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(15.6205 13.1919) rotate(44.765) scale(22.3594 26.2283)"
                >
                    <stop offset="0.3649" stopColor="white" />
                    <stop
                        offset="0.538"
                        stopColor="#FDFDFD"
                        stopOpacity="0.7411"
                    />
                    <stop
                        offset="0.6342"
                        stopColor="#F5F5F5"
                        stopOpacity="0.5972"
                    />
                    <stop
                        offset="0.7115"
                        stopColor="#E7E7E7"
                        stopOpacity="0.4816"
                    />
                    <stop
                        offset="0.7788"
                        stopColor="#D3D3D3"
                        stopOpacity="0.3809"
                    />
                    <stop
                        offset="0.8396"
                        stopColor="#BABABA"
                        stopOpacity="0.29"
                    />
                    <stop
                        offset="0.8956"
                        stopColor="#9A9A9A"
                        stopOpacity="0.2061"
                    />
                    <stop
                        offset="0.9478"
                        stopColor="#767676"
                        stopOpacity="0.1281"
                    />
                    <stop
                        offset="0.9961"
                        stopColor="#4C4C4C"
                        stopOpacity="0.0558"
                    />
                    <stop offset="1" stopColor="#484848" stopOpacity="0.05" />
                </radialGradient>
            </defs>
        </SvgIcon>
    );
}
