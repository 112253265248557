import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './styles';

export default function GraphicIcon({ className, ...props }: SvgIconProps) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox="0 0 20 17" className={clsx(classes.root, className ?? className)}>
            <path d="M1.72803 0.5V14.9676H18.563" stroke="#414141"/>
            <rect x="5.15117" y="7.67803" width="0.606061" height="3.76894" stroke="#FFA21E" strokeWidth="0.606061"/>
            <rect x="9.39385" y="5.80303" width="0.606061" height="5.64394" stroke="#FFA21E" strokeWidth="0.606061"/>
            <rect x="13.636" y="3.92803" width="0.606061" height="7.51894" stroke="#FFA21E" strokeWidth="0.606061"/>
            <ellipse cx="1.72829" cy="1.65741" rx="1.12233" ry="1.15741" fill="white" stroke="#414141"/>
            <ellipse cx="18.0022" cy="14.9676" rx="1.12233" ry="1.15741" fill="white" stroke="#414141"/>
        </SvgIcon>
    );
};
