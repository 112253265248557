import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addIcon: {
        width: '10px',
        marginLeft: '5px',
    },
    categorySpacing: {
        marginTop: '8px',
    },
    buttonCancel: {
        marginLeft: '16px',
    },
});
