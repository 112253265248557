import React, { FC } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import iconUser from 'assets/images/icons/user.png';
import useStyles from './styles';
import {
    FlagIcon,
    VideoIcon,
} from 'components/icons';

const Statistic: FC<any> = props => {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2} className={classes.grid}>
                <Grid item md={4} xs={12} sm={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Grid container>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.header}>TOTAL GUESTS</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Box position="relative" top={7}>
                                    <img src={iconUser} alt="user-icon" />
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography align="right">
                                    <Typography component="span"
                                                variant="h4">{props.data?.totalGuest.value || 0}</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Grid container>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.header}>COMPLETED MAIN TASK RATE</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Box position="relative" top={7}>
                                    <FlagIcon color="primary" />
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography align="right">
                                    <Typography component="span"
                                                variant="h4">{props.data?.completedMainTaskRate.value ? props.data?.completedMainTaskRate.value.toFixed(0) : 0}%</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Grid container>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.header}>COMPLETED OPTIONAL TASK RATE</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Box position="relative" top={7}>
                                    <VideoIcon style={{color:"transparent"}}/>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography align='right' variant="h4">{props.data?.completeOptionalTaskRate.value ? props.data?.completeOptionalTaskRate.value.toFixed(0) : 0}%</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </>
    );
};

export default Statistic;
