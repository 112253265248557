import { http } from "../../data";
import { AccountFormInterface } from "pages/Account/Account/types";
import { PasswordInterface } from "pages/Account/Password/types";
import { ILogin, IRegister, PaymentStripe, PaymentStripeData, IUserID, StripeCustomerAddData } from "./types";
import {
  UserByEmail,
  ProfileUpdateRequest,
  ListCoachResponse,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  UpdatePrivacyRequest,
  UpdateSingleNotificationRequest,
  EditPassUserRequest,
  SearchByFirstName,
  ListUserSearchProfile,
  ListPresenterResponse,
} from "interfaces/user";
import { PaginationInterface } from "interfaces/common";
import { GetActivityPayload, GetActivityResponse } from "interfaces/activity";
import moment from "moment";

const endPoints = {
  login: "login",
  register: "register",
  password: "editPassUser",
  forgotPassword: "forgotpass",
  resetPassword: "resetpass",
  editPassword: "editPassuser",

  user: "dataUsers",
  profileMetrics: "profile",
  userByEmail: "dataUsers/byemail",
  postUser: "editUser",
  userAbout: "editUserAbout",
  userPrivacy: "privacy",
  notifications: "notifications/comfeed",
  updateNotifications: "notifications/read",

  listPresenter: "presenter",
  listCoach: "coach",
  assessment: "admin/assessments/weekly",

  avatar: "avatar",
  header: "header",

  activity: "activities/getDataByUser",
  seaarchFirstName: "search/users/firstname",
  seaarchFirstNameBooking: "search/users/firstname/booking",
  stripe: "payment/stripe",
  stripeData: "payment/stripe/data",
  stripeCustomer: "payment/stripe/customer",
  stripeSub: "payment/stripe/sub",
  getStripeCustomerByEmail: "payment/stripe/customer",

  serachByEmail: "search/users/email",
  searchLikeEmail: "/search/users/like/email",
};

export const postLogin = (payload: ILogin) => http.post(endPoints.login, payload);
export const postRegister = (payload: IRegister) => http.post(endPoints.register, payload);
export const updatePassword = (payload: PasswordInterface) => http.post(endPoints.password, payload);
export const forgotPassword = (payload: ForgotPasswordRequest) => http.post(endPoints.forgotPassword, payload);
export const resetPassword = (payload: ResetPasswordRequest) => http.post(endPoints.resetPassword, payload);
export const editPassword = (payload: EditPassUserRequest) => http.post(endPoints.editPassword, payload);

export const getUser = (payload: IUserID) => http.get(endPoints.userByEmail, { params: payload });
export const getProfileMetrics = (payload: { userId: string }) => http.get(endPoints.profileMetrics, { params: payload });
export const getUserByEmail = (payload: UserByEmail) => http.get(endPoints.userByEmail, { params: payload });
export const updateUser = (payload: AccountFormInterface) => http.post(endPoints.postUser, payload);
export const updateUserAbout = (payload: ProfileUpdateRequest) => http.post(endPoints.userAbout, payload);
export const updatePrivacy = (payload: UpdatePrivacyRequest) => http.post(endPoints.userPrivacy, payload);
export const getNotifications = (payload: PaginationInterface) => http.get(endPoints.notifications, { params: payload });
export const updateNotifications = (payload: UpdateSingleNotificationRequest | undefined) => http.post(endPoints.updateNotifications, payload);
export const getPrivacy = () => http.get(endPoints.userPrivacy);

export const getListPresenter = (): Promise<ListPresenterResponse> => http.get(endPoints.listPresenter);

export const getListCoach = (): Promise<ListCoachResponse> => http.get(endPoints.listCoach + `?date=${moment().format("YYYY-MM-DD")}`);
export const getListCoachAll = (): Promise<ListCoachResponse> => http.get(endPoints.listCoach + `?date=${moment().format("YYYY-MM-DD")}&visibility=false`);

export const getListCoachByid = (id, date): Promise<ListCoachResponse> => http.get(endPoints.listCoach + `?date=${date}&coach_id=${id}`);
export const postWeeklySubmission = (payload: any): Promise<any> => http.post(endPoints.assessment, payload);
export const putWeeklySubmission = (payload: any): Promise<any> => http.put(endPoints.assessment, payload);
export const uploadAvatar = async (payload: any): Promise<any> => await http.post(endPoints.avatar, payload);
export const uploadHeader = async (payload: any): Promise<any> => await http.post(endPoints.header, payload);

export const getActivity = async (payload: GetActivityPayload): Promise<GetActivityResponse> => await http.get(endPoints.activity, { params: payload });
export const getUserByFirstName = async (payload: SearchByFirstName): Promise<any> => await http.get(endPoints.seaarchFirstName, { params: payload });
export const getUserByFirstNameBooking = async (payload: SearchByFirstName): Promise<any> => await http.get(endPoints.seaarchFirstNameBooking, { params: payload });
export const searchUserByEmail = async (email: string): Promise<any> => await http.get(endPoints.serachByEmail, { params: { email: email } });
export const searchUserLikeEmail = async (email: string): Promise<ListUserSearchProfile> => await http.get(endPoints.searchLikeEmail, { params: { email: email } });

export const paymentStripe = async (payload: PaymentStripe): Promise<any> => await http.post(endPoints.stripe, payload);
export const paymentStripeData = async (payload: PaymentStripeData): Promise<any> => await http.post(endPoints.stripeData, payload);

export const createStripeCustomer = async (payload: StripeCustomerAddData): Promise<any> => await http.post(endPoints.stripeCustomer, payload);

export const createStripeSubscription = async (payload: { customer: string; priceId: string }): Promise<any> => await http.post(endPoints.stripeSub, payload);

export const getStripeCustomerByEmail = async (payload: { email: string }): Promise<any> => await http.get(endPoints.getStripeCustomerByEmail, { params: payload });
