import { lazy } from 'react';
import { routeConstants, LayoutOptions } from 'config/constants';

const PromoCode = lazy(() => import('./Promocode'));

export default {
    path: routeConstants.PROMO_CODE.path,
    component: PromoCode,
    layout: LayoutOptions.dashboard,
};
