import React, { FC, useCallback, useMemo, useState } from "react";
import { Paper, Box, Typography, Tooltip } from "@material-ui/core";
import { Toast } from "services/util/toast";
import MTable from "components/_newDesign/table/Table";
import TypographyMui from "components/_newDesign/typography/Typography";
import { Column } from "react-table";
import { getCoachUsers } from "services/domain/admin/users";
import ButtonIcon from "components/button/ButtonIcon";
import { ViewListOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

const CoachReporting: FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [coaches, setCoaches] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [refresh, setRefresh] = useState<boolean>(false);

  const tableComp = React.useRef<any>(null);

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: "Name",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">
              {props.row.original.name.firstName} {props.row.original.name.lastName}
            </TypographyMui>
          </Box>
        ),
      },
      {
        Header: "Email",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <TypographyMui variant="mov_body1">{props.row.original.email}</TypographyMui>
          </Box>
        ),
      },
      {
        Header: "View Reports",
        Cell: (props) => (
          <Box textAlign={"center"} margin="auto">
            <Tooltip title="View Reports">
              <Link to={`/accounting/coach-reports/${props.row?.original?._id}`}>
                <ButtonIcon color="primary" variant="contained" startIcon={<ViewListOutlined />} />
              </Link>
            </Tooltip>
          </Box>
        ),
        id: "action",
      },
    ],
    [],
  );

  const fetchCoaches = useCallback(async ({ pageIndex, pageSize, sortBy }: { pageIndex: any; pageSize: any; sortBy: any }) => {
    try {
      setLoading(true);

      const result = await getCoachUsers({
        existData: pageIndex * pageSize,
        limitData: pageSize,
      });

      const { data } = result;

      setCoaches(data);
      setPageCount(result.meta.pagination.totalPage);
    } catch (e) {
      console.error(e);

      const errorToast = Toast({});
      errorToast.fire({
        icon: "error",
        title: "Failed to load Coaches",
        text: "Please refresh the page to try again.",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Typography variant="h4">Coach Reports</Typography>
      <Box mt={3}>
        <Paper>
          <MTable columns={columns} data={coaches} onFetchData={fetchCoaches} pageCount={pageCount} refreshPage={refresh} loading={loading} ref={tableComp} />
        </Paper>
      </Box>
    </>
  );
};

export default CoachReporting;
